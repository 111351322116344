import { useFieldArray, useFormContext } from "react-hook-form";
import { IPriceList } from "../../../../types/Inventory/PriceList";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDisable } from "../../../../hooks/use-disable";
import { usePriceItemList } from "../../../../hooks/Inventory/use-price-item-list";
import ClearIcon from "@mui/icons-material/Clear";
import CheckedItemModal from "../../../UI/Modal/CheckedItemModal";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { t } from "i18next";
import { useModal } from "../../../../hooks/use-modal";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { numberFormatter } from "../../../../utils/Formatter/Global";

const PriceItemList = () => {
  const { control } = useFormContext<IPriceList>();
  const { modal, openModalHandler, closeModalHandler } = useModal();

  const [itemIds, setItemsIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemsIdsSnapshot] = useState<string[]>([]);

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "adjusted_item_list",
    keyName: "genId",
  });

  const [disabled] = useDisable();

  const headers = usePriceItemList(disabled);

  const finishItemsSelect = (data: any[]) => {
    // filter out Items not selected in current modal session
    let filteredItems = fields.filter((item) =>
      itemIds.includes(item.sku_name)
    );

    // get current Items id to prevent duplication when appending newly selected Items
    const filteredItemsId = filteredItems.map((item) => item.sku_name);

    data.forEach((item) => {
      if (!filteredItemsId.includes(item.sku_name)) {
        filteredItems.push({
          genId: uuidv4(),
          sku_name: item.sku_name,
          item_name: item.name,
          current_price: item.sale_price,
          adjusted_price: 0,
        });
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredItems = filteredItems.sort((a, b) => {
      return itemIds.indexOf(a.sku_name) - itemIds.indexOf(b.sku_name);
    });
    replace(filteredItems);
    closeModalHandler();
  };

  useEffect(() => {
    const itemSkuName = fields.map((field) => field.sku_name);
    setItemsIds(itemSkuName);
    setItemsIdsSnapshot(itemSkuName);
  }, [fields]);

  return (
    <>
      <CustomizedBox maxWidth={1040} bgcolor="primary.light" margin={0}>
        <TableContainer>
          <Table sx={{ overflow: "scroll" }}>
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color="primary.main"
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((item, index) => (
                <TableRow key={item.genId}>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography fontSize={14}>
                      {item.sku_name} - {item.item_name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography fontSize={14}>
                      {numberFormatter(item.current_price)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <ControlledNumberTextField
                      control={control}
                      name={`adjusted_item_list.${index}.adjusted_price`}
                      viewMode={disabled}
                      textAlign="right"
                    />
                  </TableCell>
                  {!disabled && (
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!disabled && (
          <CustomizedButton
            sx={{ mt: 2 }}
            title={`${t("button.add")}${t("inventory.items.index")}`}
            variant="outlined"
            addIcon
            onClick={openModalHandler}
          />
        )}
      </CustomizedBox>
      <CheckedItemModal
        itemIds={itemIds}
        setItemIds={setItemsIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemsIdsSnapshot}
        closeItemTable={closeModalHandler}
        rowSelection="multiple"
        showSelectItem={modal}
        finishItemsSelect={finishItemsSelect}
      />
    </>
  );
};

export default PriceItemList;
