import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { IGoodsAdjustment } from "../../../types/Inventory/GoodsAdjustment";
import { useInventoryError } from "../../../hooks/use-inventory-error";

import { useCallback, useEffect, useState } from "react";
import { useDisable } from "../../../hooks/use-disable";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GoodsAdjustCreateInput,
  GoodsAdjustUpdateInput,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  useGoodsAdjustCreateMutation,
  useGoodsAdjustQuery,
  useGoodsAdjustUpdateMutation,
  useInventoryDocumentCancelMutation,
  useItemSkuQtysQuery,
} from "../../../generated/inventory";
import {
  goodsAdjustmentSchema,
  goodsAdjustmentValidation,
} from "../../../components/Form/Inventory/GoodsAdjustment/schema";
import { enqueueSnackbar } from "notistack";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, CircularProgress, Stack } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import {
  goodsAdjustmentCreateFormatter,
  goodsAdjustmentQueryFormatter,
  goodsAdjustmentUpdateFormatter,
} from "../../../utils/Formatter/Inventory/GoodsAdjustment";
import GoodsAdjustmentHeader from "../../../components/Form/Inventory/GoodsAdjustment/Header";
import GoodsAdjustmentInfo from "../../../components/Form/Inventory/GoodsAdjustment/info";
import GoodsAdjustmentItemList from "../../../components/Table/Inventory/GoodsAdjustment/itemList";
import { useAdjustmentButtonByStatus } from "../../../hooks/Inventory/use-adjustment-button-by-status";
import GoodsAdjustmentHeaderBreadcrumbs from "../../../components/Form/Inventory/GoodsAdjustment/HeaderBreadcrumbs";
import { useStateContext } from "../../../contexts/auth-context";
import { IApprovalTemplate } from "../../../types/global";
import useInventoryControl from "../../../hooks/Inventory/use-inventory-control";
import { useActivityLog } from "../../../hooks/use-activity-log";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";

const GoodsAdjustmentContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const graphQLClient = createGraphQLClientWithMiddleware("wms");
  const [isEdit, setIsEdit] = useState(false);
  const [, setDisabled] = useDisable();

  const { createActivityLog } = useActivityLog();

  const {
    state: { authUser },
  } = useStateContext();

  const [approverList, setApproverList] = useState<IApprovalTemplate[]>([]);
  const [approvalStep, setApprovalStep] = useState<number | null | undefined>(
    0
  );
  const [isHaveApproval, setIsHaveApproval] = useState<boolean>(false);

  setDisabled(false);

  const { data, isLoading, refetch, isSuccess } = useGoodsAdjustQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const goodsAdjustmentInfo = data?.GoodsAdjust;

  const { refetch: refetchItemSku } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClient,
    {
      findManyInput: {
        where: {
          barcode: {
            in: goodsAdjustmentInfo?.trace_entry_list.reduce<string[]>(
              (list, traceEntry) => {
                if (traceEntry.barcode) list.push(traceEntry.barcode);

                return list;
              },
              []
            ),
          },
        },
      },
    },
    {
      enabled: !!id,
    }
  );

  const currentStatus = goodsAdjustmentInfo?.aggrid_status;

  const methods = useForm<IGoodsAdjustment>({
    defaultValues: goodsAdjustmentSchema,
    resolver: yupResolver<any>(goodsAdjustmentValidation),
  });

  const { mutateAsync: create, isLoading: isCreating } =
    useGoodsAdjustCreateMutation<Error>(graphQLClient);

  const { mutateAsync: updateTransfer, isLoading: isUpdate } =
    useGoodsAdjustUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancelTransfer, isLoading: isCanceling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const { approvalTemplates } = useInventoryControl(
    undefined,
    undefined,
    "goods_adjust"
  );

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = methods;

  const aggridStatus = useWatch({
    control,
    name: "aggrid_status",
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const formatGT = useCallback(
    async (data: IGoodsAdjustment) => {
      const isFreezeStockQty = ["finished", "cancelled"].includes(
        data.aggrid_status || ""
      );

      const itemSkuData = isFreezeStockQty
        ? undefined
        : (await refetchItemSku()).data;
      const goodsAdjustment = goodsAdjustmentQueryFormatter(data, itemSkuData);

      reset(goodsAdjustment);

      if (currentStatus && ["finished", "cancelled"].includes(currentStatus)) {
        setDisabled(true);
      }
    },
    [reset, setDisabled, currentStatus, refetchItemSku]
  );

  // const editHandler = () => {
  //   setIsEdit(true);
  // };

  const getGoodsAdjustment = useCallback(async () => {
    if (isSuccess) {
      const { GoodsAdjust } = data;
      const goodsAdjustment = GoodsAdjust as IGoodsAdjustment;
      formatGT(goodsAdjustment);

      const currentStep = GoodsAdjust?.approval_step;
      setApprovalStep(currentStep);

      if (GoodsAdjust?.aggrid_status === "wait_approve") {
        setApproverList(approvalTemplates as IApprovalTemplate[]);
      } else {
        const allApproval = GoodsAdjust?.approver_list || [];
        setApproverList(allApproval);
      }

      const currentReviewerList =
        approvalTemplates.find(
          (template) => template?.step_number === currentStep
        )?.approver_list || [];

      const isHaveApproval = currentReviewerList.some(
        (reviewer) => reviewer.id === authUser?.id
      );

      setIsHaveApproval(isHaveApproval);
    }
  }, [approvalTemplates, authUser?.id, data, formatGT, isSuccess]);

  useEffect(() => {
    getGoodsAdjustment();
  }, [getGoodsAdjustment]);

  const statusButtonInfo = useAdjustmentButtonByStatus(
    approvalTemplates.length > 0,
    isHaveApproval
  );

  const handleCreateUpdate = async (
    data: IGoodsAdjustment,
    title: string,
    nextStatus: string | undefined
  ) => {
    try {
      setIsEdit(false);

      if (nextStatus === "cancelled") {
        reset();
        return;
      }

      let changingStatus = nextStatus;
      let currentApprovalList: IApprovalTemplate[] = [];
      let currentApprovalStep = approvalStep || 0;

      const reviewed_by = authUser?.id;

      if (data.aggrid_status === "wait_approve") {
        if (nextStatus === "not_approved") {
          changingStatus = "not_approved";
          currentApprovalList = approvalTemplates as IApprovalTemplate[];
        } else {
          if (approvalStep && approvalStep > approvalTemplates.length - 1) {
            if (nextStatus) {
              changingStatus = "finished";
              currentApprovalList = approvalTemplates as IApprovalTemplate[];
            }
          } else {
            changingStatus = "wait_approve";
            if (nextStatus) {
              currentApprovalStep = currentApprovalStep + 1;
            }
          }
        }
      } else {
        if (nextStatus === "wait_approve") {
          currentApprovalStep = 1;
        }
        changingStatus = nextStatus;
      }

      const formatData = id
        ? goodsAdjustmentUpdateFormatter(
            data,
            changingStatus,
            currentApprovalStep,
            currentApprovalList,
            reviewed_by
          )
        : goodsAdjustmentCreateFormatter(
            data,
            changingStatus,
            approvalTemplates.length > 0 ? 1 : 0
          );

      const result: any = id
        ? await updateTransfer({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsAdjustUpdateInput,
          })
        : await create({
            createInput: formatData as GoodsAdjustCreateInput,
          });
      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: result.GoodsAdjustCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: changingStatus,
          },
        });
      } else if (id && changingStatus === "finished") {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: result.GoodsAdjustUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Approve,
            message: [`ลำดับที่ ${approvalStep}`],
            prev_status: "wait_approve",
            curr_status: "finished",
          },
        });
      } else if (
        id &&
        changingStatus === "wait_approve" &&
        approvalStep !== currentApprovalStep
      ) {
        await createActivityLog({
          activity_type: ActivityType.Approve,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: result.GoodsAdjustUpdate?.id,
          activity_detail: {
            message: [`ลำดับที่ ${approvalStep}`],
          },
        });
      } else if (id && nextStatus && data.aggrid_status !== nextStatus) {
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: result.GoodsAdjustUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: changingStatus,
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsAdjustment,
          reference_id: result.GoodsAdjustUpdate?.id,
          activity_detail: {},
        });
      }

      if (!id)
        navigate(`/inventory/goods-adjustment/${result.GoodsAdjustCreate.id}`);
      await refetch();

      enqueueSnackbar(`${title}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${title}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancelTransfer({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryDocumentType.GoodsAdjust,
      });
      await createActivityLog({
        activity_type: ActivityType.Cancel,
        document_type: ActivityLogDocumentType.GoodsAdjustment,
        reference_id: parseInt(id!),
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("status.cancelled")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("status.cancelled")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const renderButton = (status?: string) => {
    return (
      <>
        {statusButtonInfo(isEdit, status).map((button, index) => {
          return (
            <CustomizedButton
              key={index}
              variant={button.variant}
              title={button.title}
              onClick={handleSubmit((data) =>
                handleCreateUpdate(data, button.title, button.next)
              )}
            />
          );
        })}
      </>
    );
  };

  useInventoryError(errors);

  if (id && (isLoading || isCreating || isUpdate || isCanceling)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <GoodsAdjustmentHeaderBreadcrumbs
          cancelHandler={cancelHandler}
          aggridStatus={aggridStatus ?? ""}
          // editHandler={editHandler}
        />
        <GoodsAdjustmentHeader
          isEdit={isEdit}
          approverList={approverList}
          approvalStep={approvalStep}
        />
        <GoodsAdjustmentInfo isEdit={isEdit} />
        <GoodsAdjustmentItemList
          fields={fields}
          remove={remove}
          update={update}
          append={append}
          isEdit={isEdit}
        />
      </FormProvider>
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(currentStatus || "")}
        </Stack>
      </BottomNavbar>
    </>
  );
};

export default GoodsAdjustmentContainer;
