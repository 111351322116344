import { gql } from "graphql-request";

export const CUSTOMERS_AGGRID = gql`
  query CustomersView($aggridInput: AnyAggridInput) {
    CustomersView(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        business_type
        name
        last_name
        full_name
        contact_channel_list
        taxpayer_id
        tag_list
        status
        address_list
        credit_day
        sales_person_list
        contact_person_list
      }
    }
  }
`;

export const VENDORS_AGGRID = gql`
  query VendorsView($aggridInput: AnyAggridInput) {
    VendorsView(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        business_type
        name
        last_name
        full_name
        contact_channel_list
        taxpayer_id
        tag_list
        status
        address_list
        credit_day
      }
    }
  }
`;
