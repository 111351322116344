import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ISelectOption } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ControlledTextField from "../../../components/Controller/ControlledTextField";
import { useForm, useWatch, Controller, FormProvider } from "react-hook-form";
import { priceListSchema, priceListValidation } from "./schema";
import { IPriceList } from "../../../types/Inventory/PriceList";
import { useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../hooks/use-disable";
import { useCallback, useEffect } from "react";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import RadioBox from "../../../components/UI/RadioBox";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import ControlledNumberTextField from "../../../components/Controller/ControlledNumberTextField";
import ControlledSwitch from "../../../components/Controller/ControlledSwitch";
import ControlledDatePicker from "../../../components/Controller/ControlledDatePicker";
import ControlledSelect from "../../../components/Controller/ControlledSelect";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import PriceItemList from "../../../components/Table/Inventory/PriceList/ItemList";
import LabelInput from "../../../components/UI/LabelInput";
import {
  ItemPriceListFindUniqueQuery,
  WmsModelType,
  WmsUniqueIdGenerateQuery,
  useItemPriceListCreateMutation,
  useItemPriceListDeleteMutation,
  useItemPriceListFindUniqueQuery,
  useItemPriceListUpdateMutation,
  useWmsUniqueIdGenerateQuery,
} from "../../../generated/inventory";
import { useSnackbar } from "notistack";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { useModal } from "../../../hooks/use-modal";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import {
  SalesChannelsFindAllQuery,
  useSalesChannelsFindAllQuery,
} from "../../../generated/general";
import {
  priceListCreateFormatter,
  priceListQueryFormatter,
  priceListUpdateFormatter,
} from "../../../utils/Formatter/Inventory/PriceList";
import { yupResolver } from "@hookform/resolvers/yup";

const currencyOptions: ISelectOption[] = [
  {
    label: "THB",
    value: "THB",
  },
];

const selectModifyOptions = [
  {
    value: "แก้ไข",
    disabled: false,
  },
  {
    value: "ลบ",
    disabled: false,
  },
];

const PriceListContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled, setDisabled] = useDisable();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<IPriceList>({
    defaultValues: priceListSchema,
    resolver: yupResolver<any>(priceListValidation),
  });

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    resetField,
    handleSubmit,
    reset,
  } = methods;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.price_list.index"),
      to: "/inventory/price-list",
    },
    {
      name:
        id && Boolean(id)
          ? getValues("unique_id")
          : `${t("button.create")}${t("inventory.price_list.index")}`,
    },
  ];

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled]);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchIsIncrease = useWatch({
    control,
    name: "is_increase",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const graphQLClientWms = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClientWms,
      {
        modelType: WmsModelType.ItemPriceList,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const { data: salesChannel, isLoading: isSalesChannelLoading } =
    useSalesChannelsFindAllQuery<SalesChannelsFindAllQuery>(graphQLClient);

  const { data, isSuccess, isLoading } =
    useItemPriceListFindUniqueQuery<ItemPriceListFindUniqueQuery>(
      graphQLClientWms,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useItemPriceListCreateMutation<Error>(graphQLClientWms);

  const { mutateAsync: update, isLoading: isUpdating } =
    useItemPriceListUpdateMutation<Error>(graphQLClientWms);

  const { mutateAsync: deletePriceList, isLoading: isDeleting } =
    useItemPriceListDeleteMutation<Error>(graphQLClientWms);

  const salesChannelOptions: ISelectOption[] =
    salesChannel?.SalesChannelsFindAll.map((channel) => ({
      id: channel.id,
      label: channel.name_th,
      value: channel.id,
    })) || [];

  const mapSalesChannelValue = (id?: number) =>
    salesChannel?.SalesChannelsFindAll.find((channel) => channel.id === id)
      ?.name_th;

  const createPriceListHandler = async (data: IPriceList) => {
    try {
      const unique_id = getValues("unique_id");
      const formatData = priceListCreateFormatter(data, unique_id);
      const { ItemPriceListCreate } = await create({
        data: formatData,
      });
      enqueueSnackbar(
        `${t("button.create")}${t("inventory.price_list.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
      navigate(`/inventory/price-list/${ItemPriceListCreate.id}`);
    } catch (err) {
      enqueueSnackbar(
        `${t("button.create")}${t("inventory.price_list.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const updatePriceListHandler = async (data: IPriceList) => {
    try {
      const { id } = data;
      const formatData = priceListUpdateFormatter(data);
      const { ItemPriceListUpdate } = await update({
        uniqueInput: {
          id,
        },
        data: formatData,
      });
      const updatedData = priceListQueryFormatter({
        ...ItemPriceListUpdate,
      } as IPriceList);
      reset(updatedData);
      setDisabled(true);
      enqueueSnackbar(
        `${t("sentence.edit")}${t("inventory.price_list.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      enqueueSnackbar(
        `${t("sentence.edit")}${t("inventory.price_list.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const deletePriceListHandler = async () => {
    try {
      await deletePriceList({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`ลบ${t("inventory.price_list.index")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/inventory/price-list");
    } catch (err) {
      enqueueSnackbar(`ลบ${t("inventory.price_list.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const { ItemPriceListFindUnique } = data;
      const priceListData = priceListQueryFormatter({
        ...ItemPriceListFindUnique,
      } as IPriceList);
      reset(priceListData);
    }
  }, [data, isSuccess, reset]);

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deletePriceListHandler);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const { adjusted_item_list } = errors;
      if (adjusted_item_list) {
        if (Array.isArray(adjusted_item_list)) {
          adjusted_item_list.forEach((item) => {
            if (item) {
              const { adjusted_price } = item;
              if (adjusted_price) {
                enqueueSnackbar(adjusted_price.message, {
                  variant: "error",
                });
              }
            }
          });
        } else {
          enqueueSnackbar(adjusted_item_list.message, {
            variant: "error",
          });
        }
      }
    }
  }, [enqueueSnackbar, errors]);

  if (
    isSalesChannelLoading ||
    (id && (isLoading || isCreating || isUpdating || isDeleting))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        maxWidth={1040}
        mt={3}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              case "ลบ":
                openConfirmationHandler();
                break;

              default:
                break;
            }
          }}
          disabled={!id}
        />
      </Box>
      <CustomizedBox>
        <Typography color="primary.main" fontWeight={600} mb={2}>
          {`${t("inventory.price_list.index")}`}
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
            <ControlledTextField
              name="unique_id"
              control={control}
              label={t(`inventory.price_list.unique_id`)}
              error={Boolean(errors.unique_id)}
              helperText={errors.unique_id?.message?.toString()}
              disabled={Boolean(id)}
              viewMode={disabled}
              required
            />
          </Grid>
          {!Boolean(id) ? (
            <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
              <CustomizedTooltip
                title={`เรียก${t(`inventory.price_list.unique_id`)}`}
                enterNextDelay={200}
              >
                <IconButton
                  onClick={generateUniqueId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          {!disabled ? (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <RadioBox
                  onClick={() => {
                    setValue("type", "by_item");
                    resetField("adjusted_price");
                  }}
                  label={t("inventory.price_list.by_item")}
                  checked={watchType === "by_item"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <RadioBox
                  onClick={() => {
                    setValue("type", "all");
                    resetField("adjusted_item_list");
                  }}
                  label={t("inventory.price_list.all")}
                  checked={watchType === "all"}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <LabelInput
                  label={t("inventory.price_list.type")}
                  value={t(`inventory.price_list.${getValues("type")}`)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
            </>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              name="name"
              control={control}
              label={t(`inventory.price_list.name`)}
              error={Boolean(errors.name)}
              helperText={errors.name?.message?.toString()}
              disabled={disabled}
              viewMode={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {!disabled ? (
              <Controller
                control={control}
                name={"sales_channel_id"}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("inventory.price_list.sales_channel")}
                    options={salesChannelOptions}
                    onChange={(_, newValue) => {
                      field.onChange(newValue ? newValue.value : "");
                    }}
                    value={mapSalesChannelValue(field.value)}
                    error={Boolean(errors.sales_channel_id)}
                    helperText={errors.sales_channel_id?.message?.toString()}
                    required
                  />
                )}
              />
            ) : (
              <LabelInput
                label={t("inventory.price_list.sales_channel")}
                value={mapSalesChannelValue(getValues("sales_channel_id"))}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
          {watchType === "all" ? (
            <>
              {!disabled ? (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <RadioBox
                      onClick={() => {
                        setValue("is_increase", 1);
                        resetField("adjusted_item_list");
                      }}
                      label={t("inventory.price_list.increase")}
                      checked={watchIsIncrease === 1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <RadioBox
                      onClick={() => {
                        setValue("is_increase", 0);
                        resetField("adjusted_item_list");
                      }}
                      label={t("inventory.price_list.decrease")}
                      checked={watchIsIncrease === 0}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <LabelInput
                      label={t("inventory.price_list.is_increase")}
                      value={
                        getValues("is_increase")
                          ? t("inventory.price_list.increase")
                          : t("inventory.price_list.decrease")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
                </>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledNumberTextField
                  fullWidth
                  label="เปอร์เซ็นต์"
                  control={control}
                  name="adjusted_price"
                  endAdornmentMessage="%"
                  error={Boolean(errors.adjusted_price)}
                  helperText={errors.adjusted_price?.message?.toString()}
                  viewMode={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {!disabled ? (
                  <ControlledSelect
                    label={t("inventory.price_list.currency")}
                    options={currencyOptions}
                    control={control}
                    name="currency"
                    error={Boolean(errors.currency)}
                    helperText={errors.currency?.message?.toString()}
                  />
                ) : (
                  <LabelInput
                    label={t("inventory.price_list.currency")}
                    value={getValues("currency")}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
            </>
          )}
          <Grid item xs={12} sm={12}>
            <ControlledTextField
              placeholder={t("inventory.price_list.description")}
              name="description"
              control={control}
              error={Boolean(errors.description)}
              helperText={errors.description?.message?.toString()}
              multiline
              rows={4}
              label={t("inventory.price_list.description")}
              viewMode={disabled}
            />
          </Grid>

          {watchType === "by_item" && (
            <Grid item xs={12} sm={12}>
              <PriceItemList />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledSwitch
              control={control}
              name="is_active"
              label={t("status.active")}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledDatePicker
              control={control}
              label={t("inventory.price_list.start_date")}
              name="start_date"
              disabled={disabled}
              viewMode={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledDatePicker
              control={control}
              label={t("inventory.price_list.end_date")}
              name="end_date"
              disabled={disabled}
              viewMode={disabled}
              required
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <BottomNavbar>
        {!disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            {!!id && (
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={editCancelHandler}
                disabled={isCreating || isUpdating}
              />
            )}
            <CustomizedButton
              variant="contained"
              title={t("button.save")}
              onClick={handleSubmit(
                id ? updatePriceListHandler : createPriceListHandler
              )}
              disabled={isCreating || isUpdating}
            />
          </Stack>
        )}
      </BottomNavbar>
      <ConfirmationModal
        title={`ยันยืนการลบ${t("inventory.price_list.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </FormProvider>
  );
};

export default PriceListContainer;
