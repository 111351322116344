import { Box, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Drawer from "@mui/material/Drawer";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import { useMediaQuery } from "@mui/material";

export type SlideInPanelProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  width: string;
};

export const SlideInPanel = ({
  children,
  open,
  handleClose,
  title,
  width = "500px",
}: PropsWithChildren<SlideInPanelProps>) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : width,
          borderRadius: "10px 0px 0px 10px",
        },
      }}
      ModalProps={{ sx: { zIndex: "rightDrawer" } }}
    >
      <CustomizedScrollbar>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            p: "1.25rem",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: "10px",
              }}
            >
              {title}
              <CloseOutlinedIcon
                onClick={handleClose}
                fontSize="small"
                cursor="pointer"
              />
            </Box>
          </Box>
          {children}
        </Box>
      </CustomizedScrollbar>
    </Drawer>
  );
};
