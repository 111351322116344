import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";

import { Grid } from "@mui/material";

import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import LabelInput from "../../../UI/LabelInput";

const Sales = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const salesVatTypeOption = [
    { id: 0, value: "NO_VAT", label: "ไม่มี" },
    { id: 1, value: "VAT_0", label: "0 %" },
    { id: 2, value: "VAT_7", label: "7 %" },
  ];

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledNumberTextField
          control={control}
          name={"sale_price"}
          label={t("inventory.items.sale_price")}
          error={Boolean(errors?.sale_price)}
          helperText={errors?.sale_price?.message?.toString()}
          viewMode={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledNumberTextField
          control={control}
          name={"special_price"}
          label={t("inventory.items.special_price")}
          error={Boolean(errors?.special_price)}
          helperText={errors?.special_price?.message?.toString()}
          viewMode={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        {disabled ? (
          <LabelInput
            label={t("inventory.items.sale_vat_Type")}
            value={getValues("sale_vat_type")}
          />
        ) : (
          <Controller
            control={control}
            name={"sale_vat_type"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-sales-vat-text-field"
                label={t("inventory.items.sale_vat_Type")}
                options={salesVatTypeOption}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.label : "");
                }}
              />
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Sales;
