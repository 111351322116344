import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  marginBottom?: number;
  marginTop?: number;
  maxWidth?: boolean;
  sx?: SxProps;
};

const HeaderLayout = ({
  children,
  marginBottom,
  marginTop,
  maxWidth,
  sx,
}: Props) => {
  return (
    <Box
      sx={{
        mb: marginBottom ?? 3,
        mt: marginTop ?? 3,
        display: "flex",
        justifyContent: "space-between",
        maxWidth: maxWidth ? "1040px" : undefined,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default HeaderLayout;
