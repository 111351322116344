import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { IPaymentChannel } from "../../../types/Setting/Sales";
import CheckedBranchModal from "./CheckedBranchModal";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useCallback, useEffect, useState } from "react";
import { useModal } from "../../../hooks/use-modal";
import { IBranch } from "../../../types/Setting/Branch";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ControlledSSCreatable from "../../Controller/ControlledSSCreatable";
import {
  accountTypeOptions,
  bankNameOptions,
  paymentNameOptions,
} from "../../../utils/Global";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GeneralModelType,
  GeneralUniqueIdGenerateQuery,
  useGeneralUniqueIdGenerateQuery,
} from "../../../generated/general";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BranchSettingTable from "../../Table/Setting/Branch";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: IPaymentChannel) => void;
  onUpdateHandler: (data: IPaymentChannel) => void;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const paymentChannelSettingSchema: IPaymentChannel = {
  unique_id: "",
  account_no: "",
  bank_name: "",
  description: "",
  name: "",
  payment_name: "",
  account_type: "",
  branch_id_list: [],
  is_salable: false,
  is_purchasable: true,
};

const paymentChannelValidation = Yup.object().shape({
  payment_name: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  branch_id_list: Yup.array().min(1, "กรุณาเลือก"),
});

const PaymentChannelModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  data,
  isLoading,
  isSuccess,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const id = searchParams.get("id");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IPaymentChannel>({
    defaultValues: paymentChannelSettingSchema,
    resolver: yupResolver<any>(paymentChannelValidation),
  });

  const [branchIds, setBranchsIds] = useState<any[]>([]);
  const [branchIdsSnapshot, setBranchsIdsSnapshot] = useState<any[]>([]);

  const {
    modal: branchModal,
    openModalHandler: openBranchModal,
    closeModalHandler: closeBranchModal,
  } = useModal();

  const {
    modal: selectedModal,
    openModalHandler: openSelectedModal,
    closeModalHandler: closeSelectedModal,
  } = useModal();

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: GeneralModelType.PaymentChannel,
      },
      {
        enabled: false,
      }
    );

  const onClose = () => {
    reset(paymentChannelSettingSchema);
    setBranchsIds([]);
    setBranchsIdsSnapshot([]);
    closeModalHandler();
  };

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (open && !id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id, open]);

  useEffect(() => {
    if (isSuccess) {
      const { payment_type, ...otherData } = data;
      const is_salable = payment_type.includes("is_salable");
      const is_purchasable = payment_type.includes("is_purchasable");
      const formatData = {
        ...otherData,
        is_salable,
        is_purchasable,
      };
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  const selectedBranches = watch("branch_id_list");

  const finishBranchSelect = (data: IBranch[]) => {
    const branchIds: number[] = data.map((branch) => branch.id || 0);
    reset((prev) => ({
      ...prev,
      branch_id_list: branchIds,
    }));
    closeBranchModal();
  };

  useEffect(() => {
    if (data) {
      setBranchsIds(data.branch_id_list);
      setBranchsIdsSnapshot(data.branch_id_list);
    }
  }, [data]);

  return (
    <ModalUI
      isLoading={Boolean(id && isLoading)}
      title={
        (id ? t("sentence.edit") : t("button.add")) +
        t("setting.sales.payment_channel.index")
      }
      open={open}
      handleClose={onClose}
      maxWidth="md"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {id ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={() => {
                handleSubmit(onUpdateHandler)();
                if (isValid) {
                  onClose();
                }
              }}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={() => {
                handleSubmit(onAddHandler)();
                if (isValid) {
                  onClose();
                }
              }}
            />
          )}
        </Box>
      }
    >
      <Typography fontWeight={600} mb={1}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledTextField
            label={t("setting.unique_id")}
            control={control}
            name="unique_id"
            error={Boolean(errors?.unique_id)}
            helperText={
              errors?.unique_id && errors.unique_id.message?.toString()
            }
            disabled
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {/* <ControlledTextField
            label={t("setting.sales.payment_channel.payment_name")}
            control={control}
            name="payment_name"
            error={Boolean(errors?.payment_name)}
            helperText={
              errors?.payment_name && errors.payment_name.message?.toString()
            }
            required
          /> */}
          <ControlledSSCreatable
            label={t("setting.sales.payment_channel.payment_name")}
            control={control}
            defaultOptions={paymentNameOptions}
            documentType="payment_channel"
            name="payment_name"
            setValue={setValue}
            error={Boolean(errors?.payment_name)}
            helperText={
              errors?.payment_name && errors.payment_name.message?.toString()
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledTextField
            label={t("setting.sales.payment_channel.name")}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message?.toString()}
            required
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} mt={2} mb={1}>
        รายละเอียด
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          {/* <ControlledTextField
            label={t("setting.sales.payment_channel.bank_name")}
            control={control}
            name="bank_name"
            error={Boolean(errors?.bank_name)}
            helperText={
              errors?.bank_name && errors.bank_name.message?.toString()
            }
          /> */}
          <ControlledSSCreatable
            label={t("setting.sales.payment_channel.bank_name")}
            control={control}
            defaultOptions={bankNameOptions}
            documentType="payment_channel"
            name="bank_name"
            setValue={setValue}
            error={Boolean(errors?.bank_name)}
            helperText={
              errors?.bank_name && errors.bank_name.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledTextField
            label={t("setting.sales.payment_channel.account_no")}
            control={control}
            name="account_no"
            error={Boolean(errors?.account_no)}
            helperText={
              errors?.account_no && errors.account_no.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {/* <ControlledTextField
            label={t("setting.sales.payment_channel.account_type")}
            control={control}
            name="account_type"
            error={Boolean(errors?.account_type)}
            helperText={
              errors?.account_type && errors.account_type.message?.toString()
            }
          /> */}
          <ControlledSSCreatable
            label={t("setting.sales.payment_channel.account_type")}
            control={control}
            defaultOptions={accountTypeOptions}
            documentType="payment_channel"
            name="account_type"
            setValue={setValue}
            error={Boolean(errors?.account_type)}
            helperText={
              errors?.account_type && errors.account_type.message?.toString()
            }
          />
        </Grid>
      </Grid>
      <Box display={"flex"} gap={0.5} mt={2} alignItems={"center"}>
        <Typography
          color={errors.branch_id_list && "error.main"}
          fontWeight={600}
        >
          {t("branch.index")}
        </Typography>
        <Typography color="error.main" fontWeight={600}>
          *
        </Typography>
        {selectedBranches && selectedBranches.length === 0 ? (
          <CustomizedButton
            sx={{
              color: "#3F9FED",
            }}
            variant="text"
            title={"เลือกสาขา"}
            endIcon={<LaunchOutlinedIcon />}
            onClick={openBranchModal}
          />
        ) : (
          <Box display={"flex"} gap={0.5} ml={1}>
            <CustomizedStatus
              status={"finished"}
              text={`เลือก ${selectedBranches.length} สาขา`}
            />
            <IconButton
              onClick={openSelectedModal}
              sx={{
                color: "#3F9FED",
              }}
              size="small"
            >
              <EditIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {errors.branch_id_list && (
        <FormHelperText sx={{ color: "error.main" }}>
          {errors.branch_id_list.message?.toString()}
        </FormHelperText>
      )}
      <Typography fontWeight={600} fontSize={14} mt={1} mb={1}>
        คำอธิบาย
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            placeholder={t("setting.description")}
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={
              errors?.description && errors.description.message?.toString()
            }
            multiline
            minRows={1}
            maxRows={5}
          />
        </Grid>
      </Grid>
      <Box display={"flex"} gap={0.5} mt={2} alignItems={"center"}>
        <Typography fontWeight={600}>
          {t("setting.sales.payment_channel.payment_type")}
        </Typography>
        <Box display={"flex"} gap={0.25} alignItems={"center"}>
          <ErrorOutlineIcon sx={{ fontSize: 16, color: "#737373" }} />
          <Typography variant="caption" sx={{ color: "#737373" }}>
            หากไม่เลือกจะไม่ถูกนำไปใช้
          </Typography>
        </Box>
      </Box>
      <ControlledCheckbox
        control={control}
        name="is_salable"
        label={t("setting.sales.payment_channel.is_salable")}
      />
      <ControlledCheckbox
        control={control}
        name="is_purchasable"
        label={t("setting.sales.payment_channel.is_purchasable")}
        disabled
      />
      <ModalUI
        open={selectedModal}
        handleClose={closeSelectedModal}
        title="สาขา"
        maxWidth="lg"
        action={
          <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
            <CustomizedButton
              title={t("button.edit")}
              variant="contained"
              size="medium"
              onClick={() => {
                closeSelectedModal();
                openBranchModal();
              }}
            />
          </Box>
        }
      >
        <BranchSettingTable branch_id_list={selectedBranches} />
      </ModalUI>
      <CheckedBranchModal
        branchIds={branchIds}
        branchIdsSnapshot={branchIdsSnapshot}
        closeBranchTable={closeBranchModal}
        setBranchIds={setBranchsIds}
        setBranchIdsSnapshot={setBranchsIdsSnapshot}
        showSelectBranch={branchModal}
        finishBranchSelect={finishBranchSelect}
        rowSelection="multiple"
        keyName={"id"}
      />
    </ModalUI>
  );
};

export default PaymentChannelModal;
