import { gql } from "graphql-request";

export const GOODS_RECEIVE_REPORT_VIEW = gql`
  query GoodsReceiveReportView($aggridInput: AnyAggridInput) {
    GoodsReceiveReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        posted_date
        unique_id
        type
        reference_unique_id_list
        branch_id
        branch_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        remark
        aggrid_status
        scanned_date
        item_name
        po_reference_unique_id
        qty
        uom
        serial_no
        barcode
        source_bin_location_id
        source_bin_location_name
        scanned_status
        scanned_by_name
        item_remark
        lot_date
        manufactured_date
        expired_date
        created_by_name
        last_updated_date
        last_updated_by_name
      }
    }
  }
`;

export const GOODS_RETURN_REPORT_VIEW = gql`
  query GoodsReturnReportView($aggridInput: AnyAggridInput) {
    GoodsReturnReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        posted_date
        unique_id
        type
        reference_unique_id
        branch_id
        branch_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        remark
        aggrid_status
        scanned_date
        item_name
        document_qty
        posted_qty
        pending_qty
        qty
        uom
        serial_no
        barcode
        source_bin_location_id
        source_bin_location_name
        scanned_by_name
        item_remark
        lot_date
        manufactured_date
        expired_date
        created_by_name
        last_updated_date
        last_updated_by_name
      }
    }
  }
`;

export const GOODS_ISSUE_REPORT_VIEW = gql`
  query GoodsIssueReportView($aggridInput: AnyAggridInput) {
    GoodsIssueReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        posted_date
        unique_id
        type
        reference_unique_id
        branch_id
        branch_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        remark
        aggrid_status
        scanned_date
        item_name
        document_qty
        posted_qty
        pending_qty
        qty
        uom
        serial_no
        barcode
        source_bin_location_id
        source_bin_location_name
        scanned_by_name
        item_remark
        lot_date
        manufactured_date
        expired_date
        created_by_name
        last_updated_date
        last_updated_by_name
      }
    }
  }
`;

export const GOODS_TRANSFER_REPORT_VIEW = gql`
  query GoodsTransferReportView($aggridInput: AnyAggridInput) {
    GoodsTransferReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        posted_date
        unique_id
        source_branch_id
        source_branch_unique_id
        source_branch_name
        source_warehouse_id
        source_warehouse_unique_id
        source_warehouse_name
        destination_branch_id
        destination_branch_unique_id
        destination_branch_name
        destination_warehouse_id
        destination_warehouse_unique_id
        destination_warehouse_name
        remark
        aggrid_status
        source_scanned_date
        item_name
        qty
        uom
        serial_no
        barcode
        source_bin_location_id
        source_bin_location_name
        source_scanned_by_name
        destination_bin_location_id
        destination_bin_location_name
        destination_scanned_status
        destination_scanned_by_name
        destination_scanned_date
        item_remark
        lot_date
        manufactured_date
        expired_date
        created_by_name
        last_updated_date
        last_updated_by_name
      }
    }
  }
`;

export const GOODS_ADJUSTMENT_REPORT_VIEW = gql`
  query GoodsAdjustReportView($aggridInput: AnyAggridInput) {
    GoodsAdjustReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        posted_date
        unique_id
        branch_id
        branch_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        remark
        aggrid_status
        scanned_date
        item_name
        stock_qty
        qty
        uom
        serial_no
        barcode
        source_bin_location_id
        source_bin_location_name
        scanned_by_name
        item_remark
        lot_date
        manufactured_date
        expired_date
        created_by_name
        last_updated_date
        last_updated_by_name
      }
    }
  }
`;

export const UNSCANNED_SN_REPORT_VIEW = gql`
  query UnscannedSNReportView($aggridInput: AnyAggridInput) {
    UnscannedSNReportView(aggridInput: $aggridInput) {
      count
      results {
        id
        created_date
        serial_no
        main_category
        item_name
        qty
        uom
        type
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        barcode
        lot_date
        manufactured_date
        expired_date
        reference_unique_id
        aggrid_status
        created_by_name
        last_updated_date
        last_updated_by_name
      }
    }
  }
`;

export const CURRENT_STOCK_BY_SN_REPORT_VIEW = gql`
  query CurrentStockBySNReportView($aggridInput: AnyAggridInput) {
    CurrentStockBySNReportView(aggridInput: $aggridInput) {
      count
      results {
        sku_name
        item_unique_id
        item_name
        item_main_category
        serial_no
        barcode
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_id
        bin_name
        stock_qty
        lot_date
        manufactured_date
        expired_date
      }
    }
  }
`;

export const NO_TRACE_ENTRY_REPORT_VIEW = gql`
  query NoTraceEntryReportView($aggridInput: AnyAggridInput) {
    NoTraceEntryReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        unique_id
        name
        description
        purchase_ordered_qty
        sale_committed_qty
        stock_qty
        available_qty
        category_group
        main_category
        sub_category
        tag_list
        is_active
        created_by_name
      }
    }
  }
`;

export const TRACE_ENTRY_REPORT_VIEW = gql`
  query TraceEntryReportView($aggridInput: AnyAggridInput) {
    TraceEntryReportView(aggridInput: $aggridInput) {
      count
      results {
        created_date
        posted_date
        source_branch_id
        source_branch_unique_id
        source_branch_name
        source_warehouse_id
        source_warehouse_unique_id
        source_warehouse_name
        source_bin_location_id
        source_bin_location_name
        destination_branch_id
        destination_branch_unique_id
        destination_branch_name
        destination_warehouse_id
        destination_warehouse_unique_id
        destination_warehouse_name
        destination_bin_location_id
        destination_bin_location_name
        item_unique_id
        sku_name
        item_name
        serial_no
        qty
        uom
        type
        reference_unique_id
        created_by_name
      }
    }
  }
`;

export const CURRENT_STOCK_BY_BRANCH_REPORT_VIEW = gql`
  query CurrentStockByBranchReportView($aggridInput: AnyAggridInput) {
    CurrentStockByBranchReportView(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        available_qty
        stock_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
    }
  }
`;

export const CURRENT_STOCK_BY_WAREHOUSE_REPORT_VIEW = gql`
  query CurrentStockByWarehouseReportView($aggridInput: AnyAggridInput) {
    CurrentStockByWarehouseReportView(aggridInput: $aggridInput) {
      results {
        unique_id
        sku_name
        name
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        available_qty
        stock_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
        min_purchase_request_qty
        min_stock_qty
        max_stock_qty
        reorder_point
        status
      }
      count
    }
  }
`;

export const CURRENT_STOCK_BY_LOCATION_REPORT_VIEW = gql`
  query CurrentStockByLocationReportView($aggridInput: AnyAggridInput) {
    CurrentStockByLocationReportView(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_location_id
        bin_name
        available_qty
        stock_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
    }
  }
`;

export const CURRENT_STOCK_BY_LOT_REPORT_VIEW = gql`
  query CurrentStockByLotReportView($aggridInput: AnyAggridInput) {
    CurrentStockByLotReportView(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_location_id
        bin_location_name
        serial_no
        barcode
        available_qty
        stock_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
        lot_date
        manufactured_date
        expired_date
      }
    }
  }
`;
