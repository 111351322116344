import React, { createContext, useState, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export const DisableContext = createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => false]);

export const DisableContextProvider = ({ children }: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  return (
    <DisableContext.Provider value={[disabled, setDisabled]}>
      {children}
    </DisableContext.Provider>
  );
};

export default DisableContext;
