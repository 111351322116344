import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { useDisable } from "../../../../hooks/use-disable";

import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import LabelInput from "../../../UI/LabelInput";

const Purchase = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const purchaseVatTypeOption = [
    { id: 0, value: "NO_VAT", label: "ไม่มี" },
    { id: 1, value: "VAT_0", label: "0 %" },
    { id: 2, value: "VAT_7", label: "7 %" },
  ];

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledNumberTextField
          control={control}
          name={"purchase_standard_price"}
          label={t("inventory.items.purchasePrice")}
          error={Boolean(errors?.purchase_standard_price)}
          helperText={errors?.purchase_standard_price?.message?.toString()}
          viewMode={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"min_purchase_request_qty"}
          label={t("inventory.items.minimumOrder")}
          error={Boolean(errors?.min_purchase_request_qty)}
          helperText={errors?.min_purchase_request_qty?.message?.toString()}
          viewMode={disabled}
          type="number"
          onChange={(e, field) => field.onChange(parseInt(e.target.value))}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        {disabled ? (
          <LabelInput
            label={t("inventory.items.purchaseVatType")}
            value={getValues("purchase_vat_type")}
          />
        ) : (
          <Controller
            control={control}
            name={"purchase_vat_type"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-purchase-vat-text-field"
                label={t("inventory.items.purchaseVatType")}
                options={purchaseVatTypeOption}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.label : "");
                }}
              />
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Purchase;
