import { useFieldArray, useFormContext } from "react-hook-form";
import { IContact, IContactPerson } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import PersonCard from "../../UI/PersonCard";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid } from "@mui/material";
import { useModal } from "../../../hooks/use-modal";
import PersonModal from "../../UI/Modal/PersonModal";
import { useDisable } from "../../../hooks/use-disable";

const ContactPersonList = () => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "contact_person_list",
  });

  const [disabled] = useDisable();

  const addContactPersonHandler = (data: IContactPerson) => {
    append(data);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  return (
    <>
      <Box my={2}>
        <Grid container spacing={1.5}>
          {fields.map((person, index) => (
            <PersonCard
              key={person.id}
              person={person}
              index={index}
              remove={remove}
              update={update}
            />
          ))}
        </Grid>
      </Box>
      {!disabled && (
        <CustomizedButton
          variant="outlined"
          title={`${t("button.add")}${t("contact.contact_person.index")}`}
          onClick={openModalHandler}
        />
      )}
      <PersonModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={addContactPersonHandler}
      />
    </>
  );
};

export default ContactPersonList;
