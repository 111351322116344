import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDepositListPdfHeader } from "./columnDef";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import { CustomerDepositItem } from "../../../../generated/sales";

type Props = {
  itemList: CustomerDepositItem[];
  startNumber?: number;
  lastTable?: boolean;
  totalAmount?: number;
  isLastPage?: boolean;
  hasItems?: boolean;
};

const DepositListPdfTable = ({
  itemList,
  startNumber,
  isLastPage,
  lastTable,
  totalAmount,
  hasItems,
}: Props) => {
  const headers = useDepositListPdfHeader();

  const tablePadding = {
    px: 1,
    py: 1,
  };

  const fontSize = 10.66;

  return (
    <TableContainer
      sx={{
        my: 1,
      }}
    >
      <Table aria-label="simple table">
        {(!isLastPage || hasItems) && (
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align}
                  key={index}
                  sx={tablePadding}
                  width={header.width}
                >
                  <Typography fontSize={fontSize} fontWeight={600}>
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {itemList.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell align={headers[0]?.align}>
                <Typography fontSize={fontSize}>
                  {startNumber ? startNumber + index : index + 1}
                </Typography>
              </TableCell>
              <TableCell align={headers[1]?.align}>
                <Typography fontSize={fontSize} color={"#737373"}>
                  {item.name}
                </Typography>
              </TableCell>
              <TableCell align={headers[2]?.align}>
                <Typography
                  fontSize={fontSize}
                  sx={{ wordBreak: "break-word" }}
                >
                  {item.pre_vat_amount
                    ? numberFormatter(item.pre_vat_amount)
                    : 0}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {lastTable && (
        <Table>
          <TableHead>
            <TableRow
              sx={{
                height: "24px",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                backgroundColor: (theme) => theme.palette.primary.light,
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <TableCell
                align="right"
                sx={{
                  py: 0.5,
                  pr: 7,
                  wordBreak: "break-word",
                }}
              >
                <Typography
                  marginLeft={0.5}
                  fontSize={fontSize}
                  fontWeight={600}
                  color={"primary.main"}
                >
                  รวม
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{ py: 0.5, pr: 5, wordBreak: "break-word" }}
              >
                <Typography
                  marginRight={2}
                  fontWeight={600}
                  fontSize={fontSize}
                  color={"primary.main"}
                >
                  {numberFormatter(totalAmount)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
    </TableContainer>
  );
};

export default DepositListPdfTable;
