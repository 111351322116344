import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import SalesByBranchReportTable from "../../../components/Table/Sales/Report/SalesByBranchReportTable";
import { useSalesReport } from "../../../hooks/Sales/use-sales-report";
import { SalesExportType } from "../../../generated/sales";

const SalesByBranchReport = () => {
  const { t } = useTranslation();

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isLoading } = useSalesReport(
    gridRef,
    SalesExportType.SalesItemByBranch
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
      to: "/sales/report",
    },
    {
      name: t("sales.report.sales_by_branch"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("sales.report.sales_by_branch")}
        </Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isLoading}
        />
      </HeaderLayout>
      <SalesByBranchReportTable gridRef={gridRef} />
    </>
  );
};

export default SalesByBranchReport;
