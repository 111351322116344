import Button, { ButtonProps } from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

type ExtendedButtonProps = ButtonProps & {
  testId?: string;
  addIcon?: boolean;
};

const CustomizedButton = ({
  sx,
  variant,
  title,
  onClick,
  startIcon,
  type,
  fullWidth,
  color,
  size,
  disabled,
  id,
  testId,
  endIcon,
  addIcon,
}: ExtendedButtonProps) => {
  return (
    <Button
      id={id}
      data-test-id={testId}
      sx={sx}
      type={type || "button"}
      size={size || "small"}
      variant={variant}
      onClick={onClick}
      startIcon={addIcon ? <AddIcon /> : startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      style={{ lineHeight: "normal" }}
      color={color}
      disabled={disabled}
      formNoValidate
    >
      {title}
    </Button>
  );
};

export default CustomizedButton;
