import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "../../types/global";

const getDefaultHeader = (
  isOpenDestinationScanned: boolean
): IHeaderTable[] => {
  return [
    {
      thaiLabel: "ลำดับ",
      width: 75,
    },
    {
      thaiLabel: "วันที่สแกน (ต้นทาง)",
      width: 120,
    },
    {
      thaiLabel: "รูป",
      width: 100,
    },
    {
      thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
      width: 150,
      align: "left",
    },
    {
      thaiLabel: "จำนวน",
      width: 100,
    },
    {
      thaiLabel: "จำนวนที่โอนย้าย",
      width: 120,
    },
    {
      thaiLabel: "หน่วย",
      width: 100,
    },
    {
      thaiLabel: "Barcode/SN",
      width: 150,
    },
    {
      thaiLabel: "สถานที่ต้นทาง",
      width: 200,
    },
    {
      thaiLabel: "ผู้สแกนต้นทาง",
      width: 100,
    },
    ...(isOpenDestinationScanned
      ? [
          {
            thaiLabel: "สถานที่ปลายทาง",
            width: 200,
          },
          {
            thaiLabel: "",
            width: 100,
          },
          {
            thaiLabel: "ผู้สแกนปลายทาง",
            width: 150,
          },
          {
            thaiLabel: "วันที่สแกน (ปลายทาง)",
            width: 200,
          },
        ]
      : []),
    {
      thaiLabel: "Barcode",
      width: 300,
      align: "left",
    },
    {
      thaiLabel: "หมายเหตุ",
      width: 150,
    },
    {
      thaiLabel: "LOT",
      width: 100,
    },
    {
      thaiLabel: "วันที่ผลิต",
      width: 100,
    },
    {
      thaiLabel: "วันที่หมดอายุ",
      width: 100,
    },
  ];
};

const headerPdf: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 20,
  },
  // {
  //   thaiLabel: "รูป",
  //   width: 50,
  // },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 105,
  },
  {
    thaiLabel: "จำนวนโอนย้าย - หน่วย",
    width: 115,
  },
  {
    thaiLabel: "Batch/SN",
    width: 110,
  },
  {
    thaiLabel: "สถานที่ต้นทาง",
    width: 100,
  },
  {
    thaiLabel: "สถานที่ปลายทาง",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 200,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 50,
  },
  {
    thaiLabel: "LOT",
    width: 100,
  },
];

export const useGoodsTransferItemList = (
  isNotDraft: boolean,
  isOpenDestinationScanned: boolean
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  const selectedHeader = useMemo(() => {
    return getDefaultHeader(isOpenDestinationScanned);
  }, [isOpenDestinationScanned]);

  useEffect(() => {
    if (isNotDraft) {
      setHeaders([...selectedHeader]);
    } else {
      setHeaders([
        ...selectedHeader,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    }
  }, [isNotDraft, selectedHeader]);

  return headers;
};

export const useGoodsTransferItemListPdf = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders(headerPdf);
  }, []);

  return headers;
};
