import { IOptionsCreatable, ISelectOption } from "../types/global";

const DEFAULT_FLOAT_DECIMAL_DIGITS = 2;

export const contactChannelOptions: ISelectOption[] = [
  {
    label: "เบอร์โทรศัพท์",
    value: "phone",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "แฟกซ์",
    value: "fax",
  },
  {
    label: "Line",
    value: "line",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "มือถือ",
    value: "mobile_phone",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

export const countryOptions: ISelectOption[] = [
  {
    label: "ไทย",
    value: "thailand",
  },
  {
    label: "ประเทศไทย",
    value: "ประเทศไทย",
  },
];

export const phoneRegExp =
  /^(?:(\([0-9]{2,3}\)|[0-9]{2,3})?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))?$/;

export const faxReqExp =
  /^(?:(\([0-9]{2}\)|[0-9]{2})?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))?$/;

export const characterRegExp = /^[\u0E00-\u0E7Fa-zA-Z\d]+$/;

export const errorMessageFormatter = (error: any, type?: string) => {
  const formatError = JSON.stringify(error);
  if (formatError.includes("Unique constraint failed")) {
    if (
      formatError.includes(
        "Unique constraint failed on the fields: (`unique_id`)"
      )
    ) {
      return type === "item"
        ? "รหัสสินค้าภายในนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่"
        : "มีเลขที่เอกสารอยู่ในระบบแล้ว กรุณาระบุใหม่";
    }
    if (
      formatError.includes(
        "Unique constraint failed on the fields: (`seller_unique_id`)"
      )
    ) {
      return type === "item"
        ? "รหัสนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่"
        : "รหัสสินค้าภายในนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`name`)")
    ) {
      return "ชื่อนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes(
        "Unique constraint failed on the fields: (`barcode`)"
      )
    ) {
      return "Barcode นี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`phone`)")
    ) {
      return "เบอร์โทรศัพท์นี้มีในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`email`)")
    ) {
      return "อีเมลนี้มีในระบบแล้ว กรุณาระบุใหม่";
    }
  } else if (formatError.includes("is in use")) {
    return "ไม่สามารถแก้ไขหรือลบได้เนื่องจากถูกใช้งานอยู่";
  } else if (formatError.includes("HQ already exists")) {
    return "มีสำนักงานใหญ่อยู่แล้ว กรุณาเลือกใหม่";
  } else if (formatError.includes("Cannot delete HQ.")) {
    return "ไม่สามารถแก้ไขหรือลบได้เนื่องจากเป็นสำนักงานใหญ่";
  } else if (formatError.includes("Department is being used.")) {
    return "ไม่สามารถแก้ไขหรือลบได้เนื่องจากแผนกนี้ถูกนำไปใช้งานแล้ว";
  } else if (
    formatError.includes("เกินจำนวนสั่งซื้อ") ||
    formatError.includes("เกินจำนวนส่งคืน")
  ) {
    return error.response?.errors?.[0].message.split(" ")[0] || "";
  }
  return;
};

export const parseFloatFunction = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  field: any
) => {
  const value = e.target.value;
  return field.onChange(parseFloat(value));
};

export const checkIsNotDraftOrEmptyStatus = (status: string) =>
  !["", "draft", "not_approved"].includes(status);

export const entryTypeEngToThai = (data: string) => {
  switch (data) {
    case "goods_receive":
      return "นำเข้า";
    case "goods_return":
      return "รับคืน";
    case "goods_issue":
      return "นำออก";
    case "goods_transfer":
      return "โอนย้าย";
    case "goods_adjustment":
    case "goods_adjust":
      return "ปรับปรุง";
    default:
      return "";
  }
};

export const formatString = (str: any) => {
  return str?.toString().trim();
};

export const formatStringArray = (
  arr: string[] | string,
  filterInvalid?: boolean
) => {
  if (typeof arr === "string") {
    return [arr];
  }
  const result = Array.isArray(arr)
    ? arr?.map((item) => item?.toString().trim())
    : [];
  if (filterInvalid && result?.length > 0) {
    return result?.filter((item) => item);
  }
  return result;
};

export const formatStringToArray = (str: string, denominator?: string) => {
  if (str) {
    return str?.split(denominator || ",")?.map((item) => item?.trim()) || [];
  }
  return [];
};

export const formatPriceTwoDecimal = (input: any) => {
  return typeof input === "string"
    ? Math.round((parseFloat(input.replace(",", "")) + Number.EPSILON) * 100) /
        100
    : Math.round((input + Number.EPSILON) * 100) / 100;
};

export const formatFloat = (input: any, points?: number) => {
  return !isNaN(parseFloat(input))
    ? parseFloat(
        parseFloat(input).toFixed(points || DEFAULT_FLOAT_DECIMAL_DIGITS)
      )
    : undefined;
};

export const formatInt = (input: any) => {
  return !isNaN(parseInt(input)) ? parseInt(input) : undefined;
};

export const formatNumber = (number: number) => {
  return (Math.round((number + Number.EPSILON) * 100) / 100)?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );
};

export const branchInfoFromSessionStorage = () => {
  const branch_id = sessionStorage.getItem("branch-id");
  const is_hq = sessionStorage.getItem("is_hq");
  return { branch_id, is_hq };
};

export const paymentNameOptions: IOptionsCreatable[] = [
  {
    label: "เงินสด",
    value: "เงินสด",
  },
  {
    label: "บัญชีธนาคาร",
    value: "บัญชีธนาคาร",
  },
  {
    label: "เช็ค",
    value: "เช็ค",
  },
];

export const bankNameOptions: IOptionsCreatable[] = [
  {
    label: "KBANK",
    value: "KBANK",
  },
  {
    label: "SCB",
    value: "SCB",
  },
  {
    label: "BBL",
    value: "BBL",
  },
  {
    label: "Krungsri",
    value: "Krungsri",
  },
  {
    label: "KTB",
    value: "KTB",
  },
  {
    label: "LH BANK",
    value: "LH BANK",
  },
  {
    label: "TMBThanachart",
    value: "TMBThanachart",
  },
  {
    label: "KKP",
    value: "KKP",
  },
  {
    label: "MHCB",
    value: "MHCB",
  },
];

export const accountTypeOptions: IOptionsCreatable[] = [
  {
    label: "ออมทรัพย์",
    value: "ออมทรัพย์",
  },
  {
    label: "ฝากประจำ",
    value: "ฝากประจำ",
  },
  {
    label: "กระแสเงินสด",
    value: "กระแสเงินสด",
  },
  {
    label: "อื่นๆ",
    value: "อื่นๆ",
  },
];

export const formatCompanyName = (type: string, name: string) => {
  switch (type) {
    case "company":
      return `บริษัท ${name} จำกัด`;
    case "public_limited":
      return `บริษัท ${name} จำกัด (มหาชน)`;
    case "limited_partnership":
      return `ห้างหุ้นส่วนจำกัด ${name}`;
    case "registered_ordinary_partnership":
      return `ห้างหุ้นส่วน ${name}`;
    case "association":
      return `สมาคม${name}`;
    default:
      return name;
  }
};
