import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";

import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import { CustomizedBox } from "../../../Custom/CustomizedBox";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { IHeaderTable } from "../../../../types/global";
import { IUomConversion } from "../../../../types/Inventory/item";
import { useUnitOptions } from "../../../../hooks/use-inventory-setting-option";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

const Conversion = () => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  const [disabled] = useDisable();
  const {
    control,
    getValues,
    // formState: { errors },
  } = useFormContext();

  const { allUnit, renderOption } = useUnitOptions();

  const watchBaseUom = useWatch({ control, name: "stock_uom_id" });
  const watchConversion = useWatch({ control, name: "uom_conversion_list" });

  const { fields, replace, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "uom_conversion_list", // unique name for your Field Array
  });

  const allUomConversion =
    watchConversion?.map((uom: IUomConversion) => uom.target_uom_id) ?? [];

  useEffect(() => {
    const newValue = getValues("uom_conversion_list") || [];
    replace(newValue);
  }, [getValues, replace]);

  useEffect(() => {
    if (disabled)
      setHeaders([
        { label: t("inventory.items.conversion.quantity"), width: 150 },
        { label: t("inventory.items.conversion.baseUom"), width: 150 },
        {
          label: t("inventory.items.conversion.quantityConversion"),
          width: 150,
        },
        {
          label: t("inventory.items.conversion.conversionUom"),
          width: 150,
        },
      ]);
    else
      setHeaders([
        { label: t("inventory.items.conversion.quantity"), width: 130 },
        { label: t("inventory.items.conversion.baseUom"), width: 130 },
        {
          label: t("inventory.items.conversion.quantityConversion"),
          width: 130,
        },
        {
          label: t("inventory.items.conversion.conversionUom"),
          width: 130,
        },
        { label: "", width: 50 },
      ]);
  }, [disabled, t]);

  const handleAppendData = () => {
    const filterUom = allUnit
      .filter((unit) => unit.id !== watchBaseUom)
      .filter((unit) => !allUomConversion.includes(unit.id));

    append({
      base_uom_rate: 1,
      target_uom_rate: 1,
      target_uom_id: (filterUom && filterUom[0].id) || 0,
    });
  };

  const handleRemoveAttribute = (index: number) => {
    const uomValues = getValues("uom_conversion_list");
    const newValues =
      uomValues?.filter((uom: any, uomInd: number) => index !== uomInd) || [];
    replace(newValues);
  };

  return (
    <>
      <Typography fontWeight="bold">
        {t("inventory.items.conversion.index")}
      </Typography>
      <CustomizedBox sx={{ my: disabled ? 0 : 1 }} bgcolor="primary.light">
        <TableContainer>
          <Table
            sx={{ minWidth: 100, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                bgcolor: "primary.light",
              }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell align="center" key={index} width={header.width}>
                    <Box minWidth={header.width}>
                      <Typography
                        textAlign="center"
                        fontSize={14}
                        fontWeight={700}
                      >
                        {header.label}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((row, index: number) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    <Box sx={{ mt: "2px" }}>
                      <ControlledNumberTextField
                        name={`uom_conversion_list[${index}].base_uom_rate`}
                        control={control}
                        disabled={disabled}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ mt: "2px" }} alignItems={"center"}>
                      <CustomizedTextField
                        disabled
                        value={renderOption(watchBaseUom)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ mt: "2px" }}>
                      <ControlledNumberTextField
                        name={`uom_conversion_list[${index}].target_uom_rate`}
                        control={control}
                        // error={Boolean(
                        //   errors?.uom_conversion_list?.[index]?.target_uom_rate
                        // )}
                        // helperText={
                        //   errors?.uom_conversion_list?.[index]?.target_uom_rate
                        //     ?.message
                        // }
                        disabled={disabled}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ mt: "2px" }}>
                      <Controller
                        control={control}
                        name={`uom_conversion_list[${index}].target_uom_id`}
                        render={({ field }) => (
                          <CustomizedComboBox
                            testId={`item-conversion-target-uom-field-${index}`}
                            options={allUnit
                              ?.map((unit) => {
                                return {
                                  id: unit.id,
                                  value: unit.id,
                                  label: unit.name,
                                  name: unit.name,
                                };
                              })
                              .filter((uom) => uom.id !== watchBaseUom)
                              .filter(
                                (uom) => !allUomConversion.includes(uom.name)
                              )}
                            {...field}
                            disabled={disabled}
                            value={renderOption(field.value)}
                            onChange={(_, newValue) => {
                              field.onChange(newValue ? newValue.id : "");
                            }}
                          />
                        )}
                      />
                    </Box>
                  </TableCell>
                  {!disabled && (
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                        onClick={() => {
                          handleRemoveAttribute(index);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomizedBox>
      {!disabled && (
        <CustomizedButton
          testId="item-add-conversion-btn"
          color="secondary"
          onClick={handleAppendData}
          title={t("button.add") + t("inventory.items.conversion.index")}
          variant="outlined"
          sx={{ mt: 1, mr: 2 }}
          addIcon
          // disabled={!watchBaseUom}
        />
      )}
    </>
  );
};

export default Conversion;
