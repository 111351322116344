import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Fragment, useCallback, useEffect, useState } from "react";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IBarcodeForm } from "../../../../types/Inventory";
import { IGoodsTransfer } from "../../../../types/Inventory/GoodsTransfer";
import { useDisable } from "../../../../hooks/use-disable";
import { useTranslation } from "react-i18next";
import Confirmation from "../../../UI/Confirmation";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { useGoodsTransferItemList } from "../../../../hooks/Inventory/use-transfer-item-list";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ClearIcon from "@mui/icons-material/Clear";
import { useStateContext } from "../../../../contexts/auth-context";
import {
  ItemSkuQtysQuery,
  TraceEntriesFindManyAggridQuery,
  useItemSkuQtysQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import { enqueueSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { goodsTransferStampDestinationFormatter } from "../../../../utils/Formatter/Inventory/GoodsTransfer";
import { checkIsNotDraftOrEmptyStatus } from "../../../../utils/Global";
import {
  BinLocationsFindAllQuery,
  Tracability,
  useBinLocationsFindAllQuery,
} from "../../../../generated/general";
import { ISelectOption } from "../../../../types/global";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useBarcodeSubmitHandler } from "../../../../hooks/Inventory/use-transfer-handle-scan-barcode";
import GoodsTransferTotalPostedQuantityCell from "./totalPostedQuantityCell";
import GoodsTransferSerialList from "./SerialList";

type Props = {
  fields: FieldArrayWithId<IGoodsTransfer, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsTransfer, "trace_entry_list">;
  append: UseFieldArrayAppend<IGoodsTransfer, "trace_entry_list">;
};

const GoodsTransferItemList = ({ fields, remove, append, update }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const graphQLClientWithHeaderItem = createGraphQLClientWithMiddleware("wms");
  const graphQLClientWithHeaderItemGeneral =
    createGraphQLClientWithMiddleware("general");

  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const [binLocationOptions, setBinLocationOptions] = useState<ISelectOption[]>(
    []
  );

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext<IGoodsTransfer>();

  const watchSourceWarehouseId = useWatch({
    control,
    name: "source_warehouse_id",
  });

  const destinationWarehouseId = getValues("destination_warehouse_id");
  const status = getValues("aggrid_status");

  const isOpenDestinationScanned = [
    "wait_transfer",
    "in_transit",
    "finished",
  ].includes(status || "");

  const isFinished = ["finished", "cancelled"].includes(status || "");
  const isNotDraft = checkIsNotDraftOrEmptyStatus(status || "");
  const headers = useGoodsTransferItemList(
    isNotDraft,
    isOpenDestinationScanned
  );

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const consignment_bin_location_id = watch("consignment_bin_location_id");

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
    setValue: setBarcodeValue,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      destination_bin_location_id: undefined,
    },
  });

  useEffect(() => {
    if (consignment_bin_location_id) {
      setBarcodeValue(
        "destination_bin_location_id",
        consignment_bin_location_id
      );
    }
  }, [consignment_bin_location_id, setBarcodeValue]);

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter: watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals: watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const { refetch: refetchBinAll } =
    useBinLocationsFindAllQuery<BinLocationsFindAllQuery>(
      graphQLClientWithHeaderItemGeneral
    );

  const useBarcodeSubmitDestinationHandler = () => {
    const barcode = watchBarcode("barcode").trim();

    const barcodeSubmit = useCallback(() => {
      const selectedNormalTrace = fields.find(
        (trace) => trace.barcode === barcode
      );

      if (selectedNormalTrace) {
        const destination_bin_location_id = Number(
          watchBarcode("destination_bin_location_id")
        );

        if (
          selectedNormalTrace?.source_bin_location_id ===
          destination_bin_location_id
        ) {
          enqueueSnackbar(`กรุณาเลือกสถานที่ปลายทางที่ต่างจากสถานที่ต้นทาง`, {
            variant: "error",
            style: { whiteSpace: "pre-line" },
          });
        } else {
          const index = fields.findIndex((trace) => trace.barcode === barcode);

          const selectedBinLocation = binLocationOptions.find(
            (bin) => bin.value === destination_bin_location_id
          );

          const formatted = goodsTransferStampDestinationFormatter(
            selectedBinLocation,
            authUser
          );

          const { id, ...otherFields } = fields[index];

          update(index, {
            ...otherFields,
            ...formatted,
          });
        }
      } else {
        let nestedIndex: number = -1;

        fields.forEach((trace, index) => {
          if (trace?.serial_list?.length) {
            const updatedSerialList = trace.serial_list.map((serial) => {
              if (serial.barcode === barcode) {
                nestedIndex = index;

                if (
                  watchBarcode("destination_bin_location_id") ===
                  serial.source_bin_location_id
                ) {
                  enqueueSnackbar(
                    `กรุณาเลือกสถานที่ปลายทางที่ต่างจากสถานที่ต้นทาง`,
                    {
                      variant: "error",
                      style: { whiteSpace: "pre-line" },
                    }
                  );
                } else {
                  const selectedBinLocation = binLocationOptions.find(
                    (bin) =>
                      bin.value ===
                      Number(watchBarcode("destination_bin_location_id"))
                  );
                  const formatted = goodsTransferStampDestinationFormatter(
                    selectedBinLocation,
                    authUser
                  );
                  return {
                    ...serial,
                    ...formatted,
                  };
                }
              }

              return serial;
            });

            if (nestedIndex === index) {
              update(nestedIndex, {
                ...fields[nestedIndex],
                serial_list: updatedSerialList,
              });
            }
          }
        });

        if (nestedIndex < 0) {
          enqueueSnackbar(`QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม`, {
            variant: "error",
            style: { whiteSpace: "pre-line" },
          });
        }
      }

      resetBarcode((prev) => ({
        ...prev,
        barcode: "",
      }));
    }, [barcode]);

    return barcodeSubmit;
  };

  const handle = useBarcodeSubmitDestinationHandler();

  useEffect(() => {
    const fetchData = async () => {
      if (isOpenDestinationScanned) {
        const { data } = await refetchBinAll();
        const binLocations = data?.BinLocationsFindAll?.filter(
          (bin) =>
            bin.warehouse_level_3.warehouse_level_2.warehouse_level_1.warehouse
              .id === Number(destinationWarehouseId ?? 0)
        ).map((bin) => ({ label: bin.name, value: bin.id }));

        setBinLocationOptions(binLocations || []);
      }
    };

    fetchData();
  }, [isOpenDestinationScanned, refetchBinAll, destinationWarehouseId, fields]);

  const handleBarcodeSummit = useBarcodeSubmitHandler({
    append,
    fields,
    update,
    refetchTraceEntries: refetch,
    refetchSkuQty: refetchSkuQty,
    barcode: watchBarcode("barcode").trim(),
  });

  const onBarcodeSubmitHandler = async () => {
    await handleBarcodeSummit();

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  const tablePadding = {
    px: 1,
    py: 1,
  };

  return (
    <>
      {status !== "finished" && status !== "cancelled" && (
        <CustomizedBox margin={0}>
          <Typography fontWeight={600} color={"primary.main"}>
            {t("inventory.goods_transfer.detail.scan_barcode")}
          </Typography>
          <Grid container spacing={1.5} mt={1}>
            {isOpenDestinationScanned ? (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <ControlledSelect
                  control={barcodeControl}
                  name="destination_bin_location_id"
                  label={t("inventory.chooseLocation")}
                  options={binLocationOptions}
                  disabled={Boolean(consignment_bin_location_id)}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    isOpenDestinationScanned
                      ? handle()
                      : onBarcodeSubmitHandler();
                  }
                }}
                disabled={
                  (!isOpenDestinationScanned &&
                    (isNotDraft || !watchSourceWarehouseId)) ||
                  (isOpenDestinationScanned &&
                    !watchBarcode("destination_bin_location_id"))
                }
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
      <CustomizedBox
        maxWidth={1650}
        margin={
          status !== "finished" && status !== "cancelled" ? "1.5rem 0" : 0
        }
      >
        <Typography color="primary.main" fontWeight={600} mb={2}>
          {t("inventory.goods_transfer.detail.table.header")}
        </Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell align="center" sx={tablePadding}>
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      <Typography fontSize={14}>
                        {formatDateTimeNoAMPM(trace.posted_date)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell sx={tablePadding}>
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      {trace.tracability === Tracability.Normal ? (
                        <Typography fontSize={14}>
                          {trace.stock_qty || 0}
                        </Typography>
                      ) : (
                        <GoodsTransferTotalPostedQuantityCell
                          nestedIndex={index}
                          keyTrace="stock_qty"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      {trace.tracability === Tracability.Normal ? (
                        <>
                          {disabled ? (
                            <Typography fontSize={14}>{trace.qty}</Typography>
                          ) : (
                            <ControlledTextField
                              type="number"
                              control={control}
                              name={`trace_entry_list.${index}.qty`}
                              error={Boolean(
                                errors?.trace_entry_list?.[index]?.qty
                              )}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              FormHelperTextProps={{
                                style: { fontSize: "10px" },
                              }}
                              viewMode={isNotDraft}
                            />
                          )}
                        </>
                      ) : (
                        <GoodsTransferTotalPostedQuantityCell
                          nestedIndex={index}
                          keyTrace="qty"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontSize={14}>
                        {trace.uom?.name_th}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>{trace.serial_no}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal ? (
                        trace.all_bin_locations &&
                        trace.all_bin_locations.length > 1 &&
                        !disabled &&
                        !isNotDraft ? (
                          <ControlledSelect
                            control={control}
                            name={`trace_entry_list[${index}].source_bin_location_id`}
                            options={trace.all_bin_locations.map((bin) => ({
                              label: bin.name,
                              value: bin.id,
                            }))}
                            onChange={(e: any) => {
                              const { id, ...otherFields } = fields[index];
                              update(index, {
                                ...otherFields,
                                stock_qty:
                                  trace.all_bin_locations &&
                                  trace.all_bin_locations.find(
                                    (bin) => bin.id === e.target.value
                                  ).stock_qty,
                                qty: 0,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            MenuProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            helperTextSize={10}
                          />
                        ) : (
                          <Typography fontSize={14}>
                            {trace.source_bin_location?.name}
                          </Typography>
                        )
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal &&
                        trace.scanned_by && (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CustomizedAvatar
                              avatars={[
                                {
                                  unique_id:
                                    trace.scanned_by.user_unique_id || "",
                                  first_name: trace.scanned_by.first_name || "",
                                  last_name: trace.scanned_by.last_name || "",
                                  img_url: trace.scanned_by.img_url
                                    ? trace.scanned_by.img_url[0]
                                    : "",
                                },
                              ]}
                            />
                          </Box>
                        )}
                    </TableCell>
                    {isOpenDestinationScanned ? (
                      <>
                        <TableCell align="center">
                          {trace.tracability === Tracability.Normal && (
                            <Typography fontSize={14}>
                              {trace.destination_bin_location?.name || ""}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {trace.tracability === Tracability.Normal &&
                            (trace.status === "cancelled" ? (
                              <Box
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <CustomizedStatus
                                  status={"inactive"}
                                  text={"ยกเลิก"}
                                />
                              </Box>
                            ) : trace.destination_scanned_by ? (
                              <Box
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <CustomizedStatus status={"is_scanned"} />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <CustomizedStatus status={"is_active"} />
                              </Box>
                            ))}
                        </TableCell>
                        <TableCell align="center">
                          {trace.tracability === Tracability.Normal &&
                            (trace.destination_scanned_by ? (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <CustomizedAvatar
                                  avatars={[
                                    {
                                      unique_id:
                                        trace.destination_scanned_by
                                          .user_unique_id || "",
                                      first_name:
                                        trace.destination_scanned_by
                                          .first_name || "",
                                      last_name:
                                        trace.destination_scanned_by
                                          .last_name || "",
                                      img_url: trace.destination_scanned_by
                                        .img_url
                                        ? trace.destination_scanned_by
                                            .img_url[0]
                                        : "",
                                    },
                                  ]}
                                />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                              ></Box>
                            ))}
                        </TableCell>
                        <TableCell align="center" sx={tablePadding}>
                          <Typography fontSize={14}>
                            {trace.destination_scanned_date
                              ? formatDateTimeNoAMPM(
                                  trace.destination_scanned_date
                                )
                              : ""}
                          </Typography>
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>{trace.barcode}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal &&
                        (isFinished ? (
                          <Typography fontSize={14}>{trace.remark}</Typography>
                        ) : (
                          <ControlledTextField
                            placeholder={t("sentence.remark")}
                            control={control}
                            name={`trace_entry_list.${index}.remark`}
                            viewMode={isFinished}
                          />
                        ))}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.lot_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[14]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.manufactured_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.expired_date)}
                        </Typography>
                      )}
                    </TableCell>
                    {!isNotDraft && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            openConfirmationHandler();
                            setDeletedIndex(index);
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <GoodsTransferSerialList nestedIndex={index} />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Confirmation
          title="ยืนยันหากต้องการลบสินค้า"
          message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
      </CustomizedBox>
    </>
  );
};

export default GoodsTransferItemList;
