import { Box, Typography } from "@mui/material";

interface IProps {
  title: string;
  value?: string | number;
  unit?: string;
  borderColor?: string;
  onClick?: () => void;
  margin?: number | string;
}

const TotalBox = ({
  title,
  value,
  unit,
  borderColor,
  onClick,
  margin,
}: IProps) => {
  return (
    <Box
      sx={{
        cursor: onClick ? "pointer" : "auto",
        // borderLeft: `5px ${
        //   borderColor ? borderColor : "rgba(72, 48, 222, 1) "
        // } solid`,
        border: "rgba(186, 186, 186, 1) 1px solid",
        padding: "1rem",
        // boxShadow: "0px 2px 7px #E5E5E5",
        margin: margin || "1rem 0 0 0",
        borderRadius: "5px",
      }}
      onClick={onClick}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h4">{value}</Typography>
      {unit && (
        <Box sx={{ position: "relative" }}>
          <Typography
            sx={{
              position: "absolute",
              right: 1,
              bottom: -15,
            }}
            variant="caption"
          >
            {`${unit}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TotalBox;
