import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../../types/global";

const ContactSetting = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.contact.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.contact.customer_type"),
      to: "/setting/contact/customer-type",
    },
    {
      name: t("setting.contact.vendor_type"),
      to: "/setting/contact/vendor-type",
    },
    {
      name: t("setting.contact.customer_group"),
      to: "/setting/contact/customer-group",
    },
    {
      name: t("setting.contact.vendor_group"),
      to: "/setting/contact/vendor-group",
    },
    {
      name: t("setting.contact.customer_attribute"),
      to: "/setting/contact/customer-attribute",
    },
    {
      name: t("setting.contact.vendor_attribute"),
      to: "/setting/contact/vendor-attribute",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default ContactSetting;
