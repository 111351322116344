// import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useState, Fragment } from "react";
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useConfirmation } from "../../../hooks/use-confirmation";
import { useDisable } from "../../../hooks/use-disable";

// import { ITEM_VARIANT_IS_DELETABLE } from "../../../services/Inventory/Item/ItemVariantIsDeletable";
// import { ITEM_SKU_DETAIL_CAN_DISABLE } from "../../../services/Inventory/Item/ItemSkuDetailCanDisable";

import { Grid, Typography, Box, Divider, IconButton } from "@mui/material";
import ModalUI from "../../../components/UI/Modal/ModalUI";
import DropzoneUI from "../../../components/UI/DropzoneUI";
import Confirmation from "../../../components/UI/Confirmation";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import VariantChip from "../../../components/Form/Inventory/Item/VariantChip";
import CustomizedCreatable from "../../../components/Custom/CustomizedCreatable";
import ControlledTextField from "../../../components/Controller/ControlledTextField";
import OneVariantTable from "../../../components/Table/Inventory/Item/Variant/OneVariantTable";
import TwoVariantTable from "../../../components/Table/Inventory/Item/Variant/TwoVariantTable";
import ThreeVariantTable from "../../../components/Table/Inventory/Item/Variant/ThreeVariantTable";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

import { ICreatableInput } from "../../../types/global";
import {
  countUniqueItemVariantKeyNames,
  generateVariations,
  transformVariation,
} from "../../../utils/Formatter/Item";
import { parseFloatFunction } from "../../../utils/Global";

type ExtendedProps = {
  isEditVariant: boolean;
  setIsEditVariant: (boolean: boolean) => void;
};

interface IDefaultModal {
  sale_price: number;
  special_price: number;
  purchase_standard_price: number;
}

interface IChip {
  type: string;
  index?: number;
  title?: string;
  textContent?: string;
  value?: string;
}

interface ISku {
  value?: string;
  position: string;
  current_value: string;
  name: string;
}

interface IVariationValue {
  key: string;
  name?: string | null;
  value: IVariationValue[];
  status?: boolean;
}

interface Variation {
  key: string;
  value: string[];
}

const VariationTab = ({ isEditVariant, setIsEditVariant }: ExtendedProps) => {
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedChip, setSelectedChip] = useState<IChip | null>();
  const [selectedSku, setSelectedSku] = useState<ISku | undefined>();
  const [editAllSku, setEditAllSku] = useState<boolean>(false);
  const [firstKey, setFirstKey] = useState("");
  const [secondKey, setSecondKey] = useState("");
  const [thirdKey, setThirdKey] = useState("");
  const [deleteVariantIndex, setDeleteVariantIndex] = useState<number[]>([]);
  //   const [showActiveDocuments, setShowActiveDocuments] =
  //     useState<boolean>(false);
  //   const [activeDocumentsWithSku, setActiveDocumentsWithSku] = useState<
  //     string[]
  //   >([]);

  const [disabled] = useDisable();

  const {
    control,
    setValue,
    getValues,
    // formState: { errors },
  } = useFormContext();

  const watchItemUniqueId = useWatch({ control, name: "unique_id" });
  const watchItemIsActive = useWatch({ control, name: "is_active" });
  const watchVariantList = useWatch({ control, name: "variation_list" });
  const watchVariantImgList = useWatch({ control, name: "variation_img_list" });

  const { fields, replace } = useFieldArray({
    control,
    name: "variation_list",
  });

  const {
    control: controlModal,
    handleSubmit: handleSubmitModal,
    reset,
  } = useForm<IDefaultModal>({
    defaultValues: {
      sale_price: undefined,
      special_price: undefined,
      purchase_standard_price: undefined,
    },
  });

  const {
    fields: firstOptions,
    append: appendFirstOption,
    remove: removeFirstOption,
  } = useFieldArray({ control, name: "option_1.list" });

  const {
    fields: secondOptions,
    append: appendSecondOption,
    remove: removeSecondOption,
  } = useFieldArray({ control, name: "option_2.list" });

  const {
    fields: thirdOptions,
    append: appendThirdOption,
    remove: removeThirdOption,
  } = useFieldArray({ control, name: "option_3.list" });

  const option_1 = useWatch({ control, name: "option_1" });
  const option_2 = useWatch({ control, name: "option_2" });
  const option_3 = useWatch({ control, name: "option_3" });

  const formatPayload = () => {
    const apiPayload: Variation[] = [];
    const firstValues: { value: string }[] = option_1.list;
    const secondValues: { value: string }[] = option_2.list;
    const thirdValues: { value: string }[] = option_3.list;
    if (firstValues && firstValues.length > 0)
      apiPayload.push({
        key: getValues(`option_1.key`),
        value: firstValues.map((list) => list.value),
      });
    if (secondValues && secondValues.length > 0)
      apiPayload.push({
        key: getValues(`option_2.key`),
        value: secondValues.map((list) => list.value),
      });
    if (thirdValues && thirdValues.length > 0)
      apiPayload.push({
        key: getValues(`option_3.key`),
        value: thirdValues.map((list) => list.value),
      });
    return apiPayload;
  };

  const setImgList = () => {
    const img_list: any = watchVariantImgList;
    for (const index of deleteVariantIndex) {
      img_list?.splice(index, 1);
    }
    setValue("variation_img_list", img_list);
    setDeleteVariantIndex([]);
  };

  const submitVariant = () => {
    const parameters = {
      unique_id: watchItemUniqueId,
      variation_list: formatPayload(),
    };
    if (parameters.variation_list && parameters.variation_list.length > 0) {
      const result = generateVariations(parameters);
      const initResult = transformVariation(result, watchVariantList);
      setValue("variation_list", initResult);
      setImgList();
      setIsEditVariant(true);
    }
  };

  // const handleDelete =
  //   (chipToDelete: number, removeVariant: any, type: string) => () => {
  //     removeVariant(chipToDelete);
  //   };

  const getTitle = (value: string, key: string) =>
    `ยืนยันการลบตัวเลือกที่ ${key} : ${value}`;

  const getTextContent = (value: string) => {
    const baseText = (text: string) => {
      return `หากลบแล้ว ตัวเลือก${text} จะหายไป`;
    };

    return baseText(value);
  };

  const handleOpen = (
    chip: string,
    type: string,
    index: number,
    key: string
  ) => {
    setOpenDeleteModal(true);
    setSelectedChip({
      type: type,
      index,
      title: getTitle(chip, key),
      textContent: getTextContent(chip),
    });
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
    setSelectedChip(null);
  };

  // const graphQLClientWithHeaderItem: GraphQLClient =
  //   createGraphQLClientWithMiddleware("wms");

  // const validateItemVariantDeletable = async (key: string, value: string) => {
  //   try {
  //     const { itemVariantIsDeletable } =
  //       await graphQLClientWithHeaderItem.request(ITEM_VARIANT_IS_DELETABLE, {
  //         whereUniqueInput: {
  //           item_unique_id: watchItemUniqueId,
  //           item_variant_key_name: key,
  //           item_variant_value_name: value,
  //         },
  //       });
  //     return itemVariantIsDeletable;
  //   } catch (e) {
  //     return true;
  //   }
  // };

  const handleDeleteVariant = async () => {
    if (selectedChip?.type === "first") {
      if (typeof selectedChip.index !== "undefined") {
        // const isDeletable = await validateItemVariantDeletable(
        //   option_1.key,
        //   (firstOptions[selectedChip?.index] as any)?.value as string
        // );
        // if (isDeletable) {
        removeFirstOption(selectedChip?.index);
        //     } else {
        //       enqueueSnackbar(
        //         "ไม่สามารถลบตัวเลือกได้\nเนื่องจากตัวเลือกนี้ถูกใช้งานอยู่",
        //         {
        //           variant: "error",
        //           style: { whiteSpace: "pre-line" },
        //         }
        //       );
        //     }
        //   }
      }
    } else if (selectedChip?.type === "second") {
      if (typeof selectedChip.index !== "undefined") {
        // const isDeletable = await validateItemVariantDeletable(
        //   option_2.key,
        //   (secondOptions[selectedChip?.index] as any)?.value as string
        // );
        // if (isDeletable) {
        removeSecondOption(selectedChip?.index);
        // } else {
        //   enqueueSnackbar(
        //     "ไม่สามารถลบตัวเลือกได้\nเนื่องจากตัวเลือกนี้ถูกใช้งานอยู่",
        //     {
        //       variant: "error",
        //       style: { whiteSpace: "pre-line" },
        //     }
        //   );
        // }
      }
    } else {
      if (typeof selectedChip?.index !== "undefined") {
        // const isDeletable = await validateItemVariantDeletable(
        //   option_3.key,
        //   (thirdOptions[selectedChip?.index] as any)?.value as string
        // );
        // if (isDeletable) {
        removeThirdOption(selectedChip?.index);
        // } else {
        //   enqueueSnackbar(
        //     "ไม่สามารถลบตัวเลือกได้\nเนื่องจากตัวเลือกนี้ถูกใช้งานอยู่",
        //     {
        //       variant: "error",
        //       style: { whiteSpace: "pre-line" },
        //     }
        //   );
        // }
      }
    }
    setOpenDeleteModal(false);
    setSelectedChip(null);
  };

  const handleSubmitDisabledSku = async () => {
    // if (selectedSku?.current_value) {
    //   const { itemSkuCanDisable } = await graphQLClientWithHeaderItem.request(
    //     ITEM_SKU_DETAIL_CAN_DISABLE,
    //     {
    //       uniqueInput: {
    //         sku_name: selectedSku?.name,
    //       },
    //     }
    //   );
    //   if (itemSkuCanDisable.can_disable) {
    setValue(selectedSku?.position || "", !selectedSku?.current_value);
    // } else {
    //   setActiveDocumentsWithSku(itemSkuCanDisable.active_document_list);
    //   setShowActiveDocuments(true);
    // }
    // }
  };

  const handleSubmitEditVairiant = () => {
    setIsEditVariant(false);
  };

  const handleCloseEditAllSku = () => {
    setEditAllSku(false);
  };

  const submitModal = (data: IDefaultModal) => {
    const variation_list = getValues("variation_list");
    const new_variation_list = updatePrices(variation_list, data);
    replace(new_variation_list);
    reset();
    setEditAllSku(false);
  };

  const updatePrices = (data: any, prices: IDefaultModal) => {
    const updatePricesRecursive = (items: any) => {
      items.forEach((item: any) => {
        if (item.value) {
          updatePricesRecursive(item.value);
        } else {
          item.sale_price = prices.sale_price ?? item.sale_price;
          item.special_price = prices.special_price ?? item.special_price;
          item.purchase_standard_price =
            prices.purchase_standard_price ?? item.purchase_standard_price;
        }
      });
    };
    updatePricesRecursive(data);
    return data;
  };

  const {
    confirmation: confirmationDisabled,
    openConfirmationHandler: openDisabledConfirmation,
    closeConfirmationHandler: closeDisabledConfirmation,
    submitConfirmationHandler: submitDisabledConfirmation,
  } = useConfirmation(handleSubmitDisabledSku);

  const {
    confirmation: confirmationEditVariant,
    openConfirmationHandler: openEditVariantConfirmation,
    closeConfirmationHandler: closeEditVariantConfirmation,
    submitConfirmationHandler: submitEditVariantConfirmation,
  } = useConfirmation(handleSubmitEditVairiant);

  const renderImages =
    watchVariantImgList && watchVariantImgList.length > 0
      ? watchVariantImgList.map((variant: IVariationValue, index: number) => {
          return (
            <Grid item xs={12} sm={5.5} md={3.5} lg={2.5} key={index}>
              <Typography variant="subtitle1">
                {watchVariantList[index]?.name}
              </Typography>
              <DropzoneUI
                control={control}
                setValue={setValue}
                disabled={disabled}
                name={`variation_img_list[${index}]`}
                acceptedFileType="image"
                maxSize={1}
              />
            </Grid>
          );
        })
      : watchVariantList.map((variant: IVariationValue, index: number) => {
          return (
            <Grid item xs={12} sm={5.5} md={3.5} lg={2.5} key={index}>
              <Typography variant="subtitle1">{variant.name}</Typography>
              <DropzoneUI
                control={control}
                setValue={setValue}
                disabled={disabled}
                name={`variation_img_list[${index}]`}
                acceptedFileType="image"
                maxSize={1}
              />
            </Grid>
          );
        });

  const renderTableCondition = () => {
    const count = countUniqueItemVariantKeyNames(watchVariantList);
    switch (count) {
      case 1:
        return (
          <OneVariantTable
            watchItemIsActive={Boolean(watchItemIsActive)}
            openDisabledConfirmation={openDisabledConfirmation}
            setSelectedSku={setSelectedSku}
            fields={fields}
            option_1={option_1}
          />
        );
      case 2:
        return (
          <TwoVariantTable
            watchItemIsActive={Boolean(watchItemIsActive)}
            openDisabledConfirmation={openDisabledConfirmation}
            setSelectedSku={setSelectedSku}
            fields={fields}
            option_1={option_1}
            option_2={option_2}
          />
        );
      case 3:
        return (
          <ThreeVariantTable
            watchItemIsActive={Boolean(watchItemIsActive)}
            openDisabledConfirmation={openDisabledConfirmation}
            setSelectedSku={setSelectedSku}
            fields={fields}
            option_1={option_1}
            option_2={option_2}
            option_3={option_3}
          />
        );
      default:
        return <></>;
    }
  };

  const handleAddVariant = (
    number: number,
    newValue: ICreatableInput,
    appendOption: any
  ) => {
    const arrayList = getValues(`option_${number}.list`);
    if (arrayList.length >= 20) return;
    if (typeof newValue === "string") {
      if (
        !arrayList.some((list: { value: string }) => list.value === newValue)
      ) {
        appendOption({ value: newValue });
      }
    } else if (newValue && newValue.inputValue) {
      if (
        !arrayList.some(
          (list: { value: string }) => list.value === newValue.inputValue
        )
      ) {
        appendOption({ value: newValue.inputValue });
      }
    } else {
      if (newValue) {
        if (
          !arrayList.some(
            (list: { value: string }) => list.value === newValue.value
          )
        ) {
          appendOption({ value: newValue.value });
        }
      }
    }
  };

  return (
    <Fragment>
      <CustomizedBox margin="0">
        <Box display="flex" justifyContent="space-between">
          <Confirmation
            open={openDeleteModal}
            title={selectedChip?.title || ""}
            message={selectedChip?.textContent}
            handleClose={handleClose}
            action={handleDeleteVariant}
          />
          <Confirmation
            open={confirmationDisabled}
            title={
              t("inventory.variant.sentence.diabledSKU") +
              " " +
              selectedSku?.name
            }
            message={t("inventory.variant.sentence.diabledSKUDetail")}
            handleClose={closeDisabledConfirmation}
            action={submitDisabledConfirmation}
          />
          <Confirmation
            open={confirmationEditVariant}
            title={t("inventory.variant.sentence.modalEditVariant")}
            handleClose={closeEditVariantConfirmation}
            action={submitEditVariantConfirmation}
          />
        </Box>
        <Box my={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6">
              {t("inventory.items.tag_list")}
            </Typography>
            {isEditVariant && (
              <Grid item xs={8} textAlign="end">
                <IconButton
                  onClick={openEditVariantConfirmation}
                  disabled={disabled}
                  size="small"
                >
                  <CreateOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ControlledTextField
                control={control}
                name="option_1.key"
                label={
                  t("inventory.variant.option") + t("inventory.variant.no1")
                }
                disabled={isEditVariant}
                // error={errors?.option_1?.key}
                required
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomizedCreatable
                label={t("inventory.variant.option_key")}
                options={[]}
                value={firstKey}
                onChange={(event: EventListener, newValue: ICreatableInput) => {
                  if (firstOptions.length <= 20)
                    handleAddVariant(1, newValue, appendFirstOption);
                  else return;
                  setFirstKey("");
                }}
                disabled={isEditVariant}
              />
            </Grid>
            <Grid item xs={8} alignSelf="center">
              {firstOptions.map((chip, index) => (
                <Fragment key={chip.id}>
                  <VariantChip
                    value={getValues(`option_1.list.${index}.value`)}
                    index={index}
                    removeVariant={removeFirstOption}
                    type="first"
                    key={getValues(`option_1.key`)}
                    handleOpen={handleOpen}
                    isEditVariant={isEditVariant}
                    firstOptions={firstOptions}
                    secondOptions={secondOptions}
                    thirdOptions={thirdOptions}
                    watchVariantList={watchVariantList}
                  />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ControlledTextField
                control={control}
                name="option_2.key"
                label={
                  t("inventory.variant.option") + t("inventory.variant.no2")
                }
                disabled={isEditVariant || firstOptions.length === 0}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomizedCreatable
                label={t("inventory.variant.option_key")}
                options={[]}
                value={secondKey}
                onChange={(event: EventListener, newValue: ICreatableInput) => {
                  if (secondOptions.length <= 20)
                    handleAddVariant(2, newValue, appendSecondOption);
                  else return;
                  setSecondKey("");
                }}
                disabled={isEditVariant || firstOptions.length === 0}
              />
            </Grid>
            <Grid item xs={8} alignSelf="center">
              {secondOptions.map((chip, index) => (
                <Fragment key={chip.id}>
                  <VariantChip
                    value={getValues(`option_2.list.${index}.value`)}
                    index={index}
                    removeVariant={removeSecondOption}
                    type="second"
                    key={getValues(`option_2.key`)}
                    handleOpen={handleOpen}
                    isEditVariant={isEditVariant}
                    firstOptions={firstOptions}
                    secondOptions={secondOptions}
                    thirdOptions={thirdOptions}
                    watchVariantList={watchVariantList}
                  />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ControlledTextField
                control={control}
                name="option_3.key"
                label={
                  t("inventory.variant.option") + t("inventory.variant.no3")
                }
                disabled={isEditVariant || secondOptions.length === 0}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={1} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomizedCreatable
                label={t("inventory.variant.option_key")}
                options={[]}
                value={thirdKey}
                onChange={(event: any, newValue: any) => {
                  if (thirdOptions.length <= 20)
                    handleAddVariant(3, newValue, appendThirdOption);
                  else return;
                  setThirdKey("");
                }}
                disabled={isEditVariant || secondOptions.length === 0}
              />
            </Grid>
            <Grid item xs={8} alignSelf="center">
              {thirdOptions.map((chip, index) => (
                <Fragment key={chip.id}>
                  <VariantChip
                    value={getValues(`option_3.list.${index}.value`)}
                    index={index}
                    removeVariant={removeThirdOption}
                    type="third"
                    key={getValues(`option_3.key`)}
                    handleOpen={handleOpen}
                    isEditVariant={isEditVariant}
                    firstOptions={firstOptions}
                    secondOptions={secondOptions}
                    thirdOptions={thirdOptions}
                    watchVariantList={watchVariantList}
                  />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        {!isEditVariant && (
          <CustomizedButton
            title={"บันทึก"}
            variant="contained"
            onClick={submitVariant}
          />
        )}
      </CustomizedBox>
      {watchVariantList && watchVariantList.length >= 1 && (
        <Fragment>
          <CustomizedBox mb={2} overflow={"auto"}>
            <Box
              display="flex"
              mb={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                {t("inventory.items.tag_list")}
              </Typography>
              <CustomizedButton
                disabled={
                  disabled || (watchVariantList && watchVariantList.length <= 0)
                }
                onClick={() => setEditAllSku(true)}
                title={t("inventory.variant.sentence.update_all")}
                startIcon={<RestartAltIcon />}
                variant="outlined"
              />
            </Box>
            <Divider />
            {renderTableCondition()}
          </CustomizedBox>
          <CustomizedBox>
            <Typography fontWeight="bold" mb={4}>
              อัปโหลดรูป
            </Typography>
            <Grid container spacing={2}>
              {renderImages}
            </Grid>
          </CustomizedBox>
          <ModalUI
            open={editAllSku}
            title={t("inventory.variant.header.update_all_sku")}
            handleClose={handleCloseEditAllSku}
          >
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={6}>
                <ControlledTextField
                  control={controlModal}
                  name="sale_price"
                  label={t("inventory.variant.header.sales_price")}
                  type="number"
                  onChange={parseFloatFunction}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  control={controlModal}
                  name="special_price"
                  label={t("inventory.variant.header.special_price")}
                  type="number"
                  onChange={parseFloatFunction}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  control={controlModal}
                  name="purchase_standard_price"
                  label={t("inventory.variant.header.selling_price")}
                  type="number"
                  onChange={parseFloatFunction}
                />
              </Grid>
            </Grid>
            <CustomizedButton
              sx={{ marginTop: 1 }}
              title={t("inventory.variant.sentence.update_all")}
              onClick={handleSubmitModal(submitModal)}
              variant="contained"
            />
          </ModalUI>
        </Fragment>
      )}
      {/* <ModalUI
        open={showActiveDocuments}
        handleClose={() => setShowActiveDocuments(false)}
        title={"ไม่สามารถปิดใช้งานได้ เนื่องจากสินค้าถูกใช้งานในเอกสาร"}
      >
        <Fragment>
          <Typography variant="h6">เอกสารที่ใช้งานสินค้านี้อยู่คือ:</Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {activeDocumentsWithSku.map((doc) => (
              <ListItem
                key={doc}
                sx={{
                  display: "list-item",
                }}
              >
                <Typography
                  sx={{
                    display: "inline",
                  }}
                >
                  {doc}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Fragment>
      </ModalUI> */}
    </Fragment>
  );
};

export default VariationTab;
