import { ReactNode } from "react";
import { Stack, SxProps } from "@mui/material";

type Props = {
  children?: ReactNode;
  sx?: SxProps;
};

const ButtonLayout = ({ children, sx }: Props) => {
  return (
    <Stack sx={sx} direction="row" spacing={1} alignItems="center">
      {children}
    </Stack>
  );
};

export default ButtonLayout;
