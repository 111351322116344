import { Box, CircularProgress, Stack } from "@mui/material";
import CustomerDepositHeader from "../../../components/Form/Sales/CustomerDeposit/Header";
import CustomerInfo from "../../../components/Form/Sales/CustomerInfo";
import SalesFooter from "../../../components/Form/Sales/Footer";
import SalesInfo from "../../../components/Form/Sales/SalesInfo";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useFormContext, useWatch } from "react-hook-form";
import { ICustomerDeposit } from "../../../types/Sales/customerDeposit";
import { useTranslation } from "react-i18next";
import { useDisable } from "../../../hooks/use-disable";
import { IExternalProps } from "../../../types/Sales";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DepositList from "../../../components/Table/Sales/DepositList/DepositList";
import SalesPayment from "../../../components/Form/Sales/Payment/SalesPayment";
import {
  CustomerDepositCreateInput,
  CustomerDepositFindUniqueQuery,
  CustomerDepositUpdateInput,
  useCustomerDepositCreateMutation,
  useCustomerDepositFindUniqueQuery,
  useCustomerDepositUpdateMutation,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import { useActivityLog } from "../../../hooks/use-activity-log";
import {
  customerDepositCreateFormatter,
  customerDepositQueryFormatter,
  customerDepositUpdateFormatter,
} from "../../../utils/Formatter/Sales/CustomerDeposit";
import {
  ActivityLogDocumentType,
  ActivityType,
} from "../../../generated/inventory";
import { errorMessageFormatter } from "../../../utils/Global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import AddressInfo from "../../../components/Form/Sales/CustomerInfo/AddressInfo";
import dayjs from "dayjs";
// import { salesPaymentChannelSchema } from "../../../components/Form/Sales/CashSales/schema";

const DocumentInfoTab = () => {
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    // setValue,
    formState: { dirtyFields, errors },
  } = useFormContext<ICustomerDeposit>();

  const [netAmount, setNetAmount] = useState<number | null | undefined>(0);
  const { isLoading: isOptionLoading } = useSalesOption();
  const { createActivityLog } = useActivityLog();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const watchNetAmount: number =
    useWatch({
      control,
      name: "net_amount",
    }) || 0;

  const paidTotalAmount: number =
    useWatch({
      control,
      name: "paid_total_amount",
    }) || 0;

  // const watchIssueDate = useWatch({
  //   control,
  //   name: "issue_date",
  // });

  // const depositList = useWatch({
  //   control,
  //   name: "deposit_list",
  // });

  // const allWithholdingTaxAmount = useWatch({
  //   control,
  //   name: "withholding_tax_amount",
  // });

  // const allWithholdingTax = depositList.map(
  //   (deposit) => deposit.withholding_tax_type
  // );

  // const payment = useWatch({
  //   control,
  //   name: `payment_list.0`,
  // });

  const remainingPayment =
    parseFloat(watchNetAmount.toFixed(2)) -
    parseFloat(paidTotalAmount.toFixed(2));

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess, refetch, isFetching } =
    useCustomerDepositFindUniqueQuery<CustomerDepositFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { t } = useTranslation();
  const [disabled, setDisabled] = useDisable();

  useEffect(() => {
    if (isSuccess) {
      const { CustomerDepositFindUnique } = data;
      setNetAmount(CustomerDepositFindUnique.net_amount);
      const formattedCustomerDeposit = customerDepositQueryFormatter(
        CustomerDepositFindUnique
      );
      reset(formattedCustomerDeposit);
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id && status !== "draft") {
      setDisabled(true);
    }

    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const {
        copied_unique_id,
        copied_id,
        created_date,
        issue_date,
        due_date,
        payment_list,
        ...otherState
      } = state;

      const formatPaymentList = payment_list.map((payment: any) => ({
        ...payment,
        payment_date: dayjs(payment.payment_date),
      }));
      reset({
        ...otherState,
        payment_list: formatPaymentList,
        created_date: dayjs(created_date),
        issue_date: dayjs(issue_date),
        due_date: dayjs(due_date),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    useCustomerDepositCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useCustomerDepositUpdateMutation<Error>(graphQLClient);

  const draftHandler = async (data: ICustomerDeposit) => {
    if (!id) {
      try {
        const formatData = customerDepositCreateFormatter(data, "draft");
        const { CustomerDepositCreate } = await create({
          input: formatData as CustomerDepositCreateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/customer-deposit/${CustomerDepositCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.CustomerDeposit,
            reference_id: CustomerDepositCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: CustomerDepositCreate.id,
                unique_id: CustomerDepositCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.CustomerDeposit,
          reference_id: CustomerDepositCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
            updated_fields: {
              net_amount: CustomerDepositCreate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = customerDepositUpdateFormatter(data, "draft");
        const { CustomerDepositUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as CustomerDepositUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);

        if (formattedDirtyFields.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.CustomerDeposit,
            reference_id: CustomerDepositUpdate?.id,
            activity_detail: {
              updated_fields: {
                net_amount:
                  netAmount?.toFixed(2) ===
                  CustomerDepositUpdate.net_amount?.toFixed(2)
                    ? undefined
                    : CustomerDepositUpdate.net_amount,
              },
            },
          });
        }
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitDeductionHandler = async (data: ICustomerDeposit) => {
    if (!id) {
      try {
        const formatData = customerDepositCreateFormatter(
          data,
          "wait_deduction"
        );
        const { CustomerDepositCreate } = await create({
          input: formatData as CustomerDepositCreateInput,
        });
        enqueueSnackbar(`${t("button.send")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/customer-deposit/${CustomerDepositCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.CustomerDeposit,
            reference_id: CustomerDepositCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: CustomerDepositCreate.id,
                unique_id: CustomerDepositCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.CustomerDeposit,
          reference_id: CustomerDepositCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_deduction",
            updated_fields: {
              net_amount: CustomerDepositCreate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.send")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = customerDepositUpdateFormatter(
          data,
          "wait_deduction"
        );
        const { CustomerDepositUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as CustomerDepositUpdateInput,
        });
        enqueueSnackbar(`${t("button.send")}สำเร็จ`, {
          variant: "success",
        });
        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.CustomerDeposit,
          reference_id: CustomerDepositUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "wait_deduction",
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) ===
                CustomerDepositUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : CustomerDepositUpdate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.send")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const externalReferenceHandler = async (data: IExternalProps) => {
    try {
      const formData = getValues();
      await update({
        input: {
          external_reference_no: data.external_reference_no,
          unique_id: formData.unique_id,
          branch_id: formData.branch_id || 0,
          created_by: formData.created_by,
          due_date: formData.due_date,
          issue_date: formData.issue_date,
        },
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const { CustomerDepositUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: {
          unique_id: data.unique_id,
          flag_status: flag_status,
          branch_id: data.branch_id || 0,
          created_by: data.created_by,
          due_date: data.due_date,
          issue_date: data.issue_date,
        },
      });

      enqueueSnackbar(`${t("button.cancel")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.CustomerDeposit,
        reference_id: CustomerDepositUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
    } catch (err: any) {
      if (
        err.message.includes(
          "Cannot cancel customer deposit because it has related document"
        )
      ) {
        enqueueSnackbar(
          `ไม่สามารถยกเลิกเอกสารได้ กรุณายกเลิกเอกสารที่เกี่ยวข้อง`,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(`${t("button.cancel")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const editHandler = async (data: ICustomerDeposit) => {
    try {
      const formatData = customerDepositUpdateFormatter(
        data,
        data?.main_status || ""
      );
      const { CustomerDepositUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: formatData as CustomerDepositUpdateInput,
      });
      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);

      if (formattedDirtyFields.length > 0) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.SalesOrder,
          reference_id: CustomerDepositUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) ===
                CustomerDepositUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : CustomerDepositUpdate.net_amount,
            },
          },
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating || remainingPayment < 0}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              disabled={isCreating || isUpdating || remainingPayment !== 0}
              onClick={handleSubmit(waitDeductionHandler)}
            />
          </>
        );
      default:
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => {
                  handleSubmit((data) => editHandler(data))();
                  setIsEdit(false);
                  setDisabled(true);
                }}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                disabled={isCreating || remainingPayment < 0}
                onClick={handleSubmit(draftHandler)}
              />
              <CustomizedButton
                title={t("button.send")}
                variant="contained"
                disabled={isCreating || remainingPayment !== 0}
                onClick={handleSubmit(waitDeductionHandler)}
              />
            </>
          );
        }
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setIsEdit(false);
    setDisabled(true);
    reset();
  };

  useSalesError(errors);

  // useEffect(() => {
  //   if (
  //     allWithholdingTax.some((tax) => tax !== "ยังไม่ระบุ" && tax !== "ไม่มี")
  //   ) {
  //     const new_payment_amount =
  //       watchNetAmount - (allWithholdingTaxAmount || 0);

  //     const { is_withholding_tax, payment_date, payment_amount, ...other } =
  //       payment;

  //     setValue(`payment_list.0`, {
  //       ...other,
  //       is_withholding_tax: true,
  //       payment_amount: new_payment_amount,
  //       payment_date: watchIssueDate,
  //     });
  //   } else {
  //     const { payment_date, payment_amount, ...other } = payment;
  //     setValue(`payment_list.0`, {
  //       ...other,
  //       payment_amount: watchNetAmount,
  //       payment_date: watchIssueDate,
  //     });
  //   }
  // }, [
  //   allWithholdingTax,
  //   allWithholdingTaxAmount,
  //   payment,
  //   setValue,
  //   watchIssueDate,
  //   watchNetAmount,
  // ]);

  const forceDisabled = Boolean(id) && (disabled || status !== "draft");

  if (isOptionLoading || (id && (isLoading || isFetching))) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomerDepositHeader
        editClickHandler={editClickHandler}
        externalReferenceHandler={externalReferenceHandler}
        cancelHandler={cancelHandler}
      />
      <CustomerInfo
        documentType="customer_deposit"
        forceDisabled={forceDisabled}
      />
      <AddressInfo
        documentType="customer_deposit"
        forceDisabled={forceDisabled}
      />
      <SalesInfo documentType="customer_deposit" />
      <DepositList />
      {/* <Promotion /> */}
      <SalesFooter documentType="customer_deposit" />
      {!forceDisabled && <SalesPayment documentType="customer_deposit" />}
      <BottomNavbar>
        {!disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            {renderButton(status)}
          </Stack>
        )}
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
