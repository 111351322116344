import { IconButton, Typography } from "@mui/material";
import { ChangeEventHandler, FC, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FileRejection } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FileError } from "react-dropzone";
import { Box } from "@mui/system";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IMenuOption } from "../../types/global";
import CustomizedIconMenuOptions from "../Custom/CustomizedIconMenuOptions";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  borderWidth: 2,
  borderRadius: 14,
  borderColor: "#324290",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const DropzoneField: FC<{
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  options?: IMenuOption[];
  acceptedFileType?: string;
  maxSize?: number;
  maxFileSize?: number;
  disabled?: boolean;
  isNotRevoke?: boolean;
}> = ({
  control,
  name,
  setValue,
  multiple,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isNotRevoke,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          multiple={multiple}
          onChange={(e) =>
            onChange(multiple ? e.target.files : e.target.files?.[0] ?? null)
          }
          options={options}
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isNotRevoke={isNotRevoke}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isNotRevoke?: boolean;
}> = ({
  control,
  name,
  setValue,
  multiple,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  isNotRevoke,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/*"?: any[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?: any[];
    "application/pdf"?: any[];
  } => {
    switch (fileType) {
      case "image":
        return {
          "image/*": [],
        };
      case "xlsx":
        return {
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
      default:
        return {
          "image/*": [],
          "application/pdf": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize ? maxFileSize : 10485760,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
          }
        });
      });

      const newFiles: File[] =
        (!!files?.length && [...files].concat(acceptedFiles)) || acceptedFiles;

      const url = newFiles.map((file: File) => {
        if (typeof file === "object")
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        else return file;
      });
      setValue(name, url);
    },
    disabled,
    multiple,
  });

  const style = useMemo(
    () => ({
      ...{
        ...baseStyle,
        borderStyle: "solid",
        borderColor: disabled ? "#e5e5e5" : "#324290",
      },
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [disabled, isDragActive, isDragAccept, isDragReject]
  );

  const files = useWatch({
    control,
    name,
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setValue(name, newFiles);
  };

  const moveToFirst = (arr: string[] & File[], index: number) => {
    // Check if index is within the bounds of the array
    if (index < 0 || index >= arr.length) {
      console.log("Invalid index!");
      return arr;
    }

    // Remove element at index and store it in a variable
    const element = arr.splice(index, 1)[0];

    // Insert the element at the beginning of the array
    arr.unshift(element);

    return arr;
  };

  if (!multiple && files && files.length > 0) {
    return (
      <Box
        sx={{
          width: "fit-content",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: "2px",
          borderRadius: "14px",
          borderColor: disabled ? "#e5e5e5" : "#324290",
          borderStyle: disabled ? "solid" : "dashed",
        }}
      >
        <div className="image-container">
          <img
            alt="company"
            src={files[0].preview || files[0]}
            className={disabled ? "image-no-hover" : "image"}
            // Revoke data uri after image is loaded
            onLoad={() => {
              if (!isNotRevoke) {
                URL.revokeObjectURL(files[0].preview || files[0]);
              }
            }}
          />
          {!disabled && (
            <>
              <div className="delete">
                <IconButton aria-label="cancel" onClick={removeFile(files[0])}>
                  <DeleteOutlineOutlinedIcon sx={{ color: "white" }} />
                </IconButton>
              </div>
              <div className="overlay"></div>
            </>
          )}
        </div>
      </Box>
    );
  }

  const renderGallary = () =>
    multiple && (
      <Box display={"flex"} flexWrap="wrap" gap={2}>
        {files.map((file: any, index: number) => {
          return (
            <Box
              justifyContent="center"
              width={180}
              height={180}
              key={typeof file === "string" ? file : file.preview}
            >
              <div
                className="image-container"
                style={{
                  border: "2px solid #e5e5e5",
                }}
              >
                <img
                  alt="company"
                  src={file.preview || file}
                  className="image-no-hover"
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    if (!isNotRevoke) {
                      URL.revokeObjectURL(file.preview);
                    }
                  }}
                />
                {!disabled && (
                  <>
                    <div className="delete-multiple">
                      <CustomizedIconMenuOptions
                        icon={<MoreVertIcon />}
                        options={options ?? []}
                        onSelect={(e) => {
                          const value = e.target as HTMLElement;
                          switch (value.innerText) {
                            case "ลบ":
                              const newFiles = [...files];
                              newFiles.splice(newFiles.indexOf(file), 1);
                              setValue(name, newFiles);
                              break;
                            case "เลือกเป็นภาพหลัก":
                              const compareFiles = moveToFirst(files, index);
                              setValue(name, compareFiles);
                              break;
                            default:
                              break;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Box>
          );
        })}
        {files.length !== maxSize && !disabled && (
          <Box display="flex" justifyContent="center" width={200} height={200}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps({ onChange })} />
              <ImageOutlinedIcon color="primary" />
              <Typography color="primary">อัปโหลดรูปภาพ</Typography>
              <Typography color="primary">
                {files.length}/{maxSize}
              </Typography>
            </div>
          </Box>
        )}
      </Box>
    );

  return (
    <>
      {renderGallary()}
      {!multiple && (
        <Box display="flex" justifyContent="center" width={200} height={200}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps({ onChange })} />
            <ImageOutlinedIcon
              color={disabled ? "disabled" : "primary"}
              fontSize="large"
            />
            {!disabled && (
              <Typography color="primary">อัปโหลดรูปภาพ</Typography>
            )}
            {maxSize && maxSize > 1 && (
              <Typography color="primary">
                {files.length}/{maxSize}
              </Typography>
            )}
          </div>
        </Box>
      )}
    </>
  );
};

export default DropzoneField;
