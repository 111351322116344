import { useState } from "react";
import { useLocation } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
// import Promotion from "../../../components/Form/Sales/Promotion";
import { Box, CircularProgress, IconButton } from "@mui/material";
import {
  cashSalesSchema,
  cashSalesValidation,
} from "../../../components/Form/Sales/CashSales/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActivityLogDocumentType } from "../../../generated/inventory";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import DocumentInfoTab from "./DocumentInfoTab";
import SalesReturnTab from "./SalesReturnTab";
import DeliveryOrderTab from "./DeliveryOrderTab";
import PaymentTab from "./PaymentTab";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CashSalesFindUniqueQuery,
  useCashSalesFindUniqueQuery,
} from "../../../generated/sales";

const CashSalesContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: "รับชำระ",
      path: `${pathname}?tab=payment`,
    },
    {
      label: t("logistic.delivery_order.index"),
      path: `${pathname}?tab=delivery_order`,
    },
    {
      label: t("sales.return.index"),
      path: `${pathname}?tab=sales_return`,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { id } = useParams();

  const { data, isLoading } =
    useCashSalesFindUniqueQuery<CashSalesFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "payment":
        return <PaymentTab />;
      case "delivery_order":
        return <DeliveryOrderTab />;
      case "sales_return":
        return (
          <SalesReturnTab
            referenceUniqueId={data?.CashSalesFindUnique.unique_id}
          />
        );
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const branch_id = sessionStorage.getItem("branch-id");

  const methods = useForm<any>({
    defaultValues: {
      ...cashSalesSchema,
      branch_id: branch_id ? parseInt(branch_id) : undefined,
    },
    resolver: yupResolver(cashSalesValidation),
  });

  // console.log("นี่คือ getvalue");
  // console.log(methods.getValues());
  // console.log("นี่คือหลัง getvalue");
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.cash_sales.index"),
      to: "/sales/cash-sales",
    },
    {
      name: id
        ? data?.CashSalesFindUnique.unique_id || "-"
        : t("button.create") + t("sales.cash_sales.index"),
    },
  ];

  const status = data?.CashSalesFindUnique.aggrid_status;

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      {status === "finished" && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.CashSales}
        />
      )}
    </FormProvider>
  );
};

export default CashSalesContainer;
