import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IHeaderTable, ISelectOption } from "../../../../types/global";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { FieldArrayWithId, useFormContext, useWatch } from "react-hook-form";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CloseIcon from "@mui/icons-material/Close";
import TocIcon from "@mui/icons-material/Toc";
import { ISalesItemList } from "../../../../types/Sales";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import PreVatAmountCell from "./PreVatAmountCell";
// import WithholdingTaxCell from "./WithholdingTaxCell";
import {
  numberFormatter,
  priceNumberFormatter,
} from "../../../../utils/Formatter/Global";
import { ISalesReturn } from "../../../../types/Sales/salesReturn";
import { useDisable } from "../../../../hooks/use-disable";
import { useParams } from "react-router-dom";

type FieldArrayType = {
  item_list: ISalesItemList[];
};

type Props = {
  fields: FieldArrayWithId<FieldArrayType, "item_list", "unique_id">[];
  headers: IHeaderTable[];
  dragEndHandler: (result: DropResult) => any;
  openDeleteItemConfirmationHandler: (index: number) => void;
  vatOptions: ISelectOption[];
};

const SalesReturnItemList = ({
  fields,
  headers,
  dragEndHandler,
  openDeleteItemConfirmationHandler,
  vatOptions,
}: Props) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ISalesReturn>();
  const { id } = useParams();
  const [disabled] = useDisable();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const tableDisabled =
    Boolean(id) &&
    (disabled || (status !== "draft" && status !== "not_approved"));

  const uomOptions = (field: ISalesItemList) => {
    const options = (
      field.uom_group
        ? field.uom_group.convertable_uom_list &&
          field.uom_group.convertable_uom_list.length > 0
          ? [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
              ...field.uom_group.convertable_uom_list.map((uom) => ({
                label: uom.name_th,
                value: uom.id,
              })),
            ]
          : [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
            ]
        : []
    ) as ISelectOption[];
    return options;
  };

  const textAlignToJustify = (align?: string) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={header.align}
                key={index}
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={header.width}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: header.width,
                    justifyContent: textAlignToJustify(header.align),
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={"primary.main"}
                  >
                    {header.thaiLabel}
                  </Typography>
                  {header.required && (
                    <Typography
                      fontSize={14}
                      color={"error.main"}
                      fontWeight={600}
                    >
                      *
                    </Typography>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={dragEndHandler}>
          <Droppable droppableId="droppable" isDropDisabled={disabled}>
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => (
                  <Draggable
                    key={field.unique_id}
                    draggableId={"item-" + field.unique_id}
                    index={index}
                    isDragDisabled={disabled}
                  >
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          ...provided.draggableProps.style,
                          breakInside: "avoid",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[0]?.width || 0
                              : 0) + 16
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {!disabled && <TocIcon fontSize="small" />}
                            <Typography fontSize={14}>{index + 1}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[1]?.width || 0
                              : 0) + 16
                          }
                        >
                          <ItemNameCell
                            control={control}
                            errors={errors}
                            field={field}
                            index={index}
                            disabled={tableDisabled}
                            documentType="sales_return"
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[2]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <Typography fontSize={14}>
                              {numberFormatter(field.so_qty || 0)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.so_qty`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.so_qty
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.so_qty &&
                                errors?.item_list[index]?.so_qty?.message
                              }
                              textAlign="right"
                              disabled
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[3]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <Typography fontSize={14}>
                              {numberFormatter(field.qty)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.qty`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.qty
                              )}
                              onChange={(value) => {
                                const parseValue = value || 0;
                                const currentUomId = getValues(
                                  `item_list.${index}.uom_id`
                                );

                                if (currentUomId) {
                                  if (field.uom_group) {
                                    if (
                                      currentUomId ===
                                      field.uom_group.base_uom?.id
                                    ) {
                                      setValue(
                                        `item_list.${index}.stock_qty`,
                                        parseValue
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_id ===
                                              currentUomId
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list.${index}.stock_qty`,
                                            parseValue * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }
                              }}
                              textAlign="right"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[4]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <Typography fontSize={14}>
                              {field.uom_name}
                            </Typography>
                          ) : (
                            <ControlledSelect
                              control={control}
                              name={`item_list.${index}.uom_id`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.uom_id
                              )}
                              disabled
                              options={uomOptions(field)}
                              onChange={(e: any) => {
                                const uom_name = uomOptions(field).find(
                                  (uom) => uom.value === e.target.value
                                )?.label;
                                setValue(
                                  `item_list.${index}.uom_name`,
                                  uom_name
                                );
                                const currentQty = getValues(
                                  `item_list.${index}.qty`
                                );
                                if (field.uom_group) {
                                  if (
                                    e.target.value ===
                                    field.uom_group.base_uom?.id
                                  ) {
                                    setValue(
                                      `item_list.${index}.stock_qty`,
                                      currentQty
                                    );
                                  } else {
                                    if (
                                      field.uom_group.uom_conversion_list &&
                                      field.uom_group.uom_conversion_list
                                        .length > 0
                                    ) {
                                      const conversionUom =
                                        field.uom_group.uom_conversion_list.find(
                                          (conversion) =>
                                            conversion.target_uom_id ===
                                            e.target.value
                                        );
                                      if (conversionUom) {
                                        const targetUomQty =
                                          conversionUom.base_uom_rate /
                                          conversionUom.target_uom_rate;
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          currentQty * targetUomQty
                                        );
                                      }
                                    }
                                  }
                                }
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[5]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <Typography fontSize={14}>
                              {priceNumberFormatter(field.price_per_unit)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.price_per_unit`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.price_per_unit
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.price_per_unit &&
                                errors?.item_list[index]?.price_per_unit
                                  ?.message
                              }
                              disabled={true}
                              textAlign="right"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[6]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <Typography fontSize={14}>
                              {priceNumberFormatter(field.discount)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.discount`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.discount
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.discount &&
                                errors?.item_list[index]?.discount?.message
                              }
                              disabled={true}
                              textAlign="right"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[7]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <Typography fontSize={14}>
                              {field.vat_percentage}
                            </Typography>
                          ) : (
                            <ControlledSelect
                              control={control}
                              name={`item_list.${index}.vat_percentage`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.vat_percentage
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.vat_percentage &&
                                errors?.item_list[index]?.vat_percentage
                                  ?.message
                              }
                              options={vatOptions}
                              disabled={true}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[8]?.width || 0
                              : 0) + 16
                          }
                        >
                          <PreVatAmountCell
                            control={control}
                            index={index}
                            field={field}
                            disabled={disabled}
                            setValue={setValue}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[9]?.width || 0
                              : 0) + 16
                          }
                        >
                          {tableDisabled ? (
                            <>
                              <Typography fontSize={14}>
                                {numberFormatter(field.stock_qty)}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ color: "#BDBDBD" }}
                              >
                                {field.uom_group?.base_uom?.name_th}
                              </Typography>
                            </>
                          ) : (
                            <ControlledTextField
                              sx={{ mt: 3 }}
                              type="number"
                              control={control}
                              name={`item_list.${index}.stock_qty`}
                              helperText={field.uom_group?.base_uom?.name_th}
                              FormHelperTextProps={{
                                sx: {
                                  color: "#BDBDBD",
                                  fontSize: 12,
                                },
                              }}
                              viewMode={true}
                            />
                          )}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[10]?.width || 0
                              : 0) + 16
                          }
                        >
                          <WithholdingTaxCell
                            control={control}
                            errors={errors}
                            index={index}
                            documentType={"sales_return"}
                            disabled={tableDisabled}
                            setValue={setValue}
                            field={field}
                          />
                        </TableCell> */}
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[10]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {field.remark}
                            </Typography>
                          ) : (
                            <ControlledTextField
                              control={control}
                              name={`item_list.${index}.remark`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.remark
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.remark &&
                                errors?.item_list[index]?.remark?.message
                              }
                              disabled={disabled}
                            />
                          )}
                        </TableCell>
                        {!disabled && (
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[11]?.width || 0
                                : 0) + 16
                            }
                          >
                            <IconButton
                              aria-label="delete"
                              size="small"
                              sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                              onClick={() =>
                                openDeleteItemConfirmationHandler(index)
                              }
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export default SalesReturnItemList;
