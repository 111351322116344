import { IDateFilterParams } from "ag-grid-community";

export const dateFilterParams: IDateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    const dateWithoutTime = cellValue.split(",")[0];
    var dateParts = dateWithoutTime.split("/");
    var year = Number(dateParts[2].substring(0, 4));
    var month = Number(dateParts[1]) - 1;
    var day = Number(dateParts[0]);
    var cellDate = new Date(year, month, day);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const dateFilterModel = (filterObj: any) => {
  if (!filterObj) {
    return undefined;
  }
  if (filterObj.type === "equals") {
    const nextDay = new Date(filterObj.dateFrom);
    nextDay.setDate(nextDay.getDate() + 1);
    return {
      filterType: "date",
      type: "inRange",
      dateFrom: filterObj.dateFrom
        ? new Date(filterObj.dateFrom).toISOString()
        : undefined,
      dateTo: nextDay ? nextDay.toISOString() : undefined,
    };
  }
  return {
    filterType: "date",
    type: filterObj.type,
    dateFrom: filterObj.dateFrom
      ? new Date(filterObj.dateFrom).toISOString()
      : undefined,
    dateTo: filterObj.dateTo
      ? new Date(filterObj.dateTo).toISOString()
      : undefined,
  };
};
