import { useFieldArray, useFormContext } from "react-hook-form";
import { IContact, ISalesPerson } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import PersonCard from "../../UI/PersonCard";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid } from "@mui/material";
import { useDisable } from "../../../hooks/use-disable";
import CheckedUserModal from "../../UI/Modal/CheckedUserModal";
import { useModal } from "../../../hooks/use-modal";
import { useEffect, useState } from "react";
// import PersonModal from "../../UI/Modal/PersonModal";

const SalesPersonList = () => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();

  const [disabled] = useDisable();

  const { fields, remove, update, replace } = useFieldArray({
    control,
    name: "sales_person_list",
  });

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const [userIds, setUsersIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUsersIdsSnapshot] = useState<string[]>([]);

  const finishUsersSelect = (data: any) => {
    // filter out users not selected in current modal session
    let filteredUsers = fields.filter((user) =>
      userIds.includes(user.unique_id)
    );

    // get current users id to prevent duplication when appending newly selected users
    const filteredUsersId = filteredUsers.map((user) => user.unique_id);

    if (!filteredUsersId.includes(data.unique_id)) {
      filteredUsers.push(data);
    }

    // sort for proerly render order (and for role assignment when submitting)
    filteredUsers = filteredUsers.sort((a, b) => {
      return userIds.indexOf(a.unique_id) - userIds.indexOf(b.unique_id);
    });
    const formatSalesPerson: ISalesPerson[] = filteredUsers.map((user) => ({
      unique_id: user.unique_id,
      first_name: user.first_name,
      img_url: user.img_url,
      last_name: user.last_name,
      position: user.position,
      title_name: user.title_name,
    }));
    replace(formatSalesPerson);
    closeModalHandler();
  };

  useEffect(() => {
    const userUniqueId = fields.map((field) => field.unique_id);
    setUsersIds(userUniqueId);
    setUsersIdsSnapshot(userUniqueId);
  }, [fields]);

  return (
    <>
      <Box my={2}>
        <Grid container spacing={1.5}>
          {fields.map((person, index) => (
            <PersonCard
              key={person.id}
              person={person}
              remove={remove}
              index={index}
              update={update}
              isSalesPerson
            />
          ))}
        </Grid>
      </Box>
      {!disabled && (
        <CustomizedButton
          variant="outlined"
          title={`${t("button.add")}${t("contact.sales_person.index")}`}
          onClick={openModalHandler}
          disabled={fields.length > 0 ? true : false}
        />
      )}
      <CheckedUserModal
        userIds={userIds}
        setUserIds={setUsersIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUsersIdsSnapshot}
        showSelectUser={modal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        rowSelection="single"
        isSalesPerson
      />
    </>
  );
};

export default SalesPersonList;
