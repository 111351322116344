import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import GoodsAdjustmentReportTable from "../../../components/Table/Inventory/Report/GoodsAdjustmentReportTable";
import { useInventoryReport } from "../../../hooks/Inventory/use-inventory-report";
import { InventoryReportType } from "../../../generated/inventory";

const GoodsAdjustmentReport = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.goods_adjustment.index"),
    },
  ];

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isExporting, isFetching } = useInventoryReport(
    gridRef,
    InventoryReportType.GoodsAdjust
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("inventory.goods_adjustment.index")}
        </Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isExporting && isFetching}
        />
      </HeaderLayout>
      <GoodsAdjustmentReportTable gridRef={gridRef} />
    </>
  );
};

export default GoodsAdjustmentReport;
