import { TreeItem, TreeView } from "@mui/x-tree-view";
import { CustomizedBox } from "../../../../components/Custom/CustomizedBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IWarehouse } from "../../../../types/Setting/Inventory";

const LocationTab = () => {
  const { getValues } = useFormContext<IWarehouse>();

  const warehouse_level_1_list = getValues("warehouse_level_1_list");

  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    if (warehouse_level_1_list) {
      const allId: string[] = [];
      warehouse_level_1_list.forEach((locationLv1) => {
        if (
          locationLv1.warehouse_level_2_list &&
          locationLv1.warehouse_level_2_list.length > 0
        ) {
          allId.push(`${locationLv1.id}`);
          locationLv1.warehouse_level_2_list.forEach((locationLv2) => {
            if (
              locationLv2.warehouse_level_3_list &&
              locationLv2.warehouse_level_3_list.length > 0
            ) {
              allId.push(`${locationLv1.id}-${locationLv2.id}`);
              locationLv2.warehouse_level_3_list.forEach((locationLv3) => {
                if (locationLv3.bin_location_list.length > 0)
                  allId.push(
                    `${locationLv1.id}-${locationLv2.id}-${locationLv3.id}`
                  );
              });
            }
          });
        }
      });
      setExpanded(allId);
    }
  }, [warehouse_level_1_list]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {warehouse_level_1_list &&
          warehouse_level_1_list.length > 0 &&
          warehouse_level_1_list.map((locationLv1) => (
            <TreeItem
              key={`${locationLv1.id}`}
              nodeId={`${locationLv1.id}`}
              label={locationLv1.name}
            >
              {locationLv1.warehouse_level_2_list &&
                locationLv1.warehouse_level_2_list.length > 0 &&
                locationLv1.warehouse_level_2_list.map((locationLv2) => (
                  <TreeItem
                    key={`${locationLv1.id}-${locationLv2.id}`}
                    nodeId={`${locationLv1.id}-${locationLv2.id}`}
                    label={<>{locationLv2.name} </>}
                  >
                    {locationLv2.warehouse_level_3_list &&
                      locationLv2.warehouse_level_3_list.length > 0 &&
                      locationLv2.warehouse_level_3_list.map((locationLv3) => (
                        <TreeItem
                          key={`${locationLv1.id}-${locationLv2.id}-${locationLv3.id}`}
                          nodeId={`${locationLv1.id}-${locationLv2.id}-${locationLv3.id}`}
                          label={locationLv3.name}
                        >
                          {locationLv3.bin_location_list &&
                            locationLv3.bin_location_list.length > 0 &&
                            locationLv3.bin_location_list.map((binLocation) => (
                              <TreeItem
                                key={binLocation.name}
                                nodeId={`${binLocation.name}`}
                                label={binLocation.name}
                              />
                            ))}
                        </TreeItem>
                      ))}
                  </TreeItem>
                ))}
            </TreeItem>
          ))}
      </TreeView>
    </CustomizedBox>
  );
};

export default LocationTab;
