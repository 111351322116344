import { Grid, Typography } from "@mui/material";
import Remark from "./Remark";
import SalesSummary from "./Summary";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import TagList from "../../../UI/TagList";
import { useFormContext } from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";
import { useTranslation } from "react-i18next";

interface Props {
  documentType: string;
  isShowRemainingPayment?: boolean;
}

const SalesFooter = ({ documentType, isShowRemainingPayment }: Props) => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const [disabled] = useDisable();
  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6.5} lg={6.5} xl={6.5}>
          <Remark />
          <Typography color={"primary.main"} fontWeight={600} mt={1}>
            {t("sales.tag_list")}
          </Typography>
          <Grid container spacing={1.5} mt={0}>
            <TagList
              fullWidth
              control={control}
              documentType={documentType}
              name="tag_list"
              label={t("sales.tag_list")}
              getValues={getValues}
              setValue={setValue}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <SalesSummary
            documentType={documentType}
            isShowRemainingPayment={isShowRemainingPayment}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default SalesFooter;
