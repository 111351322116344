import { useTranslation } from "react-i18next";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../types/Sales";
import ModalUI from "./ModalUI";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid } from "@mui/material";
import {
  deductionValidation,
  salesDeductionSchema,
} from "../../Form/Sales/CashSales/schema";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { ISelectOption } from "../../../types/global";
import { SalesReferenceDocumentType } from "../../../generated/inventory";
import { useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ActivityLogDocumentType,
  ActivityType,
  SalesInvoiceFindUniqueQuery,
  useSalesInvoiceFindUniqueQuery,
  useSalesInvoiceUpdateMutation,
} from "../../../generated/sales";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { CUSTOMER_DEPOSIT_AGGRID } from "../../../services/AgGrid/SalesAgGrid";
import { ICustomerDeposit } from "../../../types/Sales/customerDeposit";
import PaymentSummary from "../../Form/Sales/Payment/PaymentSummary";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { useStateContext } from "../../../contexts/auth-context";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
};

const deductionOptions: ISelectOption[] = [
  {
    label: "เอกสารภายนอก",
    value: "external_document",
  },
  {
    label: "ใบมัดจำ",
    value: "customer_deposit",
  },
];

const DeductionModal = ({ open, closeModalHandler }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { control: defaultControl, getValues: getDefaultValues } =
    useFormContext();

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const [customerDepositList, setCustomerDepositList] = useState<any[]>([]);

  const { refetch } =
    useSalesInvoiceFindUniqueQuery<SalesInvoiceFindUniqueQuery>(
      graphqlClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { mutateAsync: update } =
    useSalesInvoiceUpdateMutation<Error>(graphqlClient);

  const { createActivityLog } = useActivityLog();

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<ISalesDeduction>({
    defaultValues: salesDeductionSchema,
    resolver: yupResolver<any>(deductionValidation),
  });

  const issueDate = useWatch({
    control: defaultControl,
    name: "issue_date",
  });

  const paymentList: ISalesPaymentChannel[] = useWatch({
    control: defaultControl,
    name: "payment_list",
  });

  const paymentWhtAmount: number = paymentList.reduce(
    (acc, channel) => acc + (channel.withholding_tax_amount || 0),
    0
  );
  const documentType = watch(`document_type`);

  const paidTotalAmount =
    useWatch({
      control: defaultControl,
      name: "paid_total_amount",
    }) || 0;

  const netAmount =
    useWatch({
      control: defaultControl,
      name: "net_amount",
    }) || 0;

  const watchCustomerId = useWatch({
    control: defaultControl,
    name: "customer_unique_id",
  });

  useEffect(() => {
    if (open) {
      const getCdUnqiueId = async () => {
        const graphQLClientWithHeaderSales: GraphQLClient =
          createGraphQLClientWithMiddleware("sales");
        const { CustomerDepositViewsAggrid } =
          await graphQLClientWithHeaderSales.request(CUSTOMER_DEPOSIT_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: Math.pow(10, 5),
              filterModel: {
                status: {
                  values: ["partially_deduction", "wait_deduction"],
                  filterType: "set",
                },
                customer_unique_id: {
                  filterType: "text",
                  type: "contains",
                  filter: watchCustomerId,
                },
              },
              sortModel: [
                {
                  sort: "desc",
                  colId: "created_date",
                },
              ],
            },
          });

        const formatUniqueIdOptions = CustomerDepositViewsAggrid.results.map(
          (cd: ICustomerDeposit) => ({
            label: cd.unique_id,
            value: cd.unique_id,
            remaining_deposit_amount: cd.remaining_deposit_amount,
          })
        );
        setCustomerDepositList(formatUniqueIdOptions);
      };
      getCdUnqiueId();
    }
  }, [open, watchCustomerId]);

  useEffect(() => {
    if (open) {
      const paid_net_amount = getDefaultValues("paid_net_amount") || 0;
      const net_amount = getDefaultValues("net_amount");

      const new_deduction_amount =
        net_amount - (paid_net_amount + paymentWhtAmount);
      setValue("deduction_issue_date", issueDate);
      setValue("deduction_amount", new_deduction_amount);
    }
  }, [paymentWhtAmount, getDefaultValues, issueDate, open, setValue]);

  const addDeductionHandler = async (data: ISalesDeduction) => {
    try {
      const {
        price_vat_type,
        currency,
        payment_list,
        deduction_document_list,
        net_amount,
        branch_id,
        unique_id,
        aggrid_status,
      } = getDefaultValues();
      const updatedDeductionDocumentList = [data, ...deduction_document_list];

      const formatDeductionDocumentList = updatedDeductionDocumentList.map(
        ({ id, ...otherItem }: ISalesDeduction) => ({
          ...otherItem,
          branch_id: branch_id,
          reference_document_type: SalesReferenceDocumentType.SalesInvoice,
          reference_unique_id: unique_id,
        })
      );

      const paymentAmount: number =
        payment_list && payment_list.length > 0
          ? payment_list.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.payment_amount || 0),
              0
            )
          : 0;

      const paymentWhtAmount: number =
        payment_list && payment_list.length > 0
          ? payment_list.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.withholding_tax_amount || 0),
              0
            )
          : 0;

      const deductionAmount: number =
        formatDeductionDocumentList && formatDeductionDocumentList.length > 0
          ? formatDeductionDocumentList.reduce(
              (acc: number, deduct: ISalesDeduction) =>
                acc + (deduct.deduction_amount || 0),
              0
            )
          : 0;

      const paidNetAmount: number = paymentAmount + deductionAmount;

      const paidTotalAmount: number = paidNetAmount + paymentWhtAmount;

      if (isValid) {
        const updatedMainStatus =
          parseFloat(paidTotalAmount.toFixed(2)) <
          parseFloat(net_amount.toFixed(2))
            ? "partially_payment"
            : "fully_payment";

        const { SalesInvoiceUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: {
            currency,
            price_vat_type,
            main_status: updatedMainStatus,
            deduction_document_list: formatDeductionDocumentList,
            paid_net_amount: paidNetAmount,
            payment_total_amount: paymentAmount,
            deduction_total_amount: deductionAmount,
            paid_total_amount: paidTotalAmount,
            last_updated_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          },
        });
        enqueueSnackbar(`รับชำระสำเร็จ`, {
          variant: "success",
        });
        await refetch();

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.SalesInvoice,
          reference_id: SalesInvoiceUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
            prev_status: aggrid_status,
            curr_status: updatedMainStatus,
          },
        });
        onClose();
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`รับชำระไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onClose = () => {
    reset(salesDeductionSchema);
    closeModalHandler();
  };

  const currentDeductionAmount = watch("deduction_amount");

  return (
    <ModalUI
      title={t("sales.deduction.index")}
      open={open}
      handleClose={onClose}
      maxWidth="md"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.add")}
            variant="contained"
            onClick={handleSubmit(addDeductionHandler)}
            disabled={
              parseFloat(paidTotalAmount.toFixed(2)) +
                parseFloat((currentDeductionAmount || 0).toFixed(2)) >
              parseFloat(netAmount.toFixed(2))
            }
          />
        </Box>
      }
    >
      <>
        <Grid container spacing={1.5} mt={0}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledSelect
              control={control}
              name={`document_type`}
              label={t("sales.deduction.document_type")}
              options={deductionOptions}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  setValue(`document_unique_id`, "");
                  setValue("credit_balance", 0);
                }
              }}
              required
              error={Boolean(errors?.document_type)}
              helperText={errors?.document_type?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {documentType === "customer_deposit" ? (
              <ControlledSelect
                control={control}
                name={`document_unique_id`}
                label={t("sales.deduction.document_unique_id")}
                options={customerDepositList}
                required
                error={Boolean(errors?.document_unique_id)}
                helperText={errors?.document_unique_id?.message}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value) {
                    const foundCustomerDeposit = customerDepositList.find(
                      (cd) => cd.label === value
                    );
                    setValue(
                      `credit_balance`,
                      foundCustomerDeposit?.remaining_deposit_amount || 0
                    );
                  }
                }}
              />
            ) : (
              <ControlledTextField
                control={control}
                name={`document_unique_id`}
                label={t("sales.deduction.document_unique_id")}
                required
                error={Boolean(errors?.document_unique_id)}
                helperText={errors?.document_unique_id?.message}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledNumberTextField
              textAlign="right"
              fullWidth
              control={control}
              name={`deduction_amount`}
              label={t("sales.deduction.deduction_amount")}
              error={Boolean(errors?.deduction_amount)}
              helperText={errors?.deduction_amount?.message}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledNumberTextField
              textAlign="right"
              fullWidth
              control={control}
              name={`credit_balance`}
              label={t("sales.deduction.credit_balance")}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledDatePicker
              control={control}
              name={`deduction_issue_date`}
              label={t("sales.deduction.deduction_issue_date")}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              label={t("sentence.remark")}
              control={control}
              name={`remark`}
              multiline
              minRows={1}
              maxRows={5}
            />
          </Grid>
        </Grid>
      </>
      <Box mt={2}>
        <PaymentSummary
          isModal
          currentDeductionAmount={currentDeductionAmount}
        />
      </Box>
    </ModalUI>
  );
};

export default DeductionModal;
