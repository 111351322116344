import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Typography, Grid } from "@mui/material";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useFormContext } from "react-hook-form";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import ControlledSSCreatable from "../../Controller/ControlledSSCreatable";
import { useContactOption } from "../../../hooks/use-contact-option";

type Props = {
  type: "customer" | "vendor";
};

const ContactDetail = ({ type }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [disabled] = useDisable();

  const { groupsOption, typesOption, saleChannelsOption, zonesOption } =
    useContactOption(type);

  return (
    <CustomizedBox margin="0 0 1.5rem 0">
      <Typography color="primary.main" fontWeight={600} mb={2}>
        รายละเอียด{t(`contact.contact_type.${type}`)}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            label={t(`contact.info.source.${type}`)}
            control={control}
            documentType={type}
            name="source"
            setValue={setValue}
            defaultOptions={[]}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t(`contact.info.type.${type}`)}
              value={getValues("type")}
            />
          ) : (
            <ControlledSelect
              name="type"
              control={control}
              label={t(`contact.info.type.${type}`)}
              error={Boolean(errors.type)}
              helperText={errors.type?.message?.toString()}
              options={typesOption}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t(`contact.info.group.${type}`)}
              value={getValues("group")}
            />
          ) : (
            <ControlledSelect
              name="group"
              control={control}
              label={t(`contact.info.group.${type}`)}
              error={Boolean(errors.group)}
              helperText={errors.group?.message?.toString()}
              options={groupsOption}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t(`contact.info.zone`)}
              value={getValues("zone")}
            />
          ) : (
            <ControlledSelect
              name="zone"
              control={control}
              label={t(`contact.info.zone`)}
              error={Boolean(errors.zone)}
              helperText={errors.zone?.message?.toString()}
              options={zonesOption}
            />
          )}
        </Grid>
        {type === "customer" && (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {disabled ? (
              <LabelInput
                label={t(`contact.info.sales_channel`)}
                value={getValues("sales_channel")}
              />
            ) : (
              <ControlledSelect
                name="sales_channel"
                control={control}
                label={t(`contact.info.sales_channel`)}
                error={Boolean(errors.sales_channel)}
                helperText={errors.sales_channel?.message?.toString()}
                options={saleChannelsOption || []}
              />
            )}
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default ContactDetail;
