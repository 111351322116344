import Avatar from "@mui/material/Avatar";
import { CustomizedTooltip } from "./CustomizedTooltip";
import { SxProps } from "@mui/material";
import { forwardRef } from "react";

interface Props {
  name: string;
  sx?: SxProps;
  noTooltip?: boolean;
}

// function stringToColor(string: string) {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = "#";

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// }

const stringThai = [
  "ะ",
  "า",
  "ิ",
  "ี",
  "ึ",
  "ื",
  "ุ",
  "ู",
  "เ",
  "แ",
  "ไ",
  "ใ",
  "โ",
  "ั",
  "ำ",
];

const findNotExcludeText = (name: string) => {
  let onlyText = [];
  for (let i = 0; i < name.length; i++) {
    if (!stringThai.includes(name[i])) onlyText.push(name[i]);
  }
  return onlyText[0] || null;
};

export function stringAvatar(name: string, sx?: SxProps) {
  return {
    sx: {
      bgcolor: "#324290",
      ...sx,
    },
    children: `${findNotExcludeText(name.split(" ")[0])}${findNotExcludeText(
      name.split(" ")[1]
    )}`,
  };
}

const CustomizedLetterAvatar = forwardRef<any, Props>(
  ({ name, sx, noTooltip }, ref) => {
    return noTooltip ? (
      <Avatar ref={ref} {...stringAvatar(name, sx)} />
    ) : (
      <CustomizedTooltip title={name}>
        <Avatar ref={ref} {...stringAvatar(name, sx)} />
      </CustomizedTooltip>
    );
  }
);

export default CustomizedLetterAvatar;
