import { useRef } from "react";
import useGlobalSettingColumnDefs from "./useGlobalSettingColumnDefs";
import AgGrid from "../../../UI/AgGrid";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  data: any[];
  attribute?: boolean;
};

const GlobalSettingTable = ({
  onEditHandler,
  onDeleteHandler,
  data,
  attribute,
}: Props) => {
  const gridRef = useRef(null);
  const columnDefs = useGlobalSettingColumnDefs(onEditHandler, onDeleteHandler);

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
    />
  );
};

export default GlobalSettingTable;
