import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useRef, useState } from "react";
import TotalBox from "../../../components/UI/TotalBox";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import GoodsReturnTable from "../../../components/Table/Inventory/GoodsReturn";
import { AgGridReact } from "ag-grid-react";
import { IGoodsReturn } from "../../../types/Inventory/GoodsReturn";

const GoodsReturn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const [rowCount, setRowCount] = useState<number>(0);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_return.index"),
    },
  ];

  const gridRef = useRef<AgGridReact<IGoodsReturn>>(null);

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_receive"),
      path: `${pathname}?filter=wait_receive`,
    },
    {
      label: t("status.in_transit"),
      path: `${pathname}?filter=in_transit`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current?.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <TotalBox title={t("inventory.all")} value={rowCount} />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("inventory.goods_return.index")}
        </Typography>
        <CustomizedButton
          title={`${t("inventory.goods_return.index")}`}
          variant="contained"
          onClick={() => navigate(`${pathname}/add`)}
          addIcon
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.reset_filter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      <GoodsReturnTable
        gridRef={gridRef}
        isFilter={isFilter}
        setRowCount={setRowCount}
      />
    </>
  );
};

export default GoodsReturn;
