import { useFormContext, useFieldArray } from "react-hook-form";
import { IContact, IContactAttribute } from "../../../types/Contact/contact";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  // IconButton,
} from "@mui/material";
import useAttributeColumn from "../../../hooks/use-attribute-column";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
// import ClearIcon from "@mui/icons-material/Clear";
// import CustomizedButton from "../../Custom/CustomizedButton";
// import { t } from "i18next";
import { useDisable } from "../../../hooks/use-disable";
// import { useRef, useState } from "react";
// import AttributeTableModal from "../../UI/Modal/AttributeTableModal";
// import { AgGridReact } from "ag-grid-react";
import { useContactOption } from "../../../hooks/use-contact-option";
import { useEffect } from "react";
// import { useParams } from "react-router-dom";

interface Props {
  type: "customer" | "vendor";
}

const ContactAttribute = ({ type }: Props) => {
  // const gridRef = useRef<AgGridReact<any>>(null);
  // const [attributeModal, setAttributeModal] = useState<boolean>(false);
  // const { id } = useParams();
  const { control, getValues } = useFormContext<IContact>();

  const [disabled] = useDisable();

  const { fields, replace } = useFieldArray({
    control,
    name: "attribute_list",
  });

  const { attributes } = useContactOption(type);

  useEffect(() => {
    if (attributes) {
      const currentAttributes = getValues("attribute_list");
      let formatAttributes: IContactAttribute[] = attributes.map(
        (attribute) => ({
          name: attribute.name || "",
          short_name: attribute.short_name || "",
          value:
            currentAttributes.find((curr) => curr.name === attribute.name)
              ?.value || "",
        })
      );
      replace(formatAttributes);
    }
  }, [attributes, getValues, replace]);

  // const openAttributeHandler = () => {
  //   setAttributeModal(true);
  // };

  // const closeAttributeHandler = () => {
  //   setAttributeModal(false);
  // };

  // const addAttributeHandler = () => {
  //   const selectedAttributes = gridRef?.current?.api.getSelectedRows();
  //   if (selectedAttributes) {
  //     selectedAttributes.forEach((attribute) => {
  //       let formatAttribute: IContactAttribute = {
  //         name: attribute.name,
  //         short_name: attribute.short_name,
  //         value: "",
  //       };
  //       append(formatAttribute);
  //     });
  //   }
  //   closeAttributeHandler();
  // };

  // const removeAttributeHandler = (index: number) => {
  //   remove(index);
  // };

  const headers = useAttributeColumn(true);

  return (
    <>
      <CustomizedBox maxWidth={1040} bgcolor="primary.light">
        <TableContainer>
          <Table sx={{ overflow: "scroll" }}>
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color="primary.main"
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((attribute, index) => (
                <TableRow key={attribute.id}>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography fontSize={14}>{attribute.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography fontSize={14}>
                      {attribute.short_name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <ControlledTextField
                      control={control}
                      name={`attribute_list.${index}.value`}
                      viewMode={disabled}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomizedBox>
      {/* {!disabled && (
        <CustomizedButton
          title={t("button.add") + t("contact.attribute.index")}
          variant="outlined"
          onClick={openAttributeHandler}
        />
      )} */}
      {/* <AttributeTableModal
        ref={gridRef}
        attributeModal={attributeModal}
        addAttributesHandler={addAttributeHandler}
        closeAttributeModalHandler={closeAttributeHandler}
        type={type}
      /> */}
    </>
  );
};

export default ContactAttribute;
