import { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import GoodsIssueTab from "./GoodsIssueTab";
import DocumentInfoTab from "./DocumentInfoTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import BreadcrumbsLayout from "../../../components/UI/BreadcrumbsLayout";
import {
  purchaseReturnSchema,
  purchaseReturnValidation,
} from "../../../components/Form/Purchase/Return/schema";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { IPurchaseReturn } from "../../../types/Purchase/purchaseReturn";

import { ActivityLogDocumentType } from "../../../generated/inventory";
import { yupResolver } from "@hookform/resolvers/yup";

const PurchaseReturnContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const methods = useForm<IPurchaseReturn>({
    defaultValues: { ...purchaseReturnSchema },
    resolver: yupResolver<any>(purchaseReturnValidation),
  });

  const { control, getValues } = methods;

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.goods_issue.index"),
      path: `${pathname}?tab=goods_issue`,
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "goods_issue":
        return <GoodsIssueTab referenceUniqueId={getValues("unique_id")} />;
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.return.index"),
      to: "/purchase/return",
    },
    {
      name: id
        ? getValues("unique_id") || "-"
        : t("button.create") + t("purchase.return.index"),
    },
  ];

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        setOpenDrawer={setOpenDrawer}
      />
      {(status === "approved" || status === "fully_returned") && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.PurchaseReturn}
        />
      )}
    </FormProvider>
  );
};

export default PurchaseReturnContainer;
