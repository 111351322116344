import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import { Divider, Typography } from "@mui/material";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import PaymentInfo from "./PaymentInfo";
import { ISalesPaymentChannel } from "../../../../types/Sales";
import ControlledCheckbox from "../../../Controller/ControlledCheckbox";
import { useDisable } from "../../../../hooks/use-disable";
import {
  salesDeductionSchema,
  salesPaymentChannelSchema,
} from "../CashSales/schema";
import DeductionInfo from "./DeductionInfo";
import PaymentSummary from "./PaymentSummary";

type Props = {
  documentType: string;
};

const SalesPayment = ({ documentType }: Props) => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext();
  const [disabled] = useDisable();

  // const [withholdingTaxRate, setWithholdingTaxRate] =
  //   useState<string>("กำหนดเอง");
  // const [withholdingTaxAmount, setWithholdingTaxAmount] = useState<number>(0);
  // const [postDiscountWhtAmount, setPostDiscountWhtAmount] = useState<number>(0);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "payment_list",
  });

  const {
    fields: deductDoc,
    remove: removeDeductDoc,
    append: appendDeductDoc,
  } = useFieldArray({
    control,
    name: "deduction_document_list",
  });

  // const itemList: ISalesItemList[] = useWatch({
  //   control,
  //   name: documentType === "customer_deposit" ? "deposit_list" : "item_list",
  // });

  // const allWithholdingTax = itemList.map((item) => item.withholding_tax_type);

  const issueDate = useWatch({
    control,
    name: "issue_date",
  });

  const isHavePayment = useWatch({
    control,
    name: "is_have_payment",
  });

  const isHaveDeduct = useWatch({
    control,
    name: "is_have_deduct",
  });

  // useEffect(() => {
  //   if (
  //     allWithholdingTax.every((tax) => tax === "ยังไม่ระบุ" || tax === "ไม่มี")
  //   ) {
  //     setWithholdingTaxRate("กำหนดเอง");
  //   } else {
  //     if (allWithholdingTax.every((tax) => tax === allWithholdingTax[0])) {
  //       setWithholdingTaxRate(allWithholdingTax[0]);
  //     } else {
  //       setWithholdingTaxRate("กำหนดเอง");
  //     }
  //     setWithholdingTaxAmount(allWithholdingTaxAmount);
  //   }
  // }, [allWithholdingTax, allWithholdingTaxAmount]);

  // useEffect(() => {
  //   if (documentType === "customer_deposit") {
  //     const post_discount_amount = itemList.reduce((acc, item) => {
  //       if (
  //         item.withholding_tax_type !== "ยังไม่ระบุ" &&
  //         item.withholding_tax_type !== "ไม่มี"
  //       ) {
  //         if (
  //           item.vat_percentage === "7" &&
  //           priceVatType === PriceVatType.IncludeVat
  //         ) {
  //           return acc + item.pre_vat_amount / 1.07;
  //         }
  //         return acc + item.pre_vat_amount;
  //       }
  //       return acc;
  //     }, 0);
  //     setPostDiscountWhtAmount(post_discount_amount);
  //   } else {
  //     const total_pre_vat_amount = itemList.reduce(
  //       (acc, item) => acc + item.pre_vat_amount,
  //       0
  //     );

  //     const post_discount_amount = itemList.reduce((acc, item) => {
  //       const item_additional_discount =
  //         additionalDiscount * (item.pre_vat_amount / total_pre_vat_amount) ||
  //         0;

  //       if (
  //         item.withholding_tax_type !== "ยังไม่ระบุ" &&
  //         item.withholding_tax_type !== "ไม่มี"
  //       ) {
  //         if (
  //           item.vat_percentage === "7" &&
  //           priceVatType === PriceVatType.IncludeVat
  //         ) {
  //           return (
  //             acc + (item.pre_vat_amount - item_additional_discount) / 1.07
  //           );
  //         }
  //         return acc + (item.pre_vat_amount - item_additional_discount);
  //       }
  //       return acc;
  //     }, 0);

  //     setPostDiscountWhtAmount(post_discount_amount);
  //   }
  // }, [additionalDiscount, documentType, itemList, priceVatType]);

  const removePaymentHandler = (index: number) => {
    remove(index);
  };

  const addPaymentHandler = () => {
    const deduction_total_amount = getValues("deduction_total_amount");
    const net_amount = getValues("net_amount");

    // if (
    //   allWithholdingTax.some((tax) => tax !== "ยังไม่ระบุ" && tax !== "ไม่มี")
    // ) {
    //   const { is_withholding_tax, payment_date, payment_amount, ...other } =
    //     salesPaymentChannelSchema;

    //   let new_payment_amount = 0;
    //   if (isHaveDeduct) {
    //     new_payment_amount = net_amount - deduction_total_amount;
    //   } else {
    //     new_payment_amount = net_amount;
    //   }
    //   append({
    //     ...other,
    //     is_withholding_tax: true,
    //     payment_date: issueDate,
    //     payment_amount: new_payment_amount,
    //   });
    // } else {
    let new_payment_amount = 0;
    const { payment_date, ...other } = salesPaymentChannelSchema;
    if (isHaveDeduct) {
      new_payment_amount = net_amount - deduction_total_amount;
    } else {
      new_payment_amount = net_amount;
    }
    append({
      ...other,
      payment_date: issueDate,
      payment_amount: new_payment_amount,
    });
    // }
  };

  const removeDeductHandler = (index: number) => {
    removeDeductDoc(index);
  };

  const addDeductHandler = () => {
    const { deduction_issue_date, deduction_amount, ...other } =
      salesDeductionSchema;
    const paymentList: ISalesPaymentChannel[] = getValues("payment_list");
    const payment_total_amount = getValues("payment_total_amount");
    const net_amount = getValues("net_amount");

    let new_deduction_amount = 0;
    if (isHavePayment) {
      const paymentWhtAmount: number = paymentList.reduce(
        (acc, channel) => acc + (channel.withholding_tax_amount || 0),
        0
      );

      new_deduction_amount =
        net_amount - (payment_total_amount + paymentWhtAmount);
    } else {
      new_deduction_amount = net_amount;
    }
    appendDeductDoc({
      ...other,
      deduction_amount: new_deduction_amount,
      deduction_issue_date: issueDate,
    });
  };

  return (
    <CustomizedBox margin={0}>
      <Typography fontWeight={600} color={"primary.main"} mb={1}>
        {t("sales.payment.index")}
      </Typography>

      {documentType !== "customer_deposit" && (
        <>
          <ControlledCheckbox
            control={control}
            name="is_have_deduct"
            label={t("button.add") + t("sales.deduction.index")}
            disabled={disabled}
            onChange={(_, checked) => {
              if (checked) {
                addDeductHandler();
              } else {
                removeDeductHandler(0);
              }
            }}
          />
          {isHaveDeduct && (
            <>
              {deductDoc.map((deduct, index) => (
                <CustomizedBox
                  margin={
                    index === 0
                      ? "0 0 1rem 0"
                      : index === deductDoc.length - 1
                      ? "1rem 0rem"
                      : index % 2 !== 0
                      ? 0
                      : "1rem 0rem"
                  }
                  bgcolor="#EFF2FF"
                  key={deduct.id}
                >
                  <DeductionInfo
                    index={index}
                    removeDeductHandler={removeDeductHandler}
                    type={documentType}
                  />
                </CustomizedBox>
              ))}
              {/* {!disabled && (
            <Box>
              <CustomizedButton
                title="+ เพิ่มการตัดกับเอกสาร"
                onClick={addDeductHandler}
              />
            </Box>
          )} */}
            </>
          )}
          <Divider variant="fullWidth" sx={{ my: 0.5 }} />
        </>
      )}
      {documentType !== "customer_deposit" && (
        <ControlledCheckbox
          control={control}
          name="is_have_payment"
          label={t("button.add") + t("sales.payment.index")}
          disabled={disabled}
          onChange={(_, checked) => {
            if (checked) {
              addPaymentHandler();
            } else {
              removePaymentHandler(0);
            }
          }}
        />
      )}
      {(isHavePayment || documentType === "customer_deposit") && (
        <>
          {fields.map((field, index) => (
            <CustomizedBox
              margin={
                index === 0
                  ? "0 0 1rem 0"
                  : index === fields.length - 1
                  ? "1rem 0rem"
                  : index % 2 !== 0
                  ? 0
                  : "1rem 0rem"
              }
              bgcolor="#EFF2FF"
              key={field.id}
            >
              <PaymentInfo
                index={index}
                // removePaymentHandler={removePaymentHandler}
                // allWithholdingTaxAmount={allWithholdingTaxAmount}
                documentType={documentType}
              />
            </CustomizedBox>
          ))}
          {/* {!disabled && (
            <Box>
              <CustomizedButton
                title="+ เพิ่มช่องทางการชำระเงิน"
                onClick={addPaymentHandler}
              />
            </Box>
          )} */}
        </>
      )}
      {/* <Divider variant="fullWidth" sx={{ my: 0.5 }} /> */}
      <PaymentSummary />
    </CustomizedBox>
  );
};

export default SalesPayment;
