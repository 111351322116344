import React, { createContext, useMemo, useState, ReactNode } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

interface INavbarContext {
  sidebar: {
    isSidebarOpen: boolean;
    setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

interface Props {
  children?: ReactNode;
}

export const NavbarContext = createContext<INavbarContext | null>(null);

export const NavbarContextProvider = ({ children }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isSmallScreen);

  const sidebar = useMemo(
    () => ({ isSidebarOpen, setIsSidebarOpen }),
    [isSidebarOpen, setIsSidebarOpen]
  );

  const navbarContext = {
    sidebar,
  };

  return (
    <NavbarContext.Provider value={navbarContext}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
