import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "เลขที่เอกสาร PO",
    width: 150,
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
  },
  {
    thaiLabel: "จำนวนนำเข้าแล้ว",
    width: 100,
  },
  {
    thaiLabel: "จำนวนรอนำเข้า",
    width: 100,
  },

  {
    thaiLabel: "จำนวนที่นำเข้า",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "ราคา",
    width: 100,
  },
  {
    label: "",
    thaiLabel: "",
    width: 20,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 150,
  },
  {
    thaiLabel: "Lot",
    width: 140,
  },
  {
    thaiLabel: "วันที่ผลิต",
    width: 140,
  },
  {
    thaiLabel: "วันที่หมดอายุ",
    width: 140,
  },
];

const defaultScanHeader: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "เลขที่เอกสาร PO",
    width: 150,
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
  },
  {
    thaiLabel: "จำนวนนำเข้าแล้ว",
    width: 100,
  },
  {
    thaiLabel: "จำนวนรอนำเข้า",
    width: 100,
  },

  {
    thaiLabel: "จำนวนที่นำเข้า",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "ราคา",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "สถานที่",
    width: 200,
  },
  {
    thaiLabel: "",
    width: 75,
  },
  {
    thaiLabel: "ผู้สแกน",
    width: 100,
  },

  {
    thaiLabel: "หมายเหตุ",
    width: 150,
  },
  {
    thaiLabel: "Lot",
    width: 140,
  },
  {
    thaiLabel: "วันที่ผลิต",
    width: 140,
  },
  {
    thaiLabel: "วันที่หมดอายุ",
    width: 140,
  },
];

const headerPdf: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 20,
  },
  // {
  //   thaiLabel: "รูป",
  //   width: 50,
  // },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 110,
  },
  {
    thaiLabel: "เลขที่เอกสาร",
    width: 75,
  },
  {
    thaiLabel: "จำนวนนำเข้า - หน่วย",
    width: 105,
  },
  {
    thaiLabel: "Batch/SN",
    width: 120,
  },
  {
    thaiLabel: "Barcode",
    width: 200,
  },
  {
    thaiLabel: "สถานที่",
    width: 130,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 80,
  },
  {
    thaiLabel: "LOT",
    width: 70,
  },
];

export const useGoodsReceiveItemList = (disabled: boolean, status?: string) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!status || status === "draft") {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    } else {
      setHeaders(defaultScanHeader);
    }
  }, [disabled, status]);

  return headers;
};

export const useGoodsReceiveItemListPdf = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders(headerPdf);
  }, []);

  return headers;
};
