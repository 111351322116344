import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { Fragment } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";

const SalesReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.report.sales_by_customer"),
      to: "sales-by-customer",
    },
    {
      name: t("sales.report.sales_by_branch"),
      to: "sales-by-branch",
    },
    {
      name: t("sales.report.sales_by_item"),
      to: "sales-by-item",
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default SalesReport;
