import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ISelectOption } from "../../../../types/global";

import { Grid } from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledSelect from "../../../Controller/ControlledSelect";
// import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

import RadioBox from "../../../UI/RadioBox";
import ImporterDropzoneUI from "../../../UI/ImporterDropzoneUI";

const UploadFilePriceList = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const type = watch("type");
  const fileType = watch("fileType");

  const fileTypeOptions: ISelectOption[] = [
    {
      label: t("inventory.price_list.import.all_item"),
      value: "all_item",
    },
    {
      label: t("inventory.price_list.import.by_item"),
      value: "by_item",
    },
  ];

  const downloadTemplateHandler = () => {
    let filePath: string = "";
    const basePath: string = "/static/importer/items/pricelist/";
    filePath = basePath + `pricelist_${fileType}_importer_template.xlsx`;
    window.open(filePath, "_blank");
  };

  return (
    <Fragment>
      <Grid container spacing={2} mb={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <ControlledSelect
            control={control}
            name="fileType"
            options={fileTypeOptions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedButton
            title={t("inventory.items.import.download_template")}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={downloadTemplateHandler}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <CustomizedTooltip title="">
            <InfoOutlinedIcon />
          </CustomizedTooltip>
        </Grid> */}
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "create");
            }}
            label={t("button.create")}
            checked={type === "create"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "update");
            }}
            label={t("button.edit")}
            checked={type === "update"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      <ImporterDropzoneUI hasLabelHeader />
    </Fragment>
  );
};

export default UploadFilePriceList;
