import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { ISelectOption } from "../../../../types/global";
import { ICustomerDeposit } from "../../../../types/Sales/customerDeposit";
import { useDisable } from "../../../../hooks/use-disable";
import { useDepositListHeader } from "../../../../hooks/Sales/use-deposit-list-header";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { IDepositList } from "../../../../types/Sales";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TocIcon from "@mui/icons-material/Toc";
import ControlledTextField from "../../../Controller/ControlledTextField";
import {
  numberFormatter,
  vatTypeFormatter,
} from "../../../../utils/Formatter/Global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CloseIcon from "@mui/icons-material/Close";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import SumPreVatAmount from "../ItemList/SumPreVatAmount";
import { useParams } from "react-router-dom";

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const DepositList = () => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<ICustomerDeposit>();
  const [disabled] = useDisable();
  const { id } = useParams();

  const headers = useDepositListHeader(disabled);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const { fields, move, remove, append } = useFieldArray({
    control,
    name: "deposit_list",
    keyName: "genId",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const addItemsHandler = () => {
    const document_unique_id = getValues("unique_id");

    const newItem: IDepositList = {
      reference_document_id: document_unique_id,
      name: "",
      vat_percentage: "ไม่มี",
      pre_vat_amount: 0,
      withholding_tax_type: "ยังไม่ระบุ",
      withholding_tax_value: 0,
      remark: "",
    };

    append(newItem);
  };

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const tableDisabled = Boolean(id) && (disabled || status !== "draft");

  return (
    <CustomizedBox sx={{ breakAfter: "auto" }} margin={0} maxWidth={1650}>
      <Typography fontWeight={600} color={"primary.main"} mb={2}>
        รายการมัดจำ
      </Typography>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header?.align || "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      // width: header.width,
                      justifyContent: header?.align || "center",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={tableDisabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.genId}
                      draggableId={"item-" + field.genId}
                      index={index}
                      isDragDisabled={tableDisabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!tableDisabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {field.name}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`deposit_list.${index}.name`}
                                error={Boolean(
                                  errors?.deposit_list &&
                                    errors?.deposit_list[index] &&
                                    errors?.deposit_list[index]?.name
                                )}
                                // helperText={
                                //   errors?.deposit_list &&
                                //   errors?.deposit_list[index] &&
                                //   errors?.deposit_list[index]?.name?.message
                                // }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {vatTypeFormatter(field.vat_percentage)}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`deposit_list.${index}.vat_percentage`}
                                error={Boolean(
                                  errors?.deposit_list &&
                                    errors?.deposit_list[index] &&
                                    errors?.deposit_list[index]?.vat_percentage
                                )}
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {numberFormatter(field.pre_vat_amount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                fullWidth
                                control={control}
                                name={`deposit_list.${index}.pre_vat_amount`}
                                error={Boolean(
                                  errors?.deposit_list &&
                                    errors?.deposit_list[index] &&
                                    errors?.deposit_list[index]?.pre_vat_amount
                                )}
                                textAlign="right"
                                // helperText={
                                //   errors?.deposit_list &&
                                //   errors?.deposit_list[index] &&
                                //   errors?.deposit_list[index]?.pre_vat_amount
                                //     ?.message
                                // }
                              />
                            )}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            <WithholdingTaxCell
                              control={control}
                              errors={errors}
                              index={index}
                              documentType={"customer_deposit"}
                              disabled={tableDisabled}
                              setValue={setValue}
                              field={field}
                            />
                          </TableCell> */}
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`deposit_list.${index}.remark`}
                                error={Boolean(
                                  errors?.deposit_list &&
                                    errors?.deposit_list[index] &&
                                    errors?.deposit_list[index]?.remark
                                )}
                              />
                            )}
                          </TableCell>
                          {!tableDisabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[5]?.width || 0
                                  : 0) + 16
                              }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!tableDisabled && (
        <CustomizedButton
          sx={{ mt: 2 }}
          title="เพิ่มรายการ"
          variant="outlined"
          onClick={addItemsHandler}
          addIcon
        />
      )}
      <Grid container maxWidth={984} spacing={0}>
        <Grid item xs={12} sm={12} md={5} />
        <Grid item xs={12} sm={12} md={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
    </CustomizedBox>
  );
};

export default DepositList;
