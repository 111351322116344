import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";

type IDefault = {
  value: string;
};

type Props = {
  title: string;
  open: boolean;
  closeModalHandler: () => void;
  onSubmit: (data: IDefault) => void;
};

const LocationModal = ({ title, open, closeModalHandler, onSubmit }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IDefault>();
  const { t } = useTranslation();

  return (
    <ModalUI
      title={`${title === "deleteLocation" ? "ยืนยันการลบ" : ""}${t(
        `setting.inventory.location.${title}`
      )}`}
      open={open}
      handleClose={closeModalHandler}
      maxWidth="xs"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={closeModalHandler}
          />
          <CustomizedButton
            title={t("button.save")}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      }
    >
      {title !== "deleteLocation" && (
        <Box mt={1}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <ControlledTextField
                name={"value"}
                control={control}
                label={t("setting.inventory.location.index")}
                error={Boolean(errors.value)}
                helperText={errors.value?.message?.toString()}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </ModalUI>
  );
};

export default LocationModal;
