import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { IAttribute, ISelectOption } from "../../../types/global";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ControlledSelect from "../../Controller/ControlledSelect";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: IAttribute) => void;
  onUpdateHandler: (data: IAttribute) => void;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const attributeSchema: IAttribute = {
  // set_item_attribute_id: 0,
  name: "",
  short_name: "",
  type: "",
  description: "",
};

const attributeValidation = Yup.object({
  name: Yup.string().required("กรุณาระบุชื่อ"),
  short_name: Yup.string().required("กรุณาระบุตัวย่อ"),
});

const attributeTypeOption: ISelectOption[] = [
  {
    label: "ทศนิยม",
    value: "ทศนิยม",
  },
  {
    label: "จำนวนเต็ม",
    value: "จำนวนเต็ม",
  },
  {
    label: "ตัวอักษร",
    value: "ตัวอักษร",
  },
];

const AttributeModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  data,
  isLoading,
  isSuccess,
}: Props) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const attribute_id = searchParams.get("attribute_id");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: attributeSchema,
    resolver: yupResolver<any>(attributeValidation),
  });

  const onClose = () => {
    closeModalHandler();
    reset(attributeSchema);
  };

  useEffect(() => {
    if (isSuccess) {
      reset(data);
    }
  }, [data, id, isSuccess, reset, setValue]);

  return (
    <ModalUI
      isLoading={Boolean(attribute_id && isLoading)}
      title={attribute_id ? t("sentence.edit") : t("button.add")}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {attribute_id ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={() => {
                handleSubmit(onUpdateHandler)();
                onClose();
              }}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={() => {
                handleSubmit(onAddHandler)();
                onClose();
              }}
            />
          )}
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.attribute.name")}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message?.toString()}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.attribute.short_name")}
            control={control}
            name="short_name"
            error={Boolean(errors?.short_name)}
            helperText={
              errors?.short_name && errors.short_name.message?.toString()
            }
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledSelect
            label={t("setting.attribute.type")}
            control={control}
            name="type"
            error={Boolean(errors?.type)}
            helperText={errors?.type && errors.type.message?.toString()}
            options={attributeTypeOption}
          />
        </Grid>
      </Grid>
      <Typography
        color="primary.main"
        fontWeight={600}
        fontSize={14}
        mt={3}
        mb={2}
      >
        คำอธิบาย
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.description")}
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={
              errors?.description && errors.description.message?.toString()
            }
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default AttributeModal;
