import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { useStateContext } from "../../contexts/auth-context";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import { useNavigate } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ValidateTokenQuery,
  useBranchUserGetByUserIdQuery,
  useValidateTokenQuery,
} from "../../generated/general";
import { IBranch } from "../../types/Setting/Branch";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import { useBranchFilter } from "../../hooks/use-branch-filter";
import CustomizedTextField from "../Custom/CustomizedTextField";

const BranchBadge = () => {
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useValidateTokenQuery<ValidateTokenQuery, Error>(
    graphQLClient,
    {},
    {
      enabled: false,
    }
  );

  const currentBranchId = sessionStorage.getItem("branch-id");

  const { data } = useBranchUserGetByUserIdQuery(graphQLClient, {
    userId: authUser?.id,
  });

  const [currentBranch, setCurrentBranch] = useState<IBranch>();

  useEffect(() => {
    const defaultBranch = data?.BranchUserGetByUserId.find(
      (branch) => branch.branch_id === parseInt(currentBranchId ?? "")
    )?.branch as IBranch;
    setCurrentBranch(defaultBranch);
  }, [currentBranchId, data?.BranchUserGetByUserId]);
  const [branchList, setBranchList] = useState<IBranch[]>([]);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  useEffect(() => {
    const filteredBranchList = data?.BranchUserGetByUserId.filter(
      (branch) => branch.branch_id !== parseInt(currentBranchId ?? "")
    ).map((branchUser) => branchUser.branch) as IBranch[];

    setBranchList(filteredBranchList);
  }, [currentBranchId, data?.BranchUserGetByUserId]);

  const onBranchChangeHandler = (branchId?: number, isHq?: boolean) => {
    sessionStorage.setItem("branch-id", branchId?.toString() || "");
    sessionStorage.setItem("is_hq", isHq ? "true" : "false");

    const foundBranch = data?.BranchUserGetByUserId.find(
      (branch) => branch.branch_id === branchId
    )?.branch as IBranch;
    setCurrentBranch(foundBranch);
    closeActionsHandler();
    navigate("/landing", {
      replace: true,
    });
    refetch();
  };

  const sortByName = (a: IBranch, b: IBranch) => {
    // Use localeCompare for case-insensitive sorting
    return a.name.localeCompare(b.name);
  };

  const sortedBranchList = branchList?.sort(sortByName) || [];

  const { filterText, filteredDataMemoized, handleInputChange } =
    useBranchFilter();

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        disableAutoFocusItem
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                width: 10,
                left: "50%",
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <MenuItem disabled sx={{ opacity: "1 !important" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              component="span"
              sx={{
                borderRadius: "50%",
                width: 32,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              bgcolor={(theme) => theme.palette.primary.light}
            >
              <DomainOutlinedIcon color="primary" />
            </Box>
            <Box>
              <Typography
                fontWeight={600}
                fontSize={14}
                whiteSpace={"pre-wrap"}
              >
                {currentBranch?.name}{" "}
                {currentBranch?.is_hq ? "(สำนักงานใหญ่)" : ""}
              </Typography>
              <Typography fontSize={14}>
                รหัส: {currentBranch?.unique_id}
              </Typography>
              <Typography fontSize={14}>
                สถานะ: {currentBranch?.status ? "ใช้งาน" : "หยุดใช้งาน"}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        {sortedBranchList && sortedBranchList.length > 0 && (
          <Divider variant="middle" />
        )}
        <Box width={250} px={2} pb={1}>
          <CustomizedTextField
            label={"ค้นหา"}
            value={filterText}
            onChange={handleInputChange}
          />
        </Box>
        <CustomizedScrollbar sx={{ maxHeight: 300, width: 250 }}>
          {filteredDataMemoized?.map((branch) => (
            <MenuItem
              key={branch.id}
              onClick={() => onBranchChangeHandler(branch.id, branch.is_hq)}
            >
              <Box display="flex" gap={1} alignItems="center">
                <Box
                  component="span"
                  sx={{
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DomainOutlinedIcon color="disabled" />
                </Box>
                <Box>
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    whiteSpace={"pre-wrap"}
                  >
                    {branch.name} {branch?.is_hq ? "(สำนักงานใหญ่)" : ""}
                  </Typography>
                  <Typography fontSize={14}>
                    รหัส: {branch.unique_id}
                  </Typography>
                  <Typography fontSize={14}>
                    สถานะ: {branch.status ? "ใช้งาน" : "หยุดใช้งาน"}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </CustomizedScrollbar>
      </Menu>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={openActionsHandler}
        >
          <Box
            component="span"
            sx={{
              borderRadius: "50%",
              width: 32,
              height: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            bgcolor={(theme) => theme.palette.primary.light}
          >
            <DomainOutlinedIcon color="primary" />
          </Box>
          <Typography fontWeight={600} fontSize={14}>
            {currentBranch?.name} {currentBranch?.is_hq ? "(สำนักงานใหญ่)" : ""}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          onClick={openActionsHandler}
        >
          <Typography fontWeight={600} fontSize={14} ml={3.5}>
            {currentBranch?.name} {currentBranch?.is_hq ? "(สำนักงานใหญ่)" : ""}
          </Typography>
          <IconButton>
            <ExpandMoreIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default BranchBadge;
