import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  userSchema,
  userValidation,
} from "../../../components/Form/User/schema";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import UserCredential from "../../../components/Form/User/Credential";
import UserGeneralInfo from "../../../components/Form/User/GeneralInfo";
import UserDutyInfo from "../../../components/Form/User/DutyInfo";
import UserPermission from "../../../components/Form/User/Permission";
import { IUser } from "../../../types/Auth/user";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useState } from "react";
import { useDisable } from "../../../hooks/use-disable";
import {
  useUserUpdateMutation,
  useUserQuery,
  UserQuery,
  useBranchUserUpsertMutation,
  useGeneralUniqueIdGenerateQuery,
  GeneralUniqueIdGenerateQuery,
  GeneralModelType,
} from "../../../generated/general";
import {
  userQueryFormatter,
  userUpdateFormatter,
} from "../../../utils/Formatter/User";
import { useStateContext } from "../../../contexts/auth-context";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserOption } from "../../../hooks/use-user-option";

const UserSettingContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useDisable();

  const [removeIds, setRemoveIds] = useState<number[]>([]);

  const {
    state: { authUser },
  } = useStateContext();

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.setting.index"),
      to: "/user/setting",
    },
  ];

  const methods = useForm({
    defaultValues: userSchema,
    resolver: yupResolver<any>(userValidation),
  });

  const { handleSubmit, reset, setValue } = methods;

  const { branches, branchUser, isOptionLoading, refetchBranchUser } =
    useUserOption(authUser?.id);

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess } = useUserQuery<UserQuery>(
    graphqlClientGeneral,
    {
      uniqueInput: {
        id: authUser?.id,
      },
    }
  );

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphqlClientGeneral,
      {
        modelType: GeneralModelType.User,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(
    async (button: boolean) => {
      const { data } = await refetchUniqueId();
      if (button) setValue("unique_id", data?.UniqueIdGenerate ?? "");
    },
    [refetchUniqueId, setValue]
  );

  useEffect(() => {
    setDisabled(true);
    return () => setDisabled(false);
  }, [setDisabled]);

  useEffect(() => {
    if (isSuccess) {
      const { UserFindUnique } = data;
      const userData = userQueryFormatter(
        UserFindUnique as IUser,
        branches,
        branchUser
      );
      reset(userData);
    }
  }, [branchUser, branches, data, isSuccess, reset]);

  const { mutateAsync: update, isLoading: isUpdating } =
    useUserUpdateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: branchUserUpsert, isLoading: isBranchUserUpserting } =
    useBranchUserUpsertMutation<Error>(graphqlClientGeneral);

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: false,
    },
  ];

  const userUpdateHandler = async (data: IUser) => {
    try {
      const { id } = data;
      const formatData = await userUpdateFormatter(data);
      const { UserUpdate } = await update({
        uniqueInput: {
          id: id,
        },
        data: formatData,
      });
      const { branch_permission } = data;
      const mappedBranchId =
        branchUser?.map((branch) => branch.branch_id) || [];

      const addBranchUserPayload = branch_permission
        ?.filter(
          (permission) =>
            !mappedBranchId?.includes(permission.id) &&
            permission.access === "open"
        )
        .map((branch) => ({
          branch_id: branch.id,
          user_id: id || 0,
        }));
      const removeBranchUserPayload = removeIds.map((branchId) => ({
        branch_id: branchId,
        user_id: id || 0,
      }));
      await branchUserUpsert({
        addInput: addBranchUserPayload,
        deleteInput: removeBranchUserPayload,
      });
      enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      const { data: updatedBranchUser } = await refetchBranchUser();
      const updatedData = userQueryFormatter(
        UserUpdate as IUser,
        branches,
        updatedBranchUser?.BranchUserGetByUserId
      );
      reset(updatedData);
      setRemoveIds([]);
      setDisabled(true);
    } catch (err) {
      enqueueSnackbar("แก้ไขข้อมูลส่วนตัวไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  if (isOptionLoading || isLoading || isUpdating || isBranchUserUpserting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        maxWidth={1040}
        mt={3}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              default:
                break;
            }
          }}
          disabled={false}
        />
      </Box>
      <FormProvider {...methods}>
        <UserCredential />
        <UserGeneralInfo generateUniqueId={generateUniqueId} />
        <UserDutyInfo />
        <UserPermission setRemoveIds={setRemoveIds} isSetting />
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={editCancelHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(userUpdateHandler)}
                disabled={isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
    </>
  );
};

export default UserSettingContainer;
