import { List } from "@mui/material";
import { LeftNavbarItem } from "./LeftNavbarItem";

// interface IChild {
//   title: string;
//   path: string;
// }
// interface IItem {
//   title: string;
//   path: string;
//   icon: ReactElement<SvgIconProps>;
//   children: IChild;
//   info: string;
//   chip: ReactElement<SvgIconProps>;
// }
// interface INavItems {
//   depth: number;
//   items: IItem[];
//   path: string;
//   onItemClick: () => void;
//   searchParams: string[];
// }

// interface INavChildItem {
//   acc: ReactNode[];
//   depth: number;
//   item: IItem;
//   path: string;
//   onItemClick: () => void;
//   searchParams: string[];
// }

const renderNavItems = ({
  depth = 0,
  items,
  path,
  onItemClick,
  searchParams,
  isSmallScreen,
}: any) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc: any, item: any) =>
          reduceChildRoutes({
            acc,
            depth,
            item,
            path,
            onItemClick,
            searchParams,
            isSmallScreen,
          }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  path,
  onItemClick,
  searchParams,
  isSmallScreen,
}: any) => {
  const key = `${item.title}-${depth}`;
  const modulePath = path.split("/")[1];
  const moduleItemPath = item.path.split("/")[1];
  const removedQueryParam = item.path.split("?")[0];
  const partialMatch = removedQueryParam
    ? modulePath.includes(moduleItemPath) && path.includes(removedQueryParam)
    : false;

  const exactMatch = path === removedQueryParam; // We don't compare query params

  if (isSmallScreen && item?.disabledMobile !== undefined) return acc;

  if (item.children) {
    acc.push(
      <LeftNavbarItem
        activeBg={exactMatch}
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        onItemClick={onItemClick}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          path,
          onItemClick,
          searchParams,
          isSmallScreen,
        })}
      </LeftNavbarItem>
    );
  } else {
    acc.push(
      <LeftNavbarItem
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        onItemClick={onItemClick}
      />
    );
  }

  return acc;
};

export const LeftNavbarSection = (props: any) => {
  const { items, path, onItemClick, searchParams, isSmallScreen, ...other } =
    props;
  return (
    <>
      <List {...other} mt={20}>
        {renderNavItems({
          items,
          path,
          onItemClick,
          searchParams,
          isSmallScreen,
        })}
      </List>
    </>
  );
};
