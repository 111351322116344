import { gql } from "graphql-request";

export const PURCHASE_REQUEST_AGGRID = gql`
  query PurchaseRequestViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseRequestViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        expected_date
        status
        branch_id
        branch_unique_id
        branch_name
        branch_unique_id_name
        requester
        requester_object
        created_by
        created_by_object
        related_user_list
        related_user_object_list
        warehouse_id
        warehouse_name
        remark
        tag_list
        department
        position
        reference_no
        reference_document_object_list
        reference_document_list
        created_date
        last_updated_date
        item_list {
          id
          unique_id
          reference_line_item {
            line_item_unique_id
            line_item_document_type
          }
          reference_unique_id
          reference_document_type
          item_unique_id
          item_sku_name
          item_name
          item_sku_desc
          item_img_url
          tracability
          barcode
          qty
          po_qty
          qty_ordered
          qty_received
          qty_issued
          uom_id
          uom_name
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          stock_qty
          withholding_tax_type
          withholding_tax_value
          remark
        }
      }
    }
  }
`;

export const PURCHASE_ORDER_AGGRID = gql`
  query PurchaseOrderViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseOrderViewsAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        issue_date
        within_date
        status
        branch_unique_id_name
        created_by
        related_user_list
        vendor_unique_id
        vendor_name
        net_amount
        remark
        destination_warehouse_id
        destination_warehouse_unique_id
        destination_warehouse_name
        expected_date
        reference_no
        created_date
        last_updated_date
        branch_id
        branch_unique_id
        branch_name
        created_by_object
        related_user_object_list
        reference_document_list
        reference_document_object_list
        item_list {
          id
          unique_id
          reference_line_item {
            line_item_unique_id
            line_item_document_type
          }
          reference_unique_id
          reference_document_type
          item_unique_id
          item_sku_name
          item_name
          item_sku_desc
          item_img_url
          tracability
          barcode
          qty
          po_qty
          qty_ordered
          qty_received
          qty_issued
          uom_id
          uom_name
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          stock_qty
          withholding_tax_type
          withholding_tax_value
          remark
        }
      }
      count
    }
  }
`;

export const PURCHASE_RETURN_AGGRID = gql`
  query PurchaseReturnViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseReturnViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        delivery_date
        status
        vendor_unique_id
        vendor_name
        created_by
        related_user_list
        total_amount
        reference_document_list
        reference_no
        reason_to_return
        created_date
        last_updated_date
        branch_unique_id_name
        branch_id
        branch_unique_id
        branch_name
        created_by_object
        related_user_object_list
        reference_document_object_list
        item_list {
          id
          unique_id
          reference_line_item {
            line_item_unique_id
            line_item_document_type
            line_item_document_unique_id
          }
          reference_unique_id
          reference_document_type
          item_unique_id
          item_sku_name
          item_name
          item_sku_desc
          item_img_url
          tracability
          barcode
          qty
          po_qty
          qty_ordered
          qty_received
          qty_issued
          uom_id
          uom_name
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          stock_qty
          withholding_tax_type
          withholding_tax_value
          remark
        }
      }
    }
  }
`;
