import {
  ColDef,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { SALES_BY_CUSTOMER_EXPORT_VIEW } from "../../../../services/AgGrid/Report/SalesReportAggrid";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const SalesByCustomerReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "customer_id",
      headerName: "รหัสลูกค้า",
      filter: "agTextColumnFilter",
    },
    {
      field: "customer_name",
      headerName: "ชื่อลูกค้า",
      filter: "agTextColumnFilter",
    },
    {
      field: "document_type",
      headerName: "ประเภทเอกสาร",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["sales_invoice", "cash_sales"],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case "sales_invoice":
              return t("sales.invoice.index");
            case "cash_sales":
              return t("sales.cash_sales.document");
            default:
              return "";
          }
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        switch (params.value) {
          case "sales_invoice":
            return t("sales.invoice.index");
          case "cash_sales":
            return t("sales.cash_sales.document");
          default:
            return "";
        }
      },
    },
    {
      field: "status",
      headerName: t("status.index"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "draft",
          "wait_approve",
          "not_approved",
          "approved",
          "wait_payment",
          "partially_payment",
          "fully_payment",
          "finished",
          "cancelled",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellRenderer: (params: ICellRendererParams) => (
        <CustomizedStatus status={params.value} />
      ),
    },
    {
      field: "total_document",
      headerName: "จำนวนเอกสาร",
      filter: "agNumberColumnFilter",
    },
    {
      field: "item_pre_discount_amount",
      headerName: "มูลค่าก่อนหักส่วนลด",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "item_discount",
      headerName: "ส่วนลด",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "additional_discount",
      headerName: "ส่วนลดเพิ่มเติม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "post_discount_amount",
      headerName: "มูลค่าหลังหักส่วนลด",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "vat_amount",
      headerName: "ภาษีมูลค่าเพิ่มรวม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "net_amount",
      headerName: "มูลค่ารวมสุทธิ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "withholding_tax_amount",
      headerName: "ภาษีหัก ณ ที่จ่าย",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "total_amount",
      headerName: "จำนวนเงินชำระทั้งสิ้น",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const formatFilter = {
        ...filterModel,
      };

      try {
        const { SalesByCustomerExportViewsAggrid } =
          await graphQLClient.request(SALES_BY_CUSTOMER_EXPORT_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: SalesByCustomerExportViewsAggrid.results,
          rowCount: SalesByCustomerExportViewsAggrid.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default SalesByCustomerReportTable;
