import { Box, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { ICreatedBy, IReviewedBy } from "../../../types/global";
import SignerBox from "../SignerBox";

type Props = {
  approver_list: any[];
  created_by: ICreatedBy;
  issue_date: Date;
  reviewer_list: IReviewedBy[];
  status?: string;
  documentType?: string;
};

const PurchasePdfSigner = ({
  created_by,
  issue_date,
  approver_list,
  reviewer_list,
  status,
  documentType,
}: Props) => {
  const fontSize = 10.66;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexWrap: "nowrap",
          width: "197.3mm",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Typography
            sx={{ fontSize: fontSize, fontWeight: 600, lineHeight: 2 }}
          >
            สแกนดูเอกสาร
          </Typography>
          <QRCodeSVG value={window.location.href} size={75} />
        </Box>
        <SignerBox
          title={
            documentType === "purchase_order"
              ? "ผู้สั่งซื้อ"
              : documentType === "purchase_request"
              ? "ผู้ขอซื้อ"
              : "ผู้รับคืน"
          }
          date={issue_date}
          name={`${created_by?.first_name} ${created_by?.last_name}`}
        />
        <SignerBox title={"ผู้ตรวจสอบ"} />
        {status === "wait_approve" && approver_list && approver_list.length > 0
          ? approver_list.map((_, index) => (
              <SignerBox
                date={
                  reviewer_list && reviewer_list.length > 0
                    ? reviewer_list[index]?.reviewed_date
                    : undefined
                }
                name={
                  reviewer_list && reviewer_list.length > 0
                    ? reviewer_list[index]?.first_name &&
                      reviewer_list[index]?.last_name
                      ? `${reviewer_list[index]?.first_name} ${reviewer_list[index]?.last_name}`
                      : undefined
                    : undefined
                }
                title={
                  approver_list.length === 1
                    ? "ผู้อนุมัติ"
                    : `ผู้อนุมัติลำดับที่ ${index + 1}`
                }
              />
            ))
          : reviewer_list &&
            reviewer_list.length > 0 &&
            reviewer_list.map((reviewer, index) => (
              <SignerBox
                date={reviewer.reviewed_date}
                name={`${reviewer.first_name} ${reviewer.last_name}`}
                title={
                  approver_list.length === 0
                    ? "ผู้อนุมัติ"
                    : `ผู้อนุมัติลำดับที่ ${index + 1}`
                }
              />
            ))}
      </Box>
    </>
  );
};

export default PurchasePdfSigner;
