import { useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  customerDepositSchema,
  customerDepositValidation,
} from "../../../components/Form/Sales/CustomerDeposit/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActivityLogDocumentType,
  CustomerDepositFindUniqueQuery,
  useCustomerDepositFindUniqueQuery,
} from "../../../generated/sales";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import DocumentInfoTab from "./DocumentInfoTab";
import PaymentTab from "./PaymentTab";
import CashSalesTab from "./CashSalesTab";
import SalesInvoiceTab from "./SalesInvoiceTab";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ICustomerDeposit } from "../../../types/Sales/customerDeposit";

const CustomerDepositContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const { id } = useParams();

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: "รับชำระ",
      path: `${pathname}?tab=payment`,
    },
    {
      label: t("sales.cash_sales.index"),
      path: `${pathname}?tab=cash_sales`,
    },
    {
      label: t("sales.invoice.index"),
      path: `${pathname}?tab=sales_invoice`,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } =
    useCustomerDepositFindUniqueQuery<CustomerDepositFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "payment":
        return <PaymentTab />;
      case "cash_sales":
        return (
          <CashSalesTab
            cdUniqueId={data?.CustomerDepositFindUnique.unique_id}
          />
        );
      case "sales_invoice":
        return (
          <SalesInvoiceTab
            cdUniqueId={data?.CustomerDepositFindUnique.unique_id}
          />
        );
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const branch_id = sessionStorage.getItem("branch-id");

  const methods = useForm<ICustomerDeposit>({
    defaultValues: {
      ...customerDepositSchema,
      branch_id: branch_id ? parseInt(branch_id) : undefined,
    },
    resolver: yupResolver<any>(customerDepositValidation),
  });

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.customer_deposit.index"),
      to: "/sales/customer-deposit",
    },
    {
      name: id
        ? data?.CustomerDepositFindUnique.unique_id || "-"
        : t("button.create") + t("sales.customer_deposit.index"),
    },
  ];

  const status = data?.CustomerDepositFindUnique.aggrid_status;

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      {(status === "wait_deduction" ||
        status === "partially_deduction" ||
        status === "finished") && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}

      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.CustomerDeposit}
        />
      )}
    </FormProvider>
  );
};

export default CustomerDepositContainer;
