import { gql } from "graphql-request";

export const PURCHASE_ORDER_BY_VENDOR = gql`
    query PurchaseOrderByVendorExportViewsAggird($aggridInput: AnyAggridInput) {
        PurchaseOrderByVendorExportViewsAggird(aggridInput: $aggridInput) {
            count
            results {
                vendor_id
                vendor_name
                status
                total_document
                pre_discount_amount
                discount
                additional_discount
                pre_vat_amount
                vat_amount
                net_amount
            }
        }
    }
`;

export const PURCHASE_ORDER_BY_BRANCH = gql`
    query PurchaseOrderByBranchExportViewsAggird($aggridInput: AnyAggridInput) {
        PurchaseOrderByBranchExportViewsAggird(aggridInput: $aggridInput) {
            count
            results {
                branch_id
                branch_name
                item_unique_id
                item_name
                status
                qty
                uom_name
                item_pre_discount_amount
                item_discount
                item_additional_discount
                post_discount_amount
                vat_amount
                net_amount
            }
        }
    }
`;

export const PURCHASE_ORDER_BY_ITEM = gql`
    query PurchaseOrderByItemExportViewsAggird($aggridInput: AnyAggridInput) {
        PurchaseOrderByItemExportViewsAggird(aggridInput: $aggridInput) {
            count
            results {
                item_unique_id
                item_name
                status
                qty
                uom_name
                item_pre_discount_amount
                item_discount
                item_additional_discount
                item_pre_vat_amount
                item_vat_amount
                item_net_amount
            }
        }
    }
`;
