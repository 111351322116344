import { ReactInstance, useRef } from "react";
import { useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useReactToPrint } from "react-to-print";
import { useGoodsTransferQuery } from "../../../../generated/inventory";
import { IPdfInfo } from "../../../../types/global";
import { formatDate } from "../../../../utils/Date";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  CustomerFindUniqueQuery,
  useCustomerFindUniqueQuery,
} from "../../../../generated/contact";
import { renderAddressString } from "../../../../utils/Formatter/Global";
import { useInventoryPDFMultiplePage } from "../../../../hooks/use-inventory-pdf-multiple-page";
import {
  BinLocationFindUniqueQuery,
  useBinLocationFindUniqueQuery,
} from "../../../../generated/general";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import InventoryPdfLayout from "../../../../components/UI/InventoryPdf/InventoryPdfLayout";
import ConsignmentTable from "../../../../components/Table/Pdf/Consignment/ConsignmentTable";

const ConsignmentPdf = () => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);
  const graphQLClient = createGraphQLClientWithMiddleware("wms");
  const graphQLClientContact = createGraphQLClientWithMiddleware("crm");
  const graphQLClientGeneral = createGraphQLClientWithMiddleware("general");

  const { data, isLoading } = useGoodsTransferQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const goodsTransferInfo = data?.GoodsTransfer;

  const { data: binLocations, isLoading: isBinLocationLoading } =
    useBinLocationFindUniqueQuery<BinLocationFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          id: data?.GoodsTransfer?.consignment_bin_location_id,
        },
      }
    );

  const { data: customerData, isLoading: isCustomerLoading } =
    useCustomerFindUniqueQuery<CustomerFindUniqueQuery>(
      graphQLClientContact,
      {
        uniqueInput: {
          unique_id: binLocations?.BinLocationFindUnique?.name,
        },
      },
      {
        retry: false,
        enabled: binLocations?.BinLocationFindUnique?.name ? true : false,
      }
    );

  const customerInfo = customerData?.CustomerFindUnique;

  const { paginatedItems, startNumbers } = useInventoryPDFMultiplePage(
    data?.GoodsTransfer, //data
    141,
    700,
    226.484,
    226.484
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.GoodsTransfer?.unique_id,
  });

  const highlightSection: IPdfInfo = {
    headers: ["เลขที่เอกสาร", "วันที่ออกเอกสาร", "วันกำหนดส่งของ"],
    info: [
      goodsTransferInfo?.unique_id || "-",
      goodsTransferInfo?.created_date
        ? formatDate(goodsTransferInfo?.created_date)
        : "-",
      goodsTransferInfo?.posted_date
        ? formatDate(goodsTransferInfo?.posted_date)
        : "-",
    ],
  };

  const identityNoBranch =
    customerInfo && customerInfo?.taxpayer_id
      ? `${customerInfo?.taxpayer_id}`
      : "-";

  const foundBillingAddress = customerInfo
    ? customerInfo?.address_list.find(
        (address) => address.type === "ที่อยู่จดทะเบียน"
      )
    : undefined;

  const foundEmail = customerInfo
    ? customerInfo.contact_channel_list?.find(
        (channel) => channel.contact_channel_type === "email"
      )?.contact_channel_info || "-"
    : "-";

  const foundPhone = customerInfo
    ? customerInfo.contact_channel_list?.find(
        (channel) => channel.contact_channel_type === "phone"
      )?.contact_channel_info || "-"
    : "-";

  const foundFax = customerInfo
    ? customerInfo.contact_channel_list?.find(
        (channel) => channel.contact_channel_type === "fax"
      )?.contact_channel_info || "-"
    : "-";

  const customerBillingAddress = foundBillingAddress
    ? renderAddressString({
        address_name: foundBillingAddress.address_name,
        sub_district: foundBillingAddress.sub_district,
        district: foundBillingAddress.district,
        province: foundBillingAddress.province,
        postal_code: foundBillingAddress.postal_code,
        country: foundBillingAddress.country,
      })
    : "-";

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@โทรสาร",
      "อีเมล",
    ],
    info: [
      customerInfo
        ? `${customerInfo.unique_id} ${customerInfo.name}${
            customerInfo.last_name ? " " + customerInfo.last_name : ""
          }`
        : "-",
      customerBillingAddress,
      identityNoBranch,
      `${foundPhone}@${foundFax}`,
      foundEmail,
    ],
  };

  const destinationBranch = goodsTransferInfo?.destination_branch.name || "-";
  const destinationWarehouse =
    goodsTransferInfo?.destination_warehouse?.name || "-";

  const rightSection: IPdfInfo = {
    headers: ["สาขาปลายทาง", "คลังปลายทาง"],
    info: [destinationBranch, destinationWarehouse],
  };

  if (id && (isLoading || isCustomerLoading || isBinLocationLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={1650}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5">ใบส่งของ</Typography>
        <Box>
          <CustomizedButton
            fullWidth
            title="พิมพ์เอกสาร"
            variant="outlined"
            onClick={handlePrint}
          />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box ref={componentRef} sx={{ m: "auto" }}>
          {paginatedItems.map((item, index) => (
            <InventoryPdfLayout
              key={index}
              highlightSection={highlightSection}
              leftSection={leftSection}
              rightSection={rightSection}
              name={"ใบส่งของ"}
              multiplePage={paginatedItems.length > 1}
              page={index + 1}
              allPages={paginatedItems.length}
              data={data?.GoodsTransfer}
              documentType="sales_return"
            >
              <ConsignmentTable
                traceEntryList={item}
                startNumber={startNumbers[index]}
              />
            </InventoryPdfLayout>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ConsignmentPdf;
