import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useStateContext } from "../../../../contexts/auth-context";

import { Avatar, Box, Grid, Typography } from "@mui/material";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";

import { IGoodsTransfer } from "../../../../types/Inventory/GoodsTransfer";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { IApprovalTemplate, IMenuOption } from "../../../../types/global";
import ApproverBox from "../../../UI/ApproverBox";
import { printStatusWithApproval } from "../../../../utils/Constant";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";

type Props = {
  approverList: IApprovalTemplate[];
  approvalStep?: number | null;
};

const GoodsTransferHeader = ({ approverList, approvalStep }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const { control, setValue, getValues } = useFormContext<IGoodsTransfer>();

  const aggridStatus = useWatch({ control, name: "aggrid_status" });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const watchDestinationBranchId = useWatch({
    control,
    name: "destination_branch_id",
  });

  const watchDestinationWarehouseId = useWatch({
    control,
    name: "destination_warehouse_id",
  });

  const { isConsignment } = useInventoryControl(
    watchDestinationBranchId,
    watchDestinationWarehouseId
  );

  useEffect(() => {
    if (!id && authUser) {
      setValue("created_by", {
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, id, setValue]);

  const isFinished = Boolean(
    aggridStatus && ["cancelled", "finished"].includes(aggridStatus)
  );
  const isDisablePostDate =
    isFinished ||
    Boolean(aggridStatus && ["in_transit"].includes(aggridStatus));

  const printOptions: IMenuOption[] = [
    {
      value: `${t("button.print")}ใบ${t("inventory.goods_transfer.index")}`,
    },
    ...(isConsignment
      ? [
          {
            value: `${t("button.print")}${t(
              "logistic.delivery_order.index"
            )} (ฝากขาย)`,
          },
        ]
      : []),
  ];

  return (
    <>
      <Box maxWidth={1040} mt={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography variant="h5">
                {id
                  ? getValues("unique_id")
                  : `${t("button.create")}${t(
                      "inventory.goods_transfer.index"
                    )}`}
              </Typography>
              <CustomizedStatus status={aggridStatus} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={1.5}>
              <Grid item xs={6} sm={6} md={4}>
                <CustomizedMenuOptions
                  fullWidth
                  size="medium"
                  label={t("button.print_document")}
                  options={printOptions}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    switch (value.innerText) {
                      case `${t("button.print")}ใบ${t(
                        "inventory.goods_transfer.index"
                      )}`:
                        navigate("pdf");
                        break;
                      case `${t("button.print")}${t(
                        "logistic.delivery_order.index"
                      )} (ฝากขาย)`:
                        navigate("consignment-pdf");
                        break;
                      default:
                    }
                  }}
                  disabled={
                    !id || !printStatusWithApproval.includes(aggridStatus ?? "")
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  label={t(
                    "inventory.goods_transfer.detail.header.created_date"
                  )}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <ControlledDatePicker
                  name="posted_date"
                  control={control}
                  label={t(
                    "inventory.goods_transfer.detail.header.posted_date"
                  )}
                  disabled={isDisablePostDate}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
          <Typography fontWeight="bold">{t("sentence.created_by")}</Typography>
          <CustomizedTooltip
            title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
            enterNextDelay={200}
          >
            {watchCreatedBy?.img_url && watchCreatedBy?.img_url.length > 0 ? (
              <Avatar
                alt={`avatar`}
                src={watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""}
              />
            ) : (
              <CustomizedLetterAvatar
                name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
              />
            )}
          </CustomizedTooltip>
        </Box>
      </Box>
      <Box maxWidth={1650} mb={2}>
        {id && aggridStatus !== "draft" && approverList.length > 0 && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              mt: 1,
              whiteSpace: "nowrap",
              flexWrap: "wrap",
            }}
          >
            <Typography fontWeight={"bold"}>ผู้อนุมัติ</Typography>
            {approverList.map((approval, index) => {
              const formatApproval = approval as IApprovalTemplate;
              return (
                <ApproverBox
                  key={index}
                  approval={formatApproval}
                  index={index}
                  approvalStep={approvalStep}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default GoodsTransferHeader;
