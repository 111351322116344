import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDisable } from "../../../../hooks/use-disable";

import { Grid } from "@mui/material";

import ControlledTextField from "../../../Controller/ControlledTextField";

const Insurance = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [disabled] = useDisable();

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"insurance_name"}
          label={t("inventory.items.insurance_name")}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"insurance_detail"}
          label={t("inventory.items.insurance_detail")}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"customer_insurance_duration"}
          label={t("inventory.items.customer_insurance_duration")}
          onChange={(e, field) => field.onChange(parseInt(e.target.value))}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"seller_insurance_duration"}
          label={t("inventory.items.seller_insurance_duration")}
          onChange={(e, field) => field.onChange(parseInt(e.target.value))}
          viewMode={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default Insurance;
