import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";

import { Grid, IconButton, Typography } from "@mui/material";

import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedButton from "../../../Custom/CustomizedButton";

import CloseIcon from "@mui/icons-material/Close";
import { useDisable } from "../../../../hooks/use-disable";
const Margetting = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [disabled] = useDisable();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "selling_point_list",
  });

  return (
    <Fragment>
      <Typography variant="h6" my={2} ml={1}>
        {t("inventory.items.selling_point")}
      </Typography>
      {fields.map((list, index) => (
        <Grid container spacing={2} mb={1} key={list.id}>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name={`selling_point_list[${index}].name`}
              label={t("inventory.items.selling_point") + ` ${index + 1}`}
              viewMode={disabled}
            />
          </Grid>
          {!disabled && (
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <IconButton
                onClick={() => {
                  remove(index);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      {!disabled && (
        <CustomizedButton
          title={t("button.add") + t("inventory.items.selling_point")}
          variant="outlined"
          onClick={() => {
            append({
              name: "",
            });
          }}
          addIcon
        />
      )}
    </Fragment>
  );
};

export default Margetting;
