import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Typography } from "@mui/material";
import CustomizedTab from "../../Custom/CustomizedTab";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ITab } from "../../../types/global";
import BranchPermissionTable from "../../Table/Permission/BranchPermissionTable";

interface Props {
  setRemoveIds: Dispatch<SetStateAction<number[]>>;
  isSetting: boolean;
}

const UserPermission = ({ setRemoveIds, isSetting }: Props) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(
    pathname + "?tab=branch"
  );

  const tabs: ITab[] = [
    {
      label: t("branch.index"),
      path: `${pathname}?tab=branch`,
    },
    {
      label: t("inventory.index"),
      path: `${pathname}?tab=inventory`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "branch":
        setCurrentTab(pathname + "?tab=branch");
        break;
      case "inventory":
        setCurrentTab(pathname + "?tab=inventory");
        break;
      default:
        setCurrentTab(pathname + "?tab=branch");
        break;
    }
  }, [pathname, tab]);

  const renderTable = (tab: string | null) => {
    switch (tab) {
      case "branch":
        return (
          <BranchPermissionTable
            setRemoveIds={setRemoveIds}
            isSetting={isSetting}
          />
        );
      case "inventory":
        return;
      default:
        return (
          <BranchPermissionTable
            setRemoveIds={setRemoveIds}
            isSetting={isSetting}
          />
        );
    }
  };

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography color="primary.main" fontWeight={600} mb={2}>
          {t("button.add") + t("user.rbac.access")}
        </Typography>
        <Typography>-</Typography>
      </CustomizedBox>
      <CustomizedBox>
        <Typography color="primary.main" fontWeight={600} mb={2}>
          {t("user.rbac.access")}
        </Typography>
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
        {renderTable(tab)}
      </CustomizedBox>
    </>
  );
};

export default UserPermission;
