import { GraphQLClient } from "graphql-request";
import { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  IServerSideDatasource,
} from "ag-grid-community";
import { ITEM_VIEW } from "../../../services/AgGrid/InventoryAggrid";
import { ItemType } from "../../../generated/inventory";
import ModalUI from "./ModalUI";
import { Box } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import AgGrid from "../AgGrid";
import { useItemColumnDefs } from "../../Table/Inventory/Item/useItemColumnDefs";

interface Props {
  itemModal: boolean;
  closeItemModalHandler: () => void;
  addItemsHandler: () => void;
}

const ItemTableModal = forwardRef<any, Props>(
  ({ itemModal, closeItemModalHandler, addItemsHandler }, ref) => {
    const { t } = useTranslation();

    const columnDefs: ColDef[] = useItemColumnDefs(true);

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("wms");

    const datasource: IServerSideDatasource = {
      async getRows(params) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const { status, ...otherFilter } = filterModel;

        const formatFilter = {
          ...otherFilter,
          status: status && {
            ...status,
            values: status.values.map((v: string) => parseInt(v)),
          },
        };

        const formatSorting = [
          ...sortModel,
          { sort: "desc", colId: "created_date" },
        ];

        try {
          const { ItemsView } = await graphQLClientWithHeaderItem.request(
            ITEM_VIEW,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel: formatSorting,
              },
            }
          );
          params.success({
            rowData: ItemsView.results as any[],
            rowCount: ItemsView.count as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      const instanceStatus = params.api.getFilterInstance("status");
      const typeInstance = params.api.getFilterInstance("type");
      typeInstance?.setModel({
        values: [ItemType.Normal, ItemType.Variant, ItemType.Service],
      });
      instanceStatus?.setModel({ values: ["1"] });
      params.api.setServerSideDatasource(datasource);
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
      return params.data.sku_name;
    }, []);

    return (
      <ModalUI
        open={itemModal}
        handleClose={closeItemModalHandler}
        title="สินค้า"
        maxWidth="lg"
        action={
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              size="medium"
              onClick={closeItemModalHandler}
            />
            <CustomizedButton
              title={t("button.add")}
              onClick={addItemsHandler}
              variant="contained"
              size="medium"
            />
          </Box>
        }
      >
        <AgGrid
          ref={ref}
          columnDefs={columnDefs}
          height={665}
          onGridReady={onGridReady}
          rowSelection="multiple"
          getRowId={getRowId}
          rowMultiSelectWithClick
        />
      </ModalUI>
    );
  }
);

export default ItemTableModal;
