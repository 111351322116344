import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Date";
import { goodsIssueTypeValueFormatter } from "../../../../utils/Formatter/Inventory/GoodsIssue";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";

export const useGoodsIssueColumnDefs = (referenceUniqueId?: string) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();

  useEffect(() => {
    setColumnDefs([
      {
        field: "created_date",
        headerName: t("date.issue_date"),
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "posted_date",
        headerName: t("date.issued_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "unique_id",
        headerName: t("sales.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "reference_unique_id",
        headerName: t("sentence.reference_unique_id"),
        filter: "agTextColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            return params.value;
          } else {
            return "-";
          }
        },
        filterParams: {
          readOnly: !!referenceUniqueId,
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "branch_id",
        filter: "agNumberColumnFilter",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "branch_name",
        headerName: t("branch.index"),
        filter: "agTextColumnFilter",
      },
      {
        field: "warehouse_name",
        headerName: t("inventory.warehouse"),
        filter: "agTextColumnFilter",
      },
      {
        field: "type",
        headerName: t("sentence.type"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["sales_order", "purchase_return", "other"],
          valueFormatter: (params: ValueFormatterParams) =>
            goodsIssueTypeValueFormatter(params.value),
        },
        valueFormatter: (params: ValueFormatterParams) =>
          goodsIssueTypeValueFormatter(params.value),
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_by",
        headerName: t("inventory.createdBy"),
        filter: "agTextColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          if (params.value) {
            if (params.data?.img_url && params.data?.img_url.length > 0) {
              return (
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: "",
                      first_name: params.value,
                      last_name: "",
                      img_url: params.data.img_url,
                    },
                  ]}
                />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value ?? ""} />;
            }
          }
        },
        minWidth: 150,
        flex: 1,
      },
      {
        field: "aggrid_status",
        headerName: t("status.index"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<IGoodsIssue, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        filterParams: {
          values: [
            "draft",
            "wait_approve",
            "not_approved",
            "wait_issue",
            "finished",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    ]);
  }, [referenceUniqueId, t]);
  return columnDefs;
};
