import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import PurchaseRequestTable from "../../../components/Table/Purchase/Request";

import { IBreadcrumbsAndMenu, ITab, IMenuOption } from "../../../types/global";
import { branchInfoFromSessionStorage } from "../../../utils/Global";

import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ExporterPurchaseModal from "../../../components/UI/Modal/ExporterPurchaseModal";
import { useModal } from "../../../hooks/use-modal";
import { PurchaseExportType } from "../../../generated/purchase";

const PurchaseRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { is_hq } = branchInfoFromSessionStorage();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.request.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_approve"),
      path: `${pathname}?filter=wait_approve`,
    },
    {
      label: t("status.not_approved"),
      path: `${pathname}?filter=not_approved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.partially_ordered"),
      path: `${pathname}?filter=partially_ordered`,
    },
    {
      label: t("status.fully_ordered"),
      path: `${pathname}?filter=fully_ordered`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  // const onFilterReset = () => {
  //   if (gridRef) {
  //     gridRef.current?.api.setFilterModel({});
  //   }
  // };

  const options: IMenuOption[] = [
    {
      value: t("purchase.export"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("purchase.request.index")}</Typography>
        <Box display="flex" gap={1}>
          <CustomizedButton
            title={`${t("button.create")}${t("purchase.request.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
            addIcon
          />
          {is_hq === "true" && (
            <CustomizedMenuOptions
              isIcon
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("purchase.export")}`:
                    openModalHandler();
                    break;
                  default:
                }
              }}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <PurchaseRequestTable isFilter={isFilter} />
      <ExporterPurchaseModal
        open={modal}
        closeModalHandler={closeModalHandler}
        documentType={PurchaseExportType.PurchaseRequest}
      />
    </>
  );
};

export default PurchaseRequest;
