import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFilterParams } from "../../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../../utils/Date";
import { ItemType } from "../../../../../generated/inventory";

export const useCurrentStockColumnDefs = (
  type: "branch" | "warehouse" | "location" | "serial_number",
  item_type?: ItemType
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    const defaultColumnDefs: ColDef[] = [
      {
        field: "sku_name",
        headerName: t("inventory.items.unique_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          readOnly: true,
        },
      },
      {
        field: "item_name",
        headerName: t("inventory.items.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "branch_unique_id",
        headerName: t("branch.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "branch_name",
        headerName: t("branch.index"),
        filter: "agTextColumnFilter",
      },
    ];

    const defaultSumColumnDefs: ColDef[] = [
      {
        field: "available_qty",
        headerName: t("inventory.quantities.available_qty"),
        filter: "agNumberColumnFilter",
      },
      {
        field: "stock_qty",
        headerName: t("inventory.quantities.stock_qty"),
        filter: "agNumberColumnFilter",
      },
      {
        field: "purchase_ordered_qty",
        headerName: t("inventory.quantities.purchase_ordered_qty"),
        filter: "agNumberColumnFilter",
      },
      {
        field: "transit_qty",
        headerName: t("inventory.quantities.in_transit_qty"),
        filter: "agNumberColumnFilter",
      },
      {
        field: "sale_committed_qty",
        headerName: t("inventory.quantities.sale_committed_qty"),
        filter: "agNumberColumnFilter",
      },
    ];

    switch (type) {
      case "branch":
        if (item_type === ItemType.Bundle)
          setColumnDefs([
            ...defaultColumnDefs,
            defaultSumColumnDefs[0],
            defaultSumColumnDefs[1],
          ]);
        else setColumnDefs([...defaultColumnDefs, ...defaultSumColumnDefs]);
        break;
      case "warehouse":
        if (item_type === ItemType.Bundle)
          setColumnDefs([
            ...defaultColumnDefs,
            {
              field: "warehouse_unique_id",
              headerName: t("inventory.warehouseId"),
              filter: "agTextColumnFilter",
            },
            {
              field: "warehouse_name",
              headerName: t("inventory.warehouse"),
              filter: "agTextColumnFilter",
            },
            defaultSumColumnDefs[0],
            defaultSumColumnDefs[1],
          ]);
        else
          setColumnDefs([
            ...defaultColumnDefs,
            {
              field: "warehouse_unique_id",
              headerName: t("inventory.warehouseId"),
              filter: "agTextColumnFilter",
            },
            {
              field: "warehouse_name",
              headerName: t("inventory.warehouse"),
              filter: "agTextColumnFilter",
            },
            ...defaultSumColumnDefs,
          ]);
        break;
      case "location":
        if (item_type === ItemType.Bundle)
          setColumnDefs([
            ...defaultColumnDefs,
            {
              field: "warehouse_unique_id",
              headerName: t("inventory.warehouseId"),
              filter: "agTextColumnFilter",
            },
            {
              field: "warehouse_name",
              headerName: t("inventory.warehouse"),
              filter: "agTextColumnFilter",
            },
            {
              field: "bin_id",
              headerName: t("inventory.locationId"),
              filter: "agNumberColumnFilter",
            },
            {
              field: "bin_name",
              headerName: t("inventory.location"),
              filter: "agTextColumnFilter",
            },
            defaultSumColumnDefs[0],
            defaultSumColumnDefs[1],
          ]);
        else
          setColumnDefs([
            ...defaultColumnDefs,
            {
              field: "warehouse_unique_id",
              headerName: t("inventory.warehouseId"),
              filter: "agTextColumnFilter",
            },
            {
              field: "warehouse_name",
              headerName: t("inventory.warehouse"),
              filter: "agTextColumnFilter",
            },
            {
              field: "bin_id",
              headerName: t("inventory.locationId"),
              filter: "agNumberColumnFilter",
            },
            {
              field: "bin_name",
              headerName: t("inventory.location"),
              filter: "agTextColumnFilter",
            },
            ...defaultSumColumnDefs,
          ]);
        break;
      case "serial_number":
        setColumnDefs([
          ...defaultColumnDefs,
          {
            field: "warehouse_unique_id",
            headerName: t("inventory.warehouseId"),
            filter: "agTextColumnFilter",
          },
          {
            field: "warehouse_name",
            headerName: t("inventory.warehouse"),
            filter: "agTextColumnFilter",
          },
          {
            field: "bin_id",
            headerName: t("inventory.locationId"),
            filter: "agNumberColumnFilter",
          },
          {
            field: "bin_name",
            headerName: t("inventory.location"),
            filter: "agTextColumnFilter",
          },
          {
            field: "serial_no",
            headerName: "SN",
            filter: "agTextColumnFilter",
          },
          {
            field: "barcode",
            headerName: "Barcode",
            filter: "agTextColumnFilter",
          },
          {
            field: "stock_qty",
            headerName: t("inventory.quantities.stock_qty"),
            filter: "agNumberColumnFilter",
          },
          {
            field: "lot_date",
            headerName: "วันที่ Lot",
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            valueFormatter: (params: ValueFormatterParams) =>
              formatDate(params.value),
          },
          {
            field: "manufactured_date",
            headerName: "วันที่ผลิต",
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            valueFormatter: (params: ValueFormatterParams) =>
              formatDate(params.value),
          },
          {
            field: "expired_date",
            headerName: "วันที่หมดอายุ",
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            valueFormatter: (params: ValueFormatterParams) =>
              formatDate(params.value),
          },
        ]);
        break;
      default:
        break;
    }
  }, [item_type, t, type]);
  return columnDefs;
};
