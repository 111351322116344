import { useTranslation } from "react-i18next";
import {
  IBreadcrumbsAndMenu,
  IExporterDate,
  IMenuOption,
} from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, CircularProgress, Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useLocation, useNavigate } from "react-router-dom";
import PriceListTable from "../../../components/Table/Inventory/PriceList";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { useModal } from "../../../hooks/use-modal";
import ExporterPriceListModal from "../../../components/UI/Modal/ExporterPriceListModal";
import { useEffect, useState } from "react";
import {
  ExportGenerateInput,
  InventoryExportType,
  useGenerateInventoryExportQuery,
} from "../../../generated/inventory";
import { useSnackbar } from "notistack";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const PriceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [exportInput, setExportInput] = useState<ExportGenerateInput | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.price_list.index"),
    },
  ];

  const options: IMenuOption[] = [
    {
      value: t("button.import"),
    },
    {
      value: t("button.export"),
    },
  ];

  const graphqlClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const { refetch: refetchData, isLoading: isExporting } =
    useGenerateInventoryExportQuery(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporterDate) => {
    if (data) {
      const { start_date, end_date } = data;
      setExportInput({
        report_type: InventoryExportType.ItemPriceList,
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.GenerateExportedDataNoQueue || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  if (exportInput && isExporting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("inventory.price_list.index")}</Typography>

        <Box display="flex" gap={1}>
          <CustomizedButton
            title={`${t("button.create")}${t("inventory.price_list.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          <CustomizedMenuOptions
            isIcon
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case `${t("button.import")}`:
                  navigate(`${pathname}/import`);
                  break;
                case `${t("button.export")}`:
                  openModalHandler();
                  break;
                default:
              }
            }}
          />
        </Box>
      </Box>
      <PriceListTable />
      <ExporterPriceListModal
        open={modal}
        closeModalHandler={closeModalHandler}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default PriceList;
