import { useRef } from "react";
import AgGrid from "../../UI/AgGrid";
import useContactColumnDefs from "./useContactColumnDefs";
import { useNavigate } from "react-router-dom";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CUSTOMERS_AGGRID,
  VENDORS_AGGRID,
} from "../../../services/AgGrid/ContactAgGrid";

type Props = {
  type: "customer" | "vendor";
};

const ContactTable = ({ type }: Props) => {
  const gridRef = useRef();
  const navigate = useNavigate();

  const graphQLClientContact: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const columnDefs = useContactColumnDefs(type);

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, tag_list, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
      };
      try {
        if (type === "customer") {
          const { CustomersView } = await graphQLClientContact.request(
            CUSTOMERS_AGGRID,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
          params.success({
            rowData: CustomersView.results as any[],
            rowCount: CustomersView.count as number,
          });
        } else {
          const { VendorsView } = await graphQLClientContact.request(
            VENDORS_AGGRID,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
          params.success({
            rowData: VendorsView.results as any[],
            rowCount: VendorsView.count as number,
          });
        }
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/contact/${type}/${id}?tab=address`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/contact/${type}`}
      disabledSidebar
      // onGridReady={onGridReady}
    />
  );
};

export default ContactTable;
