import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { IApproval, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { Box, CircularProgress } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  approvalSchema,
  approvalValidation,
} from "../../../components/Form/Approval/schema";
import ApprovalSelect from "../../../components/Form/Approval/ApprovalSelect";
import ApprovalList from "../../../components/Form/Approval/ApprovalList";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  WorkflowDocumentType,
  useApprovalTemplateFindByDocumentTypeQuery,
  useApprovalTemplateUpsertManyMutation,
} from "../../../generated/general";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useConfirmation } from "../../../hooks/use-confirmation";
import Confirmation from "../../../components/UI/Confirmation";

const InventoryApproval = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.approval.index"), to: "/setting/approval" },
    {
      name: t("setting.approval.inventory"),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState(`${pathname}?tab=goods_issue`);

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess, refetch } =
    useApprovalTemplateFindByDocumentTypeQuery(graphQLClient, {
      documentType: tab || "",
    });

  const { mutateAsync: upsert, isLoading: upserting } =
    useApprovalTemplateUpsertManyMutation(graphQLClient);

  const tabs: ITab[] = [
    {
      label: t("inventory.goods_issue.index"),
      path: `${pathname}?tab=goods_issue`,
    },
    {
      label: t("inventory.goods_transfer.index"),
      path: `${pathname}?tab=goods_transfer`,
    },
    {
      label: t("inventory.goods_adjustment.index"),
      path: `${pathname}?tab=goods_adjust`,
    },
  ];

  const methods = useForm<IApproval>({
    defaultValues: approvalSchema,
    resolver: yupResolver<any>(approvalValidation),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      const { ApprovalTemplateFindByDocumentType } = data;
      const formatData = {
        is_have_approval:
          ApprovalTemplateFindByDocumentType &&
          ApprovalTemplateFindByDocumentType.length > 0
            ? true
            : false,
        approval_templates: ApprovalTemplateFindByDocumentType?.map(
          (template) => {
            const formatApproverList = template?.approver_list?.map(
              (approver) => ({
                ...approver,
                branch_name: approver.original_branch_object?.name,
              })
            );
            return {
              required_approval: template?.required_approval,
              approver_list: formatApproverList,
              workflow_step_number: template?.workflow_step_number,
              workflow_document_type: template?.workflow_document_type,
            };
          }
        ),
      } as IApproval;
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    switch (tab) {
      case "goods_issue":
        setCurrentTab(pathname + "?tab=goods_issue");
        break;
      case "goods_transfer":
        setCurrentTab(pathname + "?tab=goods_transfer");
        break;
      case "goods_adjust":
        setCurrentTab(pathname + "?tab=goods_adjust");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
    return () => {
      reset();
    };
  }, [pathname, reset, tab]);

  const watchIsHaveApprove = watch("is_have_approval");

  const {
    confirmation,
    closeConfirmationHandler,
    openConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(() => handleSubmit(saveApprovalTemplateHandler)());

  const saveApprovalTemplateHandler = async (data: IApproval) => {
    try {
      const formatData = data.approval_templates.map(
        ({ approver_list, workflow_document_type, ...template }, index) => ({
          ...template,
          workflow_document_type:
            workflow_document_type as WorkflowDocumentType,
          step_number: index + 1,
          approver_id_list: approver_list.map((approver) => approver.id),
        })
      );

      await upsert({
        data: watchIsHaveApprove ? formatData : [],
        documentType: tab as WorkflowDocumentType,
      });
      await refetch();
      enqueueSnackbar("แก้ไขการอนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขการอนุมัติไม่สำเร็จ", {
        variant: "error",
      });
      console.error(err);
    }
  };

  useEffect(() => {
    const approvalTemplateError = errors.approval_templates as any;
    if (approvalTemplateError) {
      const isNoneApproverError = approvalTemplateError?.some(
        (template: any) => template?.approver_list
      );
      if (isNoneApproverError) {
        enqueueSnackbar("กรุณาเลือกผู้อนุมัติ", {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box my={2}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
        <FormProvider {...methods}>
          <ApprovalSelect />
          {isLoading || upserting ? (
            <Box
              sx={{
                height: "calc(100dvh - 500px)",
                marginRight: "260px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>{watchIsHaveApprove && <ApprovalList />}</>
          )}

          <BottomNavbar>
            <CustomizedButton
              variant="contained"
              title={t("button.save")}
              onClick={openConfirmationHandler}
            />
          </BottomNavbar>
        </FormProvider>
      </Box>
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนแปลงข้อมูลการอนุมัติ"
        message=" การเปลี่ยนแปลงข้อมูลการอนุมัติ อาจะส่งผลกระทบต่อระบบการทำงานของบริษัท กรุณากดยืนยันเพื่อบันทึกการเปลี่ยนแปลง"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default InventoryApproval;
