import { useRef } from "react";
import useAttributeColumnDefs from "./useAttributeColumnDefs";
import AgGrid from "../../../UI/AgGrid";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  data: any[];
};

const AttributeTable = ({ onEditHandler, onDeleteHandler, data }: Props) => {
  const gridRef = useRef(null);
  const columnDefs = useAttributeColumnDefs(onEditHandler, onDeleteHandler);

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
    />
  );
};

export default AttributeTable;
