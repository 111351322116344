import { ISelectOption } from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import TocIcon from "@mui/icons-material/Toc";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import PreVatAmountCell from "./PreVatAmountCell";
// import WithholdingTaxCell from "./WithholdingTaxCell";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useModal } from "../../../../hooks/use-modal";
import { AgGridReact } from "ag-grid-react";
import SumPreVatAmount from "./SumPreVatAmount";
import { v4 as uuidv4 } from "uuid";
import {
  numberFormatter,
  priceNumberFormatter,
  vatTypeFormatter,
} from "../../../../utils/Formatter/Global";
import { useSalesItemListHeader } from "../../../../hooks/Sales/use-sales-item-list-header";
import { ISalesItemList } from "../../../../types/Sales";
import { useDisable } from "../../../../hooks/use-disable";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import ItemTableModal from "../../../UI/Modal/ItemTableModal";
import { priceListInItem } from "../../../../utils/Formatter/Sales";
import { ICashSales } from "../../../../types/Sales/cashSales";
import { IQuotation } from "../../../../types/Sales/quotation";
import SalesReturnItemList from "./SalesReturnItemList";
import { ISalesInvoice } from "../../../../types/Sales/salesInvoice";
import CheckedItemListModal from "../../../UI/Modal/CheckedItemListModal";
import { useParams } from "react-router-dom";

interface Props {
  referenceItemList?: ISalesItemList[];
  documentType: string;
}

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const SalesItemList = ({ documentType, referenceItemList }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IQuotation | ISalesOrder | ICashSales | ISalesInvoice>();
  const [disabled] = useDisable();
  const gridRef = useRef<AgGridReact<any>>(null);
  const { id } = useParams();
  //const gridRefItemList = useRef<AgGridReact<ISalesItemList>>(null);

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const tableDisabled =
    Boolean(id) &&
    (disabled || (status !== "draft" && status !== "not_approved"));

  const headers = useSalesItemListHeader(documentType, disabled);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const { fields, move, remove, append, replace } = useFieldArray({
    control,
    name: "item_list",
    keyName: "unique_id",
  });

  useEffect(() => {
    if (fields && fields.length > 0) {
      let uniqueIdList: string[] = [];

      console.log("fields", fields);
      console.log("reference", referenceItemList);
      fields.forEach(({ reference_line_item }) => {
        if (reference_line_item && reference_line_item.line_item_unique_id) {
          uniqueIdList.push(reference_line_item.line_item_unique_id);
        }
      });

      setItemIds(uniqueIdList);
      setItemIdsSnapshot(uniqueIdList);
    }
  }, [fields, referenceItemList]);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  // const isReferenceDocumentList = getValues("reference_document_list");
  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      const document_unique_id = getValues("unique_id");

      const vatType = (sale_vat_type: "ไม่มี" | "0 %" | "7 %") => {
        switch (sale_vat_type) {
          case "ไม่มี":
            return "ไม่มี";
          case "0 %":
            return "0";
          case "7 %":
            return "7";
          default:
            return "ไม่มี";
        }
      };

      const priceList = getValues("item_price_list");

      selectedItems.forEach((sku_detail) => {
        let formatItems: ISalesItemList = {
          unique_id: uuidv4(),
          item_unique_id: sku_detail.item_unique_id || "",
          item_sku_name: sku_detail.sku_name,
          item_name: sku_detail.name,
          item_sku_desc: sku_detail.description,
          tracability: sku_detail.tracability,
          branch_id: getValues("branch_id"),
          qty: 1,
          discount: 0,
          pre_vat_amount: 0,
          price_per_unit: sku_detail.sale_price ?? 0,
          uom_id: sku_detail.stock_uom_id,
          remark: "",
          stock_qty: 1,
          vat_percentage: vatType(sku_detail.sale_vat_type),
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          reference_document_type: documentType,
          reference_unique_id: document_unique_id,
          uom_name: sku_detail.stock_uom_name_th,
          uom_group: sku_detail.uom_group,
          item_sku_qty: sku_detail.total_sku_qty,
          item_img_url: sku_detail.img_url,
          volume: sku_detail.delivery_attribute[0].volume,
          weight: sku_detail.delivery_attribute[0].weight,
          barcode: sku_detail.barcode || undefined,
        };
        const formatWithPriceList = priceListInItem(priceList, formatItems);

        append(formatWithPriceList);
      });
    }
    closeItemModalHandler();
  };

  const addReferenceItemsHandler = (itemList: any[]) => {
    if (itemList && itemList.length > 0) {
      const currentItemList = getValues("item_list");
      let filteredItems = currentItemList.filter((item) =>
        itemIds.includes(item.reference_line_item?.line_item_unique_id || "")
      );

      // get current Items id to prevent duplication when appending newly selected Items
      const filteredItemsId = filteredItems.map(
        (item) => item.reference_line_item?.line_item_unique_id
      );

      itemList.forEach((item) => {
        if (!filteredItemsId.includes(item.unique_id)) {
          const foundUpdateItem = currentItemList.find(
            (item) =>
              item.reference_line_item?.line_item_unique_id === item.unique_id
          );
          if (foundUpdateItem) {
            filteredItems.push({
              ...item,
              unique_id: foundUpdateItem?.unique_id,
              reference_line_item: {
                line_item_document_type: item.reference_document_type,
                line_item_unique_id: item.unique_id,
              },
            });
          } else {
            filteredItems.push({
              ...item,
              reference_line_item: {
                line_item_document_type: item.reference_document_type,
                line_item_unique_id: item.unique_id,
              },
              unique_id: uuidv4(),
            });
          }
        }
      });
      // sort for proerly render order (and for role assignment when submitting)
      filteredItems = filteredItems.sort((a, b) => {
        return itemIds.indexOf(a.unique_id) - itemIds.indexOf(b.unique_id);
      });

      replace(filteredItems);
    }
  };

  const uomOptions = (field: ISalesItemList) => {
    const options = (
      field.uom_group
        ? field.uom_group.convertable_uom_list &&
          field.uom_group.convertable_uom_list.length > 0
          ? [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
              ...field.uom_group.convertable_uom_list.map((uom) => ({
                label: uom.name_th,
                value: uom.id,
              })),
            ]
          : [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
            ]
        : []
    ) as ISelectOption[];
    return options;
  };

  if (documentType === "sales_return") {
    return (
      <CustomizedBox sx={{ breakAfter: "auto" }} margin={0} maxWidth={1650}>
        <Typography fontWeight={600} color={"primary.main"} mb={2}>
          สินค้า
        </Typography>
        <SalesReturnItemList
          dragEndHandler={dragEndHandler}
          fields={fields}
          headers={headers}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          vatOptions={vatOptions}
        />
        {!disabled && (
          <CustomizedButton
            sx={{ mt: 2 }}
            title="เพิ่มสินค้า"
            variant="outlined"
            onClick={openItemModalHandler}
            addIcon
          />
        )}

        <Grid container maxWidth={984} spacing={0}>
          <Grid item xs={12} sm={12} md={6.5} />
          <Grid item xs={12} sm={12} md={5.5}>
            <SumPreVatAmount control={control} />
          </Grid>
        </Grid>
        <Confirmation
          title="ยืนยันการลบสินค้า"
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
        />
        <CheckedItemListModal
          showSelectItem={itemModal}
          closeItemTable={closeItemModalHandler}
          finishItemsSelect={addReferenceItemsHandler}
          rowData={referenceItemList}
          rowSelection="multiple"
          itemIds={itemIds}
          setItemIds={setItemIds}
          itemIdsSnapshot={itemIdsSnapshot}
          setItemIdsSnapshot={setItemIdsSnapshot}
        />
      </CustomizedBox>
    );
  }

  const textAlignToJustify = (align?: string) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  return (
    <CustomizedBox sx={{ breakAfter: "auto" }} margin={0} maxWidth={1650}>
      <Typography fontWeight={600} color={"primary.main"} mb={2}>
        สินค้า
      </Typography>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: textAlignToJustify(header.align),
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={tableDisabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.unique_id}
                      draggableId={"item-" + field.unique_id}
                      index={index}
                      isDragDisabled={tableDisabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!tableDisabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={tableDisabled}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {numberFormatter(field.qty)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.qty`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.qty
                                )}
                                onChange={(value) => {
                                  const parseValue = value || 0;
                                  const currentUomId = getValues(
                                    `item_list.${index}.uom_id`
                                  );
                                  if (currentUomId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomId ===
                                        field.uom_group.base_uom?.id
                                      ) {
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_id ===
                                                currentUomId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list.${index}.stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                                textAlign="right"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {field.uom_name}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                name={`item_list.${index}.uom_id`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.uom_id
                                )}
                                options={uomOptions(field)}
                                onChange={(e: any) => {
                                  const uom_name = uomOptions(field).find(
                                    (uom) => uom.value === e.target.value
                                  )?.label;
                                  setValue(
                                    `item_list.${index}.uom_name`,
                                    uom_name
                                  );
                                  const currentQty = getValues(
                                    `item_list.${index}.qty`
                                  );
                                  if (field.uom_group) {
                                    if (
                                      e.target.value ===
                                      field.uom_group.base_uom?.id
                                    ) {
                                      setValue(
                                        `item_list.${index}.stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list.${index}.stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.price_per_unit)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                control={control}
                                name={`item_list.${index}.price_per_unit`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.price_per_unit
                                )}
                                decimalScale={4}
                                textAlign="right"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.discount`}
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.discount
                                )}
                                decimalScale={4}
                                textAlign="right"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {tableDisabled ? (
                              <Typography fontSize={14}>
                                {vatTypeFormatter(field.vat_percentage)}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                disabled={Boolean(
                                  field.reference_line_item &&
                                    field.reference_line_item
                                      .line_item_document_type &&
                                    field.reference_line_item
                                      .line_item_document_type === "sales_order"
                                )}
                                name={`item_list.${index}.vat_percentage`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.vat_percentage
                                )}
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={tableDisabled}
                              setValue={setValue}
                            />
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[8]?.width || 0
                                : 0) + 16
                            }
                          >
                            <WithholdingTaxCell
                              control={control}
                              errors={errors}
                              index={index}
                              documentType={documentType}
                              disabled={tableDisabled}
                              setValue={setValue}
                              field={field}
                            />
                          </TableCell> */}
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[8]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <>
                                <Typography fontSize={14}>
                                  {numberFormatter(field.stock_qty)}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#BDBDBD" }}
                                >
                                  {field.uom_group?.base_uom?.name_th}
                                </Typography>
                              </>
                            ) : (
                              <ControlledTextField
                                sx={{ mt: 3 }}
                                control={control}
                                name={`item_list.${index}.stock_qty`}
                                helperText={field.uom_group?.base_uom?.name_th}
                                FormHelperTextProps={{
                                  sx: { color: "#BDBDBD", fontSize: 12 },
                                }}
                                viewMode={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list.${index}.remark`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.remark
                                )}
                                placeholder="หมายเหตุสินค้า"
                              />
                            )}
                          </TableCell>
                          {(documentType === "sales_order" ||
                            documentType === "cash_sales") &&
                            disabled && (
                              <>
                                <TableCell
                                  align="right"
                                  sx={{
                                    px: 1,
                                    py: 1,
                                  }}
                                  width={
                                    (headers && headers.length > 0
                                      ? headers[10]?.width || 0
                                      : 0) + 16
                                  }
                                >
                                  <Typography fontSize={14}>
                                    {numberFormatter(field.qty_to_ship || 0)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    px: 1,
                                    py: 1,
                                  }}
                                  width={
                                    (headers && headers.length > 0
                                      ? headers[11]?.width || 0
                                      : 0) + 16
                                  }
                                >
                                  <Typography fontSize={14}>
                                    {numberFormatter(field.qty_shipped || 0)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    px: 1,
                                    py: 1,
                                  }}
                                  width={
                                    (headers && headers.length > 0
                                      ? headers[12]?.width || 0
                                      : 0) + 16
                                  }
                                >
                                  <Typography fontSize={14}>
                                    {numberFormatter(field.qty_invoiced || 0)}
                                  </Typography>
                                </TableCell>
                                {documentType !== "cash_sales" && (
                                  <TableCell
                                    align="right"
                                    sx={{
                                      px: 1,
                                      py: 1,
                                    }}
                                    width={
                                      (headers && headers.length > 0
                                        ? headers[13]?.width || 0
                                        : 0) + 16
                                    }
                                  >
                                    <Typography fontSize={13}>
                                      {numberFormatter(field.qty_returned || 0)}
                                    </Typography>
                                  </TableCell>
                                )}
                              </>
                            )}
                          {!tableDisabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              // width={
                              //   (headers && headers.length > 0
                              //     ? headers[11]?.width || 0
                              //     : 0) + 16
                              // }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!tableDisabled && (
        <CustomizedButton
          sx={{ mt: 2 }}
          title="เพิ่มสินค้า"
          variant="outlined"
          onClick={openItemModalHandler}
          addIcon
        />
      )}

      <Grid container maxWidth={984} spacing={0}>
        <Grid item xs={12} sm={12} md={6.5} />
        <Grid item xs={12} sm={12} md={5.5}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      />
    </CustomizedBox>
  );
};

export default SalesItemList;
