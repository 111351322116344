import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../generated/general";
import { ITraceEntry } from "../types/Inventory";
export const useInventoryPDFMultiplePage = (
  data: any,
  rowWidth: number, // item name and description cell width
  maxHeight: number,
  leftHeaderMinHeight: number,
  rightHeaderMinHeight: number
) => {
  const baseHeaderHeight = Math.max(leftHeaderMinHeight, rightHeaderMinHeight);

  let paginatedItems: any[][] = [];
  let currentItems: any[] = [];
  let currentTableHeight = 0;

  // Initialize traceEntryList from data or set to an empty array
  const traceEntryList: ITraceEntry[] = data?.trace_entry_list || [];

  // Create a map to track the first occurrence index of each item_unique_id
  const order = new Map<string, number>();
  traceEntryList.forEach((item, index) => {
    if (!order.has(item.item_unique_id)) {
      order.set(item.item_unique_id, index);
    }
  });

  // Sort traceEntryList based on the first occurrence of item_unique_id
  traceEntryList.sort(
    (a, b) => order.get(a.item_unique_id)! - order.get(b.item_unique_id)!
  );

  const result: any[] = [];
  const seenSkuNames = new Set<string>();

  const qtyMap = new Map();

  // Iterate through the data
  traceEntryList.forEach((item) => {
    const isSerial = item.tracability === "serial";
    // Check if the item is serial and not yet processed
    if (isSerial && !seenSkuNames.has(item.sku_name)) {
      // Update the total quantity in the Map
      if (qtyMap.has(item.sku_name)) {
        qtyMap.set(item.sku_name, qtyMap.get(item.sku_name) + item.qty);
      } else {
        qtyMap.set(item.sku_name, item.qty);
      }
    }
  });

  traceEntryList.forEach((item) => {
    const isSerial = item.tracability === "serial";

    // Add the item to the result only if it's a "serial" traceability and SKU name hasn't been seen
    if (isSerial && !seenSkuNames.has(item.sku_name)) {
      result.push({
        item_unique_id: item.item_unique_id,
        sku_name: item.sku_name,
        item_name: item.item_name,
        tracability: item.tracability,
        uom: item.uom,
        qty: qtyMap.get(item.sku_name),
      });
      seenSkuNames.add(item.sku_name);
    }

    // Add item details based on traceability type
    result.push({
      ...(isSerial
        ? {
            qty: item.qty,
            uom: item.uom,
            tracability: item.tracability,
            serial_no: item.serial_no,
            barcode: item.barcode,
          }
        : {
            item_unique_id: item.item_unique_id,
            sku_name: item.sku_name,
            item_name: item.item_name,
            tracability: item.tracability,
            uom: item.uom,
            qty: item.qty,
            barcode: item.barcode,
          }),
    });
  });

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: company } = useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: 1,
      },
    }
  );

  const defaultAddress =
    company?.CompanyFindUnique &&
    company.CompanyFindUnique.address_list &&
    company.CompanyFindUnique.address_list.find(
      (address: { is_default: any }) => address.is_default
    );

  //Calculate header height

  const calculateLeftHeaderHeight = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const companyAddressMaxWidth = 282.73;
    const addressMaxWidth = 303.11;
    const nameMaxWidth = 303.11;

    if (!context) {
      throw new Error("Cannot get 2D context");
    }

    context.font = "10.66px";

    const companyAddress = `${defaultAddress?.address} ${defaultAddress?.sub_district} ${defaultAddress?.district} ${defaultAddress?.province} ${defaultAddress?.postal_code}`;

    const customerAddress = "-";

    let name = "-";

    let additionalHeight = 0;

    let companyAddressWidth = context.measureText(companyAddress).width;
    if (companyAddressWidth > companyAddressMaxWidth) {
      const lines = Math.ceil(companyAddressWidth / addressMaxWidth);
      additionalHeight += (lines - 1) * 15.98;
    }

    let customerAddressWidth = context.measureText(customerAddress).width;
    if (customerAddressWidth > addressMaxWidth) {
      const lines = Math.ceil(customerAddressWidth / addressMaxWidth);
      additionalHeight += (lines - 1) * 21.313;
    }

    let nameWidth = context.measureText(name).width;
    if (nameWidth > nameMaxWidth) {
      const lines = Math.ceil(nameWidth / nameMaxWidth);
      additionalHeight += (lines - 1) * 21.313;
    }

    return leftHeaderMinHeight + additionalHeight;
  };

  const calculateRightHeaderHeight = () => {
    return rightHeaderMinHeight;
  };

  const leftHeaderHeight = calculateLeftHeaderHeight();
  const rightHeaderHeight = calculateRightHeaderHeight();

  const additionalHeaderHeight =
    Math.max(leftHeaderHeight, rightHeaderHeight) - baseHeaderHeight;

  maxHeight -= additionalHeaderHeight;

  let numberOfLines = 1;

  result?.forEach((item) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "10.66px";

    let rowHeight = 0;
    const { sku_name, tracability } = item;

    const nameText = sku_name;
    const nameWidth = context.measureText(nameText).width;
    const nameLines = Math.ceil(nameWidth / rowWidth);
    numberOfLines += nameLines > 1 ? nameLines : 0;

    if (numberOfLines > 1) {
      rowHeight = 56 + (numberOfLines - 1) * 15;
    } else {
      rowHeight = tracability === "normal" ? 56 : 36;
    }

    //   let existingItem = currentItems.find(
    //     (currItem) => currItem.item_unique_id === item.item_unique_id
    //   );

    //   if (tracability === "serial") {
    //     if (currentTableHeight + rowHeight > maxHeight) {
    //       paginatedItems.push(currentItems);
    //       currentItems = [];
    //       currentTableHeight = rowHeight;
    //       if (existingItem) {
    //         currentItems.push({
    //           qty: item.qty,
    //           uom: item.uom,
    //           tracability: item.tracability,
    //           serial_no: item.serial_no,
    //           barcode: item.barcode,
    //         });
    //       } else {
    //         currentItems.push({
    //           qty: item.qty,
    //           uom: item.uom,
    //           tracability: item.tracability,
    //           serial_no: item.serial_no,
    //           barcode: item.barcode,
    //         });
    //       }
    //     } else {
    //       currentTableHeight += rowHeight;
    //       if (existingItem) {
    //         currentItems.push({
    //           qty: item.qty,
    //           uom: item.uom,
    //           tracability: item.tracability,
    //           serial_no: item.serial_no,
    //           barcode: item.barcode,
    //         });
    //       } else {
    //         currentItems.push({
    //           item_unique_id: item.item_unique_id,
    //           sku_name: item.sku_name,
    //           item_name: item.item_name,
    //           tracability: item.tracability,
    //           uom: item.uom,
    //         });
    //         currentItems.push({
    //           qty: item.qty,
    //           uom: item.uom,
    //           tracability: item.tracability,
    //           serial_no: item.serial_no,
    //           barcode: item.barcode,
    //         });
    //       }
    //     }
    //   } else {
    //     if (currentTableHeight + rowHeight > maxHeight) {
    //       paginatedItems.push(currentItems);
    //       currentItems = [];
    //       currentTableHeight = rowHeight;
    //     } else {
    //       currentTableHeight += rowHeight;
    //     }
    //     currentItems.push(item);
    //   }
    // });

    if (currentTableHeight + rowHeight > maxHeight) {
      paginatedItems.push(currentItems);
      currentItems = [];
      currentTableHeight = rowHeight;
      currentItems.push(item);
    } else {
      currentTableHeight += rowHeight;
      currentItems.push(item);
    }
  });

  paginatedItems.push(currentItems);

  if (currentTableHeight > maxHeight) {
    paginatedItems.push([]);
  }

  let startNumbers: number[] = [];
  let currentNumber = 0;

  paginatedItems.forEach((pageItems, index) => {
    if (index === 0) {
      startNumbers.push(1);
      currentNumber = pageItems.filter((item) => item.sku_name).length;
    } else {
      startNumbers.push(currentNumber + 1);
      currentNumber += pageItems.filter((item) => item.sku_name).length;
    }
  });

  return {
    paginatedItems,
    startNumbers,
  };
};
