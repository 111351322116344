import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { CUSTOMERS_AGGRID } from "../../../services/AgGrid/ContactAgGrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import useContactColumnDefs from "../../Table/Contact/useContactColumnDefs";

type CheckedCustomerModalProps = {
  showSelectCustomer: boolean;
  closeCustomerTable: () => void;
  finishCustomersSelect: (data: any) => void;
  customerIds: string[];
  setCustomerIds: Dispatch<SetStateAction<string[]>>;
  customerIdsSnapshot: string[];
  setCustomerIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single";
};

const CheckedCustomerModal = ({
  showSelectCustomer,
  closeCustomerTable,
  finishCustomersSelect,
  customerIds,
  setCustomerIds,
  customerIdsSnapshot,
  setCustomerIdsSnapshot,
  rowSelection,
}: CheckedCustomerModalProps) => {
  const { t } = useTranslation();
  const selectCustomerGridRef = useRef();

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("crm");

  const columnDefs = useContactColumnDefs("customer");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, tag_list, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
      };
      try {
        const { CustomersView } = await graphQLClient.request(
          CUSTOMERS_AGGRID,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: CustomersView.results as any[],
          rowCount: CustomersView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    if (params) {
      const instanceStatus = params.api.getFilterInstance("status");
      instanceStatus?.setModel({ values: ["1"] });

      params.api.setServerSideDatasource(datasource);
    }
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={"ลูกค้า"}
      btnTitle={t("button.add")}
      gridRef={selectCustomerGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishCustomersSelect}
      modalIsOpen={showSelectCustomer}
      getRowId={getRowId}
      closeModal={closeCustomerTable}
      selectedIds={customerIds}
      setSelectedIds={setCustomerIds}
      idsSnapshot={customerIdsSnapshot}
      setIdsSnapshot={setCustomerIdsSnapshot}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedCustomerModal;
