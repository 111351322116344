import { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import DocumentInfoTab from "./DocumentInfoTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import BreadcrumbsLayout from "../../../components/UI/BreadcrumbsLayout";

import GoodsReceiveTab from "./GoodsReceiveTab";
import PurchaseReturnTab from "./PurchaseReturnTab";
import {
  purchaseOrderSchema,
  purchaseOrderValidation,
} from "../../../components/Form/Purchase/Order/schema";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";

import { ActivityLogDocumentType } from "../../../generated/inventory";
import {
  PurchaseOrderFindUniqueQuery,
  usePurchaseOrderFindUniqueQuery,
} from "../../../generated/purchase";

const PurchaseOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const methods = useForm<IPurchaseOrder>({
    defaultValues: { ...purchaseOrderSchema },
    resolver: yupResolver<any>(purchaseOrderValidation),
  });

  const { control } = methods;

  const { data, isLoading } =
    usePurchaseOrderFindUniqueQuery<PurchaseOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.goods_receive.index"),
      path: `${pathname}?tab=goods_receive`,
    },
    {
      label: t("purchase.return.index"),
      path: `${pathname}?tab=purchase_return`,
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "goods_receive":
        return (
          <GoodsReceiveTab
            referenceUniqueId={data?.PurchaseOrderFindUnique.unique_id}
            isLoading={isLoading}
          />
        );
      case "purchase_return":
        return (
          <PurchaseReturnTab
            referenceUniqueId={data?.PurchaseOrderFindUnique.unique_id}
            isLoading={isLoading}
          />
        );
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
      to: "/purchase/order",
    },
    {
      name: id
        ? data?.PurchaseOrderFindUnique.unique_id || "-"
        : t("button.create") + t("purchase.order.index"),
    },
  ];

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        setOpenDrawer={setOpenDrawer}
      />
      {(tab ||
        status === "approved" ||
        status === "partially_imported" ||
        status === "fully_imported" ||
        status === "cancelled") && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.PurchaseOrder}
        />
      )}
    </FormProvider>
  );
};

export default PurchaseOrderContainer;
