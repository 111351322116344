import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import DropzoneUI from "../../UI/DropzoneUI";
import { contactPersonSchema } from "../../Form/Contact/schema";
import { IContactPerson } from "../../../types/Contact/contact";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Fragment, useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { contactChannelOptions } from "../../../utils/Global";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../LabelInput";
import { contactChannelFormatter } from "../../../utils/Formatter/Global";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  isEdit?: boolean;
  onAddHandler?: (data: IContactPerson) => void;
  onUpdateHandler?: (data: IContactPerson) => void;
  data?: IContactPerson;
};

const PersonModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  isEdit,
  data,
}: Props) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IContactPerson>({
    defaultValues: contactPersonSchema,
  });

  const [disabled] = useDisable();

  useEffect(() => {
    if (isEdit) {
      reset(data);
    }
  }, [data, isEdit, reset]);

  const { t } = useTranslation();

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_channel_list",
  });

  const addContactChannel = () => {
    append({
      contact_channel_type: "",
      contact_channel_info: "",
    });
  };

  const removeContactChannel = (index: number) => {
    remove(index);
  };

  const onClose = () => {
    if (!disabled) {
      reset(contactPersonSchema);
    }
    closeModalHandler();
  };

  return (
    <ModalUI
      title={
        isEdit
          ? t("sentence.edit")
          : t("button.add") + t("contact.contact_person.index")
      }
      open={open}
      handleClose={onClose}
      width={900}
      maxWidth="md"
      action={
        <>
          {!disabled ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <CustomizedButton
                title={t("button.cancel")}
                variant="outlined"
                onClick={onClose}
              />
              {isEdit ? (
                <CustomizedButton
                  title={t("button.save")}
                  variant="contained"
                  onClick={() => {
                    if (onUpdateHandler) {
                      handleSubmit(onUpdateHandler)();
                    }
                    onClose();
                  }}
                />
              ) : (
                <CustomizedButton
                  title={t("button.add")}
                  variant="contained"
                  onClick={() => {
                    if (onAddHandler) {
                      handleSubmit(onAddHandler)();
                    }
                    onClose();
                  }}
                />
              )}
            </Box>
          ) : undefined}
        </>
      }
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <Typography color="primary.main" fontWeight={600} mb={3}>
            ข้อมูล
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="position"
                control={control}
                label={t(`contact.contact_person.position`)}
                error={Boolean(errors.position)}
                helperText={errors.position?.message?.toString()}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t("contact.contact_person.prefix.index")}
                  value={getValues("title_name")}
                />
              ) : (
                <ControlledSelect
                  name="title_name"
                  control={control}
                  label={t("contact.contact_person.prefix.index")}
                  error={Boolean(errors.title_name)}
                  helperText={errors.title_name?.message?.toString()}
                  options={prefixes}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="first_name"
                control={control}
                label={t(`contact.contact_person.name.first_name`)}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message?.toString()}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="last_name"
                control={control}
                label={t(`contact.contact_person.name.last_name`)}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message?.toString()}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="nick_name"
                control={control}
                label={t(`contact.contact_person.name.nick_name`)}
                error={Boolean(errors.nick_name)}
                helperText={errors.nick_name?.message?.toString()}
                viewMode={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={disabled}
            isNotRevoke
          />
        </Grid>
      </Grid>
      <Typography color="primary.main" fontWeight={600} my={2}>
        {t("contact.contact_channel.index")}
      </Typography>
      <Grid container spacing={1.5}>
        {fields.map((channel, index) => (
          <Fragment key={channel.id}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {disabled ? (
                <LabelInput
                  label={t("contact.contact_channel.type")}
                  value={contactChannelFormatter(
                    getValues(
                      `contact_channel_list.${index}.contact_channel_type`
                    )
                  )}
                />
              ) : (
                <ControlledSelect
                  name={`contact_channel_list.${index}.contact_channel_type`}
                  control={control}
                  label={t("contact.contact_channel.type")}
                  options={contactChannelOptions}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {!disabled ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <ControlledTextField
                    name={`contact_channel_list.${index}.contact_channel_info`}
                    control={control}
                    label={t("contact.contact_channel.info")}
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_channel_info
                    )}
                    helperText={
                      errors.contact_channel_list &&
                      errors.contact_channel_list?.[index]
                        ?.contact_channel_info &&
                      errors.contact_channel_list?.[index]?.contact_channel_info
                        ?.message
                    }
                    viewMode={disabled}
                    required
                  />
                  <IconButton onClick={() => removeContactChannel(index)}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              ) : (
                <ControlledTextField
                  name={`contact_channel_list.${index}.contact_channel_info`}
                  control={control}
                  label={t("contact.contact_channel.info")}
                  error={Boolean(
                    errors.contact_channel_list?.[index]?.contact_channel_info
                  )}
                  helperText={
                    errors.contact_channel_list &&
                    errors.contact_channel_list?.[index]
                      ?.contact_channel_info &&
                    errors.contact_channel_list?.[index]?.contact_channel_info
                      ?.message
                  }
                  viewMode={disabled}
                  required
                />
              )}
            </Grid>
          </Fragment>
        ))}
      </Grid>
      {!disabled && (
        <CustomizedButton
          sx={{
            mt: 2,
          }}
          title={t("button.add") + t("contact.contact_channel.index")}
          variant="outlined"
          onClick={addContactChannel}
        />
      )}
      <Typography color="primary.main" fontWeight={600} my={2}>
        {t("sentence.remark")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name="remark"
            error={Boolean(errors?.remark)}
            helperText={errors?.remark && errors.remark.message?.toString()}
            multiline
            rows={3}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default PersonModal;
