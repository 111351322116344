import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useGoodsIssueItemListPdf } from "../../../../hooks/Inventory/use-issue-item-list";
import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";
import { Fragment } from "react";
import { Tracability } from "../../../../generated/general";
import { formatDate } from "../../../../utils/Date";
// import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import GoodsIssueSerialListPdf from "./GoodsIssueSerialListPdf";

type Props = { itemList?: IGoodsIssue["trace_entry_list"] };

const GoodsIssuePdfTable = ({ itemList }: Props) => {
  const headers = useGoodsIssueItemListPdf();

  const tablePadding = {
    px: 1,
    py: 1,
  };

  const fontSize = 10;

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={header.align || "center"}
                key={index}
                sx={tablePadding}
                width={header.width}
              >
                <Typography fontSize={12} fontWeight={600} width={header.width}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList?.map((trace, index) => (
            <Fragment key={index}>
              <TableRow>
                <TableCell align="center" sx={tablePadding}>
                  <Typography fontSize={fontSize}>{index + 1}</Typography>
                </TableCell>
                {/* <TableCell align="center" sx={tablePadding}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      minWidth: "60px",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={trace.item_name}
                      src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                      sx={{
                        width: 50,
                        height: 50,
                        border: "1px solid #BEBEBE",
                        borderRadius: "2px",
                      }}
                      variant="square"
                    >
                      <ImageOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        fontSize="medium"
                      />
                    </Avatar>
                  </Box>
                </TableCell> */}
                <TableCell sx={tablePadding}>
                  <Typography fontSize={fontSize}>
                    {trace.item_unique_id}
                  </Typography>
                  <Typography fontSize={fontSize}>{trace.item_name}</Typography>
                </TableCell>
                <TableCell align="center" sx={tablePadding}>
                  {trace.tracability === Tracability.Normal ? (
                    <Typography fontSize={fontSize}>{trace.qty}</Typography>
                  ) : (
                    <Typography fontSize={fontSize}>
                      {trace.serial_list?.reduce(
                        (prev, curr) => prev + curr.qty,
                        0
                      )}
                    </Typography>
                  )}
                  <Typography fontSize={fontSize}>
                    {trace.uom?.name_th}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={fontSize}>
                      {trace.serial_no}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={fontSize}>
                      {trace.source_bin_location?.name || ""}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={fontSize}>{trace.barcode}</Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={fontSize}>{trace.remark}</Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={fontSize}>
                      {formatDate(trace.lot_date)}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              <GoodsIssueSerialListPdf
                fontSize={fontSize}
                serialList={trace.serial_list ?? []}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoodsIssuePdfTable;
