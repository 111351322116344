import { IPriceList } from "../../../types/Inventory/PriceList";
import * as Yup from "yup";

export const priceListSchema: IPriceList = {
  unique_id: "",
  name: "",
  adjusted_price: 0,
  currency: "THB",
  description: "",
  start_date: null,
  end_date: null,
  is_active: 1,
  is_increase: 1,
  type: "all",
  adjusted_item_list: [],
};

const adjustedItemListSchema = Yup.object().shape({
  adjusted_price: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .required("กรุณากำหนดราคา"),
});

export const priceListValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุรหัสราคา"),
  type: Yup.string(),
  name: Yup.string().required("กรุณาระบุชื่อราคา"),
  adjusted_item_list: Yup.array()
    .of(adjustedItemListSchema)
    .when("type", {
      is: "by_item",
      then: (schema) => schema.min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
    }),
  sales_channel_id: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? undefined : value
    )
    .required("กรุณาเลือกช่องทางการขาย"),
  start_date: Yup.date().required("กรุณาระบุวันที่เริ่ม"),
  end_date: Yup.date()
    .required("กรุณาระบุวันที่สิ้นสุด")
    .when(
      "start_date",
      (start_date, schema) =>
        start_date &&
        schema.min(
          start_date,
          "กรุณาระบุวันที่เท่ากับหรือสูงกว่าวันที่เริ่มต้น"
        )
    ),
  adjusted_price: Yup.number().when("type", {
    is: "all",
    then: (schema) =>
      schema
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .required("กรุณาระบุเปอร์เซ็น"),
  }),
});
