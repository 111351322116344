import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { ISetAttributeSetting } from "../../../types/global";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: ISetAttributeSetting) => void;
  onUpdateHandler: (data: ISetAttributeSetting) => void;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const setAttributeSettingSchema: ISetAttributeSetting = {
  name_th: "",
  remark: "",
};

const setAttributeSettingValidation = Yup.object({
  name_th: Yup.string().required("กรุณาระบุชื่อ"),
});

const SetAttributeModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  data,
  isLoading,
  isSuccess,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const id = searchParams.get("id");
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: setAttributeSettingSchema,
    resolver: yupResolver<any>(setAttributeSettingValidation),
  });

  const onClose = () => {
    reset(setAttributeSettingSchema);
    closeModalHandler();
  };

  useEffect(() => {
    if (isSuccess) {
      reset(data);
    }
  }, [data, isSuccess, reset]);

  return (
    <ModalUI
      isLoading={Boolean(id && isLoading)}
      title={
        (id ? t("sentence.edit") : t("button.add")) +
        t("inventory.items.set_attribute")
      }
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.save")}
            variant="contained"
            onClick={() => {
              handleSubmit(id ? onUpdateHandler : onAddHandler)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.name_th")}
            control={control}
            name="name_th"
            error={Boolean(errors?.name_th)}
            helperText={errors?.name_th && errors.name_th.message?.toString()}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.description")}
            control={control}
            name="remark"
            error={Boolean(errors?.remark)}
            helperText={errors?.remark && errors.remark.message?.toString()}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default SetAttributeModal;
