import { Box, InputLabel, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";
import { numberFormatter } from "../../utils/Formatter/Global";
import { StandardLonghandProperties } from "../../../node_modules/csstype/index";

interface Props {
  label: ReactNode;
  noDecimal?: boolean;
  value?: string | number;
  helperText?: ReactNode;
  helperTextSx?: SxProps<Theme>;
  endAdornment?: string;
  textAlign?: StandardLonghandProperties["textAlign"];
}

const LabelInput = ({
  label,
  noDecimal,
  value,
  textAlign,
  endAdornment,
  helperText,
  helperTextSx,
}: Props) => {
  const formatValue =
    typeof value === "number"
      ? noDecimal
        ? value
        : numberFormatter(value)
      : value;
  return (
    <Box mx={1.5}>
      <InputLabel sx={{ fontSize: 11.11 }}>{label}</InputLabel>
      {endAdornment ? (
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ whiteSpace: "pre-line", textAlign: textAlign }}>
            {formatValue || "-"}
          </Typography>
          <InputLabel>{endAdornment}</InputLabel>
        </Box>
      ) : (
        <>
          <Typography sx={{ whiteSpace: "pre-line", textAlign: textAlign }}>
            {formatValue || "-"}
          </Typography>
          {helperText && (
            <Typography sx={helperTextSx}>{helperText}</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default LabelInput;
