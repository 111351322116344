import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { IExporterDate } from "../../../types/global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import CustomizedButton from "../../Custom/CustomizedButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioBox from "../RadioBox";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  exportHandler: (data: IExporterDate) => void;
};

const exporterSchena: IExporterDate = {
  export_type: "all",
  start_date: null,
  end_date: null,
};

const exporterValidation = Yup.object().shape({
  start_date: Yup.date().when("export_type", {
    is: "date_range",
    then: (schema) => schema.required("กรุณาเลือกวันที่"),
    otherwise: (schema) => schema.nullable(),
  }),
  end_date: Yup.date().when("export_type", {
    is: "date_range",
    then: (schema) => schema.required("กรุณาเลือกวันที่"),
    otherwise: (schema) => schema.nullable(),
  }),
});

const ExporterPriceListModal = ({
  open,
  closeModalHandler,
  exportHandler,
}: Props) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
    reset,
  } = useForm<IExporterDate>({
    defaultValues: exporterSchena,
    resolver: yupResolver<any>(exporterValidation),
  });

  const onClose = () => {
    reset(exporterSchena);
    closeModalHandler();
  };

  const watchExportType = watch("export_type");
  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.export")}
            variant="contained"
            onClick={() => {
              handleSubmit(exportHandler)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.all")}
            onClick={() => {
              setValue("export_type", "all");
              setValue("start_date", null);
              setValue("end_date", null);
            }}
            checked={watchExportType === "all"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.date_range")}
            onClick={() => {
              setValue("export_type", "date_range");
            }}
            checked={watchExportType === "date_range"}
          />
        </Grid>
        {watchExportType === "date_range" && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="start_date"
                label={t("date.from")}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="end_date"
                label={t("date.to")}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalUI>
  );
};

export default ExporterPriceListModal;
