import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Box, Typography } from "@mui/material";
import { numberFormatter } from "../../../../utils/Formatter/Global";

interface Props {
  control: IDefaultForm["control"];
}

const SumPreVatAmount = ({ control }: Props) => {
  const sumPreVatAmount =
    useWatch({
      control,
      name: "pre_vat_amount",
    }) || 0;
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        alignItems: "center",
        gap: 3,
        bgcolor: "primary.light",
        py: 1,
      }}
    >
      <>
        <Typography
          variant="subtitle1"
          textAlign={"right"}
          flex={2}
          color={"primary.main"}
          fontWeight={600}
        >
          รวม
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
          color={"primary.main"}
          fontWeight={600}
        >
          {numberFormatter(sumPreVatAmount)}
        </Typography>
      </>
      <Typography
        variant="subtitle1"
        sx={{ ml: 2.5, mr: 4 }}
        color={"primary.main"}
        fontWeight={600}
      >
        บาท
      </Typography>
    </Box>
  );
};

export default SumPreVatAmount;
