import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Stack, IconButton } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  userSchema,
  userValidation,
} from "../../../components/Form/User/schema";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import UserCredential from "../../../components/Form/User/Credential";
import UserGeneralInfo from "../../../components/Form/User/GeneralInfo";
import UserDutyInfo from "../../../components/Form/User/DutyInfo";
import UserPermission from "../../../components/Form/User/Permission";
import { IUser } from "../../../types/Auth/user";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useState } from "react";
import { useDisable } from "../../../hooks/use-disable";
import {
  useUserCreateMutation,
  useUserUpdateMutation,
  useUserQuery,
  UserQuery,
  useUserDeleteMutation,
  useBranchUserUpsertMutation,
  useGeneralUniqueIdGenerateQuery,
  GeneralUniqueIdGenerateQuery,
  GeneralModelType,
} from "../../../generated/general";
import {
  userCreateFormatter,
  userQueryFormatter,
  userUpdateFormatter,
} from "../../../utils/Formatter/User";
import { useUserOption } from "../../../hooks/use-user-option";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import { useModal } from "../../../hooks/use-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Global";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

const UserAccountContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled, setDisabled] = useDisable();
  const [showActivityLog, setShowActivityLog] = useState<boolean>(false);

  const [removeIds, setRemoveIds] = useState<number[]>([]);

  const navigate = useNavigate();

  const { createActivityLog } = useActivityLog();

  const methods = useForm<IUser>({
    defaultValues: userSchema,
    resolver: yupResolver<any>(userValidation),
  });

  const { handleSubmit, reset, setValue } = methods;

  const { isOptionLoading, branches, branchUser, refetchBranchUser } =
    useUserOption(id ? parseInt(id) : undefined);

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess } = useUserQuery<UserQuery>(
    graphqlClientGeneral,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphqlClientGeneral,
      {
        modelType: GeneralModelType.User,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(
    async (button: boolean) => {
      const { data } = await refetchUniqueId();
      if (!id || button) setValue("unique_id", data?.UniqueIdGenerate ?? "");
    },
    [id, refetchUniqueId, setValue]
  );

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
      to: "/user/account",
    },
    {
      name:
        id && data && data.UserFindUnique?.unique_id
          ? data.UserFindUnique?.unique_id
          : "" || t("user.account.add_new_account"),
    },
  ];

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    generateUniqueId(false);
  }, [generateUniqueId]);

  useEffect(() => {
    if (isSuccess) {
      const { UserFindUnique } = data;
      const userData = userQueryFormatter(
        UserFindUnique as IUser,
        branches,
        branchUser
      );
      reset(userData);
    }
  }, [branchUser, branches, data, isSuccess, reset]);

  const { mutateAsync: create, isLoading: isCreating } =
    useUserCreateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: update, isLoading: isUpdating } =
    useUserUpdateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: deleteUser, isLoading: isDeleting } =
    useUserDeleteMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: branchUserUpsert, isLoading: isBranchUserUpserting } =
    useBranchUserUpsertMutation<Error>(graphqlClientGeneral);

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: false,
    },
    {
      value: "ลบ",
      disabled: false,
    },
  ];

  const userCreateHandler = async (data: IUser) => {
    try {
      const formatData = await userCreateFormatter(data, id);
      const { UserCreate } = await create({
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.User,
        reference_id: UserCreate!.id,
        activity_detail: {},
      });
      const { branch_permission } = data;
      const addBranchUserPayload = branch_permission
        ?.filter((branchUser) => branchUser.access === "open")
        .map((branch) => ({
          branch_id: branch.id,
          user_id: UserCreate?.id ? UserCreate.id : 0,
        }));
      const removeBranchUserPayload = removeIds.map((branchId) => ({
        branch_id: branchId,
        user_id: UserCreate?.id ? UserCreate.id : 0,
      }));
      await branchUserUpsert({
        addInput: addBranchUserPayload,
        deleteInput: removeBranchUserPayload,
      });
      enqueueSnackbar("สร้างผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate(`/user/account/${UserCreate?.id}?tab=branch`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "สร้างผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const userUpdateHandler = async (data: IUser) => {
    try {
      const { id } = data;
      const formatData = await userUpdateFormatter(data);
      const { UserUpdate } = await update({
        uniqueInput: {
          id: id,
        },
        data: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.User,
        reference_id: UserUpdate!.id,
        activity_detail: {},
      });
      const { branch_permission } = data;
      const mappedBranchId =
        branchUser?.map((branch) => branch.branch_id) || [];

      const addBranchUserPayload = branch_permission
        ?.filter(
          (permission) =>
            !mappedBranchId?.includes(permission.id) &&
            permission.access === "open"
        )
        .map((branch) => ({
          branch_id: branch.id,
          user_id: id || 0,
        }));
      const removeBranchUserPayload = removeIds.map((branchId) => ({
        branch_id: branchId,
        user_id: id || 0,
      }));
      await branchUserUpsert({
        addInput: addBranchUserPayload,
        deleteInput: removeBranchUserPayload,
      });
      enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      const { data: updatedBranchUser } = await refetchBranchUser();
      const updatedData = userQueryFormatter(
        UserUpdate as IUser,
        branches,
        updatedBranchUser?.BranchUserGetByUserId
      );
      reset(updatedData);
      setRemoveIds([]);
      setDisabled(true);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "แก้ไขผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const userDeleteHandler = async () => {
    try {
      const { UserDelete } = await deleteUser({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      await createActivityLog({
        activity_type: ActivityType.Delete,
        document_type: ActivityLogDocumentType.User,
        reference_id: UserDelete!.id,
        activity_detail: {},
      });
      enqueueSnackbar("ลบผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate("/user/account");
    } catch (err) {
      enqueueSnackbar("ลบผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(userDeleteHandler);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  if (
    isOptionLoading ||
    (id &&
      (isLoading ||
        isCreating ||
        isUpdating ||
        isDeleting ||
        isBranchUserUpserting))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.User}
        />
      )}
      <Box display="flex" maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />{" "}
        <Box marginLeft="auto">
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setShowActivityLog(true);
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        maxWidth={1040}
        mt={3}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              case "ลบ":
                openConfirmationHandler();
                break;

              default:
                break;
            }
          }}
          disabled={!id}
        />
      </Box>
      <FormProvider {...methods}>
        <UserCredential />
        <UserGeneralInfo generateUniqueId={generateUniqueId} />
        <UserDutyInfo />
        <UserPermission setRemoveIds={setRemoveIds} isSetting={false} />
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              {!!id && (
                <CustomizedButton
                  variant="outlined"
                  title={t("button.cancel")}
                  onClick={editCancelHandler}
                  disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(
                  id ? userUpdateHandler : userCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <ConfirmationModal
        title={`ยันยืนการลบ${t("user.account.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default UserAccountContainer;
