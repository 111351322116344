import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { Fragment } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";

const InventoryReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.goods_receive.index"),
      to: "goods-receive",
    },
    {
      name: t("inventory.goods_return.index"),
      to: "goods-return",
    },
    {
      name: t("inventory.goods_issue.index"),
      to: "goods-issue",
    },
    {
      name: t("inventory.goods_transfer.index"),
      to: "goods-transfer",
    },
    {
      name: t("inventory.goods_adjustment.index"),
      to: "goods-adjustment",
    },
    {
      name: t("inventory.notActiveSerialNumber.index"),
      to: "unscanned-sn",
    },
    {
      name: t("inventory.itemCurrentStockSerialNumber.index"),
      to: "current-stock-by-sn",
    },
    {
      name: t("inventory.noEntry.index"),
      to: "no-trace-entry",
    },
    {
      name: t("inventory.stockEntry.index"),
      to: "trace-entry",
    },
    {
      name: "จำนวน" + t("inventory.itemCurrentStockBranch.index"),
      to: "current-stock-by-branch",
    },
    {
      name: "จำนวน" + t("inventory.itemCurrentStock.index"),
      to: "current-stock-by-warehouse",
    },
    {
      name: "จำนวน" + t("inventory.itemCurrentStockLocation.index"),
      to: "current-stock-by-location",
    },
    {
      name: "จำนวน" + t("inventory.itemCurrentStockLot.index"),
      to: "current-stock-by-lot",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default InventoryReport;
