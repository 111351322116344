import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";
import ControlledTextField from "../../../Controller/ControlledTextField";
// import { KeyboardEvent } from "react";
import { Typography } from "@mui/material";
import { useState } from "react";

const Remark = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const maxRows = 10;
  const maxChars = 1000;

  const [disabled] = useDisable();

  const { t } = useTranslation();

  const remarkValue = useWatch({
    control,
    name: "remark",
  });

  // const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
  //   const currentLineCount = remarkValue.split("\n").length;

  //   // Check if the current number of lines exceeds the limit
  //   if (currentLineCount >= maxRows && event.key === "Enter") {
  //     event.preventDefault(); // Prevent adding a new line
  //   }
  // };

  const focusHandler = () => {
    setIsFocused(true);
  };

  const blurHandler = () => {
    setIsFocused(false);
  };

  return (
    <>
      <Typography fontWeight={600} color={"primary.main"} mb={1}>
        {t("sentence.remark")}
      </Typography>
      <ControlledTextField
        name="remark"
        control={control}
        error={Boolean(errors.remark)}
        disabled={disabled}
        sx={{ my: 1.5 }}
        multiline
        minRows={5}
        maxRows={maxRows}
        inputProps={{
          maxLength: maxChars,
        }}
        viewModeNoHelperText
        helperText={isFocused ? `${remarkValue.length}/1000` : undefined}
        viewMode={disabled}
        placeholder={t("sentence.remark")}
        onFocus={focusHandler}
        onBlur={blurHandler}
        FormHelperTextProps={{
          style: {
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 0,
          },
        }}
      />
    </>
  );
};

export default Remark;
