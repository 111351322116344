import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { ISelectOption } from "../../../../types/global";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { useDisable } from "../../../../hooks/use-disable";
import { useSalesSummary } from "../../../../hooks/Sales/use-sales-summary";
import { AdditionalDiscountType } from "../../../../generated/sales";
import { useParams } from "react-router-dom";

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

const additionalDiscountTypeOptions: ISelectOption[] = [
  {
    label: "บาท",
    value: AdditionalDiscountType.Baht,
  },
  {
    label: "%",
    value: AdditionalDiscountType.Percent,
  },
];

interface Props {
  documentType?: string;
  isShowRemainingPayment?: boolean;
}

const SalesSummary = ({ documentType, isShowRemainingPayment }: Props) => {
  const { id } = useParams();
  const { control, resetField, setValue } = useFormContext();
  const [disabled] = useDisable();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const pre_vat_amount = useWatch({
    control,
    name: "pre_vat_amount",
  });

  const additionDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  const paidTotalAmount =
    useWatch({
      control,
      name: "paid_total_amount",
    }) || 0;

  const netAmount =
    useWatch({
      control,
      name: "net_amount",
    }) || 0;

  const remainingPayment =
    parseFloat(netAmount.toFixed(2)) - parseFloat(paidTotalAmount.toFixed(2));

  const { t } = useTranslation();
  const summary = useSalesSummary(
    control,
    setValue,
    documentType === "customer_deposit" ? "deposit_list" : "item_list"
  );

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "additional_discount",
        label: `${t("sales.additional_discount")}`,
        amount: summary.additional_discount,
      },
      {
        name: "post_discount_amount",
        label: `${t("sales.post_discount_amount")}`,
        amount: summary.post_discount_amount,
      },
      {
        name: "vat_exempted_amount",
        label: `${t("sales.vat_exempted_amount")}`,
        amount: summary.vat_exempted_amount,
      },
      {
        name: "vat_0_percent_amount",
        label: `${t("sales.vat_0_percent_amount")}`,
        amount: summary.vat_0_percent_amount,
      },
      {
        name: "vat_7_percent_amount",
        label: `${t("sales.vat_7_percent_amount")}`,
        amount: summary.vat_7_percent_amount,
      },
      {
        name: "vat_amount",
        label: `${t("sales.vat_amount")}`,
        amount: summary.vat_amount,
      },
      {
        name: "net_amount",
        label: `${t("sales.net_amount")}`,
        amount: summary.net_amount,
      },
      // {
      //   name: "withholding_tax_amount",
      //   label: `${t("sales.withholding_tax_amount")}`,
      //   amount: summary.withholding_tax_amount,
      // },
      // {
      //   name: "total_amount",
      //   label: `${t("sales.total_amount")}`,
      //   amount: summary.total_amount,
      // },
    ],
    [
      summary.additional_discount,
      summary.net_amount,
      summary.post_discount_amount,
      // summary.total_amount,
      summary.vat_0_percent_amount,
      summary.vat_7_percent_amount,
      summary.vat_amount,
      summary.vat_exempted_amount,
      // summary.withholding_tax_amount,
      t,
    ]
  );

  const [summaryList, setSummaryList] =
    useState<ISummary[]>(defaultsSummaryList);

  useEffect(() => {
    if (additionDiscountType === "percent") {
      setSummaryList([
        {
          name: "additional_discount",
          label: `${t("sales.additional_discount")}`,
          amount: (summary.additional_discount / pre_vat_amount) * 100,
        },
        {
          label: "",
          amount: summary.additional_discount,
        },
        {
          name: "post_discount_amount",
          label: `${t("sales.post_discount_amount")}`,
          amount: summary.post_discount_amount,
        },
        {
          name: "vat_exempted_amount",
          label: `${t("sales.vat_exempted_amount")}`,
          amount: summary.vat_exempted_amount,
        },
        {
          name: "vat_0_percent_amount",
          label: `${t("sales.vat_0_percent_amount")}`,
          amount: summary.vat_0_percent_amount,
        },
        {
          name: "vat_7_percent_amount",
          label: `${t("sales.vat_7_percent_amount")}`,
          amount: summary.vat_7_percent_amount,
        },
        {
          name: "vat_amount",
          label: `${t("sales.vat_amount")}`,
          amount: summary.vat_amount,
        },
        {
          name: "net_amount",
          label: `${t("sales.net_amount")}`,
          amount: summary.net_amount,
        },
        ...(isShowRemainingPayment
          ? [
              {
                name: "remaining_payment",
                label: "มูลค่คงเหลือที่ต้องชำระ",
                amount: remainingPayment,
              },
            ]
          : []),
        // {
        //   name: "withholding_tax_amount",
        //   label: `${t("sales.withholding_tax_amount")}`,
        //   amount: summary.withholding_tax_amount,
        // },
        // {
        //   name: "total_amount",
        //   label: `${t("sales.total_amount")}`,
        //   amount: summary.total_amount,
        // },
      ]);
    } else {
      setSummaryList([
        ...defaultsSummaryList,
        ...(isShowRemainingPayment
          ? [
              {
                name: "remaining_payment",
                label: "มูลค่าคงเหลือที่ต้องชำระ",
                amount: remainingPayment,
              },
            ]
          : []),
      ]);
    }
  }, [
    additionDiscountType,
    defaultsSummaryList,
    isShowRemainingPayment,
    pre_vat_amount,
    remainingPayment,
    summary.additional_discount,
    summary.net_amount,
    summary.post_discount_amount,
    summary.vat_0_percent_amount,
    summary.vat_7_percent_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    t,
  ]);

  useEffect(() => {
    if (documentType === "customer_deposit") {
      defaultsSummaryList.splice(0, 2);
      setSummaryList(defaultsSummaryList);
    }
  }, [defaultsSummaryList, documentType]);

  const additionalDiscountType: string = useWatch({
    control,
    name: "additional_discount_type",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const priceDisabled =
    Boolean(id) &&
    (disabled || (status !== "draft" && status !== "not_approved"));

  return (
    <>
      {summaryList.map((item) => (
        <Box key={item.label} sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1.5,
              gap: 3,
              py: item.name === "net_amount" ? 1 : 0,
            }}
            bgcolor={item.name === "net_amount" ? "primary.light" : undefined}
          >
            {!["additional_discount"].includes(item?.name || "") ||
            priceDisabled ? (
              <>
                <Typography
                  color={"primary.main"}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                  fontWeight={item.name === "net_amount" ? 600 : 400}
                >
                  {item.label}
                </Typography>
                <Typography
                  color={"primary.main"}
                  fontWeight={item.name === "net_amount" ? 600 : 400}
                  sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
                >
                  {numberFormatter(item.amount)}
                </Typography>
                {additionalDiscountType === AdditionalDiscountType.Baht ? (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4.7 }}
                    fontWeight={item.name === "net_amount" ? 600 : 400}
                  >
                    บาท
                  </Typography>
                ) : item.name === "additional_discount" ? (
                  <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 6 }}>
                    %
                  </Typography>
                ) : (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4.7 }}
                    fontWeight={item.name === "net_amount" ? 600 : 400}
                  >
                    บาท
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  color={"primary.main"}
                  mr={2.5}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                >
                  {item.label}
                </Typography>
                <Box
                  minWidth={150}
                  flex={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ControlledNumberTextField
                    name={item?.name || ""}
                    control={control}
                    textAlign="right"
                    disabled={documentType === "sales_return"}
                  />
                </Box>
                {item.name === "additional_discount" ? (
                  <Box
                    sx={{
                      width: 75,
                    }}
                  >
                    <ControlledSelect
                      control={control}
                      name="additional_discount_type"
                      options={additionalDiscountTypeOptions}
                      onChange={() => {
                        if (resetField)
                          resetField("additional_discount", {
                            defaultValue: 0,
                          });
                      }}
                      disabled={documentType === "sales_return"}
                    />
                  </Box>
                ) : (
                  <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 4.7 }}>
                    บาท
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default SalesSummary;
