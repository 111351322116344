import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../hooks/use-disable";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { IGoodsReturn } from "../../../types/Inventory/GoodsReturn";
import {
  goodsReturnSchema,
  goodsReturnValidation,
} from "../../../components/Form/Inventory/GoodsReturn/schema";
import GoodsReturnHeader from "../../../components/Form/Inventory/GoodsReturn/Header";
import GoodsReturnInfo from "../../../components/Form/Inventory/GoodsReturn/Info";
import GoodsReturnItemList from "../../../components/Table/Inventory/GoodsReturn/ItemList";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useInventoryError } from "../../../hooks/use-inventory-error";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  goodsReturnCreatePayloadFormatter,
  goodsReturnQueryFormatter,
  goodsReturnUpdatePayloadFormatter,
} from "../../../utils/Formatter/Inventory/GoodsReturn";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GoodsReturnUpdateInput,
  InventoryDocumentType,
  useGoodsReturnCreateMutation,
  useGoodsReturnQuery,
  useGoodsReturnUpdateMutation,
  useInventoryDocumentCancelMutation,
} from "../../../generated/inventory";
import GoodsReturnScanItemList from "../../../components/Table/Inventory/GoodsReturn/ScanItemList";
import useInventoryControl from "../../../hooks/Inventory/use-inventory-control";
import GoodsReturnHeaderBreadcrumbs from "../../../components/Form/Inventory/GoodsReturn/HeaderBreadcrumbs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useActivityLog } from "../../../hooks/use-activity-log";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";

const GoodsReturnContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDisabled] = useDisable();
  const navigate = useNavigate();
  const methods = useForm<IGoodsReturn>({
    defaultValues: goodsReturnSchema,
    resolver: yupResolver<any>(goodsReturnValidation),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = methods;

  const { createActivityLog } = useActivityLog();

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { mutateAsync: createGoodsReturn, isLoading: isCreating } =
    useGoodsReturnCreateMutation<Error>(graphQLClient);

  const { mutateAsync: updateGoodsReturn, isLoading: isUpdating } =
    useGoodsReturnUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancelGoodsReturn, isLoading: isCanceling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const { isOptionsLoading } = useInventoryControl();

  const { data, refetch, isSuccess, isLoading } = useGoodsReturnQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const { GoodsReturn } = data;
      const goodsReturnType = GoodsReturn as IGoodsReturn;
      const formattedGoodsReceive = goodsReturnQueryFormatter(goodsReturnType);
      reset(formattedGoodsReceive);
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (
      id &&
      (data?.GoodsReturn?.aggrid_status === "finished" ||
        data?.GoodsReturn?.aggrid_status === "cancelled")
    ) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, data?.GoodsReturn?.aggrid_status]);

  useInventoryError(errors);

  const draftHandler = async (data: IGoodsReturn) => {
    try {
      if (!id) {
        const payload = goodsReturnCreatePayloadFormatter(data, "draft");
        const { GoodsReturnCreate } = await createGoodsReturn({
          createInput: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReturn,
          reference_id: GoodsReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
        navigate(`/inventory/goods-return/${GoodsReturnCreate.id}`);
      } else {
        const payload = goodsReturnUpdatePayloadFormatter(data, "draft");
        const { GoodsReturnUpdate } = await updateGoodsReturn({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsReturn,
          reference_id: GoodsReturnUpdate?.id,
          activity_detail: {},
        });
        await refetch();
      }
      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const saveHandler = async (data: IGoodsReturn) => {
    try {
      const payload = goodsReturnUpdatePayloadFormatter(
        data,
        data.aggrid_status || ""
      ) as GoodsReturnUpdateInput;
      await updateGoodsReturn({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      await refetch();
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const waitReceiveHandler = async (data: IGoodsReturn) => {
    try {
      if (!id) {
        const payload = goodsReturnCreatePayloadFormatter(data, "wait_receive");
        const { GoodsReturnCreate } = await createGoodsReturn({
          createInput: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReturn,
          reference_id: GoodsReturnCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_receive",
          },
        });
        navigate(`/inventory/goods-return/${GoodsReturnCreate.id}`);
      } else {
        const payload = goodsReturnUpdatePayloadFormatter(data, "wait_receive");
        const { GoodsReturnUpdate } = await updateGoodsReturn({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: payload,
        });
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReturn,
          reference_id: GoodsReturnUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.main_status,
            curr_status: "wait_receive",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
        await refetch();
      }
      enqueueSnackbar(`${t("sentence.continue")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("sentence.continue")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const inTransitHandler = async (data: IGoodsReturn) => {
    try {
      const payload = goodsReturnUpdatePayloadFormatter(data, "in_transit");
      const { GoodsReturnUpdate } = await updateGoodsReturn({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsReturn,
        reference_id: GoodsReturnUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.main_status,
          curr_status: "in_transit",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
      await refetch();
      enqueueSnackbar(`${t("sentence.continue")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("sentence.continue")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const receiveHandler = async (data: IGoodsReturn) => {
    try {
      const payload = goodsReturnUpdatePayloadFormatter(data, "finished");
      const { GoodsReturnUpdate } = await updateGoodsReturn({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsReturn,
        reference_id: GoodsReturnUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.main_status,
          curr_status: "finished",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
      await refetch();
      enqueueSnackbar(`${t("inventory.goods_return.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("inventory.goods_return.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancelGoodsReturn({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryDocumentType.GoodsReturn,
      });
      await createActivityLog({
        activity_type: ActivityType.Cancel,
        document_type: ActivityLogDocumentType.GoodsReturn,
        reference_id: parseInt(id!),
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("status.cancelled")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("status.cancelled")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const renderButton = (status: string) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(draftHandler)}
              disabled={isUpdating}
            />
            <CustomizedButton
              variant="contained"
              title={t("button.next")}
              onClick={handleSubmit(waitReceiveHandler)}
              disabled={isUpdating}
            />
          </>
        );
      case "wait_receive":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              onClick={handleSubmit(saveHandler)}
              disabled={isUpdating}
            />
            <CustomizedButton
              variant="outlined"
              title={t("status.in_transit")}
              onClick={handleSubmit(inTransitHandler)}
              disabled={isUpdating}
            />
            <CustomizedButton
              variant="contained"
              title={t("inventory.goods_receive.index")}
              onClick={handleSubmit(receiveHandler)}
              disabled={isUpdating}
            />
          </>
        );
      case "in_transit":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              onClick={handleSubmit(saveHandler)}
              disabled={isUpdating}
            />
            <CustomizedButton
              variant="contained"
              title={t("inventory.goods_receive.index")}
              onClick={handleSubmit(receiveHandler)}
              disabled={isUpdating}
            />
          </>
        );
      case "finished":
      case "cancelled":
        return null;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              onClick={handleSubmit(draftHandler)}
              disabled={isCreating}
            />
            <CustomizedButton
              variant="contained"
              title={t("button.next")}
              onClick={handleSubmit(waitReceiveHandler)}
              disabled={isCreating}
            />
          </>
        );
    }
  };

  if (
    isOptionsLoading ||
    (id && (isLoading || isCreating || isUpdating || isCanceling))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <GoodsReturnHeaderBreadcrumbs cancelHandler={cancelHandler} />
      <GoodsReturnHeader />
      <GoodsReturnInfo append={append} replace={replace} />
      {!id || data?.GoodsReturn?.aggrid_status === "draft" ? (
        <GoodsReturnItemList fields={fields} remove={remove} />
      ) : (
        <GoodsReturnScanItemList
          fields={fields}
          append={append}
          remove={remove}
          update={update}
        />
      )}
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(data?.GoodsReturn?.aggrid_status || "")}
        </Stack>
      </BottomNavbar>
    </FormProvider>
  );
};

export default GoodsReturnContainer;
