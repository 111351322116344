import { alpha, Box, BoxProps, styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type ExtendedBoxProps = BoxProps & {
  icon?: ReactNode;
  padding?: number | string;
  isgriditem?: string;
  ismoblie?: boolean;
  testId?: string;
  width?: number | string;
  title?: string;
  onClick?: () => void;
  linkto: string;
};

const ColorIconBox = styled(Box)<ExtendedBoxProps>(
  ({ theme, padding, isgriditem, ismoblie }) => ({
    display: "flex",
    flexDirection: !ismoblie ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${!ismoblie ? "#d6cfcf" : "#328F35"}`,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cursor: "pointer",
    padding: ismoblie ? "1.5rem" : padding ? padding : ".5rem 3rem .5rem 3rem",
    width: isgriditem ? "100%" : ismoblie ? "230px" : "fit-content",
    gap: "1rem",
    height: "100%",
  })
);

const CustomizedMenuBox = ({
  icon,
  title,
  onClick,
  isgriditem,
  width,
  padding,
  testId,
  ismoblie,
  linkto,
}: ExtendedBoxProps) => {
  return (
    <Link to={linkto} style={{ textDecoration: "none" }}>
      <ColorIconBox
        onClick={onClick}
        width={width}
        padding={padding}
        isgriditem={isgriditem}
        data-test-id={testId}
        ismoblie={ismoblie}
        linkto={linkto}
      >
        {icon}
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {title}
        </Typography>
      </ColorIconBox>
    </Link>
  );
};

export default CustomizedMenuBox;
