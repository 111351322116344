import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useModal } from "../../../hooks/use-modal";
import { useState } from "react";
import { IPaymentChannel } from "../../../types/Setting/Sales";
import PaymentChannelModal from "../../../components/UI/Modal/PaymentChannelModal";
import PaymentChannelTable from "../../../components/Table/Setting/PaymentChannel";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  PaymentChannelFindUniqueQuery,
  PaymentChannelsFindAllQuery,
  usePaymentChannelCreateMutation,
  usePaymentChannelDeleteMutation,
  usePaymentChannelFindUniqueQuery,
  usePaymentChannelUpdateMutation,
  usePaymentChannelsFindAllQuery,
} from "../../../generated/general";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";

const PaymentChannelSetting = () => {
  const { t } = useTranslation();
  const [deletingId, setDeletingId] = useState<number>();

  let [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const setSearchParamsHandler = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const removeSearchParamsHandler = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal(
    undefined,
    () => removeSearchParamsHandler("id")
  );

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.sales.index"),
      to: "/setting/sales",
    },
    {
      name: t("setting.sales.payment_channel.index"),
    },
  ];

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData, refetch: refetchRowData } =
    usePaymentChannelsFindAllQuery<PaymentChannelsFindAllQuery>(
      graphQLClientGeneral
    );

  const { data, isLoading, isSuccess } =
    usePaymentChannelFindUniqueQuery<PaymentChannelFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: createPaymentChannel } =
    usePaymentChannelCreateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: updatePaymentChannel } =
    usePaymentChannelUpdateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: deletePaymentChannel } =
    usePaymentChannelDeleteMutation<Error>(graphQLClientGeneral);

  const onAddHandler = async (data: IPaymentChannel) => {
    try {
      const { is_salable, is_purchasable, ...otherData } = data;
      const payment_type = [];
      if (is_salable) {
        payment_type.push("is_salable");
      }
      if (is_purchasable) {
        payment_type.push("is_purchasable");
      }
      const formatData = {
        ...otherData,
        payment_type,
      };
      await createPaymentChannel({
        data: formatData,
      });
      enqueueSnackbar(
        `เพิ่ม${t("setting.sales.payment_channel.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
      await refetchRowData();
    } catch (err) {
      enqueueSnackbar(
        `เพิ่ม${t("setting.sales.payment_channel.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const onEditHandler = (id?: number) => {
    if (id) {
      setSearchParamsHandler("id", id.toString());
      openModalHandler();
    }
  };

  const onUpdateHandler = async (data: IPaymentChannel) => {
    try {
      const { id, is_salable, is_purchasable, ...otherData } = data;

      const payment_type = [];
      if (is_salable) {
        payment_type.push("is_salable");
      }
      if (is_purchasable) {
        payment_type.push("is_purchasable");
      }
      const formatData = {
        ...otherData,
        payment_type,
      };
      await updatePaymentChannel({
        uniqueInput: {
          id: id,
        },
        data: formatData,
      });
      enqueueSnackbar(
        `แก้ไข${t("setting.sales.payment_channel.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
      await refetchRowData();
    } catch (err) {
      enqueueSnackbar(
        `แก้ไข${t("setting.sales.payment_channel.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const onDeleteHandler = (id?: number) => {
    if (id) {
      setDeletingId(id);
      openConfirmationHandler();
    }
  };

  const deleteActionHandler = async () => {
    try {
      await deletePaymentChannel({
        uniqueInput: {
          id: deletingId,
        },
      });
      setDeletingId(undefined);
      enqueueSnackbar(`ลบ${t("setting.sales.payment_channel.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetchRowData();
    } catch (err) {
      enqueueSnackbar(
        `ลบ${t("setting.sales.payment_channel.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deleteActionHandler);

  const branch_id = sessionStorage.getItem("branch-id");
  const is_hq = sessionStorage.getItem("is_hq");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("setting.sales.payment_channel.index")}
        </Typography>
        {is_hq === "true" && (
          <CustomizedButton
            title={t("button.add") + t("setting.sales.payment_channel.index")}
            variant="contained"
            onClick={openModalHandler}
          />
        )}
      </Box>
      <PaymentChannelTable
        data={
          is_hq === "true"
            ? rowData?.PaymentChannelsFindAll || []
            : rowData?.PaymentChannelsFindAll.filter((channel) =>
                channel.branch_id_list?.includes(
                  branch_id ? parseInt(branch_id) : 0
                )
              ) || []
        }
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
      />
      <PaymentChannelModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
        data={data?.PaymentChannelFindUnique}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <ConfirmationModal
        title={`ยันยืนการลบ${t("setting.sales.payment_channel.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default PaymentChannelSetting;
