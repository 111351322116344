import { WarehouseFindUniqueQuery } from "../../generated/general";
import { IWarehouse } from "../../types/Setting/Inventory";

export const warehouseCreateFormatter = (data: IWarehouse) => {
  const {
    supervisor,
    branch_unique_id,
    branch_id,
    user_id,
    warehouse_level_1_list,
    ...otherData
  } = data;
  return {
    branch_id: branch_id as number,
    user_id: user_id as number,
    ...otherData,
  };
};

export const warehouseUpdateFormatter = (data: IWarehouse) => {
  const {
    id,
    supervisor,
    branch_unique_id,
    branch_id,
    user_id,
    warehouse_level_1_list,
    ...otherData
  } = data;
  return {
    branch_id: branch_id as number,
    user_id: user_id as number,
    ...otherData,
  };
};

export const warehouseQueryFormatter = (
  data: WarehouseFindUniqueQuery["WarehouseFindUnique"]
) => {
  const warehouseData = { ...data };
  const { branch, user, description, ...otherData } = warehouseData;
  const formatData = {
    ...otherData,
    branch_id: branch?.id,
    user_id: user?.id,
    branch_unique_id: branch?.unique_id,
    supervisor: user?.first_name + " " + user?.last_name,
    description: description as string,
  };
  return formatData;
};
