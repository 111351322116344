import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledSwitch from "../../../Controller/ControlledSwitch";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "../../../../generated/sales";
import { useCallback, useEffect } from "react";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { IExternalProps } from "../../../../types/Sales";
import { useDisable } from "../../../../hooks/use-disable";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { useModal } from "../../../../hooks/use-modal";
import ExternalReferenceModal from "../../../UI/Modal/ExternalReferenceModal";
import { useCashSalesOption } from "../../../../hooks/Sales/CashSales/use-cash-sales-option";
import { useCashSalesCreate } from "../../../../hooks/Sales/CashSales/use-cash-sales-create";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import {
  copyCashSalesFormatter,
  createSalesReturnFromCashSales,
} from "../../../../utils/Formatter/Sales/CashSales";
import Confirmation from "../../../UI/Confirmation";
import { useStateContext } from "../../../../contexts/auth-context";
import SalesPersonHeader from "../SalesPerson";
import { useCashSalesPrint } from "../../../../hooks/Sales/CashSales/use-cash-sales-print";
// import PrintModal from "../../../UI/Modal/PrintModal";

type Props = {
  externalReferenceHandler: (data: IExternalProps) => void;
  cancelHandler: () => Promise<void>;
};

const CashSalesHeader = ({
  externalReferenceHandler,
  cancelHandler,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<any>();
  const [disabled] = useDisable();
  const navigate = useNavigate();
  const {
    state: { authUser },
  } = useStateContext();

  // const [printDocument, setPrintDocument] = useState<string>("tax_invoice");

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.CashSales,
      },
      {
        enabled: false,
      }
    );

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const external_reference_no = useWatch({
    control,
    name: "external_reference_no",
  });

  const is_need_tax = useWatch({
    control,
    name: "need_tax_invoice",
  });

  // const is_original_print = useWatch({
  //   control,
  //   name: "is_original_print",
  // });

  const printOptions = useCashSalesPrint(status, is_need_tax);

  const selectModifyOptions = useCashSalesOption(status);
  const { selectCreateOptions, disabledCreateOptions } =
    useCashSalesCreate(status);

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const copyDocumentHandler = () => {
    const cashSales = getValues();
    navigate("/sales/cash-sales/add", {
      state: copyCashSalesFormatter(cashSales, authUser),
    });
  };

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  // const {
  //   modal: printModal,
  //   openModalHandler: openPrintModalHandler,
  //   closeModalHandler: closePrintModalHandler,
  // } = useModal();

  // const printNavigateHandler = (printType: "original" | "copy") => {
  //   switch (printDocument) {
  //     case "tax_invoice":
  //       navigate(`/sales/cash-sales/${id}/tax-invoice-pdf`, {
  //         state: {
  //           printType,
  //           printDocument,
  //         },
  //       });
  //       break;
  //     case "receipt":
  //       navigate(`/sales/cash-sales/${id}/receipt-pdf`, {
  //         state: {
  //           printType,
  //           printDocument,
  //         },
  //       });
  //       break;
  //     case "tax_invoice_receipt":
  //       navigate(`/sales/cash-sales/${id}/tax-invoice-receipt-pdf`, {
  //         state: {
  //           printType,
  //           printDocument,
  //         },
  //       });
  //       break;
  //   }
  // };

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("sales.cash_sales.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `พิมพ์ใบกำกับภาษี`:
                      navigate(`/sales/cash-sales/${id}/tax-invoice-pdf`);
                      // setPrintDocument("tax_invoice");
                      break;
                    case `พิมพ์ใบเสร็จรับเงิน`:
                      navigate(`/sales/cash-sales/${id}/receipt-pdf`);
                      // setPrintDocument("receipt");
                      break;
                    case `พิมพ์ใบเสร็จรับเงิน/ใบกำกับภาษี`:
                      navigate(
                        `/sales/cash-sales/${id}/tax-invoice-receipt-pdf`
                      );
                      // setPrintDocument("tax_invoice_receipt");
                      break;
                  }
                }}
                disabled={
                  !id ||
                  status === "cancelled" ||
                  status === "not_approved" ||
                  status === "draft"
                }
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openCancelConfirmation();
                      break;
                    case t("sales.external_reference_no"):
                      openModalHandler();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  const cashSales = getValues();
                  switch (value.innerText) {
                    case `${t("logistic.delivery_order.index")}`:
                      // navigate("/sales/cash_sales/add", {
                      //   state: createSalescash_salesFromQuotation(quotation),
                      // });
                      break;
                    case t("sales.return.index"):
                      navigate("/sales/return/add", {
                        state: createSalesReturnFromCashSales(cashSales),
                      });
                      break;
                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight="bold">{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("sales.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id) && status && status !== "draft"}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1}>
              {(!Boolean(id) || (status && status === "draft")) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.reference_document_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box mx={disabled ? 1.5 : 0}>
                {reference_document_list && reference_document_list.length > 0
                  ? reference_document_list.map((reference: any) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          if (reference.document_type === "sales_order") {
                            window.open(
                              `/sales/order/${encodeURIComponent(
                                reference.document_id
                              )}`,
                              "_blank"
                            );
                          }
                        }}
                        value={reference.document_unique_id}
                        color="primary"
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight="bold">
                {t("sales.sentence.issuing_tax_invoices")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Box mx={disabled ? 1.5 : 0}>
                <ControlledSwitch
                  control={control}
                  name="need_tax_invoice"
                  label={t("sales.sentence.tax_invoices")}
                  disabled={disabled}
                />
              </Box>
            </Grid>
            <Grid item xs={1} />
            {is_need_tax && status === "finished" && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography fontWeight="bold">
                    {t("sales.sentence.tax_invoice_no")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={7}>
                  <ControlledTextField
                    name="tax_invoice_no"
                    control={control}
                    placeholder={t("sales.sentence.tax_invoice_no")}
                    error={Boolean(errors?.unique_id)}
                    helperText={
                      errors?.unique_id && errors?.unique_id.message?.toString()
                    }
                    disabled
                    viewMode={disabled}
                  />
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight="bold">
                {t("sales.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                placeholder={t("sales.reference_no")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight="bold">
                {t("sales.external_reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <Typography mx={1.5}>
                {external_reference_no ? external_reference_no : "-"}
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight="bold">
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={7}>
              <SalesPersonHeader />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ExternalReferenceModal
        open={modal}
        handleClose={closeModalHandler}
        action={externalReferenceHandler}
      />
      <Confirmation
        title="ยกเลิกขายสด"
        message="หากทำการยกเลิกเอกสารจะไม่สามารถย้อนกลับได้"
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      {/* <PrintModal
        open={printModal}
        closeModalHandler={closePrintModalHandler}
        // action={printNavigateHandler}
        isOriginalDisabled={
          is_original_print && is_original_print[printDocument]
        }
      />*/}
    </Box>
  );
};

export default CashSalesHeader;
