import { Dispatch, SetStateAction, useState } from "react";
import { useSnackbar } from "notistack";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

import {
  InventoryImportMode,
  useImportItemVariantPriceMutation,
  useImportItemVariantValueMutation,
  useImportVariantItemsMutation,
  useValidateItemImportQuery,
} from "../../../generated/inventory";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
  findNoneAble,
  findNonePrice,
  findNoneStatus,
  findNoneTracability,
  findNoneVatType,
  mapNestedData,
} from "../../../utils/Importer";
import {
  formatFloat,
  formatInt,
  formatPriceTwoDecimal,
  formatString,
  formatStringToArray,
} from "../../../utils/Global";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useStateContext } from "../../../contexts/auth-context";
import { IImporterError } from "../../../types/global";
import {
  formatTracability,
  formatVatType,
} from "../../../utils/Formatter/Item";

const requiredKeys = [
  { label: "รหัสสินค้า", value: "seller_unique_id" },
  { label: "ชื่อสินค้า", value: "name" },
  { label: "ซื้อได้", value: "is_purchasable" },
  { label: "ขายได้", value: "is_saleable" },
  { label: "สต๊อกได้", value: "is_stockable" },
  { label: "หน่วยสต๊อก", value: "stock_uom_unique_id" },
  { label: "กลุ่มหมวดหมู่", value: "category_group" },
];

const columns = [
  "seller_unique_id",
  "name",
  "sku_name",
  "img_url",
  "vendor_unique_id",
  "model",
  "description",
  "is_purchasable",
  "is_saleable",
  "is_stockable",
  "is_active",
  "inactive_date",
  "remark_status",
  "tag_list",
  "category_group",
  "main_category",
  "sub_category",
  "brand",
  "segment",
  "series",
  "package_width",
  "package_length",
  "package_height",
  "package_weight",
  "purchase_standard_price",
  "min_purchase_request_qty",
  "purchase_vat_type",
  "sale_price",
  "special_price",
  "sale_vat_type",
  "tracability",
  "stock_uom_unique_id",
  "sales_uom_unique_id",
  "purchase_uom_unique_id",
  "deliver_uom_unique_id",
  "insurance_name",
  "insurance_detail",
  "customer_insurance_duration",
  "seller_insurance_duration",
  "selling_point_list",
];

const requiredKeysSku = [
  { label: "รหัสสินค้า", value: "unique_id" },
  { label: "ตัวเลือก", value: "variant_key" },
  { label: "ชื่อตัวเลือก", value: "variant_value" },
];

const columnsSku = ["unique_id", "variant_key", "variant_value"];

const requiredKeysEachSku = [
  { label: "รหัสสินค้า", value: "unique_id" },
  { label: "SKU", value: "sku_name" },
  { label: "ราคาตั้งต้น", value: "sale_price" },
];

const columnsEachSku = ["unique_id", "sku_name", "sale_price"];

const count_limit = 50000;

interface OutputSkuData {
  item_unique_id: string | null;
  item_variant_key_list: Array<{
    variant_key_name: string | null;
    item_variant_value_list: Array<{
      variant_value_name: string | null;
    }>;
  }>;
}

const transformDataSku = (inputData: any[]): OutputSkuData[] => {
  const result: OutputSkuData[] = [];

  // Group data by unique_id
  const groupedByUniqueId = inputData.reduce((acc, item) => {
    const key = item.unique_id;
    acc[key] = acc[key] || [];
    acc[key].push(item);
    return acc;
  }, {});

  // Transform grouped data
  for (const uniqueId in groupedByUniqueId) {
    if (groupedByUniqueId.hasOwnProperty(uniqueId)) {
      const group = groupedByUniqueId[uniqueId];

      const itemVariantKeyList = group.reduce((acc: any, item: any) => {
        const variantKeyIndex = acc.findIndex(
          (v: any) => v.variant_key_name === item.variant_key
        );

        if (variantKeyIndex === -1) {
          // Variant key not found, add a new entry
          acc.push({
            variant_key_name: item.variant_key,
            item_variant_value_list: [
              { variant_value_name: item.variant_value },
            ],
          });
        } else {
          // Variant key found, add variant value to the existing entry
          acc[variantKeyIndex].item_variant_value_list.push({
            variant_value_name: item.variant_value,
          });
        }

        return acc;
      }, []);

      result.push({
        item_unique_id: uniqueId,
        item_variant_key_list: itemVariantKeyList,
      });
    }
  }
  return result;
};

interface OutputSkuValue {
  item_unique_id: string;
  sku_list: { sku_name: string; sale_price: number }[];
}

const transformDataSkuValue = (inputData: any[]): OutputSkuValue[] => {
  const transformedData: OutputSkuValue[] = [];

  // Group input data by 'unique_id'
  const groupedData = inputData.reduce((acc, item) => {
    const key = item.unique_id;
    (acc[key] = acc[key] || []).push(item);
    return acc;
  }, {});

  // Iterate through the grouped data to create transformedData
  for (const unique_id in groupedData) {
    const group = groupedData[unique_id];
    const skuList = group.map((item: any) => ({
      sku_name: item.sku_name,
      sale_price: formatFloat(item.sale_price),
    }));

    // Append the transformed data to the result
    transformedData.push({
      item_unique_id: unique_id,
      sku_list: skuList,
    });
  }

  return transformedData;
};

export const useVariantItemImporter = (
  type: InventoryImportMode,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    item_name_list: string[];
    item_unique_id_list: string[];
    uom_unique_id_list: string[];
    vendor_unique_id_list: string[];
    category_group_list: string[];
    main_category_list: string[];
    sub_category_list: string[];
    brand_list: string[];
    segment_list: string[];
    series_list: string[];
    model_list: string[];
  }>({
    import_mode: InventoryImportMode.Create,
    item_name_list: [],
    item_unique_id_list: [],
    uom_unique_id_list: [],
    vendor_unique_id_list: [],
    category_group_list: [],
    main_category_list: [],
    sub_category_list: [],
    brand_list: [],
    segment_list: [],
    series_list: [],
    model_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportVariantItemsMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!data[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: data[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.sort().forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const {
        uniqueIdList,
        // skuList,
        nameList,
        statusList,
        tracabilityList,
        barcodeList,
        purchasableList,
        saleableList,
        stockableList,
        purchaseVatList,
        saleVatList,
        purchaseStandardPriceList,
        salePriceList,
        specialPriceList,
      } = data.reduce(
        (lists: any, item: any) => {
          if (item.seller_unique_id) {
            lists.uniqueIdList.push(item.seller_unique_id);
          }
          // if (item.sku_name) {
          //   lists.skuList.push(item.sku_name);
          // }
          if (item.name) {
            lists.nameList.push(item.name);
          }
          if (item.is_active) {
            lists.statusList.push(item.is_active);
          }
          if (item.tracability) {
            lists.tracabilityList.push(item.tracability);
          }
          if (item.barcode) {
            lists.barcodeList.push(item.barcode);
          }
          if (item.is_purchasable) {
            lists.purchasableList.push(item.is_purchasable);
          }
          if (item.is_saleable) {
            lists.saleableList.push(item.is_saleable);
          }
          if (item.is_stockable) {
            lists.stockableList.push(item.is_stockable);
          }
          if (item.purchase_vat_type) {
            lists.purchaseVatList.push(item.purchase_vat_type);
          }
          if (item.sale_vat_type) {
            lists.saleVatList.push(item.sale_vat_type);
          }
          if (item.purchase_standard_price) {
            lists.purchaseStandardPriceList.push(item.purchase_standard_price);
          }
          if (item.sale_price) {
            lists.salePriceList.push(item.sale_price);
          }
          if (item.special_price) {
            lists.specialPriceList.push(item.special_price);
          }

          return lists;
        },
        {
          uniqueIdList: [] as string[],
          skuList: [] as string[],
          nameList: [] as string[],
          statusList: [] as string[],
          tracabilityList: [] as string[],
          barcodeList: [] as string[],
          purchasableList: [] as string[],
          saleableList: [] as string[],
          stockableList: [] as string[],
          purchaseVatList: [] as string[],
          saleVatList: [] as string[],
          purchaseStandardPriceList: [] as string[],
          salePriceList: [] as string[],
          specialPriceList: [] as string[],
        }
      );

      const formattedData = data.map((item: any, index: number) => {
        const delivery_attribute = {
          width: formatFloat(item.package_width),
          width_uom_unique_id: "เซนติเมตร",
          length: formatFloat(item.package_length),
          length_uom_unique_id: "เซนติเมตร",
          height: formatFloat(item.package_height),
          height_uom_unique_id: "เซนติเมตร",
          weight: formatFloat(item.package_weight),
          weight_uom_unique_id: "กิโลกรัม",
        };

        return {
          seller_unique_id: formatString(item.seller_unique_id),
          unique_id: formatString(item.seller_unique_id),
          name: formatString(item.name),
          // sku_name: formatString(item.sku_name),
          description: formatString(item.description),
          vendor_unique_id: formatString(item.vendor_unique_id),
          is_purchasable: item.is_purchasable === "ได้",
          is_saleable: item.is_saleable === "ได้",
          is_stockable: item.is_purchasable === "ได้",
          is_active: item.is_active === "ใช้งาน" ? 1 : 0,
          inactive_date:
            item.is_active === "ใช้งาน" ? null : item.inactive_date,
          remark_status: formatString(item.remark_status),
          img_url: formatStringToArray(item.image_list),
          category_group: formatString(item.category_group),
          main_category: formatString(item.main_category),
          sub_category: formatString(item.sub_category),
          segment: formatString(item.segment),
          series: formatString(item.series),
          brand: formatString(item.brand),
          model: formatString(item.model),
          delivery_attribute,
          purchase_standard_price: formatPriceTwoDecimal(
            item.purchase_standard_price
          ),
          min_purchase_request_qty: formatInt(item.min_purchase_request_qty),
          purchase_vat_type: formatVatType(item.purchase_vat_type),
          sale_price: formatPriceTwoDecimal(item.sale_price),
          special_price: formatPriceTwoDecimal(item.special_price),
          sale_vat_type: formatVatType(item.sale_vat_type),
          tracability: formatTracability(item.tracability),
          stock_uom_unique_id: formatString(item.stock_uom_unique_id),
          sales_uom_unique_id: formatString(item.sales_uom_unique_id),
          purchase_uom_unique_id: formatString(item.purchase_uom_unique_id),
          deliver_uom_unique_id: formatString(item.deliver_uom_unique_id),
          selling_point_list: item.selling_point_list
            ? [item.selling_point_list]
            : [],
          insurance_name: formatString(item.insurance_name),
          insurance_detail: formatString(item.insurance_detail),
          customer_insurance_duration: formatInt(
            item.customer_insurance_duration
          ),
          seller_insurance_duration: formatInt(item.seller_insurance_duration),
        };
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const duplicateName = findDuplicatesWithList(nameList);
      // const duplicateSku = findDuplicatesWithList(skuList);
      const duplicateBarcode = findDuplicatesWithList(barcodeList);
      const nonePurchasable = findNoneAble(purchasableList);
      const noneSaleable = findNoneAble(saleableList);
      const noneStockable = findNoneAble(stockableList);
      const nonePurchaseTax = findNoneVatType(purchaseVatList);
      const noneSaleVat = findNoneVatType(saleVatList);
      const noneStatus = findNoneStatus(statusList);
      const noneTracability = findNoneTracability(tracabilityList);
      const isNaNPurchaseStandardPrice = findNonePrice(
        purchaseStandardPriceList
      );
      const isNaNSalePrice = findNonePrice(salePriceList);
      const isNaNSpecialPrice = findNonePrice(specialPriceList);

      const allError = [
        duplicateUniqueId,
        duplicateName,
        // duplicateSku,
        duplicateBarcode,
        noneStatus,
        noneTracability,
        nonePurchasable,
        noneSaleable,
        noneStockable,
        nonePurchaseTax,
        noneSaleVat,
        isNaNPurchaseStandardPrice,
        isNaNSalePrice,
        isNaNSpecialPrice,
      ];

      addImporterErrorHandler(
        errorData,
        "รหัสสินค้าในไฟล์ซ้ำ",
        "รหัสสินค้า",
        duplicateUniqueId
      );
      // addImporterErrorHandler(
      //   errorData,
      //   "รหัสสินค้าในไฟล์ซ้ำ",
      //   "SKU",
      //   duplicateSku
      // );
      addImporterErrorHandler(
        errorData,
        "ชื่อสินค้าในไฟล์ซ้ำ",
        "ชื่อสินค้า",
        duplicateName
      );
      addImporterErrorHandler(
        errorData,
        "Barcode ในไฟล์ซ้ำ",
        "Barcode",
        duplicateBarcode
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "การติดตาม",
        noneTracability
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ซื้อได้",
        nonePurchasable
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ขายได้",
        noneSaleable
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สต๊อกได้",
        noneStockable
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทภาษีซื้อ",
        nonePurchaseTax
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทภาษีขาย",
        noneSaleVat
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ราคาซื้อต่อหน่วย",
        isNaNPurchaseStandardPrice
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ราคาขาย (บาท)",
        isNaNSalePrice
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ราคาขายพิเศษ",
        isNaNSpecialPrice
      );

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength || requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const existingItemUniqueIds =
        data?.ValidateItemImport.existing_item_unique_id_list || [];
      const existingSkuNames =
        data?.ValidateItemImport.existing_sku_name_list || [];
      const missingItemUniqueId =
        data?.ValidateItemImport.missing_item_unique_id_list || [];
      const missingUomUniqueId =
        data?.ValidateItemImport.missing_uom_unique_id_list || [];
      const missingVendorUniqueId =
        data?.ValidateItemImport.missing_vendor_unique_id_list || [];
      const missingCategoryGroup =
        data?.ValidateItemImport.missing_category_group_list || [];
      const missingMainCategory =
        data?.ValidateItemImport.missing_main_category_list || [];
      const missingSubCategory =
        data?.ValidateItemImport.missing_sub_category_list || [];
      const missingBrand = data?.ValidateItemImport.missing_brand_list || [];
      const missingSegment =
        data?.ValidateItemImport.missing_segment_list || [];
      const missingSeries = data?.ValidateItemImport.missing_series_list || [];
      const missingModel = data?.ValidateItemImport.missing_model_list || [];

      let arrays: any[] = [];
      let sumErrorLength: boolean = false;
      let errorData: IImporterError[] = [];
      const defaultArrays = [
        missingUomUniqueId,
        missingVendorUniqueId,
        missingCategoryGroup,
        missingMainCategory,
        missingSubCategory,
        missingBrand,
        missingSegment,
        missingSeries,
        missingModel,
      ];

      if (type === InventoryImportMode.Create) {
        arrays = [existingItemUniqueIds, existingSkuNames, ...defaultArrays];
        addImporterErrorHandler(
          errorData,
          "รหัสสินค้าซ้ำกับในระบบ",
          "รหัสสินค้า",
          existingItemUniqueIds
        );
        addImporterErrorHandler(
          errorData,
          "รหัสสินค้าซ้ำกับในระบบ",
          "SKU",
          existingSkuNames
        );
      } else {
        arrays = [missingItemUniqueId, ...defaultArrays];
        addImporterErrorHandler(
          errorData,
          "ไม่พบข้อมูลในระบบ",
          "รหัสสินค้า",
          missingItemUniqueId
        );
      }

      sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วย",
        missingUomUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "กลุ่มหมวดหมู่",
        missingCategoryGroup
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หมวดหมู่หลัก",
        missingMainCategory
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หมวดหมู่ย่อย",
        missingSubCategory
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "Brand",
        missingBrand
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "Segment",
        missingSegment
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "Series",
        missingSeries
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "Model",
        missingModel
      );
      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "ผู้ขาย",
        missingVendorUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await importItems({
            importInput: {
              data: rowData,
              import_mode: type,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (error) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData: any[] = await formatItemdata(tempDoc);

      const validateInput = {
        import_mode: type,
        item_name_list: formattedData
          .map((data) => data.name)
          .filter((name) => name !== null && name !== undefined),
        item_unique_id_list: formattedData
          .map((data) => data.unique_id)
          .filter((unique_id) => unique_id !== null && unique_id !== undefined),
        // sku_name_list: formattedData
        //   .map((data) => data.sku_name)
        //   .filter((skuName) => skuName !== null && skuName !== undefined),
        uom_unique_id_list: Array.from(
          new Set([
            ...formattedData.map((data) => data.stock_uom_unique_id),
            ...formattedData.map((data) => data.sales_uom_unique_id),
            ...formattedData.map((data) => data.purchase_uom_unique_id),
            ...formattedData.map((data) => data.deliver_uom_unique_id),
          ])
        ).filter((id) => id !== null && id),
        vendor_unique_id_list: formattedData
          .map((data) => data.vendor_unique_id)
          .filter(
            (vendor_unique_id) =>
              vendor_unique_id !== null && vendor_unique_id !== undefined
          ),
        category_group_list: formattedData
          .map((data) => data.category_group)
          .filter(
            (category_group) =>
              category_group !== null && category_group !== undefined
          ),
        main_category_list: formattedData
          .map((data) => data.main_category)
          .filter(
            (main_category) =>
              main_category !== null && main_category !== undefined
          ),
        sub_category_list: formattedData
          .map((data) => data.sub_category)
          .filter(
            (sub_category) =>
              sub_category !== null && sub_category !== undefined
          ),
        brand_list: formattedData
          .map((data) => data.brand)
          .filter((brand) => brand !== null && brand !== undefined),
        segment_list: formattedData
          .map((data) => data.segment)
          .filter((segment) => segment !== null && segment !== undefined),
        series_list: formattedData
          .map((data) => data.series)
          .filter((series) => series !== null && series !== undefined),
        model_list: formattedData
          .map((data) => data.model)
          .filter((model) => model !== null && model !== undefined),
      };

      setValidateInput(validateInput);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler: importHandler,
  };
};

export const useVariantSkuImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    item_unique_id_list: string[];
  }>({
    import_mode: InventoryImportMode.Update,
    item_unique_id_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportItemVariantValueMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columnsSku.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < requiredKeysSku.length; j++) {
          if (!data[i][requiredKeysSku[j].value]) {
            requiredFieldErrors.push({
              unique_id: data[i].unique_id,
              type: "required",
              field: requiredKeysSku[j].label,
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const outputData = transformDataSku(data);

      setErrorData(errorData);

      if (requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      setRowData(outputData);
      return outputData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const missingItemUniqueIds =
        data?.ValidateItemImport.missing_item_unique_id_list || [];
      const { variantKeyMoreThanThree, variantValueMoreThanTwenty } =
        rowData.reduce(
          (list, curr) => {
            const keysLength = curr.item_variant_key_list.length;
            const valuesLength = curr.item_variant_key_list.filter(
              (keyList: any) => keyList.item_variant_value_list.length > 20
            ).length;

            if (keysLength > 3) {
              list.variantKeyMoreThanThree.push(curr.item_unique_id);
            }
            if (valuesLength > 0) {
              list.variantValueMoreThanTwenty.push(curr.item_unique_id);
            }

            return list;
          },
          {
            variantKeyMoreThanThree: [] as string[],
            variantValueMoreThanTwenty: [] as string[],
          }
        );

      const arrays = [
        missingItemUniqueIds,
        variantKeyMoreThanThree,
        variantValueMoreThanTwenty,
      ];

      let errorData: IImporterError[] = [];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสสินค้า",
        missingItemUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "สินค้ามีตัวเลือกมากกว่า 3",
        "รหัสสินค้า",
        variantKeyMoreThanThree
      );
      addImporterErrorHandler(
        errorData,
        "สินค้ามีชื่อตัวเลือกมากกว่า 20",
        "รหัสสินค้า",
        variantValueMoreThanTwenty
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await importItems({
            importInput: {
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (error) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = (await formatItemdata(tempDoc)) as OutputSkuData[];

      const validateInput = {
        import_mode: InventoryImportMode.Update,
        item_unique_id_list: formattedData.map(
          (data) => data.item_unique_id ?? ""
        ),
      };

      setValidateInput(validateInput);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }

    //use validateInput to call api ValidateItemImport or ... for other modules
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler: importHandler,
  };
};

export const useVariantValueImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    item_unique_id_list: string[];
    sku_name_list: string[];
  }>({
    import_mode: InventoryImportMode.Update,
    item_unique_id_list: [],
    sku_name_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportItemVariantPriceMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columnsEachSku.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < requiredKeysEachSku.length; j++) {
          if (!data[i][requiredKeysEachSku[j].value]) {
            requiredFieldErrors.push({
              unique_id: data[i].unique_id,
              type: "required",
              field: requiredKeysEachSku[j].label,
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const { skuList, salePriceList } = data.reduce(
        (lists: any, item: any) => {
          if (item.sku_name) {
            lists.skuList.push(item.sku_name);
          }
          if (item.sale_price) {
            lists.salePriceList.push(item.sale_price);
          }

          return lists;
        },
        {
          skuList: [] as string[],
          salePriceList: [] as string[],
        }
      );

      const duplicateSku = findDuplicatesWithList(skuList);
      const noneSalePrice = findNonePrice(salePriceList);

      setErrorData(errorData);

      const allError = [duplicateSku, noneSalePrice];

      addImporterErrorHandler(
        errorData,
        "รหัสสินค้าในไฟล์ซ้ำ",
        "SKU",
        duplicateSku
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ราคาตั้งต้น",
        noneSalePrice
      );

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength || requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      const formattedData = transformDataSkuValue(data);
      setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const missingItemUniqueIds =
        data?.ValidateItemImport.missing_item_unique_id_list || [];
      const missingSku = data?.ValidateItemImport.missing_sku_name_list || [];

      const arrays = [missingSku, missingItemUniqueIds];
      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      let errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสสินค้า",
        missingItemUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "SKU",
        missingSku
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        //TODO: create many
        await importItems({
          importInput: {
            data: rowData,
            priority: 1,
            user_unique_id: authUser?.unique_id || "",
          },
        });
        enqueueSnackbar("นำเข้าสำเร็จ", {
          variant: "success",
        });
        setValue("step", 3);
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData: any[] = await formatItemdata(tempDoc);

      const validateInput = {
        import_mode: InventoryImportMode.Update,
        item_unique_id_list: formattedData
          .map((data) => data.item_unique_id)
          .filter((unique_id) => unique_id !== null && unique_id !== undefined),
        sku_name_list: Array.from(
          new Set([
            ...formattedData.reduce((acc, curr) => {
              if (curr.sku_list) {
                curr.sku_list.forEach((list: any) => acc.push(list.sku_name));
              }
              return acc;
            }, []),
          ])
        ).filter((sku_name) => sku_name !== null && sku_name !== undefined),
      };

      setValidateInput(validateInput);

      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }

    //use validateInput to call api ValidateItemImport or ... for other modules
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler,
  };
};
