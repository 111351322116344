import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const useSalesInvoicePdfOption = (
  is_need_tax: boolean,
  status?: string | null,
  id?: string | null
) => {
  const { t } = useTranslation();
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: `พิมพ์${t("sales.invoice.index")}`,
      },
      {
        value: `พิมพ์${t("sales.invoice.tax_invoice")}`,
      },
      {
        value: `พิมพ์${t("sales.invoice.delivery_tax_invoice")}`,
      },
      {
        value: `พิมพ์${t("sales.invoice.invoice_tax_invoice")}`,
      },
    ],
    [t]
  );

  const [printOptions, setPrintOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "wait_approve":
        setPrintOptions([defaultOptions[0]]);
        break;
      default:
        if (is_need_tax) {
          setPrintOptions(defaultOptions);
        } else {
          setPrintOptions([defaultOptions[0]]);
        }
        break;
    }
  }, [defaultOptions, is_need_tax, status]);

  return printOptions;
};
