import * as XLSX from "xlsx";
import dayjs from "dayjs";

export const exportAsExcel = (
  data: any,
  fileName: string,
  sheetName?: string,
  type?: string
) => {
  const workbook = XLSX.utils.book_new();
  let flattenedData = [...data];

  const workSheetWithThai = XLSX.utils.json_to_sheet(flattenedData);

  const timestamp = dayjs().format("YYYY-MM-DD_HHmmss");
  XLSX.utils.book_append_sheet(
    workbook,
    workSheetWithThai,
    sheetName || "Sheet1"
  );
  XLSX.writeFile(workbook, `${fileName}_${timestamp}.${type ?? "xlsx"}`);
};
