import PurchaseRequestTable from "../../../components/Table/Purchase/Request";

type Props = {
  referenceUniqueId?: string;
};

const PurchaseRequestTab = ({ referenceUniqueId }: Props) => {
  return <PurchaseRequestTable referenceUniqueId={referenceUniqueId} />;
};

export default PurchaseRequestTab;
