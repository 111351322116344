import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../types/global";
import { useForm, FormProvider } from "react-hook-form";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import BottomNavbar from "../../components/UI/Navbar/BottomNavbar";
import { CustomizedBox } from "../../components/Custom/CustomizedBox";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import UserImporterTable from "../../components/Table/User/ImporterTable";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import UserImportUploader from "../../components/Form/User/ImportUploader";
import CustomizedSteppers from "../../components/Custom/CustomizedStepper";
import { useUserImporter } from "../../hooks/Importer/User/use-user-importer";
import ImporterErrorModal from "../../components/UI/Modal/ImporterErrorModal";
import { useState } from "react";
import { useModal } from "../../hooks/use-modal";

const UserImporter = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
      to: "/user/account",
    },
    {
      name: `นำเข้า${t("user.account.index")}`,
    },
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const [errorData, setErrorData] = useState<any[]>([]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const methods = useForm<any>({
    defaultValues: {
      type: "create",
      file: {},
      step: 0,
      errors: [],
    },
  });

  const steps = [
    t("inventory.items.import.upload_file"),
    t("inventory.items.import.validate_data"),
    t("inventory.items.import.import_data"),
  ];

  const tabs: ITab[] = [
    {
      label: t("status.success"),
      path: `${pathname}`,
    },
    {
      label: t("status.failed"),
      path: `${pathname}?filter=failed`,
    },
  ];

  const { watch, setValue, getValues } = methods;
  const activeStep = watch("step");

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const { importHandler, isLoading, rowData, validateHandler } =
    useUserImporter(getValues, setValue, setErrorData, openModalHandler);

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <UserImportUploader />;
    } else {
      return (
        <>
          {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )}
          {isFilter ? (
            <UserImporterTable data={[]} />
          ) : (
            <UserImporterTable data={rowData} />
          )}
        </>
      );
    }
  };

  const renderButton = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateHandler}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title={t("button.back")}
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title={t("button.import")}
              variant="contained"
              onClick={importHandler}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูบัญชีผู้ใช้ทั้งหมด"
            variant="contained"
            onClick={() => navigate("/user/account")}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {`นำเข้า${t("user.account.index")}`}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box>
          {renderContent(activeStep)}
        </CustomizedBox>
      </FormProvider>
      <BottomNavbar>
        <Stack direction={"row"} gap={1}>
          {renderButton(activeStep)}
        </Stack>
      </BottomNavbar>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default UserImporter;
