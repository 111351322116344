import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";

import { Box, IconButton } from "@mui/material";
import CustomizedBreadcrumbs from "../Custom/CustomizedBreadcrumbs";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import { IBreadcrumbsAndMenu } from "../../types/global";

type Props = {
  breadcrumbs: IBreadcrumbsAndMenu[];
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
};

const BreadcrumbsLayout = ({ breadcrumbs, setOpenDrawer }: Props) => {
  const { id } = useParams();

  return (
    <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      {id && (
        <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
          <IconButton
            onClick={() => {
              setOpenDrawer(true);
            }}
            sx={{
              color: (theme) => theme.palette.primary.main,
              padding: 0,
              marginLeft: "4px",
            }}
          >
            <RestoreOutlinedIcon />
          </IconButton>
        </CustomizedTooltip>
      )}
    </Box>
  );
};

export default BreadcrumbsLayout;
