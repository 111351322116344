import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 200,
  },
  {
    thaiLabel: "ราคาต่อหน่วย",
    width: 150,
  },
  {
    thaiLabel: "กำหนดราคา",
    width: 150,
  },
];

export const usePriceItemList = (disabled?: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  useEffect(() => {
    if (disabled) {
      setHeaders(defaultHeaders);
    } else {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    }
  }, [disabled]);
  return headers;
};
