import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedSteppers from "../../../components/Custom/CustomizedStepper";
import UploadFilePriceList from "../../../components/Form/Inventory/Importer/UploadFilePriceList";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import PriceListImporterTable from "../../../components/Table/Inventory/PriceList/Importer";

import {
  usePriceListAllItemImporter,
  usePriceListByItemImporter,
} from "../../../hooks/Importer/Inventory/use-price-list-importer";
import { useModal } from "../../../hooks/use-modal";
import ImporterErrorModal from "../../../components/UI/Modal/ImporterErrorModal";

const PriceListImporter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const [errorData, setErrorData] = useState<any[]>([]);
  const { modal, openModalHandler, closeModalHandler } = useModal();

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.price_list.index"),
      to: "/inventory/price-list",
    },
    {
      name: t("inventory.price_list.import.index"),
    },
  ];

  const steps = [
    t("inventory.items.import.upload_file"),
    t("inventory.items.import.validate_data"),
    t("inventory.items.import.import_data"),
  ];

  const tabs: ITab[] = [
    {
      label: t("status.success"),
      path: `${pathname}`,
    },
    {
      label: t("status.failed"),
      path: `${pathname}?filter=failed`,
    },
  ];

  const methods = useForm<any>({
    defaultValues: {
      fileType: "all_item",
      type: "create",
      filePreview: null,
      file: {},
      step: 0,
      errors: [],
    },
  });

  const { getValues, watch, setValue } = methods;
  const activeStep = watch("step");
  const watchType = watch("type");
  const watchFileType = watch("fileType");

  const {
    rowData: allItemData,
    validateHandler: validateAllItem,
    importHandler: importAllItem,
    isLoading: isLoadingAllItem,
  } = usePriceListAllItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: byItemData,
    validateHandler: validateByItem,
    importHandler: importByItem,
    isLoading: isLoadingByItem,
  } = usePriceListByItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const backToUploadHandler = () => {
    setValue("step", 0);
    if (watchFileType === "by_item") {
      setValue("filePreview", null);
      setValue("file", {});
    }
  };

  const backToAllItemHandler = () => {
    navigate("/inventory/price-list");
  };

  const rowDataCondition = () => {
    if (watchFileType === "all_item") return allItemData;
    else return byItemData;
  };

  const validateCondition = () => {
    if (watchFileType === "all_item") return validateAllItem();
    else return validateByItem();
  };

  const importCondition = () => {
    if (watchFileType === "all_item") return importAllItem();
    else return importByItem();
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <UploadFilePriceList />;
    } else {
      return (
        <>
          {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )}
          <PriceListImporterTable data={rowDataCondition()} />
        </>
      );
    }
  };

  const renderButton = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateCondition}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importCondition}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูสินค้าทั้งหมด"
            variant="contained"
            onClick={backToAllItemHandler}
          />
        );
      default:
        return null;
    }
  };

  if (isLoadingAllItem || isLoadingByItem) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" my={2}>
        {t("inventory.price_list.import.index")}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box>
          {renderContent(activeStep)}
        </CustomizedBox>
        <BottomNavbar>
          <Stack direction={"row"} gap={1}>
            {renderButton(activeStep)}
          </Stack>
        </BottomNavbar>
      </FormProvider>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </Fragment>
  );
};

export default PriceListImporter;
