import { Box, Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlledSelect from "../../Controller/ControlledSelect";
import DropzoneUI from "../../UI/DropzoneUI";
import ControlledSwitch from "../../Controller/ControlledSwitch";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { genderFormatter } from "../../../utils/Formatter/Global";
import { ISelectOption } from "../../../types/global";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

type Props = {
  generateUniqueId: (button: boolean) => void;
};

const UserGeneralInfo = ({ generateUniqueId }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const { t } = useTranslation();

  const [disabled] = useDisable();

  const prefixes: ISelectOption[] = [
    {
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const genders: ISelectOption[] = [
    {
      label: t("user.account.gender.male"),
      value: "male",
    },
    {
      label: t("user.account.gender.female"),
      value: "female",
    },
    {
      label: t("user.account.gender.other"),
      value: "other",
    },
  ];

  const watchStatus = useWatch({
    control,
    name: "status",
  });

  return (
    <CustomizedBox margin={0}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            ข้อมูลพนักงาน
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                label={t("user.account.id")}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message?.toString()}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {!disabled && (
                <CustomizedTooltip
                  title={`เรียก${t("user.account.id")}`}
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={() => generateUniqueId(true)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {disabled ? (
                <LabelInput
                  label={t("user.account.title_name")}
                  value={getValues("title_name")}
                />
              ) : (
                <ControlledSelect
                  name="title_name"
                  control={control}
                  label={t("user.account.title_name")}
                  error={Boolean(errors.title_name)}
                  helperText={errors.title_name?.message?.toString()}
                  options={prefixes}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="nick_name"
                control={control}
                label={t("user.account.nick_name")}
                error={Boolean(errors.nick_name)}
                helperText={errors.nick_name?.message?.toString()}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="first_name"
                control={control}
                label={t("user.account.first_name")}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message?.toString()}
                required
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="last_name"
                control={control}
                label={t("user.account.last_name")}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message?.toString()}
                required
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {disabled ? (
                <LabelInput
                  label={t("user.account.gender.index")}
                  value={genderFormatter(getValues("gender"))}
                />
              ) : (
                <ControlledSelect
                  name="gender"
                  control={control}
                  label={t("user.account.gender.index")}
                  error={Boolean(errors.gender)}
                  helperText={errors.gender?.message?.toString()}
                  options={genders}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="phone"
                control={control}
                label={t("user.account.phone")}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message?.toString()}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={disabled}
          />
          <Box my={1.5}>
            <ControlledSwitch
              control={control}
              name="status"
              label="ใช้งาน"
              disabled={disabled}
            />
          </Box>
          <ControlledDatePicker
            name="start_date"
            control={control}
            label={t("date.start_date")}
            viewMode={disabled}
          />
          {!watchStatus && (
            <>
              <Box mt={1.5}>
                <ControlledDatePicker
                  name="inactive_date"
                  control={control}
                  label={t("date.inactive_date")}
                  viewMode={disabled}
                />
              </Box>
              <Box mt={1.5}>
                <ControlledTextField
                  name="remark_status"
                  control={control}
                  label={t("sentence.remark_status")}
                  viewMode={disabled}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default UserGeneralInfo;
