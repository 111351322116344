import dayjs from "dayjs";
import { IUser } from "../../../types/Auth/user";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/Global";

export const userSchema: IUser = {
  title_name: "",
  nick_name: "",
  first_name: "",
  last_name: "",
  gender: "",
  start_date: dayjs(),
  inactive_date: null,
  position: "",
  department: "",
  employee_type: "",
  company_name: "",
  created_date: null,
  email: "",
  img_url: [],
  is_sales_employee: false,
  last_updated_date: null,
  original_branch: undefined,
  phone: "",
  status: 1,
  unique_id: "",
  password: "",
  confirm_password: "",
  branch_permission: [],
};

export const userValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  first_name: Yup.string().required("กรุณาระบุ"),
  last_name: Yup.string().required("กรุณาระบุ"),
  email: Yup.string().email("กรุณาระบุให้ถูกค้อง").required("กรุณาระบุ"),
  phone: Yup.string()
    .matches(phoneRegExp, "กรุณาระบุให้ถูกต้อง")
    .required("กรุณาระบุ"),
  password: Yup.string()
    .min(8, "กรุณาระบุมากกว่า 8 ตัว")
    .matches(/[a-z]+/, "ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว")
    .matches(/[A-Z]+/, "ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว")
    .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), undefined],
    "รหัสผ่านไม่ตรงกัน"
  ),
  original_branch: Yup.string().required("กรุณาระบุ"),
});
