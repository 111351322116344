import { useWatch, useFormContext } from "react-hook-form";
import { Typography } from "@mui/material";
import { IGoodsAdjustment } from "../../../../types/Inventory/GoodsAdjustment";

interface Props {
  nestedIndex: number;
  keyTrace: "qty" | "stock_qty" | "all";
}

const GoodsAdjustmentTotalPostedQuantityCell = ({
  nestedIndex,
  keyTrace,
}: Props) => {
  const { control } = useFormContext<IGoodsAdjustment>();
  const watchSerialList =
    useWatch({
      control,
      name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
    }) || [];

  const totalPostedQuantity = watchSerialList.reduce((prev, curr) => {
    const qty =
      keyTrace === "all"
        ? Number(curr["qty"]) + (curr["stock_qty"] ?? 0)
        : curr[keyTrace] ?? 0;

    if (qty) {
      if (typeof qty === "string") {
        return prev + parseInt(qty);
      } else {
        return prev + qty;
      }
    } else {
      return prev + 0;
    }
  }, 0);

  return <Typography fontSize={14}>{totalPostedQuantity}</Typography>;
};

export default GoodsAdjustmentTotalPostedQuantityCell;
