import dayjs from "dayjs";
import { Tracability } from "../../../generated/inventory";
import { ITraceEntry } from "../../../types/Inventory";
import { IGoodsReceive } from "../../../types/Inventory/GoodsReceive";

export const goodsReceiveCreateFormatter = (
  data: IGoodsReceive,
  status?: string
) => {
  const { created_date, branch_id, trace_entry_list, ...otherData } = data;

  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({ uom, ...serial }) => ({
            ...serial,
            reference_unique_id: data.unique_id,
            reference_line_item: trace.reference_line_item,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            uom_id: uom?.id,
            barcode: `${serial.item_unique_id}#${serial.serial_no}#${serial.qty}`,
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const { serial_list, uom, ...otherTrace } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_id: uom?.id,
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherData,
    branch_id: branch_id as number,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
  };
};

const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const goodsReceiveQueryFormatter = (data: IGoodsReceive) => {
  const { trace_entry_list, ...otherData } = data;
  const groupedTrace = groupBy(trace_entry_list, "sku_name");

  const allTraceEntries: ITraceEntry[] = [];

  for (const entry of Object.entries(groupedTrace)) {
    const key = entry[0] as string;
    const value = entry[1] as ITraceEntry[];

    let formatSerial = [];

    formatSerial = value.map((serial) => ({
      ...serial,
      serial_no: serial.serial_no,
      posted_date: serial.posted_date ?? undefined,
      scanned_by: serial.scanned_by ?? undefined,
      lot_date: serial.lot_date ? dayjs(serial.lot_date) : null,
      expired_date: serial.expired_date ? dayjs(serial.expired_date) : null,
      manufactured_date: serial.manufactured_date
        ? dayjs(serial.manufactured_date)
        : null,
    }));

    const formatTrace = {
      item_unique_id: key,
      sku_name: value[0].sku_name,
      po_reference_unique_id: value[0].po_reference_unique_id,
      is_stockable: value[0].is_stockable,
      item_name: value[0].item_name,
      item_img_url: value[0].item_img_url,
      tracability: value[0].tracability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      cost_price: value[0].cost_price,
      document_item_qty: value[0].document_item_qty,
      posted_date:
        value[0].tracability === Tracability.Normal
          ? value[0].posted_date
          : undefined,
      created_date: value[0].created_date,
      uom: value[0].uom,
      source_bin_location:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].tracability === Tracability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.tracability === Tracability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].tracability === Tracability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].tracability === Tracability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      remark: value[0].remark ?? undefined,
      reference_unique_id: value[0].reference_unique_id,
      reference_line_item: value[0].reference_line_item,
      lot_date: value[0].lot_date ? dayjs(value[0].lot_date) : null,
      expired_date: value[0].expired_date ? dayjs(value[0].expired_date) : null,
      manufactured_date: value[0].manufactured_date
        ? dayjs(value[0].manufactured_date)
        : null,
    } as ITraceEntry;
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
    created_date: dayjs(otherData.created_date),
    posted_date: dayjs(otherData.posted_date),
  };
};

export const goodsReceiveUpdateFormatter = (
  data: IGoodsReceive,
  status: string
) => {
  const {
    id,
    branch_id,
    branch,
    warehouse,
    trace_entry_list,
    created_date,
    created_by,
    unique_id,
    aggrid_status,
    ...otherData
  } = data;
  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_id: uom?.id,
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          created_date,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          uom_id: uom?.id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherData,
    branch_id: branch_id as number,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
  };
};

export const goodsReceiveTypeValueFormatter = (type: string) => {
  switch (type) {
    case "purchase_order":
      return "ซื้อ";
    case "other":
      return "อื่นๆ";
    default:
      return "";
  }
};

export const goodsReceiveReportTypeValueFormatter = (type: string) => {
  switch (type) {
    case "purchase_order":
      return "ซื้อ";
    case "other":
      return "นำเข้า";
    default:
      return "";
  }
};
