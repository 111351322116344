import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { USERS_AGGRID } from "../../../services/AgGrid/UserAgGrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import useUserColumnDefs from "../../Table/User/useUserColumnDefs";

type CheckedUserModalProps = {
  showSelectUser: boolean;
  closeUserTable: () => void;
  finishUsersSelect: (data: any) => void;
  userIds: string[];
  setUserIds: Dispatch<SetStateAction<string[]>>;
  userIdsSnapshot: string[];
  setUserIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  lockRows?: (string | undefined)[];
  rowSelection: "single" | "multiple";
  isSalesPerson?: boolean;
  selectedUserId?: string;
};

const CheckedUserModal = ({
  showSelectUser,
  closeUserTable,
  finishUsersSelect,
  userIds,
  setUserIds,
  userIdsSnapshot,
  setUserIdsSnapshot,
  rowSelection,
  lockRows,
  isSalesPerson,
  selectedUserId,
}: CheckedUserModalProps) => {
  const { t } = useTranslation();
  const selectUserGridRef = useRef();

  const columnDefs = useUserColumnDefs();

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, is_sales_employee, unique_id, ...otherFilter } =
        filterModel;
      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        is_sales_employee: isSalesPerson && {
          filterType: "setBoolean",
          values: [true],
        },
        unique_id: selectedUserId
          ? {
              filterType: "text",
              type: "equals",
              filter: selectedUserId,
            }
          : unique_id,
      };
      try {
        const { UsersView } = await graphQLClientGeneral.request(USERS_AGGRID, {
          aggridInput: {
            startRow,
            endRow,
            filterModel: formatFilter,
            sortModel,
          },
        });
        params.success({
          rowData: UsersView.results as any[],
          rowCount: UsersView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instanceStatus = params.api.getFilterInstance("status");
    instanceStatus?.setModel({ values: ["1"], filterType: "set" });
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={"พนักงาน"}
      btnTitle={t("button.add")}
      gridRef={selectUserGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishUsersSelect}
      modalIsOpen={showSelectUser}
      getRowId={getRowId}
      closeModal={closeUserTable}
      selectedIds={userIds}
      setSelectedIds={setUserIds}
      idsSnapshot={userIdsSnapshot}
      setIdsSnapshot={setUserIdsSnapshot}
      onGridReady={onGridReady}
      lockRows={lockRows}
    />
  );
};

export default CheckedUserModal;
