import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ITraceEntry } from "../../../../types/Inventory";
import { useConsignmentTraceEntryHeader } from "./columnDef";
import { Fragment } from "react";

type Props = {
  traceEntryList: ITraceEntry[];
  startNumber?: number;
  isLastPage?: boolean;
  hasItems?: boolean;
};

const ConsignmentTable = ({
  traceEntryList,
  hasItems,
  isLastPage,
  startNumber,
}: Props) => {
  const tablePadding = {
    px: 1,
    py: 1,
  };
  const fontSize = 10.66;

  const headers = useConsignmentTraceEntryHeader();

  return (
    <TableContainer
      sx={{
        my: 1,
      }}
    >
      <Table aria-label="simple table">
        {(!isLastPage || hasItems) && (
          <TableHead
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  width={header.width}
                  align={header.align}
                  sx={tablePadding}
                >
                  <Typography fontSize={fontSize} fontWeight={600}>
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {traceEntryList.map((trace, index) => (
            <Fragment key={trace.unique_id}>
              <TableRow>
                <TableCell width={headers[0]?.width} align={headers[0]?.align}>
                  <Typography fontSize={fontSize}>
                    {startNumber
                      ? trace.sku_name
                        ? startNumber++
                        : undefined
                      : index + 1}
                  </Typography>
                </TableCell>
                <TableCell width={headers[1]?.width} align={headers[1]?.align}>
                  <Typography
                    fontSize={fontSize}
                    color={"primary.main"}
                    fontWeight={600}
                  >
                    {trace.sku_name}
                  </Typography>
                  <Typography
                    fontSize={fontSize}
                    color={"primary.main"}
                    fontWeight={600}
                  >
                    {trace.item_name}
                  </Typography>
                </TableCell>
                <TableCell width={headers[2]?.width} align={headers[2]?.align}>
                  <Typography fontSize={fontSize}>{trace.qty}</Typography>
                </TableCell>
                <TableCell width={headers[3]?.width} align={headers[3]?.align}>
                  <Typography fontSize={fontSize}>
                    {trace.uom?.name_th}
                  </Typography>
                </TableCell>
                <TableCell width={headers[4]?.width} align={headers[4]?.align}>
                  <Typography fontSize={fontSize}>
                    {/* {trace.tracability === Tracability.Normal && ( */}
                    <Typography fontSize={fontSize}>
                      {trace.serial_no}
                    </Typography>
                    {/* )} */}
                  </Typography>
                </TableCell>
                <TableCell width={headers[5]?.width} align={headers[5]?.align}>
                  {/* {trace.tracability === Tracability.Normal && ( */}
                  <Typography fontSize={fontSize}>{trace.barcode}</Typography>
                  {/* )} */}
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConsignmentTable;
