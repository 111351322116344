import { CustomizedTooltip } from "../Custom/CustomizedTooltip";
import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import { IApprovalTemplate } from "../../types/global";
import CustomizedAvatar from "../Custom/CustomizedAvatar";
import { useFormContext, useWatch } from "react-hook-form";
import ReportIcon from "@mui/icons-material/Report";
import {
  BranchUserGetAllQuery,
  useBranchUserGetAllQuery,
} from "../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";

type Props = {
  approval: IApprovalTemplate;
  approvalStep?: number | null;
  index: number;
};

const ApproverBox = ({ approval, approvalStep, index }: Props) => {
  const { control } = useFormContext();
  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const { data } = useBranchUserGetAllQuery<BranchUserGetAllQuery>(
    graphQLClient,
    {
      where: {
        user_id_in_list: approval.approver_list.map((reviewer) => reviewer.id),
      },
    }
  );

  const currentBranch = sessionStorage.getItem("branch-id");
  const isHq = sessionStorage.getItem("is_hq") === "true";

  const accessReviwerId = data?.BranchUserGetAll.filter(
    (branchUser) => branchUser.branch_id === parseInt(currentBranch || "")
  ).map((bu) => bu.user_id);

  const isHighlight = approvalStep ? approvalStep >= index + 1 : false;

  const isNotApproved =
    status === "not_approved"
      ? approvalStep && approvalStep === index + 1
        ? true
        : false
      : false;

  const isApproved =
    status === "wait_approve"
      ? approvalStep && approvalStep > index + 1
        ? true
        : false
      : isNotApproved ||
        (approvalStep && approvalStep === index + 1 && status === "cancelled")
      ? false
      : true;

  const renderColor = () => {
    if (isHighlight) {
      if (isApproved) {
        return {
          borderColor: "rgba(50, 143, 53, 0.6)",
          bgColor: "rgba(199, 230, 199, 0.2)",
          color: "#246527",
          label: "อนุมัติแล้ว",
        };
      } else {
        if (isNotApproved) {
          return {
            borderColor: "rgba(181, 72, 57, 0.6)",
            bgColor: "rgba(254, 236, 235, 0.4)",
            color: "#B54839",
            label: "ไม่อนุมัติ",
          };
        } else {
          return {
            borderColor: "rgba(195, 118, 46, 0.6)",
            bgColor: "rgba(255, 241, 197, 0.4)",
            color: "#C3762E",
            label: "รออนุมัติ",
          };
        }
      }
    } else {
      return {
        borderColor: "#91B0FC",
        bgColor: "white",
        color: "#C3762E",
        label: "รออนุมัติ",
      };
    }
  };

  const allReviwerList = isHq
    ? approval.approver_list
    : approval.approver_list.filter((reviewer) =>
        accessReviwerId?.includes(reviewer.id)
      );

  const maxLength = 2;

  return (
    <CustomizedTooltip
      backgroundColor="#fff"
      title={
        <Box sx={{ px: 1, minWidth: 250 }}>
          <CustomizedScrollbar sx={{ maxHeight: 300 }}>
            {allReviwerList.length > 0 ? (
              allReviwerList.map((reviewer, index) => (
                <Box key={`${reviewer.id} + ${index}`}>
                  <Typography color="primary.main">{`${reviewer.first_name} ${reviewer.last_name}`}</Typography>
                  <Typography color="primary.main">{reviewer.email}</Typography>
                  {index !== allReviwerList.length - 1 && (
                    <Divider sx={{ my: 0.5 }} />
                  )}
                </Box>
              ))
            ) : (
              <Typography>
                กรุณาไปที่ตั้งค่าการอนุมัติเพื่อเพิ่มพนักงานในสาขาที่มีสิทธิ์อนุมัติในลำดับที่{" "}
                {index + 1}
              </Typography>
            )}
            {isHighlight && (
              <Typography
                sx={{
                  color: renderColor().color,
                  fontWeight: "bold",
                  mt: 1,
                }}
              >
                {renderColor().label}
              </Typography>
            )}
          </CustomizedScrollbar>
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          cursor: "pointer",
          flexWrap: "wrap",
          p: 1,
          border: 0.5,
          borderRadius: 14,
          borderColor: renderColor().borderColor,
          bgcolor: renderColor().bgColor,
        }}
      >
        {allReviwerList.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              gap: 0.1,
              alignItems: "center",
            }}
          >
            {allReviwerList.map((rw, index) => (
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                }}
                key={`${rw.id} + ${index}`}
              >
                {index + 1 > maxLength ? null : (
                  <>
                    <CustomizedAvatar
                      avatars={[rw]}
                      sx={{ width: 24, height: 24, fontSize: "14px" }}
                      noTooltip
                    />
                    <Typography color="primary.main" noWrap>
                      {rw.first_name}
                    </Typography>
                  </>
                )}
              </Box>
            ))}
            {allReviwerList.length - maxLength > 0 && (
              <Avatar sx={{ width: 28, height: 28, fontSize: 16 }}>
                +{allReviwerList.length - maxLength}
              </Avatar>
            )}
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
            key={`${index}`}
          >
            <IconButton sx={{ width: 28, height: 28 }}>
              <ReportIcon color="primary" />
            </IconButton>
            <Typography color="primary.main">ไม่มีผู้อนุมัติ</Typography>
          </Box>
        )}
      </Box>
    </CustomizedTooltip>
  );
};

export default ApproverBox;
