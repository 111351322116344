import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import ContactImportUploader from "../../../components/Form/Contact/ImportUploader";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedSteppers from "../../../components/Custom/CustomizedStepper";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ContactImporterTable from "../../../components/Table/Contact/ImporterTable";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useNormalContactImporter from "../../../hooks/Importer/Contact/use-normal-contact-importer";
import { ContactImportType } from "../../../generated/contact";
import useContactChannelImporter from "../../../hooks/Importer/Contact/use-contact-channel-importer";
import { MouseEventHandler, useState } from "react";
import { useContactPersonImporter } from "../../../hooks/Importer/Contact/use-contact-person-importer";
import { useContactAddressImporter } from "../../../hooks/Importer/Contact/use-contact-address-importer";
import { useContactAttributeImporter } from "../../../hooks/Importer/Contact/use-contact-attibute-importer";
import ImporterErrorModal from "../../../components/UI/Modal/ImporterErrorModal";
import { useModal } from "../../../hooks/use-modal";

const CustomerImporter = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.customer"),
      to: "/contact/customer",
    },
    {
      name: `นำเข้า${t("contact.contact_type.customer")}`,
    },
  ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const [errorData, setErrorData] = useState<any[]>([]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const methods = useForm<any>({
    defaultValues: {
      import_type: "customer",
      type: "create",
      file: {},
      step: 0,
      errors: [],
    },
  });

  const steps = [
    t("inventory.items.import.upload_file"),
    t("inventory.items.import.validate_data"),
    t("inventory.items.import.import_data"),
  ];

  const tabs: ITab[] = [
    {
      label: t("status.success"),
      path: `${pathname}`,
    },
    {
      label: t("status.failed"),
      path: `${pathname}?filter=failed`,
    },
  ];

  const { watch, getValues, setValue } = methods;
  const activeStep = watch("step");
  const importType = watch("import_type");

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const { importHandler, isLoading, rowData, validateHandler } =
    useNormalContactImporter(
      ContactImportType.Customer,
      getValues,
      setValue,
      setErrorData,
      openModalHandler
    );

  const {
    importHandler: importContactChannelHandler,
    isLoading: isContactChannelLoading,
    rowData: contactChannelData,
    validateHandler: validateContactChannel,
  } = useContactChannelImporter(
    ContactImportType.Customer,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    importHandler: importContactPersonHandler,
    isLoading: isContactPersonLoading,
    rowData: contactPersonData,
    validateHandler: validateContactPerson,
  } = useContactPersonImporter(
    ContactImportType.Customer,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    importHandler: importContactAddressHandler,
    isLoading: isContactAddressLoading,
    rowData: contactAddressData,
    validateHandler: validateAddress,
  } = useContactAddressImporter(
    ContactImportType.Customer,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    importHandler: importContactAttributeHandler,
    isLoading: isContactAttributeLoading,
    rowData: contactAttributeData,
    validateHandler: validateAttribute,
  } = useContactAttributeImporter(
    ContactImportType.Customer,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const renderTable = (importType: string) => {
    switch (importType) {
      case "customer":
        if (isFilter) return <ContactImporterTable type="customer" data={[]} />;
        return <ContactImporterTable type="customer" data={rowData} />;
      case "customer_contact_channel":
        if (isFilter) return <ContactImporterTable type="customer" data={[]} />;
        return (
          <ContactImporterTable type="customer" data={contactChannelData} />
        );
      case "customer_contact_person":
        if (isFilter) return <ContactImporterTable type="customer" data={[]} />;
        return (
          <ContactImporterTable type="customer" data={contactPersonData} />
        );
      case "customer_address":
        if (isFilter) return <ContactImporterTable type="customer" data={[]} />;
        return (
          <ContactImporterTable type="customer" data={contactAddressData} />
        );
      case "customer_attribute":
        if (isFilter) return <ContactImporterTable type="customer" data={[]} />;
        return (
          <ContactImporterTable type="customer" data={contactAttributeData} />
        );
    }
  };

  const renderContent = (step: number, importType: string) => {
    if (step === 0) {
      return <ContactImportUploader contactType="customer" />;
    } else {
      return (
        <>
          {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )}
          {renderTable(importType)}
        </>
      );
    }
  };

  const renderButton = (step: number, importType: string) => {
    const getHandler = (
      handlerType: "validate" | "import"
    ): MouseEventHandler<HTMLButtonElement> | undefined => {
      switch (importType) {
        case "customer":
          return handlerType === "validate" ? validateHandler : importHandler;
        case "customer_contact_channel":
          return handlerType === "validate"
            ? validateContactChannel
            : importContactChannelHandler;
        case "customer_contact_person":
          return handlerType === "validate"
            ? validateContactPerson
            : importContactPersonHandler;
        case "customer_address":
          return handlerType === "validate"
            ? validateAddress
            : importContactAddressHandler;
        case "customer_attribute":
          return handlerType === "validate"
            ? validateAttribute
            : importContactAttributeHandler;
        default:
          return undefined;
      }
    };

    const validateContactHandler = getHandler("validate");
    const importContactHandler = getHandler("import");

    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateContactHandler}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importContactHandler}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูลูกค้าทั้งหมด"
            variant="contained"
            onClick={() => navigate("/contact/customer")}
          />
        );
      default:
        return null;
    }
  };

  if (
    isLoading ||
    isContactChannelLoading ||
    isContactPersonLoading ||
    isContactAddressLoading ||
    isContactAttributeLoading
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {`นำเข้า${t("contact.contact_type.customer")}`}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box>
          {renderContent(activeStep, importType)}
        </CustomizedBox>
      </FormProvider>
      <BottomNavbar>
        <Stack direction={"row"} gap={1}>
          {renderButton(activeStep, importType)}
        </Stack>
      </BottomNavbar>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default CustomerImporter;
