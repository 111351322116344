import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Typography, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import { useUserOption } from "../../../hooks/use-user-option";
import ControlledSSCreatable from "../../Controller/ControlledSSCreatable";
import { IUser } from "../../../types/Auth/user";

const UserDutyInfo = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<IUser>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { departmentsOption, positionsOption, branchesOptions } =
    useUserOption();

  return (
    <CustomizedBox>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        จัดการหน้าที่
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("user.account.original_branch")}
              value={getValues("original_branch_object.name")}
            />
          ) : (
            <ControlledSelect
              name="original_branch"
              control={control}
              label={t("user.account.original_branch")}
              error={Boolean(errors.original_branch)}
              helperText={errors.original_branch?.message?.toString()}
              options={branchesOptions}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("user.account.department")}
              value={getValues("department")}
            />
          ) : (
            <ControlledSelect
              name="department"
              control={control}
              label={t("user.account.department")}
              error={Boolean(errors.department)}
              helperText={errors.department?.message?.toString()}
              options={departmentsOption}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("user.account.position")}
              value={getValues("position")}
            />
          ) : (
            <ControlledSelect
              name="position"
              control={control}
              label={t("user.account.position")}
              error={Boolean(errors.position)}
              helperText={errors.position?.message?.toString()}
              options={positionsOption}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSSCreatable
            label={t("user.account.employee_type")}
            control={control}
            defaultOptions={[]}
            documentType="employee"
            name="employee_type"
            setValue={setValue}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledCheckbox
            name="is_sales_employee"
            control={control}
            label="พนักงานขาย"
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default UserDutyInfo;
