import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import PurchaseOrderTable from "../../../components/Table/Purchase/Order";
import LoadingUI from "../../../components/UI/LoadingUI";

type Props = {
  isLoading?: boolean;
  referenceUniqueId?: string;
};

const PurchaseOrderTab = ({ referenceUniqueId, isLoading }: Props) => {
  const { t } = useTranslation();
  if (isLoading) return <LoadingUI />;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("purchase.order.index")}</Typography>
      </Box>
      <PurchaseOrderTable referenceUniqueId={referenceUniqueId} />;
    </>
  );
};

export default PurchaseOrderTab;
