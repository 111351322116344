import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CheckedVendorModal from "../../../UI/Modal/CheckedVendorModal";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { useDisable } from "../../../../hooks/use-disable";
import { useContactDescription } from "../../../../hooks/use-contact-description";
import { IPurchaseOrder } from "../../../../types/Purchase/purchaseOrder";
import SubHeader from "../SubHeader";

type Props = {
  documentType?: string;
};

const VendorInfo = ({ documentType = "purchase_order" }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext<IPurchaseOrder>();

  const {
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    finishContactsSelect,
    openContactTableHandler,
    openContactDetail,
  } = useContactDescription("purchase", documentType);

  return (
    <Fragment>
      <CustomizedBox>
        <Box display="flex" gap={1} alignItems="center" mb={2}>
          <SubHeader title={t("purchase.vendor_description")} />
          {!disabled ? (
            <CustomizedTooltip title={t("sentence.choose_vendor")}>
              <IconButton onClick={openContactTableHandler} color="primary">
                <EditIcon />
              </IconButton>
            </CustomizedTooltip>
          ) : (
            <CustomizedTooltip title="กดเพื่อดูรายละเอียดผู้ขาย">
              <IconButton onClick={openContactDetail} color="primary">
                <LaunchOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="vendor_details.unique_id_name"
              label={
                t("purchase.vendor_unique_id") +
                " - " +
                t("purchase.vendor_name")
              }
              disabled
              viewMode={disabled}
              required
              error={Boolean(errors.vendor_details?.name)}
              helperText={errors?.vendor_details?.name?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="vendor_details.identity_no"
              label={t("sales.identity_no")}
              error={Boolean(errors.vendor_details?.identity_no)}
              helperText={errors?.vendor_details?.identity_no?.message?.toString()}
              viewMode={disabled}
              disabled
              required
            />
          </Grid>
          <Box width={"100%"} />
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="vendor_details.phone"
              label={t("global.phone_number")}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="vendor_details.email"
              label={t("global.email")}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="vendor_details.fax"
              label={"โทรสาร"}
              viewMode={disabled}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedVendorModal
        showSelectVendor={openContactTable}
        closeVendorTable={closeContactTableHandler}
        vendorIds={contactIds}
        setVendorIds={setContactIds}
        vendorIdsSnapshot={contactIdsSnapshot}
        setVendorIdsSnapshot={setContactIdsSnapshot}
        finishVendorsSelect={finishContactsSelect}
        rowSelection="single"
      />
    </Fragment>
  );
};

export default VendorInfo;
