import {
  IApprovalTemplate,
  IReviewedBy,
  ISelectOption,
} from "./../../../types/global";
import { ItemSkuQtysQuery, Tracability } from "./../../../generated/inventory";
import dayjs from "dayjs";
import { InventoryDocumentType } from "../../../generated/inventory";
import { IUser } from "../../../types/Auth/user";
import { v4 as uuidv4 } from "uuid";
import { ITraceEntry } from "../../../types/Inventory";
import { IGoodsTransfer } from "../../../types/Inventory/GoodsTransfer";

const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const goodsTransferQueryFormatter = (
  data: IGoodsTransfer,
  allSkuQtys?: ItemSkuQtysQuery
) => {
  const { trace_entry_list, created_date, posted_date, ...otherData } = data;
  const groupedTrace = groupBy(trace_entry_list, "item_unique_id");
  const allTraceEntries: ITraceEntry[] = [];

  for (const entry of Object.entries(groupedTrace)) {
    const key = entry[0] as string;
    const value = entry[1] as ITraceEntry[];

    const allSkuQtyInWarehouse = allSkuQtys?.itemSkuQtys
      ? allSkuQtys?.itemSkuQtys
          .filter(
            (sku) =>
              sku?.warehouse_id === Number(otherData?.source_warehouse_id)
          )
          .map((sku) => ({
            id: sku?.bin_id,
            barcode: sku?.barcode,
            name: sku?.bin_location?.name,
            stock_qty: sku?.stock_qty,
            warehouse_id: sku?.warehouse_id,
          }))
      : undefined;

    const filterBinLocations =
      allSkuQtyInWarehouse && value[0].tracability === Tracability.Normal
        ? allSkuQtyInWarehouse.filter(
            (sku) => sku?.barcode === value[0].barcode
          )
        : undefined;

    const formatSerial = value.map((serial) => {
      const stock_qty = allSkuQtys?.itemSkuQtys
        ? allSkuQtys.itemSkuQtys?.find(
            (sku) =>
              sku?.barcode === serial.barcode &&
              sku?.bin_id === serial.source_bin_location_id
          )?.stock_qty
        : serial.stock_qty;

      const filterBinLocationsSerial = allSkuQtyInWarehouse
        ? allSkuQtyInWarehouse.filter((sku) => sku?.barcode === serial.barcode)
        : undefined;

      return {
        ...serial,
        posted_date: serial.posted_date ?? undefined,
        scanned_by: serial.scanned_by ?? undefined,
        all_bin_locations: filterBinLocationsSerial,
        stock_qty: stock_qty,
      };
    });

    const formatTrace: ITraceEntry = {
      item_unique_id: key,
      is_stockable: value[0].is_stockable,
      sku_name: value[0].sku_name,
      item_name: value[0].item_name,
      item_img_url: value[0].item_img_url,
      tracability: value[0].tracability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      posted_date:
        value[0].tracability === Tracability.Normal
          ? value[0].posted_date
          : undefined,
      created_date: value[0].created_date,
      uom: value[0].uom,
      source_bin_location:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].tracability === Tracability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.tracability === Tracability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].tracability === Tracability.Normal
          ? value[0].status
          : undefined,
      remark:
        value[0].tracability === Tracability.Normal
          ? value[0].remark
          : undefined,
      scanned_by:
        value[0].tracability === Tracability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      destination_bin_location:
        value[0].tracability === Tracability.Normal
          ? value[0].destination_bin_location
          : undefined,
      destination_bin_location_id:
        value[0].tracability === Tracability.Normal
          ? value[0].destination_bin_location_id ?? undefined
          : undefined,
      destination_scanned_by:
        value[0].tracability === Tracability.Normal
          ? value[0].destination_scanned_by ?? undefined
          : undefined,
      destination_scanned_date:
        value[0].tracability === Tracability.Normal
          ? value[0].destination_scanned_date ?? undefined
          : undefined,
      all_bin_locations: filterBinLocations,
      stock_qty: formatSerial?.reduce<number>(
        (prev, curr) => prev + (curr.stock_qty ?? 0),
        0
      ),
    };
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
    created_date: dayjs(created_date),
    posted_date: dayjs(posted_date),
  };
};

export const goodsTransferTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocations: any[],
  user: IUser | null
) => {
  const {
    type,
    posted_date,
    created_date,
    scanned_by,
    unique_id,
    ...otherPayload
  } = trace;

  if (!user) return;

  const formatTrace: ITraceEntry = {
    ...otherPayload,
    qty: 0,
    source_bin_location: binLocations[0],
    source_bin_location_id: binLocations[0].id,
    all_bin_locations: binLocations,
    unique_id: uuidv4(),
    type: InventoryDocumentType.GoodsTransfer,
    scanned_by: {
      user_unique_id: user.unique_id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      img_url: user.img_url,
    },
    status: "is_scanned",
    remark: "",
    destination_bin_location: undefined,
    destination_scanned_by: undefined,
    posted_date: dayjs().toDate(),
    stock_qty: binLocations[0].stock_qty,
  };

  return formatTrace;
};

export const goodsTransferStampDestinationFormatter = (
  binLocation: ISelectOption | undefined,
  user: IUser | null
) => {
  if (!user) return;

  return {
    destination_bin_location: {
      id: Number(binLocation?.value),
      name: binLocation?.label,
    },
    destination_bin_location_id: Number(binLocation?.value),
    destination_scanned_by: {
      user_unique_id: user.unique_id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      img_url: user.img_url,
    },
    destination_scanned_date: dayjs().toDate(),
  };
};

export const goodsTransferCreateFormatter = (
  data: IGoodsTransfer,
  status?: string,
  approval_step?: number
) => {
  const { trace_entry_list, created_date, is_consignment, ...rest } = data;
  const new_trace_entry_list = formatTraceEntry(
    trace_entry_list,
    data.unique_id
  );

  return {
    ...rest,
    trace_entry_list: new_trace_entry_list,
    source_branch_id: Number(data.source_branch_id),
    source_warehouse_id: Number(data.source_warehouse_id),
    destination_branch_id: Number(data.destination_branch_id),
    destination_warehouse_id: Number(data.destination_warehouse_id),
    consignment_bin_location_id: Number(data.consignment_bin_location_id),
    main_status: status,
    approval_step: approval_step ? 1 : 0,
  };
};

export const goodsTransferUpdateFormatter = (
  data: IGoodsTransfer,
  status: string | undefined,
  approval_step?: number,
  approver_list?: IApprovalTemplate[],
  reviewed_by?: IReviewedBy
) => {
  if (!status) {
    return {
      remark: data.remark,
    };
  }

  const { reviewer_list } = data;
  const flagStatuses = ["cancelled", "not_approved", "late"];

  const isUpdateFlagStatus = flagStatuses.includes(status);
  const flag_status = isUpdateFlagStatus
    ? data?.flag_status && data.flag_status.length > 0
      ? data.flag_status.includes(status)
        ? [...data.flag_status]
        : [...data.flag_status, status]
      : [status]
    : [];

  const new_trace_entry_list = formatTraceEntry(
    data.trace_entry_list,
    data.unique_id
  );

  return {
    destination_branch_id: Number(data.destination_branch_id),
    destination_warehouse_id: Number(data.destination_warehouse_id),
    consignment_bin_location_id: data.consignment_bin_location_id
      ? Number(data.consignment_bin_location_id)
      : null,
    posted_date: data.posted_date,
    remark: data.remark,
    source_branch_id: Number(data.source_branch_id),
    source_warehouse_id: Number(data.source_warehouse_id),
    trace_entry_list: new_trace_entry_list.map((item) => {
      const { created_date, ...rest } = item;

      return rest;
    }),
    flag_status,
    main_status: flag_status.length > 0 ? undefined : status,
    approval_step: approval_step ? approval_step : 0,
    approver_list:
      approver_list && approver_list.length > 0
        ? approver_list
        : data.approver_list,
    reviewer_list: reviewer_list
      ? data.aggrid_status === "wait_approve" && reviewed_by
        ? status !== "not_approved"
          ? [...reviewer_list, reviewed_by]
          : [...reviewer_list]
        : data.aggrid_status === "not_approved" && status === "wait_approve"
        ? []
        : [...reviewer_list]
      : [],
  };
};

const formatTraceEntry = (
  traceEntryList: ITraceEntry[],
  reference_unique_id: string
) => {
  return traceEntryList.reduce<ITraceEntry[]>((prev, traceEntry) => {
    const {
      uom,
      source_bin_location,
      destination_bin_location,
      all_bin_locations,
      serial_list,
      ...restTraceEntry
    } = traceEntry;

    if (traceEntry.tracability === Tracability.Serial && serial_list) {
      const formatted = serial_list.map((serial) => {
        const {
          uom,
          source_bin_location,
          destination_bin_location,
          all_bin_locations,
          serial_list,
          ...restSerial
        } = { ...serial, reference_unique_id };

        return restSerial;
      });

      prev.push(...formatted);
    } else {
      prev.push({ ...restTraceEntry, reference_unique_id });
    }

    return prev;
  }, []);
};
