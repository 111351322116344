import { Box, Button, Typography } from "@mui/material";
// import { useParams } from "react-router-dom";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import { ReactInstance, ReactNode, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PDFHeader from "./PDFHeader";
// import PDFFooter from "./PDFFooter";
import PDFSigner from "./PDFSigner";

interface Props {
  children?: ReactNode;
  documentName: string;
  data: any;
  documentType?: string;
  noCompany?: boolean;
  horizontal?: boolean;
  isApprove?: boolean;
}

const PDFLayout = ({
  children,
  documentName,
  data,
  documentType,
  noCompany,
  horizontal,
  isApprove,
}: Props) => {
  // const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box>
      {horizontal && (
        <style>{`@media print {
        html,
        body {
          height: 100%;
          margin: 0 !important;
          padding: 0 !important;
        }
      
        @page {
          size: 297mm 210mm;
        }
      }`}</style>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">{documentName}</Typography>
          {/* <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography> */}
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>

      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: horizontal ? "297mm" : "210mm",
            height: horizontal ? "210mm" : "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <CustomizedScrollbar
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 3,
              }}
              ref={componentRef}
            >
              <Box>
                <PDFHeader documentName={documentName} noCompany={noCompany} />
                {children}
                {/* <PDFFooter data={data} documentType={documentType}  /> */}
              </Box>
              <Box
                sx={{
                  py: 3,
                  breakInside: "avoid",
                }}
              >
                <PDFSigner
                  data={data}
                  documentType={documentType}
                  horizontal={horizontal}
                  isApprove={isApprove}
                />
              </Box>
            </Box>
          </CustomizedScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default PDFLayout;
