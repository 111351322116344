import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { IPurchaseItemList } from "../../types/Purchase";
import { ITEM_SKU_DETAIL_AGGRID } from "../../services/AgGrid/InventoryAggrid";

export const usePurchaseItemListFormatter = (
  item_list: IPurchaseItemList[]
) => {
  const [formatItemList, setFormatItemList] = useState<IPurchaseItemList[]>([]);

  const getUomName = useCallback(async () => {
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("wms");
    if (item_list && item_list.length > 0) {
      const allItemListUniqueId = item_list.map((item) => item?.item_unique_id);

      const { itemSkuDetailsFindManyAggrid } =
        await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: 9999,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: allItemListUniqueId,
              },
            },
          },
        });

      let formatItemList: IPurchaseItemList[] = [];

      const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;
      item_list.forEach((item) => {
        const foundItemIndex = itemSkuDetails.findIndex(
          (realItem: any) => realItem.sku_name === item?.item_unique_id
        );

        const itemType = item as IPurchaseItemList;

        formatItemList.push({
          ...itemType,
          uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
          item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
        });
      });
      return formatItemList;
    } else {
      return [];
    }
  }, [item_list]);

  useEffect(() => {
    const fetcher = async () => {
      const formatWithUom = await getUomName();
      setFormatItemList(formatWithUom);
    };
    fetcher();
  }, [getUomName]);

  return formatItemList;
};
