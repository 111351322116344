import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import TocIcon from "@mui/icons-material/Toc";
import CloseIcon from "@mui/icons-material/Close";

import ItemNameCell from "./ItemNameCell";
import SumPreVatAmount from "./SumPreVatAmount";
import PreVatAmountCell from "./PreVatAmountCell";
import Confirmation from "../../../UI/Confirmation";
import SubHeader from "../../../Form/Purchase/SubHeader";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import CheckedItemListModal from "../../../UI/Modal/CheckedItemListModal";

import {
  numberFormatter,
  priceNumberFormatter,
  priceVatTypeFormatter,
  vatTypeFormatter,
} from "../../../../utils/Formatter/Global";

import { useModal } from "../../../../hooks/use-modal";
import { useDisable } from "../../../../hooks/use-disable";
import { usePurchaseItemListHeader } from "../../../../hooks/Purchase/use-purchase-item-list-header";

import { ISelectOption } from "../../../../types/global";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { IPurchaseReturn } from "../../../../types/Purchase/purchaseReturn";
import LabelInput from "../../../UI/LabelInput";
import { useTranslation } from "react-i18next";
import { PriceVatType } from "../../../../generated/purchase";

interface Props {
  referenceItemList?: IPurchaseItemList[];
  documentType: string;
}

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const priceVatTypeOptions: ISelectOption[] = [
  {
    label: "ราคารวมภาษี",
    value: PriceVatType.IncludeVat,
  },
  {
    label: "ราคาแยกภาษี",
    value: PriceVatType.ExcludeVat,
  },
];

const currencyOptions: ISelectOption[] = [
  {
    label: "THB",
    value: "THB",
  },
];

const PurchaseReturnitemList = ({ documentType, referenceItemList }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IPurchaseReturn>();
  const [disabled] = useDisable();
  const headers = usePurchaseItemListHeader(documentType, disabled);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: itemRSModal,
    openModalHandler: openItemRSModalHandler,
    closeModalHandler: closeItemRSModalHandler,
  } = useModal();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  useEffect(() => {
    if (referenceItemList && referenceItemList.length > 0) {
      const uniqueIdList = referenceItemList.map(
        ({ unique_id }) => unique_id || ""
      );
      setItemIds(uniqueIdList);
      setItemIdsSnapshot(uniqueIdList);
    }
  }, [referenceItemList]);

  const { fields, move, remove, replace } = useFieldArray({
    control,
    name: "item_list",
    keyName: "unique_id",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const addReferenceItemsHandler = (itemList: any[]) => {
    if (itemList && itemList.length > 0) {
      let filteredItems = fields.filter((item) =>
        itemIds.includes(item.unique_id)
      );

      // get current Items id to prevent duplication when appending newly selected Items
      const filteredItemsId = filteredItems.map((item) => item.unique_id);

      itemList.forEach((item) => {
        if (!filteredItemsId.includes(item.unique_id)) {
          filteredItems.push(item);
        }
      });

      // sort for proerly render order (and for role assignment when submitting)
      filteredItems = filteredItems.sort((a, b) => {
        return itemIds.indexOf(a.unique_id) - itemIds.indexOf(b.unique_id);
      });

      replace(filteredItems);
    } else if (itemList.length === 0) {
      replace([]);
    }
  };

  const uomOptions = (field: IPurchaseItemList) => {
    console.log("field", field);
    const options = (
      field.uom_group
        ? field.uom_group.convertable_uom_list &&
          field.uom_group.convertable_uom_list.length > 0
          ? [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
              ...field.uom_group.convertable_uom_list.map((uom) => ({
                label: uom.name_th,
                value: uom.id,
              })),
            ]
          : [
              {
                label: field.uom_group?.base_uom?.name_th || "",
                value: field.uom_group?.base_uom?.id || "",
              },
            ]
        : []
    ) as ISelectOption[];
    return options;
  };

  const notEditStatus =
    ["approved", "partially_imported", "fully_imported"].includes(
      status || ""
    ) || disabled;

  return (
    <CustomizedBox
      sx={{ breakAfter: "auto" }}
      margin="2rem 0 0 0"
      maxWidth={1650}
    >
      <SubHeader title={"สินค้า"} sx={{ marginBottom: 2 }} />
      <Grid container my={2} spacing={2}>
        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("sales.price_vat_type")}
              value={priceVatTypeFormatter(getValues("price_vat_type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="price_vat_type"
              label={t("sales.price_vat_type")}
              options={priceVatTypeOptions}
              error={Boolean(errors.price_vat_type)}
              helperText={errors?.price_vat_type?.message?.toString()}
              disabled={notEditStatus}
            />
          )}
        </Grid>
        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("global.currency")}
              value={getValues("currency")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="currency"
              label={t("global.currency")}
              options={currencyOptions}
              error={Boolean(errors.currency)}
              helperText={errors?.currency?.message?.toString()}
              disabled
            />
          )}
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                    backgroundColor: "#F0F1FA",
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.unique_id}
                      draggableId={"item-" + field.unique_id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={disabled}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {numberFormatter(field.qty)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.qty`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.qty
                                )}
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(value) => {
                                  const parseValue = value || 0;
                                  const currentUomId = getValues(
                                    `item_list.${index}.uom_id`
                                  );
                                  if (currentUomId) {
                                    if (field.uom_group) {
                                      if (
                                        currentUomId ===
                                        field.uom_group.base_uom?.id
                                      ) {
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_group.uom_conversion_list &&
                                          field.uom_group.uom_conversion_list
                                            .length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_group.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_id ===
                                                currentUomId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `item_list.${index}.stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                                disabled={status === "approved"}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.uom_name}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list.${index}.uom_id`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.uom_id
                                )}
                                options={uomOptions(field)}
                                onChange={(e: any) => {
                                  const uom_name = uomOptions(field).find(
                                    (uom) => uom.value === e.target.value
                                  )?.label;
                                  setValue(
                                    `item_list.${index}.uom_name`,
                                    uom_name
                                  );
                                  const currentQty = getValues(
                                    `item_list.${index}.qty`
                                  );
                                  if (field.uom_group) {
                                    if (
                                      e.target.value ===
                                      field.uom_group.base_uom?.id
                                    ) {
                                      setValue(
                                        `item_list.${index}.stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list.${index}.stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.price_per_unit)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.price_per_unit`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.price_per_unit
                                )}
                                decimalScale={4}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`item_list.${index}.discount`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.discount
                                )}
                                decimalScale={4}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {vatTypeFormatter(field.vat_percentage)}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`item_list.${index}.vat_percentage`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.vat_percentage
                                )}
                                options={vatOptions}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={disabled}
                              setValue={setValue}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Typography fontSize={14}>
                              {
                                field.reference_line_item
                                  ?.line_item_document_unique_id
                              }
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[9]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <>
                                <Typography fontSize={14}>
                                  {numberFormatter(field.stock_qty)}
                                </Typography>
                                <Typography variant="caption" color="#BDBDBD">
                                  {field.uom_group?.base_uom?.name_th}
                                </Typography>
                              </>
                            ) : (
                              <ControlledTextField
                                sx={{ mt: 3 }}
                                type="number"
                                control={control}
                                name={`item_list.${index}.stock_qty`}
                                helperText={field.uom_group?.base_uom?.name_th}
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "center" },
                                  },
                                }}
                                viewMode={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[10]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                control={control}
                                name={`item_list.${index}.remark`}
                                error={Boolean(
                                  errors?.item_list &&
                                    errors?.item_list[index] &&
                                    errors?.item_list[index]?.remark
                                )}
                              />
                            )}
                          </TableCell>

                          {!disabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[11]?.width || 0
                                  : 0) + 16
                              }
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!disabled && (
        <CustomizedButton
          sx={{ mt: 2 }}
          title="เพิ่มสินค้า"
          variant="outlined"
          onClick={openItemRSModalHandler}
          addIcon
        />
      )}
      <Grid container maxWidth={984} spacing={0}>
        <Grid item xs={12} sm={12} md={5} />
        <Grid item xs={12} sm={12} md={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <CheckedItemListModal
        showSelectItem={itemRSModal}
        closeItemTable={closeItemRSModalHandler}
        finishItemsSelect={addReferenceItemsHandler}
        rowData={referenceItemList}
        rowSelection="multiple"
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        documentType="purchase_return"
      />
    </CustomizedBox>
  );
};

export default PurchaseReturnitemList;
