import { Box, Divider, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { formatDate } from "../../utils/Date";

type Props = {
  title: string;
  name?: string;
  date?: Date | Dayjs;
  customDivider?: boolean;
  documentType?: string;
};

const SignerBox = ({ title, name, date, customDivider }: Props) => {
  const fontSize = 10.66;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: fontSize,
          fontWeight: 600,
          lineHeight: 2,
        }}
      >
        {title}
      </Typography>

      {customDivider ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              mt: 2,
              mb: 2,
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                width: 90,
                alignSelf: "center",
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  width: 24,
                }}
              />
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  width: 24,
                }}
              />
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  width: 24,
                }}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              height: 86.45,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            {name && name !== "- " ? (
              <Typography
                sx={{
                  mt: 0,
                  fontSize: 8.9,
                  fontWeight: 600,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                  width: 90,
                  textAlign: "center",
                }}
              >
                {name}
              </Typography>
            ) : (
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  width: 90,
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "flex-end",
              height: 26.6,
              textAlign: "center",
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            {date ? (
              <Typography
                sx={{
                  mt: 0,
                  fontSize: 8.9,
                  fontWeight: 600,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                  width: 90,
                  textAlign: "center",
                }}
              >
                {formatDate(date)}
              </Typography>
            ) : (
              <>
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 24,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 24,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 24,
                  }}
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SignerBox;
