import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";
import { useSnackbar } from "notistack";
import { useDisable } from "../../../../hooks/use-disable";
import { Fragment, useCallback, useState } from "react";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import { useGoodsIssueItemList } from "../../../../hooks/Inventory/use-issue-item-list";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import {
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  TraceEntriesFindManyAggridQuery,
  useItemSkuQtysQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import SerialList from "./SerialList";
import ClearIcon from "@mui/icons-material/Clear";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import Confirmation from "../../../UI/Confirmation";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { goodsIssueTraceEntryFormatter } from "../../../../utils/Formatter/Inventory/GoodsIssue";
import { useStateContext } from "../../../../contexts/auth-context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  fields: FieldArrayWithId<IGoodsIssue, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsIssue, "trace_entry_list">;
  append: UseFieldArrayAppend<IGoodsIssue, "trace_entry_list">;
};

const GoodsIssueItemList = ({ fields, remove, append, update }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IGoodsIssue>();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled] = useDisable();
  const { id } = useParams();

  const {
    state: { authUser },
  } = useStateContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
    },
  });

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchWarehouseId = useWatch({
    control,
    name: "warehouse_id",
  });

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const headers = useGoodsIssueItemList(
    status === "wait_issue" || status === "finished" || status === "cancelled"
  );
  const graphQLClientWithHeaderItem = createGraphQLClientWithMiddleware("wms");

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetch();
    const { data: skuQtyData } = await refetchSkuQty();
    const warehouse_id = getValues("warehouse_id");

    if (
      fields &&
      fields.length > 0 &&
      fields.some(
        (trace) =>
          trace.item_unique_id === skuQtyData?.itemSkuQtys?.[0]?.sku_name
      ) &&
      watchType === "อื่นๆ"
    ) {
      enqueueSnackbar(`QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`, {
        variant: "error",
        style: { whiteSpace: "pre-line" },
      });
    } else {
      if (skuQtyData?.itemSkuQtys) {
        if (skuQtyData.itemSkuQtys.length > 0) {
          const filteredWarehouse = skuQtyData.itemSkuQtys.filter(
            (itemSkuQty) => itemSkuQty?.warehouse_id === warehouse_id
          );

          if (filteredWarehouse.length > 0) {
            const formatBinLocations = skuQtyData.itemSkuQtys.map(
              (itemSku) => ({
                id: itemSku?.bin_id,
                name: itemSku?.bin_name,
                stock_qty: itemSku?.stock_qty,
                warehouse_id: itemSku?.warehouse_id,
              })
            );

            const filterBinLocations = formatBinLocations.filter(
              (bin) => bin.warehouse_id === watchWarehouseId
            );

            const traceEntries = data?.TraceEntriesFindManyAggrid?.results;
            const traceEntry = traceEntries
              ? traceEntries.find(
                  (trace) => trace?.type === InventoryDocumentType.GoodsReceive
                )
              : null;
            if (traceEntry) {
              const traceEntryType = traceEntry as ITraceEntry;
              const formatTrace = goodsIssueTraceEntryFormatter(
                traceEntryType,
                filterBinLocations,
                authUser
              );
              if (formatTrace) {
                if (formatTrace.tracability === Tracability.Serial) {
                  const foundExisting = fields.findIndex(
                    (trace) =>
                      trace.item_unique_id === formatTrace.item_unique_id
                  );
                  if (foundExisting === -1) {
                    // append new trace (serial view)
                    const {
                      posted_date,
                      serial_no,
                      source_bin_location,
                      source_bin_location_id,
                      scanned_by,
                      barcode,
                      ...otherTrace
                    } = formatTrace;

                    const formatTraceWithSerial = {
                      ...otherTrace,
                      serial_list: [
                        {
                          ...otherTrace,
                          posted_date,
                          serial_no,
                          source_bin_location,
                          source_bin_location_id,
                          scanned_by,
                          barcode,
                        },
                      ],
                    };
                    append(formatTraceWithSerial);
                  } else {
                    // update serial
                    const currentTrace: ITraceEntry = getValues(
                      `trace_entry_list.${foundExisting}`
                    );
                    if (currentTrace && currentTrace.serial_list) {
                      const foundBarcode = currentTrace.serial_list.findIndex(
                        (serial) =>
                          serial.barcode ===
                          (watchBarcode("barcodeMobile")?.trim() ||
                            watchBarcode("barcode").trim())
                      );
                      if (foundBarcode === -1) {
                        const { document_item_qty, posted_qty, ...otherTrace } =
                          formatTrace;
                        const formatTraceWithDocument: ITraceEntry = {
                          ...otherTrace,
                          document_item_qty: currentTrace.document_item_qty,
                          posted_qty: currentTrace.posted_qty,
                        };
                        update(foundExisting, {
                          ...currentTrace,
                          serial_list: [
                            ...currentTrace.serial_list,
                            formatTraceWithDocument,
                          ],
                        });
                        enqueueSnackbar(
                          `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                          {
                            variant: "success",
                            style: { whiteSpace: "pre-line" },
                          }
                        );
                      } else {
                        enqueueSnackbar(
                          `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                          {
                            variant: "error",
                            style: { whiteSpace: "pre-line" },
                          }
                        );
                      }
                    }
                  }
                } else {
                  // append new trace (normal view)
                  const foundExisting = fields.findIndex(
                    (trace) =>
                      trace.item_unique_id === formatTrace.item_unique_id
                  );
                  if (foundExisting === -1) {
                    append(formatTrace);
                    enqueueSnackbar(
                      `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                      {
                        variant: "success",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  } else {
                    const currentTrace: ITraceEntry = getValues(
                      `trace_entry_list.${foundExisting}`
                    );
                    const { document_item_qty, posted_qty, ...otherTrace } =
                      formatTrace;

                    const formatTraceWithDocument: ITraceEntry = {
                      ...otherTrace,
                      document_item_qty: currentTrace.document_item_qty,
                      posted_qty: currentTrace.posted_qty,
                    };

                    if (currentTrace.status === "is_active") {
                      update(foundExisting, formatTraceWithDocument);
                      enqueueSnackbar(
                        `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                        {
                          variant: "success",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    } else {
                      enqueueSnackbar(
                        `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                        {
                          variant: "error",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    }
                  }
                }
              }
            }
          } else {
            // Warehouse not match
            enqueueSnackbar(
              "QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่",
              {
                variant: "error",
              }
            );
          }
        } else {
          // Barcode not found
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
            variant: "error",
          });
        }
      }
    }
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [
    append,
    authUser,
    enqueueSnackbar,
    fields,
    getValues,
    refetch,
    refetchSkuQty,
    resetBarcode,
    update,
    watchBarcode,
    watchType,
    watchWarehouseId,
  ]);

  return (
    <>
      {(!id || status === "draft" || status === "not_approved") && (
        <CustomizedBox margin={0}>
          <Typography fontWeight={600} color={"primary.main"}>
            สแกน Barcode
          </Typography>
          <Grid container spacing={1.5} mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitHandler();
                  }
                }}
                disabled={disabled || !watchWarehouseId}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
      <CustomizedBox
        maxWidth={1650}
        margin={
          !id || status === "draft" || status === "not_approved"
            ? "1.5rem 0rem"
            : 0
        }
      >
        <Typography color="primary.main" fontWeight={600} mb={2}>
          รายการสินค้า
        </Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[0]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[1]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {formatDateTimeNoAMPM(trace.posted_date)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[2]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[3]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[4]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.document_item_qty || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[5]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.posted_qty || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[6]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {(trace.document_item_qty || 0) -
                          (trace.posted_qty || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[7]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal ? (
                        <>
                          {status === "wait_issue" ||
                          status === "finished" ||
                          status === "cancelled" ||
                          !trace.scanned_by ? (
                            <Typography fontSize={14}>{trace.qty}</Typography>
                          ) : (
                            <ControlledTextField
                              type="number"
                              control={control}
                              name={`trace_entry_list.${index}.qty`}
                              error={Boolean(
                                errors?.trace_entry_list?.[index]?.qty
                              )}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  // style: {
                                  //   fontSize: 14,
                                  // },
                                },
                              }}
                              FormHelperTextProps={{
                                style: { fontSize: "10px" },
                              }}
                              helperText={
                                trace.scanned_by
                                  ? `จำนวนคงคลัง ${
                                      trace.stock_qty?.toString() || "-"
                                    }`
                                  : undefined
                              }
                            />
                          )}
                        </>
                      ) : (
                        <TotalPostedQuantityCell nestedIndex={index} />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.uom?.name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.serial_no}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[9]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal ? (
                        trace.all_bin_locations &&
                        trace.all_bin_locations.length > 1 &&
                        !disabled ? (
                          <ControlledSelect
                            control={control}
                            name={`trace_entry_list[${index}].source_bin_location_id`}
                            options={trace.all_bin_locations.map((bin) => ({
                              label: bin.name,
                              value: bin.id,
                            }))}
                            onChange={(e: any) => {
                              const { id, ...otherFields } = fields[index];
                              update(index, {
                                ...otherFields,
                                stock_qty:
                                  trace.all_bin_locations &&
                                  trace.all_bin_locations.find(
                                    (bin) => bin.id === e.target.value
                                  ).stock_qty,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            MenuProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            helperTextSize={10}
                          />
                        ) : (
                          <Typography fontSize={14}>
                            {trace.source_bin_location?.name}
                          </Typography>
                        )
                      ) : null}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[10]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.scanned_by && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CustomizedAvatar
                            avatars={[
                              {
                                unique_id:
                                  trace.scanned_by.user_unique_id || "",
                                first_name: trace.scanned_by.first_name || "",
                                last_name: trace.scanned_by.last_name || "",
                                img_url: trace.scanned_by.img_url
                                  ? trace.scanned_by.img_url[0]
                                  : "",
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[11]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.barcode}</Typography>
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[12]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          <ControlledTextField
                            placeholder={t("sentence.remark")}
                            control={control}
                            name={`trace_entry_list.${index}.remark`}
                            viewMode={status === "finished"}
                            viewModeNoLabel
                          />
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[13]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.lot_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[14]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.manufactured_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[15]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.expired_date)}
                        </Typography>
                      )}
                    </TableCell>
                    {(!id || status === "draft") && (
                      <TableCell
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[16]?.width || 0
                            : 0) + 16
                        }
                      >
                        <IconButton
                          onClick={() => {
                            openConfirmationHandler();
                            setDeletedIndex(index);
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <SerialList nestedIndex={index} />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Confirmation
          title="ยืนยันหากต้องการลบสินค้า"
          message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
      </CustomizedBox>
    </>
  );
};

export default GoodsIssueItemList;
