import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { useContactDescription } from "../../../../hooks/use-contact-description";
import CheckedCustomerModal from "../../../UI/Modal/CheckedCustomerModal";
import { useDisable } from "../../../../hooks/use-disable";

type Props = {
  forceDisabled?: boolean;
  documentType?: string;
};

const CustomerInfo = ({ forceDisabled, documentType }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    finishContactsSelect,
    openContactTableHandler,
    selectedContactId,
    openContactDetail,
  } = useContactDescription("sales", documentType);

  return (
    <Fragment>
      <CustomizedBox>
        <Grid container spacing={1.5} alignItems="center" mb={2}>
          <Grid item xs={4} sm={3} md={2}>
            <Typography fontWeight={600} color={"primary.main"}>
              {t("sales.customer_description")}
            </Typography>
          </Grid>
          {!disabled && !forceDisabled && (
            <Grid item xs={4} sm={3} md={2}>
              <Box>
                <CustomizedTooltip title={t("sentence.choose_customer")}>
                  <IconButton
                    onClick={openContactTableHandler}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.unique_id_name"
              label={
                t("sales.customer_unique_id") + " - " + t("sales.customer_name")
              }
              disabled
              viewMode={disabled}
              required
              error={Boolean(errors.customer_unique_id)}
              helperText={errors?.customer_unique_id?.message?.toString()}
            />
          </Grid>
          <Grid item xs={11} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.identity_no"
              label={t("sales.identity_no")}
              viewMode={disabled}
              disabled
              required
            />
          </Grid>
          <Grid item xs={1} md={4}>
            {selectedContactId && (
              <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                <IconButton
                  onClick={openContactDetail}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  <LaunchOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.phone"
              label={t("global.phone_number")}
              viewMode={disabled}
              disabled={forceDisabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.email"
              label={t("global.email")}
              viewMode={disabled}
              disabled={forceDisabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.fax"
              label={"โทรสาร"}
              viewMode={disabled}
              disabled={forceDisabled}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedCustomerModal
        showSelectCustomer={openContactTable}
        closeCustomerTable={closeContactTableHandler}
        customerIds={contactIds}
        setCustomerIds={setContactIds}
        customerIdsSnapshot={contactIdsSnapshot}
        setCustomerIdsSnapshot={setContactIdsSnapshot}
        finishCustomersSelect={finishContactsSelect}
        rowSelection="single"
      />
    </Fragment>
  );
};

export default CustomerInfo;
