import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const usePurchaseOrderOption = (status?: string | null) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "อ้างอิงเอกสารภายนอก",
        disabled: false,
      },
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "not_approved":
        setSelectedModifyOptions([defaultOptions[2], defaultOptions[3]]); // ยกเลิก, คัดลอก
        break;
      case "wait_approve":
        setSelectedModifyOptions([
          defaultOptions[1],
          defaultOptions[2],
          defaultOptions[3],
        ]); //แก้ไข , คัดลอก , ยกเลิก
        break;
      case "partially_imported":
        setSelectedModifyOptions([
          {
            value: "ยกเลิกจำนวนคงค้าง",
            disabled: false,
          },
          defaultOptions[0],
          defaultOptions[2],
        ]); // คัดลอก
        break;
      case "fully_imported":
        setSelectedModifyOptions([defaultOptions[0], defaultOptions[2]]); // คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[2]]); //คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};

export const usePurchaseOrderCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "ใบ" + t("inventory.goods_receive.index"),
        disabled: status === "fully_imported",
      },
      {
        value: t("purchase.return.index"),
        disabled: false,
      },
    ],
    [status, t]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "partially_imported":
        setSelectedModifyOptions([defaultOptions[0], defaultOptions[1]]); // คัดลอก
        break;
      case "fully_imported":
        setSelectedModifyOptions([defaultOptions[1]]); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    switch (status) {
      case "accepted":
      case "approved":
      case "finished":
      case "partially_imported":
      case "fully_imported":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);

  return {
    selectCreateOptions: selectModifyOptions,
    disabledCreateOptions: disabled,
  };
};
