import { ColDef, ValueGetterParams } from "ag-grid-community";
import AgGrid from "../../UI/AgGrid";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomizedStatus from "../../Custom/CustomizedStatus";

type Props = {
  data: any[];
};

const UserImporterTable = ({ data }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: "รหัสพนักงาน",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "ชื่อพนักงาน",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
      valueGetter: (params: ValueGetterParams) =>
        params.data.first_name + " " + params.data.last_name,
    },
    {
      field: "status",
      headerName: "สถานะ",
      filter: "agSetColumnFilter",
      flex: 1,
      minWidth: 200,
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      rowData={data}
      disabledSidebar
    />
  );
};

export default UserImporterTable;
