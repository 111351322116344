import { GraphQLClient } from "graphql-request";

import { USERS_AGGRID } from "../../services/AgGrid/UserAgGrid";
import { BRANCHES_AGGRIDS } from "../../services/AgGrid/BranchAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

import { IUser } from "../../types/Auth/user";
import { IBranch } from "../../types/Setting/Branch";

export const usePurchaseAgGrid = (type: string) => {
  const branch_id = parseInt(sessionStorage.getItem("branch-id") || "");

  const branchFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { BranchesFindManyAggrid } = await graphQLClient.request(
          BRANCHES_AGGRIDS,
          {
            aggridInput: {
              startRow: 0,
              endRow: 9999,
              filterModel: {
                id: {
                  filterType: "set",
                  values: branch_id === -1 ? undefined : [branch_id],
                },
              },
              sortModel: [],
            },
          }
        );
        const branchName = BranchesFindManyAggrid.results.map(
          (branch: IBranch) => branch.unique_id + " - " + branch.name
        );
        params.success(branchName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const purchaseUserFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { UsersView } = await graphQLClient.request(USERS_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: 9999,
            filterModel: {
              // branch_id: {
              //   filterType: "set",
              //   values: branch_id === -1 ? [branch_id] : [-1, branch_id],
              // },
            },
            sortModel: [],
          },
        });
        const userName = UsersView.results.map(
          (user: IUser) => user.first_name + " " + user.last_name
        );
        params.success(userName);
      } catch (err) {
        params.fail();
      }
    },
  };

  return {
    branchFilterParams,
    purchaseUserFilterParams,
  };
};
