import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider } from "@mui/material";

import { formatDate } from "../../../../utils/Date";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GoodsReturnQuery,
  useGoodsReturnQuery,
} from "../../../../generated/inventory";

import PDFLayout from "../../../../components/UI/Pdf/PDFLayout";
import PDFInfo from "../../../../components/UI/Pdf/PDFInfo";
import GoodsReturnPdfTable from "../../../../components/Table/Pdf/GoodsReturn/GoodsReturnPdfTable";
import {
  goodsReturnQueryFormatter,
  goodsReturnTypeValueFormatter,
} from "../../../../utils/Formatter/Inventory/GoodsReturn";
import { IGoodsReturn } from "../../../../types/Inventory/GoodsReturn";

const GoodsReturnPdf = () => {
  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading } = useGoodsReturnQuery<GoodsReturnQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const leftHeader = [
    "เลขที่เอกสาร",
    "ประเภท",
    "เอกสารอ้างอิง",
    "สาขา",
    "คลัง",
  ];

  const leftInfo = [
    data?.GoodsReturn?.unique_id || "-",
    goodsReturnTypeValueFormatter(data?.GoodsReturn?.type ?? "") || "-",
    data?.GoodsReturn?.reference_unique_id || "-",
    data?.GoodsReturn?.branch.name || "-",
    data?.GoodsReturn?.warehouse?.name || "-",
  ];

  const rightHeader = ["วันที่สร้าง", "วันที่รับคืน", "หมายเหตุ"];

  const rightInfo = [
    data?.GoodsReturn?.created_date
      ? formatDate(data?.GoodsReturn?.created_date)
      : "-",
    data?.GoodsReturn?.posted_date
      ? formatDate(data?.GoodsReturn?.posted_date)
      : "-",
    data?.GoodsReturn?.remark ?? "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบรับคืน / Goods Return"
      data={data?.GoodsReturn}
      horizontal
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <GoodsReturnPdfTable
        itemList={
          data?.GoodsReturn
            ? goodsReturnQueryFormatter(data.GoodsReturn as IGoodsReturn)
                .trace_entry_list
            : []
        }
      />
    </PDFLayout>
  );
};

export default GoodsReturnPdf;
