import { Theme } from "@mui/material";
import { styled } from "@mui/system";

interface Props {
  theme: Theme;
  open?: boolean;
}

const DashboardContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: Props) => ({
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "260px",
  }),
  flexGrow: 1,
  paddingTop: 64,
  paddingBottom: 64,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default DashboardContainer;
