import { ColDef } from "ag-grid-community";
import AgGrid from "../../UI/AgGrid";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
// import { IContact } from "../../../types/Contact/contact";
// import CustomizedStatus from "../../Custom/CustomizedStatus";

type Props = {
  type: "customer" | "vendor";
  data: any[];
};

const ContactImporterTable = ({ type, data }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: type === "customer" ? "รหัสลูกค้า" : "รหัสผู้ขาย",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "name",
    //   headerName: type === "customer" ? "ชื่อลูกค้า" : "ชื่อผู้ขาย",
    //   filter: "agTextColumnFilter",
    //   flex: 1,
    //   minWidth: 200,
    //   valueFormatter: ({ data }: { data: IContact }) => {
    //     if (data.business_type === "นิติบุคคล") {
    //       return data.name;
    //     }
    //     return `${data.name} ${data?.last_name || ""}`;
    //   },
    // },
    // {
    //   field: "status",
    //   headerName: "สถานะ",
    //   filter: "agSetColumnFilter",
    //   flex: 1,
    //   minWidth: 200,
    //   filterParams: {
    //     values: [1, 0],
    //     valueFormatter: ({ value }: { value: number }) => {
    //       if (value) return "ใช้งาน";
    //       return "หยุดใช้งาน";
    //     },
    //   },
    //   cellRenderer: ({ value }: { value: boolean }) => {
    //     if (value) return <CustomizedStatus status="active" />;
    //     return <CustomizedStatus status="inactive" />;
    //   },
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
  ];
  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      rowData={data}
      disabledSidebar
    />
  );
};

export default ContactImporterTable;
