import dayjs from "dayjs";
import {
  CustomerCreateInput,
  CustomerUpdateInput,
  VendorCreateInput,
  VendorUpdateInput,
} from "../../generated/contact";
import { IContact } from "../../types/Contact/contact";
import { uploadFileToS3 } from "../s3";
import { IAttachment, ISelectOption } from "../../types/global";

export const customerCreateFormatter = async (
  data: IContact,
  id: string | undefined
) => {
  const {
    status,
    img_url,
    tag_list,
    attachment_list,
    contact_person_list,
    ...otherData
  } = data;

  let uploded_img: string[] = [];
  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "customer",
        id || ""
      );
      uploded_img.push(Location);
    } else {
      uploded_img = img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "customer",
            data?.unique_id + person.unique_id + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = data.img_url;
        }
        return { ...person, img_url: updatedImgUrl || [] };
      }
      return person;
    }) || []
  );

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "customer",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  const formatData: CustomerCreateInput = {
    ...otherData,
    status: status ? 1 : 0,
    img_url: uploded_img,
    tag_id_list,
    attachment_list: uploadedAttachment,
    contact_person_list: transformedContactPersons,
    sales_person_list: otherData.sales_person_list || [],
  };
  return formatData;
};

export const customerUpdateFormatter = async (data: IContact) => {
  const {
    status,
    img_url,
    source_country,
    id,
    tag_list,
    created_date,
    attachment_list,
    contact_person_list,
    ...otherData
  } = data;

  let uploded_img: string[] = [];
  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "customer",
        id?.toString() || ""
      );
      uploded_img.push(Location);
    } else {
      uploded_img = img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "contact",
            data?.unique_id + person.unique_id + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = person.img_url;
        }
        return { ...person, img_url: updatedImgUrl || [] };
      } else {
        return person;
      }
    }) || []
  );

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "deliveryOrder",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  const formatData: CustomerUpdateInput = {
    ...otherData,
    status: status ? 1 : 0,
    img_url: uploded_img,
    tag_id_list,
    attachment_list: uploadedAttachment,
    contact_person_list: transformedContactPersons,
    sales_person_list: otherData.sales_person_list || [],
  };
  return formatData;
};

export const customerQueryFotmatter = (data: IContact) => {
  const { member_detail, inactive_date, ...otherData } = data;
  const { opened_date, expired_date, ...otherDetail } = member_detail;

  const formatMemberDetail = {
    ...otherDetail,
    opened_date: opened_date ? dayjs(opened_date) : null,
    expired_date: expired_date ? dayjs(expired_date) : null,
  };

  const formatData = {
    ...otherData,
    member_detail: formatMemberDetail,
    inactive_date: inactive_date ? dayjs(inactive_date) : null,
  };

  return formatData;
};

export const vendorCreateFormatter = async (
  data: IContact,
  id: string | undefined
) => {
  const {
    status,
    img_url,
    tag_list,
    attachment_list,
    contact_person_list,
    sales_channel,
    sales_person_list,
    ...otherData
  } = data;

  let uploded_img: string[] = [];
  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "vendor",
        id || ""
      );
      uploded_img.push(Location);
    } else {
      uploded_img = img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "contact",
            data?.unique_id + person.unique_id + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = data.img_url;
        }
        return { ...person, img_url: updatedImgUrl || [] };
      }
      return person;
    }) || []
  );

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "vendor",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  const formatData: VendorCreateInput = {
    ...otherData,
    status: status ? 1 : 0,
    img_url: uploded_img,
    tag_id_list,
    attachment_list: uploadedAttachment,
    contact_person_list: transformedContactPersons,
  };
  return formatData;
};

export const vendorUpdateFormatter = async (data: IContact) => {
  const {
    status,
    img_url,
    source_country,
    id,
    tag_list,
    created_date,
    attachment_list,
    contact_person_list,
    sales_channel,
    sales_person_list,
    ...otherData
  } = data;

  let uploded_img: string[] = [];
  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "vendor",
        id?.toString() || ""
      );
      uploded_img.push(Location);
    } else {
      uploded_img = img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "contact",
            data?.unique_id + person.unique_id + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = person.img_url;
        }
        return { ...person, img_url: updatedImgUrl || [] };
      } else {
        return person;
      }
    }) || []
  );

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "deliveryOrder",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  const formatData: VendorUpdateInput = {
    ...otherData,
    status: status ? 1 : 0,
    img_url: uploded_img,
    tag_id_list,
    attachment_list: uploadedAttachment,
    contact_person_list: transformedContactPersons,
  };
  return formatData;
};

export const vendorQueryFotmatter = (data: IContact) => {
  const { member_detail, inactive_date, ...otherData } = data;
  const { opened_date, expired_date, ...otherDetail } = member_detail;

  const formatMemberDetail = {
    ...otherDetail,
    opened_date: opened_date ? dayjs(opened_date) : null,
    expired_date: expired_date ? dayjs(expired_date) : null,
  };

  const formatData = {
    ...otherData,
    inactive_date: inactive_date ? dayjs(inactive_date) : null,
    member_detail: formatMemberDetail,
  };

  return formatData;
};

export const customerTypeOptions: ISelectOption[] = [
  {
    label: "ลูกค้า",
    value: "customer",
  },
  {
    label: "ช่องทางการติดต่อลูกค้า",
    value: "customer_contact_channel",
  },
  {
    label: "บุคคลติดต่อลูกค้า",
    value: "customer_contact_person",
  },
  {
    label: "ที่อยู่ลูกค้า",
    value: "customer_address",
  },
  {
    label: "คุณสมบัติลูกค้า",
    value: "customer_attribute",
  },
];

export const vendorTypeOptions: ISelectOption[] = [
  {
    label: "ผู้ขาย",
    value: "vendor",
  },
  {
    label: "ช่องทางการติดต่อผู้ขาย",
    value: "vendor_contact_channel",
  },
  {
    label: "บุคคลติดต่อผู้ขาย",
    value: "vendor_contact_person",
  },
  {
    label: "ที่อยู่ผู้ขาย",
    value: "vendor_address",
  },
  {
    label: "คุณสมบัติผู้ขาย",
    value: "vendor_attribute",
  },
];
