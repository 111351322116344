import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSalesItemListPdfHeader } from "./columnDef";
import { ISalesItemList } from "../../../../types/Sales";
import { numberFormatter } from "../../../../utils/Formatter/Global";

type Props = {
  itemList: ISalesItemList[];
  startNumber?: number;
  lastTable?: boolean;
  totalAmount?: number;
  documentType?: string;
  isLastPage?: boolean;
  hasItems?: boolean;
};

const SalesItemListPdfTable = ({
  itemList,
  startNumber,
  lastTable,
  totalAmount,
  documentType,
  isLastPage,
  hasItems,
}: Props) => {
  const headers = useSalesItemListPdfHeader();

  const columnWidths = {
    itemNumber: "10%",
    itemName: "30%",
    qty: "10%",
    uom: "10%",
    price: "15%",
    discount: "10%",
    amount: "15%",
  };

  const tablePadding = {
    px: 1,
    py: 1,
  };
  const fontSize = 10.66;

  return (
    <Box>
      <TableContainer
        sx={{
          my: 1,
        }}
      >
        <Table sx={{ tableLayout: "fixed" }} aria-label="simple table">
          {(!isLastPage || hasItems) && (
            <TableHead
              sx={{
                backgroundColor: (theme) => theme.palette.primary.light,
              }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    width={header.width}
                    align={header.align || "center"}
                    sx={tablePadding}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {itemList.map((item, index) => (
              <TableRow key={item.unique_id}>
                <TableCell width={columnWidths.itemNumber}>
                  <Typography fontSize={fontSize}>
                    {startNumber ? startNumber + index : index + 1}
                  </Typography>
                </TableCell>
                <TableCell width={columnWidths.itemName}>
                  <Typography
                    fontSize={fontSize}
                    color={"primary.main"}
                    fontWeight={600}
                  >
                    {item.item_unique_id}
                  </Typography>
                  <Typography
                    fontSize={fontSize}
                    color={"primary.main"}
                    fontWeight={600}
                  >
                    {item.item_name}
                  </Typography>
                  <Typography
                    fontSize={fontSize}
                    sx={{
                      color: "#737373",
                      wordBreak: "break-word",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {item.item_sku_desc}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  width={columnWidths.qty}
                  sx={{ wordBreak: "break-word" }}
                >
                  <Typography fontSize={fontSize}>
                    {numberFormatter(item.qty)}
                  </Typography>
                </TableCell>
                <TableCell width={columnWidths.uom}>
                  <Typography fontSize={fontSize}>{item.uom_name}</Typography>
                </TableCell>
                <TableCell
                  align="right"
                  width={columnWidths.price}
                  sx={{ wordBreak: "break-word" }}
                >
                  <Typography fontSize={fontSize}>
                    {numberFormatter(item.price_per_unit)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  width={columnWidths.discount}
                  sx={{ wordBreak: "break-word" }}
                >
                  <Typography fontSize={fontSize}>
                    {numberFormatter(item.discount)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  width={columnWidths.amount}
                  sx={{ wordBreak: "break-word" }}
                >
                  <Typography fontSize={fontSize}>
                    {numberFormatter(item.pre_vat_amount)}
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {lastTable && (
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  height: "24px",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  backgroundColor: (theme) => theme.palette.primary.light,
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <TableCell
                  align="right"
                  sx={{
                    py: 0.5,
                    pr: 7,
                    wordBreak: "break-word",
                  }}
                >
                  <Typography
                    marginLeft={0.5}
                    fontSize={fontSize}
                    fontWeight={600}
                    color={"primary.main"}
                  >
                    รวม
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ py: 0.5, pr: 5, wordBreak: "break-word" }}
                >
                  <Typography
                    marginRight={2}
                    fontWeight={600}
                    fontSize={fontSize}
                    color={"primary.main"}
                  >
                    {numberFormatter(totalAmount)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default SalesItemListPdfTable;
