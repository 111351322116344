import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { usePurchaseOrderListPdfHeader } from "./columnDef";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { numberFormatter } from "../../../../utils/Formatter/Global";

type Props = {
  itemList: IPurchaseItemList[];
  startNumber: number;
  lastTable: boolean;
  totalAmount: number;
};

const PurchaseOrderListPdfTable = ({
  itemList,
  startNumber,
  lastTable,
  totalAmount,
}: Props) => {
  const headers = usePurchaseOrderListPdfHeader();

  const tablePadding = {
    px: 1,
    py: 1,
  };

  const fontSize = 10.66;

  return (
    <>
      <Box>
        <TableContainer
          sx={{
            my: 1,
          }}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item, index) => (
                <TableRow key={item.unique_id}>
                  <TableCell align="center">
                    <Typography fontSize={fontSize}>
                      {startNumber + index}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.item_unique_id}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.item_name}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        color: "#737373",
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.item_sku_desc}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.qty)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontSize={fontSize}>{item.uom_name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.price_per_unit)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.discount)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.pre_vat_amount)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {lastTable && (
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    height: "24px",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    backgroundColor: (theme) => theme.palette.primary.light,
                    borderRadius: "5px",
                    overflow: "hidden",
                  }}
                >
                  <TableCell
                    align="right"
                    sx={{
                      pr: 7,
                      py: 0.5,
                      borderBottom: "none",
                      wordBreak: "break-word",
                    }}
                  >
                    <Typography
                      marginLeft={0.5}
                      fontSize={fontSize}
                      fontWeight={600}
                      color={"primary.main"}
                    >
                      รวม
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      py: 0.5,
                      pr: 5,
                      borderBottom: "none",
                      wordBreak: "break-word",
                    }}
                  >
                    <Typography
                      marginRight={2}
                      fontWeight={600}
                      fontSize={fontSize}
                      color={"primary.main"}
                    >
                      {numberFormatter(totalAmount)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default PurchaseOrderListPdfTable;
