import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";
import { IExporter, ISelectOption } from "../../../types/global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import RadioBox from "../RadioBox";
import CustomizedButton from "../../Custom/CustomizedButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  typeOptions: ISelectOption[];
  exportHandler: (data: IExporter) => void;
};

const ExporterModal = ({
  open,
  closeModalHandler,
  typeOptions,
  exportHandler,
}: Props) => {
  const exporterSchena: IExporter = {
    type: typeOptions[0].value,
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  const exporterValidation = Yup.object().shape({
    type: Yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    start_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
    end_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IExporter>({
    defaultValues: exporterSchena,
    resolver: yupResolver<any>(exporterValidation),
  });

  const onClose = () => {
    reset(exporterSchena);
    closeModalHandler();
  };

  const watchExportType = watch("export_type");
  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.export")}
            variant="contained"
            onClick={() => {
              handleSubmit(exportHandler)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2}>
        เลือกประเภทข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12}>
          <ControlledSelect
            name="type"
            control={control}
            label={"ข้อมูล"}
            error={Boolean(errors.type)}
            helperText={errors.type?.message?.toString()}
            options={typeOptions}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.all")}
            onClick={() => {
              setValue("export_type", "all");
              setValue("start_date", null);
              setValue("end_date", null);
            }}
            checked={watchExportType === "all"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.date_range")}
            onClick={() => {
              setValue("export_type", "date_range");
            }}
            checked={watchExportType === "date_range"}
          />
        </Grid>
        {watchExportType === "date_range" && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="start_date"
                label={t("date.from")}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="end_date"
                required
                label={t("date.to")}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalUI>
  );
};

export default ExporterModal;
