import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Grid, Typography } from "@mui/material";

import { IItem } from "../../../../types/Inventory/item";

import TagList from "../../../UI/TagList";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useDisable } from "../../../../hooks/use-disable";

const Tag = () => {
  const { t } = useTranslation();

  const { control, getValues, setValue } = useFormContext<IItem>();

  const [disabled] = useDisable();

  return (
    <CustomizedBox margin="0 0 1.5rem 0">
      <Typography variant="h6" marginBottom={2}>
        {t("inventory.items.tag_list")}
      </Typography>
      <Grid container spacing={1.5}>
        <TagList
          control={control}
          name="tag_list"
          label={t("inventory.items.tag_list")}
          documentType={"item"}
          disabled={disabled}
          getValues={getValues}
          setValue={setValue}
        />
      </Grid>
    </CustomizedBox>
  );
};

export default Tag;
