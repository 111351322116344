import SalesOrderTable from "../../../components/Table/Sales/Order";

type Props = {
  referenceUniqueId?: string;
};

const SalesOrderTab = ({ referenceUniqueId }: Props) => {
  return <SalesOrderTable referenceUniqueId={referenceUniqueId} />;
};

export default SalesOrderTab;
