import { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import { Box, Grid, Typography } from "@mui/material";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IPurchaseReturn } from "../../../../types/Purchase/purchaseReturn";

import { useDisable } from "../../../../hooks/use-disable";

type Props = {
  forceDisabled?: boolean;
  documentType?: string;
};

const ReturnInfo = ({ forceDisabled, documentType }: Props) => {
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext<IPurchaseReturn>();

  return (
    <Fragment>
      <CustomizedBox margin={"1.5rem 0 0 0"}>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item xs={4} sm={3} md={2}>
            <Typography fontWeight={600} color={"primary.main"}>
              เหตุผลการส่งคืน
            </Typography>
          </Grid>
          <Box width={"100%"} />
          <Grid item xs={12} md={4}>
            <ControlledTextField
              label={"เหตุผลการส่งคืน"}
              name="reason_to_return"
              control={control}
              viewMode={disabled}
              disabled={disabled}
              error={Boolean(errors.reason_to_return)}
              helperText={errors.reason_to_return?.message}
              required
            />
          </Grid>
        </Grid>
      </CustomizedBox>
    </Fragment>
  );
};

export default ReturnInfo;
