import {
  ColDef,
  GridReadyEvent,
  ValueFormatterParams,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Date";
import AgGrid from "../../../UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { TRACE_ENTRY_REPORT_VIEW } from "../../../../services/AgGrid/Report/InventoryReportAggrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const TraceEntryReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("inventory.list.posted_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "source_branch_id",
      headerName: t("branch.unique_id") + "ต้นทาง",
      filter: "agNumberColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "source_branch_unique_id",
      headerName: t("branch.unique_id") + "ต้นทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "source_branch_name",
      headerName: t("branch.index") + "ต้นทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "source_warehouse_id",
      headerName: t("inventory.warehouseId") + "ต้นทาง",
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "source_warehouse_unique_id",
      headerName: t("inventory.warehouseId") + "ต้นทาง",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "source_warehouse_name",
      headerName: t("inventory.warehouse") + "ต้นทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "source_bin_location_id",
      headerName: t("inventory.locationId") + "ต้นทาง",
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "source_bin_location_name",
      headerName: t("inventory.location") + "ต้นทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_branch_id",
      headerName: t("branch.unique_id") + "ปลายทาง",
      filter: "agNumberColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "destination_branch_unique_id",
      headerName: t("branch.unique_id") + "ปลายทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_branch_name",
      headerName: t("branch.index") + "ปลายทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_warehouse_id",
      headerName: t("inventory.warehouseId") + "ปลายทาง",
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "destination_warehouse_unique_id",
      headerName: t("inventory.warehouseId") + "ปลายทาง",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "destination_warehouse_name",
      headerName: t("inventory.warehouse") + "ปลายทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_bin_location_id",
      headerName: t("inventory.locationId") + "ปลายทาง",
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "destination_bin_location_name",
      headerName: t("inventory.location") + "ปลายทาง",
      filter: "agTextColumnFilter",
    },
    {
      field: "item_unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "sku_name",
      headerName: t("inventory.items.sku"),
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "serial_no",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "qty",
      headerName: t("inventory.list.qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "uom",
      headerName: t("inventory.unit"),
      filter: "agTextColumnFilter",
    },
    {
      field: "type",
      headerName: t("inventory.stockEntry.entryDocumentType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "goods_receive",
          "goods_return",
          "goods_issue",
          "goods_transfer",
          "goods_adjust",
        ],
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === "goods_adjust")
            return t("inventory.goods_adjustment.index");
          return t(`inventory.${params.value}.index`);
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === "goods_adjust")
          return t("inventory.goods_adjustment.index");
        return t(`inventory.${params.value}.index`);
      },
    },
    {
      field: "reference_unique_id",
      headerName: t("sentence.reference_unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "created_by_name",
      headerName: t("inventory.items.transaction_by"),
      filter: "agTextColumnFilter",
    },
  ];

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { posted_date, created_date } = filterModel;

      const formatFilter = {
        ...filterModel,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
      };

      try {
        const { TraceEntryReportView } = await graphQLClient.request(
          TRACE_ENTRY_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: TraceEntryReportView.results,
          rowCount: TraceEntryReportView.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default TraceEntryReportTable;
