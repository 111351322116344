import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedSteppers from "../../../components/Custom/CustomizedStepper";
import UploadFile from "../../../components/Form/Inventory/Importer/UploadFile";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ItemImporterTable from "../../../components/Table/Inventory/Item/Importer";

import { ItemType } from "../../../generated/inventory";

import { useModal } from "../../../hooks/use-modal";
import { useNormalItemImporter } from "../../../hooks/Importer/Inventory/use-normal-item-importer";
import { useServiceItemImporter } from "../../../hooks/Importer/Inventory/use-service-item-importer";
import {
  useBundleItemImporter,
  useItemInBundleImporter,
} from "../../../hooks/Importer/Inventory/use-bundle-item-importer";
import {
  useItemImgImporter,
  useItemStockLimitImporter,
} from "../../../hooks/Importer/Inventory/use-item-importer";
import {
  useVariantValueImporter,
  useVariantItemImporter,
  useVariantSkuImporter,
} from "../../../hooks/Importer/Inventory/use-variant-item-importer";
import ImporterErrorModal from "../../../components/UI/Modal/ImporterErrorModal";

const ItemImporter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const [errorData, setErrorData] = useState<any[]>([]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: t("inventory.items.import.index"),
    },
  ];

  const steps = [
    t("inventory.items.import.upload_file"),
    t("inventory.items.import.validate_data"),
    t("inventory.items.import.import_data"),
  ];

  const tabs: ITab[] = [
    {
      label: t("status.success"),
      path: `${pathname}`,
    },
    {
      label: t("status.failed"),
      path: `${pathname}?filter=failed`,
    },
  ];

  const methods = useForm<any>({
    defaultValues: {
      item_type: ItemType.Normal,
      file_item_type: "default",
      type: "create",
      filePreview: null,
      file: {},
      step: 0,
      errors: [],
    },
  });

  const { getValues, watch, setValue } = methods;
  const activeStep = watch("step");
  const watchType = watch("type");
  const watchItemType = watch("item_type");
  const watchFileItemType = watch("file_item_type");

  const {
    rowData: normalData,
    validateHandler: validateNormal,
    importHandler: importNormal,
    isLoading: isLoadingNormal,
  } = useNormalItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: serviceData,
    validateHandler: validateService,
    importHandler: importService,
    isLoading: isLoadingService,
  } = useServiceItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: bundleData,
    validateHandler: validateBundle,
    importHandler: importBundle,
    isLoading: isLoadingBundle,
  } = useBundleItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: itemInBundleData,
    validateHandler: validateItemInBundle,
    importHandler: importItemInBundle,
    isLoading: isLoadingItemInBundle,
  } = useItemInBundleImporter(
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: variantData,
    validateHandler: validateVariant,
    importHandler: importVariant,
    isLoading: isLoadingVariant,
  } = useVariantItemImporter(
    watchType,
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: variantSkuData,
    validateHandler: validateVariantSku,
    importHandler: importVariantSku,
    isLoading: isLoadingVariantSku,
  } = useVariantSkuImporter(
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: variantValueData,
    validateHandler: validateVariantValue,
    importHandler: importVariantValue,
    isLoading: isLoadingVariantValue,
  } = useVariantValueImporter(
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const {
    rowData: imgData,
    validateHandler: validateImages,
    importHandler: importImages,
    isLoading: isLoadingImages,
  } = useItemImgImporter(getValues, setValue, setErrorData, openModalHandler);

  const {
    rowData: stockLimitData,
    validateHandler: validateStockLimit,
    importHandler: importStockLimit,
    isLoading: isLoadingStockLimit,
  } = useItemStockLimitImporter(
    getValues,
    setValue,
    setErrorData,
    openModalHandler
  );

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const backToAllItemHandler = () => {
    navigate("/inventory/items");
  };

  const rowDataCondition = () => {
    if (watchFileItemType === "img") return imgData;
    if (watchFileItemType === "update_stock") return stockLimitData;
    else {
      if (watchItemType === "normal") return normalData;
      else if (watchItemType === "service") return serviceData;
      else if (watchItemType === "variant") {
        if (watchFileItemType === "sku") return variantSkuData;
        if (watchFileItemType === "each_sku") return variantValueData;
        else return variantData;
      } else if (watchItemType === "bundle") {
        if (watchFileItemType === "bundle_items") return itemInBundleData;
        else return bundleData;
      } else return [];
    }
  };

  const validateCondition = () => {
    if (watchFileItemType === "img") return validateImages();
    if (watchFileItemType === "update_stock") return validateStockLimit();
    else {
      if (watchItemType === "normal") return validateNormal();
      else if (watchItemType === "service") return validateService();
      else if (watchItemType === "variant") {
        if (watchFileItemType === "sku") return validateVariantSku();
        if (watchFileItemType === "each_sku") return validateVariantValue();
        else return validateVariant();
      } else if (watchItemType === "bundle") {
        if (watchFileItemType === "bundle_items") return validateItemInBundle();
        else return validateBundle();
      }
    }
  };

  const importCondition = () => {
    if (watchFileItemType === "img") return importImages();
    if (watchFileItemType === "update_stock") return importStockLimit();
    else {
      if (watchItemType === "normal") return importNormal();
      else if (watchItemType === "service") return importService();
      else if (watchItemType === "variant") {
        if (watchFileItemType === "sku") return importVariantSku();
        if (watchFileItemType === "each_sku") return importVariantValue();
        else return importVariant();
      } else if (watchItemType === "bundle") {
        if (watchFileItemType === "bundle_items") return importItemInBundle();
        else return importBundle();
      }
    }
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <UploadFile />;
    } else {
      return (
        <>
          {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )}
          <ItemImporterTable
            data={rowDataCondition()}
            itemType={watchItemType}
            type={watchFileItemType}
          />
        </>
      );
    }
  };

  const renderButton = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateCondition}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importCondition}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูสินค้าทั้งหมด"
            variant="contained"
            onClick={backToAllItemHandler}
          />
        );
      default:
        return null;
    }
  };

  if (
    isLoadingNormal ||
    isLoadingService ||
    isLoadingBundle ||
    isLoadingImages ||
    isLoadingStockLimit ||
    isLoadingItemInBundle ||
    isLoadingVariant ||
    isLoadingVariantSku ||
    isLoadingVariantValue
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" my={2}>
        {t("inventory.items.import.index")}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box>
          {renderContent(activeStep)}
        </CustomizedBox>
        <BottomNavbar>
          <Stack direction={"row"} gap={1}>
            {renderButton(activeStep)}
          </Stack>
        </BottomNavbar>
      </FormProvider>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </Fragment>
  );
};

export default ItemImporter;
