import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";
import { RefObject, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/auth-context";
import { ExportGenerateInput } from "../../../generated/purchase";
import { PurchaseExportType } from "../../../generated/purchase";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { PURCHASE_EXPORT } from "../../../services/Purchase/PurchaseExport";

export const usePurchaseOrderReport = (
    gridRef: RefObject<AgGridReact<any>>,
    exportType: PurchaseExportType
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
  
    const graphQLClientWithHeaderPurchase: GraphQLClient =
      createGraphQLClientWithMiddleware("purchase");
  
    const {
      state: { authUser },
    } = useStateContext();
  
    const [exportInput, setExportInput] = useState<ExportGenerateInput | null>(
      null
    );
  
    const onBtnExport = () => {
      setIsLoading(true);
      try {
        const filterModel = gridRef?.current?.api.getFilterModel();
        const sortModel = gridRef?.current?.columnApi
          .getColumnState()
          .filter((s) => s.sort !== null)
          .map(({ sort, colId }) => ({
            sort,
            colId,
          }));
        setExportInput({
          export_type: exportType,
          user_unique_id: authUser?.unique_id || "",
          params: {
            aggridInput: {
              filterModel,
              sortModel,
            },
          },
        });
      } catch (err) {
        enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
          variant: "error",
        });
        setExportInput(null);
      }
    };
  
    const fetchDataAndOpenWindow = async () => {
      try {
        const { GenerateExport } = await graphQLClientWithHeaderPurchase.request(
          PURCHASE_EXPORT,
          { input: exportInput }
        );
        if (GenerateExport) {
          window.open(GenerateExport || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, { 
            variant: "success" 
          });
        } else {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, { 
            variant: "error" 
          });
          setExportInput(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, { 
          variant: "error" 
        });
        setExportInput(null);
      } finally {
        setIsLoading(false);
      }
    };
    
    useEffect(() => {
      if (exportInput !== null) {
        fetchDataAndOpenWindow();
      }
    }, [exportInput]);
    
  
    return {
      onBtnExport,
      isLoading: isLoading,
    };
  };
  