import * as Yup from "yup";
import dayjs from "dayjs";
import { itemListPRSchema } from "../../Sales/itemListSchema";
import { IPurchaseOrder } from "../../../../types/Purchase/purchaseOrder";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/purchase";

export const purchaseOrderSchema: IPurchaseOrder = {
  unique_id: "",
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  issue_date: dayjs(),
  expected_date: dayjs(),
  within_date: dayjs(),
  last_updated_by: null,
  created_date: dayjs(),
  created_by: null,
  reference_no: "",
  reference_document_list: [],
  reference_document_id: "",
  related_user_list: [],
  vendor_unique_id: "",
  vendor_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  pre_vat_amount: 0,
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  post_discount_amount: 0,
  vat_exempted_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  net_amount: 0,
  total_amount: 0,
  withholding_tax_amount: 0,
  remark: "",
  tag_list: [],
  item_list: [],
  approval_step: 0,
  approver_list: [],
  reviewer_list: [],
  price_vat_type: PriceVatType.ExcludeVat,
  currency: "THB",
};

export const purchaseOrderValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  vendor_details: Yup.object().shape({
    name: Yup.string().required("กรุณาเลือกผู้ขาย"),
    identity_no: Yup.string().required("กรุณาเลือกเลขประจำตัวผู้เสียภาษี"),
  }),
  item_list: Yup.array().of(itemListPRSchema).min(1, "กรุณาเพิ่มสินค้า"),
  branch_id: Yup.number().required("กรุณาเลือกสาขา"),
});
