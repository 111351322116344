import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { VENDORS_AGGRID } from "../../../services/AgGrid/ContactAgGrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import useContactColumnDefs from "../../Table/Contact/useContactColumnDefs";

type CheckedVendorModalProps = {
  showSelectVendor: boolean;
  closeVendorTable: () => void;
  finishVendorsSelect: (data: any) => void;
  vendorIds: string[];
  setVendorIds: Dispatch<SetStateAction<string[]>>;
  vendorIdsSnapshot: string[];
  setVendorIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single";
};

const CheckedVendorModal = ({
  showSelectVendor,
  closeVendorTable,
  finishVendorsSelect,
  vendorIds,
  setVendorIds,
  vendorIdsSnapshot,
  setVendorIdsSnapshot,
  rowSelection,
}: CheckedVendorModalProps) => {
  const { t } = useTranslation();
  const selectVendorGridRef = useRef();

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("crm");

  const columnDefs = useContactColumnDefs("vendor");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const formatFilter = {
        ...filterModel,
        status: {
          filterType: "set",
          values: [1],
        },
      };
      try {
        const { VendorsView } = await graphQLClient.request(VENDORS_AGGRID, {
          aggridInput: {
            startRow,
            endRow,
            filterModel: formatFilter,
            sortModel,
          },
        });

        console.log("VendorsView", VendorsView);
        params.success({
          rowData: VendorsView.results as any[],
          rowCount: VendorsView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={"ผู้ขาย"}
      btnTitle={t("button.add")}
      gridRef={selectVendorGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishVendorsSelect}
      modalIsOpen={showSelectVendor}
      getRowId={getRowId}
      closeModal={closeVendorTable}
      selectedIds={vendorIds}
      setSelectedIds={setVendorIds}
      idsSnapshot={vendorIdsSnapshot}
      setIdsSnapshot={setVendorIdsSnapshot}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedVendorModal;
