import { GraphQLClient } from "graphql-request";
import { useEffect, useState } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { IAddress, IBillingAddress, IDeliveryAddress } from "../types/global";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  CustomerFindUniqueQuery,
  useCustomerFindUniqueQuery,
} from "../generated/contact";
import { IContact } from "../types/Contact/contact";
import { ICustomer } from "../types/Sales";

const defaultDetails = {
  id: undefined,
  unique_id_name: "",
  name: "",
  phone: "",
  email: "",
  fax: "",
  identity_no: "",
  billing_address: {
    address_name: "",
    sub_district: "",
    district: "",
    province: "",
    postal_code: "",
    country: "",
  },
  delivery_address: {
    type: "",
    is_same_as_default_address: false,
    contact_name: "",
    contact_phone: "",
    address_name: "",
    sub_district: "",
    district: "",
    province: "",
    postal_code: "",
    country: "",
  },
};

export const useContactDescription = (
  module: "sales" | "purchase",
  documentType?: string
) => {
  const { control, getValues, setValue } = useFormContext();
  const contactUniqueId = useWatch({
    control,
    name: module === "sales" ? "customer_unique_id" : "vendor_unique_id",
  });

  const contact = useWatch({
    control,
    name: module === "sales" ? "customer_details" : "vendor_details",
  });

  const [openContactTable, setOpenContactTable] = useState<boolean>(false);

  const [contactIds, setContactIds] = useState<string[]>([]);
  const [contactIdsSnapshot, setContactIdsSnapshot] = useState<string[]>([]);

  const [openBillingAddress, setOpenBillingAddress] = useState<boolean>(false);
  const [billingAddressSnapshot, setBillingAddressSnapshot] =
    useState<IBillingAddress | null>({
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    });

  const [openDeliveryAddress, setOpenDeliveryAddress] =
    useState<boolean>(false);
  const [deliveryAddressSnapshot, setDeliveryAddressSnapshot] =
    useState<IDeliveryAddress | null>({
      address_name: "",
      contact_name: "",
      contact_phone: "",
      fax: "",
      type: "",
      country: "",
      district: "",
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
    });

  const [allAddressList, setAllAddressList] = useState<
    IAddress[] | null | undefined
  >([]);

  const [selectedContactId, setSelectedContactId] = useState<number>();

  // const graphQLClientWithHeaderCompany: GraphQLClient =
  //   createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  // const { refetch: companyRefetch, isLoading: companyIsLoading } =
  //   useCompanyQuery<CompanyQuery>(
  //     graphQLClientWithHeaderCompany,
  //     {
  //       uniqueInput: {
  //         unique_id: currentTenantId,
  //       },
  //     },
  //     {
  //       enabled: false,
  //     }
  //   );

  const { refetch: customerRefetch } =
    useCustomerFindUniqueQuery<CustomerFindUniqueQuery>(
      graphQLClientWithHeaderContact,
      {
        uniqueInput: {
          unique_id: contactUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const openContactTableHandler = async () => {
    setOpenContactTable(true);
  };

  const closeContactTableHandler = () => {
    setOpenContactTable(false);
  };

  const openBillingAddressHandler = () => {
    setOpenBillingAddress(true);
  };

  const closeBillingAddressHandler = () => {
    setOpenBillingAddress(false);
  };

  const openDeliveryAddressHandler = async () => {
    const { data } = await customerRefetch();
    const contactData = data?.CustomerFindUnique as IContact;
    const { address_list } = contactData;
    setAllAddressList(address_list);
    setOpenDeliveryAddress(true);
  };

  const closeDeliveryAddressHandler = () => {
    setOpenDeliveryAddress(false);
  };

  const finishContactsSelect = (data: IContact) => {
    if (data) {
      const {
        id,
        contact_channel_list,
        name,
        last_name,
        unique_id,
        address_list,
        taxpayer_id,
        credit_day,
        sales_person_list,
        contact_person_list,
      } = data;

      setSelectedContactId(id);

      const foundEmail =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "email"
        )?.contact_channel_info || "";

      const foundPhone =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "phone"
        )?.contact_channel_info || "";

      const foundFax =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "fax"
        )?.contact_channel_info || "";

      const foundBillingAddress = address_list?.find(
        (address) => address.type === "ที่อยู่จดทะเบียน"
      );

      const formatBillingAddress = foundBillingAddress
        ? {
            address_name: foundBillingAddress.address_name,
            sub_district: foundBillingAddress.sub_district,
            district: foundBillingAddress.district,
            province: foundBillingAddress.province,
            postal_code: foundBillingAddress.postal_code,
            country: foundBillingAddress.country,
          }
        : {
            address_name: "",
            sub_district: "",
            district: "",
            province: "",
            postal_code: "",
            country: "",
          };

      const foundDeliveryAddress = address_list?.find(
        (address) => address.type === "ที่อยู่จัดส่ง"
      );

      const formatDeliveryAddress = foundDeliveryAddress
        ? {
            type: foundDeliveryAddress.type,
            is_same_as_default_address:
              foundDeliveryAddress.is_same_as_default_address,
            contact_name: foundDeliveryAddress.contact_name,
            contact_phone: foundDeliveryAddress.contact_phone,
            fax: foundDeliveryAddress.fax,
            address_name: foundDeliveryAddress.address_name,
            sub_district: foundDeliveryAddress.sub_district,
            district: foundDeliveryAddress.district,
            province: foundDeliveryAddress.province,
            postal_code: foundDeliveryAddress.postal_code,
            country: foundDeliveryAddress.country,
          }
        : {
            type: "",
            is_same_as_default_address: false,
            contact_name: "",
            contact_phone: "",
            fax: "",
            address_name: "",
            sub_district: "",
            district: "",
            province: "",
            postal_code: "",
            country: "",
          };

      const customer_details: ICustomer = {
        id: id,
        unique_id_name: `${unique_id} - ${name}${
          last_name ? " " + last_name : ""
        }`,
        name: `${name}${last_name ? " " + last_name : ""}`,
        identity_no: taxpayer_id,
        email: foundEmail,
        phone: foundPhone,
        fax: foundFax,
        billing_address: formatBillingAddress,
        delivery_address: formatDeliveryAddress,
      };

      setAllAddressList(address_list);

      const customer_unique_id = unique_id;

      if (module === "sales") {
        const foundDeliveryAddress = address_list?.find(
          (address) => address.type === "ที่อยู่จัดส่ง"
        );

        const formatDeliveryAddress = foundDeliveryAddress
          ? {
              type: foundDeliveryAddress.type,
              is_same_as_default_address:
                foundDeliveryAddress.is_same_as_default_address,
              contact_name: foundDeliveryAddress.contact_name,
              contact_phone: foundDeliveryAddress.contact_phone,
              fax: foundDeliveryAddress.fax,
              address_name: foundDeliveryAddress.address_name,
              sub_district: foundDeliveryAddress.sub_district,
              district: foundDeliveryAddress.district,
              province: foundDeliveryAddress.province,
              postal_code: foundDeliveryAddress.postal_code,
              country: foundDeliveryAddress.country,
            }
          : {
              type: "",
              is_same_as_default_address: false,
              contact_name: "",
              contact_phone: "",
              fax: "",
              address_name: "",
              sub_district: "",
              district: "",
              province: "",
              postal_code: "",
              country: "",
            };

        const customer_details: ICustomer = {
          id: id,
          unique_id_name: `${unique_id} - ${name}${
            last_name ? " " + last_name : ""
          }`,
          name: `${name}${last_name ? " " + last_name : ""}`,
          identity_no: taxpayer_id,
          email: foundEmail,
          phone: foundPhone,
          fax: foundFax,
          billing_address: formatBillingAddress,
          delivery_address: formatDeliveryAddress,
        };

        setAllAddressList(address_list);

        const customer_unique_id = unique_id;

        const formatSalesPersonList = sales_person_list?.map(
          ({ unique_id, title_name, ...otherSales }) => ({
            ...otherSales,
            user_unique_id: unique_id,
          })
        );

        if (documentType === "customer_deposit") {
          // reset((prev) => ({
          //   ...prev,
          //   customer_details,
          //   customer_unique_id,
          //   related_user_list: formatSalesPersonList?.filter(
          //     (person) =>
          //       person.user_unique_id !== prev.created_by.user_unique_id
          //   ),
          // }));
          const createdBy = getValues("created_by");
          const relatedUserList = formatSalesPersonList?.filter(
            (person) => person.user_unique_id !== createdBy.user_unique_id
          );
          setValue("customer_details", customer_details);
          setValue("customer_unique_id", customer_unique_id);
          setValue("related_user_list", relatedUserList);
          if (contact_person_list && contact_person_list.length > 0) {
            setValue("contact_person_list", contact_person_list);
          }
        } else if (documentType === "cash_sales") {
          // reset((prev) => ({
          //   ...prev,
          //   customer_details,
          //   customer_unique_id,
          //   related_user_list: formatSalesPersonList?.filter(
          //     (person) =>
          //       person.user_unique_id !== prev.created_by.user_unique_id
          //   ),
          //   credit_day,
          //   due_date: prev?.due_date?.add(credit_day || 0, "days"),
          //   deduction_document_list:
          //     prev.deduction_document_list &&
          //     prev.deduction_document_list.length > 0
          //       ? [
          //           {
          //             ...prev.deduction_document_list[0],
          //             document_unique_id:
          //               prev.deduction_document_list[0].document_type ===
          //               "customer_deposit"
          //                 ? ""
          //                 : prev.deduction_document_list[0].document_unique_id,
          //             credit_balance: 0,
          //           },
          //         ]
          //       : [],
          // }));
          const createdBy = getValues("created_by");
          const currentDueDate = getValues("due_date");
          const currentDeductionDocumentList = getValues(
            "deduction_document_list"
          );
          const relatedUserList = formatSalesPersonList?.filter(
            (person) => person.user_unique_id !== createdBy.user_unique_id
          );

          const deduction_document_list =
            currentDeductionDocumentList &&
            currentDeductionDocumentList.length > 0
              ? [
                  {
                    ...currentDeductionDocumentList[0],
                    document_unique_id:
                      currentDeductionDocumentList[0].document_type ===
                      "customer_deposit"
                        ? ""
                        : currentDeductionDocumentList[0].document_unique_id,
                    credit_balance: 0,
                  },
                ]
              : [];

          setValue("customer_details", customer_details);
          setValue("customer_unique_id", customer_unique_id);
          setValue("related_user_list", relatedUserList);
          setValue("credit_day", credit_day);
          setValue("due_date", currentDueDate.add(credit_day || 0, "days"));
          setValue("deduction_document_list", deduction_document_list);
          if (contact_person_list && contact_person_list.length > 0) {
            setValue("contact_person_list", contact_person_list);
          }
        } else {
          // reset((prev) => ({
          //   ...prev,
          //   customer_details,
          //   customer_unique_id,
          //   related_user_list: formatSalesPersonList?.filter(
          //     (person) =>
          //       person.user_unique_id !== prev.created_by.user_unique_id
          //   ),
          //   credit_day,
          //   due_date: prev?.due_date?.add(credit_day || 0, "days"),
          // }));

          const createdBy = getValues("created_by");
          const currentDueDate = getValues("due_date");
          const relatedUserList = formatSalesPersonList?.filter(
            (person) => person.user_unique_id !== createdBy.user_unique_id
          );
          setValue("customer_details", customer_details);
          setValue("customer_unique_id", customer_unique_id);
          setValue("related_user_list", relatedUserList);
          setValue("credit_day", credit_day);
          setValue("due_date", currentDueDate.add(credit_day || 0, "days"));
          if (contact_person_list && contact_person_list.length > 0) {
            setValue("contact_person_list", contact_person_list);
          }
        }
      } else if (module === "purchase") {
        // reset((prev) => ({
        //   ...prev,
        //   vendor_details: customer_details,
        //   vendor_unique_id: customer_unique_id,
        // }));

        if (documentType !== "purchase_request") {
          setValue("vendor_details", customer_details);
          setValue("vendor_unique_id", customer_unique_id);
          setValue("credit_day", credit_day);
        } else {
          setValue("vendor_details", customer_details);
          setValue("vendor_unique_id", customer_unique_id);
        }
      }
      setBillingAddressSnapshot(formatBillingAddress);
    } else {
      if (module === "sales") {
        setValue("customer_details", defaultDetails);
        setValue("customer_unique_id", "");
        // reset((prev) => ({
        //   ...prev,
        //   customer_details: defaultDetails,
        //   customer_unique_id: null,
        // }));
      } else if (module === "purchase") {
        setValue("vendor_details", defaultDetails);
        setValue("vendor_unique_id", "");
      }
      setSelectedContactId(undefined);
      setAllAddressList([]);
      setBillingAddressSnapshot({
        address_name: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      });
    }
    closeContactTableHandler();
  };

  const openContactDetail = () => {
    if (module === "sales") {
      window.open(
        `/contact/customer/${selectedContactId}?tab=address`,
        "_blank"
      );
    }
    if (module === "purchase") {
      window.open(`/contact/vendor/${selectedContactId}?tab=address`, "_blank");
    }
  };

  useEffect(() => {
    if (contactUniqueId && contact) {
      setContactIds([contactUniqueId]);
      setContactIdsSnapshot([contactUniqueId]);
      setSelectedContactId(contact.id);
      // setBillingAddressSnapshot(contact.billing_address);
      // setDeliveryAddressSnapshot(contact.delivery_address);
    }
  }, [contact, contactUniqueId]);

  return {
    contact,
    contactUniqueId,
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openBillingAddress,
    billingAddressSnapshot,
    setBillingAddressSnapshot,
    openDeliveryAddress,
    deliveryAddressSnapshot,
    setDeliveryAddressSnapshot,
    allAddressList,
    openContactTableHandler,
    openBillingAddressHandler,
    closeBillingAddressHandler,
    openDeliveryAddressHandler,
    closeDeliveryAddressHandler,
    finishContactsSelect,
    selectedContactId,
    openContactDetail,
  };
};
