import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";

const PurchaseReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.report.purchase_order_by_vendor"),
      to: "purchase-order-by-vendor",
    },
    {
      name: t("purchase.report.purchase_order_by_item"),
      to: "purchase-order-by-item",
    },
    {
      name: t("purchase.report.purchase_order_by_branch"),
      to: "purchase-order-by-branch",
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default PurchaseReport;
