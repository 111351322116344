import { Box, Typography, Radio } from "@mui/material";

type Props = {
  label: string;
  checked: boolean;
  small?: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const RadioBox = ({ checked, label, onClick, small, disabled }: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={2}
      py={small ? undefined : 1}
      border={`1px solid ${checked ? "#324290" : "#d6cfcf"} `}
      borderRadius="14px"
      sx={{
        cursor: disabled ? "" : "pointer",
        bgcolor: checked ? "#EFF2FF" : "inherit",
      }}
      onClick={disabled ? () => {} : onClick}
    >
      <Typography color={checked ? "#324290" : "rgba(0, 0, 0, 0.6)"}>
        {label}
      </Typography>
      <Radio checked={checked} disabled={disabled} />
    </Box>
  );
};

export default RadioBox;
