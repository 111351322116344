import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";

const Setting = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.zone.index"),
      to: "/setting/zone",
    },
    {
      name: t("branch.info"),
      to: "/setting/branch",
    },
    {
      name: t("setting.company.index"),
      to: "/setting/company",
    },
    {
      name: t("setting.approval.index"),
      to: "/setting/approval",
    },
    {
      name: t("setting.contact.index"),
      to: "/setting/contact",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.sales.index"),
      to: "/setting/sales",
    },
    {
      name: t("setting.employee.index"),
      to: "/setting/employee",
    },
    {
      name: t("setting.logistic.index"),
      to: "/setting/logistic",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Setting;
