import { Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useFormContext } from "react-hook-form";
import { IBranch } from "../../../types/Setting/Branch";
import { useTranslation } from "react-i18next";
import { useDisable } from "../../../hooks/use-disable";
import ControlledSelect from "../../Controller/ControlledSelect";
import LabelInput from "../../UI/LabelInput";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";
import { countryOptions } from "../../../utils/Global";
import { countryFormatter } from "../../../utils/Formatter/Global";
import { useEffect, useState } from "react";

interface Props {
  address: any;
}

const BranchAddress = ({ address }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<IBranch>();

  const [disabled] = useDisable();

  const [addressValue, setAddressValue] = useState<any>("");

  useEffect(() => {
    if (disabled) {
      setAddressValue(address);
    }
  }, [address, disabled]);

  const clearErrorsHandler = () => {
    clearErrors([
      "address.sub_district",
      "address.district",
      "address.province",
      "address.postal_code",
      "address.country",
    ]);
  };

  return (
    <CustomizedBox margin={0}>
      <Typography fontWeight={600} color="primary.main" mb={2}>
        {t("contact.address.index")}
      </Typography>
      <CustomizedBox bgcolor="#EFF2FF">
        <Typography fontWeight={600} color="primary.main" mb={2}>
          ที่อยู่จดทะเบียน
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ControlledTextField
              name={`address.address_name`}
              control={control}
              label={t("contact.address.index")}
              error={Boolean(errors.address?.address_name)}
              helperText={
                errors.address && errors.address.address_name?.message
              }
              rows={4}
              multiline
              disabled={disabled}
              viewMode={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address`}
              type="sub_district"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={disabled}
              error={Boolean(errors.address?.sub_district)}
              helperText={
                errors.address && errors.address.sub_district?.message
              }
              clearErrorsHandler={clearErrorsHandler}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address`}
              type="district"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={disabled}
              error={Boolean(errors.address?.district)}
              helperText={errors.address && errors.address.district?.message}
              clearErrorsHandler={clearErrorsHandler}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address`}
              type="province"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={disabled}
              error={Boolean(errors.address?.province)}
              helperText={errors.address && errors.address.province?.message}
              clearErrorsHandler={clearErrorsHandler}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledAddressBox
              name={`address`}
              type="postal_code"
              getValues={getValues}
              setValue={setValue}
              addressValue={addressValue}
              setAddressValue={setAddressValue}
              viewMode={disabled}
              error={Boolean(errors.address?.postal_code)}
              helperText={errors.address && errors.address.postal_code?.message}
              clearErrorsHandler={clearErrorsHandler}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {disabled ? (
              <LabelInput
                label={t("contact.address.country")}
                value={countryFormatter(getValues("address.country"))}
              />
            ) : (
              <ControlledSelect
                name={`address.country`}
                control={control}
                label={t("contact.address.country")}
                error={Boolean(errors.address?.country)}
                helperText={errors.address && errors.address.country?.message}
                options={countryOptions}
                disabled={true}
              />
            )}
          </Grid>
        </Grid>
      </CustomizedBox>
      <ControlledTextField
        label={disabled ? t("sentence.detail") : undefined}
        control={control}
        name="address.description"
        placeholder={t("sentence.detail")}
        rows={4}
        multiline
        disabled={disabled}
        viewMode={disabled}
      />
    </CustomizedBox>
  );
};

export default BranchAddress;
