import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ButtonInfo } from "../../types/Inventory/GoodsTransfer";

export const useTransferButtonByStatus = (
  isApprove: boolean,
  isHaveApproval?: boolean
) => {
  const { t } = useTranslation();
  const statusButtonInfo = useCallback(
    (status?: string): ButtonInfo[] => {
      switch (status) {
        case "draft":
          return [
            {
              variant: "outlined",
              title: t("button.save_draft"),
              next: "draft",
            },
            {
              variant: "contained",
              title: isApprove ? t("button.send") : t("button.next"),
              next: isApprove ? "wait_approve" : "wait_transfer",
            },
          ];
        case "wait_approve":
          if (isHaveApproval) {
            return [
              {
                variant: "outlined",
                title: t("button.save"),
                next: undefined,
              },
              {
                variant: "outlined",
                title: t("button.not_approve"),
                next: "not_approved",
              },
              {
                variant: "contained",
                title: t("button.approve"),
                next: "wait_transfer",
              },
            ];
          } else {
            return [
              {
                variant: "outlined",
                title: t("button.save"),
                next: undefined,
              },
            ];
          }
        case "not_approved":
          return [
            {
              variant: "outlined",
              title: t("button.save"),
              next: undefined,
            },
            {
              variant: "contained",
              title: t("button.send"),
              next: "wait_approve",
            },
          ];
        case "wait_transfer":
          return [
            {
              variant: "outlined",
              title: t("button.save"),
              next: "wait_transfer",
            },
            {
              variant: "outlined",
              title: t("button.in_transit"),
              next: "in_transit",
            },
            {
              variant: "contained",
              title: t("button.transfer"),
              next: "finished",
            },
          ];
        case "in_transit":
          return [
            {
              variant: "outlined",
              title: t("button.save"),
              next: "in_transit",
            },
            {
              variant: "contained",
              title: t("button.transfer"),
              next: "finished",
            },
          ];
        case "finished":
        case "cancelled":
          return [];
        default:
          return [
            {
              variant: "outlined",
              title: t("button.save_draft"),
              next: "draft",
            },
            {
              variant: "contained",
              title: isApprove ? t("button.send") : t("button.next"),
              next: isApprove ? "wait_approve" : "wait_transfer",
            },
          ];
      }
    },
    [isApprove, isHaveApproval, t]
  );

  return statusButtonInfo;
};
