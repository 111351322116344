import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "../types/Auth/user";
import CustomizedAvatar from "../components/Custom/CustomizedAvatar";

export const useReviewerColumn = (
  removeReviewerHandler: (index: number | null) => void
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    const defaultColumns: ColDef[] = [
      {
        field: "unique_id",
        headerName: t("user.account.id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "img_url",
        headerName: t("user.account.img_url"),
        filter: false,
        floatingFilter: false,
        cellRenderer: (params: ICellRendererParams<IUser, string[]>) => {
          if (params.value && params.value.length) {
            return (
              <CustomizedAvatar
                avatars={params.value.map((v) => ({
                  img_url: v,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.first_name ?? "",
                  last_name: params?.data?.last_name ?? "",
                }))}
              />
            );
          } else
            return (
              <CustomizedAvatar
                avatars={[
                  {
                    img_url: undefined,
                    unique_id: params?.data?.unique_id ?? "",
                    first_name: params?.data?.first_name ?? "",
                    last_name: params?.data?.last_name ?? "",
                  },
                ]}
              />
            );
        },
        minWidth: 200,
        // flex: 1,
      },
      {
        field: "first_name",
        headerName: t("user.account.first_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "last_name",
        headerName: t("user.account.last_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "department",
        headerName: t("user.account.department"),
        filter: "agTextColumnFilter",
      },
      {
        field: "position",
        headerName: t("user.account.position"),
        filter: "agTextColumnFilter",
      },
      {
        field: "branch_name",
        headerName: t("branch.index"),
        filter: "agTextColumnFilter",
      },
      // {
      //   field: "",
      //   cellRenderer: (params: ICellRendererParams) => (
      //     <IconButton
      //       aria-label="delete"
      //       size="small"
      //       sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
      //       onClick={() => removeReviewerHandler(params.node.rowIndex)}
      //     >
      //       <CloseIcon fontSize="small" />
      //     </IconButton>
      //   ),
      // },
    ];
    setColumnDefs(defaultColumns);
  }, [removeReviewerHandler, t]);
  return columnDefs;
};
