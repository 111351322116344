import { IApproval } from "../../../types/global";
import * as Yup from "yup";

export const approvalSchema: IApproval = {
  is_have_approval: false,
  approval_templates: [],
};

const approvalTemplateValidation = Yup.object().shape({
  approver_list: Yup.array().min(1, "กรุณาเลือกผู้อนุมัติ"),
});

export const approvalValidation = Yup.object().shape({
  is_have_approval: Yup.boolean(),
  approval_templates: Yup.array().when("is_have_approval", {
    is: true,
    then: (schema) =>
      schema.of(approvalTemplateValidation).min(1, "กรุณาเพิ่มลำดับ"),
    otherwise: (schema) => schema,
  }),
});
