import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { useModal } from "../../../../hooks/use-modal";
import BillingAddressModal from "../../../UI/Modal/BillingAddressModal";
import DeliveryAddressModal from "../../../UI/Modal/DeliveryAddressModal";
import { countryFormatter } from "../../../../utils/Formatter/Global";
import { useDisable } from "../../../../hooks/use-disable";

type Props = {
  forceDisabled?: boolean;
  documentType?: string;
};

const AddressInfo = ({ forceDisabled, documentType }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const contactUniqueId = watch("customer_unique_id");
  const needTaxInvoice = watch("need_tax_invoice");

  const {
    modal: openBillingAddress,
    openModalHandler: openBillingAddressHandler,
    closeModalHandler: closeBillingAddress,
  } = useModal();

  const {
    modal: openDeliveryAddress,
    openModalHandler: openDeliveryAddressHandler,
    closeModalHandler: closeDeliveryAddress,
  } = useModal();

  const renderAddressString = (values: string) => {
    const addressValues = getValues(`customer_details[${values}]`);
    if (addressValues) {
      const {
        address_name,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = addressValues;

      const orderedValues = {
        address_name,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            let newValue = "";
            if (key === "country") {
              newValue =
                countryFormatter(
                  orderedValues[key as keyof typeof orderedValues]
                ) || "";
            } else {
              newValue = orderedValues[key as keyof typeof orderedValues];
            }

            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "-";
  };

  const renderAddressContact = () => {
    const addressValues = getValues("customer_details.delivery_address");

    if (addressValues) {
      const { contact_name, contact_phone } = addressValues;

      if (contact_name && contact_phone) {
        const result = `${contact_name}, ${contact_phone}`;
        return result;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const isError = Boolean(errors?.customer_details?.root);

  return (
    <Fragment>
      <CustomizedBox margin={0} borderColor={isError ? "#F44336" : undefined}>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item xs={4} sm={3} md={2}>
            <Box display="flex" alignItems={"center"} gap={1}>
              <Typography
                fontWeight={600}
                color={isError ? "error.main" : "primary.main"}
              >
                {t("address.billing_address")}
              </Typography>
              {(documentType === "customer_deposit" || needTaxInvoice) && (
                <Typography fontWeight={600} color={"error.main"}>
                  *
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            {!disabled && !forceDisabled && contactUniqueId ? (
              <CustomizedTooltip title={`แก้ไข${t("address.billing_address")}`}>
                <IconButton
                  onClick={openBillingAddressHandler}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  <EditIcon />
                </IconButton>
              </CustomizedTooltip>
            ) : null}
          </Grid>
        </Grid>
        <Typography my={2}>{renderAddressString("billing_address")}</Typography>
        {documentType !== "customer_deposit" && (
          <>
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xs={4} sm={3} md={2}>
                <Typography fontWeight={600} color={"primary.main"}>
                  {t("address.delivery_address")}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                {!disabled && !forceDisabled && contactUniqueId ? (
                  <CustomizedTooltip
                    title={`แก้ไข${t("address.delivery_address")}`}
                  >
                    <IconButton
                      onClick={openDeliveryAddressHandler}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </CustomizedTooltip>
                ) : null}
              </Grid>
            </Grid>
            <Typography mt={2}>{renderAddressContact()}</Typography>
            <Typography>{renderAddressString("delivery_address")}</Typography>
          </>
        )}
      </CustomizedBox>
      <BillingAddressModal
        name="customer_details"
        open={openBillingAddress}
        handleClose={closeBillingAddress}
        viewMode={false}
      />
      <DeliveryAddressModal
        name="customer_details"
        open={openDeliveryAddress}
        handleClose={closeDeliveryAddress}
        viewMode={false}
      />
    </Fragment>
  );
};

export default AddressInfo;
