import { useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IQuotation } from "../../../types/Sales/quotation";
import {
  quotationSchema,
  quotationValidation,
} from "../../../components/Form/Sales/Quotation/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActivityLogDocumentType,
  QuotationFindUniqueQuery,
  useQuotationFindUniqueQuery,
} from "../../../generated/sales";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import DocumentInfoTab from "./DocumentInfoTab";
import SalesOrderTab from "./SalesOrderTab";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const QuotationContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const { id } = useParams();

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: t("sales.order.index"),
      path: `${pathname}?tab=sales_order`,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } =
    useQuotationFindUniqueQuery<QuotationFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "sales_order":
        return (
          <SalesOrderTab
            referenceUniqueId={data?.QuotationFindUnique.unique_id}
          />
        );
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const branch_id = sessionStorage.getItem("branch-id");

  const methods = useForm<IQuotation>({
    defaultValues: {
      ...quotationSchema,
      branch_id: branch_id ? parseInt(branch_id) : undefined,
    },
    resolver: yupResolver<any>(quotationValidation),
  });

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
    },
    {
      name: id
        ? data?.QuotationFindUnique.unique_id || "-"
        : t("button.create") + t("sales.quotation.index"),
    },
  ];

  const status = data?.QuotationFindUnique.aggrid_status;

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      {(status === "accepted" || status === "finished") && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.Quotation}
        />
      )}
    </FormProvider>
  );
};

export default QuotationContainer;
