import SalesInvoiceTable from "../../../components/Table/Sales/Invoice";

type Props = {
  referenceUniqueId?: string;
};

const SalesInvoiceTab = ({ referenceUniqueId }: Props) => {
  return <SalesInvoiceTable referenceUniqueId={referenceUniqueId} />;
};

export default SalesInvoiceTab;
