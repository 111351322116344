import { Dispatch, SetStateAction, useState } from "react";
import { useSnackbar } from "notistack";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

import {
  InventoryImportMode,
  useImportItemImagesMutation,
  useImportItemStockLimitMutation,
  useValidateItemImportQuery,
} from "../../../generated/inventory";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
  mapNestedData,
} from "../../../utils/Importer";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useStateContext } from "../../../contexts/auth-context";
import { formatNumber, formatString } from "../../../utils/Global";
import { IImporterError } from "../../../types/global";

const imgColumns = ["unique_id", "img_url"];

const stockColumns = [
  "unique_id",
  "sku_name",
  "branch_unique_id",
  "warehouse_unique_id",
  "max_stock_qty",
  "min_stock_qty",
];

const requiredKeys = ["unique_id"];

const count_limit = 50000;

export const useItemImgImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    item_unique_id_list: string[];
  }>({
    import_mode: InventoryImportMode.Update,
    item_unique_id_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportItemImagesMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = imgColumns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];
      const formattedDataSnapshot = [...data];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (
            !formattedDataSnapshot[i][requiredKeys[j]] &&
            formattedDataSnapshot[i][requiredKeys[j]] !== 0
          ) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j],
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const formattedData = data.reduce((result: any, item: any) => {
        if (item.unique_id) {
          const existingGroup = result.find(
            (group: any) => group.item_unique_id === item.unique_id
          );

          if (existingGroup) {
            existingGroup.img_url.push(formatString(item.img_url));
          } else {
            result.push({
              item_unique_id: item.unique_id,
              img_url: [formatString(item.img_url)],
            });
          }
        }

        return result;
      }, []);

      setErrorData(errorData);

      if (requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const missingUniqueId =
        data?.ValidateItemImport.missing_item_unique_id_list || [];

      let errorData: IImporterError[] = [];
      const arrays = [missingUniqueId];
      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสสินค้า",
        missingUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        //TODO: create many
        await importItems({
          importInput: {
            data: rowData,
            import_mode: InventoryImportMode.Update,
            priority: 1,
            user_unique_id: authUser?.unique_id || "",
          },
        });
        enqueueSnackbar("นำเข้าสำเร็จ", {
          variant: "success",
        });
        setValue("step", 3);
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData: any[] = await formatItemdata(tempDoc);

      const validateInput = {
        import_mode: InventoryImportMode.Update,
        item_unique_id_list: formattedData
          .map((data) => data.unique_id)
          .filter((uniqueId) => uniqueId !== null && uniqueId !== undefined),
      };

      setValidateInput(validateInput);

      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler,
  };
};

export const useItemStockLimitImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    item_unique_id_list: string[];
    sku_name_list: string[];
  }>({
    import_mode: InventoryImportMode.Update,
    item_unique_id_list: [],
    sku_name_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportItemStockLimitMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = stockColumns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!data[i][requiredKeys[j]] && data[i][requiredKeys[j]] !== 0) {
            requiredFieldErrors.push({
              unique_id: data[i].unique_id,
              type: "required",
              field: requiredKeys[j],
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const { uniqueIdList, skuList } = data.reduce(
        (lists: any, item: any) => {
          if (item.seller_unique_id) {
            lists.uniqueIdList.push(item.seller_unique_id);
          }
          if (item.sku_name) {
            lists.skuList.push(item.sku_name);
          }

          return lists;
        },
        {
          uniqueIdList: [] as string[],
          skuList: [] as string[],
        }
      );

      const formattedData = data.map((item: any, index: number) => {
        return {
          sku_name: formatString(item.sku_name),
          item_unique_id: formatString(item.item_unique_id),
          branch_unique_id: formatString(item.branch_unique_id),
          warehouse_unique_id: formatString(item.warehouse_unique_id),
          max_stock_qty: formatNumber(item.max_stock_qty),
          min_stock_qty: formatNumber(item.min_stock_qty),
        };
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const duplicateSku = findDuplicatesWithList(skuList);

      const allError = [duplicateUniqueId, duplicateSku];

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength || requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const missingUniqueId =
        data?.ValidateItemImport.missing_item_unique_id_list || [];
      const missingBranchUniqueId =
        data?.ValidateItemImport.missing_branch_unique_id_list || [];
      const missingWarehouseUniqueId =
        data?.ValidateItemImport.missing_warehouse_unique_id_list || [];

      let errorData: IImporterError[] = [];

      const arrays = [
        missingUniqueId,
        missingBranchUniqueId,
        missingWarehouseUniqueId,
      ];
      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสสินค้า",
        missingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "สาขา",
        missingBranchUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "คลัง",
        missingWarehouseUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await importItems({
            importInput: {
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (error) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData: any[] = await formatItemdata(tempDoc);

      const validateInput = {
        import_mode: InventoryImportMode.Update,
        item_unique_id_list: formattedData
          .map((data) => data.unique_id)
          .filter((unique_id) => unique_id !== null && unique_id !== undefined),
        sku_name_list: formattedData
          .map((data) => data.sku_name)
          .filter((skuName) => skuName !== null && skuName !== undefined),
      };

      setValidateInput(validateInput);
      if (formattedData.length > count_limit) {
        enqueueSnackbar(
          `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
          {
            variant: "error",
          }
        );
      } else {
        setRowData(formattedData);
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
        setValue("step", 1);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }

    //use validateInput to call api ValidateItemImport or ... for other modules
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler,
  };
};
