import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { Box, Typography } from "@mui/material";
import LoadingUI from "../../../components/UI/LoadingUI";
import GoodsReceiveTable from "../../../components/Table/Inventory/GoodsReceive";

import { IGoodsReceive } from "../../../types/Inventory/GoodsReceive";

type Props = {
  referenceUniqueId?: string;
  isLoading?: boolean;
};

const GoodsReceiveTab = ({ referenceUniqueId, isLoading }: Props) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IGoodsReceive>>(null);

  if (isLoading) return <LoadingUI />;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">
          {t("inventory.goods_receive.index")}
        </Typography>
      </Box>
      <GoodsReceiveTable
        gridRef={gridRef}
        referenceUniqueId={referenceUniqueId}
      />
    </>
  );
};

export default GoodsReceiveTab;
