import { IBranch } from "../../types/Setting/Branch";

export const branchCreateFormatter = (data: IBranch) => {
  const { status, ...otherData } = data;
  return {
    ...otherData,
    status: status ? 1 : 0,
  };
};

export const branchUpdateFormatter = (data: IBranch) => {
  const { id, status, ...otherData } = data;
  return { ...otherData, status: status ? 1 : 0 };
};
