import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import { Fragment } from "react";

const ApprovalSetting = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.approval.index") },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.approval.inventory"),
      to: "/setting/approval/inventory?tab=goods_issue",
    },
    {
      name: t("setting.approval.sales"),
      to: "/setting/approval/sales?tab=quotation",
    },
    {
      name: t("setting.approval.purchase"),
      to: "/setting/approval/purchase?tab=purchase_request",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default ApprovalSetting;
