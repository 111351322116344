import { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActivityLogDocumentType,
  SalesInvoiceFindUniqueQuery,
  useSalesInvoiceFindUniqueQuery,
} from "../../../generated/sales";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import DocumentInfoTab from "./DocumentInfoTab";
import PaymentTab from "./PaymentTab";
import {
  salesInvoiceSchema,
  salesInvoiceValidation,
} from "../../../components/Form/Sales/Invoice/schema";
import { ISalesInvoice } from "../../../types/Sales/salesInvoice";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../types/Sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { salesInvoiceQueryFormatter } from "../../../utils/Formatter/Sales/SalesInvoice";

const SalesInvoiceContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess } =
    useSalesInvoiceFindUniqueQuery<SalesInvoiceFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: "รับชำระ",
      path: `${pathname}?tab=payment`,
    },
  ];

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const branch_id = sessionStorage.getItem("branch-id");

  const methods = useForm<ISalesInvoice>({
    defaultValues: {
      ...salesInvoiceSchema,
      branch_id: branch_id ? parseInt(branch_id) : undefined,
    },
    resolver: yupResolver<any>(salesInvoiceValidation),
  });

  const { control, reset } = methods;

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { SalesInvoiceFindUnique } = data;

      const getSalesInvoiceData = async () => {
        const formattedSalesInvoice = await salesInvoiceQueryFormatter(
          SalesInvoiceFindUnique
        );
        reset(formattedSalesInvoice);
        setIsLoadingData(false);
      };
      getSalesInvoiceData();
    }
  }, [data, isSuccess, reset]);

  const { fields: paymentList, append: appendPayment } = useFieldArray({
    control,
    name: "payment_list",
  });

  const { fields: deductionList, append: appendDeduction } = useFieldArray({
    control,
    name: "deduction_document_list",
  });

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.invoice.index"),
      to: "/sales/invoice",
    },
    {
      name: id
        ? data?.SalesInvoiceFindUnique.unique_id || "-"
        : t("button.create") + t("sales.invoice.index"),
    },
  ];

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const isShowTab = [
    "wait_payment",
    "partially_payment",
    "fully_payment",
    "finished",
  ].includes(status || "");

  const addPaymentHandler = (data: ISalesPaymentChannel) => {
    appendPayment(data);
  };

  const addDeductionHandler = (data: ISalesDeduction) => {
    appendDeduction(data);
  };

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "payment":
        return (
          <PaymentTab paymentList={paymentList} deductionList={deductionList} />
        );
      default:
        return (
          <DocumentInfoTab
            addPaymentHandler={addPaymentHandler}
            addDeductionHandler={addDeductionHandler}
          />
        );
    }
  };

  if (id && (isLoadingData || isLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      {isShowTab && <CustomizedTab tabs={tabs} currentTab={currentTab} />}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.SalesInvoice}
        />
      )}
    </FormProvider>
  );
};

export default SalesInvoiceContainer;
