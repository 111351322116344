import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { GraphQLClient } from "graphql-request";

import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import { IApprovalTemplate, IReviewedBy } from "../../../types/global";
import {
  PurchaseDocumentType,
  PurchaseOrderFindUniqueQuery,
} from "../../../generated/purchase";

import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IPurchaseItemList } from "../../../types/Purchase";
import { IUser } from "../../../types/Auth/user";
import { IGoodsReceive } from "../../../types/Inventory/GoodsReceive";
import { ITraceEntry } from "../../../types/Inventory";
import {
  InventoryDocumentType,
  Tracability,
} from "../../../generated/inventory";
import { purchaseReturnSchema } from "../../../components/Form/Purchase/Return/schema";
import { formatAuthToUserPayload } from "../User";

export const purchaseOrderCreatePayloadFormatter = (
  data: IPurchaseOrder,
  status: string,
  approval_step?: number,
  reviewed_by?: IReviewedBy
) => {
  const { item_list, tag_list, created_date, last_updated_by, ...otherData } =
    data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];
  const formatItemList = item_list.map(
    ({
      uom_group,
      item_sku_qty,
      branch_id,
      pr_reference_unique_id,
      ...otherItem
    }) => ({
      ...otherItem,
      reference_unique_id: otherData.unique_id,
      reference_document_type: PurchaseDocumentType.PurchaseOrder,
    })
  );

  const formatPayload = {
    ...otherData,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    approval_step: approval_step ? 1 : 0,
    reviewer_list: reviewed_by ? [reviewed_by] : [],
  };
  return formatPayload;
};

export const purchaseOrderUpdatePayloadFormatter = async (
  data: IPurchaseOrder,
  status: string,
  approval_step?: number,
  approver_list?: IApprovalTemplate[],
  isNotApprove?: boolean,
  reviewed_by?: IReviewedBy
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    branch,
    reviewer_list,
    destination_warehouse,
    ...otherData
  } = data;

  const formatItemList = item_list.map(
    ({
      uom_group,
      item_sku_qty,
      branch_id,
      pr_reference_unique_id,
      ...otherItem
    }) => ({
      ...otherItem,
      reference_unique_id: unique_id,
      reference_document_type: PurchaseDocumentType.PurchaseOrder,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formatFlag =
    !isNotApprove && status === "wait_approve"
      ? flag_status?.filter((flag) => flag !== "not_approved")
      : flag_status;

  const formatPayload = {
    ...otherData,
    approval_step: approval_step ? approval_step : 0,
    approver_list:
      approver_list && approver_list.length > 0
        ? approver_list
        : data.approver_list,
    reviewer_list: reviewer_list
      ? (data.aggrid_status === "wait_approve" ||
          (data.aggrid_status === "draft" &&
            approver_list &&
            approver_list.length === 0)) &&
        reviewed_by
        ? !isNotApprove
          ? [...reviewer_list, reviewed_by]
          : [...reviewer_list]
        : data.aggrid_status === "not_approved" && status === "wait_approve"
        ? []
        : [...reviewer_list]
      : [],
    item_list: formatItemList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    flag_status: formatFlag,
  };
  return formatPayload;
};

export const purchaseOrderQueryFormatter = async (
  data: PurchaseOrderFindUniqueQuery["PurchaseOrderFindUnique"]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const allItemListUniqueId =
    data.item_list && data.item_list.length > 0
      ? data.item_list.map((item) => item?.item_sku_name)
      : [];

  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });

  const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item?.item_sku_name
      );

      const itemType = item as IPurchaseItemList;

      formatItemList.push({
        ...itemType,
        uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
      });
    });
  }

  const formatPayload = {
    ...data,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    within_date: data.within_date ? dayjs(data.within_date) : undefined,
    expected_date: data.expected_date ? dayjs(data.expected_date) : undefined,
  } as IPurchaseOrder;

  return formatPayload;
};

export const copyPurchaseOrderFormatter = (
  data: IPurchaseOrder,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      within_date,
      expected_date,
      created_by,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      branch,
      approval_step,
      approver_list,
      reviewer_list,
      destination_warehouse,
      related_user_list,
      reference_document_id,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(({ id, unique_id, po_qty, ...otherItemList }) => ({
            ...otherItemList,
            unique_id: uuidv4(),
          }))
        : [];

    const formatRelatedUserList =
      created_by?.user_unique_id !== currentUser?.unique_id
        ? [created_by, ...(related_user_list || [])]
        : related_user_list;

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
      related_user_list: formatRelatedUserList,
      delivery_address: {},
    };
  }
};

export const formatPurchaseItemListToGoodsReceiveItemList = (
  data: IPurchaseOrder
): ITraceEntry[] => {
  const formatData: ITraceEntry[] = data.item_list.map((item) => ({
    unique_id: uuidv4(),
    reference_unique_id: "",
    is_stockable: true,
    type: InventoryDocumentType.GoodsReceive,
    item_unique_id: item.item_unique_id || "",
    sku_name: item.item_sku_name || "",
    item_name: item.item_name || "",
    item_img_url: item.item_img_url || [],
    qty: 0,
    document_item_qty: item.qty,
    posted_qty: item.po_qty,
    uom: { id: item.uom_id, name_th: item.uom_name },
    cost_price: item.price_per_unit,
    remark: "",
    po_reference_unique_id: data.unique_id,
    reference_line_item: {
      line_item_unique_id: item.unique_id,
      line_item_document_type: PurchaseDocumentType.PurchaseOrder,
    },
    tracability: (item.tracability as Tracability) || Tracability.Normal,
    barcode: item.barcode,
    status: item.tracability === "serial" ? undefined : "is_active",
    stock_qty: item.stock_qty,
  }));
  return formatData as ITraceEntry[];
};

export const createGoodsReceiveFromPurchaseOrder = (
  data: IPurchaseOrder,
  currentUser: IUser | null
): IGoodsReceive => {
  const formatData = {
    unique_id: "",
    type: "purchase_order",
    reference_unique_id_list: [data.unique_id],
    warehouse_id: undefined,
    created_date: dayjs(),
    posted_date: dayjs(),
    remark: "",
    trace_entry_list: formatPurchaseItemListToGoodsReceiveItemList(data),
    created_by: {
      user_unique_id: currentUser?.unique_id,
      email: currentUser?.email,
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      img_url: currentUser?.img_url,
    },
    branch_id: data.branch_id,
    main_status: "",
    aggrid_status: "",
    flag_status: [],
  };

  return formatData as IGoodsReceive;
};

export const createPurchaseRuturnFromPurchaseOrder = (
  data: IPurchaseOrder,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      within_date,
      expected_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      attachment_list,
      branch,
      approval_step,
      approver_list,
      reviewer_list,
      related_user_list,
      reference_document_id,
      destination_warehouse,
      destination_warehouse_id,
      credit_day,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({ id, unique_id: item_unique_id, po_qty, ...otherItemList }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
              reference_unique_id: "",
              reference_document_type: PurchaseDocumentType.PurchaseReturn,
              reference_line_item: {
                line_item_unique_id: item_unique_id,
                line_item_document_type: PurchaseDocumentType.PurchaseOrder,
                line_item_document_unique_id: unique_id,
              },
            })
          )
        : [];

    const related_user = related_user_list?.filter(
      (user) => user.user_unique_id !== currentUser?.unique_id
    );

    const formatRelatedUserList =
      created_by?.user_unique_id !== currentUser?.unique_id
        ? [created_by, ...(related_user || [])]
        : related_user;

    return {
      ...otherData,
      ...purchaseReturnSchema,
      reference_document_list: [
        {
          document_id: data.id,
          document_unique_id: data.unique_id,
          document_type: PurchaseDocumentType.PurchaseRequest,
        },
      ],
      item_list: formatItemList,
      created_by: formatAuthToUserPayload(currentUser),
      related_user_list: formatRelatedUserList,
      reference_no: otherData.reference_no,
      additional_discount: otherData.additional_discount,
      additional_discount_type: otherData.additional_discount_type,
    };
  }
};
