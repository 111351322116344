import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useSalesError = (errors: any) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const { item_list, deposit_list, customer_details } = errors;
      // if (unique_id) {
      //   enqueueSnackbar(unique_id.message, {
      //     variant: "error",
      //   });
      // }
      // if (issue_date) {
      //   enqueueSnackbar(issue_date.message, {
      //     variant: "error",
      //   });
      // }
      if (customer_details) {
        enqueueSnackbar(customer_details.root.message, {
          variant: "error",
        });
      }
      if (item_list) {
        if (Array.isArray(item_list)) {
          if (item_list.length > 0) {
            item_list.forEach((item) => {
              if (item) {
                const { item_name, qty, uom, price_per_unit } = item;
                if (qty) {
                  enqueueSnackbar(qty.message, {
                    variant: "error",
                  });
                }
                if (item_name) {
                  enqueueSnackbar(item_name.message, {
                    variant: "error",
                  });
                }
                if (uom) {
                  enqueueSnackbar(uom.message, {
                    variant: "error",
                  });
                }
                if (price_per_unit) {
                  enqueueSnackbar(price_per_unit.message, {
                    variant: "error",
                  });
                }
              }
            });
          }
        } else {
          enqueueSnackbar(item_list.message || item_list.root.message, {
            variant: "error",
          });
        }
      }
      if (deposit_list) {
        if (Array.isArray(deposit_list)) {
          if (deposit_list.length > 0) {
            deposit_list.forEach((item) => {
              if (item) {
                const { name, pre_vat_amount } = item;
                if (name) {
                  enqueueSnackbar(name.message, {
                    variant: "error",
                  });
                }
                if (pre_vat_amount) {
                  enqueueSnackbar(pre_vat_amount.message, {
                    variant: "error",
                  });
                }
              }
            });
          }
        } else {
          enqueueSnackbar(deposit_list.message || deposit_list.root.message, {
            variant: "error",
          });
        }
      }
      // if (reason_to_return) {
      //   enqueueSnackbar(reason_to_return.message, {
      //     variant: "error",
      //   });
      // }
    }
  }, [enqueueSnackbar, errors]);
  return;
};
