import { RefObject, useEffect, useState } from "react";
import { useStateContext } from "../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { SalesExportType, ExportGenerateInput } from "../../generated/sales";
import { GraphQLClient } from "graphql-request";
import { SALES_EXPORT } from "../../services/Sales/SalesExport";
import { AgGridReact } from "ag-grid-react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const useSalesReport = (
  gridRef: RefObject<AgGridReact<any>>,
  exportType: SalesExportType
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const {
    state: { authUser },
  } = useStateContext();

  const [exportInput, setExportInput] = useState<ExportGenerateInput | null>(
    null
  );

  const onBtnExport = () => {
    setIsLoading(true);
    try {
      const filterModel = gridRef?.current?.api.getFilterModel();
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));
      setExportInput({
        export_type: exportType,
        user_unique_id: authUser?.unique_id || "",
        params: {
          aggridInput: {
            filterModel,
            sortModel,
          },
        },
      });
    } catch (err) {
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
      setExportInput(null);
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { GenerateExport } = await graphQLClientWithHeaderSales.request(
          SALES_EXPORT,
          {
            input: exportInput,
          }
        );
        if (GenerateExport) {
          window.open(GenerateExport || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
          setExportInput(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
        setExportInput(null);
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
    setIsLoading(false);
  }, [enqueueSnackbar, exportInput, t, graphQLClientWithHeaderSales]);

  return {
    onBtnExport,
    isLoading: isLoading,
  };
};
