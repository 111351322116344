import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useDisable } from "../../../../hooks/use-disable";
import { useAttributeOptions } from "../../../../hooks/use-inventory-setting-option";

import LabelInput from "../../../UI/LabelInput";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import ControlledTextField from "../../../Controller/ControlledTextField";

const Attribute = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();

  const [disabled] = useDisable();

  const { fields, replace } = useFieldArray({
    control,
    name: "attribute_list",
  });

  const { options, isLoading, replaceAttribute, findMapValue } =
    useAttributeOptions();

  const columnDefs = useMemo(
    () => [
      {
        field: "name",
        headerName: t("inventory.attribute.attributeName"),
      },
      {
        field: "short_name",
        headerName: t("inventory.attribute.acronym"),
      },
      {
        field: "value",
        headerName: t("inventory.attribute.attributeValue"),
        width: 400,
        autoHeight: true,
      },
    ],
    [t]
  );

  return (
    <Fragment>
      <Grid container my={2}>
        {disabled ? (
          <LabelInput
            value={getValues("set_attribute.name_th")}
            label={t("inventory.items.set_attribute")}
          />
        ) : (
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="set_attribute.name_th"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  options={options}
                  label={t("inventory.items.set_attribute")}
                  loading={isLoading}
                  onChange={(_, input) => {
                    if (input) {
                      const value = findMapValue(input.value);
                      console.log("value", value);
                      setValue("set_attribute", value);
                      replaceAttribute(input.value, replace);
                    } else field.onChange("");
                  }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <CustomizedBox margin="0" bgcolor="primary.light">
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {columnDefs.map((header, index) => (
                  <TableCell align="center" key={index} width={header.width}>
                    <Box minWidth={header.width}>
                      <Typography
                        textAlign="center"
                        fontSize={14}
                        fontWeight={700}
                      >
                        {header.headerName}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((row, index: number) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    <Typography fontSize={14}>
                      {getValues(`attribute_list[${index}].name`)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontSize={14}>
                      {getValues(`attribute_list[${index}].short_name`)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <ControlledTextField
                      name={`attribute_list.[${index}].value`}
                      control={control}
                      viewMode={disabled}
                      // error={Boolean(
                      //   errors &&
                      //     errors.attribute_list &&
                      //     errors.attribute_list.value
                      // )}
                      // helperText={errors?.attribute_list[
                      //   index
                      // ]?.message?.toString()}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomizedBox>
    </Fragment>
  );
};

export default Attribute;
