import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../../types/global";

const SalesSetting = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.sales.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.sales.type"),
      to: "/setting/sales/type",
    },
    {
      name: t("setting.sales.channel"),
      to: "/setting/sales/channel",
    },
    {
      name: t("setting.sales.payment_channel.index"),
      to: "/setting/sales/payment-channel",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default SalesSetting;
