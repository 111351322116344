import * as Yup from "yup";
import dayjs from "dayjs";
import { AdditionalDiscountType } from "../../../../generated/sales";
import { PriceVatType } from "../../../../generated/sales";
import { ICashSales } from "../../../../types/Sales/cashSales";
import { itemListSchema } from "../itemListSchema";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../../types/Sales";
import { billingAddressValidation } from "../CustomerInfo/schema";

export const salesPaymentChannelSchema: ISalesPaymentChannel = {
  id: undefined,
  branch_id: undefined,
  reference_document_type: "",
  reference_unique_id: "",
  payment_channel_id: undefined,
  payment_channel_payment_name: "",
  payment_channel_unique_id: "",
  payment_channel_name: "",
  payment_channel_unique_id_name: "",
  payment_amount: 0,
  payment_date: dayjs(),
  cheque_provider: "",
  cheque_no: "",
  cheque_date: null,
  remark: "",
  is_withholding_tax: false,
  withholding_tax_rate: "กำหนดเอง",
  withholding_tax_amount: 0,
};

export const salesDeductionSchema: ISalesDeduction = {
  id: undefined,
  branch_id: undefined,
  reference_document_type: "",
  reference_unique_id: "",
  document_type: "",
  document_unique_id: "",
  deduction_amount: 0,
  credit_balance: 0,
  deduction_issue_date: dayjs(),
  remark: "",
};

export const cashSalesSchema: ICashSales = {
  unique_id: "",
  created_by: null,
  created_date: dayjs(),
  reference_document_list: [],
  issue_date: dayjs(),
  due_date: dayjs(),
  delivery_date: dayjs(),
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  price_vat_type: PriceVatType.IncludeVat,
  currency: "THB",
  customer_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      type: "",
      contact_name: "",
      contact_phone: "",
      fax: "",
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  item_list: [],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  post_discount_amount: 0,
  total_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  customer_unique_id: "",
  withholding_tax_amount: 0,
  reference_no: "",
  tag_list: [],
  sales_channel: undefined,
  branch_id: undefined,
  credit_day: 0,
  need_tax_invoice: true,
  tax_invoice_no: "",
  external_reference_no: "",
  item_price_list_id: 0,
  paid_net_amount: 0,
  paid_total_amount: 0,
  is_have_payment: false,
  is_have_deduct: false,
  payment_list: [],
  deduction_document_list: [],
  contact_person_list: [],
};

export const paymentChannelValidation = Yup.object().shape({
  payment_channel_payment_name: Yup.string().required("กรุณาเลือก"),
  payment_channel_id: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("กรุณาเลือก"),
  payment_amount: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุ")
    .required("กรุณาระบุ"),
  payment_date: Yup.date().typeError("กรุณาเลือก").required("กรุณาเลือก"),
  cheque_provider: Yup.string().when("payment_channel_name", {
    is: "เช็ค",
    then: (schema) => schema.required("กรุณาเลือก"),
  }),
  cheque_no: Yup.string().when("payment_channel_name", {
    is: "เช็ค",
    then: (schema) => schema.required("กรุณาระบุ"),
  }),
  cheque_date: Yup.date()
    .when("payment_channel_name", {
      is: "เช็ค",
      then: (schema) => schema.required("กรุณาเลือก"),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("กรุณาเลือก"),
  is_withholding_tax: Yup.boolean(),
  withholding_tax_rate: Yup.string().when("is_withholding_tax", {
    is: true,
    then: (schema) => schema.required("กรุณาระบุ"),
  }),
  withholding_tax_amount: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .when("is_withholding_tax", {
      is: true,
      then: (schema) => schema.required("กรุณาระบุ"),
    }),
});

export const deductionValidation = Yup.object().shape({
  document_type: Yup.string().required("กรุณาระบุ"),
  document_unique_id: Yup.string().required("กรุณาระบุ"),
  deduction_amount: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุ")
    .required("กรุณาระบุ")
    .when("document_type", {
      is: "customer_deposit",
      then: (schema) =>
        schema.max(
          Yup.ref("credit_balance"),
          "มูลค่าชำระเกินจำนวนเงินมัดจำคงเหลือ"
        ),
    }),
  deduction_issue_date: Yup.date()
    .typeError("กรุณาเลือก")
    .required("กรุณาเลือก"),
});

export const cashSalesValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  issue_date: Yup.date().required("กรุณาระบุ"),
  credit_day: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  customer_unique_id: Yup.string().required("กรุณาระบุลูกค้า"),
  is_have_payment: Yup.boolean(),
  is_have_deduct: Yup.boolean(),
  item_list: Yup.array()
    .of(itemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  deduction_document_list: Yup.array().when("is_have_deduct", {
    is: true,
    then: (schema) => schema.of(deductionValidation),
  }),
  payment_list: Yup.array().when("is_have_payment", {
    is: true,
    then: (schema) =>
      schema
        .of(paymentChannelValidation)
        .test(
          "payment_date_validation",
          "",
          (value, { parent, createError }) => {
            const { issue_date } = parent;
            let errors: any = [];

            if (value && value.length > 0) {
              value.some((payment, index) => {
                if (
                  dayjs(payment.payment_date)
                    .endOf("day")
                    .isBefore(dayjs(issue_date).endOf("day"))
                ) {
                  errors.push(
                    createError({
                      path: `payment_list.${index}.payment_date`,
                      message:
                        "วันรับชำระต้องมากกว่าหรือเท่ากับวันที่ออกเอกสาร",
                    })
                  );
                }
                return false;
              });
            }

            if (errors.length > 0) {
              throw new Yup.ValidationError(errors);
            }
            return true;
          }
        ),
  }),
  additional_discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  customer_details: Yup.object()
    .shape({
      billing_address: billingAddressValidation,
    })
    .test(
      "required",
      "กรุณาระบุที่อยู่จดทะเบียน",
      function (value, { parent }) {
        const { billing_address } = value;
        if (parent.need_tax_invoice) {
          if (billing_address) {
            if (
              billing_address.address_name &&
              billing_address.address_name.trim().length > 0 &&
              billing_address.country &&
              billing_address.country.trim().length > 0
            )
              return true;
          }
          return false;
        }
        return true;
      }
    ),
});
// .test("payment_date_validation", "", function (value) {
//   const issueDate = value.issue_date;
//   if (value.payment_list && issueDate) {
//     for (let payment of value.payment_list) {
//       if (payment.payment_date && payment.payment_date < issueDate) {
//         return this.createError({
//           path: "payment_list",
//           message: "วันรับชำระต้องมากกว่าหรือเท่ากับวันที่ออกเอกสาร",
//         });
//       }
//     }
//   }
//   return true;
// });
