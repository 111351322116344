import { IExporterSales, ISelectOption } from "../../../types/global";
import { SalesExportType } from "../../../generated/sales";

export const getSalesExportTypeOptions = (pageType: SalesExportType) => {
  switch (pageType) {
    case SalesExportType.Quotation:
      return quotationTypeOptions;
    case SalesExportType.SalesOrder:
      return salesOrderTypeOptions;
    case SalesExportType.CashSales:
      return cashSalesTypeOptions;
    case SalesExportType.CustomerDeposit:
      return customerDepositTypeOptions;
    case SalesExportType.SalesInvoice:
      return salesInvoiceTypeOptions;
    case SalesExportType.SalesReturn:
      return salesReturnTypeOptions;
    default:
      return [];
  }
};

export const getSalesExporterSchema = (pageType: SalesExportType) => {
  const exporterSchena: IExporterSales = {
    type: pageType,
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  return exporterSchena;
};

const quotationTypeOptions: ISelectOption[] = [
  {
    label: "ใบเสนอราคา",
    value: SalesExportType.Quotation,
  },
];

const salesOrderTypeOptions: ISelectOption[] = [
  {
    label: "ใบสั่งขาย",
    value: SalesExportType.SalesOrder,
  },
];

const customerDepositTypeOptions: ISelectOption[] = [
  {
    label: "ใบมัดจำ",
    value: SalesExportType.CustomerDeposit,
  },
  {
    label: "การรับชำระ",
    value: SalesExportType.CustomerDepositPayment,
  },
];
const cashSalesTypeOptions: ISelectOption[] = [
  {
    label: "ใบขายสด",
    value: SalesExportType.CashSales,
  },
  {
    label: "การตัดชำระ",
    value: SalesExportType.CashSalesDeduction,
  },
  {
    label: "การรับชำระ",
    value: SalesExportType.CashSalesPayment,
  },
];

const salesInvoiceTypeOptions: ISelectOption[] = [
  {
    label: "ใบแจ้งหนี้",
    value: SalesExportType.SalesInvoice,
  },
];

const salesReturnTypeOptions: ISelectOption[] = [
  {
    label: "ใบรับคืน",
    value: SalesExportType.SalesReturn,
  },
];
