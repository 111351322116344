import { TextFieldProps } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import CustomizedTextField from "../Custom/CustomizedTextField";
import LabelInput from "../UI/LabelInput";
import { forwardRef } from "react";

type ExtendedTextFieldProps = {
  name: string;
  label?: TextFieldProps["label"];
  control: Control<any>;
  multiline?: TextFieldProps["multiline"];
  rows?: TextFieldProps["rows"];
  error?: TextFieldProps["error"];
  onChange?: (e: any, field?: any) => void;
  onBlur?: TextFieldProps["onBlur"];
  onFocus?: TextFieldProps["onFocus"];
  helperText?: TextFieldProps["helperText"];
  required?: TextFieldProps["required"];
  disabled?: TextFieldProps["disabled"];
  type?: TextFieldProps["type"];
  testId?: string;
  autoFocus?: TextFieldProps["autoFocus"];
  autoComplete?: TextFieldProps["autoComplete"];
  InputProps?: TextFieldProps["InputProps"];
  inputProps?: TextFieldProps["inputProps"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  sx?: TextFieldProps["sx"];
  minRows?: TextFieldProps["minRows"];
  maxRows?: TextFieldProps["maxRows"];
  onKeyDown?: TextFieldProps["onKeyDown"];
  FormHelperTextProps?: TextFieldProps["FormHelperTextProps"];
  placeholder?: TextFieldProps["placeholder"];
  viewMode?: boolean | null;
  viewModeNoLabel?: boolean | null;
  viewModeNoHelperText?: boolean;
  noDecimal?: boolean;
};

const ControlledTextField = forwardRef<
  HTMLInputElement,
  ExtendedTextFieldProps
>(
  (
    {
      name,
      label,
      control,
      multiline,
      rows,
      error,
      onChange,
      onFocus,
      onBlur,
      helperText,
      required,
      disabled,
      type,
      testId,
      autoFocus,
      autoComplete,
      InputProps,
      inputProps,
      InputLabelProps,
      sx,
      minRows,
      maxRows,
      onKeyDown,
      FormHelperTextProps,
      placeholder,
      viewMode,
      viewModeNoLabel,
      viewModeNoHelperText,
      noDecimal,
    },
    ref
  ) => {
    return (
      <Controller
        key={`${name}-text-field`}
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {viewMode ? (
              <LabelInput
                label={!viewModeNoLabel && label}
                value={field.value}
                helperText={!viewModeNoHelperText && helperText}
                helperTextSx={FormHelperTextProps?.sx}
                noDecimal={noDecimal}
              />
            ) : (
              <CustomizedTextField
                {...field}
                ref={ref || field.ref}
                onChange={
                  onChange ? (e: any) => onChange(e, field) : field.onChange
                }
                sx={sx}
                fullWidth
                error={error}
                helperText={helperText}
                label={label}
                type={type}
                onFocus={onFocus}
                onBlur={(e) => {
                  field.onBlur();
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                disabled={disabled}
                required={required}
                testId={testId}
                multiline={multiline}
                rows={rows}
                autoFocus={autoFocus}
                inputProps={inputProps}
                InputProps={InputProps}
                InputLabelProps={InputLabelProps}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
                minRows={minRows}
                maxRows={maxRows}
                FormHelperTextProps={FormHelperTextProps}
                placeholder={placeholder}
              />
            )}
          </>
        )}
      />
    );
  }
);

export default ControlledTextField;
