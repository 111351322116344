import * as Yup from "yup";
import dayjs from "dayjs";
import { IGoodsAdjustment } from "../../../../types/Inventory/GoodsAdjustment";
import { Tracability } from "../../../../generated/inventory";

export const goodsAdjustmentSchema: Partial<IGoodsAdjustment> = {
  unique_id: "",
  branch_id: undefined,
  warehouse_id: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: null,
  flag_status: [],
  main_status: "",
};

const goodsAdjustmentTraceValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .notOneOf([0], "กรุณาระบุจำนวนที่ปรับปรุง")
    .required("กรุณาระบุจำนวนที่ปรับปรุง")
    .when("stock_qty", (stock_qty: any, schema) => {
      return schema.test({
        test: (qty) => (qty ?? 0) + Number(stock_qty) >= 0,
        message: "ไม่สามารถปรับปรุงจำนวนที่มากกว่าจำนวนในคลังได้",
      });
    }),
});

const goodsAdjustmentSerialValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .notOneOf([0], "กรุณาระบุจำนวนที่ปรับปรุง")
          .required("กรุณาระบุจำนวนที่ปรับปรุง")
          .when("stock_qty", (stock_qty: any, schema) => {
            return schema.test({
              test: (qty) => (qty ?? 0) + Number(stock_qty) >= 0,
              message: "ไม่สามารถปรับปรุงจำนวนที่มากกว่าจำนวนในคลังได้",
            });
          })
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsAdjustmentTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsAdjustmentValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  branch_id: Yup.string().required("กรุณาระบุสาขา"),
  warehouse_id: Yup.string().required("กรุณาระบุคลัง"),
  trace_entry_list: Yup.array()
    .of(goodsAdjustmentSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
