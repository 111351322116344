export const printStatusWithoutApproval = [
  "draft",
  "in_transit",
  "finished",
  "wait_receive",
  "wait_issue",
  "wait_transfer",
];

export const printStatusWithApproval = [
  ...printStatusWithoutApproval,
  "wait_approve",
  "not_approved",
];
