import dayjs from "dayjs";
import { ISalesInvoice } from "../../../../types/Sales/salesInvoice";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/sales";
import * as Yup from "yup";
import { itemListSchema } from "../itemListSchema";
import { billingAddressValidation } from "../CustomerInfo/schema";

export const salesInvoiceSchema: ISalesInvoice = {
  unique_id: "",
  created_by: null,
  created_date: dayjs(),
  reference_document_list: [],
  issue_date: dayjs(),
  due_date: dayjs(),
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  price_vat_type: PriceVatType.IncludeVat,
  currency: "THB",
  customer_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      type: "",
      contact_name: "",
      contact_phone: "",
      fax: "",
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  item_list: [],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  post_discount_amount: 0,
  total_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  customer_unique_id: "",
  withholding_tax_amount: 0,
  reference_no: "",
  tag_list: [],
  sales_channel: undefined,
  branch_id: undefined,
  credit_day: 0,
  need_tax_invoice: true,
  tax_invoice_no: "",
  item_price_list_id: 0,
  payment_list: [],
  deduction_document_list: [],
  payment_total_amount: 0,
  deduction_total_amount: 0,
  paid_net_amount: 0,
  paid_total_amount: 0,
  contact_person_list: [],
};

export const paymentChannelInvoiceValidation = Yup.object().shape({
  payment_channel_payment_name: Yup.string().required("กรุณาเลือก"),
  payment_channel_id: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("กรุณาเลือก"),
  payment_amount: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุ")
    .required("กรุณาระบุ"),
  payment_date: Yup.date()
    .typeError("กรุณาเลือก")
    .required("กรุณาเลือก")
    .when("$issue_date", (issue_date: any, schema) => {
      return schema.min(
        issue_date[0],
        "วันรับชำระต้องมากกว่าหรือเท่ากับวันที่ออกเอกสาร"
      );
    }),
  cheque_provider: Yup.string().when("payment_channel_name", {
    is: "เช็ค",
    then: (schema) => schema.required("กรุณาเลือก"),
  }),
  cheque_no: Yup.string().when("payment_channel_name", {
    is: "เช็ค",
    then: (schema) => schema.required("กรุณาระบุ"),
  }),
  cheque_date: Yup.date()
    .when("payment_channel_name", {
      is: "เช็ค",
      then: (schema) => schema.required("กรุณาเลือก"),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError("กรุณาเลือก"),
  is_withholding_tax: Yup.boolean(),
  withholding_tax_rate: Yup.string().when("is_withholding_tax", {
    is: true,
    then: (schema) => schema.required("กรุณาระบุ"),
  }),
  withholding_tax_amount: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .when("is_withholding_tax", {
      is: true,
      then: (schema) => schema.required("กรุณาระบุ"),
    }),
});

export const salesInvoiceValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  issue_date: Yup.date().required("กรุณาระบุ"),
  due_date: Yup.date().required("กรุณาระบุ"),
  credit_day: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  customer_unique_id: Yup.string().required("กรุณาเลือกลูกค้า"),
  item_list: Yup.array()
    .of(itemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  branch_id: Yup.number().required("กรุณาเลือกสาขา"),
  price_vat_type: Yup.string().required("กรุณาเลือกประเภทราคา"),
  currency: Yup.string().required("กรุณาเลือกสกุลเงิน"),
  item_price_list_id: Yup.number().required("กรุณาเลือกรายการราคา"),
  additional_discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  customer_details: Yup.object()
    .shape({
      billing_address: billingAddressValidation,
    })
    .test(
      "required",
      "กรุณาระบุที่อยู่จดทะเบียน",
      function (value, { parent }) {
        const { billing_address } = value;
        if (parent.need_tax_invoice) {
          if (billing_address) {
            if (
              billing_address.address_name &&
              billing_address.address_name.trim().length > 0 &&
              billing_address.country &&
              billing_address.country.trim().length > 0
            )
              return true;
          }
          return false;
        }
        return true;
      }
    ),
});
