import { Box, Typography, Link, TablePagination } from "@mui/material";
import { SlideInPanel, SlideInPanelProps } from "./SlideInPanel";
import {
  ActivityLog,
  ActivityType,
  ActivityLogDocumentType,
  ActivityLogFindManyByReferenceIdQuery,
  useActivityLogFindManyByReferenceIdQuery,
} from "../../../generated/general";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Fragment, useState, useEffect, useMemo } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
// import ActivityLogDetail from "../../Form/ActivityLog/Detail";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { formatDateTime } from "../../../utils/Date";
// import { useDisable } from "../../../hooks/use-disable";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "../../../utils/Formatter/Global";

const ICON_MAP: Record<ActivityType, typeof DeleteOutlineIcon> = {
  [ActivityType.AddRelatedEmployee]: PersonAddAltOutlinedIcon,
  [ActivityType.Approve]: DoneOutlinedIcon,
  [ActivityType.Cancel]: ClearOutlinedIcon,
  [ActivityType.CancelPendingScan]: ClearOutlinedIcon,
  [ActivityType.Comment]: CommentOutlinedIcon,
  [ActivityType.Copy]: ContentCopyOutlinedIcon,
  [ActivityType.Create]: InsertDriveFileOutlinedIcon,
  [ActivityType.Delete]: DeleteOutlineIcon,
  [ActivityType.DeleteRelatedEmployee]: PersonRemoveOutlinedIcon,
  [ActivityType.Document]: DescriptionOutlinedIcon,
  [ActivityType.Edit]: EditIcon,
  [ActivityType.Image]: ImageOutlinedIcon,
  [ActivityType.StatusChange]: ArrowForwardOutlinedIcon,
};

const formatActivityType = (activityType: ActivityType | undefined) => {
  switch (activityType) {
    case ActivityType.AddRelatedEmployee:
      return "เพิ่มพนักงานที่เกี่ยวข้อง";
    case ActivityType.Approve:
      return "อนุมัติ";
    case ActivityType.Cancel:
      return "ยกเลิก";
    case ActivityType.CancelPendingScan:
      return "ยกเลิกจำนวนที่ค้าง";
    case ActivityType.Comment:
      return "แสดงความคิดเห็น";
    case ActivityType.Copy:
      return "คัดลอก";
    case ActivityType.Create:
      return "สร้าง";
    case ActivityType.Delete:
      return "ลบ";
    case ActivityType.DeleteRelatedEmployee:
      return "ลบพนักงานที่เกี่ยวข้อง";
    case ActivityType.Document:
      return "เอกสาร";
    case ActivityType.Edit:
      return "แก้ไข";
    case ActivityType.Image:
      return "รูปภาพ";
    case ActivityType.StatusChange:
      return "เปลี่ยนสถานะ";
    default:
      return "";
  }
};

type Props = Pick<SlideInPanelProps, "open" | "handleClose"> & {
  documentId: string | number;
  documentType: string;
  handleClose: () => void;
};

export const ActivityLogSlideInPanel = ({
  documentType,
  documentId,
  handleClose,
  ...props
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState("desc");
  // const [disabled] = useDisable();
  const sortOptions = [
    { id: 1, value: "desc", label: "ใหม่ไปเก่า" },
    { id: 2, value: "asc", label: "เก่าไปใหม่" },
  ];
  const { pathname } = useLocation();

  const { activityLogIsInit, setActivityLogIsInit } = useActivityLog();

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, refetch } = useActivityLogFindManyByReferenceIdQuery<
    Array<ActivityLogFindManyByReferenceIdQuery>
  >(
    graphQLClientGeneral,
    {
      findManyInput: {
        reference_id:
          typeof documentId === "string" ? parseInt(documentId) : documentId,
        document_type: documentType as ActivityLogDocumentType,
      },
    },
    {
      enabled: !activityLogIsInit,
    }
  );

  useEffect(() => {
    if (!activityLogIsInit) {
      refetch();
    }
  }, [activityLogIsInit, refetch]);

  useEffect(() => {
    if (!activityLogIsInit && documentId) {
      setActivityLogIsInit(true);
    }
    return () => {
      if (activityLogIsInit) {
        setActivityLogIsInit(false);
      }
    };
  }, [activityLogIsInit, documentId, setActivityLogIsInit, data, refetch]);

  const sortedData = useMemo(() => {
    if ((data as any)?.ActivityLogFindManyByReferenceId?.length > 0) {
      return [...(data as any)?.ActivityLogFindManyByReferenceId].sort(
        (a: any, b: any) => {
          const dateComparison =
            new Date(a.created_date).getTime() -
            new Date(b.created_date).getTime();

          // in case of same date, sort by id (happens through status change)
          if (dateComparison === 0) {
            return sortBy === "asc" ? a.id - b.id : b.id - a.id;
          } else {
            return sortBy === "asc" ? dateComparison : -dateComparison;
          }
        }
      );
    } else {
      return [];
    }
  }, [data, sortBy]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * 5, page * 5 + 5);
  }, [sortedData, page]);

  const handleSelectSortOption = (value: string) => {
    setSortBy(value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const splitPath = pathname.split("/");
  const copyPath = splitPath.splice(0, splitPath.length - 1).join("/");

  const { t } = useTranslation();

  return (
    <Fragment>
      <SlideInPanel
        title="การเคลื่อนไหว"
        handleClose={handleClose}
        {...props}
        width="560px"
      >
        {/* {!disabled && <ActivityLogDetail />} */}
        <CustomizedScrollbar
        // sx={{
        //   height: "calc(100vh - 9rem)",
        // }}
        >
          <CustomizedBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minHeight: "400px",
              height: "calc(100vh - 9rem)",
            }}
          >
            <CustomizedSelect
              options={sortOptions}
              label="เรียงลำดับ"
              onChange={(e: any) => handleSelectSortOption(e.target.value)}
              value={sortBy}
              sx={{
                mb: 0.5,
                width: "115px",
              }}
            />
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                minHeight: "100px",
              }}
            >
              {paginatedData?.map((row: ActivityLog, index: number) => {
                const Icon =
                  ICON_MAP[row.activity_type || ActivityType.Approve];
                return (
                  <Fragment key={row.id}>
                    {index > 0 && (
                      <Box
                        sx={{
                          borderRight: "1px solid #c8c8c8",
                          height: "40px",
                          width: "calc(50% - 0.5px)",
                        }}
                      ></Box>
                    )}
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 24px 1fr",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Box>วันที่ {formatDateTime(row?.created_date)}</Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          bgcolor: "#F5F5F5",
                        }}
                      >
                        <Icon fontSize="small" />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {formatActivityType(
                              row.activity_type as ActivityType
                            )}
                            {row.activity_type === ActivityType.Approve &&
                              ` ${row.activity_detail?.message?.[0]}`}
                          </Typography>
                          {row.activity_type === ActivityType.StatusChange && (
                            <CustomizedStatus
                              status={row.activity_detail?.curr_status}
                              fontSize="0.75rem"
                            />
                          )}
                        </Box>
                        {row.activity_type === ActivityType.Copy &&
                          row.activity_detail?.copied_to && (
                            <Box display={"flex"} gap={0.5}>
                              <Typography>คัดลอกไป</Typography>
                              <Link
                                href={`${copyPath}/${row.activity_detail.copied_to.id}`}
                                underline="none"
                              >
                                {row.activity_detail.copied_to.unique_id}
                              </Link>
                            </Box>
                          )}
                        {row.activity_type === ActivityType.Copy &&
                          row.activity_detail?.copied_from && (
                            <Box display={"flex"} gap={0.5}>
                              <Typography>คัดลอกมาจาก</Typography>
                              <Link
                                href={`${copyPath}/${row.activity_detail.copied_from.id}`}
                                underline="none"
                              >
                                {row.activity_detail.copied_from.unique_id}
                              </Link>
                            </Box>
                          )}
                        {row.activity_detail?.updated_fields
                          ? Object.keys(
                              row.activity_detail?.updated_fields
                            ).includes("net_amount") && (
                              <Box>
                                <Typography>
                                  {`${t("sales.net_amount")}: ${numberFormatter(
                                    row.activity_detail?.updated_fields
                                      .net_amount
                                  )} บาท`}
                                </Typography>
                              </Box>
                            )
                          : null}

                        <Box>
                          <Typography sx={{ display: "inline-block" }}>
                            โดย
                          </Typography>
                          <Link
                            component={RouterLink}
                            key={index}
                            style={{ marginLeft: "0.25rem" }}
                            underline="none"
                            onClick={() => {
                              handleClose();
                            }}
                            to={`/user/account/${row.created_by?.user_id}`}
                          >
                            {`${row.created_by?.first_name} ${row.created_by?.last_name}`}
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Fragment>
                );
              })}
            </Box>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={sortedData.length}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />
          </CustomizedBox>
        </CustomizedScrollbar>
      </SlideInPanel>
    </Fragment>
  );
};
