import { Box, Divider, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { formatCompanyName } from "../../../utils/Global";
import { ICompany } from "../../../types/Setting/Company";

type Props = {
  documentType: string;
  company: ICompany;
};

const SalesPdfExternalSigner = ({ documentType, company }: Props) => {
  const fontSize = 10.66;

  const isPayment = ["cash_sales", "customer_deposit"].includes(documentType);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexWrap: "nowrap",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        <Typography sx={{ fontSize: fontSize, fontWeight: 600, lineHeight: 2 }}>
          สแกนดูเอกสาร
        </Typography>
        <QRCodeSVG value={window.location.href} size={56} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 170,
        }}
      >
        {!isPayment && (
          <Box textAlign={"center"}>
            <Typography fontSize={fontSize} lineHeight={2}>
              ได้รับสินค้าตามรายการด้านบน
            </Typography>
            <Typography fontSize={fontSize}>ไว้เรียบร้อยแล้ว</Typography>
          </Box>
        )}
        <Box flexGrow={1} />
        {!isPayment ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography fontSize={fontSize} fontWeight={600}>
                ผู้รับสินค้า
              </Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  width: 118,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography fontSize={fontSize} fontWeight={600}>
                ลงวันที่
              </Typography>
              <Box display="flex" width={118} gap={0.5}>
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    flexGrow: 1,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    flexGrow: 1,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    flexGrow: 1,
                  }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography fontSize={fontSize} fontWeight={600}>
                ผู้รับเงิน/ใบเสร็จ
              </Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  width: 94,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography fontSize={fontSize} fontWeight={600}>
                ลงวันที่
              </Typography>
              <Box display="flex" width={94} gap={0.5}>
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    flexGrow: 1,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    flexGrow: 1,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    flexGrow: 1,
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {documentType !== "customer_deposit" && (
        <>
          <Divider flexItem orientation="vertical" variant="fullWidth" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 170,
            }}
          >
            {isPayment && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography fontSize={fontSize} fontWeight={600}>
                    ผู้รับสินค้า
                  </Typography>
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.33,
                      width: 118,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography fontSize={fontSize} fontWeight={600}>
                    ลงวันที่
                  </Typography>
                  <Box display="flex" width={118} gap={0.5}>
                    <Divider
                      flexItem
                      sx={{
                        borderBottomWidth: 1.33,
                        flexGrow: 1,
                      }}
                    />
                    <Divider
                      flexItem
                      sx={{
                        borderBottomWidth: 1.33,
                        flexGrow: 1,
                      }}
                    />
                    <Divider
                      flexItem
                      sx={{
                        borderBottomWidth: 1.33,
                        flexGrow: 1,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box flexGrow={1} />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Typography fontSize={fontSize} fontWeight={600}>
                  ผู้ส่งสินค้า
                </Typography>
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    width: 118,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Typography fontSize={fontSize} fontWeight={600}>
                  ลงวันที่
                </Typography>
                <Box display="flex" width={118} gap={0.5}>
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.33,
                      flexGrow: 1,
                    }}
                  />
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.33,
                      flexGrow: 1,
                    }}
                  />
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.33,
                      flexGrow: 1,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Divider flexItem orientation="vertical" variant="fullWidth" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 170,
        }}
      >
        <Typography fontSize={fontSize} lineHeight={2} textAlign={"center"}>
          ในนาม {formatCompanyName(company?.type || "", company?.name || "")}
        </Typography>
        <Box flexGrow={1} />
        <Divider
          flexItem
          sx={{
            borderBottomWidth: 1.33,
            width: "100%",
          }}
        />
        <Typography
          fontSize={fontSize}
          fontWeight={600}
          textAlign={"center"}
          mt={0.5}
        >
          ผู้มีอำนาจลงนาม
        </Typography>
      </Box>
    </Box>
  );
};

export default SalesPdfExternalSigner;
