import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "../../../../UI/AgGrid";
import { useCurrentStockColumnDefs } from "./useCurrentStockColumnDefs";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { GridReadyEvent, IServerSideDatasource } from "ag-grid-community";
import { CURRENT_STOCK_BY_LOCATION_VIEW } from "../../../../../services/AgGrid/InventoryAggrid";
import { useFormContext } from "react-hook-form";
import { IItem } from "../../../../../types/Inventory/item";
import { branchInfoFromSessionStorage } from "../../../../../utils/Global";
import { ItemType } from "../../../../../generated/inventory";
import { useItemCurrentStockSku } from "../../../../../hooks/Inventory/use-item-current-stock";

const CurrentStockByLocation = () => {
  const gridRef = useRef<AgGridReact>(null);

  const { getValues } = useFormContext<IItem>();
  const itemType = getValues("type");
  const itemSkuName = getValues("sku");
  const bundleItemList = getValues("bundle_item_detail_list");
  const columnDefs = useCurrentStockColumnDefs(
    "location",
    itemType || ItemType.Normal
  );

  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");
  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const formatFilter = {
        ...filterModel,
        sku_name: {
          filterType: "text",
          type: "equals",
          filter: itemSkuName,
        },
        branch_id:
          is_hq === "false"
            ? {
                type: "equals",
                filterType: "number",
                filter: parseInt(branch_id ?? "0"),
              }
            : undefined,
      };

      try {
        const { ItemSkuQtysByBinLocationView } = await graphQLClient.request(
          CURRENT_STOCK_BY_LOCATION_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: ItemSkuQtysByBinLocationView.results as any[],
          rowCount: ItemSkuQtysByBinLocationView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const { bundleSkuQty } = useItemCurrentStockSku(
    itemSkuName || "",
    bundleItemList || []
  );

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    params.api.onFilterChanged();
  };

  if (itemType === ItemType.Bundle)
    return (
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={bundleSkuQty.sku_qty_list_by_location}
        height={665}
        disabledSidebar
      />
    );

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onGridReady={onGridReady}
      disabledSidebar
    />
  );
};

export default CurrentStockByLocation;
