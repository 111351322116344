import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const useCustomerDepositOption = (status?: string | null) => {
  const { t } = useTranslation();
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]); //ยกเลิก, คักลอก
        break;
      case "wait_deduction":
        setSelectedModifyOptions([
          {
            value: t("sales.external_reference_no"),
            disabled: false,
          },
          ...defaultOptions,
        ]);
        break;
      case "partially_deduction":
        setSelectedModifyOptions([
          {
            value: t("sales.external_reference_no"),
            disabled: false,
          },
          defaultOptions[0],
          defaultOptions[1],
        ]);
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]]);
        break;
      case "finished":
        setSelectedModifyOptions([
          {
            value: t("sales.external_reference_no"),
            disabled: false,
          },
          defaultOptions[1],
        ]);
        break;
      default:
        break;
    }
  }, [defaultOptions, status, t]);

  return selectModifyOptions;
};
