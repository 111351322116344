import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";

const Inventory = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: t("inventory.goods_receive.index"),
      to: "/inventory/goods-receive",
    },
    {
      name: t("inventory.goods_return.index"),
      to: "/inventory/goods-return",
    },
    {
      name: t("inventory.goods_issue.index"),
      to: "/inventory/goods-issue",
    },
    {
      name: t("inventory.goods_transfer.index"),
      to: "/inventory/goods-transfer",
    },
    {
      name: t("inventory.goods_adjustment.index"),
      to: "/inventory/goods-adjustment",
    },
    {
      name: t("inventory.price_list.index"),
      to: "/inventory/price-list",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Inventory;
