import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next"
import { PurchaseExportType } from "../../../generated/purchase";
import { usePurchaseOrderReport } from "../../../hooks/Purchase/PurchaseOrder/use-purchase-order-report";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { Typography } from "@mui/material";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import PurchaseOrderByBranchReportTable from "../../../components/Table/Purchase/Report/PurchaseOrderByBranchReportTable";

const PurchaseOrderByBranchReport = () => {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact>(null);

    const { onBtnExport, isLoading } = usePurchaseOrderReport(
        gridRef,
        PurchaseExportType.PurchaseOrderByBranch
    );

    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("purchase.index"),
            to: "/purchase"
        },
        {
            name: t("report"),
            to: "/purchase/report"
        },
        {
            name: t("purchase.report.purchase_order_by_branch")
        },
    ];

    return (
        <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <HeaderLayout>
                <Typography variant="h5">
                    {`${t("purchase.report.purchase_order_by_branch")}`}
                </Typography>
                <CustomizedButton
                    title={`${t("button.export")}`}
                    variant="contained"
                    onClick={onBtnExport}
                    disabled={isLoading}
                />
            </HeaderLayout>
            <PurchaseOrderByBranchReportTable gridRef={gridRef} />
        </>
    );
};

export default PurchaseOrderByBranchReport;