import { Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { IGoodsAdjustment } from "../../../../types/Inventory/GoodsAdjustment";

type Props = {
  nestedIndex: number;
};

const GoodsAdjustmentSumStock = ({ nestedIndex }: Props) => {
  const { control } = useFormContext<IGoodsAdjustment>();
  const watchTrace = useWatch({
    control,
    name: `trace_entry_list.${nestedIndex}`,
  });

  return (
    <Typography fontSize={14}>
      {Number(watchTrace?.qty) + (watchTrace?.stock_qty || 0)}
    </Typography>
  );
};

export default GoodsAdjustmentSumStock;
