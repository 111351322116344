import { useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { Box, IconButton } from "@mui/material";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActivityLogDocumentType } from "../../../generated/sales";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import DocumentInfoTab from "./DocumentInfoTab";
import GoodsReturnTab from "./GoodsReturnTab";
import { ISalesReturn } from "../../../types/Sales/salesReturn";
import {
  salesReturnSchema,
  salesReturnValidation,
} from "../../../components/Form/Sales/Return/schema";

const SalesReturnContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const { id } = useParams();

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: `ใบ${t("inventory.goods_receive.index")} (รับคืน)`,
      path: `${pathname}?tab=goods_return`,
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "goods_return":
        return <GoodsReturnTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const branch_id = sessionStorage.getItem("branch-id");

  const methods = useForm<ISalesReturn>({
    defaultValues: {
      ...salesReturnSchema,
      branch_id: branch_id ? parseInt(branch_id) : undefined,
    },
    resolver: yupResolver<any>(salesReturnValidation),
  });

  const { control, getValues } = methods;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.return.index"),
      to: "/sales/return",
    },
    {
      name: id
        ? getValues("unique_id") || "-"
        : t("button.create") + t("sales.return.index"),
    },
  ];

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <FormProvider {...methods}>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1040}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        {id && (
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setOpenDrawer(true);
              }}
              sx={{
                color: (theme) => theme.palette.primary.main,
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      {(status === "approved" || status === "finished") && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.SalesReturn}
        />
      )}
    </FormProvider>
  );
};

export default SalesReturnContainer;
