import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const CustomDate = forwardRef<any, any>((props, ref) => {
  const [portal, setPortal] = useState(false);
  const [day, setDay] = useState<Date>();
  const refInput = useRef(null);
  const dateRef = useRef<any>(null);

  const onDateChanged = (selectedDates: Date) => {
    setDay(selectedDates);
    dateRef.current = selectedDates;
    props.onDateChanged();
  };

  useEffect(() => {
    if (refInput.current) {
      setPortal(true);
    }
  }, []);

  useEffect(() => {
    if (portal) {
      var element = document.getElementById("custom-date");
      if (element) element.classList.add("ag-custom-component-popup");
    }
  }, [portal]);

  useImperativeHandle(ref, () => ({
    getDate() {
      return dateRef.current;
    },
    setDate(date: Date) {
      dateRef.current = date;
      setDay(date);
    },
  }));

  return (
    <div className="ag-input-wrapper" role="presentation">
      <DatePicker
        className="date-picker"
        ref={refInput}
        selected={day}
        onChange={onDateChanged}
        dateFormat="dd/MM/yyyy"
        portalId="custom-date"
      />
    </div>
  );
});

export default CustomDate;
