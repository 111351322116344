import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import SalesPaymentTable from "../../../components/Table/Sales/Payment";
import SalesDeductionTable from "../../../components/Table/Sales/Deduction";
import {
  CashSalesFindUniqueQuery,
  useCashSalesFindUniqueQuery,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useParams } from "react-router-dom";
import PaymentTabSummary from "../../../components/Form/Sales/Payment/PaymentTabSummary";
import { numberFormatter } from "../../../utils/Formatter/Global";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../types/Sales";

const PaymentTab = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } =
    useCashSalesFindUniqueQuery<CashSalesFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const paymentAmount: number =
    data?.CashSalesFindUnique.payment_list &&
    data?.CashSalesFindUnique.payment_list.length > 0
      ? data.CashSalesFindUnique.payment_list.reduce(
          (acc, channel) => acc + (channel.payment_amount || 0),
          0
        )
      : 0;

  const deductionAmount: number =
    data?.CashSalesFindUnique.deduction_document_list &&
    data?.CashSalesFindUnique.deduction_document_list.length > 0
      ? data?.CashSalesFindUnique.deduction_document_list.reduce(
          (acc, deduct) => acc + (deduct.deduction_amount || 0),
          0
        )
      : 0;

  const withholdingTaxAmount: number =
    data?.CashSalesFindUnique.payment_list &&
    data?.CashSalesFindUnique.payment_list.length > 0
      ? data.CashSalesFindUnique.payment_list.reduce(
          (acc, channel) => acc + (channel.withholding_tax_amount || 0),
          0
        )
      : 0;

  const netAmount: number = data?.CashSalesFindUnique.net_amount || 0;

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography fontWeight={600} mb={2}>
          {t("sales.deduction.index")}
        </Typography>
        <SalesDeductionTable
          data={
            (data?.CashSalesFindUnique
              .deduction_document_list as ISalesDeduction[]) || []
          }
          documentType="cash_sales"
        />

        <Grid container maxWidth={984} spacing={0}>
          <Grid item xs={12} sm={12} md={6.5} />
          <Grid item xs={12} sm={12} md={5.5}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                gap: 3,
                py: 0.5,
                bgcolor: (theme) => theme.palette.primary.light,
              }}
            >
              <>
                <Typography
                  variant="subtitle1"
                  textAlign={"right"}
                  flex={2}
                  fontWeight={600}
                  color={"primary.main"}
                >
                  {t("sales.deduction.deduction_amount")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    minWidth: 150,
                    textAlign: "right",
                    mr: 2,
                    flex: 1,
                    fontWeight: 600,
                    color: "primary.main",
                  }}
                >
                  {numberFormatter(deductionAmount)}
                </Typography>
              </>
              <Typography
                variant="subtitle1"
                sx={{ ml: 2, mr: 4, fontWeight: 600, color: "primary.main" }}
              >
                บาท
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomizedBox>
      <CustomizedBox margin={"1rem 0"}>
        <Typography fontWeight={600} mb={2}>
          ช่องทางการชำระเงิน
        </Typography>
        <SalesPaymentTable
          data={
            (data?.CashSalesFindUnique
              .payment_list as ISalesPaymentChannel[]) || []
          }
          documentType="cash_sales"
        />
        <Grid container maxWidth={984} spacing={0}>
          <Grid item xs={12} sm={12} md={6.5} />
          <Grid item xs={12} sm={12} md={5.5}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                gap: 3,
                py: 0.5,
                bgcolor: (theme) => theme.palette.primary.light,
              }}
            >
              <>
                <Typography
                  variant="subtitle1"
                  textAlign={"right"}
                  flex={2}
                  fontWeight={600}
                  color={"primary.main"}
                >
                  {t("sales.payment.payment_amount")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    minWidth: 150,
                    textAlign: "right",
                    mr: 2,
                    flex: 1,
                    fontWeight: 600,
                    color: "primary.main",
                  }}
                >
                  {numberFormatter(paymentAmount)}
                </Typography>
              </>
              <Typography
                variant="subtitle1"
                sx={{ ml: 2, mr: 4, fontWeight: 600, color: "primary.main" }}
              >
                บาท
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomizedBox>
      <CustomizedBox margin={0}>
        <PaymentTabSummary
          paymentAmount={paymentAmount}
          deductionAmount={deductionAmount}
          withholdingTaxAmount={withholdingTaxAmount}
          netAmount={netAmount}
        />
      </CustomizedBox>
    </>
  );
};

export default PaymentTab;
