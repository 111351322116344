import { gql } from "graphql-request";

export const SALES_ITEM_EXPORT_VIEW = gql`
  query SalesItemExportViewsAggrid($aggridInput: AnyAggridInput) {
    SalesItemExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        item_name
        qty
        uom_name
        item_pre_discount_amount
        item_discount
        item_additional_discount
        post_discount_amount
        item_vat_amount
        item_net_amount
        item_withholding_tax_amount
        total_amount
        item_qty_to_ship
        item_qty_shipped
        item_qty_invoiced
        item_qty_returned
      }
    }
  }
`;

export const SALES_ITEM_BY_BRANCH_EXPORT_VIEW = gql`
  query SalesItemByBranchExportViewsAggrid($aggridInput: AnyAggridInput) {
    SalesItemByBranchExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        branch_id
        branch_name
        unique_id
        item_name
        qty
        uom_name
        item_pre_discount_amount
        item_discount
        item_additional_discount
        post_discount_amount
        item_vat_amount
        item_net_amount
        item_withholding_tax_amount
        total_amount
        item_qty_to_ship
        item_qty_shipped
        item_qty_invoiced
        item_qty_returned
      }
    }
  }
`;

export const SALES_BY_CUSTOMER_EXPORT_VIEW = gql`
  query SalesByCustomerExportViewsAggrid($aggridInput: AnyAggridInput) {
    SalesByCustomerExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        customer_id
        customer_name
        document_type
        status
        total_document
        item_pre_discount_amount
        item_discount
        additional_discount
        post_discount_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
      }
    }
  }
`;
