import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import CustomizedSnackbar from "./components/Custom/CustomizedSnackbar";
import { BrowserRouter } from "react-router-dom";
import { StateContextProvider } from "./contexts/auth-context";
import { NavbarContextProvider } from "./contexts/navbar-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DisableContextProvider } from "./contexts/disable-context";
import { ActivityLogContextProvider } from "./contexts/activity-log-context";
import AuthMiddleware from "./middleware/AuthMiddleware";
import "./index.css"; // Import the CSS

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CustomizedSnackbar>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <BrowserRouter>
          <StateContextProvider>
            <AuthMiddleware>
              <NavbarContextProvider>
                <ActivityLogContextProvider>
                  <DisableContextProvider>
                    <App />
                  </DisableContextProvider>
                </ActivityLogContextProvider>
              </NavbarContextProvider>
            </AuthMiddleware>
          </StateContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </CustomizedSnackbar>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
