import { IExporterPurchase, ISelectOption } from "../../../types/global";
import { PurchaseExportType } from "../../../generated/purchase";

export const getPurchaseExportTypeOptions = (pageType: PurchaseExportType) => {
  switch (pageType) {
    case PurchaseExportType.PurchaseRequest:
      return purchaseRequestTypeOptions;
    case PurchaseExportType.PurchaseOrder:
      return purchaseOrderTypeOptions;
    case PurchaseExportType.PurchaseReturn:
      return purchaseReturnTypeOptions;
    default:
      return [];
  }
};

export const getPurchaseExporterSchema = (pageType: PurchaseExportType) => {
  const exporterSchena: IExporterPurchase = {
    type: pageType,
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  return exporterSchena;
};

const purchaseRequestTypeOptions: ISelectOption[] = [
  {
    label: "ใบขอซื้อ",
    value: PurchaseExportType.PurchaseRequest,
  },
];

const purchaseOrderTypeOptions: ISelectOption[] = [
  {
    label: "ใบสั่งซื้อ",
    value: PurchaseExportType.PurchaseOrder,
  },
];

const purchaseReturnTypeOptions: ISelectOption[] = [
  {
    label: "ใบส่งคืน",
    value: PurchaseExportType.PurchaseReturn,
  },
];
