import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
// import { useSelector } from "react-redux";
// import { filterUom } from "../../../utils/dataTransformer";

import { Box, Grid, Typography } from "@mui/material";

import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { useUnitOptions } from "../../../../hooks/use-inventory-setting-option";
import { useDisable } from "../../../../hooks/use-disable";
import LabelInput from "../../../UI/LabelInput";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

const Logistic = () => {
  const { t } = useTranslation();

  const {
    control,
    getValues,
    // formState: { errors },
  } = useFormContext();

  const [disabled] = useDisable();

  const { option, renderOption } = useUnitOptions();

  const dimensionList = [
    {
      testId: "item-package-width-text-field",
      label: t("inventory.items.width"),
      name: "delivery_attribute.width",
      uom: "delivery_attribute.width_uom_id",
      // error: errors.delivery_attribute?.width,
      // uomError: errors.delivery_attribute?.width_uom_id,
      disabledUom: true,
    },
    {
      testId: "item-package-length-text-field",
      label: t("inventory.items.length"),
      name: "delivery_attribute.length",
      uom: "delivery_attribute.length_uom_id",
      // error: errors.delivery_attribute?.length,
      // uomError: errors.delivery_attribute?.length_uom_id,
      disabledUom: true,
    },
    {
      testId: "item-package-height-text-field",
      label: t("inventory.items.height"),
      name: "delivery_attribute.height",
      uom: "delivery_attribute.height_uom_id",
      // error: errors.delivery_attribute?.height,
      // uomError: errors.delivery_attribute?.height_uom_id,
      disabledUom: true,
    },
    {
      testId: "item-package-weight-text-field",
      label: t("inventory.items.weight"),
      name: "delivery_attribute.weight",
      uom: "delivery_attribute.weight_uom_id",
      // error: errors.delivery_attribute?.weight,
      // uomError: errors.delivery_attribute?.weight_uom_id,
      disabledUom: true,
    },
  ];

  // const renderFieldValue = (value: string) =>
  //   allUoms.find((uom) => uom?.unique_id === value)?.name;

  return (
    <Box>
      <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.deliveryDimension")}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {dimensionList.map((dimension, index) => (
            <Grid container spacing={2} key={index} mb={2}>
              <Grid item xs={6}>
                <ControlledNumberTextField
                  control={control}
                  name={dimension.name}
                  label={dimension.label}
                  viewMode={disabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                {disabled ? (
                  <LabelInput
                    label={t("inventory.unit")}
                    value={renderOption(getValues(dimension.uom))}
                  />
                ) : (
                  <Controller
                    name={dimension.uom}
                    control={control}
                    render={({ field }) => (
                      <CustomizedComboBox
                        {...field}
                        label={t("inventory.unit")}
                        options={option}
                        onChange={(_, newValue) =>
                          field.onChange(newValue ? newValue.value : null)
                        }
                        value={renderOption(field.value)}
                        // error={Boolean(dimension.uomError)}
                        // helperText={dimension.uomError?.message}
                        disabled={dimension.disabledUom}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Logistic;
