import {
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  styled,
  StepperProps,
} from "@mui/material";

type Props = StepperProps & {
  steps: string[];
  activeStep: number;
  notAlternativeLabel?: boolean;
  errors?: boolean;
};

type StyledStepIconProps = {
  errors?: boolean;
};

const CustomStyledStepIcon = styled(StepIcon)<StyledStepIconProps>(
  ({ theme, errors }) => ({
    "& .lastStep": {
      color: errors ? "#FB8C00" : theme.palette.text.primary,
    },
    // Define your custom styles here
    // color: errors ? "#FB8C00" : theme.palette.text.primary, // Change this to your desired color
  })
);

const CustomizedSteppers = ({
  steps,
  activeStep,
  notAlternativeLabel,
  errors,
}: Props) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel={notAlternativeLabel ?? true}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              StepIconComponent={CustomStyledStepIcon}
              className={index === steps.length - 1 && errors ? "lastStep" : ""}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomizedSteppers;
