import * as Yup from "yup";
import { ILogin } from "../../../types/Auth/login";

export const loginSchema: ILogin = {
  email: "",
  password: "",
};

export const loginValidation = Yup.object().shape({
  email: Yup.string().required("กรุณากรอก").nullable(),
  password: Yup.string().required("กรุณากรอก").nullable(),
});
