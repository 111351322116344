import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  AnyAggridInput: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Json: { input: any; output: any; }
};

export enum AccessPermission {
  Approval = 'approval',
  Editor = 'editor',
  Restricted = 'restricted',
  Viewer = 'viewer'
}

export type ActivityLog = {
  __typename?: 'ActivityLog';
  activity_detail?: Maybe<ActivityLogDetailObject>;
  activity_type?: Maybe<ActivityType>;
  created_by?: Maybe<CreatorObject>;
  created_date?: Maybe<Scalars['DateTime']['output']>;
  document_type?: Maybe<ActivityLogDocumentType>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_id?: Maybe<Scalars['Int']['output']>;
};

export type ActivityLogByRefereceIdInput = {
  document_type: ActivityLogDocumentType;
  reference_id: Scalars['Int']['input'];
};

export type ActivityLogCopyObject = {
  __typename?: 'ActivityLogCopyObject';
  id?: Maybe<Scalars['Int']['output']>;
  unique_id?: Maybe<Scalars['String']['output']>;
};

export type ActivityLogCopyObjectInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityLogCreateInput = {
  activity_detail: ActivityLogDetailObjectInput;
  activity_type: ActivityType;
  created_by: CreatorInputObject;
  document_type: ActivityLogDocumentType;
  reference_id: Scalars['Int']['input'];
};

export type ActivityLogDetailObject = {
  __typename?: 'ActivityLogDetailObject';
  added_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  attachment_list?: Maybe<Array<Scalars['String']['output']>>;
  copied_from?: Maybe<ActivityLogCopyObject>;
  copied_to?: Maybe<ActivityLogCopyObject>;
  curr_status?: Maybe<Scalars['String']['output']>;
  deleted_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  prev_status?: Maybe<Scalars['String']['output']>;
  updated_fields?: Maybe<Scalars['Json']['output']>;
};

export type ActivityLogDetailObjectInput = {
  added_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  attachment_list?: InputMaybe<Array<Scalars['String']['input']>>;
  copied_from?: InputMaybe<ActivityLogCopyObjectInput>;
  copied_to?: InputMaybe<ActivityLogCopyObjectInput>;
  curr_status?: InputMaybe<Scalars['String']['input']>;
  deleted_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  message?: InputMaybe<Array<Scalars['String']['input']>>;
  prev_status?: InputMaybe<Scalars['String']['input']>;
  secondary_operation?: InputMaybe<ActivityType>;
  updated_fields?: InputMaybe<Scalars['Json']['input']>;
};

export enum ActivityLogDocumentType {
  CashSales = 'cash_sales',
  CreditNote = 'credit_note',
  Customer = 'customer',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  GoodsAdjustment = 'goods_adjustment',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer',
  Item = 'item',
  Promotion = 'promotion',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  User = 'user',
  Vendor = 'vendor'
}

export type ActivityLogFindManyAggrid = {
  __typename?: 'ActivityLogFindManyAggrid';
  count: Scalars['Int']['output'];
  results: Array<ActivityLog>;
};

export type ActivityLogRelatedEmployeeObject = {
  __typename?: 'ActivityLogRelatedEmployeeObject';
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export type ActivityLogRelatedEmployeeObjectInput = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityLogUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum ActivityType {
  AddRelatedEmployee = 'add_related_employee',
  Approve = 'approve',
  Cancel = 'cancel',
  CancelPendingScan = 'cancel_pending_scan',
  Comment = 'comment',
  Copy = 'copy',
  Create = 'create',
  Delete = 'delete',
  DeleteRelatedEmployee = 'delete_related_employee',
  Document = 'document',
  Edit = 'edit',
  Image = 'image',
  StatusChange = 'status_change'
}

export enum AdditionalDiscountType {
  Baht = 'baht',
  Percent = 'percent',
  Promotion = 'promotion'
}

export enum AggridArrayFilterType {
  Array = 'array'
}

export type AggridBooleanFilterModelInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AggridBooleanFilterModelType>;
};

export enum AggridBooleanFilterModelType {
  Equals = 'equals',
  NotEqual = 'notEqual'
}

export type AggridDateFilterModelInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridDateFilterType>;
  type?: InputMaybe<AggridDateFilterModelType>;
};

export enum AggridDateFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridDateFilterType {
  Date = 'date'
}

export type AggridFloatFilterModelInput = {
  condition1?: InputMaybe<AggridFloatFilterModelInputCondition>;
  condition2?: InputMaybe<AggridFloatFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridFloatFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridGenericArrayFilterModelInput = {
  filterType?: InputMaybe<AggridArrayFilterType>;
  type?: InputMaybe<AggridGenericArrayFilterModelType>;
  values?: InputMaybe<Scalars['Any']['input']>;
};

export enum AggridGenericArrayFilterModelType {
  Equals = 'equals',
  Has = 'has',
  HasEvery = 'hasEvery',
  HasSome = 'hasSome',
  IsEmpty = 'isEmpty'
}

export enum AggridISimpleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export type AggridIntFilterModelInput = {
  condition1?: InputMaybe<AggridIntFilterModelInputCondition>;
  condition2?: InputMaybe<AggridIntFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Int']['input']>;
  filterTo?: InputMaybe<Scalars['Int']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridIntFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Int']['input']>;
  filterTo?: InputMaybe<Scalars['Int']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export enum AggridNumberFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridNumberFilterType {
  Number = 'number'
}

export enum AggridObjectArrayFilterModelType {
  Every = 'every',
  None = 'none',
  Some = 'some'
}

export enum AggridObjectArrayFilterType {
  ObjectArray = 'objectArray'
}

export enum AggridOperatorFilter {
  And = 'AND',
  Or = 'OR'
}

export type AggridSetSingleFilterModelInput = {
  condition1?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  condition2?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<AggridOperatorFilter>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>;
};

export type AggridSetSingleFilterModelInputCondition = {
  filterType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AggridSetSingleFilterModelType>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>;
};

export enum AggridSetSingleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual'
}

export type AggridSortModelItem = {
  colId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortOrder>;
};

export type AggridSortModelItemInput = {
  colId: Scalars['String']['input'];
  sort: SortOrder;
};

export type AggridStringFilterModelInput = {
  condition1?: InputMaybe<AggridStringFilterModelInputCondition>;
  condition2?: InputMaybe<AggridStringFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export type AggridStringFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export enum AggridStringFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export enum AggridStringModeOption {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type AggridTemplateInput = {
  endRow?: InputMaybe<Scalars['Int']['input']>;
  filterModel?: InputMaybe<Scalars['Any']['input']>;
  sortModel?: InputMaybe<Array<AggridSortModelItemInput>>;
  startRow?: InputMaybe<Scalars['Int']['input']>;
};

export enum AggridTextFilterType {
  Text = 'text'
}

export type ApprovalProgress = {
  __typename?: 'ApprovalProgress';
  approval_template?: Maybe<ApprovalTemplate>;
  approval_template_id: Scalars['Int']['output'];
  approver: User;
  approver_id: Scalars['Int']['output'];
  created_date: Scalars['DateTime']['output'];
  document_id: Scalars['Int']['output'];
  document_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  step_number: Scalars['Int']['output'];
  updated_date?: Maybe<Scalars['DateTime']['output']>;
};

export type ApprovalTemplate = {
  __typename?: 'ApprovalTemplate';
  approver_id_list?: Maybe<Array<Scalars['String']['output']>>;
  approver_list?: Maybe<Array<User>>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  required_approval: Scalars['Int']['output'];
  step_number: Scalars['Int']['output'];
  workflow_document_type: Scalars['String']['output'];
  workflow_step_number: Scalars['Int']['output'];
  workflow_template?: Maybe<WorkflowTemplate>;
};

export type ApprovalTemplateCreateInput = {
  approver_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  required_approval?: InputMaybe<Scalars['Int']['input']>;
  step_number?: InputMaybe<Scalars['Int']['input']>;
  workflow_document_type: WorkflowDocumentType;
  workflow_step_number?: InputMaybe<Scalars['Int']['input']>;
};

export type ApprovalTemplateStep_NumberWorkflow_Step_NumberWorkflow_Document_TypeCompoundUniqueInput = {
  step_number: Scalars['Int']['input'];
  workflow_document_type: WorkflowDocumentType;
  workflow_step_number: Scalars['Int']['input'];
};

export type ApprovalTemplateUpdateInput = {
  approver_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  required_approval?: InputMaybe<Scalars['Int']['input']>;
  step_number?: InputMaybe<Scalars['Int']['input']>;
  workflow_document_type?: InputMaybe<WorkflowDocumentType>;
  workflow_step_number?: InputMaybe<Scalars['Int']['input']>;
};

export type ApprovalTemplateUpsertInput = {
  approver_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  required_approval?: InputMaybe<Scalars['Int']['input']>;
  step_number: Scalars['Int']['input'];
};

export type ApprovalTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  step_number_workflow_step_number_workflow_document_type?: InputMaybe<ApprovalTemplateStep_NumberWorkflow_Step_NumberWorkflow_Document_TypeCompoundUniqueInput>;
};

export type BinLocation = {
  __typename?: 'BinLocation';
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse_level_3: WarehouseLevel3;
};

export type BinLocationCreateInput = {
  name: Scalars['String']['input'];
  warehouse_level_3_id: Scalars['Int']['input'];
};

export type BinLocationUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BinLocationUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_level_3_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Branch = {
  __typename?: 'Branch';
  address: Scalars['Json']['output'];
  created_date?: Maybe<Scalars['DateTime']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_hq: Scalars['Boolean']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  short_name: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  unique_id: Scalars['String']['output'];
  user_id_list: Array<Scalars['Int']['output']>;
  user_list?: Maybe<Array<Maybe<BranchUser>>>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type BranchAggrid = {
  __typename?: 'BranchAggrid';
  count: Scalars['Int']['output'];
  results: Array<Branch>;
};

export type BranchCreateInput = {
  address: Scalars['Json']['input'];
  created_date?: InputMaybe<Scalars['DateTime']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  is_hq: Scalars['Boolean']['input'];
  last_updated_date?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  short_name: Scalars['String']['input'];
  status: Scalars['Int']['input'];
  unique_id: Scalars['String']['input'];
  user_id_list: Array<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type BranchUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type BranchUpdateInput = {
  address?: InputMaybe<Scalars['Json']['input']>;
  created_date?: InputMaybe<Scalars['DateTime']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  is_hq?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_date?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  user_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type BranchUser = {
  __typename?: 'BranchUser';
  branch?: Maybe<Branch>;
  branch_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type BranchUserAddInput = {
  branch_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

export type BranchUserDeleteInput = {
  branch_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

export type BranchUserUpsertOutput = {
  __typename?: 'BranchUserUpsertOutput';
  count_add?: Maybe<Scalars['Int']['output']>;
  count_delete?: Maybe<Scalars['Int']['output']>;
};

export type BranchUserWhere = {
  user_id_in_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Brand = {
  __typename?: 'Brand';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type BrandCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type BrandUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type BrandUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type BundleItemDetail = {
  __typename?: 'BundleItemDetail';
  bundle_item: Item;
  bundle_item_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  item: Item;
  item_sku_detail?: Maybe<ItemSkuDetail>;
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Int']['output'];
  ratio_percentage?: Maybe<Scalars['Float']['output']>;
  sku_desc?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
  sorting_order?: Maybe<Scalars['Int']['output']>;
  special_price?: Maybe<Scalars['Float']['output']>;
};

export type CashSales = {
  __typename?: 'CashSales';
  account_id?: Maybe<Scalars['String']['output']>;
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  deduction_document_list?: Maybe<Array<SalesDeduction>>;
  deduction_total_amount?: Maybe<Scalars['Float']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_original_print?: Maybe<Scalars['Json']['output']>;
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  need_tax_invoice?: Maybe<Scalars['Boolean']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  paid_net_amount?: Maybe<Scalars['Float']['output']>;
  paid_total_amount?: Maybe<Scalars['Float']['output']>;
  payment_list?: Maybe<Array<SalesPayment>>;
  payment_total_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  shipping_cost?: Maybe<Scalars['Float']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  tax_invoice_date?: Maybe<Scalars['DateTime']['output']>;
  tax_invoice_no?: Maybe<Scalars['String']['output']>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type CategoryGroup = {
  __typename?: 'CategoryGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type CategoryGroupCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type CategoryGroupUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryGroupUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename?: 'Company';
  address_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  contact_channel_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  id: Scalars['Int']['output'];
  identity_no?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  is_active: Scalars['Int']['output'];
  is_registered_vat?: Maybe<Scalars['Boolean']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_currency: Scalars['String']['output'];
  name: Scalars['String']['output'];
  register_date?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  vat_registration_date?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyCreateInput = {
  address_list?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  contact_channel_list?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  identity_no?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  is_active: Scalars['Int']['input'];
  is_registered_vat?: InputMaybe<Scalars['Boolean']['input']>;
  main_currency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  register_date?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  vat_registration_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum CompanyCurrency {
  Thb = 'THB',
  Usd = 'USD'
}

export enum CompanyType {
  Association = 'association',
  Company = 'company',
  LimitedPartnership = 'limited_partnership',
  Other = 'other',
  PublicLimited = 'public_limited',
  RegisteredOrdinaryPartnership = 'registered_ordinary_partnership'
}

export type CompanyUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUpdateInput = {
  address_list?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  contact_channel_list?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  identity_no?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  is_active: Scalars['Int']['input'];
  is_registered_vat?: InputMaybe<Scalars['Boolean']['input']>;
  main_currency?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  register_date?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  vat_registration_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatableField = {
  __typename?: 'CreatableField';
  creatable_fields_name: Scalars['String']['output'];
  document_type: CreatableFieldDocumentType;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export type CreatableFieldCreateInput = {
  creatable_fields_name: Scalars['String']['input'];
  document_type: CreatableFieldDocumentType;
  name: Scalars['String']['input'];
};

export enum CreatableFieldDocumentType {
  Batch = 'batch',
  Contact = 'contact',
  CreditNote = 'credit_note',
  Customer = 'customer',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  Employee = 'employee',
  Event = 'event',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer',
  Item = 'item',
  PaymentChannel = 'payment_channel',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  Project = 'project',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial',
  Task = 'task',
  Vendor = 'vendor'
}

export type CreatableFieldFindManyInput = {
  creatable_fields_name?: InputMaybe<Scalars['Any']['input']>;
  document_type: CreatableFieldDocumentType;
  name?: InputMaybe<Scalars['Any']['input']>;
};

export type CreatableFieldNameDocument_TypeCreatable_Fields_NameCompoundUniqueInput = {
  creatable_fields_name: Scalars['String']['input'];
  document_type: CreatableFieldDocumentType;
  name: Scalars['String']['input'];
};

export type CreatableFieldUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_document_type_creatable_fields_name?: InputMaybe<CreatableFieldNameDocument_TypeCreatable_Fields_NameCompoundUniqueInput>;
};

export type CreatableFieldUpdateInput = {
  creatable_fields_name?: InputMaybe<Scalars['String']['input']>;
  document_type?: InputMaybe<CreatableFieldDocumentType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePosAccessTokenInput = {
  branch_id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorInputObject = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorObject = {
  __typename?: 'CreatorObject';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Array<Scalars['String']['output']>>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export type CustomerAttribute = {
  __typename?: 'CustomerAttribute';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  short_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CustomerAttributeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerAttributeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerAttributeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerDeposit = {
  __typename?: 'CustomerDeposit';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  branch?: Maybe<Branch>;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  deposit_list?: Maybe<Array<CustomerDepositItem>>;
  due_date: Scalars['DateTime']['output'];
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_original_print?: Maybe<Scalars['Json']['output']>;
  issue_date: Scalars['DateTime']['output'];
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  need_tax_invoice?: Maybe<Scalars['Boolean']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  paid_net_amount?: Maybe<Scalars['Float']['output']>;
  paid_total_amount?: Maybe<Scalars['Float']['output']>;
  payment_list?: Maybe<Array<SalesPayment>>;
  payment_total_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remaining_deposit_amount?: Maybe<Scalars['Float']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  tax_invoice_date?: Maybe<Scalars['DateTime']['output']>;
  tax_invoice_no?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type CustomerDepositItem = {
  __typename?: 'CustomerDepositItem';
  customer_deposit?: Maybe<CustomerDeposit>;
  customer_deposit_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  reference_document_id?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  vat_percentage?: Maybe<Scalars['String']['output']>;
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export type CustomerGroup = {
  __typename?: 'CustomerGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type CustomerGroupCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type CustomerGroupUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerGroupUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type CustomerTypeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type CustomerTypeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryAttribute = {
  __typename?: 'DeliveryAttribute';
  height?: Maybe<Scalars['Float']['output']>;
  height_uom?: Maybe<Unit>;
  height_uom_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  item: Item;
  item_id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  length_uom?: Maybe<Unit>;
  length_uom_id?: Maybe<Scalars['Int']['output']>;
  thick?: Maybe<Scalars['Float']['output']>;
  thick_uom?: Maybe<Unit>;
  thick_uom_id?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
  volume_uom?: Maybe<Unit>;
  volume_uom_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_uom?: Maybe<Unit>;
  weight_uom_id?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  width_uom?: Maybe<Unit>;
  width_uom_id?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryType = {
  __typename?: 'DeliveryType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type DeliveryTypeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type DeliveryTypeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Department = {
  __typename?: 'Department';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type DepartmentCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type DepartmentUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type DepartmentUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ExportGenerateInput = {
  end_date?: InputMaybe<Scalars['DateTime']['input']>;
  report_type: GeneralExportType;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum GenderType {
  Female = 'female',
  Male = 'male'
}

export enum GeneralExportType {
  User = 'user'
}

export type GeneralImportInput = {
  data: Array<Scalars['Any']['input']>;
  import_mode: GeneralImportMode;
  import_type: GeneralImportType;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export enum GeneralImportMode {
  Create = 'create',
  Update = 'update'
}

export type GeneralImportResult = {
  __typename?: 'GeneralImportResult';
  detail?: Maybe<Scalars['Json']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export enum GeneralImportType {
  User = 'user'
}

export enum GeneralModelType {
  Branch = 'branch',
  Brand = 'brand',
  CategoryGroup = 'category_group',
  CustomerGroup = 'customer_group',
  CustomerType = 'customer_type',
  DeliveryType = 'delivery_type',
  Department = 'department',
  MainCategory = 'main_category',
  Model = 'model',
  PaymentChannel = 'payment_channel',
  Position = 'position',
  SalesChannel = 'sales_channel',
  SalesType = 'sales_type',
  Segment = 'segment',
  Series = 'series',
  SubCategory = 'sub_category',
  Unit = 'unit',
  User = 'user',
  VendorGroup = 'vendor_group',
  VendorType = 'vendor_type',
  Warehouse = 'warehouse',
  Zone = 'zone'
}

export enum GeneralSubscriptionType {
  Import = 'import',
  Report = 'report'
}

export type Item = {
  __typename?: 'Item';
  attribute_list: Array<Scalars['Json']['output']>;
  barcode: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  bundle_item_detail_list: Array<BundleItemDetail>;
  category_group?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<Scalars['Json']['output']>;
  created_date: Scalars['DateTime']['output'];
  customer_insurance_duration?: Maybe<Scalars['Int']['output']>;
  deliver_uom?: Maybe<Unit>;
  deliver_uom_id?: Maybe<Scalars['Int']['output']>;
  delivery_attribute?: Maybe<DeliveryAttribute>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url: Array<Scalars['String']['output']>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  insurance_detail?: Maybe<Scalars['String']['output']>;
  insurance_name?: Maybe<Scalars['String']['output']>;
  is_active: Scalars['Int']['output'];
  is_purchasable?: Maybe<Scalars['Boolean']['output']>;
  is_saleable?: Maybe<Scalars['Boolean']['output']>;
  is_stockable?: Maybe<Scalars['Boolean']['output']>;
  item_variant_key_list: Array<ItemVariantKey>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_category?: Maybe<Scalars['String']['output']>;
  max_stock_qty?: Maybe<Scalars['Float']['output']>;
  min_purchase_request_qty?: Maybe<Scalars['Int']['output']>;
  min_stock_qty?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pre_sale?: Maybe<Scalars['Boolean']['output']>;
  purchase_standard_price?: Maybe<Scalars['Float']['output']>;
  purchase_uom?: Maybe<Unit>;
  purchase_uom_id?: Maybe<Scalars['Int']['output']>;
  purchase_vat_type?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  reorder_point?: Maybe<Scalars['Float']['output']>;
  replenishment_stock?: Maybe<Scalars['Boolean']['output']>;
  sale_price?: Maybe<Scalars['Float']['output']>;
  sale_vat_type?: Maybe<Scalars['String']['output']>;
  sales_uom?: Maybe<Unit>;
  sales_uom_id?: Maybe<Scalars['Int']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  seller_insurance_duration?: Maybe<Scalars['Int']['output']>;
  seller_unique_id: Scalars['String']['output'];
  selling_point_list: Array<Scalars['Json']['output']>;
  series?: Maybe<Scalars['String']['output']>;
  set_attribute?: Maybe<Scalars['Json']['output']>;
  sku_detail_list: Array<ItemSkuDetail>;
  sku_list: Array<ItemSkuValue>;
  special_price?: Maybe<Scalars['Float']['output']>;
  stock_uom?: Maybe<Unit>;
  stock_uom_id?: Maybe<Scalars['Int']['output']>;
  sub_category?: Maybe<Scalars['String']['output']>;
  tag_id_list: Array<Scalars['Int']['output']>;
  tag_list: Array<Tag>;
  tracability?: Maybe<Tracability>;
  type?: Maybe<ItemType>;
  unique_id: Scalars['String']['output'];
  uom_group?: Maybe<UomGroup>;
  uom_group_id?: Maybe<Scalars['Int']['output']>;
  vendor_contact?: Maybe<Scalars['Json']['output']>;
};

export type ItemAdjustment = {
  __typename?: 'ItemAdjustment';
  adjusted_price?: Maybe<Scalars['Float']['output']>;
  current_price?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  item_name: Scalars['String']['output'];
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_unique_id?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
};

export type ItemAttribute = {
  __typename?: 'ItemAttribute';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  set_item_attribute_id: Scalars['Int']['output'];
  short_name: Scalars['String']['output'];
  sorting_index: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ItemAttributeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  set_item_attribute_id: Scalars['Int']['input'];
  short_name: Scalars['String']['input'];
  sorting_index: Scalars['Int']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ItemAttributeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemAttributeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  sorting_index?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ItemAttributeUpdateManyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  sorting_index?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ItemDeleteOutput = {
  __typename?: 'ItemDeleteOutput';
  active_document_list?: Maybe<Array<Scalars['String']['output']>>;
  is_deletable?: Maybe<Scalars['Boolean']['output']>;
  item?: Maybe<Item>;
};

export type ItemPriceList = {
  __typename?: 'ItemPriceList';
  adjusted_item_list?: Maybe<Array<Maybe<ItemAdjustment>>>;
  adjusted_price?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  is_active: Scalars['Int']['output'];
  is_increase: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type ItemSkuDetail = {
  __typename?: 'ItemSkuDetail';
  available_qty?: Maybe<Scalars['Int']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  bundle_item_detail_list: Array<BundleItemDetail>;
  id: Scalars['Int']['output'];
  img_url: Array<Scalars['String']['output']>;
  item: Item;
  item_sku: ItemSkuValue;
  item_sku_qty?: Maybe<Array<ItemSkuQty>>;
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  purchase_ordered_qty?: Maybe<Scalars['Int']['output']>;
  purchase_standard_price?: Maybe<Scalars['Float']['output']>;
  sale_committed_qty?: Maybe<Scalars['Int']['output']>;
  sale_price?: Maybe<Scalars['Float']['output']>;
  sku_name: Scalars['String']['output'];
  special_price?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  stock_qty?: Maybe<Scalars['Int']['output']>;
  total_sku_qty?: Maybe<Scalars['Json']['output']>;
};

export type ItemSkuQty = {
  __typename?: 'ItemSkuQty';
  available_qty: Scalars['Int']['output'];
  barcode: Scalars['String']['output'];
  bin_id?: Maybe<Scalars['Int']['output']>;
  bin_location?: Maybe<BinLocation>;
  bin_name?: Maybe<Scalars['String']['output']>;
  expired_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  item_sku_detail?: Maybe<ItemSkuDetail>;
  item_unique_id?: Maybe<Scalars['String']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  lot_date?: Maybe<Scalars['DateTime']['output']>;
  manufactured_date?: Maybe<Scalars['DateTime']['output']>;
  purchase_ordered_qty: Scalars['Int']['output'];
  sale_committed_qty: Scalars['Int']['output'];
  serial_no?: Maybe<Scalars['String']['output']>;
  sku_name: Scalars['String']['output'];
  stock_qty: Scalars['Int']['output'];
  transit_qty: Scalars['Int']['output'];
  warehouse?: Maybe<Warehouse>;
  warehouse_id?: Maybe<Scalars['Int']['output']>;
};

export enum ItemSkuQtyScalarFieldEnum {
  BinId = 'bin_id',
  Id = 'id',
  SkuName = 'sku_name',
  WarehouseId = 'warehouse_id'
}

export type ItemSkuValue = {
  __typename?: 'ItemSkuValue';
  id: Scalars['Int']['output'];
  item?: Maybe<Item>;
  item_unique_id?: Maybe<Scalars['String']['output']>;
  item_variant_value: Array<ItemVariantValue>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  sku_detail?: Maybe<ItemSkuDetail>;
  sku_name: Scalars['String']['output'];
  variant_key_id: Scalars['Int']['output'];
  variant_value_id: Scalars['Int']['output'];
};

export enum ItemType {
  Bundle = 'bundle',
  Normal = 'normal',
  Service = 'service',
  Variant = 'variant'
}

export type ItemVariantKey = {
  __typename?: 'ItemVariantKey';
  id: Scalars['Int']['output'];
  item: Item;
  item_unique_id: Scalars['String']['output'];
  item_variant_value_list: Array<ItemVariantValue>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  variant_key_name: Scalars['String']['output'];
};

export type ItemVariantValue = {
  __typename?: 'ItemVariantValue';
  id: Scalars['Int']['output'];
  item_sku: Array<ItemSkuValue>;
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  variant_key: ItemVariantKey;
  variant_key_name: Scalars['String']['output'];
  variant_value_name: Scalars['String']['output'];
};

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  access_token: Scalars['String']['output'];
  user: User;
};

export type MainCategory = {
  __typename?: 'MainCategory';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type MainCategoryCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type MainCategoryUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type MainCategoryUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum MarketingModelType {
  PromotionSetting = 'promotion_setting'
}

export type Model = {
  __typename?: 'Model';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type ModelCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type ModelUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ModelUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum ModuleName {
  Branch = 'Branch',
  Company = 'Company',
  Customer = 'Customer',
  Role = 'Role',
  User = 'User',
  Vendor = 'Vendor'
}

export type Mutation = {
  __typename?: 'Mutation';
  ActivityLogCreate?: Maybe<ActivityLog>;
  ApprovalTemplateCreate: ApprovalTemplate;
  ApprovalTemplateDelete?: Maybe<ApprovalTemplate>;
  ApprovalTemplateUpdate: ApprovalTemplate;
  ApprovalTemplateUpsertMany: Array<ApprovalTemplate>;
  BinLocationCreate: BinLocation;
  BinLocationDelete: BinLocation;
  BinLocationUpdate: BinLocation;
  BranchCreate: Branch;
  BranchDelete?: Maybe<Branch>;
  BranchUpdate: Branch;
  BranchUserAdd: Scalars['Int']['output'];
  BranchUserUpsert: BranchUserUpsertOutput;
  BrandCreate: Brand;
  BrandDelete: Brand;
  BrandUpdate: Brand;
  CategoryGroupCreate: CategoryGroup;
  CategoryGroupDelete: CategoryGroup;
  CategoryGroupUpdate: CategoryGroup;
  CompanyCreate?: Maybe<Company>;
  CompanyUpdate?: Maybe<Company>;
  CreatableFieldCreate: CreatableField;
  CreatableFieldDelete: CreatableField;
  CreatableFieldUpdate: CreatableField;
  CustomerAttributeCreate: CustomerAttribute;
  CustomerAttributeDelete: CustomerAttribute;
  CustomerAttributeUpdate: CustomerAttribute;
  CustomerGroupCreate: CustomerGroup;
  CustomerGroupDelete: CustomerGroup;
  CustomerGroupUpdate: CustomerGroup;
  CustomerTypeCreate: CustomerType;
  CustomerTypeDelete: CustomerType;
  CustomerTypeUpdate: CustomerType;
  DeliveryTypeCreate: DeliveryType;
  DeliveryTypeDelete: DeliveryType;
  DeliveryTypeUpdate: DeliveryType;
  DepartmentCreate: Department;
  DepartmentDelete: Department;
  DepartmentUpdate: Department;
  ImportUser: GeneralImportResult;
  ItemAttributeCreate: ItemAttribute;
  ItemAttributeDelete: ItemAttribute;
  ItemAttributeUpdate: ItemAttribute;
  ItemAttributeUpdateMany?: Maybe<Array<ItemAttribute>>;
  MainCategoryCreate: MainCategory;
  MainCategoryDelete: MainCategory;
  MainCategoryUpdate: MainCategory;
  ModelCreate: Model;
  ModelDelete: Model;
  ModelUpdate: Model;
  PaymentChannelCreate: PaymentChannel;
  PaymentChannelDelete: PaymentChannel;
  PaymentChannelUpdate: PaymentChannel;
  PositionCreate: Position;
  PositionDelete: Position;
  PositionUpdate: Position;
  RoleCreate?: Maybe<Role>;
  RoleDelete?: Maybe<Role>;
  RoleUpdate?: Maybe<Role>;
  SalesChannelCreate: SalesChannel;
  SalesChannelDelete: SalesChannel;
  SalesChannelUpdate: SalesChannel;
  SalesTypeCreate: SalesType;
  SalesTypeDelete: SalesType;
  SalesTypeUpdate: SalesType;
  SegmentCreate: Segment;
  SegmentDelete: Segment;
  SegmentUpdate: Segment;
  SeriesCreate: Series;
  SeriesDelete: Series;
  SeriesUpdate: Series;
  SetItemAttributeCreate: SetItemAttribute;
  SetItemAttributeDelete: SetItemAttribute;
  SetItemAttributeUpdate: SetItemAttribute;
  SubCategoryCreate: SubCategory;
  SubCategoryDelete: SubCategory;
  SubCategoryUpdate: SubCategory;
  TableTemplateCreate: TableTemplate;
  TableTemplateDelete: TableTemplate;
  TableTemplateUpdate: TableTemplate;
  TagCreate: Tag;
  TagDelete: Tag;
  TagUpdate: Tag;
  UnitCreate: Unit;
  UnitDelete: Unit;
  UnitUpdate: Unit;
  UserCreate?: Maybe<User>;
  UserDelete?: Maybe<User>;
  UserSelfUpdate?: Maybe<User>;
  UserUpdate?: Maybe<User>;
  VendorAttributeCreate: VendorAttribute;
  VendorAttributeDelete: VendorAttribute;
  VendorAttributeUpdate: VendorAttribute;
  VendorGroupCreate: VendorGroup;
  VendorGroupDelete: VendorGroup;
  VendorGroupUpdate: VendorGroup;
  VendorTypeCreate: VendorType;
  VendorTypeDelete: VendorType;
  VendorTypeUpdate: VendorType;
  WarehouseCreate: Warehouse;
  WarehouseDelete: Warehouse;
  WarehouseLevel1Create: WarehouseLevel1;
  WarehouseLevel1Delete: WarehouseLevel1;
  WarehouseLevel1Update: WarehouseLevel1;
  WarehouseLevel2Create: WarehouseLevel2;
  WarehouseLevel2Delete: WarehouseLevel2;
  WarehouseLevel2Update: WarehouseLevel2;
  WarehouseLevel3Create: WarehouseLevel3;
  WarehouseLevel3Delete: WarehouseLevel3;
  WarehouseLevel3Update: WarehouseLevel3;
  WarehouseUpdate: Warehouse;
  WorkflowTemplateCreate: WorkflowTemplate;
  WorkflowTemplateDelete?: Maybe<WorkflowTemplate>;
  WorkflowTemplateUpdate: WorkflowTemplate;
  WorkflowTemplateUpsertMany: WorkflowTemplate;
  ZoneCreate: Zone;
  ZoneDelete: Zone;
  ZoneUpdate: Zone;
  createPosAccessToken: PosAccessTokenCreateOutput;
  login: LoginResponse;
  removePosAccessToken?: Maybe<PosAccessToken>;
  updatePosAccessToken: PosAccessToken;
};


export type MutationActivityLogCreateArgs = {
  data: ActivityLogCreateInput;
};


export type MutationApprovalTemplateCreateArgs = {
  data: ApprovalTemplateCreateInput;
};


export type MutationApprovalTemplateDeleteArgs = {
  where: ApprovalTemplateWhereUniqueInput;
};


export type MutationApprovalTemplateUpdateArgs = {
  data: ApprovalTemplateUpdateInput;
  where: ApprovalTemplateWhereUniqueInput;
};


export type MutationApprovalTemplateUpsertManyArgs = {
  data?: InputMaybe<Array<ApprovalTemplateCreateInput>>;
  documentType?: InputMaybe<WorkflowDocumentType>;
};


export type MutationBinLocationCreateArgs = {
  data: BinLocationCreateInput;
};


export type MutationBinLocationDeleteArgs = {
  uniqueInput: BinLocationUniqueInput;
};


export type MutationBinLocationUpdateArgs = {
  data: BinLocationUpdateInput;
  uniqueInput: BinLocationUniqueInput;
};


export type MutationBranchCreateArgs = {
  data: BranchCreateInput;
};


export type MutationBranchDeleteArgs = {
  uniqueInput: BranchUniqueInput;
};


export type MutationBranchUpdateArgs = {
  data: BranchUpdateInput;
  uniqueInput: BranchUniqueInput;
};


export type MutationBranchUserAddArgs = {
  input: Array<InputMaybe<BranchUserAddInput>>;
};


export type MutationBranchUserUpsertArgs = {
  addInput?: InputMaybe<Array<BranchUserAddInput>>;
  deleteInput?: InputMaybe<Array<BranchUserDeleteInput>>;
};


export type MutationBrandCreateArgs = {
  data: BrandCreateInput;
};


export type MutationBrandDeleteArgs = {
  uniqueInput: BrandUniqueInput;
};


export type MutationBrandUpdateArgs = {
  data: BrandUpdateInput;
  uniqueInput: BrandUniqueInput;
};


export type MutationCategoryGroupCreateArgs = {
  data: CategoryGroupCreateInput;
};


export type MutationCategoryGroupDeleteArgs = {
  uniqueInput: CategoryGroupUniqueInput;
};


export type MutationCategoryGroupUpdateArgs = {
  data: CategoryGroupUpdateInput;
  uniqueInput: CategoryGroupUniqueInput;
};


export type MutationCompanyCreateArgs = {
  data: CompanyCreateInput;
};


export type MutationCompanyUpdateArgs = {
  data: CompanyUpdateInput;
  uniqueInput: CompanyUniqueInput;
};


export type MutationCreatableFieldCreateArgs = {
  data: CreatableFieldCreateInput;
};


export type MutationCreatableFieldDeleteArgs = {
  uniqueInput: CreatableFieldUniqueInput;
};


export type MutationCreatableFieldUpdateArgs = {
  data: CreatableFieldUpdateInput;
  uniqueInput: CreatableFieldUniqueInput;
};


export type MutationCustomerAttributeCreateArgs = {
  data: CustomerAttributeCreateInput;
};


export type MutationCustomerAttributeDeleteArgs = {
  uniqueInput: CustomerAttributeUniqueInput;
};


export type MutationCustomerAttributeUpdateArgs = {
  data: CustomerAttributeUpdateInput;
  uniqueInput: CustomerAttributeUniqueInput;
};


export type MutationCustomerGroupCreateArgs = {
  data: CustomerGroupCreateInput;
};


export type MutationCustomerGroupDeleteArgs = {
  uniqueInput: CustomerGroupUniqueInput;
};


export type MutationCustomerGroupUpdateArgs = {
  data: CustomerGroupUpdateInput;
  uniqueInput: CustomerGroupUniqueInput;
};


export type MutationCustomerTypeCreateArgs = {
  data: CustomerTypeCreateInput;
};


export type MutationCustomerTypeDeleteArgs = {
  uniqueInput: CustomerTypeUniqueInput;
};


export type MutationCustomerTypeUpdateArgs = {
  data: CustomerTypeUpdateInput;
  uniqueInput: CustomerTypeUniqueInput;
};


export type MutationDeliveryTypeCreateArgs = {
  data: DeliveryTypeCreateInput;
};


export type MutationDeliveryTypeDeleteArgs = {
  uniqueInput: DeliveryTypeUniqueInput;
};


export type MutationDeliveryTypeUpdateArgs = {
  data: DeliveryTypeUpdateInput;
  uniqueInput: DeliveryTypeUniqueInput;
};


export type MutationDepartmentCreateArgs = {
  data: DepartmentCreateInput;
};


export type MutationDepartmentDeleteArgs = {
  uniqueInput: DepartmentUniqueInput;
};


export type MutationDepartmentUpdateArgs = {
  data: DepartmentUpdateInput;
  uniqueInput: DepartmentUniqueInput;
};


export type MutationImportUserArgs = {
  importInput: UserImportInput;
};


export type MutationItemAttributeCreateArgs = {
  data: ItemAttributeCreateInput;
};


export type MutationItemAttributeDeleteArgs = {
  uniqueInput: ItemAttributeUniqueInput;
};


export type MutationItemAttributeUpdateArgs = {
  data: ItemAttributeUpdateInput;
  uniqueInput: ItemAttributeUniqueInput;
};


export type MutationItemAttributeUpdateManyArgs = {
  updateManyInput?: InputMaybe<Array<ItemAttributeUpdateManyInput>>;
};


export type MutationMainCategoryCreateArgs = {
  data: MainCategoryCreateInput;
};


export type MutationMainCategoryDeleteArgs = {
  uniqueInput: MainCategoryUniqueInput;
};


export type MutationMainCategoryUpdateArgs = {
  data: MainCategoryUpdateInput;
  uniqueInput: MainCategoryUniqueInput;
};


export type MutationModelCreateArgs = {
  data: ModelCreateInput;
};


export type MutationModelDeleteArgs = {
  uniqueInput: ModelUniqueInput;
};


export type MutationModelUpdateArgs = {
  data: ModelUpdateInput;
  uniqueInput: ModelUniqueInput;
};


export type MutationPaymentChannelCreateArgs = {
  data: PaymentChannelCreateInput;
};


export type MutationPaymentChannelDeleteArgs = {
  uniqueInput: PaymentChannelUniqueInput;
};


export type MutationPaymentChannelUpdateArgs = {
  data: PaymentChannelUpdateInput;
  uniqueInput: PaymentChannelUniqueInput;
};


export type MutationPositionCreateArgs = {
  data: PositionCreateInput;
};


export type MutationPositionDeleteArgs = {
  uniqueInput: PositionUniqueInput;
};


export type MutationPositionUpdateArgs = {
  data: PositionUpdateInput;
  uniqueInput: PositionUniqueInput;
};


export type MutationRoleCreateArgs = {
  data: RoleCreateInput;
};


export type MutationRoleDeleteArgs = {
  uniqueInput: RoleUniqueInput;
};


export type MutationRoleUpdateArgs = {
  data: RoleUpdateInput;
  uniqueInput: RoleUniqueInput;
};


export type MutationSalesChannelCreateArgs = {
  data: SalesChannelCreateInput;
};


export type MutationSalesChannelDeleteArgs = {
  uniqueInput: SalesChannelUniqueInput;
};


export type MutationSalesChannelUpdateArgs = {
  data: SalesChannelUpdateInput;
  uniqueInput: SalesChannelUniqueInput;
};


export type MutationSalesTypeCreateArgs = {
  data: SalesTypeCreateInput;
};


export type MutationSalesTypeDeleteArgs = {
  uniqueInput: SalesTypeUniqueInput;
};


export type MutationSalesTypeUpdateArgs = {
  data: SalesTypeUpdateInput;
  uniqueInput: SalesTypeUniqueInput;
};


export type MutationSegmentCreateArgs = {
  data: SegmentCreateInput;
};


export type MutationSegmentDeleteArgs = {
  uniqueInput: SegmentUniqueInput;
};


export type MutationSegmentUpdateArgs = {
  data: SegmentUpdateInput;
  uniqueInput: SegmentUniqueInput;
};


export type MutationSeriesCreateArgs = {
  data: SeriesCreateInput;
};


export type MutationSeriesDeleteArgs = {
  uniqueInput: SeriesUniqueInput;
};


export type MutationSeriesUpdateArgs = {
  data: SeriesUpdateInput;
  uniqueInput: SeriesUniqueInput;
};


export type MutationSetItemAttributeCreateArgs = {
  createInput: SetItemAttributeCreateInput;
};


export type MutationSetItemAttributeDeleteArgs = {
  uniqueInput: SetItemAttributeUniqueInput;
};


export type MutationSetItemAttributeUpdateArgs = {
  uniqueInput: SetItemAttributeUniqueInput;
  updateInput: SetItemAttributeUpdateInput;
};


export type MutationSubCategoryCreateArgs = {
  data: SubCategoryCreateInput;
};


export type MutationSubCategoryDeleteArgs = {
  uniqueInput: SubCategoryUniqueInput;
};


export type MutationSubCategoryUpdateArgs = {
  data: SubCategoryUpdateInput;
  uniqueInput: SubCategoryUniqueInput;
};


export type MutationTableTemplateCreateArgs = {
  data: TableTemplateCreateInput;
};


export type MutationTableTemplateDeleteArgs = {
  uniqueInput: TableTemplateUniqueInput;
};


export type MutationTableTemplateUpdateArgs = {
  data: TableTemplateUpdateInput;
  uniqueInput: TableTemplateUniqueInput;
};


export type MutationTagCreateArgs = {
  data: TagCreateInput;
};


export type MutationTagDeleteArgs = {
  uniqueInput: TagUniqueInput;
};


export type MutationTagUpdateArgs = {
  data: TagUpdateInput;
  uniqueInput: TagUniqueInput;
};


export type MutationUnitCreateArgs = {
  data: UnitCreateInput;
};


export type MutationUnitDeleteArgs = {
  uniqueInput: UnitUniqueInput;
};


export type MutationUnitUpdateArgs = {
  data: UnitUpdateInput;
  uniqueInput: UnitUniqueInput;
};


export type MutationUserCreateArgs = {
  data: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  uniqueInput: UserUniqueInput;
};


export type MutationUserSelfUpdateArgs = {
  data: UserUpdateInput;
  validatePassword: Scalars['String']['input'];
};


export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
  uniqueInput: UserUniqueInput;
};


export type MutationVendorAttributeCreateArgs = {
  data: VendorAttributeCreateInput;
};


export type MutationVendorAttributeDeleteArgs = {
  uniqueInput: VendorAttributeUniqueInput;
};


export type MutationVendorAttributeUpdateArgs = {
  data: VendorAttributeUpdateInput;
  uniqueInput: VendorAttributeUniqueInput;
};


export type MutationVendorGroupCreateArgs = {
  data: VendorGroupCreateInput;
};


export type MutationVendorGroupDeleteArgs = {
  uniqueInput: VendorGroupUniqueInput;
};


export type MutationVendorGroupUpdateArgs = {
  data: VendorGroupUpdateInput;
  uniqueInput: VendorGroupUniqueInput;
};


export type MutationVendorTypeCreateArgs = {
  data: VendorTypeCreateInput;
};


export type MutationVendorTypeDeleteArgs = {
  uniqueInput: VendorTypeUniqueInput;
};


export type MutationVendorTypeUpdateArgs = {
  data: VendorTypeUpdateInput;
  uniqueInput: VendorTypeUniqueInput;
};


export type MutationWarehouseCreateArgs = {
  data: WarehouseCreateInput;
};


export type MutationWarehouseDeleteArgs = {
  uniqueInput: WarehouseUniqueInput;
};


export type MutationWarehouseLevel1CreateArgs = {
  data: WarehouseLevel1CreateInput;
};


export type MutationWarehouseLevel1DeleteArgs = {
  uniqueInput: WarehouseLevel1UniqueInput;
};


export type MutationWarehouseLevel1UpdateArgs = {
  data: WarehouseLevel1UpdateInput;
  uniqueInput: WarehouseLevel1UniqueInput;
};


export type MutationWarehouseLevel2CreateArgs = {
  data: WarehouseLevel2CreateInput;
};


export type MutationWarehouseLevel2DeleteArgs = {
  uniqueInput: WarehouseLevel2UniqueInput;
};


export type MutationWarehouseLevel2UpdateArgs = {
  data: WarehouseLevel2UpdateInput;
  uniqueInput: WarehouseLevel2UniqueInput;
};


export type MutationWarehouseLevel3CreateArgs = {
  data: WarehouseLevel3CreateInput;
};


export type MutationWarehouseLevel3DeleteArgs = {
  uniqueInput: WarehouseLevel3UniqueInput;
};


export type MutationWarehouseLevel3UpdateArgs = {
  data: WarehouseLevel3UpdateInput;
  uniqueInput: WarehouseLevel3UniqueInput;
};


export type MutationWarehouseUpdateArgs = {
  data: WarehouseUpdateInput;
  uniqueInput: WarehouseUniqueInput;
};


export type MutationWorkflowTemplateCreateArgs = {
  data: WorkflowTemplateCreateInput;
};


export type MutationWorkflowTemplateDeleteArgs = {
  where: WorkflowTemplateWhereUniqueInput;
};


export type MutationWorkflowTemplateUpdateArgs = {
  data: WorkflowTemplateUpdateInput;
  where: WorkflowTemplateWhereUniqueInput;
};


export type MutationWorkflowTemplateUpsertManyArgs = {
  data?: InputMaybe<Array<WorkflowTemplateUpdateInput>>;
};


export type MutationZoneCreateArgs = {
  data: ZoneCreateInput;
};


export type MutationZoneDeleteArgs = {
  uniqueInput: ZoneUniqueInput;
};


export type MutationZoneUpdateArgs = {
  data: ZoneUpdateInput;
  uniqueInput: ZoneUniqueInput;
};


export type MutationCreatePosAccessTokenArgs = {
  createPosAccessTokenInput: CreatePosAccessTokenInput;
};


export type MutationLoginArgs = {
  loginInput?: InputMaybe<LoginInput>;
};


export type MutationRemovePosAccessTokenArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdatePosAccessTokenArgs = {
  updatePosAccessTokenInput: UpdatePosAccessTokenInput;
};

export type PosAccessTokensAggrid = {
  __typename?: 'POSAccessTokensAggrid';
  count: Scalars['Int']['output'];
  results: Array<PosAccessToken>;
};

export type PaymentChannel = {
  __typename?: 'PaymentChannel';
  account_no: Scalars['String']['output'];
  account_type?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  branch_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  payment_name: Scalars['String']['output'];
  payment_type?: Maybe<Array<Scalars['String']['output']>>;
  unique_id: Scalars['String']['output'];
};

export type PaymentChannelCreateInput = {
  account_no: Scalars['String']['input'];
  account_type?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  branch_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  payment_name: Scalars['String']['input'];
  payment_type?: InputMaybe<Array<Scalars['String']['input']>>;
  unique_id: Scalars['String']['input'];
};

export type PaymentChannelUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentChannelUpdateInput = {
  account_no?: InputMaybe<Scalars['String']['input']>;
  account_type?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  branch_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment_name?: InputMaybe<Scalars['String']['input']>;
  payment_type?: InputMaybe<Array<Scalars['String']['input']>>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type PosAccessToken = {
  __typename?: 'PosAccessToken';
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PosAccessTokenCreateOutput = {
  __typename?: 'PosAccessTokenCreateOutput';
  accesstoken?: Maybe<Scalars['String']['output']>;
  branch_id: Scalars['Int']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Position = {
  __typename?: 'Position';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type PositionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type PositionUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type PositionUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum PriceVatType {
  ExcludeVat = 'exclude_vat',
  IncludeVat = 'include_vat'
}

export enum PurchaseDocumentType {
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return'
}

export type PurchaseItem = {
  __typename?: 'PurchaseItem';
  barcode: Scalars['String']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  item_img_url?: Maybe<Array<Scalars['String']['output']>>;
  item_name: Scalars['String']['output'];
  item_sku_desc?: Maybe<Scalars['String']['output']>;
  item_sku_name: Scalars['String']['output'];
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  po_qty?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_per_unit?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Float']['output'];
  qty_issued?: Maybe<Scalars['Float']['output']>;
  qty_ordered?: Maybe<Scalars['Float']['output']>;
  qty_received?: Maybe<Scalars['Float']['output']>;
  reference_document_type: PurchaseDocumentType;
  reference_line_item?: Maybe<ReferencePurchaseLineItem>;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  stock_qty?: Maybe<Scalars['Float']['output']>;
  tracability: TraceAbilityEnum;
  unique_id: Scalars['String']['output'];
  uom_id: Scalars['Int']['output'];
  uom_name: Scalars['String']['output'];
  vat_percentage?: Maybe<Scalars['String']['output']>;
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  delivery_address?: Maybe<Scalars['Json']['output']>;
  destination_warehouse?: Maybe<Warehouse>;
  destination_warehouse_id?: Maybe<Scalars['Int']['output']>;
  expected_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<PurchaseItem>>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_document_id?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<PurchaseReferenceDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  vendor_details?: Maybe<Scalars['Json']['output']>;
  vendor_unique_id?: Maybe<Scalars['String']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
  within_date?: Maybe<Scalars['DateTime']['output']>;
};

export type PurchaseReferenceDocument = {
  __typename?: 'PurchaseReferenceDocument';
  document_id?: Maybe<Scalars['Int']['output']>;
  document_type?: Maybe<PurchaseReferenceType>;
  document_unique_id?: Maybe<Scalars['String']['output']>;
};

export type PurchaseReferenceDocumentInput = {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  document_type?: InputMaybe<PurchaseReferenceType>;
  document_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum PurchaseReferenceType {
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  SalesOrder = 'sales_order'
}

export type PurchaseRequest = {
  __typename?: 'PurchaseRequest';
  accepted_remark?: Maybe<Scalars['String']['output']>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  attachment_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  delivery_address?: Maybe<Scalars['Json']['output']>;
  expected_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<PurchaseItem>>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<PurchaseReferenceDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<Scalars['Json']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  within_date?: Maybe<Scalars['DateTime']['output']>;
};

export type PurchaseReturn = {
  __typename?: 'PurchaseReturn';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  created_by?: Maybe<CreatorObject>;
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  delivery_address?: Maybe<Scalars['Json']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<PurchaseItem>>;
  last_updated_by?: Maybe<CreatorObject>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reason_to_return?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<PurchaseReferenceDocument>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  vendor_details?: Maybe<Scalars['Json']['output']>;
  vendor_unique_id?: Maybe<Scalars['String']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  ActivityLogFindManyAggrid: ActivityLogFindManyAggrid;
  ActivityLogFindManyByReferenceId: Array<ActivityLog>;
  ActivityLogFindUnique?: Maybe<ActivityLog>;
  ApprovalTemplateFindByDocumentType?: Maybe<Array<Maybe<ApprovalTemplate>>>;
  BinLocationFindUnique?: Maybe<BinLocation>;
  BinLocationsFindAll: Array<BinLocation>;
  BranchFindUnique?: Maybe<Branch>;
  BranchUserGetAll: Array<BranchUser>;
  BranchUserGetByBranchId: Array<BranchUser>;
  BranchUserGetByUserId: Array<BranchUser>;
  BranchesFindManyAggrid: BranchAggrid;
  BrandFindUnique?: Maybe<Brand>;
  BrandsFindAll: Array<Brand>;
  CategoryGroupFindUnique?: Maybe<CategoryGroup>;
  CategoryGroupsFindAll: Array<CategoryGroup>;
  CompanyFindUnique?: Maybe<Company>;
  CreatableFieldFindUnique?: Maybe<CreatableField>;
  CreatableFields: Array<CreatableField>;
  CurrentImportRequest?: Maybe<Scalars['Json']['output']>;
  CurrentReportRequest?: Maybe<Scalars['Json']['output']>;
  CustomerAttributeFindUnique?: Maybe<CustomerAttribute>;
  CustomerAttributesFindAll: Array<CustomerAttribute>;
  CustomerGroupFindUnique?: Maybe<CustomerGroup>;
  CustomerGroupsFindAll: Array<CustomerGroup>;
  CustomerTypeFindUnique?: Maybe<CustomerType>;
  CustomerTypesFindAll: Array<CustomerType>;
  DeliveryTypeFindUnique?: Maybe<DeliveryType>;
  DeliveryTypesFindAll: Array<DeliveryType>;
  DepartmentFindUnique?: Maybe<Department>;
  DepartmentsFindAll: Array<Department>;
  GenerateReport: ReportResult;
  GenerateReportNoQueue?: Maybe<Scalars['String']['output']>;
  HistoryImportRequest?: Maybe<Scalars['Json']['output']>;
  HistoryReportRequest?: Maybe<Scalars['Json']['output']>;
  ItemAttributeFindUnique?: Maybe<ItemAttribute>;
  ItemAttributesFindMany: Array<ItemAttribute>;
  MainCategoryFindUnique?: Maybe<MainCategory>;
  MainCategorysFindAll: Array<MainCategory>;
  ModelFindUnique?: Maybe<Model>;
  ModelsFindAll: Array<Model>;
  POSAccessTokensFindManyAggrid: PosAccessTokensAggrid;
  PaymentChannelFindUnique?: Maybe<PaymentChannel>;
  PaymentChannelsFindAll: Array<PaymentChannel>;
  PositionFindUnique?: Maybe<Position>;
  PositionsFindAll: Array<Position>;
  RoleFindUnique?: Maybe<Role>;
  RolesFindManyAggrid: RoleAggrid;
  RolesView: RoleViewAggrid;
  S3GetFileUrl: Scalars['Any']['output'];
  S3GetUploadUrl: Scalars['Any']['output'];
  SalesChannelFindUnique?: Maybe<SalesChannel>;
  SalesChannelsFindAll: Array<SalesChannel>;
  SalesTypeFindUnique?: Maybe<SalesType>;
  SalesTypesFindAll: Array<SalesType>;
  SegmentFindUnique?: Maybe<Segment>;
  SegmentsFindAll: Array<Segment>;
  SeriesFindUnique?: Maybe<Series>;
  SeriessFindAll: Array<Series>;
  SetItemAttribute?: Maybe<SetItemAttribute>;
  SetItemAttributes: Array<SetItemAttribute>;
  SubCategoryFindUnique?: Maybe<SubCategory>;
  SubCategorysFindAll: Array<SubCategory>;
  TableTemplateFindUnique?: Maybe<TableTemplate>;
  TableTemplatesFindAll: Array<TableTemplate>;
  TagFindUnique?: Maybe<Tag>;
  Tags: Array<Tag>;
  UniqueIdGenerate: Scalars['String']['output'];
  UniqueIdIsExist: Scalars['Boolean']['output'];
  UnitFindUnique?: Maybe<Unit>;
  UnitsFindAll: Array<Unit>;
  UserFindUnique?: Maybe<User>;
  UsersFindManyAggrid: UserAggrid;
  UsersView: UserViewAggrid;
  ValidateUserImport: ValidateUserImportResult;
  ValidateUserIsInUse?: Maybe<ValidateUserIsInUseResult>;
  VendorAttributeFindUnique?: Maybe<VendorAttribute>;
  VendorAttributesFindAll: Array<VendorAttribute>;
  VendorGroupFindUnique?: Maybe<VendorGroup>;
  VendorGroupsFindAll: Array<VendorGroup>;
  VendorTypeFindUnique?: Maybe<VendorType>;
  VendorTypesFindAll: Array<VendorType>;
  WarehouseFindUnique?: Maybe<Warehouse>;
  WarehouseLevel1FindUnique?: Maybe<WarehouseLevel1>;
  WarehouseLevel1sFindAll: Array<WarehouseLevel1>;
  WarehouseLevel2FindUnique?: Maybe<WarehouseLevel2>;
  WarehouseLevel2sFindAll: Array<WarehouseLevel2>;
  WarehouseLevel3FindUnique?: Maybe<WarehouseLevel3>;
  WarehouseLevel3sFindAll: Array<WarehouseLevel3>;
  WarehousesFindAll: Array<Warehouse>;
  WorkflowTemplateFindByDocumentType?: Maybe<Array<Maybe<WorkflowTemplate>>>;
  ZoneFindUnique?: Maybe<Zone>;
  ZonesFindAll: Array<Zone>;
  posAccessToken?: Maybe<PosAccessToken>;
  posAccessTokens: Array<Maybe<PosAccessToken>>;
  validateToken: User;
};


export type QueryActivityLogFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryActivityLogFindManyByReferenceIdArgs = {
  findManyInput: ActivityLogByRefereceIdInput;
};


export type QueryActivityLogFindUniqueArgs = {
  uniqueInput?: InputMaybe<ActivityLogUniqueInput>;
};


export type QueryApprovalTemplateFindByDocumentTypeArgs = {
  DocumentType: Scalars['String']['input'];
};


export type QueryBinLocationFindUniqueArgs = {
  uniqueInput: BinLocationUniqueInput;
};


export type QueryBranchFindUniqueArgs = {
  uniqueInput: BranchUniqueInput;
};


export type QueryBranchUserGetAllArgs = {
  where?: InputMaybe<BranchUserWhere>;
};


export type QueryBranchUserGetByBranchIdArgs = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBranchUserGetByUserIdArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBranchesFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryBrandFindUniqueArgs = {
  uniqueInput: BrandUniqueInput;
};


export type QueryCategoryGroupFindUniqueArgs = {
  uniqueInput: CategoryGroupUniqueInput;
};


export type QueryCompanyFindUniqueArgs = {
  uniqueInput?: InputMaybe<CompanyUniqueInput>;
};


export type QueryCreatableFieldFindUniqueArgs = {
  uniqueInput: CreatableFieldUniqueInput;
};


export type QueryCreatableFieldsArgs = {
  findManyInput?: InputMaybe<CreatableFieldFindManyInput>;
};


export type QueryCurrentImportRequestArgs = {
  userUniqueId: Scalars['String']['input'];
};


export type QueryCustomerAttributeFindUniqueArgs = {
  uniqueInput: CustomerAttributeUniqueInput;
};


export type QueryCustomerGroupFindUniqueArgs = {
  uniqueInput: CustomerGroupUniqueInput;
};


export type QueryCustomerTypeFindUniqueArgs = {
  uniqueInput: CustomerTypeUniqueInput;
};


export type QueryDeliveryTypeFindUniqueArgs = {
  uniqueInput: DeliveryTypeUniqueInput;
};


export type QueryDepartmentFindUniqueArgs = {
  uniqueInput: DepartmentUniqueInput;
};


export type QueryGenerateReportArgs = {
  input?: InputMaybe<ExportGenerateInput>;
};


export type QueryGenerateReportNoQueueArgs = {
  input?: InputMaybe<ExportGenerateInput>;
};


export type QueryHistoryImportRequestArgs = {
  userUniqueId: Scalars['String']['input'];
};


export type QueryItemAttributeFindUniqueArgs = {
  uniqueInput: ItemAttributeUniqueInput;
};


export type QueryItemAttributesFindManyArgs = {
  setAttributeId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMainCategoryFindUniqueArgs = {
  uniqueInput: MainCategoryUniqueInput;
};


export type QueryModelFindUniqueArgs = {
  uniqueInput: ModelUniqueInput;
};


export type QueryPosAccessTokensFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryPaymentChannelFindUniqueArgs = {
  uniqueInput: PaymentChannelUniqueInput;
};


export type QueryPositionFindUniqueArgs = {
  uniqueInput: PositionUniqueInput;
};


export type QueryRoleFindUniqueArgs = {
  uniqueInput: RoleUniqueInput;
};


export type QueryRolesFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryRolesViewArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryS3GetFileUrlArgs = {
  keyPath: Scalars['String']['input'];
};


export type QueryS3GetUploadUrlArgs = {
  fileName?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySalesChannelFindUniqueArgs = {
  uniqueInput: SalesChannelUniqueInput;
};


export type QuerySalesTypeFindUniqueArgs = {
  uniqueInput: SalesTypeUniqueInput;
};


export type QuerySegmentFindUniqueArgs = {
  uniqueInput: SegmentUniqueInput;
};


export type QuerySeriesFindUniqueArgs = {
  uniqueInput: SeriesUniqueInput;
};


export type QuerySetItemAttributeArgs = {
  uniqueInput: SetItemAttributeUniqueInput;
};


export type QuerySubCategoryFindUniqueArgs = {
  uniqueInput: SubCategoryUniqueInput;
};


export type QueryTableTemplateFindUniqueArgs = {
  uniqueInput: TableTemplateUniqueInput;
};


export type QueryTagFindUniqueArgs = {
  uniqueInput: TagUniqueInput;
};


export type QueryTagsArgs = {
  findManyInput?: InputMaybe<TagFindManyInput>;
};


export type QueryUniqueIdGenerateArgs = {
  modelType: GeneralModelType;
};


export type QueryUniqueIdIsExistArgs = {
  modelType: GeneralModelType;
  uniqueId: Scalars['String']['input'];
};


export type QueryUnitFindUniqueArgs = {
  uniqueInput: UnitUniqueInput;
};


export type QueryUnitsFindAllArgs = {
  findManyInput?: InputMaybe<UnitWhereInput>;
};


export type QueryUserFindUniqueArgs = {
  uniqueInput?: InputMaybe<UserUniqueInput>;
};


export type QueryUsersFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryUsersViewArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryValidateUserImportArgs = {
  validateInput: ValidateUserImportInput;
};


export type QueryValidateUserIsInUseArgs = {
  validateUserInput?: InputMaybe<ValidateUserInput>;
};


export type QueryVendorAttributeFindUniqueArgs = {
  uniqueInput: VendorAttributeUniqueInput;
};


export type QueryVendorGroupFindUniqueArgs = {
  uniqueInput: VendorGroupUniqueInput;
};


export type QueryVendorTypeFindUniqueArgs = {
  uniqueInput: VendorTypeUniqueInput;
};


export type QueryWarehouseFindUniqueArgs = {
  uniqueInput: WarehouseUniqueInput;
};


export type QueryWarehouseLevel1FindUniqueArgs = {
  uniqueInput: WarehouseLevel1UniqueInput;
};


export type QueryWarehouseLevel2FindUniqueArgs = {
  uniqueInput: WarehouseLevel2UniqueInput;
};


export type QueryWarehouseLevel3FindUniqueArgs = {
  uniqueInput: WarehouseLevel3UniqueInput;
};


export type QueryWarehousesFindAllArgs = {
  whereInput?: InputMaybe<WarehouseWhereInput>;
};


export type QueryWorkflowTemplateFindByDocumentTypeArgs = {
  DocumentType: Scalars['String']['input'];
};


export type QueryZoneFindUniqueArgs = {
  uniqueInput: ZoneUniqueInput;
};


export type QueryPosAccessTokenArgs = {
  id: Scalars['Int']['input'];
};

export type Quotation = {
  __typename?: 'Quotation';
  accepted_date?: Maybe<Scalars['DateTime']['output']>;
  accepted_remark?: Maybe<Scalars['String']['output']>;
  account_id?: Maybe<Scalars['String']['output']>;
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  attachment_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  contact_unique_id?: Maybe<Scalars['String']['output']>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<Maybe<SalesItem>>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  shipping_cost?: Maybe<Scalars['Float']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Maybe<Tag>>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type ReferencePurchaseLineItem = {
  __typename?: 'ReferencePurchaseLineItem';
  line_item_document_type: Scalars['String']['output'];
  line_item_document_unique_id: Scalars['String']['output'];
  line_item_unique_id: Scalars['String']['output'];
};

export type ReferencePurchaseLineItemInput = {
  line_item_document_type: Scalars['String']['input'];
  line_item_document_unique_id: Scalars['String']['input'];
  line_item_unique_id: Scalars['String']['input'];
};

export type ReferenceSalesLineItem = {
  __typename?: 'ReferenceSalesLineItem';
  line_item_document_type: Scalars['String']['output'];
  line_item_unique_id: Scalars['String']['output'];
};

export type ReferenceSalesLineItemInput = {
  line_item_document_type: Scalars['String']['input'];
  line_item_unique_id: Scalars['String']['input'];
};

export type ReportResult = {
  __typename?: 'ReportResult';
  detail?: Maybe<Scalars['Json']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type Role = {
  __typename?: 'Role';
  created_date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  role_permission_list?: Maybe<Array<Maybe<RolePermission>>>;
  user_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  user_list?: Maybe<Array<Maybe<User>>>;
};

export type RoleAggrid = {
  __typename?: 'RoleAggrid';
  count: Scalars['Int']['output'];
  results: Array<Role>;
};

export type RoleCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  role_permission_list?: InputMaybe<Array<RolePermissionCreateFromRoleInput>>;
  user_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  access_permssion?: Maybe<Array<AccessPermission>>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  module_name: ModuleName;
  role?: Maybe<Role>;
  role_id: Scalars['Int']['output'];
};

export type RolePermissionCreateFromRoleInput = {
  access_permssion?: InputMaybe<Array<AccessPermission>>;
  module_name: ModuleName;
};

export type RolePermissionCreateInput = {
  access_permssion?: InputMaybe<Array<AccessPermission>>;
  module_name: ModuleName;
  role_id: Scalars['Int']['input'];
};

export type RoleUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RoleUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_permission_list?: InputMaybe<Array<RolePermissionCreateFromRoleInput>>;
  user_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RoleView = {
  __typename?: 'RoleView';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  user_count?: Maybe<Scalars['Int']['output']>;
};

export type RoleViewAggrid = {
  __typename?: 'RoleViewAggrid';
  count: Scalars['Int']['output'];
  results: Array<RoleView>;
};

export type SalesChannel = {
  __typename?: 'SalesChannel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  item_price_list?: Maybe<Array<Maybe<ItemPriceList>>>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en: Scalars['String']['output'];
  name_th: Scalars['String']['output'];
  quotation_list?: Maybe<Array<Maybe<Quotation>>>;
  unique_id: Scalars['String']['output'];
};

export type SalesChannelCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type SalesChannelUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SalesChannelUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SalesDeduction = {
  __typename?: 'SalesDeduction';
  branch_id: Scalars['Int']['output'];
  credit_balance?: Maybe<Scalars['Float']['output']>;
  deduction_amount?: Maybe<Scalars['Float']['output']>;
  deduction_issue_date?: Maybe<Scalars['DateTime']['output']>;
  document_type?: Maybe<Scalars['String']['output']>;
  document_unique_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_document_type: SalesReferenceDocumentType;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
};

export type SalesInvoice = {
  __typename?: 'SalesInvoice';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch?: Maybe<Branch>;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  customer_details: Scalars['Json']['output'];
  customer_unique_id: Scalars['String']['output'];
  deduction_document_list?: Maybe<Array<SalesDeduction>>;
  deduction_total_amount?: Maybe<Scalars['Float']['output']>;
  due_date: Scalars['DateTime']['output'];
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_original_print?: Maybe<Scalars['Json']['output']>;
  issue_date: Scalars['DateTime']['output'];
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  need_tax_invoice?: Maybe<Scalars['Boolean']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  paid_net_amount?: Maybe<Scalars['Float']['output']>;
  paid_total_amount?: Maybe<Scalars['Float']['output']>;
  payment_list?: Maybe<Array<SalesPayment>>;
  payment_total_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type: PriceVatType;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  tax_invoice_date?: Maybe<Scalars['DateTime']['output']>;
  tax_invoice_no?: Maybe<Scalars['String']['output']>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type SalesItem = {
  __typename?: 'SalesItem';
  barcode?: Maybe<Scalars['String']['output']>;
  branch_id: Scalars['Int']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  item_img_url?: Maybe<Array<Scalars['String']['output']>>;
  item_name?: Maybe<Scalars['String']['output']>;
  item_sku_desc?: Maybe<Scalars['String']['output']>;
  item_sku_name: Scalars['String']['output'];
  item_unique_id: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_per_unit?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Float']['output'];
  qty_invoiced?: Maybe<Scalars['Float']['output']>;
  qty_issued?: Maybe<Scalars['Float']['output']>;
  qty_received?: Maybe<Scalars['Float']['output']>;
  qty_returned?: Maybe<Scalars['Float']['output']>;
  qty_shipped?: Maybe<Scalars['Float']['output']>;
  qty_to_ship?: Maybe<Scalars['Float']['output']>;
  reference_document_type: SalesReferenceDocumentType;
  reference_line_item?: Maybe<ReferenceSalesLineItem>;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  so_qty?: Maybe<Scalars['Float']['output']>;
  stock_qty?: Maybe<Scalars['Float']['output']>;
  tracability: TraceAbilityEnum;
  unique_id: Scalars['String']['output'];
  uom_id?: Maybe<Scalars['Int']['output']>;
  uom_name?: Maybe<Scalars['String']['output']>;
  vat_percentage?: Maybe<Scalars['String']['output']>;
  volume: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export enum SalesModelType {
  CashSales = 'cash_sales',
  CashSalesTax = 'cash_sales_tax',
  CustomerDeposit = 'customer_deposit',
  CustomerDepositTax = 'customer_deposit_tax',
  Quotation = 'quotation',
  SalesInvoice = 'sales_invoice',
  SalesInvoiceTax = 'sales_invoice_tax',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return'
}

export type SalesOrder = {
  __typename?: 'SalesOrder';
  account_id?: Maybe<Scalars['String']['output']>;
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  delivery_status?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  good_issue_status?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  issue_date?: Maybe<Scalars['DateTime']['output']>;
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  payments_receipt_status?: Maybe<Scalars['String']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_invoice_status?: Maybe<Scalars['String']['output']>;
  sales_type?: Maybe<Scalars['String']['output']>;
  shipping_cost?: Maybe<Scalars['Float']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type SalesPayment = {
  __typename?: 'SalesPayment';
  branch_id: Scalars['Int']['output'];
  cheque_date?: Maybe<Scalars['DateTime']['output']>;
  cheque_no?: Maybe<Scalars['String']['output']>;
  cheque_provider?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_withholding_tax?: Maybe<Scalars['Boolean']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  payment_amount?: Maybe<Scalars['Float']['output']>;
  payment_channel_id?: Maybe<Scalars['Int']['output']>;
  payment_channel_name?: Maybe<Scalars['String']['output']>;
  payment_channel_payment_name?: Maybe<Scalars['String']['output']>;
  payment_channel_unique_id?: Maybe<Scalars['String']['output']>;
  payment_date?: Maybe<Scalars['DateTime']['output']>;
  reference_document_type: SalesReferenceDocumentType;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_rate?: Maybe<Scalars['String']['output']>;
};

export enum SalesReferenceDocumentType {
  CashSales = 'cash_sales',
  CustomerDeposit = 'customer_deposit',
  Quotation = 'quotation',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  TaxInvoice = 'tax_invoice'
}

export type SalesReturn = {
  __typename?: 'SalesReturn';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  additional_discount_type?: Maybe<AdditionalDiscountType>;
  aggrid_status?: Maybe<Scalars['String']['output']>;
  approval_step?: Maybe<Scalars['Int']['output']>;
  approver_list?: Maybe<Array<Scalars['Json']['output']>>;
  branch?: Maybe<Branch>;
  branch_id: Scalars['Int']['output'];
  contact_person_list?: Maybe<Array<Scalars['Json']['output']>>;
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  credit_day?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['Json']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  due_date: Scalars['DateTime']['output'];
  external_reference_no?: Maybe<Scalars['String']['output']>;
  flag_status?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  is_effect_stock?: Maybe<Scalars['Boolean']['output']>;
  issue_date: Scalars['DateTime']['output'];
  item_list?: Maybe<Array<SalesItem>>;
  item_price_list?: Maybe<ItemPriceList>;
  item_price_list_id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_vat_type?: Maybe<PriceVatType>;
  reason_to_return?: Maybe<Scalars['String']['output']>;
  reference_document_list?: Maybe<Array<Scalars['Json']['output']>>;
  reference_no?: Maybe<Scalars['String']['output']>;
  related_user_list?: Maybe<Array<Scalars['Json']['output']>>;
  remark?: Maybe<Scalars['String']['output']>;
  reviewer_list?: Maybe<Array<Scalars['Json']['output']>>;
  sales_channel?: Maybe<SalesChannel>;
  sales_channel_id?: Maybe<Scalars['Int']['output']>;
  sales_contact?: Maybe<Array<Scalars['Json']['output']>>;
  sales_type?: Maybe<Scalars['String']['output']>;
  sub_status?: Maybe<Scalars['String']['output']>;
  tag_id_list?: Maybe<Array<Scalars['Int']['output']>>;
  tag_list?: Maybe<Array<Tag>>;
  template_remark_id?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  unique_id: Scalars['String']['output'];
  vat_0_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_7_percent_amount?: Maybe<Scalars['Float']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_exempted_amount?: Maybe<Scalars['Float']['output']>;
  withholding_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type SalesType = {
  __typename?: 'SalesType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type SalesTypeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type SalesTypeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SalesTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Segment = {
  __typename?: 'Segment';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type SegmentCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type SegmentUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SegmentUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Series = {
  __typename?: 'Series';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type SeriesCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type SeriesUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SeriesUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SetItemAttribute = {
  __typename?: 'SetItemAttribute';
  id: Scalars['Int']['output'];
  item_attribute_list?: Maybe<Array<ItemAttribute>>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_th: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
};

export type SetItemAttributeCreateInput = {
  name_th: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
};

export type SetItemAttributeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
};

export type SetItemAttributeUpdateInput = {
  name_th?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SubCategory = {
  __typename?: 'SubCategory';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type SubCategoryCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type SubCategoryUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type SubCategoryUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  SubscriptionImportReport?: Maybe<SubscriptionStatus>;
};

export type SubscriptionImportResult = {
  __typename?: 'SubscriptionImportResult';
  import?: Maybe<Scalars['Json']['output']>;
  import_type?: Maybe<GeneralImportType>;
};

export type SubscriptionReportResult = {
  __typename?: 'SubscriptionReportResult';
  report?: Maybe<Scalars['Json']['output']>;
  report_type?: Maybe<GeneralExportType>;
};

export type SubscriptionStatus = {
  __typename?: 'SubscriptionStatus';
  import_result?: Maybe<SubscriptionImportResult>;
  report_result?: Maybe<SubscriptionReportResult>;
  status?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<GeneralSubscriptionType>;
};

export type TableTemplate = {
  __typename?: 'TableTemplate';
  aggrid_name: Scalars['String']['output'];
  branch_id?: Maybe<Scalars['Int']['output']>;
  column_name: Scalars['String']['output'];
  column_no: Scalars['Int']['output'];
  filter_value: Scalars['Json']['output'];
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  reference_document_type: CreatableFieldDocumentType;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type TableTemplateCreateInput = {
  aggrid_name: Scalars['String']['input'];
  branch_id?: InputMaybe<Scalars['Int']['input']>;
  column_name: Scalars['String']['input'];
  column_no: Scalars['Int']['input'];
  filter_value: Scalars['Json']['input'];
  reference_document_type: CreatableFieldDocumentType;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type TableTemplateUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TableTemplateUpdateInput = {
  aggrid_name?: InputMaybe<Scalars['String']['input']>;
  branch_id?: InputMaybe<Scalars['Int']['input']>;
  column_name?: InputMaybe<Scalars['String']['input']>;
  column_no?: InputMaybe<Scalars['Int']['input']>;
  filter_value?: InputMaybe<Scalars['Json']['input']>;
  reference_document_type?: InputMaybe<CreatableFieldDocumentType>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Tag = {
  __typename?: 'Tag';
  document_type: TagDocumentType;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export type TagCreateInput = {
  document_type: TagDocumentType;
  name: Scalars['String']['input'];
};

export enum TagDocumentType {
  Batch = 'batch',
  CashSales = 'cash_sales',
  Contact = 'contact',
  CreditNote = 'credit_note',
  Customer = 'customer',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  Employee = 'employee',
  Event = 'event',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsTransfer = 'goods_transfer',
  Inventory = 'inventory',
  Item = 'item',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  Project = 'project',
  Promotion = 'promotion',
  Purchase = 'purchase',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  Sale = 'sale',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial',
  Task = 'task',
  Vendor = 'vendor'
}

export type TagFindManyInput = {
  document_type?: InputMaybe<TagDocumentType>;
  name?: InputMaybe<Scalars['Any']['input']>;
};

export type TagUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TagUpdateInput = {
  document_type?: InputMaybe<TagDocumentType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum Tracability {
  Lot = 'lot',
  Normal = 'normal',
  Serial = 'serial'
}

export enum TraceAbilityEnum {
  Lot = 'lot',
  Normal = 'normal',
  Serial = 'serial'
}

export type Unit = {
  __typename?: 'Unit';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type UnitCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type UnitFindManyInput = {
  cursor?: InputMaybe<UnitUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<UnitScalarField>>>;
  orderBy?: InputMaybe<Array<InputMaybe<UnitOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UnitUniqueInput>;
};

export type UnitListRelationFilter = {
  every?: InputMaybe<UnitWhereInput>;
  none?: InputMaybe<UnitWhereInput>;
  some?: InputMaybe<UnitWhereInput>;
};

export type UnitOrderByWithRelationInput = {
  id?: InputMaybe<Scalars['Any']['input']>;
  name?: InputMaybe<Scalars['Any']['input']>;
  unique_id?: InputMaybe<Scalars['Any']['input']>;
};

export enum UnitScalarField {
  NameTh = 'name_th',
  UniqueId = 'unique_id'
}

export type UnitUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type UnitUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type UnitWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<UnitWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<UnitWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<UnitWhereInput>>>;
  id?: InputMaybe<Scalars['Any']['input']>;
  name_en?: InputMaybe<Scalars['Any']['input']>;
  name_th?: InputMaybe<Scalars['Any']['input']>;
  unique_id?: InputMaybe<Scalars['Any']['input']>;
};

export type UomConversion = {
  __typename?: 'UomConversion';
  base_uom_rate: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  target_uom: Unit;
  target_uom_id: Scalars['Int']['output'];
  target_uom_rate: Scalars['Float']['output'];
  uom_group: UomGroup;
  uom_group_id: Scalars['Int']['output'];
};

export type UomGroup = {
  __typename?: 'UomGroup';
  base_uom: Unit;
  base_uom_id: Scalars['Int']['output'];
  convertable_uom_list: Array<Unit>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
  uom_conversion_list: Array<UomConversion>;
};

export type UpdatePosAccessTokenInput = {
  id: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  company_name?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  employee_type?: Maybe<Scalars['String']['output']>;
  first_name: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  is_sales_employee?: Maybe<Scalars['Boolean']['output']>;
  last_login_branch?: Maybe<Scalars['Int']['output']>;
  last_name: Scalars['String']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  nick_name?: Maybe<Scalars['String']['output']>;
  original_branch: Scalars['Int']['output'];
  original_branch_object?: Maybe<Branch>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['Int']['output'];
  title_name?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
};

export type UserAggrid = {
  __typename?: 'UserAggrid';
  count: Scalars['Int']['output'];
  results: Array<User>;
};

export type UserCreateInput = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  employee_type?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  is_sales_employee?: InputMaybe<Scalars['Boolean']['input']>;
  last_name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  original_branch: Scalars['Int']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
  status: Scalars['Int']['input'];
  title_name?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  user_password: Scalars['String']['input'];
};

export type UserImportDataInput = {
  branch_unique_id: Scalars['String']['input'];
  company_name?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  employee_type?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  is_sales_employee?: InputMaybe<Scalars['Boolean']['input']>;
  last_name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
  status: Scalars['Int']['input'];
  title_name?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  user_password: Scalars['String']['input'];
};

export type UserImportInput = {
  data: Array<UserImportDataInput>;
  import_mode: GeneralImportMode;
  priority: Scalars['Int']['input'];
  user_unique_id: Scalars['String']['input'];
};

export type UserUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateInput = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employee_type?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  is_sales_employee?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  new_password?: InputMaybe<Scalars['String']['input']>;
  nick_name?: InputMaybe<Scalars['String']['input']>;
  original_branch?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type UserView = {
  __typename?: 'UserView';
  branch_id?: Maybe<Scalars['Int']['output']>;
  branch_is_hq?: Maybe<Scalars['Boolean']['output']>;
  branch_name?: Maybe<Scalars['String']['output']>;
  branch_unique_id?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  is_sales_employee?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  title_name?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
};

export type UserViewAggrid = {
  __typename?: 'UserViewAggrid';
  count: Scalars['Int']['output'];
  results: Array<UserView>;
};

export type ValidateUserImportInput = {
  branch_unique_id_list?: InputMaybe<Array<Scalars['String']['input']>>;
  department_list?: InputMaybe<Array<Scalars['String']['input']>>;
  email_list?: InputMaybe<Array<Scalars['String']['input']>>;
  employee_type_list?: InputMaybe<Array<Scalars['String']['input']>>;
  import_mode: GeneralImportMode;
  phone_list?: InputMaybe<Array<Scalars['String']['input']>>;
  position_list?: InputMaybe<Array<Scalars['String']['input']>>;
  user_unique_id_list?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ValidateUserImportResult = {
  __typename?: 'ValidateUserImportResult';
  existing_email_list?: Maybe<Array<Scalars['String']['output']>>;
  existing_phone_list?: Maybe<Array<Scalars['String']['output']>>;
  existing_user_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_branch_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_department_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_employee_type_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_position_list?: Maybe<Array<Scalars['String']['output']>>;
  missing_user_unique_id_list?: Maybe<Array<Scalars['String']['output']>>;
};

export type ValidateUserInput = {
  unique_id: Scalars['String']['input'];
};

export type ValidateUserInventoryResult = {
  __typename?: 'ValidateUserInventoryResult';
  good_adjustment?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  good_issue?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  good_receive?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  good_return?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  good_transfer?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ValidateUserIsInUseResult = {
  __typename?: 'ValidateUserIsInUseResult';
  inventory?: Maybe<ValidateUserInventoryResult>;
  purchase?: Maybe<ValidateUserPurchaseResult>;
  sale?: Maybe<ValidateUserSaleResult>;
  user_permission?: Maybe<ValidateUserPermissionResult>;
};

export type ValidateUserPermissionResult = {
  __typename?: 'ValidateUserPermissionResult';
  approvalTemplate?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  role?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ValidateUserPurchaseResult = {
  __typename?: 'ValidateUserPurchaseResult';
  purchase_order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  purchase_request?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  purchase_return?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ValidateUserSaleResult = {
  __typename?: 'ValidateUserSaleResult';
  cash_sales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customer_deposit?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  quotation?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_invoice?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_return?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type VendorAttribute = {
  __typename?: 'VendorAttribute';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  short_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type VendorAttributeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type VendorAttributeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type VendorAttributeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type VendorGroup = {
  __typename?: 'VendorGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type VendorGroupCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type VendorGroupUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type VendorGroupUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type VendorType = {
  __typename?: 'VendorType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type VendorTypeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type VendorTypeUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type VendorTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  address?: Maybe<Scalars['Json']['output']>;
  branch: Branch;
  branch_id: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_consignment?: Maybe<Scalars['Boolean']['output']>;
  is_same_as_branch?: Maybe<Scalars['Boolean']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
  user: User;
  user_id: Scalars['Int']['output'];
  warehouse_level_1_list: Array<WarehouseLevel1>;
};

export type WarehouseCreateInput = {
  address?: InputMaybe<Scalars['Json']['input']>;
  branch_id: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  is_consignment?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};

export type WarehouseLevel1 = {
  __typename?: 'WarehouseLevel1';
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse: Warehouse;
  warehouse_level_2_list?: Maybe<Array<WarehouseLevel2>>;
};

export type WarehouseLevel1CreateInput = {
  name: Scalars['String']['input'];
  warehouse_id: Scalars['Int']['input'];
  warehouse_level_2_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel1UniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseLevel1UpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_id?: InputMaybe<Scalars['Int']['input']>;
  warehouse_level_2_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel2 = {
  __typename?: 'WarehouseLevel2';
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse_level_1: WarehouseLevel1;
  warehouse_level_3_list?: Maybe<Array<WarehouseLevel3>>;
};

export type WarehouseLevel2CreateInput = {
  name: Scalars['String']['input'];
  warehouse_level_1_id: Scalars['Int']['input'];
  warehouse_level_3_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel2UniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseLevel2UpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_level_1_id?: InputMaybe<Scalars['Int']['input']>;
  warehouse_level_3_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel3 = {
  __typename?: 'WarehouseLevel3';
  bin_location_list?: Maybe<Array<BinLocation>>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  warehouse_level_2: WarehouseLevel2;
};

export type WarehouseLevel3CreateInput = {
  bin_location_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  warehouse_level_2_id: Scalars['Int']['input'];
};

export type WarehouseLevel3UniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseLevel3UpdateInput = {
  bin_location_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_level_2_id?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehouseUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseUpdateInput = {
  address?: InputMaybe<Scalars['Json']['input']>;
  branch_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  is_consignment?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehouseWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<WarehouseWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<WarehouseWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<WarehouseWhereInput>>>;
  branch_id?: InputMaybe<Scalars['Any']['input']>;
  id?: InputMaybe<Scalars['Any']['input']>;
  user_id?: InputMaybe<Scalars['Any']['input']>;
};

export enum WorkflowDocumentType {
  Batch = 'batch',
  CreditNote = 'credit_note',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer',
  Item = 'item',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial'
}

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate';
  approval_template_list?: Maybe<Array<ApprovalTemplate>>;
  document_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  main_status: Scalars['String']['output'];
  step_number: Scalars['Int']['output'];
  sub_status?: Maybe<Scalars['String']['output']>;
};

export type WorkflowTemplateCreateInput = {
  approval_template_list?: InputMaybe<Array<ApprovalTemplateCreateInput>>;
  document_type: Scalars['String']['input'];
  main_status: Scalars['String']['input'];
  step_number: Scalars['Int']['input'];
  sub_status?: InputMaybe<Scalars['String']['input']>;
};

export type WorkflowTemplateStep_NumberDocument_TypeCompoundUniqueInput = {
  document_type: Scalars['String']['input'];
  step_number: Scalars['Int']['input'];
};

export type WorkflowTemplateUpdateInput = {
  approval_template_list?: InputMaybe<Array<ApprovalTemplateUpsertInput>>;
  document_type?: InputMaybe<Scalars['String']['input']>;
  main_status?: InputMaybe<Scalars['String']['input']>;
  step_number?: InputMaybe<Scalars['Int']['input']>;
  sub_status?: InputMaybe<Scalars['String']['input']>;
};

export type WorkflowTemplateUpsertInput = {
  approval_template_list?: InputMaybe<Array<ApprovalTemplateUpsertInput>>;
  document_type: Scalars['String']['input'];
  main_status: Scalars['String']['input'];
  step_number: Scalars['Int']['input'];
  sub_status?: InputMaybe<Scalars['String']['input']>;
};

export type WorkflowTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  step_number_document_type?: InputMaybe<WorkflowTemplateStep_NumberDocument_TypeCompoundUniqueInput>;
};

export type Zone = {
  __typename?: 'Zone';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name_en?: Maybe<Scalars['String']['output']>;
  name_th: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
};

export type ZoneCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type ZoneUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ZoneUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name_en?: InputMaybe<Scalars['String']['input']>;
  name_th?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum UomConversionScalarField {
  BaseUomRate = 'base_uom_rate',
  Id = 'id',
  TargetUomRate = 'target_uom_rate'
}

export type ActivityLogFindManyAggridQueryVariables = Exact<{
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
}>;


export type ActivityLogFindManyAggridQuery = { __typename?: 'Query', ActivityLogFindManyAggrid: { __typename?: 'ActivityLogFindManyAggrid', count: number, results: Array<{ __typename?: 'ActivityLog', id: number, activity_type?: ActivityType | null, reference_id?: number | null, document_type?: ActivityLogDocumentType | null, created_date?: any | null, activity_detail?: { __typename?: 'ActivityLogDetailObject', message?: Array<string> | null, attachment_list?: Array<string> | null, prev_status?: string | null, curr_status?: string | null, updated_fields?: any | null, added_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, deleted_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null } | null, created_by?: { __typename?: 'CreatorObject', user_id?: number | null, user_unique_id?: string | null, first_name?: string | null, last_name?: string | null, email?: string | null, img_url?: Array<string> | null } | null }> } };

export type ActivityLogCreateMutationVariables = Exact<{
  data: ActivityLogCreateInput;
}>;


export type ActivityLogCreateMutation = { __typename?: 'Mutation', ActivityLogCreate?: { __typename?: 'ActivityLog', id: number, activity_type?: ActivityType | null, reference_id?: number | null, document_type?: ActivityLogDocumentType | null, created_date?: any | null, activity_detail?: { __typename?: 'ActivityLogDetailObject', message?: Array<string> | null, attachment_list?: Array<string> | null, prev_status?: string | null, curr_status?: string | null, updated_fields?: any | null, added_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, deleted_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, copied_from?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null, copied_to?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null } | null, created_by?: { __typename?: 'CreatorObject', user_id?: number | null, user_unique_id?: string | null, first_name?: string | null, last_name?: string | null, email?: string | null, img_url?: Array<string> | null } | null } | null };

export type ActivityLogFindUniqueQueryVariables = Exact<{
  uniqueInput?: InputMaybe<ActivityLogUniqueInput>;
}>;


export type ActivityLogFindUniqueQuery = { __typename?: 'Query', ActivityLogFindUnique?: { __typename?: 'ActivityLog', id: number, activity_type?: ActivityType | null, reference_id?: number | null, document_type?: ActivityLogDocumentType | null, created_date?: any | null, activity_detail?: { __typename?: 'ActivityLogDetailObject', message?: Array<string> | null, attachment_list?: Array<string> | null, prev_status?: string | null, curr_status?: string | null, updated_fields?: any | null, added_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, deleted_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, copied_from?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null, copied_to?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null } | null, created_by?: { __typename?: 'CreatorObject', user_id?: number | null, user_unique_id?: string | null, first_name?: string | null, last_name?: string | null, email?: string | null, img_url?: Array<string> | null } | null } | null };

export type ActivityLogFindManyByReferenceIdQueryVariables = Exact<{
  findManyInput: ActivityLogByRefereceIdInput;
}>;


export type ActivityLogFindManyByReferenceIdQuery = { __typename?: 'Query', ActivityLogFindManyByReferenceId: Array<{ __typename?: 'ActivityLog', id: number, activity_type?: ActivityType | null, reference_id?: number | null, document_type?: ActivityLogDocumentType | null, created_date?: any | null, activity_detail?: { __typename?: 'ActivityLogDetailObject', message?: Array<string> | null, attachment_list?: Array<string> | null, prev_status?: string | null, curr_status?: string | null, updated_fields?: any | null, added_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, deleted_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, copied_from?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null, copied_to?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null } | null, created_by?: { __typename?: 'CreatorObject', user_id?: number | null, user_unique_id?: string | null, first_name?: string | null, last_name?: string | null, email?: string | null, img_url?: Array<string> | null } | null }> };

export type LoginMutationVariables = Exact<{
  loginInput?: InputMaybe<LoginInput>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', access_token: string, user: { __typename?: 'User', id: number, unique_id: string, email: string, original_branch: number, img_url?: Array<string | null> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, employee_type?: string | null, company_name?: string | null, is_sales_employee?: boolean | null, status: number, inactive_date?: any | null, start_date?: any | null, created_date?: any | null, last_updated_date?: any | null, last_login_branch?: number | null } } };

export type ValidateTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateTokenQuery = { __typename?: 'Query', validateToken: { __typename?: 'User', id: number, unique_id: string, email: string, original_branch: number, img_url?: Array<string | null> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, employee_type?: string | null, company_name?: string | null, is_sales_employee?: boolean | null, status: number, inactive_date?: any | null, start_date?: any | null, created_date?: any | null, last_updated_date?: any | null, last_login_branch?: number | null } };

export type BranchesFindManyAggridQueryVariables = Exact<{
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
}>;


export type BranchesFindManyAggridQuery = { __typename?: 'Query', BranchesFindManyAggrid: { __typename?: 'BranchAggrid', count: number, results: Array<{ __typename?: 'Branch', id: number, unique_id: string, name: string, short_name: string, zone?: string | null, phone?: string | null, fax?: string | null, status: number, is_hq: boolean, created_date?: any | null, last_updated_date?: any | null, address: any }> } };

export type BranchCreateMutationVariables = Exact<{
  data: BranchCreateInput;
}>;


export type BranchCreateMutation = { __typename?: 'Mutation', BranchCreate: { __typename?: 'Branch', id: number, unique_id: string, name: string, short_name: string, zone?: string | null, phone?: string | null, fax?: string | null, status: number, is_hq: boolean, created_date?: any | null, last_updated_date?: any | null, address: any } };

export type BranchDeleteMutationVariables = Exact<{
  uniqueInput: BranchUniqueInput;
}>;


export type BranchDeleteMutation = { __typename?: 'Mutation', BranchDelete?: { __typename?: 'Branch', name: string, id: number } | null };

export type BranchFindUniqueQueryVariables = Exact<{
  uniqueInput: BranchUniqueInput;
}>;


export type BranchFindUniqueQuery = { __typename?: 'Query', BranchFindUnique?: { __typename?: 'Branch', id: number, unique_id: string, name: string, short_name: string, zone?: string | null, phone?: string | null, fax?: string | null, status: number, is_hq: boolean, created_date?: any | null, last_updated_date?: any | null, address: any } | null };

export type BranchUpdateMutationVariables = Exact<{
  uniqueInput: BranchUniqueInput;
  data: BranchUpdateInput;
}>;


export type BranchUpdateMutation = { __typename?: 'Mutation', BranchUpdate: { __typename?: 'Branch', id: number, unique_id: string, name: string, short_name: string, zone?: string | null, phone?: string | null, fax?: string | null, status: number, is_hq: boolean, created_date?: any | null, last_updated_date?: any | null, address: any } };

export type ApprovalTemplateFindByDocumentTypeQueryVariables = Exact<{
  documentType: Scalars['String']['input'];
}>;


export type ApprovalTemplateFindByDocumentTypeQuery = { __typename?: 'Query', ApprovalTemplateFindByDocumentType?: Array<{ __typename?: 'ApprovalTemplate', required_approval: number, approver_id_list?: Array<string> | null, step_number: number, workflow_step_number: number, workflow_document_type: string, approver_list?: Array<{ __typename?: 'User', unique_id: string, id: number, first_name: string, last_name: string, email: string, img_url?: Array<string | null> | null, original_branch: number, department?: string | null, employee_type?: string | null, position?: string | null, status: number, original_branch_object?: { __typename?: 'Branch', name: string, unique_id: string } | null }> | null } | null> | null };

export type ApprovalTemplateUpsertManyMutationVariables = Exact<{
  data?: InputMaybe<Array<ApprovalTemplateCreateInput> | ApprovalTemplateCreateInput>;
  documentType?: InputMaybe<WorkflowDocumentType>;
}>;


export type ApprovalTemplateUpsertManyMutation = { __typename?: 'Mutation', ApprovalTemplateUpsertMany: Array<{ __typename?: 'ApprovalTemplate', id: number, step_number: number, required_approval: number, workflow_step_number: number, workflow_document_type: string, approver_id_list?: Array<string> | null, approver_list?: Array<{ __typename?: 'User', unique_id: string, id: number, first_name: string, last_name: string, email: string, img_url?: Array<string | null> | null, original_branch: number, department?: string | null, employee_type?: string | null, position?: string | null, status: number, original_branch_object?: { __typename?: 'Branch', name: string, unique_id: string } | null }> | null }> };

export type CompanyFindUniqueQueryVariables = Exact<{
  uniqueInput?: InputMaybe<CompanyUniqueInput>;
}>;


export type CompanyFindUniqueQuery = { __typename?: 'Query', CompanyFindUnique?: { __typename?: 'Company', id: number, unique_id: string, name: string, type?: string | null, identity_no?: string | null, register_date?: any | null, img_url?: Array<string | null> | null, is_registered_vat?: boolean | null, vat_registration_date?: any | null, main_currency: string, is_active: number, contact_channel_list?: Array<any | null> | null, address_list?: Array<any | null> | null } | null };

export type CompanyUpdateMutationVariables = Exact<{
  uniqueInput: CompanyUniqueInput;
  data: CompanyUpdateInput;
}>;


export type CompanyUpdateMutation = { __typename?: 'Mutation', CompanyUpdate?: { __typename?: 'Company', id: number, unique_id: string, name: string, type?: string | null, identity_no?: string | null, register_date?: any | null, img_url?: Array<string | null> | null, is_registered_vat?: boolean | null, vat_registration_date?: any | null, main_currency: string, is_active: number, contact_channel_list?: Array<any | null> | null, address_list?: Array<any | null> | null } | null };

export type CustomerAttributeCreateMutationVariables = Exact<{
  data: CustomerAttributeCreateInput;
}>;


export type CustomerAttributeCreateMutation = { __typename?: 'Mutation', CustomerAttributeCreate: { __typename?: 'CustomerAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } };

export type CustomerAttributeDeleteMutationVariables = Exact<{
  uniqueInput: CustomerAttributeUniqueInput;
}>;


export type CustomerAttributeDeleteMutation = { __typename?: 'Mutation', CustomerAttributeDelete: { __typename?: 'CustomerAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } };

export type CustomerAttributesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerAttributesFindAllQuery = { __typename?: 'Query', CustomerAttributesFindAll: Array<{ __typename?: 'CustomerAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null }> };

export type CustomerAttributeUpdateMutationVariables = Exact<{
  uniqueInput: CustomerAttributeUniqueInput;
  data: CustomerAttributeUpdateInput;
}>;


export type CustomerAttributeUpdateMutation = { __typename?: 'Mutation', CustomerAttributeUpdate: { __typename?: 'CustomerAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } };

export type CustomerAttributeFindUniqueQueryVariables = Exact<{
  uniqueInput: CustomerAttributeUniqueInput;
}>;


export type CustomerAttributeFindUniqueQuery = { __typename?: 'Query', CustomerAttributeFindUnique?: { __typename?: 'CustomerAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } | null };

export type CustomerGroupCreateMutationVariables = Exact<{
  data: CustomerGroupCreateInput;
}>;


export type CustomerGroupCreateMutation = { __typename?: 'Mutation', CustomerGroupCreate: { __typename?: 'CustomerGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CustomerGroupDeleteMutationVariables = Exact<{
  uniqueInput: CustomerGroupUniqueInput;
}>;


export type CustomerGroupDeleteMutation = { __typename?: 'Mutation', CustomerGroupDelete: { __typename?: 'CustomerGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CustomerGroupFindUniqueQueryVariables = Exact<{
  uniqueInput: CustomerGroupUniqueInput;
}>;


export type CustomerGroupFindUniqueQuery = { __typename?: 'Query', CustomerGroupFindUnique?: { __typename?: 'CustomerGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type CustomerGroupUpdateMutationVariables = Exact<{
  uniqueInput: CustomerGroupUniqueInput;
  data: CustomerGroupUpdateInput;
}>;


export type CustomerGroupUpdateMutation = { __typename?: 'Mutation', CustomerGroupUpdate: { __typename?: 'CustomerGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CustomerGroupsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerGroupsFindAllQuery = { __typename?: 'Query', CustomerGroupsFindAll: Array<{ __typename?: 'CustomerGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type CustomerTypeCreateMutationVariables = Exact<{
  data: CustomerTypeCreateInput;
}>;


export type CustomerTypeCreateMutation = { __typename?: 'Mutation', CustomerTypeCreate: { __typename?: 'CustomerType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CustomerTypeDeleteMutationVariables = Exact<{
  uniqueInput: CustomerTypeUniqueInput;
}>;


export type CustomerTypeDeleteMutation = { __typename?: 'Mutation', CustomerTypeDelete: { __typename?: 'CustomerType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CustomerTypeFindUniqueQueryVariables = Exact<{
  uniqueInput: CustomerTypeUniqueInput;
}>;


export type CustomerTypeFindUniqueQuery = { __typename?: 'Query', CustomerTypeFindUnique?: { __typename?: 'CustomerType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type CustomerTypeUpdateMutationVariables = Exact<{
  uniqueInput: CustomerTypeUniqueInput;
  data: CustomerTypeUpdateInput;
}>;


export type CustomerTypeUpdateMutation = { __typename?: 'Mutation', CustomerTypeUpdate: { __typename?: 'CustomerType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CustomerTypesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerTypesFindAllQuery = { __typename?: 'Query', CustomerTypesFindAll: Array<{ __typename?: 'CustomerType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type VendorAttributeCreateMutationVariables = Exact<{
  data: VendorAttributeCreateInput;
}>;


export type VendorAttributeCreateMutation = { __typename?: 'Mutation', VendorAttributeCreate: { __typename?: 'VendorAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } };

export type VendorAttributeDeleteMutationVariables = Exact<{
  uniqueInput: VendorAttributeUniqueInput;
}>;


export type VendorAttributeDeleteMutation = { __typename?: 'Mutation', VendorAttributeDelete: { __typename?: 'VendorAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } };

export type VendorAttributesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorAttributesFindAllQuery = { __typename?: 'Query', VendorAttributesFindAll: Array<{ __typename?: 'VendorAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null }> };

export type VendorAttributeUpdateMutationVariables = Exact<{
  uniqueInput: VendorAttributeUniqueInput;
  data: VendorAttributeUpdateInput;
}>;


export type VendorAttributeUpdateMutation = { __typename?: 'Mutation', VendorAttributeUpdate: { __typename?: 'VendorAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } };

export type VendorAttributeFindUniqueQueryVariables = Exact<{
  uniqueInput: VendorAttributeUniqueInput;
}>;


export type VendorAttributeFindUniqueQuery = { __typename?: 'Query', VendorAttributeFindUnique?: { __typename?: 'VendorAttribute', id: number, name: string, short_name?: string | null, type?: string | null, description?: string | null } | null };

export type VendorGroupCreateMutationVariables = Exact<{
  data: VendorGroupCreateInput;
}>;


export type VendorGroupCreateMutation = { __typename?: 'Mutation', VendorGroupCreate: { __typename?: 'VendorGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type VendorGroupDeleteMutationVariables = Exact<{
  uniqueInput: VendorGroupUniqueInput;
}>;


export type VendorGroupDeleteMutation = { __typename?: 'Mutation', VendorGroupDelete: { __typename?: 'VendorGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type VendorGroupFindUniqueQueryVariables = Exact<{
  uniqueInput: VendorGroupUniqueInput;
}>;


export type VendorGroupFindUniqueQuery = { __typename?: 'Query', VendorGroupFindUnique?: { __typename?: 'VendorGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type VendorGroupUpdateMutationVariables = Exact<{
  uniqueInput: VendorGroupUniqueInput;
  data: VendorGroupUpdateInput;
}>;


export type VendorGroupUpdateMutation = { __typename?: 'Mutation', VendorGroupUpdate: { __typename?: 'VendorGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type VendorGroupsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorGroupsFindAllQuery = { __typename?: 'Query', VendorGroupsFindAll: Array<{ __typename?: 'VendorGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type VendorTypeCreateMutationVariables = Exact<{
  data: VendorTypeCreateInput;
}>;


export type VendorTypeCreateMutation = { __typename?: 'Mutation', VendorTypeCreate: { __typename?: 'VendorType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type VendorTypeDeleteMutationVariables = Exact<{
  uniqueInput: VendorTypeUniqueInput;
}>;


export type VendorTypeDeleteMutation = { __typename?: 'Mutation', VendorTypeDelete: { __typename?: 'VendorType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type VendorTypeFindUniqueQueryVariables = Exact<{
  uniqueInput: VendorTypeUniqueInput;
}>;


export type VendorTypeFindUniqueQuery = { __typename?: 'Query', VendorTypeFindUnique?: { __typename?: 'VendorType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type VendorTypeUpdateMutationVariables = Exact<{
  uniqueInput: VendorTypeUniqueInput;
  data: VendorTypeUpdateInput;
}>;


export type VendorTypeUpdateMutation = { __typename?: 'Mutation', VendorTypeUpdate: { __typename?: 'VendorType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type VendorTypesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorTypesFindAllQuery = { __typename?: 'Query', VendorTypesFindAll: Array<{ __typename?: 'VendorType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type CreatableFieldCreateMutationVariables = Exact<{
  data: CreatableFieldCreateInput;
}>;


export type CreatableFieldCreateMutation = { __typename?: 'Mutation', CreatableFieldCreate: { __typename?: 'CreatableField', id: number, creatable_fields_name: string, document_type: CreatableFieldDocumentType, name: string } };

export type CreatableFieldDeleteMutationVariables = Exact<{
  uniqueInput: CreatableFieldUniqueInput;
}>;


export type CreatableFieldDeleteMutation = { __typename?: 'Mutation', CreatableFieldDelete: { __typename?: 'CreatableField', name: string } };

export type CreatableFieldsQueryVariables = Exact<{
  findManyInput?: InputMaybe<CreatableFieldFindManyInput>;
}>;


export type CreatableFieldsQuery = { __typename?: 'Query', CreatableFields: Array<{ __typename?: 'CreatableField', creatable_fields_name: string, document_type: CreatableFieldDocumentType, id: number, name: string }> };

export type DepartmentCreateMutationVariables = Exact<{
  data: DepartmentCreateInput;
}>;


export type DepartmentCreateMutation = { __typename?: 'Mutation', DepartmentCreate: { __typename?: 'Department', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type DepartmentDeleteMutationVariables = Exact<{
  uniqueInput: DepartmentUniqueInput;
}>;


export type DepartmentDeleteMutation = { __typename?: 'Mutation', DepartmentDelete: { __typename?: 'Department', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type DepartmentFindUniqueQueryVariables = Exact<{
  uniqueInput: DepartmentUniqueInput;
}>;


export type DepartmentFindUniqueQuery = { __typename?: 'Query', DepartmentFindUnique?: { __typename?: 'Department', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type DepartmentUpdateMutationVariables = Exact<{
  uniqueInput: DepartmentUniqueInput;
  data: DepartmentUpdateInput;
}>;


export type DepartmentUpdateMutation = { __typename?: 'Mutation', DepartmentUpdate: { __typename?: 'Department', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type DepartmentsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentsFindAllQuery = { __typename?: 'Query', DepartmentsFindAll: Array<{ __typename?: 'Department', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type PositionCreateMutationVariables = Exact<{
  data: PositionCreateInput;
}>;


export type PositionCreateMutation = { __typename?: 'Mutation', PositionCreate: { __typename?: 'Position', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type PositionDeleteMutationVariables = Exact<{
  uniqueInput: PositionUniqueInput;
}>;


export type PositionDeleteMutation = { __typename?: 'Mutation', PositionDelete: { __typename?: 'Position', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type PositionFindUniqueQueryVariables = Exact<{
  uniqueInput: PositionUniqueInput;
}>;


export type PositionFindUniqueQuery = { __typename?: 'Query', PositionFindUnique?: { __typename?: 'Position', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type PositionUpdateMutationVariables = Exact<{
  uniqueInput: PositionUniqueInput;
  data: PositionUpdateInput;
}>;


export type PositionUpdateMutation = { __typename?: 'Mutation', PositionUpdate: { __typename?: 'Position', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type PositionsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type PositionsFindAllQuery = { __typename?: 'Query', PositionsFindAll: Array<{ __typename?: 'Position', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type ItemAttributeCreateMutationVariables = Exact<{
  data: ItemAttributeCreateInput;
}>;


export type ItemAttributeCreateMutation = { __typename?: 'Mutation', ItemAttributeCreate: { __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null } };

export type ItemAttributeDeleteMutationVariables = Exact<{
  uniqueInput: ItemAttributeUniqueInput;
}>;


export type ItemAttributeDeleteMutation = { __typename?: 'Mutation', ItemAttributeDelete: { __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null } };

export type ItemAttributeFindUniqueQueryVariables = Exact<{
  uniqueInput: ItemAttributeUniqueInput;
}>;


export type ItemAttributeFindUniqueQuery = { __typename?: 'Query', ItemAttributeFindUnique?: { __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null } | null };

export type ItemAttributeUpdateMutationVariables = Exact<{
  uniqueInput: ItemAttributeUniqueInput;
  data: ItemAttributeUpdateInput;
}>;


export type ItemAttributeUpdateMutation = { __typename?: 'Mutation', ItemAttributeUpdate: { __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null } };

export type ItemAttributesFindManyQueryVariables = Exact<{
  setAttributeId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ItemAttributesFindManyQuery = { __typename?: 'Query', ItemAttributesFindMany: Array<{ __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null }> };

export type SetItemAttributeCreateMutationVariables = Exact<{
  createInput: SetItemAttributeCreateInput;
}>;


export type SetItemAttributeCreateMutation = { __typename?: 'Mutation', SetItemAttributeCreate: { __typename?: 'SetItemAttribute', id: number, name_th: string, remark?: string | null, item_attribute_list?: Array<{ __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null }> | null } };

export type SetItemAttributeDeleteMutationVariables = Exact<{
  uniqueInput: SetItemAttributeUniqueInput;
}>;


export type SetItemAttributeDeleteMutation = { __typename?: 'Mutation', SetItemAttributeDelete: { __typename?: 'SetItemAttribute', id: number, name_th: string, remark?: string | null, item_attribute_list?: Array<{ __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null }> | null } };

export type SetItemAttributeQueryVariables = Exact<{
  uniqueInput: SetItemAttributeUniqueInput;
}>;


export type SetItemAttributeQuery = { __typename?: 'Query', SetItemAttribute?: { __typename?: 'SetItemAttribute', id: number, name_th: string, remark?: string | null, item_attribute_list?: Array<{ __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null }> | null } | null };

export type SetItemAttributeUpdateMutationVariables = Exact<{
  uniqueInput: SetItemAttributeUniqueInput;
  updateInput: SetItemAttributeUpdateInput;
}>;


export type SetItemAttributeUpdateMutation = { __typename?: 'Mutation', SetItemAttributeUpdate: { __typename?: 'SetItemAttribute', id: number, name_th: string, remark?: string | null, item_attribute_list?: Array<{ __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null }> | null } };

export type SetItemAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type SetItemAttributesQuery = { __typename?: 'Query', SetItemAttributes: Array<{ __typename?: 'SetItemAttribute', id: number, name_th: string, remark?: string | null, item_attribute_list?: Array<{ __typename?: 'ItemAttribute', id: number, sorting_index: number, set_item_attribute_id: number, name: string, short_name: string, type?: string | null, description?: string | null }> | null }> };

export type BrandCreateMutationVariables = Exact<{
  data: BrandCreateInput;
}>;


export type BrandCreateMutation = { __typename?: 'Mutation', BrandCreate: { __typename?: 'Brand', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type BrandDeleteMutationVariables = Exact<{
  uniqueInput: BrandUniqueInput;
}>;


export type BrandDeleteMutation = { __typename?: 'Mutation', BrandDelete: { __typename?: 'Brand', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type BrandFindUniqueQueryVariables = Exact<{
  uniqueInput: BrandUniqueInput;
}>;


export type BrandFindUniqueQuery = { __typename?: 'Query', BrandFindUnique?: { __typename?: 'Brand', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type BrandUpdateMutationVariables = Exact<{
  uniqueInput: BrandUniqueInput;
  data: BrandUpdateInput;
}>;


export type BrandUpdateMutation = { __typename?: 'Mutation', BrandUpdate: { __typename?: 'Brand', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type BrandsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsFindAllQuery = { __typename?: 'Query', BrandsFindAll: Array<{ __typename?: 'Brand', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type MainCategorysFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type MainCategorysFindAllQuery = { __typename?: 'Query', MainCategorysFindAll: Array<{ __typename?: 'MainCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type MainCategoryCreateMutationVariables = Exact<{
  data: MainCategoryCreateInput;
}>;


export type MainCategoryCreateMutation = { __typename?: 'Mutation', MainCategoryCreate: { __typename?: 'MainCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type MainCategoryDeleteMutationVariables = Exact<{
  uniqueInput: MainCategoryUniqueInput;
}>;


export type MainCategoryDeleteMutation = { __typename?: 'Mutation', MainCategoryDelete: { __typename?: 'MainCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type MainCategoryFindUniqueQueryVariables = Exact<{
  uniqueInput: MainCategoryUniqueInput;
}>;


export type MainCategoryFindUniqueQuery = { __typename?: 'Query', MainCategoryFindUnique?: { __typename?: 'MainCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type MainCategoryUpdateMutationVariables = Exact<{
  uniqueInput: MainCategoryUniqueInput;
  data: MainCategoryUpdateInput;
}>;


export type MainCategoryUpdateMutation = { __typename?: 'Mutation', MainCategoryUpdate: { __typename?: 'MainCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CategoryGroupsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryGroupsFindAllQuery = { __typename?: 'Query', CategoryGroupsFindAll: Array<{ __typename?: 'CategoryGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type CategoryGroupCreateMutationVariables = Exact<{
  data: CategoryGroupCreateInput;
}>;


export type CategoryGroupCreateMutation = { __typename?: 'Mutation', CategoryGroupCreate: { __typename?: 'CategoryGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CategoryGroupDeleteMutationVariables = Exact<{
  uniqueInput: CategoryGroupUniqueInput;
}>;


export type CategoryGroupDeleteMutation = { __typename?: 'Mutation', CategoryGroupDelete: { __typename?: 'CategoryGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type CategoryGroupFindUniqueQueryVariables = Exact<{
  uniqueInput: CategoryGroupUniqueInput;
}>;


export type CategoryGroupFindUniqueQuery = { __typename?: 'Query', CategoryGroupFindUnique?: { __typename?: 'CategoryGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type CategoryGroupUpdateMutationVariables = Exact<{
  uniqueInput: CategoryGroupUniqueInput;
  data: CategoryGroupUpdateInput;
}>;


export type CategoryGroupUpdateMutation = { __typename?: 'Mutation', CategoryGroupUpdate: { __typename?: 'CategoryGroup', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ModelCreateMutationVariables = Exact<{
  data: ModelCreateInput;
}>;


export type ModelCreateMutation = { __typename?: 'Mutation', ModelCreate: { __typename?: 'Model', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ModelDeleteMutationVariables = Exact<{
  uniqueInput: ModelUniqueInput;
}>;


export type ModelDeleteMutation = { __typename?: 'Mutation', ModelDelete: { __typename?: 'Model', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ModelFindUniqueQueryVariables = Exact<{
  uniqueInput: ModelUniqueInput;
}>;


export type ModelFindUniqueQuery = { __typename?: 'Query', ModelFindUnique?: { __typename?: 'Model', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type ModelUpdateMutationVariables = Exact<{
  uniqueInput: ModelUniqueInput;
  data: ModelUpdateInput;
}>;


export type ModelUpdateMutation = { __typename?: 'Mutation', ModelUpdate: { __typename?: 'Model', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ModelsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type ModelsFindAllQuery = { __typename?: 'Query', ModelsFindAll: Array<{ __typename?: 'Model', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type SegmentCreateMutationVariables = Exact<{
  data: SegmentCreateInput;
}>;


export type SegmentCreateMutation = { __typename?: 'Mutation', SegmentCreate: { __typename?: 'Segment', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SegmentDeleteMutationVariables = Exact<{
  uniqueInput: SegmentUniqueInput;
}>;


export type SegmentDeleteMutation = { __typename?: 'Mutation', SegmentDelete: { __typename?: 'Segment', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SegmentFindUniqueQueryVariables = Exact<{
  uniqueInput: SegmentUniqueInput;
}>;


export type SegmentFindUniqueQuery = { __typename?: 'Query', SegmentFindUnique?: { __typename?: 'Segment', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type SegmentUpdateMutationVariables = Exact<{
  uniqueInput: SegmentUniqueInput;
  data: SegmentUpdateInput;
}>;


export type SegmentUpdateMutation = { __typename?: 'Mutation', SegmentUpdate: { __typename?: 'Segment', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SegmentsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentsFindAllQuery = { __typename?: 'Query', SegmentsFindAll: Array<{ __typename?: 'Segment', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type SeriesCreateMutationVariables = Exact<{
  data: SeriesCreateInput;
}>;


export type SeriesCreateMutation = { __typename?: 'Mutation', SeriesCreate: { __typename?: 'Series', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SeriesDeleteMutationVariables = Exact<{
  uniqueInput: SeriesUniqueInput;
}>;


export type SeriesDeleteMutation = { __typename?: 'Mutation', SeriesDelete: { __typename?: 'Series', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SeriesFindUniqueQueryVariables = Exact<{
  uniqueInput: SeriesUniqueInput;
}>;


export type SeriesFindUniqueQuery = { __typename?: 'Query', SeriesFindUnique?: { __typename?: 'Series', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type SeriesUpdateMutationVariables = Exact<{
  uniqueInput: SeriesUniqueInput;
  data: SeriesUpdateInput;
}>;


export type SeriesUpdateMutation = { __typename?: 'Mutation', SeriesUpdate: { __typename?: 'Series', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SeriessFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SeriessFindAllQuery = { __typename?: 'Query', SeriessFindAll: Array<{ __typename?: 'Series', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type SubCategorysFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SubCategorysFindAllQuery = { __typename?: 'Query', SubCategorysFindAll: Array<{ __typename?: 'SubCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type SubCategoryCreateMutationVariables = Exact<{
  data: SubCategoryCreateInput;
}>;


export type SubCategoryCreateMutation = { __typename?: 'Mutation', SubCategoryCreate: { __typename?: 'SubCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SubCategoryDeleteMutationVariables = Exact<{
  uniqueInput: SubCategoryUniqueInput;
}>;


export type SubCategoryDeleteMutation = { __typename?: 'Mutation', SubCategoryDelete: { __typename?: 'SubCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SubCategoryFindUniqueQueryVariables = Exact<{
  uniqueInput: SubCategoryUniqueInput;
}>;


export type SubCategoryFindUniqueQuery = { __typename?: 'Query', SubCategoryFindUnique?: { __typename?: 'SubCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type SubCategoryUpdateMutationVariables = Exact<{
  uniqueInput: SubCategoryUniqueInput;
  data: SubCategoryUpdateInput;
}>;


export type SubCategoryUpdateMutation = { __typename?: 'Mutation', SubCategoryUpdate: { __typename?: 'SubCategory', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type UnitCreateMutationVariables = Exact<{
  data: UnitCreateInput;
}>;


export type UnitCreateMutation = { __typename?: 'Mutation', UnitCreate: { __typename?: 'Unit', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type UnitDeleteMutationVariables = Exact<{
  uniqueInput: UnitUniqueInput;
}>;


export type UnitDeleteMutation = { __typename?: 'Mutation', UnitDelete: { __typename?: 'Unit', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type UnitFindUniqueQueryVariables = Exact<{
  uniqueInput: UnitUniqueInput;
}>;


export type UnitFindUniqueQuery = { __typename?: 'Query', UnitFindUnique?: { __typename?: 'Unit', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type UnitUpdateMutationVariables = Exact<{
  uniqueInput: UnitUniqueInput;
  data: UnitUpdateInput;
}>;


export type UnitUpdateMutation = { __typename?: 'Mutation', UnitUpdate: { __typename?: 'Unit', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type UnitsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsFindAllQuery = { __typename?: 'Query', UnitsFindAll: Array<{ __typename?: 'Unit', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type BinLocationFindUniqueQueryVariables = Exact<{
  uniqueInput: BinLocationUniqueInput;
}>;


export type BinLocationFindUniqueQuery = { __typename?: 'Query', BinLocationFindUnique?: { __typename?: 'BinLocation', name: string, id: number } | null };

export type BinLocationCreateMutationVariables = Exact<{
  data: BinLocationCreateInput;
}>;


export type BinLocationCreateMutation = { __typename?: 'Mutation', BinLocationCreate: { __typename?: 'BinLocation', id: number, name: string } };

export type BinLocationDeleteMutationVariables = Exact<{
  uniqueInput: BinLocationUniqueInput;
}>;


export type BinLocationDeleteMutation = { __typename?: 'Mutation', BinLocationDelete: { __typename?: 'BinLocation', id: number, name: string } };

export type BinLocationsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type BinLocationsFindAllQuery = { __typename?: 'Query', BinLocationsFindAll: Array<{ __typename?: 'BinLocation', id: number, name: string, warehouse_level_3: { __typename?: 'WarehouseLevel3', id: number, name: string, warehouse_level_2: { __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_1: { __typename?: 'WarehouseLevel1', id: number, name: string, warehouse: { __typename?: 'Warehouse', id: number, name: string } } } } }> };

export type BinLocationUpdateMutationVariables = Exact<{
  uniqueInput: BinLocationUniqueInput;
  data: BinLocationUpdateInput;
}>;


export type BinLocationUpdateMutation = { __typename?: 'Mutation', BinLocationUpdate: { __typename?: 'BinLocation', id: number, name: string } };

export type WarehouseFindUniqueQueryVariables = Exact<{
  uniqueInput: WarehouseUniqueInput;
}>;


export type WarehouseFindUniqueQuery = { __typename?: 'Query', WarehouseFindUnique?: { __typename?: 'Warehouse', id: number, branch_id: number, unique_id: string, name: string, user_id: number, is_consignment?: boolean | null, description?: string | null, address?: any | null, user: { __typename?: 'User', email: string, first_name: string, last_name: string, id: number }, branch: { __typename?: 'Branch', unique_id: string, name: string, is_hq: boolean, id: number }, warehouse_level_1_list: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> } | null };

export type WarehouseCreateMutationVariables = Exact<{
  data: WarehouseCreateInput;
}>;


export type WarehouseCreateMutation = { __typename?: 'Mutation', WarehouseCreate: { __typename?: 'Warehouse', id: number, branch_id: number, unique_id: string, name: string, user_id: number, is_consignment?: boolean | null, description?: string | null, address?: any | null, user: { __typename?: 'User', email: string, first_name: string, last_name: string, id: number }, branch: { __typename?: 'Branch', unique_id: string, name: string, is_hq: boolean, id: number }, warehouse_level_1_list: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> } };

export type WarehouseDeleteMutationVariables = Exact<{
  uniqueInput: WarehouseUniqueInput;
}>;


export type WarehouseDeleteMutation = { __typename?: 'Mutation', WarehouseDelete: { __typename?: 'Warehouse', id: number, unique_id: string, name: string } };

export type WarehouseLevel1CreateMutationVariables = Exact<{
  data: WarehouseLevel1CreateInput;
}>;


export type WarehouseLevel1CreateMutation = { __typename?: 'Mutation', WarehouseLevel1Create: { __typename?: 'WarehouseLevel1', id: number, name: string } };

export type WarehouseLevel1DeleteMutationVariables = Exact<{
  uniqueInput: WarehouseLevel1UniqueInput;
}>;


export type WarehouseLevel1DeleteMutation = { __typename?: 'Mutation', WarehouseLevel1Delete: { __typename?: 'WarehouseLevel1', id: number, name: string } };

export type WarehouseLevel1UpdateMutationVariables = Exact<{
  data: WarehouseLevel1UpdateInput;
  uniqueInput: WarehouseLevel1UniqueInput;
}>;


export type WarehouseLevel1UpdateMutation = { __typename?: 'Mutation', WarehouseLevel1Update: { __typename?: 'WarehouseLevel1', id: number, name: string } };

export type WarehouseLevel2CreateMutationVariables = Exact<{
  data: WarehouseLevel2CreateInput;
}>;


export type WarehouseLevel2CreateMutation = { __typename?: 'Mutation', WarehouseLevel2Create: { __typename?: 'WarehouseLevel2', id: number, name: string } };

export type WarehouseLevel2DeleteMutationVariables = Exact<{
  uniqueInput: WarehouseLevel2UniqueInput;
}>;


export type WarehouseLevel2DeleteMutation = { __typename?: 'Mutation', WarehouseLevel2Delete: { __typename?: 'WarehouseLevel2', id: number, name: string } };

export type WarehouseLevel2UpdateMutationVariables = Exact<{
  uniqueInput: WarehouseLevel2UniqueInput;
  data: WarehouseLevel2UpdateInput;
}>;


export type WarehouseLevel2UpdateMutation = { __typename?: 'Mutation', WarehouseLevel2Update: { __typename?: 'WarehouseLevel2', id: number, name: string } };

export type WarehouseLevel3CreateMutationVariables = Exact<{
  data: WarehouseLevel3CreateInput;
}>;


export type WarehouseLevel3CreateMutation = { __typename?: 'Mutation', WarehouseLevel3Create: { __typename?: 'WarehouseLevel3', id: number, name: string } };

export type WarehouseLevel3DeleteMutationVariables = Exact<{
  uniqueInput: WarehouseLevel3UniqueInput;
}>;


export type WarehouseLevel3DeleteMutation = { __typename?: 'Mutation', WarehouseLevel3Delete: { __typename?: 'WarehouseLevel3', id: number, name: string } };

export type WarehouseLevel3UpdateMutationVariables = Exact<{
  uniqueInput: WarehouseLevel3UniqueInput;
  data: WarehouseLevel3UpdateInput;
}>;


export type WarehouseLevel3UpdateMutation = { __typename?: 'Mutation', WarehouseLevel3Update: { __typename?: 'WarehouseLevel3', id: number, name: string } };

export type WarehouseUpdateMutationVariables = Exact<{
  uniqueInput: WarehouseUniqueInput;
  data: WarehouseUpdateInput;
}>;


export type WarehouseUpdateMutation = { __typename?: 'Mutation', WarehouseUpdate: { __typename?: 'Warehouse', id: number, branch_id: number, unique_id: string, name: string, user_id: number, description?: string | null, address?: any | null, is_consignment?: boolean | null, user: { __typename?: 'User', email: string, first_name: string, last_name: string, id: number }, branch: { __typename?: 'Branch', unique_id: string, name: string, is_hq: boolean, id: number }, warehouse_level_1_list: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> } };

export type WarehousesFindAllQueryVariables = Exact<{
  whereInput?: InputMaybe<WarehouseWhereInput>;
}>;


export type WarehousesFindAllQuery = { __typename?: 'Query', WarehousesFindAll: Array<{ __typename?: 'Warehouse', id: number, branch_id: number, unique_id: string, name: string, user_id: number, is_consignment?: boolean | null, description?: string | null, address?: any | null, user: { __typename?: 'User', email: string, first_name: string, last_name: string, id: number }, branch: { __typename?: 'Branch', unique_id: string, name: string, is_hq: boolean, id: number }, warehouse_level_1_list: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> }> };

export type DeliveryTypeCreateMutationVariables = Exact<{
  data: DeliveryTypeCreateInput;
}>;


export type DeliveryTypeCreateMutation = { __typename?: 'Mutation', DeliveryTypeCreate: { __typename?: 'DeliveryType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type DeliveryTypeDeleteMutationVariables = Exact<{
  uniqueInput: DeliveryTypeUniqueInput;
}>;


export type DeliveryTypeDeleteMutation = { __typename?: 'Mutation', DeliveryTypeDelete: { __typename?: 'DeliveryType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type DeliveryTypeFindUniqueQueryVariables = Exact<{
  uniqueInput: DeliveryTypeUniqueInput;
}>;


export type DeliveryTypeFindUniqueQuery = { __typename?: 'Query', DeliveryTypeFindUnique?: { __typename?: 'DeliveryType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type DeliveryTypeUpdateMutationVariables = Exact<{
  uniqueInput: DeliveryTypeUniqueInput;
  data: DeliveryTypeUpdateInput;
}>;


export type DeliveryTypeUpdateMutation = { __typename?: 'Mutation', DeliveryTypeUpdate: { __typename?: 'DeliveryType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type DeliveryTypesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryTypesFindAllQuery = { __typename?: 'Query', DeliveryTypesFindAll: Array<{ __typename?: 'DeliveryType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type PaymentChannelCreateMutationVariables = Exact<{
  data: PaymentChannelCreateInput;
}>;


export type PaymentChannelCreateMutation = { __typename?: 'Mutation', PaymentChannelCreate: { __typename?: 'PaymentChannel', id: number, unique_id: string, payment_name: string, account_no: string, name: string, bank_name?: string | null, account_type?: string | null, payment_type?: Array<string> | null, description?: string | null, branch_id_list?: Array<number> | null } };

export type PaymentChannelDeleteMutationVariables = Exact<{
  uniqueInput: PaymentChannelUniqueInput;
}>;


export type PaymentChannelDeleteMutation = { __typename?: 'Mutation', PaymentChannelDelete: { __typename?: 'PaymentChannel', id: number, unique_id: string, payment_name: string, account_no: string, name: string, bank_name?: string | null, account_type?: string | null, payment_type?: Array<string> | null, description?: string | null, branch_id_list?: Array<number> | null } };

export type PaymentChannelFindUniqueQueryVariables = Exact<{
  uniqueInput: PaymentChannelUniqueInput;
}>;


export type PaymentChannelFindUniqueQuery = { __typename?: 'Query', PaymentChannelFindUnique?: { __typename?: 'PaymentChannel', id: number, unique_id: string, payment_name: string, account_no: string, name: string, bank_name?: string | null, account_type?: string | null, payment_type?: Array<string> | null, description?: string | null, branch_id_list?: Array<number> | null } | null };

export type PaymentChannelUpdateMutationVariables = Exact<{
  uniqueInput: PaymentChannelUniqueInput;
  data: PaymentChannelUpdateInput;
}>;


export type PaymentChannelUpdateMutation = { __typename?: 'Mutation', PaymentChannelUpdate: { __typename?: 'PaymentChannel', id: number, unique_id: string, payment_name: string, account_no: string, name: string, bank_name?: string | null, account_type?: string | null, payment_type?: Array<string> | null, description?: string | null, branch_id_list?: Array<number> | null } };

export type PaymentChannelsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentChannelsFindAllQuery = { __typename?: 'Query', PaymentChannelsFindAll: Array<{ __typename?: 'PaymentChannel', id: number, unique_id: string, payment_name: string, account_no: string, name: string, bank_name?: string | null, account_type?: string | null, payment_type?: Array<string> | null, description?: string | null, branch_id_list?: Array<number> | null }> };

export type SalesChannelCreateMutationVariables = Exact<{
  data: SalesChannelCreateInput;
}>;


export type SalesChannelCreateMutation = { __typename?: 'Mutation', SalesChannelCreate: { __typename?: 'SalesChannel', id: number, unique_id: string, name_th: string, name_en: string, description: string } };

export type SalesChannelDeleteMutationVariables = Exact<{
  uniqueInput: SalesChannelUniqueInput;
}>;


export type SalesChannelDeleteMutation = { __typename?: 'Mutation', SalesChannelDelete: { __typename?: 'SalesChannel', id: number, unique_id: string, name_th: string, name_en: string, description: string } };

export type SalesChannelFindUniqueQueryVariables = Exact<{
  uniqueInput: SalesChannelUniqueInput;
}>;


export type SalesChannelFindUniqueQuery = { __typename?: 'Query', SalesChannelFindUnique?: { __typename?: 'SalesChannel', id: number, unique_id: string, name_th: string, name_en: string, description: string } | null };

export type SalesChannelUpdateMutationVariables = Exact<{
  uniqueInput: SalesChannelUniqueInput;
  data: SalesChannelUpdateInput;
}>;


export type SalesChannelUpdateMutation = { __typename?: 'Mutation', SalesChannelUpdate: { __typename?: 'SalesChannel', id: number, unique_id: string, name_th: string, name_en: string, description: string } };

export type SalesChannelsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SalesChannelsFindAllQuery = { __typename?: 'Query', SalesChannelsFindAll: Array<{ __typename?: 'SalesChannel', name_th: string, name_en: string, id: number, unique_id: string, description: string, item_price_list?: Array<{ __typename?: 'ItemPriceList', id: number, unique_id: string, type: string, name: string, is_increase: number, currency?: string | null, description?: string | null, is_active: number, start_date?: any | null, end_date?: any | null, adjusted_price?: number | null, adjusted_item_list?: Array<{ __typename?: 'ItemAdjustment', id: number, sku_name: string, item_name: string, reference_unique_id?: string | null, current_price?: number | null, adjusted_price?: number | null } | null> | null } | null> | null }> };

export type SalesTypeCreateMutationVariables = Exact<{
  data: SalesTypeCreateInput;
}>;


export type SalesTypeCreateMutation = { __typename?: 'Mutation', SalesTypeCreate: { __typename?: 'SalesType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SalesTypeDeleteMutationVariables = Exact<{
  uniqueInput: SalesTypeUniqueInput;
}>;


export type SalesTypeDeleteMutation = { __typename?: 'Mutation', SalesTypeDelete: { __typename?: 'SalesType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SalesTypeFindUniqueQueryVariables = Exact<{
  uniqueInput: SalesTypeUniqueInput;
}>;


export type SalesTypeFindUniqueQuery = { __typename?: 'Query', SalesTypeFindUnique?: { __typename?: 'SalesType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type SalesTypeUpdateMutationVariables = Exact<{
  uniqueInput: SalesTypeUniqueInput;
  data: SalesTypeUpdateInput;
}>;


export type SalesTypeUpdateMutation = { __typename?: 'Mutation', SalesTypeUpdate: { __typename?: 'SalesType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type SalesTypesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SalesTypesFindAllQuery = { __typename?: 'Query', SalesTypesFindAll: Array<{ __typename?: 'SalesType', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type TagCreateMutationVariables = Exact<{
  data: TagCreateInput;
}>;


export type TagCreateMutation = { __typename?: 'Mutation', TagCreate: { __typename?: 'Tag', id: number, name: string, document_type: TagDocumentType } };

export type TagDeleteMutationVariables = Exact<{
  uniqueInput: TagUniqueInput;
}>;


export type TagDeleteMutation = { __typename?: 'Mutation', TagDelete: { __typename?: 'Tag', id: number, name: string, document_type: TagDocumentType } };

export type TagsQueryVariables = Exact<{
  findManyInput?: InputMaybe<TagFindManyInput>;
}>;


export type TagsQuery = { __typename?: 'Query', Tags: Array<{ __typename?: 'Tag', id: number, name: string, document_type: TagDocumentType }> };

export type ZoneCreateMutationVariables = Exact<{
  data: ZoneCreateInput;
}>;


export type ZoneCreateMutation = { __typename?: 'Mutation', ZoneCreate: { __typename?: 'Zone', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ZoneDeleteMutationVariables = Exact<{
  uniqueInput: ZoneUniqueInput;
}>;


export type ZoneDeleteMutation = { __typename?: 'Mutation', ZoneDelete: { __typename?: 'Zone', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ZoneFindUniqueQueryVariables = Exact<{
  uniqueInput: ZoneUniqueInput;
}>;


export type ZoneFindUniqueQuery = { __typename?: 'Query', ZoneFindUnique?: { __typename?: 'Zone', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } | null };

export type ZoneUpdateMutationVariables = Exact<{
  uniqueInput: ZoneUniqueInput;
  data: ZoneUpdateInput;
}>;


export type ZoneUpdateMutation = { __typename?: 'Mutation', ZoneUpdate: { __typename?: 'Zone', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null } };

export type ZonesFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type ZonesFindAllQuery = { __typename?: 'Query', ZonesFindAll: Array<{ __typename?: 'Zone', id: number, unique_id: string, name_th: string, name_en?: string | null, description?: string | null }> };

export type BranchUserGetAllQueryVariables = Exact<{
  where?: InputMaybe<BranchUserWhere>;
}>;


export type BranchUserGetAllQuery = { __typename?: 'Query', BranchUserGetAll: Array<{ __typename?: 'BranchUser', branch_id?: number | null, user_id?: number | null }> };

export type BranchUserGetByUserIdQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type BranchUserGetByUserIdQuery = { __typename?: 'Query', BranchUserGetByUserId: Array<{ __typename?: 'BranchUser', branch_id?: number | null, branch?: { __typename?: 'Branch', id: number, unique_id: string, name: string, is_hq: boolean, status: number } | null }> };

export type BranchUserUpsertMutationVariables = Exact<{
  addInput?: InputMaybe<Array<BranchUserAddInput> | BranchUserAddInput>;
  deleteInput?: InputMaybe<Array<BranchUserDeleteInput> | BranchUserDeleteInput>;
}>;


export type BranchUserUpsertMutation = { __typename?: 'Mutation', BranchUserUpsert: { __typename?: 'BranchUserUpsertOutput', count_add?: number | null, count_delete?: number | null } };

export type GeneralUniqueIdGenerateQueryVariables = Exact<{
  modelType: GeneralModelType;
}>;


export type GeneralUniqueIdGenerateQuery = { __typename?: 'Query', UniqueIdGenerate: string };

export type GenerateUserReportQueryVariables = Exact<{
  input?: InputMaybe<ExportGenerateInput>;
}>;


export type GenerateUserReportQuery = { __typename?: 'Query', GenerateReportNoQueue?: string | null };

export type ImportUserMutationVariables = Exact<{
  importInput: UserImportInput;
}>;


export type ImportUserMutation = { __typename?: 'Mutation', ImportUser: { __typename?: 'GeneralImportResult', success: boolean, timestamp?: any | null, status?: string | null, message?: string | null, jobId?: number | null, detail?: any | null } };

export type UserCreateMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type UserCreateMutation = { __typename?: 'Mutation', UserCreate?: { __typename?: 'User', id: number, unique_id: string, email: string, original_branch: number, img_url?: Array<string | null> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, employee_type?: string | null, is_sales_employee?: boolean | null, status: number, inactive_date?: any | null, remark_status?: string | null, start_date?: any | null, created_date?: any | null, last_updated_date?: any | null, last_login_branch?: number | null, original_branch_object?: { __typename?: 'Branch', unique_id: string, is_hq: boolean, name: string } | null } | null };

export type UserDeleteMutationVariables = Exact<{
  uniqueInput: UserUniqueInput;
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', UserDelete?: { __typename?: 'User', id: number, email: string } | null };

export type UserQueryVariables = Exact<{
  uniqueInput?: InputMaybe<UserUniqueInput>;
}>;


export type UserQuery = { __typename?: 'Query', UserFindUnique?: { __typename?: 'User', id: number, unique_id: string, email: string, original_branch: number, img_url?: Array<string | null> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, employee_type?: string | null, is_sales_employee?: boolean | null, status: number, inactive_date?: any | null, remark_status?: string | null, start_date?: any | null, created_date?: any | null, last_updated_date?: any | null, last_login_branch?: number | null, original_branch_object?: { __typename?: 'Branch', unique_id: string, is_hq: boolean, name: string } | null } | null };

export type UserUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
  uniqueInput: UserUniqueInput;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', UserUpdate?: { __typename?: 'User', id: number, unique_id: string, email: string, original_branch: number, img_url?: Array<string | null> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, employee_type?: string | null, is_sales_employee?: boolean | null, status: number, inactive_date?: any | null, remark_status?: string | null, start_date?: any | null, created_date?: any | null, last_updated_date?: any | null, last_login_branch?: number | null, original_branch_object?: { __typename?: 'Branch', unique_id: string, is_hq: boolean, name: string } | null } | null };

export type ValidateUserImportQueryVariables = Exact<{
  validateInput: ValidateUserImportInput;
}>;


export type ValidateUserImportQuery = { __typename?: 'Query', ValidateUserImport: { __typename?: 'ValidateUserImportResult', existing_user_unique_id_list?: Array<string> | null, missing_user_unique_id_list?: Array<string> | null, missing_branch_unique_id_list?: Array<string> | null, existing_email_list?: Array<string> | null, existing_phone_list?: Array<string> | null, missing_department_list?: Array<string> | null, missing_position_list?: Array<string> | null, missing_employee_type_list?: Array<string> | null } };


export const ActivityLogFindManyAggridDocument = `
    query ActivityLogFindManyAggrid($aggridInput: AnyAggridInput) {
  ActivityLogFindManyAggrid(aggridInput: $aggridInput) {
    count
    results {
      id
      activity_type
      activity_detail {
        message
        attachment_list
        prev_status
        curr_status
        added_related_employee {
          user_unique_id
          first_name
          last_name
        }
        deleted_related_employee {
          user_unique_id
          first_name
          last_name
        }
        updated_fields
      }
      reference_id
      document_type
      created_date
      created_by {
        user_id
        user_unique_id
        first_name
        last_name
        email
        img_url
      }
    }
  }
}
    `;
export const useActivityLogFindManyAggridQuery = <
      TData = ActivityLogFindManyAggridQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ActivityLogFindManyAggridQueryVariables,
      options?: UseQueryOptions<ActivityLogFindManyAggridQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ActivityLogFindManyAggridQuery, TError, TData>(
      variables === undefined ? ['ActivityLogFindManyAggrid'] : ['ActivityLogFindManyAggrid', variables],
      fetcher<ActivityLogFindManyAggridQuery, ActivityLogFindManyAggridQueryVariables>(client, ActivityLogFindManyAggridDocument, variables, headers),
      options
    );
export const ActivityLogCreateDocument = `
    mutation ActivityLogCreate($data: ActivityLogCreateInput!) {
  ActivityLogCreate(data: $data) {
    id
    activity_type
    activity_detail {
      message
      attachment_list
      prev_status
      curr_status
      added_related_employee {
        user_unique_id
        first_name
        last_name
      }
      deleted_related_employee {
        user_unique_id
        first_name
        last_name
      }
      updated_fields
      copied_from {
        id
        unique_id
      }
      copied_to {
        id
        unique_id
      }
    }
    reference_id
    document_type
    created_date
    created_by {
      user_id
      user_unique_id
      first_name
      last_name
      email
      img_url
    }
  }
}
    `;
export const useActivityLogCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ActivityLogCreateMutation, TError, ActivityLogCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ActivityLogCreateMutation, TError, ActivityLogCreateMutationVariables, TContext>(
      ['ActivityLogCreate'],
      (variables?: ActivityLogCreateMutationVariables) => fetcher<ActivityLogCreateMutation, ActivityLogCreateMutationVariables>(client, ActivityLogCreateDocument, variables, headers)(),
      options
    );
export const ActivityLogFindUniqueDocument = `
    query ActivityLogFindUnique($uniqueInput: ActivityLogUniqueInput) {
  ActivityLogFindUnique(uniqueInput: $uniqueInput) {
    id
    activity_type
    activity_detail {
      message
      attachment_list
      prev_status
      curr_status
      added_related_employee {
        user_unique_id
        first_name
        last_name
      }
      deleted_related_employee {
        user_unique_id
        first_name
        last_name
      }
      updated_fields
      copied_from {
        id
        unique_id
      }
      copied_to {
        id
        unique_id
      }
    }
    reference_id
    document_type
    created_date
    created_by {
      user_id
      user_unique_id
      first_name
      last_name
      email
      img_url
    }
  }
}
    `;
export const useActivityLogFindUniqueQuery = <
      TData = ActivityLogFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ActivityLogFindUniqueQueryVariables,
      options?: UseQueryOptions<ActivityLogFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ActivityLogFindUniqueQuery, TError, TData>(
      variables === undefined ? ['ActivityLogFindUnique'] : ['ActivityLogFindUnique', variables],
      fetcher<ActivityLogFindUniqueQuery, ActivityLogFindUniqueQueryVariables>(client, ActivityLogFindUniqueDocument, variables, headers),
      options
    );
export const ActivityLogFindManyByReferenceIdDocument = `
    query ActivityLogFindManyByReferenceId($findManyInput: ActivityLogByRefereceIdInput!) {
  ActivityLogFindManyByReferenceId(findManyInput: $findManyInput) {
    id
    activity_type
    activity_detail {
      message
      attachment_list
      prev_status
      curr_status
      added_related_employee {
        user_unique_id
        first_name
        last_name
      }
      deleted_related_employee {
        user_unique_id
        first_name
        last_name
      }
      updated_fields
      copied_from {
        id
        unique_id
      }
      copied_to {
        id
        unique_id
      }
    }
    reference_id
    document_type
    created_date
    created_by {
      user_id
      user_unique_id
      first_name
      last_name
      email
      img_url
    }
  }
}
    `;
export const useActivityLogFindManyByReferenceIdQuery = <
      TData = ActivityLogFindManyByReferenceIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ActivityLogFindManyByReferenceIdQueryVariables,
      options?: UseQueryOptions<ActivityLogFindManyByReferenceIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ActivityLogFindManyByReferenceIdQuery, TError, TData>(
      ['ActivityLogFindManyByReferenceId', variables],
      fetcher<ActivityLogFindManyByReferenceIdQuery, ActivityLogFindManyByReferenceIdQueryVariables>(client, ActivityLogFindManyByReferenceIdDocument, variables, headers),
      options
    );
export const LoginDocument = `
    mutation Login($loginInput: LoginInput) {
  login(loginInput: $loginInput) {
    access_token
    user {
      id
      unique_id
      email
      original_branch
      img_url
      title_name
      first_name
      last_name
      nick_name
      gender
      phone
      department
      position
      employee_type
      company_name
      is_sales_employee
      status
      inactive_date
      start_date
      created_date
      last_updated_date
      last_login_branch
    }
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['Login'],
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables, headers)(),
      options
    );
export const ValidateTokenDocument = `
    query ValidateToken {
  validateToken {
    id
    unique_id
    email
    original_branch
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    employee_type
    company_name
    is_sales_employee
    status
    inactive_date
    start_date
    created_date
    last_updated_date
    last_login_branch
  }
}
    `;
export const useValidateTokenQuery = <
      TData = ValidateTokenQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ValidateTokenQueryVariables,
      options?: UseQueryOptions<ValidateTokenQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ValidateTokenQuery, TError, TData>(
      variables === undefined ? ['ValidateToken'] : ['ValidateToken', variables],
      fetcher<ValidateTokenQuery, ValidateTokenQueryVariables>(client, ValidateTokenDocument, variables, headers),
      options
    );
export const BranchesFindManyAggridDocument = `
    query BranchesFindManyAggrid($aggridInput: AnyAggridInput) {
  BranchesFindManyAggrid(aggridInput: $aggridInput) {
    count
    results {
      id
      unique_id
      name
      short_name
      zone
      phone
      fax
      status
      is_hq
      created_date
      last_updated_date
      address
    }
  }
}
    `;
export const useBranchesFindManyAggridQuery = <
      TData = BranchesFindManyAggridQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BranchesFindManyAggridQueryVariables,
      options?: UseQueryOptions<BranchesFindManyAggridQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BranchesFindManyAggridQuery, TError, TData>(
      variables === undefined ? ['BranchesFindManyAggrid'] : ['BranchesFindManyAggrid', variables],
      fetcher<BranchesFindManyAggridQuery, BranchesFindManyAggridQueryVariables>(client, BranchesFindManyAggridDocument, variables, headers),
      options
    );
export const BranchCreateDocument = `
    mutation BranchCreate($data: BranchCreateInput!) {
  BranchCreate(data: $data) {
    id
    unique_id
    name
    short_name
    zone
    phone
    fax
    status
    is_hq
    created_date
    last_updated_date
    address
  }
}
    `;
export const useBranchCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BranchCreateMutation, TError, BranchCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BranchCreateMutation, TError, BranchCreateMutationVariables, TContext>(
      ['BranchCreate'],
      (variables?: BranchCreateMutationVariables) => fetcher<BranchCreateMutation, BranchCreateMutationVariables>(client, BranchCreateDocument, variables, headers)(),
      options
    );
export const BranchDeleteDocument = `
    mutation BranchDelete($uniqueInput: BranchUniqueInput!) {
  BranchDelete(uniqueInput: $uniqueInput) {
    name
    id
  }
}
    `;
export const useBranchDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BranchDeleteMutation, TError, BranchDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BranchDeleteMutation, TError, BranchDeleteMutationVariables, TContext>(
      ['BranchDelete'],
      (variables?: BranchDeleteMutationVariables) => fetcher<BranchDeleteMutation, BranchDeleteMutationVariables>(client, BranchDeleteDocument, variables, headers)(),
      options
    );
export const BranchFindUniqueDocument = `
    query BranchFindUnique($uniqueInput: BranchUniqueInput!) {
  BranchFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name
    short_name
    zone
    phone
    fax
    status
    is_hq
    created_date
    last_updated_date
    address
  }
}
    `;
export const useBranchFindUniqueQuery = <
      TData = BranchFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: BranchFindUniqueQueryVariables,
      options?: UseQueryOptions<BranchFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BranchFindUniqueQuery, TError, TData>(
      ['BranchFindUnique', variables],
      fetcher<BranchFindUniqueQuery, BranchFindUniqueQueryVariables>(client, BranchFindUniqueDocument, variables, headers),
      options
    );
export const BranchUpdateDocument = `
    mutation BranchUpdate($uniqueInput: BranchUniqueInput!, $data: BranchUpdateInput!) {
  BranchUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name
    short_name
    zone
    phone
    fax
    status
    is_hq
    created_date
    last_updated_date
    address
  }
}
    `;
export const useBranchUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BranchUpdateMutation, TError, BranchUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BranchUpdateMutation, TError, BranchUpdateMutationVariables, TContext>(
      ['BranchUpdate'],
      (variables?: BranchUpdateMutationVariables) => fetcher<BranchUpdateMutation, BranchUpdateMutationVariables>(client, BranchUpdateDocument, variables, headers)(),
      options
    );
export const ApprovalTemplateFindByDocumentTypeDocument = `
    query ApprovalTemplateFindByDocumentType($documentType: String!) {
  ApprovalTemplateFindByDocumentType(DocumentType: $documentType) {
    required_approval
    approver_id_list
    approver_list {
      unique_id
      id
      first_name
      last_name
      email
      img_url
      original_branch
      original_branch_object {
        name
        unique_id
      }
      department
      employee_type
      position
      status
    }
    step_number
    workflow_step_number
    workflow_document_type
  }
}
    `;
export const useApprovalTemplateFindByDocumentTypeQuery = <
      TData = ApprovalTemplateFindByDocumentTypeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ApprovalTemplateFindByDocumentTypeQueryVariables,
      options?: UseQueryOptions<ApprovalTemplateFindByDocumentTypeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ApprovalTemplateFindByDocumentTypeQuery, TError, TData>(
      ['ApprovalTemplateFindByDocumentType', variables],
      fetcher<ApprovalTemplateFindByDocumentTypeQuery, ApprovalTemplateFindByDocumentTypeQueryVariables>(client, ApprovalTemplateFindByDocumentTypeDocument, variables, headers),
      options
    );
export const ApprovalTemplateUpsertManyDocument = `
    mutation ApprovalTemplateUpsertMany($data: [ApprovalTemplateCreateInput!], $documentType: WorkflowDocumentType) {
  ApprovalTemplateUpsertMany(data: $data, documentType: $documentType) {
    id
    step_number
    required_approval
    workflow_step_number
    workflow_document_type
    approver_id_list
    approver_list {
      unique_id
      id
      first_name
      last_name
      email
      img_url
      original_branch
      original_branch_object {
        name
        unique_id
      }
      department
      employee_type
      position
      status
    }
  }
}
    `;
export const useApprovalTemplateUpsertManyMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ApprovalTemplateUpsertManyMutation, TError, ApprovalTemplateUpsertManyMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ApprovalTemplateUpsertManyMutation, TError, ApprovalTemplateUpsertManyMutationVariables, TContext>(
      ['ApprovalTemplateUpsertMany'],
      (variables?: ApprovalTemplateUpsertManyMutationVariables) => fetcher<ApprovalTemplateUpsertManyMutation, ApprovalTemplateUpsertManyMutationVariables>(client, ApprovalTemplateUpsertManyDocument, variables, headers)(),
      options
    );
export const CompanyFindUniqueDocument = `
    query CompanyFindUnique($uniqueInput: CompanyUniqueInput) {
  CompanyFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name
    type
    identity_no
    register_date
    img_url
    is_registered_vat
    vat_registration_date
    main_currency
    is_active
    contact_channel_list
    address_list
  }
}
    `;
export const useCompanyFindUniqueQuery = <
      TData = CompanyFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CompanyFindUniqueQueryVariables,
      options?: UseQueryOptions<CompanyFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CompanyFindUniqueQuery, TError, TData>(
      variables === undefined ? ['CompanyFindUnique'] : ['CompanyFindUnique', variables],
      fetcher<CompanyFindUniqueQuery, CompanyFindUniqueQueryVariables>(client, CompanyFindUniqueDocument, variables, headers),
      options
    );
export const CompanyUpdateDocument = `
    mutation CompanyUpdate($uniqueInput: CompanyUniqueInput!, $data: CompanyUpdateInput!) {
  CompanyUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name
    type
    identity_no
    register_date
    img_url
    is_registered_vat
    vat_registration_date
    main_currency
    is_active
    contact_channel_list
    address_list
  }
}
    `;
export const useCompanyUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CompanyUpdateMutation, TError, CompanyUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CompanyUpdateMutation, TError, CompanyUpdateMutationVariables, TContext>(
      ['CompanyUpdate'],
      (variables?: CompanyUpdateMutationVariables) => fetcher<CompanyUpdateMutation, CompanyUpdateMutationVariables>(client, CompanyUpdateDocument, variables, headers)(),
      options
    );
export const CustomerAttributeCreateDocument = `
    mutation CustomerAttributeCreate($data: CustomerAttributeCreateInput!) {
  CustomerAttributeCreate(data: $data) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useCustomerAttributeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerAttributeCreateMutation, TError, CustomerAttributeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerAttributeCreateMutation, TError, CustomerAttributeCreateMutationVariables, TContext>(
      ['CustomerAttributeCreate'],
      (variables?: CustomerAttributeCreateMutationVariables) => fetcher<CustomerAttributeCreateMutation, CustomerAttributeCreateMutationVariables>(client, CustomerAttributeCreateDocument, variables, headers)(),
      options
    );
export const CustomerAttributeDeleteDocument = `
    mutation CustomerAttributeDelete($uniqueInput: CustomerAttributeUniqueInput!) {
  CustomerAttributeDelete(uniqueInput: $uniqueInput) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useCustomerAttributeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerAttributeDeleteMutation, TError, CustomerAttributeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerAttributeDeleteMutation, TError, CustomerAttributeDeleteMutationVariables, TContext>(
      ['CustomerAttributeDelete'],
      (variables?: CustomerAttributeDeleteMutationVariables) => fetcher<CustomerAttributeDeleteMutation, CustomerAttributeDeleteMutationVariables>(client, CustomerAttributeDeleteDocument, variables, headers)(),
      options
    );
export const CustomerAttributesFindAllDocument = `
    query CustomerAttributesFindAll {
  CustomerAttributesFindAll {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useCustomerAttributesFindAllQuery = <
      TData = CustomerAttributesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CustomerAttributesFindAllQueryVariables,
      options?: UseQueryOptions<CustomerAttributesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerAttributesFindAllQuery, TError, TData>(
      variables === undefined ? ['CustomerAttributesFindAll'] : ['CustomerAttributesFindAll', variables],
      fetcher<CustomerAttributesFindAllQuery, CustomerAttributesFindAllQueryVariables>(client, CustomerAttributesFindAllDocument, variables, headers),
      options
    );
export const CustomerAttributeUpdateDocument = `
    mutation CustomerAttributeUpdate($uniqueInput: CustomerAttributeUniqueInput!, $data: CustomerAttributeUpdateInput!) {
  CustomerAttributeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useCustomerAttributeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerAttributeUpdateMutation, TError, CustomerAttributeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerAttributeUpdateMutation, TError, CustomerAttributeUpdateMutationVariables, TContext>(
      ['CustomerAttributeUpdate'],
      (variables?: CustomerAttributeUpdateMutationVariables) => fetcher<CustomerAttributeUpdateMutation, CustomerAttributeUpdateMutationVariables>(client, CustomerAttributeUpdateDocument, variables, headers)(),
      options
    );
export const CustomerAttributeFindUniqueDocument = `
    query CustomerAttributeFindUnique($uniqueInput: CustomerAttributeUniqueInput!) {
  CustomerAttributeFindUnique(uniqueInput: $uniqueInput) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useCustomerAttributeFindUniqueQuery = <
      TData = CustomerAttributeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CustomerAttributeFindUniqueQueryVariables,
      options?: UseQueryOptions<CustomerAttributeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerAttributeFindUniqueQuery, TError, TData>(
      ['CustomerAttributeFindUnique', variables],
      fetcher<CustomerAttributeFindUniqueQuery, CustomerAttributeFindUniqueQueryVariables>(client, CustomerAttributeFindUniqueDocument, variables, headers),
      options
    );
export const CustomerGroupCreateDocument = `
    mutation CustomerGroupCreate($data: CustomerGroupCreateInput!) {
  CustomerGroupCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerGroupCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerGroupCreateMutation, TError, CustomerGroupCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerGroupCreateMutation, TError, CustomerGroupCreateMutationVariables, TContext>(
      ['CustomerGroupCreate'],
      (variables?: CustomerGroupCreateMutationVariables) => fetcher<CustomerGroupCreateMutation, CustomerGroupCreateMutationVariables>(client, CustomerGroupCreateDocument, variables, headers)(),
      options
    );
export const CustomerGroupDeleteDocument = `
    mutation CustomerGroupDelete($uniqueInput: CustomerGroupUniqueInput!) {
  CustomerGroupDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerGroupDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerGroupDeleteMutation, TError, CustomerGroupDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerGroupDeleteMutation, TError, CustomerGroupDeleteMutationVariables, TContext>(
      ['CustomerGroupDelete'],
      (variables?: CustomerGroupDeleteMutationVariables) => fetcher<CustomerGroupDeleteMutation, CustomerGroupDeleteMutationVariables>(client, CustomerGroupDeleteDocument, variables, headers)(),
      options
    );
export const CustomerGroupFindUniqueDocument = `
    query CustomerGroupFindUnique($uniqueInput: CustomerGroupUniqueInput!) {
  CustomerGroupFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerGroupFindUniqueQuery = <
      TData = CustomerGroupFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CustomerGroupFindUniqueQueryVariables,
      options?: UseQueryOptions<CustomerGroupFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerGroupFindUniqueQuery, TError, TData>(
      ['CustomerGroupFindUnique', variables],
      fetcher<CustomerGroupFindUniqueQuery, CustomerGroupFindUniqueQueryVariables>(client, CustomerGroupFindUniqueDocument, variables, headers),
      options
    );
export const CustomerGroupUpdateDocument = `
    mutation CustomerGroupUpdate($uniqueInput: CustomerGroupUniqueInput!, $data: CustomerGroupUpdateInput!) {
  CustomerGroupUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerGroupUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerGroupUpdateMutation, TError, CustomerGroupUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerGroupUpdateMutation, TError, CustomerGroupUpdateMutationVariables, TContext>(
      ['CustomerGroupUpdate'],
      (variables?: CustomerGroupUpdateMutationVariables) => fetcher<CustomerGroupUpdateMutation, CustomerGroupUpdateMutationVariables>(client, CustomerGroupUpdateDocument, variables, headers)(),
      options
    );
export const CustomerGroupsFindAllDocument = `
    query CustomerGroupsFindAll {
  CustomerGroupsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerGroupsFindAllQuery = <
      TData = CustomerGroupsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CustomerGroupsFindAllQueryVariables,
      options?: UseQueryOptions<CustomerGroupsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerGroupsFindAllQuery, TError, TData>(
      variables === undefined ? ['CustomerGroupsFindAll'] : ['CustomerGroupsFindAll', variables],
      fetcher<CustomerGroupsFindAllQuery, CustomerGroupsFindAllQueryVariables>(client, CustomerGroupsFindAllDocument, variables, headers),
      options
    );
export const CustomerTypeCreateDocument = `
    mutation CustomerTypeCreate($data: CustomerTypeCreateInput!) {
  CustomerTypeCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerTypeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerTypeCreateMutation, TError, CustomerTypeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerTypeCreateMutation, TError, CustomerTypeCreateMutationVariables, TContext>(
      ['CustomerTypeCreate'],
      (variables?: CustomerTypeCreateMutationVariables) => fetcher<CustomerTypeCreateMutation, CustomerTypeCreateMutationVariables>(client, CustomerTypeCreateDocument, variables, headers)(),
      options
    );
export const CustomerTypeDeleteDocument = `
    mutation CustomerTypeDelete($uniqueInput: CustomerTypeUniqueInput!) {
  CustomerTypeDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerTypeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerTypeDeleteMutation, TError, CustomerTypeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerTypeDeleteMutation, TError, CustomerTypeDeleteMutationVariables, TContext>(
      ['CustomerTypeDelete'],
      (variables?: CustomerTypeDeleteMutationVariables) => fetcher<CustomerTypeDeleteMutation, CustomerTypeDeleteMutationVariables>(client, CustomerTypeDeleteDocument, variables, headers)(),
      options
    );
export const CustomerTypeFindUniqueDocument = `
    query CustomerTypeFindUnique($uniqueInput: CustomerTypeUniqueInput!) {
  CustomerTypeFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerTypeFindUniqueQuery = <
      TData = CustomerTypeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CustomerTypeFindUniqueQueryVariables,
      options?: UseQueryOptions<CustomerTypeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerTypeFindUniqueQuery, TError, TData>(
      ['CustomerTypeFindUnique', variables],
      fetcher<CustomerTypeFindUniqueQuery, CustomerTypeFindUniqueQueryVariables>(client, CustomerTypeFindUniqueDocument, variables, headers),
      options
    );
export const CustomerTypeUpdateDocument = `
    mutation CustomerTypeUpdate($uniqueInput: CustomerTypeUniqueInput!, $data: CustomerTypeUpdateInput!) {
  CustomerTypeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerTypeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CustomerTypeUpdateMutation, TError, CustomerTypeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CustomerTypeUpdateMutation, TError, CustomerTypeUpdateMutationVariables, TContext>(
      ['CustomerTypeUpdate'],
      (variables?: CustomerTypeUpdateMutationVariables) => fetcher<CustomerTypeUpdateMutation, CustomerTypeUpdateMutationVariables>(client, CustomerTypeUpdateDocument, variables, headers)(),
      options
    );
export const CustomerTypesFindAllDocument = `
    query CustomerTypesFindAll {
  CustomerTypesFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCustomerTypesFindAllQuery = <
      TData = CustomerTypesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CustomerTypesFindAllQueryVariables,
      options?: UseQueryOptions<CustomerTypesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CustomerTypesFindAllQuery, TError, TData>(
      variables === undefined ? ['CustomerTypesFindAll'] : ['CustomerTypesFindAll', variables],
      fetcher<CustomerTypesFindAllQuery, CustomerTypesFindAllQueryVariables>(client, CustomerTypesFindAllDocument, variables, headers),
      options
    );
export const VendorAttributeCreateDocument = `
    mutation VendorAttributeCreate($data: VendorAttributeCreateInput!) {
  VendorAttributeCreate(data: $data) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useVendorAttributeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorAttributeCreateMutation, TError, VendorAttributeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorAttributeCreateMutation, TError, VendorAttributeCreateMutationVariables, TContext>(
      ['VendorAttributeCreate'],
      (variables?: VendorAttributeCreateMutationVariables) => fetcher<VendorAttributeCreateMutation, VendorAttributeCreateMutationVariables>(client, VendorAttributeCreateDocument, variables, headers)(),
      options
    );
export const VendorAttributeDeleteDocument = `
    mutation VendorAttributeDelete($uniqueInput: VendorAttributeUniqueInput!) {
  VendorAttributeDelete(uniqueInput: $uniqueInput) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useVendorAttributeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorAttributeDeleteMutation, TError, VendorAttributeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorAttributeDeleteMutation, TError, VendorAttributeDeleteMutationVariables, TContext>(
      ['VendorAttributeDelete'],
      (variables?: VendorAttributeDeleteMutationVariables) => fetcher<VendorAttributeDeleteMutation, VendorAttributeDeleteMutationVariables>(client, VendorAttributeDeleteDocument, variables, headers)(),
      options
    );
export const VendorAttributesFindAllDocument = `
    query VendorAttributesFindAll {
  VendorAttributesFindAll {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useVendorAttributesFindAllQuery = <
      TData = VendorAttributesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: VendorAttributesFindAllQueryVariables,
      options?: UseQueryOptions<VendorAttributesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorAttributesFindAllQuery, TError, TData>(
      variables === undefined ? ['VendorAttributesFindAll'] : ['VendorAttributesFindAll', variables],
      fetcher<VendorAttributesFindAllQuery, VendorAttributesFindAllQueryVariables>(client, VendorAttributesFindAllDocument, variables, headers),
      options
    );
export const VendorAttributeUpdateDocument = `
    mutation VendorAttributeUpdate($uniqueInput: VendorAttributeUniqueInput!, $data: VendorAttributeUpdateInput!) {
  VendorAttributeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useVendorAttributeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorAttributeUpdateMutation, TError, VendorAttributeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorAttributeUpdateMutation, TError, VendorAttributeUpdateMutationVariables, TContext>(
      ['VendorAttributeUpdate'],
      (variables?: VendorAttributeUpdateMutationVariables) => fetcher<VendorAttributeUpdateMutation, VendorAttributeUpdateMutationVariables>(client, VendorAttributeUpdateDocument, variables, headers)(),
      options
    );
export const VendorAttributeFindUniqueDocument = `
    query VendorAttributeFindUnique($uniqueInput: VendorAttributeUniqueInput!) {
  VendorAttributeFindUnique(uniqueInput: $uniqueInput) {
    id
    name
    short_name
    type
    description
  }
}
    `;
export const useVendorAttributeFindUniqueQuery = <
      TData = VendorAttributeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: VendorAttributeFindUniqueQueryVariables,
      options?: UseQueryOptions<VendorAttributeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorAttributeFindUniqueQuery, TError, TData>(
      ['VendorAttributeFindUnique', variables],
      fetcher<VendorAttributeFindUniqueQuery, VendorAttributeFindUniqueQueryVariables>(client, VendorAttributeFindUniqueDocument, variables, headers),
      options
    );
export const VendorGroupCreateDocument = `
    mutation VendorGroupCreate($data: VendorGroupCreateInput!) {
  VendorGroupCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorGroupCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorGroupCreateMutation, TError, VendorGroupCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorGroupCreateMutation, TError, VendorGroupCreateMutationVariables, TContext>(
      ['VendorGroupCreate'],
      (variables?: VendorGroupCreateMutationVariables) => fetcher<VendorGroupCreateMutation, VendorGroupCreateMutationVariables>(client, VendorGroupCreateDocument, variables, headers)(),
      options
    );
export const VendorGroupDeleteDocument = `
    mutation VendorGroupDelete($uniqueInput: VendorGroupUniqueInput!) {
  VendorGroupDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorGroupDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorGroupDeleteMutation, TError, VendorGroupDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorGroupDeleteMutation, TError, VendorGroupDeleteMutationVariables, TContext>(
      ['VendorGroupDelete'],
      (variables?: VendorGroupDeleteMutationVariables) => fetcher<VendorGroupDeleteMutation, VendorGroupDeleteMutationVariables>(client, VendorGroupDeleteDocument, variables, headers)(),
      options
    );
export const VendorGroupFindUniqueDocument = `
    query VendorGroupFindUnique($uniqueInput: VendorGroupUniqueInput!) {
  VendorGroupFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorGroupFindUniqueQuery = <
      TData = VendorGroupFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: VendorGroupFindUniqueQueryVariables,
      options?: UseQueryOptions<VendorGroupFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorGroupFindUniqueQuery, TError, TData>(
      ['VendorGroupFindUnique', variables],
      fetcher<VendorGroupFindUniqueQuery, VendorGroupFindUniqueQueryVariables>(client, VendorGroupFindUniqueDocument, variables, headers),
      options
    );
export const VendorGroupUpdateDocument = `
    mutation VendorGroupUpdate($uniqueInput: VendorGroupUniqueInput!, $data: VendorGroupUpdateInput!) {
  VendorGroupUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorGroupUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorGroupUpdateMutation, TError, VendorGroupUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorGroupUpdateMutation, TError, VendorGroupUpdateMutationVariables, TContext>(
      ['VendorGroupUpdate'],
      (variables?: VendorGroupUpdateMutationVariables) => fetcher<VendorGroupUpdateMutation, VendorGroupUpdateMutationVariables>(client, VendorGroupUpdateDocument, variables, headers)(),
      options
    );
export const VendorGroupsFindAllDocument = `
    query VendorGroupsFindAll {
  VendorGroupsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorGroupsFindAllQuery = <
      TData = VendorGroupsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: VendorGroupsFindAllQueryVariables,
      options?: UseQueryOptions<VendorGroupsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorGroupsFindAllQuery, TError, TData>(
      variables === undefined ? ['VendorGroupsFindAll'] : ['VendorGroupsFindAll', variables],
      fetcher<VendorGroupsFindAllQuery, VendorGroupsFindAllQueryVariables>(client, VendorGroupsFindAllDocument, variables, headers),
      options
    );
export const VendorTypeCreateDocument = `
    mutation VendorTypeCreate($data: VendorTypeCreateInput!) {
  VendorTypeCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorTypeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorTypeCreateMutation, TError, VendorTypeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorTypeCreateMutation, TError, VendorTypeCreateMutationVariables, TContext>(
      ['VendorTypeCreate'],
      (variables?: VendorTypeCreateMutationVariables) => fetcher<VendorTypeCreateMutation, VendorTypeCreateMutationVariables>(client, VendorTypeCreateDocument, variables, headers)(),
      options
    );
export const VendorTypeDeleteDocument = `
    mutation VendorTypeDelete($uniqueInput: VendorTypeUniqueInput!) {
  VendorTypeDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorTypeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorTypeDeleteMutation, TError, VendorTypeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorTypeDeleteMutation, TError, VendorTypeDeleteMutationVariables, TContext>(
      ['VendorTypeDelete'],
      (variables?: VendorTypeDeleteMutationVariables) => fetcher<VendorTypeDeleteMutation, VendorTypeDeleteMutationVariables>(client, VendorTypeDeleteDocument, variables, headers)(),
      options
    );
export const VendorTypeFindUniqueDocument = `
    query VendorTypeFindUnique($uniqueInput: VendorTypeUniqueInput!) {
  VendorTypeFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorTypeFindUniqueQuery = <
      TData = VendorTypeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: VendorTypeFindUniqueQueryVariables,
      options?: UseQueryOptions<VendorTypeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorTypeFindUniqueQuery, TError, TData>(
      ['VendorTypeFindUnique', variables],
      fetcher<VendorTypeFindUniqueQuery, VendorTypeFindUniqueQueryVariables>(client, VendorTypeFindUniqueDocument, variables, headers),
      options
    );
export const VendorTypeUpdateDocument = `
    mutation VendorTypeUpdate($uniqueInput: VendorTypeUniqueInput!, $data: VendorTypeUpdateInput!) {
  VendorTypeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorTypeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VendorTypeUpdateMutation, TError, VendorTypeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VendorTypeUpdateMutation, TError, VendorTypeUpdateMutationVariables, TContext>(
      ['VendorTypeUpdate'],
      (variables?: VendorTypeUpdateMutationVariables) => fetcher<VendorTypeUpdateMutation, VendorTypeUpdateMutationVariables>(client, VendorTypeUpdateDocument, variables, headers)(),
      options
    );
export const VendorTypesFindAllDocument = `
    query VendorTypesFindAll {
  VendorTypesFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useVendorTypesFindAllQuery = <
      TData = VendorTypesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: VendorTypesFindAllQueryVariables,
      options?: UseQueryOptions<VendorTypesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<VendorTypesFindAllQuery, TError, TData>(
      variables === undefined ? ['VendorTypesFindAll'] : ['VendorTypesFindAll', variables],
      fetcher<VendorTypesFindAllQuery, VendorTypesFindAllQueryVariables>(client, VendorTypesFindAllDocument, variables, headers),
      options
    );
export const CreatableFieldCreateDocument = `
    mutation CreatableFieldCreate($data: CreatableFieldCreateInput!) {
  CreatableFieldCreate(data: $data) {
    id
    creatable_fields_name
    document_type
    name
  }
}
    `;
export const useCreatableFieldCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatableFieldCreateMutation, TError, CreatableFieldCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatableFieldCreateMutation, TError, CreatableFieldCreateMutationVariables, TContext>(
      ['CreatableFieldCreate'],
      (variables?: CreatableFieldCreateMutationVariables) => fetcher<CreatableFieldCreateMutation, CreatableFieldCreateMutationVariables>(client, CreatableFieldCreateDocument, variables, headers)(),
      options
    );
export const CreatableFieldDeleteDocument = `
    mutation CreatableFieldDelete($uniqueInput: CreatableFieldUniqueInput!) {
  CreatableFieldDelete(uniqueInput: $uniqueInput) {
    name
  }
}
    `;
export const useCreatableFieldDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatableFieldDeleteMutation, TError, CreatableFieldDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatableFieldDeleteMutation, TError, CreatableFieldDeleteMutationVariables, TContext>(
      ['CreatableFieldDelete'],
      (variables?: CreatableFieldDeleteMutationVariables) => fetcher<CreatableFieldDeleteMutation, CreatableFieldDeleteMutationVariables>(client, CreatableFieldDeleteDocument, variables, headers)(),
      options
    );
export const CreatableFieldsDocument = `
    query CreatableFields($findManyInput: CreatableFieldFindManyInput) {
  CreatableFields(findManyInput: $findManyInput) {
    creatable_fields_name
    document_type
    id
    name
  }
}
    `;
export const useCreatableFieldsQuery = <
      TData = CreatableFieldsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CreatableFieldsQueryVariables,
      options?: UseQueryOptions<CreatableFieldsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CreatableFieldsQuery, TError, TData>(
      variables === undefined ? ['CreatableFields'] : ['CreatableFields', variables],
      fetcher<CreatableFieldsQuery, CreatableFieldsQueryVariables>(client, CreatableFieldsDocument, variables, headers),
      options
    );
export const DepartmentCreateDocument = `
    mutation DepartmentCreate($data: DepartmentCreateInput!) {
  DepartmentCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDepartmentCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DepartmentCreateMutation, TError, DepartmentCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DepartmentCreateMutation, TError, DepartmentCreateMutationVariables, TContext>(
      ['DepartmentCreate'],
      (variables?: DepartmentCreateMutationVariables) => fetcher<DepartmentCreateMutation, DepartmentCreateMutationVariables>(client, DepartmentCreateDocument, variables, headers)(),
      options
    );
export const DepartmentDeleteDocument = `
    mutation DepartmentDelete($uniqueInput: DepartmentUniqueInput!) {
  DepartmentDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDepartmentDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DepartmentDeleteMutation, TError, DepartmentDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DepartmentDeleteMutation, TError, DepartmentDeleteMutationVariables, TContext>(
      ['DepartmentDelete'],
      (variables?: DepartmentDeleteMutationVariables) => fetcher<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>(client, DepartmentDeleteDocument, variables, headers)(),
      options
    );
export const DepartmentFindUniqueDocument = `
    query DepartmentFindUnique($uniqueInput: DepartmentUniqueInput!) {
  DepartmentFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDepartmentFindUniqueQuery = <
      TData = DepartmentFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: DepartmentFindUniqueQueryVariables,
      options?: UseQueryOptions<DepartmentFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DepartmentFindUniqueQuery, TError, TData>(
      ['DepartmentFindUnique', variables],
      fetcher<DepartmentFindUniqueQuery, DepartmentFindUniqueQueryVariables>(client, DepartmentFindUniqueDocument, variables, headers),
      options
    );
export const DepartmentUpdateDocument = `
    mutation DepartmentUpdate($uniqueInput: DepartmentUniqueInput!, $data: DepartmentUpdateInput!) {
  DepartmentUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDepartmentUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DepartmentUpdateMutation, TError, DepartmentUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DepartmentUpdateMutation, TError, DepartmentUpdateMutationVariables, TContext>(
      ['DepartmentUpdate'],
      (variables?: DepartmentUpdateMutationVariables) => fetcher<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>(client, DepartmentUpdateDocument, variables, headers)(),
      options
    );
export const DepartmentsFindAllDocument = `
    query DepartmentsFindAll {
  DepartmentsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDepartmentsFindAllQuery = <
      TData = DepartmentsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: DepartmentsFindAllQueryVariables,
      options?: UseQueryOptions<DepartmentsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DepartmentsFindAllQuery, TError, TData>(
      variables === undefined ? ['DepartmentsFindAll'] : ['DepartmentsFindAll', variables],
      fetcher<DepartmentsFindAllQuery, DepartmentsFindAllQueryVariables>(client, DepartmentsFindAllDocument, variables, headers),
      options
    );
export const PositionCreateDocument = `
    mutation PositionCreate($data: PositionCreateInput!) {
  PositionCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const usePositionCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PositionCreateMutation, TError, PositionCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PositionCreateMutation, TError, PositionCreateMutationVariables, TContext>(
      ['PositionCreate'],
      (variables?: PositionCreateMutationVariables) => fetcher<PositionCreateMutation, PositionCreateMutationVariables>(client, PositionCreateDocument, variables, headers)(),
      options
    );
export const PositionDeleteDocument = `
    mutation PositionDelete($uniqueInput: PositionUniqueInput!) {
  PositionDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const usePositionDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PositionDeleteMutation, TError, PositionDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PositionDeleteMutation, TError, PositionDeleteMutationVariables, TContext>(
      ['PositionDelete'],
      (variables?: PositionDeleteMutationVariables) => fetcher<PositionDeleteMutation, PositionDeleteMutationVariables>(client, PositionDeleteDocument, variables, headers)(),
      options
    );
export const PositionFindUniqueDocument = `
    query PositionFindUnique($uniqueInput: PositionUniqueInput!) {
  PositionFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const usePositionFindUniqueQuery = <
      TData = PositionFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: PositionFindUniqueQueryVariables,
      options?: UseQueryOptions<PositionFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PositionFindUniqueQuery, TError, TData>(
      ['PositionFindUnique', variables],
      fetcher<PositionFindUniqueQuery, PositionFindUniqueQueryVariables>(client, PositionFindUniqueDocument, variables, headers),
      options
    );
export const PositionUpdateDocument = `
    mutation PositionUpdate($uniqueInput: PositionUniqueInput!, $data: PositionUpdateInput!) {
  PositionUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const usePositionUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PositionUpdateMutation, TError, PositionUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PositionUpdateMutation, TError, PositionUpdateMutationVariables, TContext>(
      ['PositionUpdate'],
      (variables?: PositionUpdateMutationVariables) => fetcher<PositionUpdateMutation, PositionUpdateMutationVariables>(client, PositionUpdateDocument, variables, headers)(),
      options
    );
export const PositionsFindAllDocument = `
    query PositionsFindAll {
  PositionsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const usePositionsFindAllQuery = <
      TData = PositionsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: PositionsFindAllQueryVariables,
      options?: UseQueryOptions<PositionsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PositionsFindAllQuery, TError, TData>(
      variables === undefined ? ['PositionsFindAll'] : ['PositionsFindAll', variables],
      fetcher<PositionsFindAllQuery, PositionsFindAllQueryVariables>(client, PositionsFindAllDocument, variables, headers),
      options
    );
export const ItemAttributeCreateDocument = `
    mutation ItemAttributeCreate($data: ItemAttributeCreateInput!) {
  ItemAttributeCreate(data: $data) {
    id
    sorting_index
    set_item_attribute_id
    name
    short_name
    type
    description
  }
}
    `;
export const useItemAttributeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ItemAttributeCreateMutation, TError, ItemAttributeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ItemAttributeCreateMutation, TError, ItemAttributeCreateMutationVariables, TContext>(
      ['ItemAttributeCreate'],
      (variables?: ItemAttributeCreateMutationVariables) => fetcher<ItemAttributeCreateMutation, ItemAttributeCreateMutationVariables>(client, ItemAttributeCreateDocument, variables, headers)(),
      options
    );
export const ItemAttributeDeleteDocument = `
    mutation ItemAttributeDelete($uniqueInput: ItemAttributeUniqueInput!) {
  ItemAttributeDelete(uniqueInput: $uniqueInput) {
    id
    sorting_index
    set_item_attribute_id
    name
    short_name
    type
    description
  }
}
    `;
export const useItemAttributeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ItemAttributeDeleteMutation, TError, ItemAttributeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ItemAttributeDeleteMutation, TError, ItemAttributeDeleteMutationVariables, TContext>(
      ['ItemAttributeDelete'],
      (variables?: ItemAttributeDeleteMutationVariables) => fetcher<ItemAttributeDeleteMutation, ItemAttributeDeleteMutationVariables>(client, ItemAttributeDeleteDocument, variables, headers)(),
      options
    );
export const ItemAttributeFindUniqueDocument = `
    query ItemAttributeFindUnique($uniqueInput: ItemAttributeUniqueInput!) {
  ItemAttributeFindUnique(uniqueInput: $uniqueInput) {
    id
    sorting_index
    set_item_attribute_id
    name
    short_name
    type
    description
  }
}
    `;
export const useItemAttributeFindUniqueQuery = <
      TData = ItemAttributeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ItemAttributeFindUniqueQueryVariables,
      options?: UseQueryOptions<ItemAttributeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ItemAttributeFindUniqueQuery, TError, TData>(
      ['ItemAttributeFindUnique', variables],
      fetcher<ItemAttributeFindUniqueQuery, ItemAttributeFindUniqueQueryVariables>(client, ItemAttributeFindUniqueDocument, variables, headers),
      options
    );
export const ItemAttributeUpdateDocument = `
    mutation ItemAttributeUpdate($uniqueInput: ItemAttributeUniqueInput!, $data: ItemAttributeUpdateInput!) {
  ItemAttributeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    sorting_index
    set_item_attribute_id
    name
    short_name
    type
    description
  }
}
    `;
export const useItemAttributeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ItemAttributeUpdateMutation, TError, ItemAttributeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ItemAttributeUpdateMutation, TError, ItemAttributeUpdateMutationVariables, TContext>(
      ['ItemAttributeUpdate'],
      (variables?: ItemAttributeUpdateMutationVariables) => fetcher<ItemAttributeUpdateMutation, ItemAttributeUpdateMutationVariables>(client, ItemAttributeUpdateDocument, variables, headers)(),
      options
    );
export const ItemAttributesFindManyDocument = `
    query ItemAttributesFindMany($setAttributeId: Int) {
  ItemAttributesFindMany(setAttributeId: $setAttributeId) {
    id
    sorting_index
    set_item_attribute_id
    name
    short_name
    type
    description
  }
}
    `;
export const useItemAttributesFindManyQuery = <
      TData = ItemAttributesFindManyQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ItemAttributesFindManyQueryVariables,
      options?: UseQueryOptions<ItemAttributesFindManyQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ItemAttributesFindManyQuery, TError, TData>(
      variables === undefined ? ['ItemAttributesFindMany'] : ['ItemAttributesFindMany', variables],
      fetcher<ItemAttributesFindManyQuery, ItemAttributesFindManyQueryVariables>(client, ItemAttributesFindManyDocument, variables, headers),
      options
    );
export const SetItemAttributeCreateDocument = `
    mutation SetItemAttributeCreate($createInput: SetItemAttributeCreateInput!) {
  SetItemAttributeCreate(createInput: $createInput) {
    id
    name_th
    remark
    item_attribute_list {
      id
      sorting_index
      set_item_attribute_id
      name
      short_name
      type
      description
    }
  }
}
    `;
export const useSetItemAttributeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetItemAttributeCreateMutation, TError, SetItemAttributeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetItemAttributeCreateMutation, TError, SetItemAttributeCreateMutationVariables, TContext>(
      ['SetItemAttributeCreate'],
      (variables?: SetItemAttributeCreateMutationVariables) => fetcher<SetItemAttributeCreateMutation, SetItemAttributeCreateMutationVariables>(client, SetItemAttributeCreateDocument, variables, headers)(),
      options
    );
export const SetItemAttributeDeleteDocument = `
    mutation SetItemAttributeDelete($uniqueInput: SetItemAttributeUniqueInput!) {
  SetItemAttributeDelete(uniqueInput: $uniqueInput) {
    id
    name_th
    remark
    item_attribute_list {
      id
      sorting_index
      set_item_attribute_id
      name
      short_name
      type
      description
    }
  }
}
    `;
export const useSetItemAttributeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetItemAttributeDeleteMutation, TError, SetItemAttributeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetItemAttributeDeleteMutation, TError, SetItemAttributeDeleteMutationVariables, TContext>(
      ['SetItemAttributeDelete'],
      (variables?: SetItemAttributeDeleteMutationVariables) => fetcher<SetItemAttributeDeleteMutation, SetItemAttributeDeleteMutationVariables>(client, SetItemAttributeDeleteDocument, variables, headers)(),
      options
    );
export const SetItemAttributeDocument = `
    query SetItemAttribute($uniqueInput: SetItemAttributeUniqueInput!) {
  SetItemAttribute(uniqueInput: $uniqueInput) {
    id
    name_th
    remark
    item_attribute_list {
      id
      sorting_index
      set_item_attribute_id
      name
      short_name
      type
      description
    }
  }
}
    `;
export const useSetItemAttributeQuery = <
      TData = SetItemAttributeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SetItemAttributeQueryVariables,
      options?: UseQueryOptions<SetItemAttributeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SetItemAttributeQuery, TError, TData>(
      ['SetItemAttribute', variables],
      fetcher<SetItemAttributeQuery, SetItemAttributeQueryVariables>(client, SetItemAttributeDocument, variables, headers),
      options
    );
export const SetItemAttributeUpdateDocument = `
    mutation SetItemAttributeUpdate($uniqueInput: SetItemAttributeUniqueInput!, $updateInput: SetItemAttributeUpdateInput!) {
  SetItemAttributeUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
    id
    name_th
    remark
    item_attribute_list {
      id
      sorting_index
      set_item_attribute_id
      name
      short_name
      type
      description
    }
  }
}
    `;
export const useSetItemAttributeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetItemAttributeUpdateMutation, TError, SetItemAttributeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetItemAttributeUpdateMutation, TError, SetItemAttributeUpdateMutationVariables, TContext>(
      ['SetItemAttributeUpdate'],
      (variables?: SetItemAttributeUpdateMutationVariables) => fetcher<SetItemAttributeUpdateMutation, SetItemAttributeUpdateMutationVariables>(client, SetItemAttributeUpdateDocument, variables, headers)(),
      options
    );
export const SetItemAttributesDocument = `
    query SetItemAttributes {
  SetItemAttributes {
    id
    name_th
    remark
    item_attribute_list {
      id
      sorting_index
      set_item_attribute_id
      name
      short_name
      type
      description
    }
  }
}
    `;
export const useSetItemAttributesQuery = <
      TData = SetItemAttributesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SetItemAttributesQueryVariables,
      options?: UseQueryOptions<SetItemAttributesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SetItemAttributesQuery, TError, TData>(
      variables === undefined ? ['SetItemAttributes'] : ['SetItemAttributes', variables],
      fetcher<SetItemAttributesQuery, SetItemAttributesQueryVariables>(client, SetItemAttributesDocument, variables, headers),
      options
    );
export const BrandCreateDocument = `
    mutation BrandCreate($data: BrandCreateInput!) {
  BrandCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useBrandCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BrandCreateMutation, TError, BrandCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BrandCreateMutation, TError, BrandCreateMutationVariables, TContext>(
      ['BrandCreate'],
      (variables?: BrandCreateMutationVariables) => fetcher<BrandCreateMutation, BrandCreateMutationVariables>(client, BrandCreateDocument, variables, headers)(),
      options
    );
export const BrandDeleteDocument = `
    mutation BrandDelete($uniqueInput: BrandUniqueInput!) {
  BrandDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useBrandDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BrandDeleteMutation, TError, BrandDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BrandDeleteMutation, TError, BrandDeleteMutationVariables, TContext>(
      ['BrandDelete'],
      (variables?: BrandDeleteMutationVariables) => fetcher<BrandDeleteMutation, BrandDeleteMutationVariables>(client, BrandDeleteDocument, variables, headers)(),
      options
    );
export const BrandFindUniqueDocument = `
    query BrandFindUnique($uniqueInput: BrandUniqueInput!) {
  BrandFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useBrandFindUniqueQuery = <
      TData = BrandFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: BrandFindUniqueQueryVariables,
      options?: UseQueryOptions<BrandFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BrandFindUniqueQuery, TError, TData>(
      ['BrandFindUnique', variables],
      fetcher<BrandFindUniqueQuery, BrandFindUniqueQueryVariables>(client, BrandFindUniqueDocument, variables, headers),
      options
    );
export const BrandUpdateDocument = `
    mutation BrandUpdate($uniqueInput: BrandUniqueInput!, $data: BrandUpdateInput!) {
  BrandUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useBrandUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BrandUpdateMutation, TError, BrandUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BrandUpdateMutation, TError, BrandUpdateMutationVariables, TContext>(
      ['BrandUpdate'],
      (variables?: BrandUpdateMutationVariables) => fetcher<BrandUpdateMutation, BrandUpdateMutationVariables>(client, BrandUpdateDocument, variables, headers)(),
      options
    );
export const BrandsFindAllDocument = `
    query BrandsFindAll {
  BrandsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useBrandsFindAllQuery = <
      TData = BrandsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BrandsFindAllQueryVariables,
      options?: UseQueryOptions<BrandsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BrandsFindAllQuery, TError, TData>(
      variables === undefined ? ['BrandsFindAll'] : ['BrandsFindAll', variables],
      fetcher<BrandsFindAllQuery, BrandsFindAllQueryVariables>(client, BrandsFindAllDocument, variables, headers),
      options
    );
export const MainCategorysFindAllDocument = `
    query MainCategorysFindAll {
  MainCategorysFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useMainCategorysFindAllQuery = <
      TData = MainCategorysFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: MainCategorysFindAllQueryVariables,
      options?: UseQueryOptions<MainCategorysFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<MainCategorysFindAllQuery, TError, TData>(
      variables === undefined ? ['MainCategorysFindAll'] : ['MainCategorysFindAll', variables],
      fetcher<MainCategorysFindAllQuery, MainCategorysFindAllQueryVariables>(client, MainCategorysFindAllDocument, variables, headers),
      options
    );
export const MainCategoryCreateDocument = `
    mutation MainCategoryCreate($data: MainCategoryCreateInput!) {
  MainCategoryCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useMainCategoryCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MainCategoryCreateMutation, TError, MainCategoryCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<MainCategoryCreateMutation, TError, MainCategoryCreateMutationVariables, TContext>(
      ['MainCategoryCreate'],
      (variables?: MainCategoryCreateMutationVariables) => fetcher<MainCategoryCreateMutation, MainCategoryCreateMutationVariables>(client, MainCategoryCreateDocument, variables, headers)(),
      options
    );
export const MainCategoryDeleteDocument = `
    mutation MainCategoryDelete($uniqueInput: MainCategoryUniqueInput!) {
  MainCategoryDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useMainCategoryDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MainCategoryDeleteMutation, TError, MainCategoryDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<MainCategoryDeleteMutation, TError, MainCategoryDeleteMutationVariables, TContext>(
      ['MainCategoryDelete'],
      (variables?: MainCategoryDeleteMutationVariables) => fetcher<MainCategoryDeleteMutation, MainCategoryDeleteMutationVariables>(client, MainCategoryDeleteDocument, variables, headers)(),
      options
    );
export const MainCategoryFindUniqueDocument = `
    query MainCategoryFindUnique($uniqueInput: MainCategoryUniqueInput!) {
  MainCategoryFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useMainCategoryFindUniqueQuery = <
      TData = MainCategoryFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: MainCategoryFindUniqueQueryVariables,
      options?: UseQueryOptions<MainCategoryFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<MainCategoryFindUniqueQuery, TError, TData>(
      ['MainCategoryFindUnique', variables],
      fetcher<MainCategoryFindUniqueQuery, MainCategoryFindUniqueQueryVariables>(client, MainCategoryFindUniqueDocument, variables, headers),
      options
    );
export const MainCategoryUpdateDocument = `
    mutation MainCategoryUpdate($uniqueInput: MainCategoryUniqueInput!, $data: MainCategoryUpdateInput!) {
  MainCategoryUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useMainCategoryUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MainCategoryUpdateMutation, TError, MainCategoryUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<MainCategoryUpdateMutation, TError, MainCategoryUpdateMutationVariables, TContext>(
      ['MainCategoryUpdate'],
      (variables?: MainCategoryUpdateMutationVariables) => fetcher<MainCategoryUpdateMutation, MainCategoryUpdateMutationVariables>(client, MainCategoryUpdateDocument, variables, headers)(),
      options
    );
export const CategoryGroupsFindAllDocument = `
    query CategoryGroupsFindAll {
  CategoryGroupsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCategoryGroupsFindAllQuery = <
      TData = CategoryGroupsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CategoryGroupsFindAllQueryVariables,
      options?: UseQueryOptions<CategoryGroupsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CategoryGroupsFindAllQuery, TError, TData>(
      variables === undefined ? ['CategoryGroupsFindAll'] : ['CategoryGroupsFindAll', variables],
      fetcher<CategoryGroupsFindAllQuery, CategoryGroupsFindAllQueryVariables>(client, CategoryGroupsFindAllDocument, variables, headers),
      options
    );
export const CategoryGroupCreateDocument = `
    mutation CategoryGroupCreate($data: CategoryGroupCreateInput!) {
  CategoryGroupCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCategoryGroupCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CategoryGroupCreateMutation, TError, CategoryGroupCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CategoryGroupCreateMutation, TError, CategoryGroupCreateMutationVariables, TContext>(
      ['CategoryGroupCreate'],
      (variables?: CategoryGroupCreateMutationVariables) => fetcher<CategoryGroupCreateMutation, CategoryGroupCreateMutationVariables>(client, CategoryGroupCreateDocument, variables, headers)(),
      options
    );
export const CategoryGroupDeleteDocument = `
    mutation CategoryGroupDelete($uniqueInput: CategoryGroupUniqueInput!) {
  CategoryGroupDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCategoryGroupDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CategoryGroupDeleteMutation, TError, CategoryGroupDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CategoryGroupDeleteMutation, TError, CategoryGroupDeleteMutationVariables, TContext>(
      ['CategoryGroupDelete'],
      (variables?: CategoryGroupDeleteMutationVariables) => fetcher<CategoryGroupDeleteMutation, CategoryGroupDeleteMutationVariables>(client, CategoryGroupDeleteDocument, variables, headers)(),
      options
    );
export const CategoryGroupFindUniqueDocument = `
    query CategoryGroupFindUnique($uniqueInput: CategoryGroupUniqueInput!) {
  CategoryGroupFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCategoryGroupFindUniqueQuery = <
      TData = CategoryGroupFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CategoryGroupFindUniqueQueryVariables,
      options?: UseQueryOptions<CategoryGroupFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CategoryGroupFindUniqueQuery, TError, TData>(
      ['CategoryGroupFindUnique', variables],
      fetcher<CategoryGroupFindUniqueQuery, CategoryGroupFindUniqueQueryVariables>(client, CategoryGroupFindUniqueDocument, variables, headers),
      options
    );
export const CategoryGroupUpdateDocument = `
    mutation CategoryGroupUpdate($uniqueInput: CategoryGroupUniqueInput!, $data: CategoryGroupUpdateInput!) {
  CategoryGroupUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useCategoryGroupUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CategoryGroupUpdateMutation, TError, CategoryGroupUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CategoryGroupUpdateMutation, TError, CategoryGroupUpdateMutationVariables, TContext>(
      ['CategoryGroupUpdate'],
      (variables?: CategoryGroupUpdateMutationVariables) => fetcher<CategoryGroupUpdateMutation, CategoryGroupUpdateMutationVariables>(client, CategoryGroupUpdateDocument, variables, headers)(),
      options
    );
export const ModelCreateDocument = `
    mutation ModelCreate($data: ModelCreateInput!) {
  ModelCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useModelCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ModelCreateMutation, TError, ModelCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ModelCreateMutation, TError, ModelCreateMutationVariables, TContext>(
      ['ModelCreate'],
      (variables?: ModelCreateMutationVariables) => fetcher<ModelCreateMutation, ModelCreateMutationVariables>(client, ModelCreateDocument, variables, headers)(),
      options
    );
export const ModelDeleteDocument = `
    mutation ModelDelete($uniqueInput: ModelUniqueInput!) {
  ModelDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useModelDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ModelDeleteMutation, TError, ModelDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ModelDeleteMutation, TError, ModelDeleteMutationVariables, TContext>(
      ['ModelDelete'],
      (variables?: ModelDeleteMutationVariables) => fetcher<ModelDeleteMutation, ModelDeleteMutationVariables>(client, ModelDeleteDocument, variables, headers)(),
      options
    );
export const ModelFindUniqueDocument = `
    query ModelFindUnique($uniqueInput: ModelUniqueInput!) {
  ModelFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useModelFindUniqueQuery = <
      TData = ModelFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ModelFindUniqueQueryVariables,
      options?: UseQueryOptions<ModelFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ModelFindUniqueQuery, TError, TData>(
      ['ModelFindUnique', variables],
      fetcher<ModelFindUniqueQuery, ModelFindUniqueQueryVariables>(client, ModelFindUniqueDocument, variables, headers),
      options
    );
export const ModelUpdateDocument = `
    mutation ModelUpdate($uniqueInput: ModelUniqueInput!, $data: ModelUpdateInput!) {
  ModelUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useModelUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ModelUpdateMutation, TError, ModelUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ModelUpdateMutation, TError, ModelUpdateMutationVariables, TContext>(
      ['ModelUpdate'],
      (variables?: ModelUpdateMutationVariables) => fetcher<ModelUpdateMutation, ModelUpdateMutationVariables>(client, ModelUpdateDocument, variables, headers)(),
      options
    );
export const ModelsFindAllDocument = `
    query ModelsFindAll {
  ModelsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useModelsFindAllQuery = <
      TData = ModelsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ModelsFindAllQueryVariables,
      options?: UseQueryOptions<ModelsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ModelsFindAllQuery, TError, TData>(
      variables === undefined ? ['ModelsFindAll'] : ['ModelsFindAll', variables],
      fetcher<ModelsFindAllQuery, ModelsFindAllQueryVariables>(client, ModelsFindAllDocument, variables, headers),
      options
    );
export const SegmentCreateDocument = `
    mutation SegmentCreate($data: SegmentCreateInput!) {
  SegmentCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSegmentCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SegmentCreateMutation, TError, SegmentCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SegmentCreateMutation, TError, SegmentCreateMutationVariables, TContext>(
      ['SegmentCreate'],
      (variables?: SegmentCreateMutationVariables) => fetcher<SegmentCreateMutation, SegmentCreateMutationVariables>(client, SegmentCreateDocument, variables, headers)(),
      options
    );
export const SegmentDeleteDocument = `
    mutation SegmentDelete($uniqueInput: SegmentUniqueInput!) {
  SegmentDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSegmentDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SegmentDeleteMutation, TError, SegmentDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SegmentDeleteMutation, TError, SegmentDeleteMutationVariables, TContext>(
      ['SegmentDelete'],
      (variables?: SegmentDeleteMutationVariables) => fetcher<SegmentDeleteMutation, SegmentDeleteMutationVariables>(client, SegmentDeleteDocument, variables, headers)(),
      options
    );
export const SegmentFindUniqueDocument = `
    query SegmentFindUnique($uniqueInput: SegmentUniqueInput!) {
  SegmentFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSegmentFindUniqueQuery = <
      TData = SegmentFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SegmentFindUniqueQueryVariables,
      options?: UseQueryOptions<SegmentFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SegmentFindUniqueQuery, TError, TData>(
      ['SegmentFindUnique', variables],
      fetcher<SegmentFindUniqueQuery, SegmentFindUniqueQueryVariables>(client, SegmentFindUniqueDocument, variables, headers),
      options
    );
export const SegmentUpdateDocument = `
    mutation SegmentUpdate($uniqueInput: SegmentUniqueInput!, $data: SegmentUpdateInput!) {
  SegmentUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSegmentUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SegmentUpdateMutation, TError, SegmentUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SegmentUpdateMutation, TError, SegmentUpdateMutationVariables, TContext>(
      ['SegmentUpdate'],
      (variables?: SegmentUpdateMutationVariables) => fetcher<SegmentUpdateMutation, SegmentUpdateMutationVariables>(client, SegmentUpdateDocument, variables, headers)(),
      options
    );
export const SegmentsFindAllDocument = `
    query SegmentsFindAll {
  SegmentsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSegmentsFindAllQuery = <
      TData = SegmentsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SegmentsFindAllQueryVariables,
      options?: UseQueryOptions<SegmentsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SegmentsFindAllQuery, TError, TData>(
      variables === undefined ? ['SegmentsFindAll'] : ['SegmentsFindAll', variables],
      fetcher<SegmentsFindAllQuery, SegmentsFindAllQueryVariables>(client, SegmentsFindAllDocument, variables, headers),
      options
    );
export const SeriesCreateDocument = `
    mutation SeriesCreate($data: SeriesCreateInput!) {
  SeriesCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSeriesCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SeriesCreateMutation, TError, SeriesCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SeriesCreateMutation, TError, SeriesCreateMutationVariables, TContext>(
      ['SeriesCreate'],
      (variables?: SeriesCreateMutationVariables) => fetcher<SeriesCreateMutation, SeriesCreateMutationVariables>(client, SeriesCreateDocument, variables, headers)(),
      options
    );
export const SeriesDeleteDocument = `
    mutation SeriesDelete($uniqueInput: SeriesUniqueInput!) {
  SeriesDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSeriesDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SeriesDeleteMutation, TError, SeriesDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SeriesDeleteMutation, TError, SeriesDeleteMutationVariables, TContext>(
      ['SeriesDelete'],
      (variables?: SeriesDeleteMutationVariables) => fetcher<SeriesDeleteMutation, SeriesDeleteMutationVariables>(client, SeriesDeleteDocument, variables, headers)(),
      options
    );
export const SeriesFindUniqueDocument = `
    query SeriesFindUnique($uniqueInput: SeriesUniqueInput!) {
  SeriesFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSeriesFindUniqueQuery = <
      TData = SeriesFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SeriesFindUniqueQueryVariables,
      options?: UseQueryOptions<SeriesFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SeriesFindUniqueQuery, TError, TData>(
      ['SeriesFindUnique', variables],
      fetcher<SeriesFindUniqueQuery, SeriesFindUniqueQueryVariables>(client, SeriesFindUniqueDocument, variables, headers),
      options
    );
export const SeriesUpdateDocument = `
    mutation SeriesUpdate($uniqueInput: SeriesUniqueInput!, $data: SeriesUpdateInput!) {
  SeriesUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSeriesUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SeriesUpdateMutation, TError, SeriesUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SeriesUpdateMutation, TError, SeriesUpdateMutationVariables, TContext>(
      ['SeriesUpdate'],
      (variables?: SeriesUpdateMutationVariables) => fetcher<SeriesUpdateMutation, SeriesUpdateMutationVariables>(client, SeriesUpdateDocument, variables, headers)(),
      options
    );
export const SeriessFindAllDocument = `
    query SeriessFindAll {
  SeriessFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSeriessFindAllQuery = <
      TData = SeriessFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SeriessFindAllQueryVariables,
      options?: UseQueryOptions<SeriessFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SeriessFindAllQuery, TError, TData>(
      variables === undefined ? ['SeriessFindAll'] : ['SeriessFindAll', variables],
      fetcher<SeriessFindAllQuery, SeriessFindAllQueryVariables>(client, SeriessFindAllDocument, variables, headers),
      options
    );
export const SubCategorysFindAllDocument = `
    query SubCategorysFindAll {
  SubCategorysFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSubCategorysFindAllQuery = <
      TData = SubCategorysFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SubCategorysFindAllQueryVariables,
      options?: UseQueryOptions<SubCategorysFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SubCategorysFindAllQuery, TError, TData>(
      variables === undefined ? ['SubCategorysFindAll'] : ['SubCategorysFindAll', variables],
      fetcher<SubCategorysFindAllQuery, SubCategorysFindAllQueryVariables>(client, SubCategorysFindAllDocument, variables, headers),
      options
    );
export const SubCategoryCreateDocument = `
    mutation SubCategoryCreate($data: SubCategoryCreateInput!) {
  SubCategoryCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSubCategoryCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SubCategoryCreateMutation, TError, SubCategoryCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SubCategoryCreateMutation, TError, SubCategoryCreateMutationVariables, TContext>(
      ['SubCategoryCreate'],
      (variables?: SubCategoryCreateMutationVariables) => fetcher<SubCategoryCreateMutation, SubCategoryCreateMutationVariables>(client, SubCategoryCreateDocument, variables, headers)(),
      options
    );
export const SubCategoryDeleteDocument = `
    mutation SubCategoryDelete($uniqueInput: SubCategoryUniqueInput!) {
  SubCategoryDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSubCategoryDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SubCategoryDeleteMutation, TError, SubCategoryDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SubCategoryDeleteMutation, TError, SubCategoryDeleteMutationVariables, TContext>(
      ['SubCategoryDelete'],
      (variables?: SubCategoryDeleteMutationVariables) => fetcher<SubCategoryDeleteMutation, SubCategoryDeleteMutationVariables>(client, SubCategoryDeleteDocument, variables, headers)(),
      options
    );
export const SubCategoryFindUniqueDocument = `
    query SubCategoryFindUnique($uniqueInput: SubCategoryUniqueInput!) {
  SubCategoryFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSubCategoryFindUniqueQuery = <
      TData = SubCategoryFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SubCategoryFindUniqueQueryVariables,
      options?: UseQueryOptions<SubCategoryFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SubCategoryFindUniqueQuery, TError, TData>(
      ['SubCategoryFindUnique', variables],
      fetcher<SubCategoryFindUniqueQuery, SubCategoryFindUniqueQueryVariables>(client, SubCategoryFindUniqueDocument, variables, headers),
      options
    );
export const SubCategoryUpdateDocument = `
    mutation SubCategoryUpdate($uniqueInput: SubCategoryUniqueInput!, $data: SubCategoryUpdateInput!) {
  SubCategoryUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSubCategoryUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SubCategoryUpdateMutation, TError, SubCategoryUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SubCategoryUpdateMutation, TError, SubCategoryUpdateMutationVariables, TContext>(
      ['SubCategoryUpdate'],
      (variables?: SubCategoryUpdateMutationVariables) => fetcher<SubCategoryUpdateMutation, SubCategoryUpdateMutationVariables>(client, SubCategoryUpdateDocument, variables, headers)(),
      options
    );
export const UnitCreateDocument = `
    mutation UnitCreate($data: UnitCreateInput!) {
  UnitCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useUnitCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnitCreateMutation, TError, UnitCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UnitCreateMutation, TError, UnitCreateMutationVariables, TContext>(
      ['UnitCreate'],
      (variables?: UnitCreateMutationVariables) => fetcher<UnitCreateMutation, UnitCreateMutationVariables>(client, UnitCreateDocument, variables, headers)(),
      options
    );
export const UnitDeleteDocument = `
    mutation UnitDelete($uniqueInput: UnitUniqueInput!) {
  UnitDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useUnitDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnitDeleteMutation, TError, UnitDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UnitDeleteMutation, TError, UnitDeleteMutationVariables, TContext>(
      ['UnitDelete'],
      (variables?: UnitDeleteMutationVariables) => fetcher<UnitDeleteMutation, UnitDeleteMutationVariables>(client, UnitDeleteDocument, variables, headers)(),
      options
    );
export const UnitFindUniqueDocument = `
    query UnitFindUnique($uniqueInput: UnitUniqueInput!) {
  UnitFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useUnitFindUniqueQuery = <
      TData = UnitFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UnitFindUniqueQueryVariables,
      options?: UseQueryOptions<UnitFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UnitFindUniqueQuery, TError, TData>(
      ['UnitFindUnique', variables],
      fetcher<UnitFindUniqueQuery, UnitFindUniqueQueryVariables>(client, UnitFindUniqueDocument, variables, headers),
      options
    );
export const UnitUpdateDocument = `
    mutation UnitUpdate($uniqueInput: UnitUniqueInput!, $data: UnitUpdateInput!) {
  UnitUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useUnitUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnitUpdateMutation, TError, UnitUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UnitUpdateMutation, TError, UnitUpdateMutationVariables, TContext>(
      ['UnitUpdate'],
      (variables?: UnitUpdateMutationVariables) => fetcher<UnitUpdateMutation, UnitUpdateMutationVariables>(client, UnitUpdateDocument, variables, headers)(),
      options
    );
export const UnitsFindAllDocument = `
    query UnitsFindAll {
  UnitsFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useUnitsFindAllQuery = <
      TData = UnitsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: UnitsFindAllQueryVariables,
      options?: UseQueryOptions<UnitsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UnitsFindAllQuery, TError, TData>(
      variables === undefined ? ['UnitsFindAll'] : ['UnitsFindAll', variables],
      fetcher<UnitsFindAllQuery, UnitsFindAllQueryVariables>(client, UnitsFindAllDocument, variables, headers),
      options
    );
export const BinLocationFindUniqueDocument = `
    query BinLocationFindUnique($uniqueInput: BinLocationUniqueInput!) {
  BinLocationFindUnique(uniqueInput: $uniqueInput) {
    name
    id
  }
}
    `;
export const useBinLocationFindUniqueQuery = <
      TData = BinLocationFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: BinLocationFindUniqueQueryVariables,
      options?: UseQueryOptions<BinLocationFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BinLocationFindUniqueQuery, TError, TData>(
      ['BinLocationFindUnique', variables],
      fetcher<BinLocationFindUniqueQuery, BinLocationFindUniqueQueryVariables>(client, BinLocationFindUniqueDocument, variables, headers),
      options
    );
export const BinLocationCreateDocument = `
    mutation BinLocationCreate($data: BinLocationCreateInput!) {
  BinLocationCreate(data: $data) {
    id
    name
  }
}
    `;
export const useBinLocationCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BinLocationCreateMutation, TError, BinLocationCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BinLocationCreateMutation, TError, BinLocationCreateMutationVariables, TContext>(
      ['BinLocationCreate'],
      (variables?: BinLocationCreateMutationVariables) => fetcher<BinLocationCreateMutation, BinLocationCreateMutationVariables>(client, BinLocationCreateDocument, variables, headers)(),
      options
    );
export const BinLocationDeleteDocument = `
    mutation BinLocationDelete($uniqueInput: BinLocationUniqueInput!) {
  BinLocationDelete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useBinLocationDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BinLocationDeleteMutation, TError, BinLocationDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BinLocationDeleteMutation, TError, BinLocationDeleteMutationVariables, TContext>(
      ['BinLocationDelete'],
      (variables?: BinLocationDeleteMutationVariables) => fetcher<BinLocationDeleteMutation, BinLocationDeleteMutationVariables>(client, BinLocationDeleteDocument, variables, headers)(),
      options
    );
export const BinLocationsFindAllDocument = `
    query BinLocationsFindAll {
  BinLocationsFindAll {
    id
    name
    warehouse_level_3 {
      id
      name
      warehouse_level_2 {
        id
        name
        warehouse_level_1 {
          id
          name
          warehouse {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const useBinLocationsFindAllQuery = <
      TData = BinLocationsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BinLocationsFindAllQueryVariables,
      options?: UseQueryOptions<BinLocationsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BinLocationsFindAllQuery, TError, TData>(
      variables === undefined ? ['BinLocationsFindAll'] : ['BinLocationsFindAll', variables],
      fetcher<BinLocationsFindAllQuery, BinLocationsFindAllQueryVariables>(client, BinLocationsFindAllDocument, variables, headers),
      options
    );
export const BinLocationUpdateDocument = `
    mutation BinLocationUpdate($uniqueInput: BinLocationUniqueInput!, $data: BinLocationUpdateInput!) {
  BinLocationUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    name
  }
}
    `;
export const useBinLocationUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BinLocationUpdateMutation, TError, BinLocationUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BinLocationUpdateMutation, TError, BinLocationUpdateMutationVariables, TContext>(
      ['BinLocationUpdate'],
      (variables?: BinLocationUpdateMutationVariables) => fetcher<BinLocationUpdateMutation, BinLocationUpdateMutationVariables>(client, BinLocationUpdateDocument, variables, headers)(),
      options
    );
export const WarehouseFindUniqueDocument = `
    query WarehouseFindUnique($uniqueInput: WarehouseUniqueInput!) {
  WarehouseFindUnique(uniqueInput: $uniqueInput) {
    id
    branch_id
    unique_id
    name
    user_id
    is_consignment
    description
    address
    user {
      email
      first_name
      last_name
      id
    }
    branch {
      unique_id
      name
      is_hq
      id
    }
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehouseFindUniqueQuery = <
      TData = WarehouseFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: WarehouseFindUniqueQueryVariables,
      options?: UseQueryOptions<WarehouseFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WarehouseFindUniqueQuery, TError, TData>(
      ['WarehouseFindUnique', variables],
      fetcher<WarehouseFindUniqueQuery, WarehouseFindUniqueQueryVariables>(client, WarehouseFindUniqueDocument, variables, headers),
      options
    );
export const WarehouseCreateDocument = `
    mutation WarehouseCreate($data: WarehouseCreateInput!) {
  WarehouseCreate(data: $data) {
    id
    branch_id
    unique_id
    name
    user_id
    is_consignment
    description
    address
    user {
      email
      first_name
      last_name
      id
    }
    branch {
      unique_id
      name
      is_hq
      id
    }
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehouseCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseCreateMutation, TError, WarehouseCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseCreateMutation, TError, WarehouseCreateMutationVariables, TContext>(
      ['WarehouseCreate'],
      (variables?: WarehouseCreateMutationVariables) => fetcher<WarehouseCreateMutation, WarehouseCreateMutationVariables>(client, WarehouseCreateDocument, variables, headers)(),
      options
    );
export const WarehouseDeleteDocument = `
    mutation WarehouseDelete($uniqueInput: WarehouseUniqueInput!) {
  WarehouseDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name
  }
}
    `;
export const useWarehouseDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseDeleteMutation, TError, WarehouseDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseDeleteMutation, TError, WarehouseDeleteMutationVariables, TContext>(
      ['WarehouseDelete'],
      (variables?: WarehouseDeleteMutationVariables) => fetcher<WarehouseDeleteMutation, WarehouseDeleteMutationVariables>(client, WarehouseDeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel1CreateDocument = `
    mutation WarehouseLevel1Create($data: WarehouseLevel1CreateInput!) {
  WarehouseLevel1Create(data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel1CreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel1CreateMutation, TError, WarehouseLevel1CreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel1CreateMutation, TError, WarehouseLevel1CreateMutationVariables, TContext>(
      ['WarehouseLevel1Create'],
      (variables?: WarehouseLevel1CreateMutationVariables) => fetcher<WarehouseLevel1CreateMutation, WarehouseLevel1CreateMutationVariables>(client, WarehouseLevel1CreateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel1DeleteDocument = `
    mutation WarehouseLevel1Delete($uniqueInput: WarehouseLevel1UniqueInput!) {
  WarehouseLevel1Delete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel1DeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel1DeleteMutation, TError, WarehouseLevel1DeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel1DeleteMutation, TError, WarehouseLevel1DeleteMutationVariables, TContext>(
      ['WarehouseLevel1Delete'],
      (variables?: WarehouseLevel1DeleteMutationVariables) => fetcher<WarehouseLevel1DeleteMutation, WarehouseLevel1DeleteMutationVariables>(client, WarehouseLevel1DeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel1UpdateDocument = `
    mutation WarehouseLevel1Update($data: WarehouseLevel1UpdateInput!, $uniqueInput: WarehouseLevel1UniqueInput!) {
  WarehouseLevel1Update(data: $data, uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel1UpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel1UpdateMutation, TError, WarehouseLevel1UpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel1UpdateMutation, TError, WarehouseLevel1UpdateMutationVariables, TContext>(
      ['WarehouseLevel1Update'],
      (variables?: WarehouseLevel1UpdateMutationVariables) => fetcher<WarehouseLevel1UpdateMutation, WarehouseLevel1UpdateMutationVariables>(client, WarehouseLevel1UpdateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel2CreateDocument = `
    mutation WarehouseLevel2Create($data: WarehouseLevel2CreateInput!) {
  WarehouseLevel2Create(data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel2CreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel2CreateMutation, TError, WarehouseLevel2CreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel2CreateMutation, TError, WarehouseLevel2CreateMutationVariables, TContext>(
      ['WarehouseLevel2Create'],
      (variables?: WarehouseLevel2CreateMutationVariables) => fetcher<WarehouseLevel2CreateMutation, WarehouseLevel2CreateMutationVariables>(client, WarehouseLevel2CreateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel2DeleteDocument = `
    mutation WarehouseLevel2Delete($uniqueInput: WarehouseLevel2UniqueInput!) {
  WarehouseLevel2Delete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel2DeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel2DeleteMutation, TError, WarehouseLevel2DeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel2DeleteMutation, TError, WarehouseLevel2DeleteMutationVariables, TContext>(
      ['WarehouseLevel2Delete'],
      (variables?: WarehouseLevel2DeleteMutationVariables) => fetcher<WarehouseLevel2DeleteMutation, WarehouseLevel2DeleteMutationVariables>(client, WarehouseLevel2DeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel2UpdateDocument = `
    mutation WarehouseLevel2Update($uniqueInput: WarehouseLevel2UniqueInput!, $data: WarehouseLevel2UpdateInput!) {
  WarehouseLevel2Update(uniqueInput: $uniqueInput, data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel2UpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel2UpdateMutation, TError, WarehouseLevel2UpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel2UpdateMutation, TError, WarehouseLevel2UpdateMutationVariables, TContext>(
      ['WarehouseLevel2Update'],
      (variables?: WarehouseLevel2UpdateMutationVariables) => fetcher<WarehouseLevel2UpdateMutation, WarehouseLevel2UpdateMutationVariables>(client, WarehouseLevel2UpdateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel3CreateDocument = `
    mutation WarehouseLevel3Create($data: WarehouseLevel3CreateInput!) {
  WarehouseLevel3Create(data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel3CreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel3CreateMutation, TError, WarehouseLevel3CreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel3CreateMutation, TError, WarehouseLevel3CreateMutationVariables, TContext>(
      ['WarehouseLevel3Create'],
      (variables?: WarehouseLevel3CreateMutationVariables) => fetcher<WarehouseLevel3CreateMutation, WarehouseLevel3CreateMutationVariables>(client, WarehouseLevel3CreateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel3DeleteDocument = `
    mutation WarehouseLevel3Delete($uniqueInput: WarehouseLevel3UniqueInput!) {
  WarehouseLevel3Delete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel3DeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel3DeleteMutation, TError, WarehouseLevel3DeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel3DeleteMutation, TError, WarehouseLevel3DeleteMutationVariables, TContext>(
      ['WarehouseLevel3Delete'],
      (variables?: WarehouseLevel3DeleteMutationVariables) => fetcher<WarehouseLevel3DeleteMutation, WarehouseLevel3DeleteMutationVariables>(client, WarehouseLevel3DeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel3UpdateDocument = `
    mutation WarehouseLevel3Update($uniqueInput: WarehouseLevel3UniqueInput!, $data: WarehouseLevel3UpdateInput!) {
  WarehouseLevel3Update(uniqueInput: $uniqueInput, data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel3UpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel3UpdateMutation, TError, WarehouseLevel3UpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel3UpdateMutation, TError, WarehouseLevel3UpdateMutationVariables, TContext>(
      ['WarehouseLevel3Update'],
      (variables?: WarehouseLevel3UpdateMutationVariables) => fetcher<WarehouseLevel3UpdateMutation, WarehouseLevel3UpdateMutationVariables>(client, WarehouseLevel3UpdateDocument, variables, headers)(),
      options
    );
export const WarehouseUpdateDocument = `
    mutation WarehouseUpdate($uniqueInput: WarehouseUniqueInput!, $data: WarehouseUpdateInput!) {
  WarehouseUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    branch_id
    unique_id
    name
    user_id
    description
    address
    is_consignment
    user {
      email
      first_name
      last_name
      id
    }
    branch {
      unique_id
      name
      is_hq
      id
    }
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehouseUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseUpdateMutation, TError, WarehouseUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseUpdateMutation, TError, WarehouseUpdateMutationVariables, TContext>(
      ['WarehouseUpdate'],
      (variables?: WarehouseUpdateMutationVariables) => fetcher<WarehouseUpdateMutation, WarehouseUpdateMutationVariables>(client, WarehouseUpdateDocument, variables, headers)(),
      options
    );
export const WarehousesFindAllDocument = `
    query WarehousesFindAll($whereInput: WarehouseWhereInput) {
  WarehousesFindAll(whereInput: $whereInput) {
    id
    branch_id
    unique_id
    name
    user_id
    is_consignment
    description
    address
    user {
      email
      first_name
      last_name
      id
    }
    branch {
      unique_id
      name
      is_hq
      id
    }
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehousesFindAllQuery = <
      TData = WarehousesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: WarehousesFindAllQueryVariables,
      options?: UseQueryOptions<WarehousesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WarehousesFindAllQuery, TError, TData>(
      variables === undefined ? ['WarehousesFindAll'] : ['WarehousesFindAll', variables],
      fetcher<WarehousesFindAllQuery, WarehousesFindAllQueryVariables>(client, WarehousesFindAllDocument, variables, headers),
      options
    );
export const DeliveryTypeCreateDocument = `
    mutation DeliveryTypeCreate($data: DeliveryTypeCreateInput!) {
  DeliveryTypeCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDeliveryTypeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeliveryTypeCreateMutation, TError, DeliveryTypeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeliveryTypeCreateMutation, TError, DeliveryTypeCreateMutationVariables, TContext>(
      ['DeliveryTypeCreate'],
      (variables?: DeliveryTypeCreateMutationVariables) => fetcher<DeliveryTypeCreateMutation, DeliveryTypeCreateMutationVariables>(client, DeliveryTypeCreateDocument, variables, headers)(),
      options
    );
export const DeliveryTypeDeleteDocument = `
    mutation DeliveryTypeDelete($uniqueInput: DeliveryTypeUniqueInput!) {
  DeliveryTypeDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDeliveryTypeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeliveryTypeDeleteMutation, TError, DeliveryTypeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeliveryTypeDeleteMutation, TError, DeliveryTypeDeleteMutationVariables, TContext>(
      ['DeliveryTypeDelete'],
      (variables?: DeliveryTypeDeleteMutationVariables) => fetcher<DeliveryTypeDeleteMutation, DeliveryTypeDeleteMutationVariables>(client, DeliveryTypeDeleteDocument, variables, headers)(),
      options
    );
export const DeliveryTypeFindUniqueDocument = `
    query DeliveryTypeFindUnique($uniqueInput: DeliveryTypeUniqueInput!) {
  DeliveryTypeFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDeliveryTypeFindUniqueQuery = <
      TData = DeliveryTypeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: DeliveryTypeFindUniqueQueryVariables,
      options?: UseQueryOptions<DeliveryTypeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DeliveryTypeFindUniqueQuery, TError, TData>(
      ['DeliveryTypeFindUnique', variables],
      fetcher<DeliveryTypeFindUniqueQuery, DeliveryTypeFindUniqueQueryVariables>(client, DeliveryTypeFindUniqueDocument, variables, headers),
      options
    );
export const DeliveryTypeUpdateDocument = `
    mutation DeliveryTypeUpdate($uniqueInput: DeliveryTypeUniqueInput!, $data: DeliveryTypeUpdateInput!) {
  DeliveryTypeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDeliveryTypeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeliveryTypeUpdateMutation, TError, DeliveryTypeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeliveryTypeUpdateMutation, TError, DeliveryTypeUpdateMutationVariables, TContext>(
      ['DeliveryTypeUpdate'],
      (variables?: DeliveryTypeUpdateMutationVariables) => fetcher<DeliveryTypeUpdateMutation, DeliveryTypeUpdateMutationVariables>(client, DeliveryTypeUpdateDocument, variables, headers)(),
      options
    );
export const DeliveryTypesFindAllDocument = `
    query DeliveryTypesFindAll {
  DeliveryTypesFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useDeliveryTypesFindAllQuery = <
      TData = DeliveryTypesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: DeliveryTypesFindAllQueryVariables,
      options?: UseQueryOptions<DeliveryTypesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DeliveryTypesFindAllQuery, TError, TData>(
      variables === undefined ? ['DeliveryTypesFindAll'] : ['DeliveryTypesFindAll', variables],
      fetcher<DeliveryTypesFindAllQuery, DeliveryTypesFindAllQueryVariables>(client, DeliveryTypesFindAllDocument, variables, headers),
      options
    );
export const PaymentChannelCreateDocument = `
    mutation PaymentChannelCreate($data: PaymentChannelCreateInput!) {
  PaymentChannelCreate(data: $data) {
    id
    unique_id
    payment_name
    account_no
    name
    bank_name
    account_type
    payment_type
    description
    branch_id_list
  }
}
    `;
export const usePaymentChannelCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PaymentChannelCreateMutation, TError, PaymentChannelCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PaymentChannelCreateMutation, TError, PaymentChannelCreateMutationVariables, TContext>(
      ['PaymentChannelCreate'],
      (variables?: PaymentChannelCreateMutationVariables) => fetcher<PaymentChannelCreateMutation, PaymentChannelCreateMutationVariables>(client, PaymentChannelCreateDocument, variables, headers)(),
      options
    );
export const PaymentChannelDeleteDocument = `
    mutation PaymentChannelDelete($uniqueInput: PaymentChannelUniqueInput!) {
  PaymentChannelDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    payment_name
    account_no
    name
    bank_name
    account_type
    payment_type
    description
    branch_id_list
  }
}
    `;
export const usePaymentChannelDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PaymentChannelDeleteMutation, TError, PaymentChannelDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PaymentChannelDeleteMutation, TError, PaymentChannelDeleteMutationVariables, TContext>(
      ['PaymentChannelDelete'],
      (variables?: PaymentChannelDeleteMutationVariables) => fetcher<PaymentChannelDeleteMutation, PaymentChannelDeleteMutationVariables>(client, PaymentChannelDeleteDocument, variables, headers)(),
      options
    );
export const PaymentChannelFindUniqueDocument = `
    query PaymentChannelFindUnique($uniqueInput: PaymentChannelUniqueInput!) {
  PaymentChannelFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    payment_name
    account_no
    name
    bank_name
    account_type
    payment_type
    description
    branch_id_list
  }
}
    `;
export const usePaymentChannelFindUniqueQuery = <
      TData = PaymentChannelFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: PaymentChannelFindUniqueQueryVariables,
      options?: UseQueryOptions<PaymentChannelFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PaymentChannelFindUniqueQuery, TError, TData>(
      ['PaymentChannelFindUnique', variables],
      fetcher<PaymentChannelFindUniqueQuery, PaymentChannelFindUniqueQueryVariables>(client, PaymentChannelFindUniqueDocument, variables, headers),
      options
    );
export const PaymentChannelUpdateDocument = `
    mutation PaymentChannelUpdate($uniqueInput: PaymentChannelUniqueInput!, $data: PaymentChannelUpdateInput!) {
  PaymentChannelUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    payment_name
    account_no
    name
    bank_name
    account_type
    payment_type
    description
    branch_id_list
  }
}
    `;
export const usePaymentChannelUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PaymentChannelUpdateMutation, TError, PaymentChannelUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PaymentChannelUpdateMutation, TError, PaymentChannelUpdateMutationVariables, TContext>(
      ['PaymentChannelUpdate'],
      (variables?: PaymentChannelUpdateMutationVariables) => fetcher<PaymentChannelUpdateMutation, PaymentChannelUpdateMutationVariables>(client, PaymentChannelUpdateDocument, variables, headers)(),
      options
    );
export const PaymentChannelsFindAllDocument = `
    query PaymentChannelsFindAll {
  PaymentChannelsFindAll {
    id
    unique_id
    payment_name
    account_no
    name
    bank_name
    account_type
    payment_type
    description
    branch_id_list
  }
}
    `;
export const usePaymentChannelsFindAllQuery = <
      TData = PaymentChannelsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: PaymentChannelsFindAllQueryVariables,
      options?: UseQueryOptions<PaymentChannelsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PaymentChannelsFindAllQuery, TError, TData>(
      variables === undefined ? ['PaymentChannelsFindAll'] : ['PaymentChannelsFindAll', variables],
      fetcher<PaymentChannelsFindAllQuery, PaymentChannelsFindAllQueryVariables>(client, PaymentChannelsFindAllDocument, variables, headers),
      options
    );
export const SalesChannelCreateDocument = `
    mutation SalesChannelCreate($data: SalesChannelCreateInput!) {
  SalesChannelCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesChannelCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SalesChannelCreateMutation, TError, SalesChannelCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SalesChannelCreateMutation, TError, SalesChannelCreateMutationVariables, TContext>(
      ['SalesChannelCreate'],
      (variables?: SalesChannelCreateMutationVariables) => fetcher<SalesChannelCreateMutation, SalesChannelCreateMutationVariables>(client, SalesChannelCreateDocument, variables, headers)(),
      options
    );
export const SalesChannelDeleteDocument = `
    mutation SalesChannelDelete($uniqueInput: SalesChannelUniqueInput!) {
  SalesChannelDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesChannelDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SalesChannelDeleteMutation, TError, SalesChannelDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SalesChannelDeleteMutation, TError, SalesChannelDeleteMutationVariables, TContext>(
      ['SalesChannelDelete'],
      (variables?: SalesChannelDeleteMutationVariables) => fetcher<SalesChannelDeleteMutation, SalesChannelDeleteMutationVariables>(client, SalesChannelDeleteDocument, variables, headers)(),
      options
    );
export const SalesChannelFindUniqueDocument = `
    query SalesChannelFindUnique($uniqueInput: SalesChannelUniqueInput!) {
  SalesChannelFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesChannelFindUniqueQuery = <
      TData = SalesChannelFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SalesChannelFindUniqueQueryVariables,
      options?: UseQueryOptions<SalesChannelFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SalesChannelFindUniqueQuery, TError, TData>(
      ['SalesChannelFindUnique', variables],
      fetcher<SalesChannelFindUniqueQuery, SalesChannelFindUniqueQueryVariables>(client, SalesChannelFindUniqueDocument, variables, headers),
      options
    );
export const SalesChannelUpdateDocument = `
    mutation SalesChannelUpdate($uniqueInput: SalesChannelUniqueInput!, $data: SalesChannelUpdateInput!) {
  SalesChannelUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesChannelUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SalesChannelUpdateMutation, TError, SalesChannelUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SalesChannelUpdateMutation, TError, SalesChannelUpdateMutationVariables, TContext>(
      ['SalesChannelUpdate'],
      (variables?: SalesChannelUpdateMutationVariables) => fetcher<SalesChannelUpdateMutation, SalesChannelUpdateMutationVariables>(client, SalesChannelUpdateDocument, variables, headers)(),
      options
    );
export const SalesChannelsFindAllDocument = `
    query SalesChannelsFindAll {
  SalesChannelsFindAll {
    item_price_list {
      id
      unique_id
      type
      name
      is_increase
      currency
      description
      is_active
      start_date
      end_date
      adjusted_price
      adjusted_item_list {
        id
        sku_name
        item_name
        reference_unique_id
        current_price
        adjusted_price
      }
    }
    name_th
    name_en
    id
    unique_id
    description
  }
}
    `;
export const useSalesChannelsFindAllQuery = <
      TData = SalesChannelsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SalesChannelsFindAllQueryVariables,
      options?: UseQueryOptions<SalesChannelsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SalesChannelsFindAllQuery, TError, TData>(
      variables === undefined ? ['SalesChannelsFindAll'] : ['SalesChannelsFindAll', variables],
      fetcher<SalesChannelsFindAllQuery, SalesChannelsFindAllQueryVariables>(client, SalesChannelsFindAllDocument, variables, headers),
      options
    );
export const SalesTypeCreateDocument = `
    mutation SalesTypeCreate($data: SalesTypeCreateInput!) {
  SalesTypeCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesTypeCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SalesTypeCreateMutation, TError, SalesTypeCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SalesTypeCreateMutation, TError, SalesTypeCreateMutationVariables, TContext>(
      ['SalesTypeCreate'],
      (variables?: SalesTypeCreateMutationVariables) => fetcher<SalesTypeCreateMutation, SalesTypeCreateMutationVariables>(client, SalesTypeCreateDocument, variables, headers)(),
      options
    );
export const SalesTypeDeleteDocument = `
    mutation SalesTypeDelete($uniqueInput: SalesTypeUniqueInput!) {
  SalesTypeDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesTypeDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SalesTypeDeleteMutation, TError, SalesTypeDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SalesTypeDeleteMutation, TError, SalesTypeDeleteMutationVariables, TContext>(
      ['SalesTypeDelete'],
      (variables?: SalesTypeDeleteMutationVariables) => fetcher<SalesTypeDeleteMutation, SalesTypeDeleteMutationVariables>(client, SalesTypeDeleteDocument, variables, headers)(),
      options
    );
export const SalesTypeFindUniqueDocument = `
    query SalesTypeFindUnique($uniqueInput: SalesTypeUniqueInput!) {
  SalesTypeFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesTypeFindUniqueQuery = <
      TData = SalesTypeFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SalesTypeFindUniqueQueryVariables,
      options?: UseQueryOptions<SalesTypeFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SalesTypeFindUniqueQuery, TError, TData>(
      ['SalesTypeFindUnique', variables],
      fetcher<SalesTypeFindUniqueQuery, SalesTypeFindUniqueQueryVariables>(client, SalesTypeFindUniqueDocument, variables, headers),
      options
    );
export const SalesTypeUpdateDocument = `
    mutation SalesTypeUpdate($uniqueInput: SalesTypeUniqueInput!, $data: SalesTypeUpdateInput!) {
  SalesTypeUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesTypeUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SalesTypeUpdateMutation, TError, SalesTypeUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SalesTypeUpdateMutation, TError, SalesTypeUpdateMutationVariables, TContext>(
      ['SalesTypeUpdate'],
      (variables?: SalesTypeUpdateMutationVariables) => fetcher<SalesTypeUpdateMutation, SalesTypeUpdateMutationVariables>(client, SalesTypeUpdateDocument, variables, headers)(),
      options
    );
export const SalesTypesFindAllDocument = `
    query SalesTypesFindAll {
  SalesTypesFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useSalesTypesFindAllQuery = <
      TData = SalesTypesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SalesTypesFindAllQueryVariables,
      options?: UseQueryOptions<SalesTypesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SalesTypesFindAllQuery, TError, TData>(
      variables === undefined ? ['SalesTypesFindAll'] : ['SalesTypesFindAll', variables],
      fetcher<SalesTypesFindAllQuery, SalesTypesFindAllQueryVariables>(client, SalesTypesFindAllDocument, variables, headers),
      options
    );
export const TagCreateDocument = `
    mutation TagCreate($data: TagCreateInput!) {
  TagCreate(data: $data) {
    id
    name
    document_type
  }
}
    `;
export const useTagCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TagCreateMutation, TError, TagCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TagCreateMutation, TError, TagCreateMutationVariables, TContext>(
      ['TagCreate'],
      (variables?: TagCreateMutationVariables) => fetcher<TagCreateMutation, TagCreateMutationVariables>(client, TagCreateDocument, variables, headers)(),
      options
    );
export const TagDeleteDocument = `
    mutation TagDelete($uniqueInput: TagUniqueInput!) {
  TagDelete(uniqueInput: $uniqueInput) {
    id
    name
    document_type
  }
}
    `;
export const useTagDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TagDeleteMutation, TError, TagDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TagDeleteMutation, TError, TagDeleteMutationVariables, TContext>(
      ['TagDelete'],
      (variables?: TagDeleteMutationVariables) => fetcher<TagDeleteMutation, TagDeleteMutationVariables>(client, TagDeleteDocument, variables, headers)(),
      options
    );
export const TagsDocument = `
    query Tags($findManyInput: TagFindManyInput) {
  Tags(findManyInput: $findManyInput) {
    id
    name
    document_type
  }
}
    `;
export const useTagsQuery = <
      TData = TagsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: TagsQueryVariables,
      options?: UseQueryOptions<TagsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<TagsQuery, TError, TData>(
      variables === undefined ? ['Tags'] : ['Tags', variables],
      fetcher<TagsQuery, TagsQueryVariables>(client, TagsDocument, variables, headers),
      options
    );
export const ZoneCreateDocument = `
    mutation ZoneCreate($data: ZoneCreateInput!) {
  ZoneCreate(data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useZoneCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ZoneCreateMutation, TError, ZoneCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ZoneCreateMutation, TError, ZoneCreateMutationVariables, TContext>(
      ['ZoneCreate'],
      (variables?: ZoneCreateMutationVariables) => fetcher<ZoneCreateMutation, ZoneCreateMutationVariables>(client, ZoneCreateDocument, variables, headers)(),
      options
    );
export const ZoneDeleteDocument = `
    mutation ZoneDelete($uniqueInput: ZoneUniqueInput!) {
  ZoneDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useZoneDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ZoneDeleteMutation, TError, ZoneDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ZoneDeleteMutation, TError, ZoneDeleteMutationVariables, TContext>(
      ['ZoneDelete'],
      (variables?: ZoneDeleteMutationVariables) => fetcher<ZoneDeleteMutation, ZoneDeleteMutationVariables>(client, ZoneDeleteDocument, variables, headers)(),
      options
    );
export const ZoneFindUniqueDocument = `
    query ZoneFindUnique($uniqueInput: ZoneUniqueInput!) {
  ZoneFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useZoneFindUniqueQuery = <
      TData = ZoneFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ZoneFindUniqueQueryVariables,
      options?: UseQueryOptions<ZoneFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ZoneFindUniqueQuery, TError, TData>(
      ['ZoneFindUnique', variables],
      fetcher<ZoneFindUniqueQuery, ZoneFindUniqueQueryVariables>(client, ZoneFindUniqueDocument, variables, headers),
      options
    );
export const ZoneUpdateDocument = `
    mutation ZoneUpdate($uniqueInput: ZoneUniqueInput!, $data: ZoneUpdateInput!) {
  ZoneUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useZoneUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ZoneUpdateMutation, TError, ZoneUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ZoneUpdateMutation, TError, ZoneUpdateMutationVariables, TContext>(
      ['ZoneUpdate'],
      (variables?: ZoneUpdateMutationVariables) => fetcher<ZoneUpdateMutation, ZoneUpdateMutationVariables>(client, ZoneUpdateDocument, variables, headers)(),
      options
    );
export const ZonesFindAllDocument = `
    query ZonesFindAll {
  ZonesFindAll {
    id
    unique_id
    name_th
    name_en
    description
  }
}
    `;
export const useZonesFindAllQuery = <
      TData = ZonesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ZonesFindAllQueryVariables,
      options?: UseQueryOptions<ZonesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ZonesFindAllQuery, TError, TData>(
      variables === undefined ? ['ZonesFindAll'] : ['ZonesFindAll', variables],
      fetcher<ZonesFindAllQuery, ZonesFindAllQueryVariables>(client, ZonesFindAllDocument, variables, headers),
      options
    );
export const BranchUserGetAllDocument = `
    query BranchUserGetAll($where: BranchUserWhere) {
  BranchUserGetAll(where: $where) {
    branch_id
    user_id
  }
}
    `;
export const useBranchUserGetAllQuery = <
      TData = BranchUserGetAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BranchUserGetAllQueryVariables,
      options?: UseQueryOptions<BranchUserGetAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BranchUserGetAllQuery, TError, TData>(
      variables === undefined ? ['BranchUserGetAll'] : ['BranchUserGetAll', variables],
      fetcher<BranchUserGetAllQuery, BranchUserGetAllQueryVariables>(client, BranchUserGetAllDocument, variables, headers),
      options
    );
export const BranchUserGetByUserIdDocument = `
    query BranchUserGetByUserId($userId: Int) {
  BranchUserGetByUserId(userId: $userId) {
    branch_id
    branch {
      id
      unique_id
      name
      is_hq
      status
    }
  }
}
    `;
export const useBranchUserGetByUserIdQuery = <
      TData = BranchUserGetByUserIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BranchUserGetByUserIdQueryVariables,
      options?: UseQueryOptions<BranchUserGetByUserIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BranchUserGetByUserIdQuery, TError, TData>(
      variables === undefined ? ['BranchUserGetByUserId'] : ['BranchUserGetByUserId', variables],
      fetcher<BranchUserGetByUserIdQuery, BranchUserGetByUserIdQueryVariables>(client, BranchUserGetByUserIdDocument, variables, headers),
      options
    );
export const BranchUserUpsertDocument = `
    mutation BranchUserUpsert($addInput: [BranchUserAddInput!], $deleteInput: [BranchUserDeleteInput!]) {
  BranchUserUpsert(addInput: $addInput, deleteInput: $deleteInput) {
    count_add
    count_delete
  }
}
    `;
export const useBranchUserUpsertMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BranchUserUpsertMutation, TError, BranchUserUpsertMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BranchUserUpsertMutation, TError, BranchUserUpsertMutationVariables, TContext>(
      ['BranchUserUpsert'],
      (variables?: BranchUserUpsertMutationVariables) => fetcher<BranchUserUpsertMutation, BranchUserUpsertMutationVariables>(client, BranchUserUpsertDocument, variables, headers)(),
      options
    );
export const GeneralUniqueIdGenerateDocument = `
    query GeneralUniqueIdGenerate($modelType: GeneralModelType!) {
  UniqueIdGenerate(modelType: $modelType)
}
    `;
export const useGeneralUniqueIdGenerateQuery = <
      TData = GeneralUniqueIdGenerateQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GeneralUniqueIdGenerateQueryVariables,
      options?: UseQueryOptions<GeneralUniqueIdGenerateQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GeneralUniqueIdGenerateQuery, TError, TData>(
      ['GeneralUniqueIdGenerate', variables],
      fetcher<GeneralUniqueIdGenerateQuery, GeneralUniqueIdGenerateQueryVariables>(client, GeneralUniqueIdGenerateDocument, variables, headers),
      options
    );
export const GenerateUserReportDocument = `
    query GenerateUserReport($input: ExportGenerateInput) {
  GenerateReportNoQueue(input: $input)
}
    `;
export const useGenerateUserReportQuery = <
      TData = GenerateUserReportQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GenerateUserReportQueryVariables,
      options?: UseQueryOptions<GenerateUserReportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GenerateUserReportQuery, TError, TData>(
      variables === undefined ? ['GenerateUserReport'] : ['GenerateUserReport', variables],
      fetcher<GenerateUserReportQuery, GenerateUserReportQueryVariables>(client, GenerateUserReportDocument, variables, headers),
      options
    );
export const ImportUserDocument = `
    mutation ImportUser($importInput: UserImportInput!) {
  ImportUser(importInput: $importInput) {
    success
    timestamp
    status
    message
    jobId
    detail
  }
}
    `;
export const useImportUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ImportUserMutation, TError, ImportUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ImportUserMutation, TError, ImportUserMutationVariables, TContext>(
      ['ImportUser'],
      (variables?: ImportUserMutationVariables) => fetcher<ImportUserMutation, ImportUserMutationVariables>(client, ImportUserDocument, variables, headers)(),
      options
    );
export const UserCreateDocument = `
    mutation UserCreate($data: UserCreateInput!) {
  UserCreate(data: $data) {
    id
    unique_id
    email
    original_branch
    original_branch_object {
      unique_id
      is_hq
      name
    }
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    employee_type
    is_sales_employee
    status
    inactive_date
    remark_status
    start_date
    created_date
    last_updated_date
    last_login_branch
  }
}
    `;
export const useUserCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserCreateMutation, TError, UserCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserCreateMutation, TError, UserCreateMutationVariables, TContext>(
      ['UserCreate'],
      (variables?: UserCreateMutationVariables) => fetcher<UserCreateMutation, UserCreateMutationVariables>(client, UserCreateDocument, variables, headers)(),
      options
    );
export const UserDeleteDocument = `
    mutation UserDelete($uniqueInput: UserUniqueInput!) {
  UserDelete(uniqueInput: $uniqueInput) {
    id
    email
  }
}
    `;
export const useUserDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>(
      ['UserDelete'],
      (variables?: UserDeleteMutationVariables) => fetcher<UserDeleteMutation, UserDeleteMutationVariables>(client, UserDeleteDocument, variables, headers)(),
      options
    );
export const UserDocument = `
    query User($uniqueInput: UserUniqueInput) {
  UserFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    email
    original_branch
    original_branch_object {
      unique_id
      is_hq
      name
    }
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    employee_type
    is_sales_employee
    status
    inactive_date
    remark_status
    start_date
    created_date
    last_updated_date
    last_login_branch
  }
}
    `;
export const useUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: UserQueryVariables,
      options?: UseQueryOptions<UserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserQuery, TError, TData>(
      variables === undefined ? ['User'] : ['User', variables],
      fetcher<UserQuery, UserQueryVariables>(client, UserDocument, variables, headers),
      options
    );
export const UserUpdateDocument = `
    mutation UserUpdate($data: UserUpdateInput!, $uniqueInput: UserUniqueInput!) {
  UserUpdate(data: $data, uniqueInput: $uniqueInput) {
    id
    unique_id
    email
    original_branch
    original_branch_object {
      unique_id
      is_hq
      name
    }
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    employee_type
    is_sales_employee
    status
    inactive_date
    remark_status
    start_date
    created_date
    last_updated_date
    last_login_branch
  }
}
    `;
export const useUserUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserUpdateMutation, TError, UserUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserUpdateMutation, TError, UserUpdateMutationVariables, TContext>(
      ['UserUpdate'],
      (variables?: UserUpdateMutationVariables) => fetcher<UserUpdateMutation, UserUpdateMutationVariables>(client, UserUpdateDocument, variables, headers)(),
      options
    );
export const ValidateUserImportDocument = `
    query ValidateUserImport($validateInput: ValidateUserImportInput!) {
  ValidateUserImport(validateInput: $validateInput) {
    existing_user_unique_id_list
    missing_user_unique_id_list
    missing_branch_unique_id_list
    existing_email_list
    existing_phone_list
    missing_department_list
    missing_position_list
    missing_employee_type_list
  }
}
    `;
export const useValidateUserImportQuery = <
      TData = ValidateUserImportQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ValidateUserImportQueryVariables,
      options?: UseQueryOptions<ValidateUserImportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ValidateUserImportQuery, TError, TData>(
      ['ValidateUserImport', variables],
      fetcher<ValidateUserImportQuery, ValidateUserImportQueryVariables>(client, ValidateUserImportDocument, variables, headers),
      options
    );