import dayjs from "dayjs";
import { IPriceList } from "../../../types/Inventory/PriceList";

export const priceListQueryFormatter = (data: IPriceList) => {
  const { start_date, end_date, ...otherData } = data;
  const formatData = {
    ...otherData,
    start_date: start_date ? dayjs(start_date) : null,
    end_date: end_date ? dayjs(end_date) : null,
  };
  return formatData;
};

export const priceListCreateFormatter = (
  data: IPriceList,
  reference_unique_id: string
) => {
  const { adjusted_item_list, is_active } = data;
  const formatItemList = adjusted_item_list.map(({ genId, ...otherItem }) => ({
    ...otherItem,
    reference_unique_id,
  }));

  return {
    ...data,
    is_active: is_active ? 1 : 0,
    adjusted_item_list: formatItemList,
  };
};

export const priceListUpdateFormatter = (data: IPriceList) => {
  const { id, sales_channel, adjusted_item_list, is_active, ...otherData } =
    data;
  const formatAdjusted = adjusted_item_list.map(
    ({ genId, ...otherData }) => otherData
  );
  return {
    ...otherData,
    is_active: is_active ? 1 : 0,
    adjusted_item_list: formatAdjusted,
  };
};
