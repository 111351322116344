import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Fragment, useState } from "react";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IBarcodeForm } from "../../../../types/Inventory";
import { IGoodsAdjustment } from "../../../../types/Inventory/GoodsAdjustment";
import { useDisable } from "../../../../hooks/use-disable";
import { useTranslation } from "react-i18next";
import Confirmation from "../../../UI/Confirmation";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ClearIcon from "@mui/icons-material/Clear";
import {
  ItemSkuQtysQuery,
  TraceEntriesFindManyAggridQuery,
  useItemSkuQtysQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { checkIsNotDraftOrEmptyStatus } from "../../../../utils/Global";
import { Tracability } from "../../../../generated/general";
import { useGoodsAdjustmentItemList } from "../../../../hooks/Inventory/use-adjustment-item-list";
import { useGoodsAdjustmentBarcodeSubmitHandler } from "../../../../hooks/Inventory/use-adjustment-handle-scan-barcode";
import GoodsAdjustmentSerialList from "./SerialList";
import GoodsAdjustmentTotalPostedQuantityCell from "./totalPostedQuantityCell";
import GoodsAdjustmentSumStock from "./SumStock";

type Props = {
  fields: FieldArrayWithId<IGoodsAdjustment, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsAdjustment, "trace_entry_list">;
  append: UseFieldArrayAppend<IGoodsAdjustment, "trace_entry_list">;
  isEdit: boolean;
};

const GoodsAdjustmentItemList = ({
  fields,
  remove,
  append,
  update,
  isEdit,
}: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const graphQLClientWithHeaderItem = createGraphQLClientWithMiddleware("wms");
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IGoodsAdjustment>();

  const watchWarehouseId = useWatch({
    control,
    name: "warehouse_id",
  });

  const status = getValues("aggrid_status");
  const isNotDraft = checkIsNotDraftOrEmptyStatus(status || "");
  const headers = useGoodsAdjustmentItemList(isNotDraft);

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      destination_bin_location_id: "",
    },
  });

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter: watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals: watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const handleBarcodeSummit = useGoodsAdjustmentBarcodeSubmitHandler({
    append,
    fields,
    update,
    refetchTraceEntries: refetch,
    refetchSkuQty: refetchSkuQty,
    barcode: watchBarcode("barcode").trim(),
  });

  const onBarcodeSubmitHandler = async () => {
    await handleBarcodeSummit();

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  const tablePadding = {
    px: 1,
    py: 1,
  };

  return (
    <>
      {isNotDraft ? null : (
        <CustomizedBox margin={0}>
          <Typography fontWeight={600} color={"primary.main"}>
            {t("inventory.goods_adjustment.detail.scan_barcode")}
          </Typography>
          <Grid container spacing={1.5} mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitHandler();
                  }
                }}
                disabled={isNotDraft || !watchWarehouseId}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
      <CustomizedBox maxWidth={1650} margin={isNotDraft ? 0 : "1.5rem 0"}>
        <Typography color="primary.main" fontWeight={600} mb={2}>
          {t("inventory.goods_adjustment.detail.table.header")}
        </Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell align="center" sx={tablePadding}>
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      <Typography fontSize={14}>
                        {formatDateTimeNoAMPM(trace.posted_date)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell sx={tablePadding}>
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      {trace.tracability === Tracability.Normal ? (
                        <Typography fontSize={14}>
                          {trace.stock_qty || 0}
                        </Typography>
                      ) : (
                        <GoodsAdjustmentTotalPostedQuantityCell
                          nestedIndex={index}
                          keyTrace="stock_qty"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={tablePadding}>
                      {trace.tracability === Tracability.Normal ? (
                        <>
                          {disabled ? (
                            <Typography fontSize={14}>{trace.qty}</Typography>
                          ) : (
                            <ControlledTextField
                              type="number"
                              control={control}
                              name={`trace_entry_list.${index}.qty`}
                              error={Boolean(
                                errors?.trace_entry_list?.[index]?.qty
                              )}
                              FormHelperTextProps={{
                                style: { fontSize: "10px" },
                              }}
                              viewMode={isNotDraft}
                            />
                          )}
                        </>
                      ) : (
                        <GoodsAdjustmentTotalPostedQuantityCell
                          nestedIndex={index}
                          keyTrace="qty"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal ? (
                        <GoodsAdjustmentSumStock
                          nestedIndex={index}
                        ></GoodsAdjustmentSumStock>
                      ) : (
                        <GoodsAdjustmentTotalPostedQuantityCell
                          nestedIndex={index}
                          keyTrace="all"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontSize={14}>
                        {trace.uom?.name_th}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>{trace.serial_no}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal ? (
                        trace.all_bin_locations &&
                        trace.all_bin_locations.length > 1 &&
                        !disabled &&
                        !isNotDraft ? (
                          <ControlledSelect
                            control={control}
                            name={`trace_entry_list[${index}].source_bin_location_id`}
                            options={trace.all_bin_locations.map((bin) => ({
                              label: bin.name,
                              value: bin.id,
                            }))}
                            onChange={(e: any) => {
                              const { id, ...otherFields } = fields[index];
                              update(index, {
                                ...otherFields,
                                stock_qty:
                                  trace.all_bin_locations &&
                                  trace.all_bin_locations.find(
                                    (bin) => bin.id === e.target.value
                                  ).stock_qty,
                                qty: 0,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            MenuProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            helperTextSize={10}
                          />
                        ) : (
                          <Typography fontSize={14}>
                            {trace.source_bin_location?.name}
                          </Typography>
                        )
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      {trace.tracability === Tracability.Normal &&
                        trace.scanned_by && (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CustomizedAvatar
                              avatars={[
                                {
                                  unique_id:
                                    trace.scanned_by.user_unique_id || "",
                                  first_name: trace.scanned_by.first_name || "",
                                  last_name: trace.scanned_by.last_name || "",
                                  img_url: trace.scanned_by.img_url
                                    ? trace.scanned_by.img_url[0]
                                    : "",
                                },
                              ]}
                            />
                          </Box>
                        )}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>{trace.barcode}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal &&
                        (status === "finished" ? (
                          <Typography fontSize={14}>{trace.remark}</Typography>
                        ) : (
                          <ControlledTextField
                            placeholder={t("sentence.remark")}
                            control={control}
                            name={`trace_entry_list.${index}.remark`}
                          />
                        ))}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.lot_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.manufactured_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.expired_date)}
                        </Typography>
                      )}
                    </TableCell>
                    {!isNotDraft && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            openConfirmationHandler();
                            setDeletedIndex(index);
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <GoodsAdjustmentSerialList
                    isEdit={isEdit}
                    nestedIndex={index}
                  />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Confirmation
          title="ยืนยันหากต้องการลบสินค้า"
          message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
      </CustomizedBox>
    </>
  );
};

export default GoodsAdjustmentItemList;
