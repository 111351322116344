import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import PdfCompanyInfo from "./PdfCompanyInfo";
import SalesPdfHighlight from "./SalesPdfHighlight";
import SalesPdfLeftInfo from "./SalesPdfLeftInfo";
import SalesPdfRightInfo from "./SalesPdfRightInfo";
import { IPdfInfo } from "../../../types/global";
import SalesPdfFooter from "./SalesPdfFooter";
import SalesPdfSigner from "./SalesPdfSigner";
import SalesPdfLeftPayment from "./SalesPdfLeftPayment";
import SalesPdfRightPayment from "./SalesPdfRightPayment";
import { useTranslation } from "react-i18next";
import SalesPdfExternalSigner from "./SalesPdfExternalSigner";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../../../generated/general";
import { ICompany } from "../../../types/Setting/Company";

type Props = {
  printType?: "copy" | "original";
  name: string;
  highlightSection: IPdfInfo;
  leftSection: IPdfInfo;
  rightSection: IPdfInfo;
  leftPaymentSection?: IPdfInfo;
  rightPaymentSection?: IPdfInfo;
  data: any;
  multiplePage?: boolean;
  page?: number;
  allPages?: number;
  summary?: boolean;
  children: ReactNode;
  salePerson?: string;
  totalAmount?: number;
  showPaymentSection?: boolean;
  hideAdditionalDiscount?: boolean;
  documentType: string;
  approvalTemplates?: any[];
  //isPrinting: boolean;
};

const SalesPdfLayout = ({
  printType,
  name,
  highlightSection,
  leftSection,
  rightSection,
  leftPaymentSection,
  rightPaymentSection,
  data,
  multiplePage,
  page,
  allPages,
  summary,
  children,
  salePerson,
  totalAmount,
  showPaymentSection,
  hideAdditionalDiscount,
  documentType,
  approvalTemplates,
}: //isPrinting,
Props) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${height}px`
      );
    }
  }, []);
  // const componentRef = useRef<ReactInstance | null>(null);
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const fontSize = 10.66;
  const isLastPage = multiplePage && page === allPages;

  const isSpiltLine = [
    "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "ใบส่งของ/ใบกำกับภาษี",
    "ใบแจ้งหนี้/ใบกำกับภาษี",
  ].includes(name);

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: companyData, isLoading } =
    useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(graphqlClientGeneral, {
      uniqueInput: {
        id: 1,
      },
    });

  return (
    <>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
          "@media print": {
            width: "auto",
            height: "297mm",
            //padding: '0',
            boxSizing: "border-box",
          },
        }}
      >
        <Box>
          <Box id="print-header" ref={headerRef}>
            <Grid container spacing={1.5}>
              <Grid item xs={7}>
                <PdfCompanyInfo
                  branch_unique_id={data.branch.unique_id}
                  company={companyData?.CompanyFindUnique as ICompany}
                  isLoading={isLoading}
                />
                <Divider sx={{ my: 1 }} />
                <SalesPdfLeftInfo
                  headers={leftSection.headers}
                  info={leftSection.info}
                />
              </Grid>
              <Grid item xs={5}>
                {printType && isSpiltLine && (
                  <Typography
                    fontSize={26.66}
                    fontWeight={600}
                    align="right"
                    color={"primary.main"}
                  >
                    {t(`print.${printType}`)}
                  </Typography>
                )}
                <Typography
                  fontSize={26.66}
                  fontWeight={600}
                  align="right"
                  color={"primary.main"}
                >
                  {printType && !isSpiltLine
                    ? `${t(`print.${printType}`)}${name}`
                    : name}
                </Typography>
                <SalesPdfHighlight
                  headers={highlightSection.headers}
                  info={highlightSection.info}
                />
                <SalesPdfRightInfo
                  headers={rightSection.headers}
                  info={rightSection.info}
                />
              </Grid>
            </Grid>

            {/* <SalesPdfFooter data={data} /> */}
          </Box>
          <Box id="print-content">{children}</Box>
          {(multiplePage && isLastPage) || allPages === 1 ? (
            <Box>
              {summary && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    mb: 1,
                  }}
                >
                  <SalesPdfFooter
                    data={data}
                    documentType={documentType}
                    hideAdditionalDiscount={hideAdditionalDiscount}
                  />
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
        <Box>
          {(multiplePage && isLastPage) || allPages === 1 ? (
            <>
              {documentType === "sales_return" && (
                <Box height={"auto"}>
                  <Divider />
                  <Box display={"flex"} gap={1} my={1}>
                    <Typography fontWeight={600} fontSize={fontSize}>
                      เหตุผลการคืนของ
                    </Typography>
                    <Typography fontSize={fontSize}>
                      {data.reason_to_return}
                    </Typography>
                  </Box>
                </Box>
              )}
              {showPaymentSection && (
                <Box height="auto">
                  <Divider />
                  <Grid container spacing={1.5}>
                    <Grid item xs={7}>
                      <SalesPdfLeftPayment
                        headers={leftPaymentSection?.headers || []}
                        info={leftPaymentSection?.info || []}
                      />
                    </Grid>
                    <Grid item xs={5} alignSelf={"flex-end"}>
                      <SalesPdfRightPayment
                        headers={rightPaymentSection?.headers || []}
                        info={rightPaymentSection?.info || []}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          ) : null}

          {(page || multiplePage) && (
            <>
              <Divider />
              <Box sx={{ height: "auto", mt: 1 }}>
                {["quotation", "sales_order"].includes(documentType) ? (
                  <SalesPdfSigner
                    status={data.aggrid_status}
                    approver_list={
                      ["draft", "wait_approve"].includes(data.aggrid_status)
                        ? approvalTemplates
                        : data.approver_list
                    }
                    reviewer_list={data.reviewer_list}
                    created_by={data.created_by}
                    issue_date={data.issue_date}
                    salePerson={salePerson}
                    documentType={documentType}
                  />
                ) : (
                  <SalesPdfExternalSigner
                    documentType={documentType}
                    company={companyData?.CompanyFindUnique as ICompany}
                  />
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  fontSize: "10px",
                  color: "#737373",
                  position: "absolute",
                  bottom: 10,
                  right: 24,
                  "@media print": {
                    height: "auto",
                  },
                }}
              >
                <Typography fontSize={11} align="right" color="#333333">
                  หน้า {page}/{allPages || 1}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SalesPdfLayout;
