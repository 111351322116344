import { Fragment, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ISelectOption } from "../../../../types/global";

import { ItemType } from "../../../../generated/inventory";

import { Grid } from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledSelect from "../../../Controller/ControlledSelect";
// import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

import RadioBox from "../../../UI/RadioBox";
import ImporterDropzoneUI from "../../../UI/ImporterDropzoneUI";

const UploadFile = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const type = watch("type");
  const itemType = watch("item_type");
  const fileItemType = watch("file_item_type");

  const itemTypeOptions: ISelectOption[] = [
    { label: t("inventory.type.normal"), value: ItemType.Normal },
    { label: t("inventory.type.variant"), value: ItemType.Variant },
    { label: t("inventory.type.bundle"), value: ItemType.Bundle },
    { label: t("inventory.type.service"), value: ItemType.Service },
  ];

  const normalItemFileTypeOptions: ISelectOption[] = [
    { label: t("inventory.items.import.file_type.index"), value: "default" },
    {
      label: t("inventory.items.import.file_type.update_stock"),
      value: "update_stock",
    },
    { label: t("inventory.items.import.file_type.img"), value: "img" },
  ];

  const variantItemFileTypeOptions: ISelectOption[] = [
    { label: t("inventory.items.import.file_type.index"), value: "default" },
    {
      label: t("inventory.items.import.file_type.update_stock"),
      value: "update_stock",
    },
    {
      label: t("inventory.items.import.file_type.sku"),
      value: "sku",
    },
    {
      label: t("inventory.items.import.file_type.each_sku"),
      value: "each_sku",
    },
    { label: t("inventory.items.import.file_type.img"), value: "img" },
  ];

  const bundleItemFileTypeOptions: ISelectOption[] = [
    { label: t("inventory.items.import.file_type.index"), value: "default" },
    {
      label: t("inventory.items.import.file_type.bundle_items"),
      value: "bundle_items",
    },
    {
      label: t("inventory.items.import.file_type.update_stock"),
      value: "update_stock",
    },
    { label: t("inventory.items.import.file_type.img"), value: "img" },
  ];

  const serviceItemFileTypeOptions: ISelectOption[] = [
    { label: t("inventory.items.import.file_type.index"), value: "default" },
    { label: t("inventory.items.import.file_type.img"), value: "img" },
  ];

  const downloadTemplateHandler = () => {
    let filePath: string = "";
    const basePath: string = "/static/importer/items/";
    if (["img", "update_stock"].includes(fileItemType)) {
      filePath = basePath + `${fileItemType}_importer_template.xlsx`;
    } else
      filePath =
        basePath +
        `${itemType}/${itemType}_${fileItemType}_importer_template.xlsx`;
    window.open(filePath, "_blank");
  };

  const updateOnly = fileItemType !== "default";

  useEffect(() => {
    if (updateOnly) {
      setValue("type", "update");
    }
  }, [updateOnly, setValue]);

  return (
    <Fragment>
      <Grid container spacing={2} mb={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <ControlledSelect
            control={control}
            name="item_type"
            options={itemTypeOptions}
            onChange={() => setValue("file_item_type", "default")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledSelect
            control={control}
            name="file_item_type"
            options={
              itemType === ItemType.Normal
                ? normalItemFileTypeOptions
                : itemType === ItemType.Service
                ? serviceItemFileTypeOptions
                : itemType === ItemType.Variant
                ? variantItemFileTypeOptions
                : bundleItemFileTypeOptions
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedButton
            title={t("inventory.items.import.download_template")}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={downloadTemplateHandler}
          />
        </Grid>
        {/* <Grid item xs={2} md={1}>
          <CustomizedTooltip title="">
            <InfoOutlinedIcon />
          </CustomizedTooltip>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "create");
            }}
            label={t("button.create")}
            checked={type === "create"}
            disabled={updateOnly}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "update");
            }}
            label={t("button.edit")}
            checked={type === "update"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      <ImporterDropzoneUI hasLabelHeader />
    </Fragment>
  );
};

export default UploadFile;
