import { useFormContext, useWatch } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useDisable } from "../../../../hooks/use-disable";
import ControlledTextField from "../../../Controller/ControlledTextField";
import DocumentDropzoneUI from "../../../UI/DocumentDropzoneUI";

type Props = {
  isModal?: boolean;
};

const AcceptQuotationForm = ({ isModal }: Props) => {
  const [disabled] = useDisable();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <>
      {!isModal && <Typography fontWeight="bold">ยอมรับใบเสนอราคา</Typography>}
      <Grid container spacing={1.5} alignItems="center" mt={0}>
        <Grid item md={isModal ? 4 : 2}>
          <ControlledDatePicker
            label="ยอมรับเมื่อ"
            name="accepted_date"
            control={control}
            disabled={disabled && status !== "wait_accept"}
            viewMode={disabled && status !== "wait_accept"}
          />
        </Grid>
      </Grid>
      <Typography fontWeight="bold" mt={2}>
        หลักฐานอ้างอิง
      </Typography>
      {isModal ? (
        <ControlledTextField
          name="accepted_remark"
          control={control}
          error={Boolean(errors.accepted_remark)}
          disabled={disabled && status !== "wait_accept"}
          sx={{ mt: 2 }}
          multiline
          rows={4}
          viewMode={disabled && status !== "wait_accept"}
        />
      ) : (
        <Grid container spacing={1.5} alignItems="center" mt={0}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ControlledTextField
              name="accepted_remark"
              control={control}
              error={Boolean(errors.accepted_remark)}
              disabled={disabled && status !== "wait_accept"}
              viewMode={disabled && status !== "wait_accept"}
              sx={{ my: 2 }}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      )}
      <DocumentDropzoneUI
        control={control}
        name="attachment_list"
        setValue={setValue}
        disabled={disabled && status !== "wait_accept"}
        isModal={isModal}
        multiple
      />
    </>
  );
};

export default AcceptQuotationForm;
