import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISalesItemList } from "../../types/Sales";
import { ITEM_SKU_DETAIL_AGGRID } from "../../services/AgGrid/InventoryAggrid";

export const useSalesItemListFormatter = (item_list: ISalesItemList[]) => {
  const [formatItemList, setFormatItemList] = useState<ISalesItemList[]>([]);

  const getUomName = useCallback(async () => {
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("wms");
    if (item_list && item_list.length > 0) {
      const allItemListSkuName = item_list.map((item) => item?.item_sku_name);

      const { itemSkuDetailsFindManyAggrid } =
        await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: allItemListSkuName.length,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: allItemListSkuName,
              },
            },
          },
        });

      let formatItemList: ISalesItemList[] = [];

      const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;
      item_list.forEach((item) => {
        const foundItemIndex = itemSkuDetails.findIndex(
          (realItem: any) => realItem.sku_name === item?.item_sku_name
        );
        formatItemList.push({
          ...item,
          uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
          item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
        });
      });
      return formatItemList;
    } else {
      return [];
    }
  }, [item_list]);

  useEffect(() => {
    const fetcher = async () => {
      const formatWithUom = await getUomName();
      setFormatItemList(formatWithUom);
    };
    fetcher();
  }, [getUomName]);

  return formatItemList;
};
