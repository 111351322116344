import { styled } from "@mui/system";
import { Box, BoxProps, Grid, Typography } from "@mui/material";
import { numberFormatter } from "../../../utils/Formatter/Global";
import { AdditionalDiscountType } from "../../../generated/inventory";

const CustomizedSummary = styled(Box)<BoxProps>(({ bgcolor, paddingY }) => ({
  "&": {
    backgroundColor: bgcolor,
    display: "grid",
    justifyContent: "flex-end",
    textAlign: "right",
    gridGap: "10px",
    gap: "10px",
    gridTemplateColumns:
      "minmax(min-content,max-content) minmax(7rem,max-content) 24px",
    paddingY: paddingY,
    paddingRight: "24px",
  },
}));

interface Props {
  data: any;
  documentType?: string;
}

const PurchasePdfFooter = ({ data, documentType }: Props) => {
  const fontSize = 10.66;
  if (documentType !== "purchase_request")
    return (
      <Grid container spacing={1.5} sx={{ breakInside: "avoid" }}>
        <Grid item xs={7}>
          <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
            หมายเหตุ
          </Typography>
          <Typography
            fontSize={fontSize}
            sx={{
              whiteSpace: "pre-line",
              wordBreak: "break-word",
            }}
            lineHeight="11.96px"
          >
            {data.remark || "-"}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          {data.additional_discount > 0 && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                ส่วนลดเพิ่มเติม
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.additional_discount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {data.additional_discount_type ===
                AdditionalDiscountType.Percent
                  ? "%"
                  : "บาท"}
              </Typography>
            </CustomizedSummary>
          )}
          {data.additional_discount_type === AdditionalDiscountType.Percent && (
            <CustomizedSummary>
              <Box />
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(
                  data.pre_vat_amount * (data.additional_discount / 100)
                )}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          <CustomizedSummary>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              lineHeight={2}
            >
              มูลค่าหลังหักส่วนลด
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              lineHeight={2}
            >
              {numberFormatter(data.post_discount_amount)}
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              lineHeight={2}
            >
              บาท
            </Typography>
          </CustomizedSummary>

          {data.vat_exempted_amount > 0 && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่าฐานยกเว้นภาษี
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_exempted_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {data.vat_0_percent_amount > 0 && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่าฐานภาษี 0%
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_0_percent_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {data.vat_7_percent_amount > 0 && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่าฐานภาษี 7%
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_7_percent_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          <CustomizedSummary>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              lineHeight={2}
            >
              ภาษีมูลค่าเพิ่ม
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              lineHeight={2}
            >
              {numberFormatter(data.vat_amount)}
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              lineHeight={2}
            >
              บาท
            </Typography>
          </CustomizedSummary>

          <CustomizedSummary
            bgcolor={(theme) => theme.palette.primary.light}
            paddingY={0.5}
          >
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
            >
              มูลค่ารวมสุทธิ
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
            >
              {numberFormatter(data.net_amount)}
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
            >
              บาท
            </Typography>
          </CustomizedSummary>
          {/* {data.withholding_tax_amount > 0 && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                ภาษีหัก ณ ที่จ่าย
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.withholding_tax_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          <CustomizedSummary>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
              lineHeight={2}
            >
              จำนวนเงินชำระทั้งสิ้น
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
              lineHeight={2}
            >
              {numberFormatter(data.total_amount)}
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
              lineHeight={2}
            >
              บาท
            </Typography>
          </CustomizedSummary> */}
        </Grid>
      </Grid>
    );
  else
    return (
      <Grid container spacing={1.5} sx={{ breakInside: "avoid" }}>
        <Grid item xs={12}>
          <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
            หมายเหตุ
          </Typography>
          <Typography
            fontSize={fontSize}
            sx={{
              whiteSpace: "pre-line",
              wordBreak: "break-word",
            }}
            lineHeight="11.96px"
          >
            {data.remark || "-"}
          </Typography>
        </Grid>
      </Grid>
    );
};

export default PurchasePdfFooter;
