import { UseFormSetValue } from "react-hook-form";
import { IPriceList } from "../../../types/Inventory/PriceList";
import { ISalesItemList } from "../../../types/Sales";
import { IPurchaseItemList } from "../../../types/Purchase";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { SALES_ITEMS } from "../../../services/AgGrid/SalesAgGrid";
import { SalesReferenceDocumentType } from "../../../generated/sales";

export const priceListEnumerator = (
  priceList: IPriceList,
  currentItemList: ISalesItemList[] | IPurchaseItemList[],
  setValue: UseFormSetValue<any>
) => {
  if (currentItemList && currentItemList.length > 0) {
    const { type, is_increase, adjusted_price, adjusted_item_list } = priceList;
    if (type === "all") {
      currentItemList.forEach((item, index) => {
        let adjustedValue: number = 0;
        const ratio = (item.price_per_unit || 0) * (adjusted_price / 100);
        if (is_increase) {
          adjustedValue = (item.price_per_unit || 0) + ratio;
        } else {
          adjustedValue = (item.price_per_unit || 0) - ratio;
        }
        setValue(`item_list.${index}.price_per_unit`, adjustedValue);
      });
    } else {
      currentItemList.forEach((item, index) => {
        const matchItem =
          adjusted_item_list &&
          adjusted_item_list.length > 0 &&
          adjusted_item_list.find(
            (adjustedItem) => adjustedItem.sku_name === item.item_unique_id
          );
        if (matchItem) {
          setValue(
            `item_list.${index}.price_per_unit`,
            matchItem.adjusted_price
          );
        }
      });
    }
  }
};

export const priceListInItem = (
  priceList: IPriceList | undefined,
  item: ISalesItemList
): ISalesItemList => {
  if (priceList) {
    const { type, is_increase, adjusted_price, adjusted_item_list } = priceList;
    if (type === "all") {
      let adjustedValue: number = 0;
      const ratio = item.price_per_unit * (adjusted_price / 100);
      if (is_increase) {
        adjustedValue = item.price_per_unit + ratio;
      } else {
        adjustedValue = item.price_per_unit - ratio;
      }
      return {
        ...item,
        price_per_unit: adjustedValue,
      };
    } else {
      const matchItem =
        adjusted_item_list &&
        adjusted_item_list.length > 0 &&
        adjusted_item_list.find(
          (adjustedItem) => adjustedItem.sku_name === item.item_unique_id
        );
      if (matchItem) {
        return {
          ...item,
          price_per_unit: matchItem.adjusted_price,
        };
      } else {
        return item;
      }
    }
  } else {
    return item;
  }
};

export const priceListInItemPurchase = (
  priceList: IPriceList | undefined,
  item: IPurchaseItemList
): IPurchaseItemList => {
  if (priceList) {
    const { type, is_increase, adjusted_price, adjusted_item_list } = priceList;
    if (type === "all") {
      let adjustedValue: number = 0;
      const ratio = item.price_per_unit || 0 * (adjusted_price / 100);
      if (is_increase) {
        adjustedValue = item.price_per_unit || 0 + ratio;
      } else {
        adjustedValue = item.price_per_unit || 0 - ratio;
      }
      return {
        ...item,
        price_per_unit: adjustedValue,
      };
    } else {
      const matchItem =
        adjusted_item_list &&
        adjusted_item_list.length > 0 &&
        adjusted_item_list.find(
          (adjustedItem) => adjustedItem.sku_name === item.item_unique_id
        );
      if (matchItem) {
        return {
          ...item,
          price_per_unit: matchItem.adjusted_price,
        };
      } else {
        return item;
      }
    }
  } else {
    return item;
  }
};

export const validateSalesItemQty = async (
  salesItemList: ISalesItemList[],
  documentType: SalesReferenceDocumentType
) => {
  let uniqueIdList: string[] = [];

  salesItemList.forEach((item) => {
    uniqueIdList.push(item.reference_line_item?.line_item_unique_id || "");
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");
  const { SalesItemFindMany: foundItemList } =
    await graphQLClientWithHeaderItem.request(SALES_ITEMS, {
      input: {
        unique_id_list: uniqueIdList,
      },
    });

  let checkQty: boolean[] = [];

  salesItemList.forEach((item: ISalesItemList) => {
    const foundItem: ISalesItemList = foundItemList.find(
      (foundItem: any) =>
        foundItem.unique_id === item?.reference_line_item?.line_item_unique_id
    );

    if (foundItem) {
      switch (documentType) {
        case SalesReferenceDocumentType.CashSales:
        case SalesReferenceDocumentType.SalesInvoice:
          if (foundItem.qty_invoiced) {
            if (item.qty <= foundItem.qty - foundItem.qty_invoiced) {
              checkQty.push(true);
            } else {
              checkQty.push(false);
            }
          }
          break;
        case SalesReferenceDocumentType.SalesReturn:
          if (foundItem.qty_returned) {
            if (item.qty <= foundItem.qty - foundItem.qty_returned) {
              checkQty.push(true);
            } else {
              checkQty.push(false);
            }
          }
          break;
        default:
          break;
      }
    }
  });

  return checkQty.every((qty) => qty);
};
