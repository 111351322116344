import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";

const Contact = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.contact_type.customer"),
      to: "/contact/customer",
    },
    {
      name: t("contact.contact_type.vendor"),
      to: "/contact/vendor",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Contact;
