import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Date";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import {
  numberFormatter,
  statusValueFormatter,
} from "../../../../utils/Formatter/Global";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { useTranslation } from "react-i18next";
import { useSalesAgGrid } from "../../../../hooks/Sales/use-sales-aggrid";
import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";

export const useCashSalesColumnDefs = (cdUniqueId?: string) => {
  console.log("cdUniqueId", cdUniqueId);
  const { t } = useTranslation();
  const [columnDef, setColumnDef] = useState<ColDef[]>();

  const {
    branchFilterPararms,
    salesChannelFilterParams,
    salesTypeFilterParams,
    salesUserFilterParams,
    salesTagList,
  } = useSalesAgGrid("cash_sales");

  useEffect(() => {
    const defaultColumns: ColDef[] = [
      {
        field: "unique_id",
        headerName: t("sales.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_date",
        headerName: t("date.created_date"),
        sort: "desc",
        hide: true,
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        suppressColumnsToolPanel: true,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "issue_date",
        headerName: t("date.issue_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "branch_id",
        hide: true,
        suppressColumnsToolPanel: true,
        filter: "agNumberColumnFilter",
      },
      {
        field: "branch_unique_id",
        headerName: t("branch.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "branch_name",
        headerName: t("branch.index"),
        minWidth: 200,
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams: branchFilterPararms,
      },
      {
        field: "status",
        headerName: t("status.index"),
        sortable: false,
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        minWidth: 200,
        flex: 1,
        filterParams: {
          values: ["draft", "finished", "cancelled"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
      {
        field: "customer_unique_id",
        headerName: t("sales.customer_unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "customer_name",
        headerName: t("sales.customer_name"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "sales_type",
        headerName: t("sales.sales_type"),
        minWidth: 200,
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams: salesTypeFilterParams,
      },
      {
        field: "sales_channel",
        headerName: t("sales.sales_channel"),
        minWidth: 200,
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams: salesChannelFilterParams,
      },
      {
        field: "tag_list",
        headerName: t("sales.tag_list"),
        minWidth: 200,
        flex: 1,
        filter: "agSetColumnFilter",
        filterParams: salesTagList,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value)
            return params.value?.map((tag: string) => (
              <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                <CustomizedChips value={`${tag}`} />
              </Box>
            ));
        },
      },
      {
        field: "tax_invoice_no",
        headerName: t("sales.tax_number"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "net_amount",
        headerName: t("sales.net_amount"),
        minWidth: 200,
        flex: 1,
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
      },
      {
        field: "created_by_object",
        headerName: t("sales.created_by"),
        filter: "agSetColumnFilter",
        filterParams: salesUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            return <CustomizedAvatar avatars={[params.value]} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "related_user_object_list",
        headerName: t("sales.employee_list"),
        filter: "agSetColumnFilter",
        filterParams: salesUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value && params.value.length > 0) {
            return <CustomizedAvatar avatars={params.value} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "reference_unique_id",
        hide: true,
        suppressColumnsToolPanel: true,
        filter: "agTextColumnFilter",
      },
    ];

    if (cdUniqueId) {
      const newColumnDefs = [...defaultColumns];
      newColumnDefs.splice(
        14,
        0,
        {
          field: "cd_last_deduction_date",
          headerName: "วันที่ทำรายการล่าสุด",
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "cd_deduction_total_amount",
          headerName: "มูลค่าตัดชำระรวม",
          minWidth: 200,
          flex: 1,
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) return numberFormatter(params.value);
            else return "-";
          },
        }
      );
      setColumnDef(newColumnDefs);
    } else {
      setColumnDef(defaultColumns);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdUniqueId, t]);
  return columnDef;
};
