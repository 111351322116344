import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider } from "@mui/material";

import { formatDate } from "../../../../utils/Date";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GoodsTransferQuery,
  useGoodsTransferQuery,
} from "../../../../generated/inventory";

import PDFLayout from "../../../../components/UI/Pdf/PDFLayout";
import PDFInfo from "../../../../components/UI/Pdf/PDFInfo";
import GoodsTransferPdfTable from "../../../../components/Table/Pdf/GoodsTransfer/GoodsTransferPdfTable";
import { goodsTransferQueryFormatter } from "../../../../utils/Formatter/Inventory/GoodsTransfer";
import { IGoodsTransfer } from "../../../../types/Inventory/GoodsTransfer";

const GoodsTransferPdf = () => {
  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading } = useGoodsTransferQuery<GoodsTransferQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const leftHeader = [
    "เลขที่เอกสาร",
    "สาขาต้นทาง",
    "คลังต้นทาง",
    "สาขาปลายทาง",
    "คลังปลายทาง",
  ];

  const leftInfo = [
    data?.GoodsTransfer?.unique_id || "-",
    data?.GoodsTransfer?.source_branch.name || "-",
    data?.GoodsTransfer?.source_warehouse?.name || "-",
    data?.GoodsTransfer?.destination_branch.name || "-",
    data?.GoodsTransfer?.destination_warehouse?.name || "-",
  ];

  const rightHeader = ["วันที่สร้าง", "วันที่โอนย้าย", "หมายเหตุ"];

  const rightInfo = [
    data?.GoodsTransfer?.created_date
      ? formatDate(data?.GoodsTransfer?.created_date)
      : "-",
    data?.GoodsTransfer?.posted_date
      ? formatDate(data?.GoodsTransfer?.posted_date)
      : "-",
    data?.GoodsTransfer?.remark ?? "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบโอนย้าย / Goods Transfer"
      documentType="GT"
      data={data?.GoodsTransfer}
      isApprove
      horizontal
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <GoodsTransferPdfTable
        itemList={
          data?.GoodsTransfer
            ? goodsTransferQueryFormatter(data.GoodsTransfer as IGoodsTransfer)
                .trace_entry_list
            : []
        }
      />
    </PDFLayout>
  );
};

export default GoodsTransferPdf;
