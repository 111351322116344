import { Grid } from "@mui/material";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { ISelectOption } from "../../../../types/global";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useDisable } from "../../../../hooks/use-disable";
import LabelInput from "../../../UI/LabelInput";
import { ICashSales } from "../../../../types/Sales/cashSales";
import { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { CUSTOMER_DEPOSIT_AGGRID } from "../../../../services/AgGrid/SalesAgGrid";
import { ICustomerDeposit } from "../../../../types/Sales/customerDeposit";

type Props = {
  index: number;
  removeDeductHandler: (index: number) => void;
  type: string;
};

const deductionOptions: ISelectOption[] = [
  {
    label: "เอกสารภายนอก",
    value: "external_document",
  },
  {
    label: "ใบมัดจำ",
    value: "customer_deposit",
  },
];

const DeductionInfo = ({ index, type }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ICashSales>();

  const [customerDepositList, setCustomerDepositList] = useState<any[]>([]);

  const documentType = useWatch({
    control,
    name: `deduction_document_list.${index}.document_type`,
  });

  const issueDate = useWatch({
    control,
    name: "issue_date",
  });

  const watchCustomerId = useWatch({
    control,
    name: "customer_unique_id",
  });

  const [disabled] = useDisable();

  useEffect(() => {
    const getCdUnqiueId = async () => {
      const graphQLClientWithHeaderSales: GraphQLClient =
        createGraphQLClientWithMiddleware("sales");
      const { CustomerDepositViewsAggrid } =
        await graphQLClientWithHeaderSales.request(CUSTOMER_DEPOSIT_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: Math.pow(10, 5),
            filterModel: {
              status: {
                values: ["partially_deduction", "wait_deduction"],
                filterType: "set",
              },
              customer_unique_id: {
                filterType: "text",
                type: "contains",
                filter: watchCustomerId || "",
              },
            },
            sortModel: [
              {
                sort: "desc",
                colId: "created_date",
              },
            ],
          },
        });

      const formatUniqueIdOptions = CustomerDepositViewsAggrid.results.map(
        (cd: ICustomerDeposit) => ({
          label: cd.unique_id,
          value: cd.unique_id,
          remaining_deposit_amount: cd.remaining_deposit_amount,
        })
      );
      setCustomerDepositList(formatUniqueIdOptions);
    };

    getCdUnqiueId();
  }, [watchCustomerId]);

  useEffect(() => {
    setValue(
      `deduction_document_list.${index}.deduction_issue_date`,
      issueDate
    );
  }, [index, issueDate, setValue]);

  return (
    <>
      {/* {!disabled && (
        <Box display={"flex"} justifyContent={"flex-end"} mb={1}>
          <IconButton size={"small"} onClick={() => removeDeductHandler(index)}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      )} */}
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6}>
          {disabled ? (
            <LabelInput
              label={t("sales.deduction.document_type")}
              value={
                deductionOptions.find(
                  (deduct) =>
                    deduct.value ===
                    getValues(`deduction_document_list.${index}.document_type`)
                )?.label
              }
            />
          ) : (
            <ControlledSelect
              control={control}
              name={`deduction_document_list.${index}.document_type`}
              label={t("sales.deduction.document_type")}
              options={deductionOptions}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  setValue(
                    `deduction_document_list.${index}.document_unique_id`,
                    ""
                  );
                  setValue(
                    `deduction_document_list.${index}.credit_balance`,
                    0
                  );
                }
              }}
              required
              error={Boolean(
                errors?.deduction_document_list &&
                  errors?.deduction_document_list[index] &&
                  errors?.deduction_document_list[index]?.document_type
              )}
              helperText={
                errors?.deduction_document_list &&
                errors?.deduction_document_list[index]?.document_type?.message
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {documentType === "customer_deposit" ? (
            <>
              {disabled ? (
                <LabelInput
                  label={t("sales.deduction.document_unique_id")}
                  value={getValues(
                    `deduction_document_list.${index}.document_unique_id`
                  )}
                />
              ) : (
                <ControlledSelect
                  control={control}
                  name={`deduction_document_list.${index}.document_unique_id`}
                  label={t("sales.deduction.document_unique_id")}
                  options={customerDepositList}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    if (value) {
                      const foundCustomerDeposit = customerDepositList.find(
                        (cd) => cd.label === value
                      );
                      setValue(
                        `deduction_document_list.${index}.credit_balance`,
                        foundCustomerDeposit?.remaining_deposit_amount || 0
                      );
                    }
                  }}
                  required
                  error={Boolean(
                    errors?.deduction_document_list &&
                      errors?.deduction_document_list[index] &&
                      errors?.deduction_document_list[index]?.document_unique_id
                  )}
                  helperText={
                    errors?.deduction_document_list &&
                    errors?.deduction_document_list[index]?.document_unique_id
                      ?.message
                  }
                />
              )}
            </>
          ) : (
            <ControlledTextField
              control={control}
              name={`deduction_document_list.${index}.document_unique_id`}
              label={t("sales.deduction.document_unique_id")}
              viewMode={disabled}
              required
              error={Boolean(
                errors?.deduction_document_list &&
                  errors?.deduction_document_list[index] &&
                  errors?.deduction_document_list[index]?.document_unique_id
              )}
              helperText={
                errors?.deduction_document_list &&
                errors?.deduction_document_list[index]?.document_unique_id
                  ?.message
              }
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ControlledNumberTextField
            textAlign="right"
            fullWidth
            control={control}
            name={`deduction_document_list.${index}.deduction_amount`}
            label={t("sales.deduction.deduction_amount")}
            viewMode={disabled}
            error={Boolean(
              errors?.deduction_document_list &&
                errors?.deduction_document_list[index] &&
                errors?.deduction_document_list[index]?.deduction_amount
            )}
            helperText={
              errors?.deduction_document_list &&
              errors?.deduction_document_list[index]?.deduction_amount?.message
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledNumberTextField
            textAlign="right"
            fullWidth
            control={control}
            name={`deduction_document_list.${index}.credit_balance`}
            label={t("sales.deduction.credit_balance")}
            viewMode={disabled}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ControlledDatePicker
            control={control}
            name={`deduction_document_list.${index}.deduction_issue_date`}
            label={t("sales.deduction.deduction_issue_date")}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name={`deduction_document_list.${index}.remark`}
            multiline
            minRows={1}
            maxRows={5}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DeductionInfo;
