import { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import DocumentInfoTab from "./DocumentInfoTab";
import PurchaseOrderTab from "./PurchaseOrderTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import BreadcrumbsLayout from "../../../components/UI/BreadcrumbsLayout";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import {
  purchaseRequestSchema,
  purchaseRequestValidation,
} from "../../../components/Form/Purchase/Request/schema";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { ActivityLogDocumentType } from "../../../generated/inventory";
import { IPurchaseRequest } from "../../../types/Purchase/purchaseRequest";
import {
  PurchaseRequestFindUniqueQuery,
  usePurchaseRequestFindUniqueQuery,
} from "../../../generated/purchase";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const PurchaseRequestContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const methods = useForm<IPurchaseRequest>({
    defaultValues: { ...purchaseRequestSchema },
    resolver: yupResolver<any>(purchaseRequestValidation),
  });

  const { control } = methods;

  const { data, isLoading } =
    usePurchaseRequestFindUniqueQuery<PurchaseRequestFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    {
      label: t("purchase.order.index"),
      path: `${pathname}?tab=purchase_order`,
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "purchase_order":
        return (
          <PurchaseOrderTab
            referenceUniqueId={data?.PurchaseRequestFindUnique.unique_id}
            isLoading={isLoading}
          />
        );
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.request.index"),
      to: "/purchase/request",
    },
    {
      name: id
        ? data?.PurchaseRequestFindUnique.unique_id || "-"
        : t("button.create") + t("purchase.request.index"),
    },
  ];

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        setOpenDrawer={setOpenDrawer}
      />
      {(tab === "purchase_order" ||
        status === "approved" ||
        status === "partially_ordered" ||
        status === "fully_ordered") && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLogSlideInPanel
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          documentId={id!}
          documentType={ActivityLogDocumentType.PurchaseRequest}
        />
      )}
    </FormProvider>
  );
};

export default PurchaseRequestContainer;
