import { Theme } from "@mui/material";
import { styled } from "@mui/system";
import { AppBar } from "@mui/material";

interface Props {
  theme: Theme;
  open?: boolean;
}

const DashboardBottom = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: Props) => ({
  top: "auto",
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  borderTopColor: theme.palette.divider,
  borderTopStyle: "solid",
  borderTopWidth: 1,
  borderLeftColor: theme.palette.divider,
  borderLeftStyle: "solid",
  borderLeftWidth: 1,
  boxShadow: "none",
  width: "100%",
  zIndex: 3,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - 259px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default DashboardBottom;
