import { Grid, Box, Typography } from "@mui/material";

interface Props {
  leftHeader: string[];
  leftInfo: string[];
  rightHeader: string[];
  rightInfo: string[];
}

const PDFInfo = ({ leftHeader, leftInfo, rightHeader, rightInfo }: Props) => {
  return (
    <Grid container my={1}>
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={6}>
            {leftHeader.map((name, index) => (
              <Box sx={{ display: "flex", gap: 2 }} key={index}>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    fontWeight: 600,
                    color: "#333333",
                    flex: 1,
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    flex: 1,
                    width: "100%",
                    wordBreak: "break-word",
                  }}
                >
                  {leftInfo[index]}
                </Typography>
              </Box>
            ))}
          </Grid>
          <Grid item xs={6}>
            {rightHeader.map((name, index) => (
              <Box sx={{ display: "flex", gap: 2 }} key={index}>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    fontWeight: 600,
                    color: "#333333",
                    flex: 1,
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    flex: 1,
                    width: "100%",
                    wordBreak: "break-word",
                  }}
                >
                  {rightInfo[index]}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}></Grid>
    </Grid>
  );
};

export default PDFInfo;
