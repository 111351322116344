import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { IDefaultForm } from "../../../types/global";

interface Props {
  serialModal: boolean;
  closeSerialModalHandler: () => void;
  addSerialsHandler: () => void;
  control: IDefaultForm["control"];
  reset: IDefaultForm["reset"];
}

const SerialModal = ({
  serialModal,
  closeSerialModalHandler,
  addSerialsHandler,
  control,
  reset,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalUI
      title="เพิ่ม LOT/SN"
      open={serialModal}
      handleClose={closeSerialModalHandler}
      maxWidth="sm"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={() => {
              if (reset) {
                reset();
              }
              closeSerialModalHandler();
            }}
          />
          <CustomizedButton
            title={t("button.add")}
            onClick={addSerialsHandler}
            variant="contained"
            size="medium"
          />
        </Box>
      }
    >
      <Grid container spacing={1.5} mt={1}>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("inventory.goods_receive.serial.serial_quantity")}
            control={control}
            name="serial_quantity"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("inventory.goods_receive.serial.quantity_per_serial")}
            control={control}
            name="quantity_per_serial"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name="remark"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} />
        <Grid item xs={12} sm={12} md={6}>
          <ControlledDatePicker label="LOT" control={control} name="lot_date" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} />
        <Grid item xs={12} sm={12} md={6}>
          <ControlledDatePicker
            label={t("date.manufactured_date")}
            control={control}
            name="manufactured_date"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledDatePicker
            label={t("date.expired_date")}
            control={control}
            name="expired_date"
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default SerialModal;
