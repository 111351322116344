import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import AgGrid from "../../../UI/AgGrid";

import { usePriceListImporterColumnDefs } from "../../../../hooks/Importer/Inventory/use-importer-column-defs";

type Props = {
  data: any[];
  type?: string;
};

const PriceListImporterTable = ({ data, type }: Props) => {
  const gridRef = useRef<AgGridReact>(null);

  const columnDefs: ColDef[] = usePriceListImporterColumnDefs(type);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      rowData={data}
      disabledSidebar
    />
  );
};

export default PriceListImporterTable;
