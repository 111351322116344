import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ISalesPaymentChannel } from "../../../../types/Sales";
import { useSalesPaymentColumnDefs } from "./columnDefs";
import { formatDate } from "../../../../utils/Date";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import CustomizedScrollbar from "../../../Custom/CustomizedScrollbar";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationModal from "../../../UI/Modal/ConfirmationModal";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { useState } from "react";

type Props = {
  data: ISalesPaymentChannel[];
  documentType: string;
  onDeleteHandler?: (id?: number) => void;
};

const SalesPaymentTable = ({ data, documentType, onDeleteHandler }: Props) => {
  const [deletingId, setDeleingId] = useState<number>();
  const headers = useSalesPaymentColumnDefs(documentType);
  const paddingTable = {
    px: 1.5,
    py: 1.5,
  };

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(() => {
    if (onDeleteHandler) onDeleteHandler(deletingId);
  });

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <CustomizedScrollbar
          sx={{ width: "100%", py: 1 }}
          forceVisible="y"
          autoHide={false}
        >
          <TableHead sx={{ bgcolor: (theme) => theme.palette.primary.light }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header.thaiLabel}
                  align={header.align}
                  sx={paddingTable}
                  width={header.width}
                >
                  <Typography fontWeight={600} width={header.width}>
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((payment, index) => (
              <TableRow
                key={`${payment.payment_channel_name}-${payment.payment_amount}-${index}`}
              >
                <TableCell align={headers[0]?.align}>
                  <Typography width={headers[0]?.width}>
                    {formatDate(payment.payment_date)}
                  </Typography>
                </TableCell>
                <TableCell align={headers[1]?.align}>
                  <Typography width={headers[1]?.width}>
                    {payment.payment_channel_payment_name}
                  </Typography>
                </TableCell>
                <TableCell align={headers[2]?.align}>
                  <Typography width={headers[2]?.width}>
                    {payment.payment_channel_unique_id} -{" "}
                    {payment.payment_channel_name}
                  </Typography>
                </TableCell>
                <TableCell align={headers[3]?.align}>
                  <Typography width={headers[3]?.width}>
                    {numberFormatter(payment.payment_amount)}
                  </Typography>
                </TableCell>
                <TableCell align={headers[4]?.align}>
                  <Typography width={headers[4]?.width}>
                    {numberFormatter(payment.withholding_tax_amount)}
                  </Typography>
                </TableCell>
                <TableCell align={headers[5]?.align}>
                  <Typography width={headers[5]?.width}>
                    {payment.cheque_provider || "-"}
                  </Typography>
                </TableCell>
                <TableCell align={headers[6]?.align}>
                  <Typography width={headers[6]?.width}>
                    {payment.cheque_no || "-"}
                  </Typography>
                </TableCell>
                <TableCell align={headers[7]?.align}>
                  <Typography width={headers[7]?.width}>
                    {payment.cheque_date
                      ? formatDate(payment.cheque_date)
                      : "-"}
                  </Typography>
                </TableCell>
                <TableCell align={headers[8]?.align}>
                  <Typography width={headers[8]?.width}>
                    {payment.remark || "-"}
                  </Typography>
                </TableCell>
                {documentType === "sales_invoice" && (
                  <TableCell align={headers[9]?.align}>
                    <IconButton
                      onClick={() => {
                        openCancelConfirmation();
                        setDeleingId(payment.id);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {data.length === 0 && (
              <TableRow sx={{ position: "relative", height: 50 }}>
                <Typography
                  sx={{
                    position: "sticky",
                    top: "70%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  ไม่มีข้อมูล
                </Typography>
              </TableRow>
            )}
          </TableBody>
        </CustomizedScrollbar>
      </Table>
      <ConfirmationModal
        title="ยกเลิกการรับชำระ"
        message="หากทำการยกเลิกจะไม่สามารถย้อนกลับได้"
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
    </TableContainer>
  );
};

export default SalesPaymentTable;
