import { Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  UseFieldArrayAppend,
  UseFieldArrayReplace,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";
import { useCallback, useEffect, useState } from "react";
import { ISelectOption } from "../../../../types/global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";
import { IGoodsReturn } from "../../../../types/Inventory/GoodsReturn";
import LabelInput from "../../../UI/LabelInput";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  InventoryDocumentType,
  Tracability,
  WmsModelType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "../../../../generated/inventory";
import { useParams } from "react-router-dom";
import { ITraceEntry } from "../../../../types/Inventory";
import {
  branchInfoFromSessionStorage,
  checkIsNotDraftOrEmptyStatus,
} from "../../../../utils/Global";
import { goodsReturnTypeValueFormatter } from "../../../../utils/Formatter/Inventory/GoodsReturn";

const typeOptions: ISelectOption[] = [
  {
    label: "รับคืนจากลูกค้า",
    value: "sales_return",
  },
];

interface Props {
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsReturn, "trace_entry_list">;
}

const referenceOptions: ISelectOption[] = [
  {
    label: "SR2312040001",
    value: "SR2312040001",
  },
];

const valueToAppend: ITraceEntry[] = [
  {
    sku_name: "P00023",
    unique_id: "ec3a69da-4cb7-4fcc-8faf-523d3b3ce344",
    type: InventoryDocumentType.GoodsReceive,
    item_unique_id: "P00023",
    item_img_url: [],
    item_name: "RE-ITEM01 (DO NOT DELETE)",
    document_item_qty: 100,
    qty: 0,
    uom: {
      id: 1,
      name_th: "เมตร",
      unique_id: "เมตร",
    },
    remark: "",
    tracability: Tracability.Serial,
    barcode: "P00023#2#GR2312040003#00001#100",
    status: "is_active",
    serial_no: "2#GR2312040003#00001",
    is_stockable: true,
  },
  {
    sku_name: "P00024",
    unique_id: "f7138354-709d-4e7a-adac-409d4c08da18",
    type: InventoryDocumentType.GoodsReceive,
    item_unique_id: "P00024",
    item_img_url: [],
    item_name: "RE-ITEM02 (DO NOT DELETE)",
    qty: 0,
    document_item_qty: 100,
    uom: {
      id: 1,
      name_th: "เมตร",
      unique_id: "เมตร",
    },
    tracability: Tracability.Normal,
    barcode: "P00024",
    status: "is_active",
    is_stockable: true,
  },
];

const GoodsReturnInfo = ({ append, replace }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    resetField,
  } = useFormContext();
  const [disabled] = useDisable();
  const [currentType, setCurrentType] = useState<string>("");

  const { branch_id, is_hq } = branchInfoFromSessionStorage();
  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: WmsModelType.GoodsReturn,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  // const [currentReference, setCurrentReference] = useState<string>("");
  // const [referenceUniqueIdOptions, setReferenceUniqueIdOptions] = useState<
  //   ISelectOption[]
  // >([]);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { branchesOptions, warehousesOptions } =
    useInventoryControl(watchBranchId);

  // const resetTraceEntry = () => {
  //   reset((prev: IGoodsReturn) => ({
  //     ...prev,
  //     trace_entry_list: [],
  //   }));
  // };

  const onTypeChangeHandler = () => {
    replace([]);
    setValue("type", currentType);
    setValue("reference_unique_id", "");
  };

  // const { fields, append, remove } = useFieldArray({
  //   name: "reference_unique_id_list",
  //   control,
  // });

  // const addReferenceUniqueIdHandler = () => {
  //   append("");
  // };

  // const removeReferenceUniqueIdHandler = (index: number) => {
  //   remove(index);
  // };

  const notDraft = checkIsNotDraftOrEmptyStatus(status);

  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="unique_id"
            control={control}
            label={t(`sentence.unique_id`)}
            error={Boolean(errors.unique_id)}
            helperText={errors.unique_id?.message?.toString()}
            disabled={Boolean(id)}
            required
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={8} lg={8} xl={8}>
          {!Boolean(id) && (
            <CustomizedTooltip
              title={`เรียก${t(`sentence.unique_id`)}`}
              enterNextDelay={200}
            >
              <IconButton onClick={generateUniqueId} color="primary">
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("sentence.type")}
              value={goodsReturnTypeValueFormatter(getValues("type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="type"
              label={t("sentence.type")}
              options={typeOptions}
              onChange={(e: any) => {
                setCurrentType(e.target.value);
                const currentTrace = getValues("trace_entry_list");
                if (currentTrace.length > 0) {
                  onTypeChangeHandler();
                }
              }}
              disabled={notDraft}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("branch.index")}
              value={getValues("branch.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="branch_id"
              label={t("branch.index")}
              options={
                is_hq === "true"
                  ? branchesOptions
                  : branchesOptions.filter(
                      (branch) => branch.value === parseInt(branch_id ?? "0")
                    )
              }
              disabled={notDraft}
              onChange={() => {
                resetField("warehouse_unique_id");
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.warehouse")}
              value={getValues("warehouse.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="warehouse_id"
              label={t("inventory.warehouse")}
              options={warehousesOptions}
              disabled={notDraft || warehousesOptions.length === 0}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("sentence.reference_unique_id")}
              value={getValues("reference_unique_id")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="reference_unique_id"
              label={t("sentence.reference_unique_id")}
              options={referenceOptions}
              disabled={notDraft || !watchType || watchType === "other"}
              onChange={() => {
                valueToAppend.forEach((value) => {
                  append(value);
                });
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            label={t("inventory.remark")}
            viewMode={disabled}
            error={Boolean(errors?.remark)}
            helperText={errors.remark?.message?.toString()}
            required
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default GoodsReturnInfo;
