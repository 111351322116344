import CashSalesTable from "../../../components/Table/Sales/CashSales";

type Props = {
  referenceUniqueId?: string;
};

const CashSalesTab = ({ referenceUniqueId }: Props) => {
  return <CashSalesTable referenceUniqueId={referenceUniqueId} />;
};

export default CashSalesTab;
