import { Box, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { ICreatedBy, IReviewedBy } from "../../../types/global";
import SignerBox from "../SignerBox";

type Props = {
  approver_list: any[];
  created_by: ICreatedBy;
  issue_date: Date;
  reviewer_list: IReviewedBy[];
  status?: string;
  salePerson?: string;
  documentType: string;
};

const SalesPdfSigner = ({
  created_by,
  issue_date,
  approver_list,
  reviewer_list,
  status,
  salePerson,
  documentType,
}: Props) => {
  const fontSize = 10.66;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Typography
            sx={{ fontSize: fontSize, fontWeight: 600, lineHeight: 2 }}
          >
            สแกนดูเอกสาร
          </Typography>
          <QRCodeSVG value={window.location.href} size={56} />
        </Box>
        {approver_list &&
        approver_list.length > 0 &&
        (status === "draft" || status === "wait_approve")
          ? approver_list.map((_, index) => (
              <SignerBox
                date={
                  reviewer_list && reviewer_list.length > 0
                    ? reviewer_list[index]?.reviewed_date
                    : undefined
                }
                name={
                  reviewer_list && reviewer_list.length > 0
                    ? reviewer_list[index]?.first_name &&
                      reviewer_list[index]?.last_name
                      ? `${reviewer_list[index]?.first_name} ${reviewer_list[index]?.last_name}`
                      : undefined
                    : undefined
                }
                title={`ผู้อนุมัติลำดับที่ ${index + 1}`}
              />
            ))
          : reviewer_list &&
            reviewer_list.length > 0 &&
            reviewer_list.map((reviewer, index) => (
              <SignerBox
                date={reviewer.reviewed_date}
                name={`${reviewer.first_name} ${reviewer.last_name}`}
                title={`ผู้อนุมัติลำดับที่ ${index + 1}`}
              />
            ))}
        {/* {approver_list &&
          approver_list.length > 0 &&
          (reviewer_list && reviewer_list.length > 0 ? (
            reviewer_list.length === 1 ? (
              reviewer_list.map((reviewer) => (
                <SignerBox
                  date={reviewer.reviewed_date}
                  name={`${reviewer.first_name} ${reviewer.last_name}`}
                  title="ผู้อนุมัติ"
                />
              ))
            ) : (
              reviewer_list.map((reviewer, index) => (
                <SignerBox
                  date={reviewer.reviewed_date}
                  name={`${reviewer.first_name} ${reviewer.last_name}`}
                  title={`ผู้อนุมัติลำดับที่ ${index + 1}`}
                />
              ))
            )
          ) : (
            <SignerBox title="ผู้อนุมัติ" />
          ))} */}
        {[
          "cash_sales",
          "sales_invoice",
          "sales_return",
          "customer_deposit",
        ].includes(documentType) && <SignerBox title="ผู้มีอำนาจลงนาม" />}
        <SignerBox name={salePerson} title="พนักงานขาย" />
        {["quotation", "sales_order"].includes(documentType) && (
          <SignerBox title="ผู้อนุมัติการสั่งซื้อ" />
        )}
        {["cash_sales", "sales_invoice", "customer_deposit"].includes(
          documentType
        ) && <SignerBox title="พนักงานบัญชี" />}
        {["cash_sales", "sales_invoice", "sales_return"].includes(
          documentType
        ) && (
          <Box
            sx={{
              bgcolor: "#F0F2F980",
            }}
          >
            <SignerBox title="ผู้ส่งของ" customDivider={true} />
            <SignerBox title="ผู้รับของ" customDivider={true} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SalesPdfSigner;
