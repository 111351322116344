import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledTextField from "../../Controller/ControlledTextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import AddressDetail from "./AddressDetail";
import { ICompany } from "../../../types/Setting/Company";
import { useDisable } from "../../../hooks/use-disable";

interface Props {
  addressList: any[] | null | undefined;
}

const CompanyAddress = ({ addressList }: Props) => {
  const { control } = useFormContext<ICompany>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "address_list",
  });

  const addAddressHandler = () => {
    append({
      type: "",
      address_name: "",
      district: "",
      is_default: false,
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "thailand",
      fax: "",
    });
  };

  const removeAddressHandler = (index: number) => {
    remove(index);
  };

  return (
    <>
      {fields.map((address, index) => (
        <CustomizedBox
          key={address.id}
          margin={
            index === fields.length - 1
              ? "1.5rem 0rem"
              : index % 2 !== 0
              ? 0
              : "1.5rem 0rem"
          }
          bgcolor="#EFF2FF"
        >
          <Grid container spacing={1.5}>
            <Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
              {index === 0 ? (
                <Typography color="primary.main" fontWeight={600}>
                  ที่อยู่จดทะเบียน
                </Typography>
              ) : index === 1 ? (
                <Typography color="primary.main" fontWeight={600}>
                  ที่อยู่จัดส่งสินค้า
                </Typography>
              ) : (
                <ControlledTextField
                  label="ชื่อที่อยู่"
                  control={control}
                  name={`address_list.${index}.type`}
                  viewMode={disabled}
                />
              )}
            </Grid>
            {index !== 0 && index !== 1 && !disabled && (
              <Grid item xs={2} sm={2} md={6} lg={6} xl={6}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <IconButton onClick={() => removeAddressHandler(index)}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
            {index !== 0 && (
              <Grid item xs={12}>
                <ControlledCheckbox
                  name={`address_list.${index}.is_same_as_default_address`}
                  control={control}
                  label="เหมือนที่อยู่จดทะเบียน"
                  disabled={disabled}
                />
              </Grid>
            )}
            <AddressDetail index={index} addressList={addressList} />
          </Grid>
        </CustomizedBox>
      ))}
      {!disabled && (
        <CustomizedButton
          title={t("button.add") + t("contact.address.index")}
          variant="outlined"
          onClick={addAddressHandler}
          sx={{ mb: 2 }}
        />
      )}
    </>
  );
};

export default CompanyAddress;
