import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { IGoodsReturn } from "../../../../types/Inventory/GoodsReturn";
import { useStateContext } from "../../../../contexts/auth-context";
import { Fragment, useState } from "react";
import { useGoodsReturnItemList } from "../../../../hooks/Inventory/use-return-item-list";
import { useDisable } from "../../../../hooks/use-disable";
import { useSnackbar } from "notistack";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  InventoryDocumentType,
  Tracability,
  TraceEntriesFindManyAggridQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import { goodsReturnTraceEntryFormatter } from "../../../../utils/Formatter/Inventory/GoodsReturn";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { formatDate, formatDateTimeNoAMPM } from "../../../../utils/Date";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SerialList from "./SerialList";
import Confirmation from "../../../UI/Confirmation";
import { useTranslation } from "react-i18next";

type Props = {
  fields: FieldArrayWithId<IGoodsReturn, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
  update: UseFieldArrayUpdate<IGoodsReturn, "trace_entry_list">;
  remove: UseFieldArrayRemove;
};

const GoodsReturnScanItemList = ({ fields, append, update, remove }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IGoodsReturn>();
  const {
    state: { authUser },
  } = useStateContext();
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );
  const [disabled] = useDisable();

  const headers = useGoodsReturnItemList("wait_receive", disabled);

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const watchBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const watchWarehouseId = useWatch({
    control,
    name: "warehouse_id",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { binLocationOptions, allBinLocation } = useInventoryControl(
    watchBranchId,
    watchWarehouseId
  );

  const {
    control: barcodeControl,
    getValues: getBarcodeValues,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      source_bin_location_id: undefined,
    },
  });

  const watchSourceWarehouseId = useWatch({
    control,
    name: "warehouse_id",
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter: watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const onBarcodeSubmitHandler = async () => {
    const { data } = await refetch();
    const source_bin_location_id = getBarcodeValues("source_bin_location_id");
    const foundBinLocation = allBinLocation?.find(
      (bin) => bin.id === source_bin_location_id
    );

    const traceEntries = data?.TraceEntriesFindManyAggrid?.results;

    const traceEntry = traceEntries
      ? traceEntries.find(
          (trace) => trace?.type === InventoryDocumentType.GoodsReceive
        )
      : null;

    if (traceEntry) {
      const traceEntryType = traceEntry as ITraceEntry;
      const formatTrace = goodsReturnTraceEntryFormatter(
        traceEntryType,
        foundBinLocation,
        authUser
      );

      if (formatTrace) {
        const foundExisting = fields.findIndex(
          (trace) => trace.item_unique_id === formatTrace.item_unique_id
        );
        if (formatTrace.tracability === Tracability.Serial) {
          if (foundExisting === -1) {
            const {
              qty,
              posted_date,
              serial_no,
              source_bin_location,
              source_bin_location_id,
              scanned_by,
              barcode,
              ...otherTrace
            } = formatTrace;
            const formatTraceWithSerial = {
              ...otherTrace,
              qty: 0,
              serial_list: [
                {
                  ...otherTrace,
                  qty,
                  posted_date,
                  serial_no,
                  source_bin_location,
                  source_bin_location_id,
                  scanned_by,
                  barcode,
                },
              ],
            };
            append(formatTraceWithSerial);
          } else {
            const currentTrace: ITraceEntry = getValues(
              `trace_entry_list.${foundExisting}`
            );

            if (currentTrace && currentTrace.serial_list) {
              const foundBarcode = currentTrace.serial_list.findIndex(
                (serial) => serial.barcode === watchBarcode("barcode").trim()
              );
              if (foundBarcode === -1) {
                const { document_item_qty, posted_qty, ...otherTrace } =
                  formatTrace;
                const formatTraceWithDocument: ITraceEntry = {
                  ...otherTrace,
                  document_item_qty: currentTrace.document_item_qty,
                  posted_qty: currentTrace.posted_qty,
                };
                update(foundExisting, {
                  ...currentTrace,
                  serial_list: [
                    ...currentTrace.serial_list,
                    formatTraceWithDocument,
                  ],
                });
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          }
        } else {
          const foundExisting = fields.findIndex(
            (trace) => trace.item_unique_id === formatTrace.item_unique_id
          );
          if (foundExisting === -1) {
            append(formatTrace);
          } else {
            const currentTrace: ITraceEntry = getValues(
              `trace_entry_list.${foundExisting}`
            );
            const { document_item_qty, posted_qty, ...otherTrace } =
              formatTrace;

            const formatTraceWithDocument: ITraceEntry = {
              ...otherTrace,
              document_item_qty: currentTrace.document_item_qty,
              posted_qty: currentTrace.posted_qty,
            };

            update(foundExisting, formatTraceWithDocument);
          }
        }
        enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
          variant: "success",
          style: { whiteSpace: "pre-line" },
        });
      }
    } else {
      // Barcode not found
      enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
        variant: "error",
      });
    }
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  return (
    <>
      {status && (status === "wait_receive" || status === "in_transit") && (
        <CustomizedBox margin={0}>
          <Typography color="primary.main" fontWeight={600}>
            สแกน Barcode
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <ControlledSelect
                label="เลือกสถานที่"
                control={barcodeControl}
                name="source_bin_location_id"
                options={binLocationOptions || []}
                disabled={disabled || !watchSourceWarehouseId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitHandler();
                  }
                }}
                disabled={disabled || !watchBarcode("source_bin_location_id")}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
      <CustomizedBox maxWidth={1650} margin={disabled ? 0 : "1.5rem 0rem"}>
        <Typography color="primary.main" fontWeight={600} mb={2}>
          รายการสินค้า
        </Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                      color={"primary.main"}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[0]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[1]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {formatDateTimeNoAMPM(trace.posted_date)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[2]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[3]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[4]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.document_item_qty || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[5]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.posted_qty || 0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[6]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {(trace.document_item_qty || 0) -
                          (trace.posted_qty || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[7]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal ? (
                        <>
                          {disabled || !trace.scanned_by ? (
                            <Typography fontSize={14}>{trace.qty}</Typography>
                          ) : (
                            <ControlledTextField
                              type="number"
                              control={control}
                              name={`trace_entry_list[${index}].qty`}
                              disabled={disabled}
                              error={Boolean(
                                errors?.trace_entry_list?.[index]?.qty
                              )}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              FormHelperTextProps={{
                                style: { fontSize: "10px" },
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <TotalPostedQuantityCell nestedIndex={index} />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.uom?.name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.serial_no}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[9]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.source_bin_location?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[10]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.scanned_by && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CustomizedAvatar
                            avatars={[
                              {
                                unique_id:
                                  trace.scanned_by.user_unique_id || "",
                                first_name: trace.scanned_by.first_name || "",
                                last_name: trace.scanned_by.last_name || "",
                                img_url: trace.scanned_by.img_url
                                  ? trace.scanned_by.img_url[0]
                                  : "",
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[11]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.barcode}</Typography>
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[12]?.width || 0
                          : 0) + 16
                      }
                      align="center"
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          <ControlledTextField
                            placeholder={t("sentence.remark")}
                            control={control}
                            name={`trace_entry_list.${index}.remark`}
                            viewMode={status === "finished"}
                            viewModeNoLabel
                          />
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[13]?.width || 0
                          : 0) + 16
                      }
                      align="center"
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.lot_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[14]?.width || 0
                          : 0) + 16
                      }
                      align="center"
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.manufactured_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[15]?.width || 0
                          : 0) + 16
                      }
                      align="center"
                    >
                      {trace.tracability === Tracability.Normal && (
                        <Typography fontSize={14}>
                          {formatDate(trace.expired_date)}
                        </Typography>
                      )}
                    </TableCell>
                    {!disabled && (
                      <TableCell
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[12]?.width || 0
                            : 0) + 16
                        }
                      >
                        <IconButton
                          onClick={() => {
                            openConfirmationHandler();
                            setDeletedIndex(index);
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <SerialList nestedIndex={index} />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Confirmation
          title="ยืนยันหากต้องการลบสินค้า"
          message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
      </CustomizedBox>
    </>
  );
};

export default GoodsReturnScanItemList;
