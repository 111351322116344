import { Box, Skeleton, Typography } from "@mui/material";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../../../generated/general";

interface Props {
  documentName: string;
  noCompany?: boolean;
  imgSize?: number;
}

const PDFHeader = ({ documentName, noCompany, imgSize }: Props) => {
  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } = useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(
    graphqlClientGeneral,
    {
      uniqueInput: {
        id: 1,
      },
    }
  );

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  // const defaultAddress =
  //   data?.CompanyFindUnique &&
  //   data.CompanyFindUnique.address_list &&
  //   data.CompanyFindUnique.address_list.find((address) => address.is_default);

  // const phoneNumber =
  //   data?.CompanyFindUnique &&
  //   data.CompanyFindUnique.contact_channel_list &&
  //   data.CompanyFindUnique.contact_channel_list
  //     .filter((channel) => channel.contact_channel_type === "เบอร์โทรศัพท์")
  //     .map((c) => c.contact_channel_name);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {documentName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box sx={{ width: 120, height: 120 }} mt={-5}>
            <img
              alt="npr-erp-logo"
              src={
                data?.CompanyFindUnique?.img_url
                  ? data?.CompanyFindUnique.img_url[0]
                    ? data?.CompanyFindUnique.img_url[0]
                    : "/static/logo.png"
                  : "/static/logo.png"
              }
              className="image-no-hover"
            />
          </Box>
          {/* {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                บริษัท {data?.CompanyFindUnique?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                {defaultAddress?.address || ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                {defaultAddress?.sub_district || ""}{" "}
                {defaultAddress?.district || ""}{" "}
                {defaultAddress?.province || ""}{" "}
                {defaultAddress?.postal_code || ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                โทรศัพท์{" "}
                {phoneNumber && phoneNumber.length > 1
                  ? phoneNumber?.join(", ")
                  : "-"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                เลขประจำตัวผู้เสียภาษีอากร{" "}
                {data?.CompanyFindUnique?.identity_no || "-"}
              </Typography>
            </Box>
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default PDFHeader;
