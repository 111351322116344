import { Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import RadioBox from "../../UI/RadioBox";
import { useFormContext, useWatch } from "react-hook-form";
import { IApproval } from "../../../types/global";

const ApprovalSelect = () => {
  const { control, setValue } = useFormContext<IApproval>();
  const watchSelect = useWatch({
    control,
    name: "is_have_approval",
  });
  const { t } = useTranslation();

  return (
    <CustomizedBox>
      <Typography fontWeight={"bold"} color={"primary.main"} mb={2}>
        {t("setting.approval.index")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <RadioBox
            label={`ไม่ตั้งค่า${t("setting.approval.index")}`}
            onClick={() => {
              setValue("is_have_approval", false);
              // setValue("approval_templates", []);
            }}
            checked={!watchSelect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <RadioBox
            label={`ตั้งค่า${t("setting.approval.index")}`}
            onClick={() => {
              setValue("is_have_approval", true);
            }}
            checked={watchSelect}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ApprovalSelect;
