import { Box, Grid, Typography, IconButton } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledSwitch from "../../Controller/ControlledSwitch";
import DropzoneUI from "../../UI/DropzoneUI";
import { useEffect, useMemo, useState } from "react";
import RadioBox from "../../UI/RadioBox";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import {
  businessTypeFormatter,
  companyTypeFormatter,
  countryFormatter,
  genderFormatter,
} from "../../../utils/Formatter/Global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { useParams } from "react-router-dom";

type Props = {
  type: "customer" | "vendor";
  generateUniqueId: (button: boolean) => void;
};

const ContactInfo = ({ type, generateUniqueId }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    resetField,
  } = useFormContext();
  const { t } = useTranslation();
  const watchBusinessType = useWatch({
    control,
    name: "business_type",
  });

  const [disabled] = useDisable();

  const { id } = useParams();

  const watchBranchUniqueId = useWatch({ control, name: "branch_unique_id" });
  const watchSourceCountry = useWatch({ control, name: "source_country" });

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const genders = [
    {
      id: 1,
      label: t("user.account.gender.male"),
      value: "male",
    },
    {
      id: 2,
      label: t("user.account.gender.female"),
      value: "female",
    },
    {
      id: 3,
      label: t("user.account.gender.other"),
      value: "other",
    },
  ];

  const corporateTypes = useMemo(
    () => [
      {
        id: 1,
        label: t("contact.info.corporate.company"),
        value: "company",
      },
      {
        id: 2,
        label: t("contact.info.corporate.public_limited"),
        value: "public_limited",
      },
      {
        id: 3,
        label: t("contact.info.corporate.limited_partnership"),
        value: "limited_partnership",
      },
      {
        id: 4,
        label: t("contact.info.corporate.juristic_partnership"),
        value: "registered_ordinary_partnership",
      },
      {
        id: 5,
        label: t("contact.info.corporate.association"),
        value: "association",
      },
      { id: 6, label: t("contact.info.corporate.other"), value: "other" },
    ],
    [t]
  );

  const individualTypes = useMemo(
    () => [
      {
        id: 1,
        label: t("contact.info.individual.index"),
        value: "individual",
      },
      {
        id: 2,
        label: t("contact.info.individual.ordinary_partnership"),
        value: "ordinary_partnership",
      },
      {
        id: 3,
        label: t("contact.info.individual.store"),
        value: "store",
      },
      {
        id: 4,
        label: t("contact.info.individual.entities"),
        value: "entities",
      },
    ],
    [t]
  );

  const [businessOption, setBusinessOption] = useState<string>("cooperate");

  useEffect(() => {
    if (watchBranchUniqueId && watchBranchUniqueId.length > 0) {
      setBusinessOption("branch");
    }
  }, [watchBranchUniqueId]);

  // useEffect(() => {
  //   console.log("watchBusinessType", watchBusinessType);
  //   if (watchBusinessType && watchBusinessType === "corporate") {
  //     setBusinessOption("cooperate");
  //   }
  // }, [watchBusinessType, setValue]);

  const watchStatus = useWatch({
    control,
    name: "status",
  });

  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            ข้อมูลทั่วไป
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="unique_id"
                control={control}
                label={`รหัส${t(`contact.contact_type.${type}`)}`}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message?.toString()}
                viewMode={disabled}
                disabled={id ? true : false}
                required
              />
            </Grid>
            <Grid item xs={1} sm={1} md={8} lg={8} xl={8}>
              {!disabled && !id && (
                <CustomizedTooltip
                  title={`เรียกรหัส${t(`contact.contact_type.${type}`)}`}
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={() => generateUniqueId(true)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            {disabled ? (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <LabelInput
                    label={t("contact.info.business_type")}
                    value={businessTypeFormatter(getValues("business_type"))}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} />
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setValue("business_type", "corporate");
                      resetField("business_type_detail");
                      resetField("title_name");
                      resetField("name");
                      resetField("last_name");
                      resetField("nick_name");
                      resetField("gender");
                    }}
                    label={t("contact.info.corporate.index")}
                    checked={watchBusinessType === "corporate"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setValue("business_type", "individual");
                      resetField("business_type_detail");
                      resetField("name");
                    }}
                    label={t("contact.info.individual.index")}
                    checked={watchBusinessType === "individual"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              </>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t(`contact.info.${watchBusinessType}.index`)}
                  value={companyTypeFormatter(
                    getValues("business_type_detail")
                  )}
                />
              ) : (
                <ControlledSelect
                  name="business_type_detail"
                  control={control}
                  label={t(`contact.info.${watchBusinessType}.index`)}
                  error={Boolean(errors.business_type_detail)}
                  helperText={errors.business_type_detail?.message?.toString()}
                  options={
                    watchBusinessType === "corporate"
                      ? corporateTypes
                      : individualTypes
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} />
            {watchBusinessType === "corporate" ? (
              <>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <ControlledTextField
                    name="name"
                    control={control}
                    label={t("contact.info.brand_name")}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message?.toString()}
                    viewMode={disabled}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  {disabled ? (
                    <LabelInput
                      label={t(`user.account.title_name`)}
                      value={getValues("title_name")}
                    />
                  ) : (
                    <ControlledSelect
                      name="title_name"
                      control={control}
                      label={t("user.account.title_name")}
                      error={Boolean(errors.title_name)}
                      helperText={errors.title_name?.message?.toString()}
                      options={prefixes}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextField
                    name="name"
                    control={control}
                    label={t("contact.contact_person.name.first_name")}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message?.toString()}
                    viewMode={disabled}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextField
                    name="last_name"
                    control={control}
                    label={t("contact.contact_person.name.last_name")}
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name?.message?.toString()}
                    viewMode={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextField
                    name="nick_name"
                    control={control}
                    label={t("contact.contact_person.name.nick_name")}
                    error={Boolean(errors.nick_name)}
                    helperText={errors.nick_name?.message?.toString()}
                    viewMode={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  {disabled ? (
                    <LabelInput
                      label={t(`user.account.gender.index`)}
                      value={genderFormatter(getValues("gender"))}
                    />
                  ) : (
                    <ControlledSelect
                      name="gender"
                      control={control}
                      label={t("user.account.gender.index")}
                      error={Boolean(errors.gender)}
                      helperText={errors.gender?.message?.toString()}
                      options={genders}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              </>
            )}
            {disabled ? (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <LabelInput
                    label="ประเทศ"
                    value={countryFormatter(getValues("source_country"))}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} />
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setValue("source_country", "thailand");
                    }}
                    label={t("contact.info.country.thailand")}
                    checked={watchSourceCountry === "thailand"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setValue("source_country", "other");
                    }}
                    label={t("contact.info.country.other")}
                    checked={watchSourceCountry === "other"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
              </>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="taxpayer_id"
                control={control}
                label={t("contact.info.taxpayer_id")}
                error={Boolean(errors.taxpayer_id)}
                helperText={errors.taxpayer_id?.message?.toString()}
                viewMode={disabled}
                onChange={(e, field) => {
                  const value = e.target.value;
                  const onlyNumbers = value.replace(/[^0-9]/g, "");
                  field.onChange(
                    watchSourceCountry === "thailand" ? onlyNumbers : value
                  );
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} />
            {!disabled && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setBusinessOption("cooperate");
                    }}
                    label="สำนักงานใหญ่"
                    checked={businessOption === "cooperate"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setBusinessOption("branch");
                    }}
                    label="สาขา"
                    checked={businessOption === "branch"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <RadioBox
                    onClick={() => {
                      setBusinessOption("none");
                    }}
                    label="ไม่ระบุ"
                    checked={businessOption === "none"}
                  />
                </Grid>
              </>
            )}
            {businessOption === "branch" && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  name="branch_unique_id"
                  control={control}
                  label={`รหัส${t("contact.branch")}`}
                  error={Boolean(errors.branch_unique_id)}
                  helperText={errors.branch_unique_id?.message?.toString()}
                  viewMode={disabled}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={disabled}
          />
          <Box mt={2}>
            <ControlledSwitch
              control={control}
              name="status"
              label="ใช้งาน"
              disabled={disabled}
            />
          </Box>
          {!watchStatus && (
            <>
              <Box mt={1.5}>
                <ControlledDatePicker
                  name="inactive_date"
                  control={control}
                  label={t("date.inactive_date")}
                  viewMode={disabled}
                />
              </Box>
              <ControlledTextField
                sx={{ mt: 1.5 }}
                name="remark_status"
                control={control}
                label={t("sentence.remark_status")}
                viewMode={disabled}
              />
            </>
          )}
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ContactInfo;
