import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const useDepositListHeader = (disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        width: 75,
      },
      {
        thaiLabel: "รายละเอียดรายการ",
        width: 250,
        required: true,
        align: "left",
      },
      {
        thaiLabel: "ภาษี (%)",
        width: 100,
      },
      {
        thaiLabel: "จำนวนเงิน",
        width: 150,
        required: true,
        align: "right",
      },
      {
        thaiLabel: "หมายเหตุรายการ",
        width: 150,
        align: "left",
      },
    ];

    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled]);

  return headers;
};
