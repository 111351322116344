import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Grid, Stack } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useForm, useFormContext } from "react-hook-form";
import { countryOptions } from "../../../utils/Global";
import { useEffect, useState } from "react";
import { IDeliveryAddress } from "../../../types/global";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";

type Props = {
  open: boolean;
  handleClose: () => void;
  viewMode: boolean;
  name: string;
};

const DeliveryAddressModal = ({ open, handleClose, viewMode, name }: Props) => {
  const { setValue: setContextValue, getValues: getContextValues } =
    useFormContext();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<IDeliveryAddress>({
    defaultValues: {
      is_same_as_default_address: false,
      type: "",
      contact_name: "",
      contact_phone: "",
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  });

  const onSubmitHandler = (data: IDeliveryAddress) => {
    setContextValue(`${name}.delivery_address`, data);
    handleClose();
  };

  const [addressValue, setAddressValue] = useState<any>("");

  const watchIsSameAddress = watch("is_same_as_default_address");

  useEffect(() => {
    if (open) {
      if (watchIsSameAddress) {
        const currentAddress = getContextValues(`${name}.billing_address`);
        const previousDeliveryAddress = getContextValues(
          `${name}.delivery_address`
        );
        const newAddress = {
          ...currentAddress,
          type: previousDeliveryAddress.type,
          contact_name: previousDeliveryAddress.contact_name,
          contact_phone: previousDeliveryAddress.contact_phone,
          fax: previousDeliveryAddress.fax,
          is_same_as_default_address: true,
        };
        setAddressValue(newAddress);
        reset(newAddress);
      } else {
        const currentAddress = getContextValues(`${name}.delivery_address`);
        const newAddress = {
          ...currentAddress,
          is_same_as_default_address: false,
        };
        setAddressValue(newAddress);
        reset(newAddress);
      }
    }
  }, [getContextValues, name, open, reset, watchIsSameAddress]);

  const { t } = useTranslation();
  return (
    <ModalUI
      open={open}
      handleClose={handleClose}
      title={t("address.delivery_address")}
      maxWidth="md"
      action={
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            title={t("button.save")}
            onClick={handleSubmit(onSubmitHandler)}
            variant="contained"
          />
        </Stack>
      }
    >
      <Grid container spacing={1.5} mt={0.1}>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            label="ชื่อที่อยู่"
            control={control}
            name={`type`}
            viewMode={viewMode}
          />
        </Grid> */}
        <Grid item xs={12}>
          <ControlledCheckbox
            name={`is_same_as_default_address`}
            control={control}
            label="เหมือนที่อยู่จดทะเบียน"
            disabled={viewMode}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name={`contact_name`}
            control={control}
            label={t("contact.address.contact_person_name")}
            error={Boolean(errors.contact_name)}
            helperText={errors.contact_name && errors.contact_name?.message}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            name={`contact_phone`}
            control={control}
            label={t("contact.address.phone_number")}
            error={Boolean(errors.contact_phone)}
            helperText={errors.contact_phone && errors.contact_phone?.message}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            name={`fax`}
            control={control}
            label={t("contact.address.fax")}
            error={Boolean(errors.fax)}
            helperText={errors.fax && errors.fax?.message}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name={`address_name`}
            control={control}
            label={t("contact.address.index")}
            error={Boolean(errors.address_name)}
            helperText={errors.address_name && errors.address_name?.message}
            rows={4}
            multiline
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="sub_district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="province"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="postal_code"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSelect
            name={`country`}
            control={control}
            label={t("contact.address.country")}
            error={Boolean(errors.country)}
            helperText={errors.country && errors.country?.message}
            options={countryOptions}
            disabled={true}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default DeliveryAddressModal;
