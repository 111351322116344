import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link } from "@mui/material";
import { IBreadcrumbsAndMenu } from "../../types/global";

interface propsType {
  breadcrumbs: IBreadcrumbsAndMenu[];
}

const CustomizedBreadcrumbs = ({ breadcrumbs }: propsType) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs.map(
        (
          breadcrumb: IBreadcrumbsAndMenu,
          index: number,
          row: IBreadcrumbsAndMenu[]
        ) => {
          if (index + 1 === row.length) {
            return (
              <Typography key={index} fontWeight="bold">
                {breadcrumb.name}
              </Typography>
            );
          } else {
            if (breadcrumb.to) {
              return (
                <Link
                  component={RouterLink}
                  key={index}
                  color="inherit"
                  underline="none"
                  to={breadcrumb.to}
                >
                  {breadcrumb.name}
                </Link>
              );
            } else {
              return <Typography key={index}>{breadcrumb.name}</Typography>;
            }
          }
        }
      )}
    </Breadcrumbs>
  );
};

export default CustomizedBreadcrumbs;
