import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "../../../../types/global";

export const usePurchaseOrderListPdfHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  const columns = useMemo<IHeaderTable[]>(
    () => [
      {
        thaiLabel: "",
        width: 12,
      },
      {
        thaiLabel: "สินค้า",
        width: 122.2,
        align: "left",
      },
      {
        thaiLabel: "จำนวน",
        width: 60,
        align: "right",
      },
      {
        thaiLabel: "หน่วย",
        width: 50,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        width: 100,
        align: "right",
      },
      {
        thaiLabel: "ส่วนลด",
        width: 50,
        align: "right",
      },
      {
        thaiLabel: "จำนวนเงิน",
        width: 100,
        align: "right",
      },
    ],
    []
  );
  useEffect(() => {
    setHeaders(columns);
  }, [columns]);

  return headers;
};
