import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Typography, Divider } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";

interface Props {
  open: boolean;
  handleClose: () => void;
  action: () => void;
}

const CalcelReceiveQtyModal = ({ open, handleClose, action }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalUI
      title={"ยืนยันหากต้องการยกเลิกเอกสาร"}
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      action={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={action}
          />
        </Box>
      }
    >
      <Divider sx={{ marginBottom: 2 }} />
      <Box display="flex" gap={1}>
        <Typography mb={2}>หากยกเลิกจำนวนคงค้างจะไม่สามารถแก้ไขได้</Typography>
      </Box>
      <Divider sx={{ marginTop: 0.5 }} />
    </ModalUI>
  );
};

export default CalcelReceiveQtyModal;
