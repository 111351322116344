import { useCookies } from "react-cookie";
import React, { useEffect } from "react";
import { GraphQLClient, gql } from "graphql-request";
import { useStateContext } from "../contexts/auth-context";
import { IUser } from "../types/Auth/user";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { IToken } from "../types/Auth/token";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { Box, CircularProgress } from "@mui/material";
import {
  ValidateTokenQuery,
  useValidateTokenQuery,
} from "../generated/general";
import { useSnackbar } from "notistack";

// const cookies = new Cookies();

export const REFRESH_ACCESS_TOKEN = gql`
  mutation {
    refreshToken {
      access_token
    }
  }
`;

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const navigate = useNavigate();
  const { dispatch } = useStateContext();
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(
    "general",
    false
  );

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch, isLoading, isFetching, isError } = useValidateTokenQuery<
    ValidateTokenQuery,
    Error
  >(
    graphQLClientWithHeader,
    {},
    {
      retry: 1,
      enabled: cookies.logged_in,
      staleTime: 3600 * 1000,
      onSuccess: ({ validateToken }) => {
        dispatch({
          type: "SET_USER",
          payload: validateToken as IUser,
        });

        const branch_id = sessionStorage.getItem("branch-id");
        if (!branch_id) {
          sessionStorage.setItem(
            "branch-id",
            validateToken.last_login_branch?.toString() || ""
          );
          if (validateToken.last_login_branch === -1) {
            sessionStorage.setItem("is_hq", "true");
          } else {
            sessionStorage.setItem("is_hq", "false");
          }
        }
      },
      async onError(error: any) {
        if (error.message === "Network request failed") {
          removeCookie("access_token");
          removeCookie("refresh_token");
          setCookie("logged_in", false, { path: "/" });
          navigate("/login");
          enqueueSnackbar(
            "ไม่สามารถเชื่อมต่อกับฐานข้อมูลได้ (Service is not avaiable)",
            {
              variant: "error",
            }
          );
        } else {
          const refresh_token = cookies.refresh_token;
          if (refresh_token) {
            for (const err of error.response.errors) {
              if (err.message.includes("Unauthorized")) {
                try {
                  const { refreshToken } = await graphQLClient.request(
                    REFRESH_ACCESS_TOKEN
                  );
                  const { access_token } = refreshToken;
                  const { exp: accessMaxAge } = jwtDecode<IToken>(access_token);
                  setCookie("access_token", access_token, {
                    path: "/",
                    maxAge: accessMaxAge,
                  });
                  refetch();
                } catch (error) {
                  removeCookie("access_token");
                  removeCookie("refresh_token");
                  setCookie("logged_in", false, { path: "/" });
                  navigate("/login");
                }
              }
            }
          } else {
            logoutHandler();
          }
        }
      },
    }
  );

  useEffect(() => {
    if (!cookies.access_token && cookies.logged_in) {
      refetch();
    }
  }, [refetch, cookies.access_token, cookies.logged_in]);

  const loading =
    (isLoading || isFetching || (isError && cookies.refresh_token)) &&
    cookies.logged_in;

  const logoutHandler = () => {
    removeCookie("access_token", {
      path: "/",
    });
    removeCookie("refresh_token", {
      path: "/",
    });
    setCookie("logged_in", false, {
      path: "/",
    });
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    navigate("/login", { replace: true });
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100dvh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return children;
};

export default AuthMiddleware;
