import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import PdfCompanyInfo from "./PdfCompanyInfo";
import PurchasePdfHighlight from "./PurchasePdfHighlight";
import PurchasePdfLeftInfo from "./PurchasePdfLeftInfo";
import PurchasePdfRightInfo from "./PurchasePdfRightInfo";
import { IPdfInfo } from "../../../types/global";
import PurchasePdfFooter from "./PurchasePdfFooter";
import PurchasePdfSigner from "./PurchasePdfSigner";

type Props = {
  name: string;
  highlightSection: IPdfInfo;
  leftSection: IPdfInfo;
  rightSection: IPdfInfo;
  data: any;
  summary: boolean;
  multiplePage: boolean;
  page: number;
  allPages: number;
  children: ReactNode;
  documentType?: string;
  approvalTemplates?: any[];
};

const PurchasePdfLayout = ({
  name,
  highlightSection,
  leftSection,
  rightSection,
  data,
  summary,
  multiplePage,
  page,
  allPages,
  children,
  documentType,
  approvalTemplates,
}: Props) => {
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${height}px`
      );
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
        }}
      >
        <Box id="print-header" ref={headerRef} height="80mm">
          <Grid container spacing={1.5}>
            <Grid item xs={7}>
              <PdfCompanyInfo branch_unique_id={data.branch.unique_id} />
              <Divider sx={{ my: 1 }} />
              <PurchasePdfLeftInfo
                headers={leftSection.headers}
                info={leftSection.info}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                fontSize={26.66}
                fontWeight={600}
                align="right"
                color={"primary.main"}
              >
                {name}
              </Typography>
              <PurchasePdfHighlight
                headers={highlightSection.headers}
                info={highlightSection.info}
              />
              <PurchasePdfRightInfo
                headers={rightSection.headers}
                info={rightSection.info}
              />
            </Grid>
          </Grid>
          <Box id="print-content">{children}</Box>
        </Box>
        <Box mt="auto">
          {summary ? (
            <PurchasePdfFooter data={data} documentType={documentType} />
          ) : (
            ""
          )}
          <Box mt="16px">
            <PurchasePdfSigner
              status={data.aggrid_status}
              approver_list={
                ["draft", "wait_approve"].includes(data.aggrid_status)
                  ? approvalTemplates
                  : data.approver_list
              }
              reviewer_list={data.reviewer_list}
              created_by={data.created_by}
              issue_date={data.issue_date}
              documentType={documentType}
            />
          </Box>
        </Box>

        <Box
          sx={{
            fontSize: "10px",
            color: "#737373",
            position: "absolute",
            bottom: 10,
            right: 24,
          }}
        >
          หน้า {page}/{allPages}
        </Box>
      </Box>
    </>
  );
};

export default PurchasePdfLayout;
