import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { Grid } from "@mui/material";

import { IItem } from "../../../../types/Inventory/item";
import { useDisable } from "../../../../hooks/use-disable";

import LabelInput from "../../../UI/LabelInput";
import {
  useCategoryGroupOptions,
  useMainCategoryOptions,
  useSubCategoryOptions,
  useBrandOptions,
  useSegmentOptions,
  useSeriesOptions,
} from "../../../../hooks/use-inventory-setting-option";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";

const General = () => {
  const { t } = useTranslation();

  const { control, getValues } = useFormContext<IItem>();
  const { options: categoryGroupOptions } = useCategoryGroupOptions();
  const { options: mainCategoryOptions } = useMainCategoryOptions();
  const { options: subCategoryOptions } = useSubCategoryOptions();
  const { options: brandOptions } = useBrandOptions();
  const { options: segmentOptions } = useSegmentOptions();
  const { options: seriesOptions } = useSeriesOptions();

  const [disabled] = useDisable();

  return (
    <Fragment>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.items.category_group")}
              value={getValues("category_group")}
            />
          ) : (
            <Controller
              control={control}
              name="category_group"
              render={({ field }) => (
                <CustomizedComboBox
                  options={categoryGroupOptions}
                  label={t("inventory.items.category_group")}
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.items.main_category")}
              value={getValues("main_category")}
            />
          ) : (
            <Controller
              control={control}
              name="main_category"
              render={({ field }) => (
                <CustomizedComboBox
                  options={mainCategoryOptions}
                  label={t("inventory.items.main_category")}
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.items.sub_category")}
              value={getValues("sub_category")}
            />
          ) : (
            <Controller
              control={control}
              name="sub_category"
              render={({ field }) => (
                <CustomizedComboBox
                  options={subCategoryOptions}
                  label={t("inventory.items.sub_category")}
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.items.brand")}
              value={getValues("brand")}
            />
          ) : (
            <Controller
              control={control}
              name="brand"
              render={({ field }) => (
                <CustomizedComboBox
                  options={brandOptions}
                  label={t("inventory.items.brand")}
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.items.segment")}
              value={getValues("segment")}
            />
          ) : (
            <Controller
              control={control}
              name="segment"
              render={({ field }) => (
                <CustomizedComboBox
                  options={segmentOptions}
                  label={t("inventory.items.segment")}
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.items.series")}
              value={getValues("series")}
            />
          ) : (
            <Controller
              control={control}
              name="series"
              render={({ field }) => (
                <CustomizedComboBox
                  options={seriesOptions}
                  label={t("inventory.items.series")}
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                  }}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default General;
