import { Box, Skeleton, Typography } from "@mui/material";
import { formatCompanyName } from "../../../utils/Global";
import { ICompany } from "../../../types/Setting/Company";

type Props = {
  branch_unique_id: string;
  company: ICompany;
  isLoading: boolean;
};

const PdfCompanyInfo = ({ branch_unique_id, company, isLoading }: Props) => {
  const defaultAddress =
    company &&
    company.address_list &&
    company.address_list.find((address) => address.is_default);

  const fontSize = 10.66;

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box>
        <img
          alt="npr-erp-logo"
          src={
            company?.img_url
              ? company.img_url[0]
                ? company.img_url[0]
                : "/static/logo.png"
              : "/static/logo.png"
          }
          className="image-no-hover"
        />
      </Box>
      <Box>
        <Typography fontSize={16} fontWeight={600} lineHeight={2}>
          {formatCompanyName(company?.type || "", company?.name || "")}
        </Typography>
        <Typography fontSize={fontSize}>
          {`${defaultAddress?.address_name || ""} ${
            defaultAddress?.sub_district || ""
          } ${defaultAddress?.district || ""} ${
            defaultAddress?.province || ""
          } ${defaultAddress?.postal_code || ""}`}
        </Typography>
        <Box display={"flex"} gap={0.5}>
          <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
            เลขประจำตัวผู้เสียภาษี
          </Typography>
          <Typography fontSize={fontSize} lineHeight={2}>
            {company?.identity_no || "-"} ({branch_unique_id})
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfCompanyInfo;
