import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "../../../../types/global";

export const useConsignmentTraceEntryHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  const columns = useMemo<IHeaderTable[]>(
    () => [
      {
        thaiLabel: "",
        width: 20,
        align: "left",
      },
      {
        thaiLabel: "สินค้า",
        width: 227.5,
        align: "left",
      },
      {
        thaiLabel: "จำนวน",
        width: 66.5,
        align: "right",
      },
      {
        thaiLabel: "หน่วย",
        width: 66.5,
        align: "left",
      },
      {
        thaiLabel: "Batch/SN",
        width: 159.5,
        align: "left",
      },
      {
        thaiLabel: "Barcode",
        width: 159.5,
        align: "left",
      },
    ],
    []
  );
  useEffect(() => {
    setHeaders(columns);
  }, [columns]);

  return headers;
};
