import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ButtonInfo } from "../../types/Inventory/GoodsTransfer";

export const useAdjustmentButtonByStatus = (
  isApprove: boolean,
  isHaveApproval?: boolean
) => {
  const { t } = useTranslation();
  const statusButtonInfo = useCallback(
    (isEdit: boolean, status?: string): ButtonInfo[] => {
      if (isEdit) {
        return [
          {
            variant: "outlined",
            title: t("button.cancel"),
            next: "cancelled",
          },
          {
            variant: "contained",
            title: t("button.save"),
            next: undefined,
          },
        ];
      }

      switch (status) {
        case "draft":
          return [
            {
              variant: "outlined",
              title: t("button.save_draft"),
              next: "draft",
            },
            {
              variant: "contained",
              title: isApprove ? t("button.send") : t("button.adjust"),
              next: isApprove ? "wait_approve" : "finished",
            },
          ];
        case "wait_approve":
          if (isHaveApproval) {
            return [
              {
                variant: "outlined",
                title: t("button.save"),
                next: undefined,
              },
              {
                variant: "outlined",
                title: t("button.not_approve"),
                next: "not_approved",
              },
              {
                variant: "contained",
                title: t("button.approve"),
                next: "wait_transfer",
              },
            ];
          } else {
            return [
              {
                variant: "outlined",
                title: t("button.save"),
                next: undefined,
              },
            ];
          }
        case "not_approved":
          return [
            {
              variant: "outlined",
              title: t("button.save"),
              next: undefined,
            },
            {
              variant: "contained",
              title: t("button.send"),
              next: "wait_approve",
            },
          ];
        case "finished":
        case "cancelled":
          return [];
        default:
          return [
            {
              variant: "outlined",
              title: t("button.save_draft"),
              next: "draft",
            },
            {
              variant: "contained",
              title: isApprove ? t("button.send") : t("button.adjust"),
              next: isApprove ? "wait_approve" : "finished",
            },
          ];
      }
    },
    [isApprove, isHaveApproval, t]
  );

  return statusButtonInfo;
};
