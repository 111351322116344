import { useEffect, useState } from "react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";

const useWarehouseColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName:
          t("setting.unique_id") + t("setting.inventory.warehouse.index"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: t("setting.inventory.warehouse.name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "branch",
        headerName: t("setting.inventory.warehouse.branch_id"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            const { unique_id } = params.value;
            return unique_id;
          } else {
            return "-";
          }
        },
      },
      {
        field: "address",
        headerName: t("setting.inventory.warehouse.address"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            const {
              address_name,
              sub_district,
              district,
              province,
              postal_code,
            } = params.value;
            return `${address_name} ${sub_district} ${district} ${province} ${postal_code}`;
          } else {
            return "-";
          }
        },
      },
      {
        field: "user",
        headerName: t("setting.inventory.warehouse.supervisor"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            const { first_name, last_name } = params.value;
            return first_name + " " + last_name;
          } else {
            return "-";
          }
        },
      },
    ]);
  }, [t]);
  return columnDefs;
};

export default useWarehouseColumnDefs;
