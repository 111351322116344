import { Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideDatasource,
  ValueFormatterParams,
} from "ag-grid-community";

import { Link, Typography } from "@mui/material";
import AgGrid from "../../../components/UI/AgGrid";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";

import { GraphQLClient } from "graphql-request";
import { InventoryDocumentType } from "../../../generated/inventory";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { TRACE_ENTRY_VIEW } from "../../../services/AgGrid/InventoryAggrid";

import {
  dateFilterModel,
  dateFilterParams,
} from "../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../utils/Date";
import {
  branchInfoFromSessionStorage,
  entryTypeEngToThai,
} from "../../../utils/Global";

const TransactionTab = () => {
  const gridRef: any = useRef();
  const { id } = useParams();
  const { t } = useTranslation();
  const { control } = useFormContext();

  const watchName = useWatch({ control, name: "name" });

  const navigateToGoodPage = (type: string, id: number) => {
    if (type === "goods-adjust") type = "goods-adjustment";
    window.open(`/inventory/${type}/${encodeURIComponent(id)}`, "_blank");
  };

  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        posted_date,
        lot_date,
        is_scanned,
        is_active,
        type,
        source_branch_id,
        ...otherFilter
      } = filterModel;

      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        lot_date: dateFilterModel(lot_date),
        sku_name: {
          filterType: "text",
          type: "equals",
          filter: id,
        },
        status: {
          filterType: "text",
          type: "equals",
          filter: "is_scanned",
        },
        source_branch_id: is_hq
          ? source_branch_id
          : {
              filterType: "number",
              type: "equals",
              filter: branch_id,
            },
        type: type && {
          ...type,
          filter: type.filter,
        },
      };

      const sortbyId = [...sortModel, { colId: "created_date", sort: "desc" }];

      try {
        const { TraceEntriesView } = await graphQLClientWithHeaderItem.request(
          TRACE_ENTRY_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: sortbyId,
            },
          }
        );

        params.success({
          rowData: TraceEntriesView.results as any[],
          rowCount: TraceEntriesView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("inventory.list.posted_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "reference_unique_id",
      headerName: t("sentence.unique_id"),
      cellRenderer: (params: ICellRendererParams) => {
        const { reference_unique_id, reference_id, type } = params.data;
        return (
          <Link
            onClick={() =>
              navigateToGoodPage(type?.replace("_", "-"), reference_id)
            }
            style={{ cursor: "pointer" }}
          >
            {reference_unique_id}
          </Link>
        );
      },
    },
    {
      field: "source_branch_id",
      headerName: t("inventory.list.source_branch_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_branch_name",
      headerName: t("inventory.list.source_branch_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_warehouse_id",
      headerName: t("inventory.list.sourceWarehousDocId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_warehouse_name",
      headerName: t("inventory.list.sourceWarehouse"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_bin_location_id",
      headerName: t("inventory.list.sourceBinLocationDocId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_bin_location_name",
      headerName: t("inventory.list.sourceBinLocation"),
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_branch_id",
      headerName: t("inventory.list.destination_branch_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_branch",
      headerName: t("inventory.list.destination_branch_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_warehouse_id",
      headerName: t("inventory.list.destinationWarehouseDocId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_warehouse_name",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_bin_location_id",
      headerName: t("inventory.list.destinationBinLocationDocId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "destination_bin_location_name",
      headerName: t("inventory.list.destinationBinLocation"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "lot_date",
      headerName: t("inventory.list.lot_number_thai"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "serial_no",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: "agTextColumnFilter",
    },
    {
      field: "qty",
      headerName: t("inventory.list.qty"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "uom_name_th",
      headerName: t("inventory.unit"),
      filter: "agTextColumnFilter",
    },
    {
      field: "type",
      headerName: t("inventory.stockEntry.entryDocumentType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          InventoryDocumentType.GoodsReceive,
          InventoryDocumentType.GoodsIssue,
          InventoryDocumentType.GoodsReturn,
          InventoryDocumentType.GoodsTransfer,
          InventoryDocumentType.GoodsAdjust,
        ],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case InventoryDocumentType.GoodsReceive:
              return "นำเข้า";
            case InventoryDocumentType.GoodsReturn:
              return "รับคืน";
            case InventoryDocumentType.GoodsIssue:
              return "นำออก";
            case InventoryDocumentType.GoodsAdjust:
              return "ปรับปรุง";
            case InventoryDocumentType.GoodsTransfer:
              return "โอนย้าย";
            default:
              break;
          }
        },
      },
      valueFormatter: (params: ValueFormatterParams) =>
        params.value ? entryTypeEngToThai(params.value) : "",
    },
    {
      field: "scanned_by",
      headerName: t("inventory.scanned_by"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams<any, string>) => {
        if (params.value) {
          if (
            params.data.scanned_by_object?.img_url &&
            params.data.scanned_by_object.img_url.length > 0
          ) {
            return (
              <CustomizedAvatar
                avatars={[
                  {
                    unique_id: params.data.scanned_by_object.unique_id,
                    img_url: params.data.scanned_by_object.img_url,
                    first_name: params.data.scanned_by_object.first_name,
                    last_name: params.data.scanned_by_object.last_name,
                  },
                ]}
              />
            );
          } else {
            return <CustomizedLetterAvatar name={params.value} />;
          }
        } else return <></>;
      },
    },
  ];

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <Fragment>
      <Typography my={2} variant="h6">
        {watchName}
      </Typography>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        disabledSidebar
        height={670}
      />
    </Fragment>
  );
};

export default TransactionTab;
