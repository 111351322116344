import {
  ColDef,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Date";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import AgGrid from "../../../UI/AgGrid";
import { CURRENT_STOCK_BY_SN_REPORT_VIEW } from "../../../../services/AgGrid/Report/InventoryReportAggrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const CurrentStockBySNReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "item_unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_main_category",
      headerName: "หมวดหมู่สินค้า",
      filter: "agTextColumnFilter",
    },
    {
      field: "serial_no",
      headerName: "Batch/SN",
      filter: "agTextColumnFilter",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      filter: "agTextColumnFilter",
    },
    {
      field: "branch_id",
      headerName: t("branch.unique_id"),
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "branch_unique_id",
      headerName: t("branch.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "branch_name",
      headerName: t("branch.index"),
      filter: "agTextColumnFilter",
    },
    {
      field: "warehouse_id",
      headerName: t("inventory.warehouseId"),
      filter: "agTextColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "warehouse_unique_id",
      headerName: t("inventory.warehouseId"),
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "warehouse_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
    },
    {
      field: "bin_id",
      headerName: t("inventory.locationId"),
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "bin_name",
      headerName: t("inventory.location"),
      filter: "agTextColumnFilter",
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "lot_date",
      headerName: t("reports.lot_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "manufactured_date",
      headerName: t("date.manufactured_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      hide: true,
    },
    {
      field: "expired_date",
      headerName: t("date.expired_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      hide: true,
    },
  ];

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { lot_date, manufactured_date, expired_date } = filterModel;

      const formatFilter = {
        ...filterModel,
        lot_date: dateFilterModel(lot_date),
        manufactured_date: dateFilterModel(manufactured_date),
        expired_date: dateFilterModel(expired_date),
      };

      try {
        const { CurrentStockBySNReportView } = await graphQLClient.request(
          CURRENT_STOCK_BY_SN_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: CurrentStockBySNReportView.results,
          rowCount: CurrentStockBySNReportView.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default CurrentStockBySNReportTable;
