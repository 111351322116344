import { AgGridReact } from "ag-grid-react";
import { IPriceList } from "../../../../types/Inventory/PriceList";
import { useRef } from "react";
import AgGrid from "../../../UI/AgGrid";
import { usePriceListColumnDefs } from "./columnDef";
import {
  GridReadyEvent,
  IServerSideDatasource,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { ITEM_PRICE_LIST_VIEW } from "../../../../services/AgGrid/InventoryAggrid";

const PriceListTable = () => {
  const gridRef = useRef<AgGridReact<IPriceList>>(null);
  const columnDef = usePriceListColumnDefs();
  const navigate = useNavigate();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { start_date, end_date, is_active, is_increase, type } =
        filterModel;

      const formatFilter = {
        ...filterModel,
        start_date: dateFilterModel(start_date),
        end_date: dateFilterModel(end_date),
        is_increase: is_increase && {
          ...is_increase,
          values: is_increase.values.map((v: string) => parseInt(v)),
        },
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        type: type
          ? type
          : is_increase && is_increase.values[0] === "1"
          ? {
              filterType: "set",
              values: ["all"],
            }
          : undefined,
      };

      try {
        const { ItemPriceListViewAggrid } =
          await graphQLClientWithHeaderItem.request(ITEM_PRICE_LIST_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });

        params.success({
          rowData: ItemPriceListViewAggrid.results as any[],
          rowCount: ItemPriceListViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/inventory/price-list/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/inventory/price-list"}
    />
  );
};

export default PriceListTable;
