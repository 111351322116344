import { useParams } from "react-router";
import {
  ItemSkuQtyBySkuViewQuery,
  ItemSkuQtysByBranchViewQuery,
  useItemSkuQtyBySkuViewQuery,
  useItemSkuQtysByBranchViewQuery,
} from "../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { IBundleDetail } from "../../types/Inventory/item";
import { branchInfoFromSessionStorage } from "../../utils/Global";

interface ResultItem {
  branch_id: number;
  branch_unique_id: string;
  branch_name: string;
  item_name: string;
  items: {
    item_unique_id: string;
    qty: number;
    stock_qty: number;
    available_qty: number;
  }[];
}

interface ResultItemWarehouse {
  branch_id: number;
  branch_unique_id: string;
  branch_name: string;
  warehouse_unique_id: string;
  warehouse_name: string;
  item_name: string;
  items: {
    item_unique_id: string;
    qty: number;
    stock_qty: number;
    available_qty: number;
  }[];
}

interface ResultItemLocation {
  branch_id: number;
  branch_unique_id: string;
  branch_name: string;
  warehouse_unique_id: string;
  warehouse_name: string;
  bin_id: number;
  bin_name: string;
  item_name: string;
  items: {
    item_unique_id: string;
    qty: number;
    stock_qty: number;
    available_qty: number;
  }[];
}

export const useItemCurrentStockSku = (
  itemSkuName: string,
  bundleList?: IBundleDetail[]
) => {
  const graphqlClient = createGraphQLClientWithMiddleware("wms");
  const { id } = useParams();
  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const { data } = useItemSkuQtyBySkuViewQuery<ItemSkuQtyBySkuViewQuery>(
    graphqlClient,
    {
      skuName: itemSkuName || "",
    }
  );

  const { data: skuByBranch } =
    useItemSkuQtysByBranchViewQuery<ItemSkuQtysByBranchViewQuery>(
      graphqlClient,
      {
        aggridInput: {
          filterModel: {
            branch_id: {
              type: "equals",
              filterType: "number",
              filter: parseInt(branch_id ?? "0"),
            },
            sku_name: {
              type: "equals",
              filterType: "text",
              filter: itemSkuName || "",
            },
          },
        },
      }
    );

  const calculateQuantity = (item: any, quantityField: string): number => {
    return (
      item.item_sku_detail?.item_sku_qty
        ?.filter(
          (qty: any) =>
            qty.warehouse?.branch_id === parseInt(branch_id || "0") ||
            is_hq === "true"
        )
        .reduce(
          (prev: any, curr: any) => prev + parseInt(curr?.[quantityField]) || 0,
          0
        ) || 0
    );
  };

  const calculateBundleStock = (
    bundleItems: IBundleDetail[],
    type: "stock" | "available"
  ): number => {
    let bundleStocks: number[] = [];

    for (const item of bundleItems) {
      const stockQty = calculateQuantity(item, "stock_qty");
      const availableQty = calculateQuantity(item, "available_qty");
      const qty = item.qty || 0;

      if (qty !== 0) {
        const bundleItemQty =
          (type === "stock" ? stockQty : availableQty) / qty;
        bundleStocks.push(bundleItemQty);
      } else bundleStocks.push(0);
    }

    // Use the minimum value in the array
    const bundleStock = bundleStocks.length > 0 ? Math.min(...bundleStocks) : 0;

    return Math.floor(bundleStock); // Round down to the nearest whole number
  };

  const stockBundle = calculateBundleStock(bundleList ?? [], "stock");

  const availableBundle = calculateBundleStock(bundleList ?? [], "available");

  const sumStockAndAvailableByBranch = (data: any[]): ResultItem[] => {
    const result: Record<number, ResultItem> = {};

    data.forEach((item) => {
      item.item_sku_detail.item_sku_qty.forEach((skuQty: any) => {
        const branchId = skuQty.warehouse.branch_id;
        const itemUniqueId = skuQty.item_unique_id;

        if (!itemUniqueId) {
          return;
        }

        if (!result[branchId]) {
          result[branchId] = {
            branch_id: branchId,
            branch_unique_id: skuQty.warehouse.branch.unique_id,
            branch_name: skuQty.warehouse.branch.name,
            item_name: item.bundle_item.name,
            items: [],
          };
        }

        const existingItemIndex = result[branchId].items.findIndex(
          (i) => i.item_unique_id === itemUniqueId && i.qty === item.qty
        );

        if (existingItemIndex !== -1) {
          // If an item with the same qty and item_unique_id already exists, update the stock_qty and available_qty
          result[branchId].items[existingItemIndex].stock_qty +=
            skuQty.stock_qty;
          result[branchId].items[existingItemIndex].available_qty +=
            skuQty.available_qty;
        } else {
          // If not, add a new item
          result[branchId].items.push({
            item_unique_id: itemUniqueId,
            qty: item.qty,
            stock_qty: skuQty.stock_qty,
            available_qty: skuQty.available_qty,
          });
        }
      });
    });

    return Object.values(result);
  };

  const sumStockAndAvailableByWarehouse = (
    data: any[]
  ): ResultItemWarehouse[] => {
    const result: Record<number, ResultItemWarehouse> = {};

    data.forEach((item) => {
      item.item_sku_detail.item_sku_qty.forEach((skuQty: any) => {
        const branchId = skuQty.warehouse.branch_id;
        const warehouseId = skuQty.warehouse.unique_id;
        const itemUniqueId = skuQty.item_unique_id;

        if (!itemUniqueId) {
          return;
        }

        if (!result[warehouseId]) {
          result[warehouseId] = {
            branch_id: branchId,
            warehouse_unique_id: warehouseId,
            branch_unique_id: skuQty.warehouse.branch.unique_id,
            branch_name: skuQty.warehouse.branch.name,
            warehouse_name: skuQty.warehouse.name,
            item_name: item.bundle_item.name,
            items: [],
          };
        }

        const existingItemIndex = result[warehouseId].items.findIndex(
          (i) => i.item_unique_id === itemUniqueId && i.qty === item.qty
        );

        if (existingItemIndex !== -1) {
          // If an item with the same qty and item_unique_id already exists, update the stock_qty and available_qty
          result[warehouseId].items[existingItemIndex].stock_qty +=
            skuQty.stock_qty;
          result[warehouseId].items[existingItemIndex].available_qty +=
            skuQty.available_qty;
        } else {
          // If not, add a new item
          result[warehouseId].items.push({
            item_unique_id: itemUniqueId,
            qty: item.qty,
            stock_qty: skuQty.stock_qty,
            available_qty: skuQty.available_qty,
          });
        }
      });
    });

    return Object.values(result);
  };

  const sumStockAndAvailableBylocation = (
    data: any[]
  ): ResultItemLocation[] => {
    const result: Record<number, ResultItemLocation> = {};

    data.forEach((item) => {
      item.item_sku_detail.item_sku_qty.forEach((skuQty: any) => {
        const branchId = skuQty.warehouse.branch_id;
        const binId = skuQty.bin_location?.id;
        const warehouseId = skuQty.warehouse.unique_id;
        const itemUniqueId = skuQty.item_unique_id;

        if (!itemUniqueId) {
          return;
        }

        if (!binId) {
          return;
        }

        if (!result[binId]) {
          result[binId] = {
            branch_id: branchId,
            warehouse_unique_id: warehouseId,
            branch_unique_id: skuQty.warehouse.branch.unique_id,
            branch_name: skuQty.warehouse.branch.name,
            warehouse_name: skuQty.warehouse.name,
            bin_id: binId,
            bin_name: skuQty.bin_location.name,
            item_name: item.bundle_item.name,
            items: [],
          };
        }

        const existingItemIndex = result[binId].items.findIndex(
          (i) => i.item_unique_id === itemUniqueId && i.qty === item.qty
        );

        if (existingItemIndex !== -1) {
          // If an item with the same qty and item_unique_id already exists, update the stock_qty and available_qty
          result[binId].items[existingItemIndex].stock_qty += skuQty.stock_qty;
          result[binId].items[existingItemIndex].available_qty +=
            skuQty.available_qty;
        } else {
          // If not, add a new item
          result[binId].items.push({
            item_unique_id: itemUniqueId,
            qty: item.qty,
            stock_qty: skuQty.stock_qty,
            available_qty: skuQty.available_qty,
          });
        }
      });
    });

    return Object.values(result);
  };

  const mapQtyAndStockQty = (type: string = "branch") => {
    const result =
      type === "branch"
        ? sumStockAndAvailableByBranch(bundleList ?? [])
        : type === "warehouse"
        ? sumStockAndAvailableByWarehouse(bundleList ?? [])
        : sumStockAndAvailableBylocation(bundleList ?? []);
    const finalResult = result.map((bundle_qty) => {
      if (bundle_qty.items.length !== bundleList?.length)
        return {
          ...bundle_qty,
          sku_name: id,
          stock_qty: 0,
          available_qty: 0,
        };
      else
        return {
          ...bundle_qty,
          sku_name: id,
          stock_qty: Math.min(
            ...bundle_qty.items.map((item) =>
              Math.floor(item.stock_qty / item.qty)
            )
          ),
          available_qty: Math.min(
            ...bundle_qty.items.map((item) =>
              Math.floor(item.available_qty / item.qty)
            )
          ),
        };
    });

    return finalResult.filter(
      (res) => res.branch_id === parseInt(branch_id ?? "") || is_hq === "true"
    );
  };

  const bundleSkuQty = {
    sku_qty_list: mapQtyAndStockQty(),
    sku_qty_list_by_warehouse: mapQtyAndStockQty("warehouse"),
    sku_qty_list_by_location: mapQtyAndStockQty("location"),
    stock_qty: stockBundle,
    available_qty: availableBundle,
  };

  return {
    skuByBranch: skuByBranch?.ItemSkuQtysByBranchView?.results?.[0],
    sku: data?.ItemSkuQtyBySkuView,
    bundleSkuQty,
  };
};
