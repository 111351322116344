import dayjs from "dayjs";
import { GraphQLClient } from "graphql-request";
import {
  PurchaseDocumentType,
  PurchaseRequestFindUniqueQuery,
} from "../../../generated/purchase";

import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IPurchaseItemList } from "../../../types/Purchase";
import { IPurchaseRequest } from "../../../types/Purchase/purchaseRequest";
import {
  IApprovalTemplate,
  IAttachment,
  IReviewedBy,
} from "../../../types/global";
import { uploadFileToS3 } from "../../s3";
import { v4 as uuidv4 } from "uuid";
import { SalesModelType } from "../../../generated/sales";
import { ISalesOrder } from "../../../types/Sales/salesOrder";
import { purchaseOrderSchema } from "../../../components/Form/Purchase/Order/schema";
import { IUser } from "../../../types/Auth/user";
import { formatAuthToUserPayload } from "../User";

export const purchaseRequestCreatePayloadFormatter = (
  data: IPurchaseRequest,
  status: string,
  approval_step?: number,
  reviewed_by?: IReviewedBy
) => {
  const { item_list, tag_list, created_date, last_updated_by, ...otherData } =
    data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, branch_id, ...otherItem }) => ({
      ...otherItem,
      reference_unique_id: otherData.unique_id,
      reference_document_type: PurchaseDocumentType.PurchaseRequest,
    })
  );

  const formatPayload = {
    ...otherData,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    approval_step: approval_step ? 1 : 0,
    reviewer_list: reviewed_by ? [reviewed_by] : [],
  };
  return formatPayload;
};

export const purchaseRequestUpdatePayloadFormatter = async (
  data: IPurchaseRequest,
  status: string,
  approval_step?: number,
  approver_list?: IApprovalTemplate[],
  isNotApprove?: boolean,
  reviewed_by?: IReviewedBy
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    branch,
    reviewer_list,
    ...otherData
  } = data;

  const formatItemList = item_list.map(
    ({
      uom_group,
      item_sku_qty,
      branch_id,
      purchase_request_id,
      ...otherItem
    }) => ({
      ...otherItem,
      reference_unique_id: unique_id,
      reference_document_type: PurchaseDocumentType.PurchaseRequest,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  let attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "purchase_request",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        attachment_list.push(formatAttachment);
      } else {
        attachment_list.push(file);
      }
    }
  }

  const formatFlag =
    !isNotApprove && status === "wait_approve"
      ? flag_status?.filter((flag) => flag !== "not_approved")
      : flag_status;

  const formatPayload = {
    ...otherData,
    approval_step: approval_step ? approval_step : 0,
    approver_list:
      approver_list && approver_list.length > 0
        ? approver_list
        : data.approver_list,
    reviewer_list: reviewer_list
      ? (data.aggrid_status === "wait_approve" ||
          (data.aggrid_status === "draft" &&
            approver_list &&
            approver_list.length === 0)) &&
        reviewed_by
        ? !isNotApprove
          ? [...reviewer_list, reviewed_by]
          : [...reviewer_list]
        : data.aggrid_status === "not_approved" && status === "wait_approve"
        ? []
        : [...reviewer_list]
      : [],
    item_list: formatItemList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    flag_status: formatFlag,
    attachment_list,
  };
  return formatPayload;
};

export const purchaseRequestQueryFormatter = async (
  data: PurchaseRequestFindUniqueQuery["PurchaseRequestFindUnique"]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const allItemListUniqueId =
    data.item_list && data.item_list.length > 0
      ? data.item_list.map((item) => item?.item_sku_name)
      : [];

  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });

  const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item?.item_sku_name
      );

      const itemType = item as IPurchaseItemList;

      formatItemList.push({
        ...itemType,
        uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
      });
    });
  }

  const formatPayload = {
    ...data,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    within_date: data.within_date ? dayjs(data.within_date) : undefined,
    expected_date: data.expected_date ? dayjs(data.expected_date) : undefined,
  } as IPurchaseRequest;

  return formatPayload;
};

export const copyPurchaseRequestFormatter = (
  data: IPurchaseRequest,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      within_date,
      expected_date,
      created_by,
      accepted_remark,
      attachment_list,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      branch,
      approval_step,
      approver_list,
      reviewer_list,
      related_user_list,

      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              unique_id,
              reference_line_item,
              po_qty,
              ...otherItemList
            }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
            })
          )
        : [];

    const formatRelatedUserList =
      created_by?.user_unique_id !== currentUser?.unique_id
        ? [created_by, ...(related_user_list || [])]
        : related_user_list;

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
      attachment_list: [],
      related_user_list: formatRelatedUserList,
      delivery_address: {},
    };
  }
};

export const createPurchaseOrderFromPurchaseRequest = async (
  data: IPurchaseRequest,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      within_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      accepted_remark,
      attachment_list,
      branch,
      approval_step,
      approver_list,
      reviewer_list,
      requester,
      related_user_list,
      ...otherData
    } = data;

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("wms");

    const allItemListUniqueId =
      data.item_list && data.item_list.length > 0
        ? data.item_list.map((item) => item?.item_sku_name)
        : [];

    const { itemSkuDetailsFindManyAggrid } =
      await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            sku_name: {
              filterType: "set",
              values: allItemListUniqueId,
            },
          },
        },
      });

    const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

    let formatItemList: IPurchaseItemList[] = [];

    if (data.item_list && data.item_list.length > 0) {
      data.item_list.forEach(({ id, purchase_request_id, ...item }) => {
        const foundItemIndex = itemSkuDetails.findIndex(
          (realItem: any) => realItem.sku_name === item?.item_sku_name
        );

        const itemType = item as IPurchaseItemList;

        const apiItem = itemSkuDetails[foundItemIndex];

        const uniqueId = uuidv4();

        formatItemList.push({
          ...itemType,
          unique_id: uniqueId,
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          reference_line_item: {
            line_item_unique_id: item.unique_id || "",
            line_item_document_unique_id: data.unique_id || "",
            line_item_document_type: PurchaseDocumentType.PurchaseRequest,
          },
          qty: item.qty - (item.po_qty || 0) || 0,
          price_per_unit: apiItem.item.purchase_standard_price,
          vat_percentage: "ไม่มี",
          withholding_tax_type: "ยังไม่ระบุ",
          uom_group: apiItem.item.uom_group,
          item_sku_qty: apiItem.item_sku_qty,
        });
      });
    }

    const related_user = related_user_list?.filter(
      (user) => user.user_unique_id !== currentUser?.unique_id
    );

    const formatRelatedUserList =
      created_by?.user_unique_id !== currentUser?.unique_id
        ? [created_by, ...(related_user || [])]
        : related_user;

    return {
      ...otherData,
      ...purchaseOrderSchema,
      reference_document_list: [
        {
          document_id: data.id,
          document_unique_id: data.unique_id,
          document_type: PurchaseDocumentType.PurchaseRequest,
        },
      ],
      item_list: formatItemList,
      created_by: formatAuthToUserPayload(currentUser),
      related_user_list: formatRelatedUserList,
      tag_list: otherData.tag_list,
      reference_no: otherData.reference_no,
    };
  }
};

export const createPurchaseRequestFromSalesOrder = (data: ISalesOrder) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      reference_no,
      branch,
      approval_step,
      approver_list,
      reviewer_list,
      tag_list,
      external_reference_no,
      item_price_list,
      item_price_list_id,
      net_amount,
      post_discount_amount,
      pre_vat_amount,
      price_vat_type,
      customer_details,
      customer_unique_id,
      sales_channel,
      sales_channel_id,
      sales_contact,
      vat_0_percent_amount,
      vat_7_percent_amount,
      vat_amount,
      vat_exempted_amount,
      withholding_tax_amount,
      sales_type,
      total_amount,
      credit_day,
      currency,
      additional_discount,
      additional_discount_type,
      contact_person_list,
      remark,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              reference_document_type,
              reference_unique_id,
              unique_id: item_unique_id,
              so_qty,
              ...otherItemList
            }) => ({
              unique_id: uuidv4(),
              reference_document_type: PurchaseDocumentType.PurchaseRequest,
              // reference_unique_id: data.unique_id,
              reference_line_item: {
                line_item_unique_id: item_unique_id,
                line_item_document_type: SalesModelType.SalesOrder,
                line_item_document_unique_id: data.unique_id,
              },
              item_unique_id: otherItemList.item_unique_id || "",
              item_sku_name: otherItemList.item_sku_name,
              item_name: otherItemList.item_name,
              item_sku_desc: otherItemList.item_sku_desc,
              tracability: otherItemList.tracability,
              branch_id: otherItemList.branch_id,
              qty: otherItemList.qty,
              uom_id: otherItemList.uom_id,
              remark: "",
              stock_qty: otherItemList.qty,
              uom_name: otherItemList.uom_name,
              uom_group: otherItemList.uom_group,
              item_sku_qty: otherItemList.item_sku_qty,
              item_img_url: otherItemList.item_img_url,
              barcode: otherItemList.barcode ?? "",
            })
          )
        : [];

    return {
      ...otherData,
      reference_document_list: [
        {
          document_id: data.id,
          document_unique_id: data.unique_id,
          document_type: SalesModelType.SalesOrder,
        },
      ],
      item_list: formatItemList,
    };
  }
};
