import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";

import { ReactInstance, useRef } from "react";

import PurchasePdfLayout from "../../../components/UI/PurchasePdf/PurchasePdfLayout";
import PurchaseRequestListPdfTable from "../../../components/Table/Pdf/PurchaseRequest";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { IPdfInfo } from "../../../types/global";
import { IPurchaseItemList } from "../../../types/Purchase";
import {
  usePurchaseRequestFindUniqueQuery,
  PurchaseRequestFindUniqueQuery,
} from "../../../generated/purchase";

import { formatDate } from "../../../utils/Date";
import { renderAddressString } from "../../../utils/Formatter/Global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useReactToPrint } from "react-to-print";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import {
  ApprovalTemplateFindByDocumentTypeQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
} from "../../../generated/general";

const PurchaseRequestPdf = () => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");
  const graphQLClientGeneral = createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    usePurchaseRequestFindUniqueQuery<PurchaseRequestFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      }
    );

  const { data: approvalTemplates, isLoading: isApprovalLoading } =
    useApprovalTemplateFindByDocumentTypeQuery<ApprovalTemplateFindByDocumentTypeQuery>(
      graphQLClientGeneral,
      {
        documentType: "purchase_request",
      }
    );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.PurchaseRequestFindUnique,
    "purchase_request",
    201,
    705,
    615,
    182.25,
    162.55,
    66.531
  );

  // const formatItemList = useSalesItemListFormatter(
  //   (data?.QuotationFindUnique.item_list as ISalesItemList[]) || []
  // );
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const highlightSection: IPdfInfo = {
    headers: ["เลขที่เอกสาร", "วันที่ออกเอกสาร", "ต้องการภายในวันที่"],
    info: [
      data?.PurchaseRequestFindUnique.unique_id || "-",
      data?.PurchaseRequestFindUnique.issue_date
        ? formatDate(data?.PurchaseRequestFindUnique.issue_date)
        : "-",
      data?.PurchaseRequestFindUnique.within_date
        ? formatDate(data?.PurchaseRequestFindUnique.within_date)
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: ["ผู้ขอ", "แผนก", "ตำแหน่ง", "ที่อยู่จัดส่งผู้รับ"],
    info: [
      `${data?.PurchaseRequestFindUnique.requester.unique_id} - ${data?.PurchaseRequestFindUnique.requester.name}`,
      data?.PurchaseRequestFindUnique.requester.department || "-",
      data?.PurchaseRequestFindUnique.requester.position || "-",
      renderAddressString(data?.PurchaseRequestFindUnique.delivery_address),
    ],
  };

  const rightSection: IPdfInfo = {
    headers: ["อ้างอิงถึง", "หมายเลขอ้างอิง"],
    info: [
      data?.PurchaseRequestFindUnique?.reference_document_list
        ?.map((list) => list.document_unique_id)
        .join(", ") || "-",
      data?.PurchaseRequestFindUnique.reference_no || "-",
    ],
  };

  if (id && (isLoading || isApprovalLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">ใบขอซื้อ</Typography>
        <CustomizedButton
          title="พิมพ์เอกสาร"
          variant="outlined"
          onClick={handlePrint}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <PurchasePdfLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={"ใบขอซื้อ"}
            data={data?.PurchaseRequestFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
            documentType="purchase_request"
            approvalTemplates={
              approvalTemplates?.ApprovalTemplateFindByDocumentType || []
            }
          >
            <PurchaseRequestListPdfTable
              itemList={(item as IPurchaseItemList[]) || []}
              startNumber={startNumbers[index]}
            />
          </PurchasePdfLayout>
        ))}
      </Box>
    </Box>
  );
};

export default PurchaseRequestPdf;
