import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider } from "@mui/material";

import { formatDate } from "../../../../utils/Date";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GoodsIssueQuery,
  useGoodsIssueQuery,
} from "../../../../generated/inventory";

import PDFLayout from "../../../../components/UI/Pdf/PDFLayout";
import PDFInfo from "../../../../components/UI/Pdf/PDFInfo";
import GoodsIssuePdfTable from "../../../../components/Table/Pdf/GoodsIssue/GoodsIssuePdfTable";

import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";
import {
  goodsIssueQueryFormatter,
  goodsIssueTypeValueFormatter,
} from "../../../../utils/Formatter/Inventory/GoodsIssue";

const GoodsIssuePdf = () => {
  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading } = useGoodsIssueQuery<GoodsIssueQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const leftHeader = [
    "เลขที่เอกสาร",
    "ประเภท",
    "เอกสารอ้างอิง",
    "สาขา",
    "คลัง",
  ];

  const leftInfo = [
    data?.GoodsIssue?.unique_id || "-",
    goodsIssueTypeValueFormatter(data?.GoodsIssue?.type ?? "") || "-",
    data?.GoodsIssue?.reference_unique_id || "-",
    data?.GoodsIssue?.branch.name || "-",
    data?.GoodsIssue?.warehouse?.name || "-",
  ];

  const rightHeader = ["วันที่สร้าง", "วันที่นำออก", "หมายเหตุ"];

  const rightInfo = [
    data?.GoodsIssue?.created_date
      ? formatDate(data?.GoodsIssue?.created_date)
      : "-",
    data?.GoodsIssue?.posted_date
      ? formatDate(data?.GoodsIssue?.posted_date)
      : "-",
    data?.GoodsIssue?.remark ?? "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบนำออก / Goods Issue"
      documentType="GI"
      data={data?.GoodsIssue}
      isApprove
      horizontal
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <GoodsIssuePdfTable
        itemList={
          data?.GoodsIssue
            ? goodsIssueQueryFormatter(data.GoodsIssue as IGoodsIssue)
                .trace_entry_list
            : []
        }
      />
    </PDFLayout>
  );
};

export default GoodsIssuePdf;
