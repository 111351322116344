import { QueryObserverResult } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  useFormContext,
} from "react-hook-form";
import { useStateContext } from "../../contexts/auth-context";
import {
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  TraceEntriesFindManyAggridQuery,
} from "../../generated/inventory";
import { ITraceEntry } from "../../types/Inventory";
import { IGoodsTransfer } from "../../types/Inventory/GoodsTransfer";
import { goodsTransferTraceEntryFormatter } from "../../utils/Formatter/Inventory/GoodsTransfer";

type Props = {
  fields: FieldArrayWithId<IGoodsTransfer, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsTransfer, "trace_entry_list">;
  update: UseFieldArrayUpdate<IGoodsTransfer, "trace_entry_list">;
  refetchTraceEntries: () => Promise<
    QueryObserverResult<TraceEntriesFindManyAggridQuery, unknown>
  >;
  refetchSkuQty: () => Promise<QueryObserverResult<ItemSkuQtysQuery, unknown>>;
  barcode: string;
};

export const useBarcodeSubmitHandler = ({
  fields,
  append,
  update,
  barcode,
  refetchTraceEntries,
  refetchSkuQty,
}: Props) => {
  const { getValues } = useFormContext<IGoodsTransfer>();
  const {
    state: { authUser },
  } = useStateContext();

  const barcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetchTraceEntries();
    const { data: skuQtyData } = await refetchSkuQty();
    const source_warehouse_id = getValues("source_warehouse_id");
    const type = getValues("type");

    const isAddedItem =
      fields &&
      fields.length > 0 &&
      fields.some(
        (trace) =>
          trace.item_unique_id === skuQtyData?.itemSkuQtys?.[0]?.sku_name
      ) &&
      type === "อื่นๆ";
    if (isAddedItem) {
      enqueueSnackbar(`QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`, {
        variant: "error",
        style: { whiteSpace: "pre-line" },
      });
    } else {
      if (skuQtyData?.itemSkuQtys?.length) {
        const itemSkuInWarehouse = skuQtyData.itemSkuQtys.filter(
          (itemSkuQty) =>
            itemSkuQty?.warehouse_id === Number(source_warehouse_id)
        );

        if (itemSkuInWarehouse.length) {
          const formatBinLocations = itemSkuInWarehouse.map((itemSku) => ({
            id: itemSku?.bin_id,
            name: itemSku?.bin_location?.name,
            stock_qty: itemSku?.stock_qty,
            warehouse_id: itemSku?.warehouse_id,
          }));

          const traceEntries = data?.TraceEntriesFindManyAggrid?.results;
          const traceEntry =
            traceEntries?.find(
              (trace) => trace?.type === InventoryDocumentType.GoodsReceive
            ) || null;
          if (traceEntry) {
            const traceEntryType = traceEntry as ITraceEntry;
            const formatTrace = goodsTransferTraceEntryFormatter(
              traceEntryType,
              formatBinLocations,
              authUser
            );

            if (!formatTrace) return;

            if (formatTrace?.tracability === Tracability.Serial) {
              const foundExisting = fields.findIndex(
                (trace) => trace.item_unique_id === formatTrace.item_unique_id
              );
              if (foundExisting === -1) {
                // append new trace (serial view)
                const {
                  posted_date,
                  serial_no,
                  source_bin_location,
                  source_bin_location_id,
                  scanned_by,
                  barcode,
                  destination_bin_location_id,
                  destination_scanned_by,
                  destination_scanned_date,
                  ...otherTrace
                } = formatTrace;

                const formatTraceWithSerial = {
                  ...otherTrace,
                  serial_list: [
                    {
                      ...otherTrace,
                      posted_date,
                      serial_no,
                      source_bin_location,
                      source_bin_location_id,
                      scanned_by,
                      barcode,
                      destination_bin_location_id,
                      destination_scanned_by,
                      destination_scanned_date,
                      qty: 0,
                    },
                  ],
                };
                append(formatTraceWithSerial);
              } else {
                // update serial
                const currentTrace: ITraceEntry = getValues(
                  `trace_entry_list.${foundExisting}`
                );

                if (currentTrace?.serial_list) {
                  const foundBarcode = currentTrace.serial_list.findIndex(
                    (serial) => serial.barcode === barcode
                  );

                  if (foundBarcode === -1) {
                    const { posted_qty, ...otherTrace } = formatTrace;
                    const formatTraceWithDocument: ITraceEntry = {
                      ...otherTrace,
                      posted_qty: currentTrace.posted_qty,
                    };
                    update(foundExisting, {
                      ...currentTrace,
                      serial_list: [
                        ...currentTrace.serial_list,
                        formatTraceWithDocument,
                      ],
                    });
                    enqueueSnackbar(
                      `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                      {
                        variant: "success",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  } else {
                    enqueueSnackbar(
                      `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                      {
                        variant: "error",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  }
                }
              }
            } else {
              const foundExisting = fields.findIndex(
                (trace) => trace.item_unique_id === formatTrace.item_unique_id
              );

              if (foundExisting === -1) {
                append(formatTrace);
                enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
                  variant: "success",
                  style: { whiteSpace: "pre-line" },
                });
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          }
        } else {
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
          variant: "error",
        });
      }
    }
  }, [
    append,
    authUser,
    barcode,
    fields,
    getValues,
    refetchSkuQty,
    refetchTraceEntries,
    update,
  ]);

  return barcodeSubmitHandler;
};
