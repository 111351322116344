import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useBranchUserGetByUserIdQuery } from "../generated/general";
import { useStateContext } from "../contexts/auth-context";
import { IBranch } from "../types/Setting/Branch";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
  let timeout: NodeJS.Timeout;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

export const useBranchFilter = () => {
  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { refetch: refetchBranchUser } = useBranchUserGetByUserIdQuery(
    graphQLClient,
    {
      userId: authUser?.id,
    },
    {
      enabled: false,
    }
  );

  const [branchList, setBranchList] = useState<IBranch[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<IBranch[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: branchUser } = await refetchBranchUser();
      const formatBranchList = branchUser?.BranchUserGetByUserId.map(
        (bn) => bn.branch
      ) as IBranch[];

      const sortByName = (a: IBranch, b: IBranch) => {
        // Use localeCompare for case-insensitive sorting
        return a.name.localeCompare(b.name);
      };

      const sortedBranchList = formatBranchList?.sort(sortByName) || [];

      setBranchList(sortedBranchList);
      setFilteredData(sortedBranchList);
    };

    if (authUser?.id) {
      fetchData();
    }
  }, [authUser?.id, refetchBranchUser]);

  const handleFilterChange = useCallback(
    (searchText: string) => {
      const filteredResults = branchList.filter(
        (branch) =>
          branch.unique_id.toLowerCase().includes(searchText.toLowerCase()) ||
          branch.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filteredResults);
    },
    [branchList]
  );

  const debouncedFilter = useMemo(
    () => debounce(handleFilterChange, 500),
    [handleFilterChange]
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
    debouncedFilter(e.target.value);
  };

  const filteredDataMemoized = useMemo(() => {
    return filteredData;
  }, [filteredData]);

  return {
    filterText,
    handleInputChange,
    filteredDataMemoized,
  };
};
