import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { useStateContext } from "./auth-context";
import {
  ActivityLog,
  ActivityLogCreateInput,
  useActivityLogCreateMutation,
} from "../generated/general";

type ActivityLogCreateInputWithoutCreatedBy = Pick<
  ActivityLogCreateInput,
  Exclude<keyof ActivityLogCreateInput, "created_by">
>;

interface IActivityLogContext {
  createActivityLog: (
    input: ActivityLogCreateInputWithoutCreatedBy
  ) => Promise<ActivityLog>;
  activityLogIsInit: boolean;
  setActivityLogIsInit: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingLog: boolean;
}

interface Props {
  children?: ReactNode;
}

export const ActivityLogContext = createContext<IActivityLogContext>({
  createActivityLog: async () => {
    throw new Error("Not implemented");
  },
  activityLogIsInit: false,
  setActivityLogIsInit: () => {},
  isCreatingLog: false,
});

export const ActivityLogContextProvider = ({ children }: Props) => {
  const [activityLogIsInit, setActivityLogIsInit] = useState<boolean>(false);
  const {
    state: { authUser },
  } = useStateContext();

  const graphqlClientGeneral = createGraphQLClientWithMiddleware("general");

  const { isLoading: isCreatingLog, mutateAsync } =
    useActivityLogCreateMutation<Error>(graphqlClientGeneral);

  const createActivityLog = useCallback(
    async (input: ActivityLogCreateInputWithoutCreatedBy) => {
      try {
        const { ActivityLogCreate } = await mutateAsync({
          data: {
            ...input,
            created_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          },
        });
        setActivityLogIsInit(false);
        return ActivityLogCreate;
      } catch (error) {
        console.log(error);
      }
    },
    [authUser, mutateAsync]
  );

  const activityLog = useMemo(
    () => ({
      createActivityLog,
      activityLogIsInit,
      setActivityLogIsInit,
      isCreatingLog,
    }),
    [createActivityLog, activityLogIsInit, setActivityLogIsInit, isCreatingLog]
  );

  return (
    <ActivityLogContext.Provider value={activityLog as IActivityLogContext}>
      {children}
    </ActivityLogContext.Provider>
  );
};

export default ActivityLogContext;
