import "simplebar-react/dist/simplebar.min.css";
import { forwardRef, ReactNode } from "react";
import SimpleBar from "simplebar-react";
import { styled, SxProps } from "@mui/material/styles";

interface Props {
  sx?: SxProps;
  children?: ReactNode;
  autoHide?: boolean;
  forceVisible?: boolean | "x" | "y";
}

const ScrollbarRoot = styled(SimpleBar)``;

const CustomizedScrollbar = forwardRef<any, Props>((props, ref) => {
  return (
    <ScrollbarRoot ref={ref} {...props}>
      {props.children}
    </ScrollbarRoot>
  );
});

export default CustomizedScrollbar;
