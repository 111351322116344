import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../../types/global";
import { useForm, FormProvider } from "react-hook-form";
import { IWarehouse } from "../../../../types/Setting/Inventory";
import LocationTab from "./LocationTab";
import InfoTab from "./InfoTab";
import CustomizedTab from "../../../../components/Custom/CustomizedTab";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import { useDisable } from "../../../../hooks/use-disable";
import BottomNavbar from "../../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import { Stack, Box, CircularProgress } from "@mui/material";
import CustomizedMenuOptions from "../../../../components/Custom/CustomizedMenuOptions";
import { useModal } from "../../../../hooks/use-modal";
import ConfirmationModal from "../../../../components/UI/Modal/ConfirmationModal";
import {
  useWarehouseCreateMutation,
  useWarehouseDeleteMutation,
  useWarehouseFindUniqueQuery,
  useWarehouseUpdateMutation,
} from "../../../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  warehouseCreateFormatter,
  warehouseQueryFormatter,
  warehouseUpdateFormatter,
} from "../../../../utils/Formatter/Warehouse";
import { useSnackbar } from "notistack";
import { errorMessageFormatter } from "../../../../utils/Global";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const warehouseSettingSchema: IWarehouse = {
  unique_id: "",
  address: {
    address_name: "",
    country: "thailand",
    district: "",
    is_same_as_branch: false,
    postal_code: "",
    province: "",
    sub_district: "",
  },
  branch_id: undefined,
  branch_unique_id: "",
  is_consignment: false,
  user_id: undefined,
  description: "",
  name: "",
  supervisor: "",
};

const addressValidation = Yup.object().shape({
  address_name: Yup.string().required("กรุณาระบุ"),
  district: Yup.string().required("กรุณาระบุ"),
  postal_code: Yup.string().required("กรุณาระบุ"),
  province: Yup.string().required("กรุณาระบุ"),
  sub_district: Yup.string().required("กรุณาระบุ"),
});

const warehouseSettingValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  branch_unique_id: Yup.string().required("กรุณาระบุ"),
  address: addressValidation,
});

const WarehouseContainer = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();
  const { id } = useParams();
  const [disabled, setDisabled] = useDisable();

  const [currentTab, setCurrentTab] = useState(pathname);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: false,
    },
    {
      value: "ลบ",
      disabled: false,
    },
  ];

  const tabs: ITab[] = [
    {
      label: "ข้อมูลทั่วไป",
      path: `${pathname}`,
    },
    {
      label: t("setting.inventory.location.index"),
      path: `${pathname}?tab=location`,
      disabled: !id,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "location":
        setCurrentTab(pathname + "?tab=location");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  const methods = useForm({
    defaultValues: warehouseSettingSchema,
    resolver: yupResolver<any>(warehouseSettingValidation),
  });

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { data, isSuccess, isLoading } = useWarehouseFindUniqueQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.warehouse.index"),
      to: "/setting/inventory/warehouse",
    },
    {
      name:
        id && Boolean(id)
          ? data && data.WarehouseFindUnique?.unique_id
            ? data.WarehouseFindUnique.unique_id
            : ""
          : t("button.add") + t("setting.inventory.warehouse.index"),
    },
  ];

  const { mutateAsync: create, isLoading: isCreating } =
    useWarehouseCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useWarehouseUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: deleteWarehouse, isLoading: isDeleting } =
    useWarehouseDeleteMutation<Error>(graphQLClient);

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (isSuccess) {
      const { WarehouseFindUnique } = data;
      const warehouseData = warehouseQueryFormatter(
        WarehouseFindUnique
      ) as IWarehouse;
      reset(warehouseData);
    }
  }, [data, isSuccess, reset]);

  const addWarehouseHandler = async (data: IWarehouse) => {
    try {
      const formatData = warehouseCreateFormatter(data);
      const { WarehouseCreate } = await create({
        data: formatData,
      });
      enqueueSnackbar(
        `${t("button.add")}${t("setting.inventory.warehouse.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
      navigate(`/setting/inventory/warehouse/${WarehouseCreate?.id}`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(
        formatError ||
          `${t("button.add")}${t(
            "setting.inventory.warehouse.index"
          )}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const updateWarehouseHandler = async (data: IWarehouse) => {
    try {
      const { id } = data;
      const formatData = warehouseUpdateFormatter(data);
      await update({
        uniqueInput: {
          id,
        },
        data: formatData,
      });
      enqueueSnackbar(
        `${t("sentence.edit")}${t("setting.inventory.warehouse.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
      setDisabled(true);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(
        formatError ||
          `${t("sentence.edit")}${t(
            "setting.inventory.warehouse.index"
          )}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const deleteWarehousehandler = async () => {
    try {
      await deleteWarehouse({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`ลบ${t("setting.inventory.warehouse.index")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/setting/inventory/warehouse");
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(
        formatError || `ลบ${t("setting.inventory.warehouse.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "location":
        return <LocationTab />;
      default:
        return <InfoTab address={data?.WarehouseFindUnique?.address} />;
    }
  };

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deleteWarehousehandler);

  if (id && (isLoading || isCreating || isUpdating || isDeleting)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        maxWidth={1040}
      >
        <CustomizedTab tabs={tabs} currentTab={currentTab} />
        <Box display="flex" justifyContent={"flex-end"} flexGrow={1}>
          <CustomizedMenuOptions
            size="small"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "แก้ไข":
                  setDisabled(false);
                  break;
                case "ลบ":
                  openConfirmationHandler();
                  break;

                default:
                  break;
              }
            }}
            disabled={!id}
          />
        </Box>
      </Box>
      {renderTab(tab)}
      <BottomNavbar>
        {!disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            {!!id && (
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={editCancelHandler}
                disabled={false}
              />
            )}
            <CustomizedButton
              variant="contained"
              title={t("button.save")}
              onClick={handleSubmit(
                id ? updateWarehouseHandler : addWarehouseHandler
              )}
              disabled={false}
            />
          </Stack>
        )}
      </BottomNavbar>
      <ConfirmationModal
        title={`ยันยืนการลบ${t("setting.inventory.warehouse.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </FormProvider>
  );
};

export default WarehouseContainer;
