import { IGoodsReceive } from "../../../../types/Inventory/GoodsReceive";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";

export const goodsReceiveSchema: IGoodsReceive = {
  unique_id: "",
  type: "purchase_order",
  reference_unique_id_list: [],
  warehouse_id: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: {},
  branch_id: undefined,
  main_status: "",
  aggrid_status: "",
  flag_status: [],
};

const goodsReceiveTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่นำเข้า")
    .required("กรุณาระบุจำนวนที่นำเข้า"),
});

const goodsReceiveSerialValidation = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .min(1, "กรุณาระบุจำนวนที่นำเข้า")
          .required("กรุณาระบุจำนวนที่นำเข้า")
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsReceiveTraceValidation)
          .min(1, "กรุณาเพิ่ม SN ในรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsReceiveValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  warehouse_id: Yup.number().required("กรุณาระบุคลัง"),
  reference_unique_id_list: Yup.array().when("type", (type, schema) => {
    if (type[0] && type[0] !== "other") {
      return schema
        .min(1, "กรุณาระบุเอกสารอ้างอิง")
        .required("กรุณาระบุเอกสารอ้างอิง");
    } else {
      return schema;
    }
  }),
  trace_entry_list: Yup.array()
    .of(goodsReceiveSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
