import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
// import { Warehouse } from "../../../../generated/inventory";
// import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
// import { GET_ALL_WAREHOUSES } from "../../../../services/AgGrid/InventoryAgGrid";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";

import { formatDate } from "../../../../utils/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
// import { goodsReceiveTypeValueFormatter } from "../../../../utils/Formatter/GoodsReceive";
import { IGoodsReceive } from "../../../../types/Inventory/GoodsReceive";
// import { GraphQLClient } from "graphql-request";
// import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

export const goodsReceiveColumnDefs = (
  t: TFunction,
  referenceUniqueId?: string
) => {
  const columnDef: ColDef[] = [
    {
      field: "created_date",
      headerName: t("date.issue_date"),
      sort: "desc",
      filter: "agDateColumnFilter",
      // filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("date.received_date"),
      filter: "agDateColumnFilter",
      // filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "unique_id",
      headerName: t("sales.unique_id"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "reference_unique_id_list",
      headerName: t("sentence.reference_unique_id"),
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value;
        } else {
          return;
        }
      },
      filterParams: {
        readOnly: !!referenceUniqueId,
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "branch_id",
      filter: "agNumberColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "branch_name",
      headerName: t("branch.index"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "warehouse_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      // filterParams: {
      //   values: async (params: any) => {
      //     const graphQLClientWithHeaderItem: GraphQLClient =
      //       createGraphQLClientWithMiddleware("wms");
      //     const { warehouses } = await graphQLClientWithHeaderItem.request(
      //       GET_ALL_WAREHOUSES
      //     );
      //     const warehousesName = warehouses.map(
      //       (warehouse: Warehouse) => warehouse.name
      //     );
      //     params.success(warehousesName);
      //   },
      // },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "created_by",
      headerName: t("inventory.createdBy"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams<any, string>) => {
        if (params.value) {
          if (params.data?.img_url && params.data?.img_url.length > 0) {
            return (
              <CustomizedAvatar
                avatars={[
                  {
                    unique_id: "",
                    first_name: params.value,
                    last_name: "",
                    img_url: params.data.img_url,
                  },
                ]}
              />
            );
          } else {
            return <CustomizedLetterAvatar name={params.value ?? ""} />;
          }
        }
      },
      minWidth: 150,
      flex: 1,
    },
    {
      field: "remark",
      headerName: t("sentence.remark"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "aggrid_status",
      headerName: t("status.index"),
      filter: "agSetColumnFilter",
      cellRenderer: (params: ICellRendererParams<IGoodsReceive, string>) => (
        <CustomizedStatus status={params.value} />
      ),
      filterParams: {
        values: [
          "draft",
          "finished",
          "cancelled",
          "wait_receive",
          "in_transit",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  return columnDef;
};
