import { gql } from "graphql-request";

export const BRANCHES_AGGRIDS = gql`
  query BranchesFindManyAggrid($aggridInput: AnyAggridInput) {
    BranchesFindManyAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        name
        short_name
        zone
        phone
        fax
        status
        is_hq
        created_date
        last_updated_date
        address
      }
    }
  }
`;
