import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../UI/AgGrid";
import useUserColumnDefs from "./useUserColumnDefs";
import {
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { USERS_AGGRID } from "../../../services/AgGrid/UserAgGrid";

const UserTable = () => {
  const gridRef = useRef();
  const navigate = useNavigate();

  const graphQLClientUser: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/user/account/${id}`);
  };

  const columnDefs = useUserColumnDefs();

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
      };
      try {
        const { UsersView } = await graphQLClientUser.request(USERS_AGGRID, {
          aggridInput: {
            startRow,
            endRow,
            filterModel: formatFilter,
            sortModel,
          },
        });
        params.success({
          rowData: UsersView.results as any[],
          rowCount: UsersView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const branch_id = sessionStorage.getItem("branch-id");
    const is_hq = sessionStorage.getItem("is_hq");

    const instance = params.api.getFilterInstance("branch_id");
    instance?.setModel({
      filterType: "number",
      type: "equals",
      filter: is_hq === "false" && branch_id ? parseInt(branch_id) : undefined,
    });

    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      path={"/user/account"}
      disabledSidebar
      onGridReady={onGridReady}
    />
  );
};

export default UserTable;
