import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Typography, Grid, IconButton, Box } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Fragment } from "react";
import { IContact } from "../../../types/Contact/contact";
import CustomizedButton from "../../Custom/CustomizedButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { contactChannelOptions } from "../../../utils/Global";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import { contactChannelFormatter } from "../../../utils/Formatter/Global";

const ContactChannel = () => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    name: "contact_channel_list",
    control,
  });

  const [disabled] = useDisable();

  const addContactChannel = () => {
    append({
      contact_channel_type: "",
      contact_channel_info: "",
    });
  };

  const removeContactChannel = (index: number) => {
    remove(index);
  };

  return (
    <CustomizedBox margin={0}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("contact.contact_channel.index")}
      </Typography>
      <Grid container spacing={1.5}>
        {fields.map((channel, index) => {
          if (index === 0 || index === 1) {
            return (
              <Fragment key={channel.id}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {disabled ? (
                    <LabelInput
                      label={t("contact.contact_channel.type")}
                      value={contactChannelFormatter(
                        getValues(
                          `contact_channel_list.${index}.contact_channel_type`
                        )
                      )}
                    />
                  ) : (
                    <ControlledSelect
                      name={`contact_channel_list.${index}.contact_channel_type`}
                      control={control}
                      label={t("contact.contact_channel.type")}
                      options={contactChannelOptions}
                      disabled={true}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledTextField
                    name={`contact_channel_list.${index}.contact_channel_info`}
                    control={control}
                    label={t("contact.contact_channel.info")}
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_channel_info
                    )}
                    helperText={
                      errors.contact_channel_list &&
                      errors.contact_channel_list?.[index]
                        ?.contact_channel_info &&
                      errors.contact_channel_list?.[index]?.contact_channel_info
                        ?.message
                    }
                    viewMode={disabled}
                  />
                </Grid>
              </Fragment>
            );
          } else {
            return (
              <Fragment key={channel.id}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {disabled ? (
                    <LabelInput
                      label={t("contact.contact_channel.type")}
                      value={contactChannelFormatter(
                        getValues(
                          `contact_channel_list.${index}.contact_channel_type`
                        )
                      )}
                    />
                  ) : (
                    <ControlledSelect
                      name={`contact_channel_list.${index}.contact_channel_type`}
                      control={control}
                      label={t("contact.contact_channel.type")}
                      options={contactChannelOptions}
                      error={Boolean(
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_type
                      )}
                      helperText={
                        errors.contact_channel_list &&
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_type &&
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_type?.message
                      }
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {!disabled ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ControlledTextField
                        name={`contact_channel_list.${index}.contact_channel_info`}
                        control={control}
                        label={t("contact.contact_channel.info")}
                        error={Boolean(
                          errors.contact_channel_list?.[index]
                            ?.contact_channel_info
                        )}
                        helperText={
                          errors.contact_channel_list &&
                          errors.contact_channel_list?.[index]
                            ?.contact_channel_info &&
                          errors.contact_channel_list?.[index]
                            ?.contact_channel_info?.message
                        }
                        viewMode={disabled}
                      />
                      <IconButton onClick={() => removeContactChannel(index)}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <ControlledTextField
                      name={`contact_channel_list.${index}.contact_channel_info`}
                      control={control}
                      label={t("contact.contact_channel.info")}
                      error={Boolean(
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_info
                      )}
                      helperText={
                        errors.contact_channel_list &&
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_info &&
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_info?.message
                      }
                      viewMode={disabled}
                    />
                  )}
                </Grid>
              </Fragment>
            );
          }
        })}
      </Grid>
      {!disabled && (
        <CustomizedButton
          sx={{
            mt: 2,
          }}
          title={t("button.add") + t("contact.contact_channel.index")}
          variant="outlined"
          onClick={addContactChannel}
        />
      )}
    </CustomizedBox>
  );
};

export default ContactChannel;
