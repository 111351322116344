import { TableCell, TableRow, Typography } from "@mui/material";
import { formatDate } from "../../../../utils/Date";

type Props = {
  serialList: any;
  fontSize?: number;
};

const GoodsReceiveSerialListPdf = ({ serialList, fontSize }: Props) => {
  const tablePadding = {
    px: 1,
    py: 1,
  };

  return (
    <>
      {serialList.map((serial: any, index: number) => (
        <TableRow key={serial.id}>
          <TableCell sx={tablePadding}></TableCell>
          <TableCell sx={tablePadding}></TableCell>
          <TableCell sx={tablePadding}></TableCell>
          <TableCell align="center" sx={tablePadding}>
            <Typography fontSize={fontSize ?? 12}>{serial.qty}</Typography>
            <Typography fontSize={fontSize ?? 12}>
              {serial.uom?.name_th}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={fontSize ?? 12}>
              {serial.serial_no}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={fontSize ?? 12}>{serial.barcode}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={fontSize ?? 12}>
              {serial.source_bin_location?.name}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={fontSize ?? 12}>{serial.remark}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={fontSize ?? 12}>
              {formatDate(serial.lot_date)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default GoodsReceiveSerialListPdf;
