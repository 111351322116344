import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IUser } from "../../../types/Auth/user";
import { useDisable } from "../../../hooks/use-disable";

const UserCredential = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IUser>();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [disabled] = useDisable();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <CustomizedBox>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        ข้อมูลผู้ใช้งาน
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            label={t("user.account.email")}
            control={control}
            name="email"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} />
        {!disabled && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    error={Boolean(errors.password)}
                  >
                    <InputLabel htmlFor="outlined-adornment-password1">
                      {t("user.account.password")}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password1"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label={t("user.account.password")}
                      {...field}
                    />
                    <FormHelperText
                      id="helper-text-password1"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {errors.password?.message?.toString() ||
                        "ตัวอักษรภาษาอังกฤษ A-Z, a-z, ตัวเลข 0-9, ความยาว 8 ตัวอักษรขึ้นไป"}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                type="password"
                label={t("user.account.confirm_password")}
                name="confirm_password"
                control={control}
                error={Boolean(errors.confirm_password)}
                helperText={errors.confirm_password?.message?.toString()}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default UserCredential;
