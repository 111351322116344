import * as AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { gql } from "graphql-request";

export const oldUploadFileToS3 = async (
  file: any,
  folder: string,
  userID: string
) => {
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
  // aws s3 config

  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const fileName = folder + new Date().toISOString() + userID + uuidv4();
  const photoKey = folder + "/" + fileName;

  const upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket:
        (ENVIRONMENT === "development"
          ? process.env.REACT_APP_AWS_DEV_FILE_UPLOAD_BUCKET_NAME
          : process.env.REACT_APP_AWS_PROD_FILE_UPLOAD_BUCKET_NAME) || "",
      Key: photoKey,
      Body: file,
      ContentType: file.type,
    },
  });

  const promise = await upload.promise();
  return promise;
};

const S3_UPLOAD_URL = gql`
  query Query($fileName: String) {
    S3GetUploadUrl(fileName: $fileName)
  }
`;

// const GET_UPLOADED_FILE = gql`
//   query Query($keyPath: String!) {
//     S3GetFileUrl(keyPath: $keyPath)
//   }
// `;

export const uploadFileToS3 = async (
  file: any,
  folder: string,
  userID: string
) => {
  const graphQLClient = createGraphQLClientWithMiddleware("general");
  try {
    // const fileName = folder + new Date().toISOString() + userID + uuidv4();
    const { S3GetUploadUrl } = await graphQLClient.request(S3_UPLOAD_URL, {
      fileName: `${folder}-${userID}`,
    });

    // const start_index: number =
    //   S3GetUploadUrl.indexOf(".com/") + ".com/".length;
    const end_index: number = S3GetUploadUrl.indexOf("?");

    try {
      await fetch(S3GetUploadUrl, {
        method: "PUT",
        body: file,
      });
      const keyPath: string = await S3GetUploadUrl.substring(0, end_index);

      // const { S3GetFileUrl } = await graphQLClient.request(GET_UPLOADED_FILE, {
      //   keyPath,
      // });

      const Location = keyPath;

      return {
        Location,
      };
    } catch (err) {
      console.error("error while put upload");
      console.error(err);
      return {
        Location: "",
      };
    }
  } catch (err) {
    console.error("error when request url");
    console.error(err);
    return {
      Location: "",
    };
  }
};

export const humanFileSize = (
  bytes: number,
  si: boolean = false,
  dp: number = 1
) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};
