import { useState } from "react";

export const useConfirmation = (action?: (params?: any) => void) => {
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const openConfirmationHandler = () => {
    setConfirmation(true);
  };

  const closeConfirmationHandler = () => {
    setConfirmation(false);
  };

  const submitConfirmationHandler = () => {
    if (action) {
      action();
    }
    closeConfirmationHandler();
  };

  return {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  };
};
