import { Box, IconButton, Typography } from "@mui/material";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";
import { IDefaultForm } from "../../../../types/global";
import { useEffect, useState } from "react";
import ItemQty from "../../../UI/ItemQty";
import { ItemSkuQty } from "../../../../generated/inventory";
import { useWatch } from "react-hook-form";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: boolean;
  field: any;
  index: number;
  documentType: string;
}

const ItemNameCell = ({
  control,
  field,
  errors,
  index,
  disabled,
  documentType,
}: Props) => {
  const [isSufficient, setIsSufficient] = useState<boolean>(true);

  const status = useWatch({
    control,
    name: `aggrid_status`,
  });

  const watchItemList = useWatch({
    control,
    name: `item_list.${index}`,
  });
  const watchUom = watchItemList.uom_name;

  const watchStockQty = watchItemList.stock_qty;

  const watchItemSkuQty = watchItemList.item_sku_qty;

  const watchItemSkuDetail = watchItemList.item_sku_desc || "";

  const filteredQty =
    watchItemSkuQty &&
    watchItemSkuQty.length > 0 &&
    watchItemSkuQty.map((itemSku: ItemSkuQty) => ({
      purchase_ordered_qty: itemSku?.purchase_ordered_qty,
      transit_qty: itemSku?.transit_qty,
      sale_committed_qty: itemSku?.sale_committed_qty,
      stock_qty: itemSku?.stock_qty,
      available_qty: itemSku?.available_qty,
    }));

  const calculateSum = (array: any[], property: string) => {
    let sum = 0;

    array.forEach((element) => {
      sum += element[property];
    });

    return sum;
  };

  useEffect(() => {
    const current_available_qty = calculateSum(
      filteredQty || [],
      "available_qty"
    );
    switch (documentType) {
      case "purchase_request": {
        const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
        const totalAvailableQty = current_available_qty + curr_qty;
        if (totalAvailableQty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
      }
      case "purchase_order": {
        switch (status) {
          case null:
          case "draft":
          case "wait_approve":
            const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
            const totalAvailableQty = current_available_qty + curr_qty;
            if (totalAvailableQty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
          default:
            if (current_available_qty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
        }
        break;
      }
      default:
        if (current_available_qty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
    }
  }, [documentType, filteredQty, setIsSufficient, status, watchStockQty]);

  const formatData: any[] = [
    {
      status: "กำลังจัดซื้อ",
      amount: calculateSum(filteredQty || [], "purchase_ordered_qty"),
    },
    {
      status: "ระหว่างทาง",
      amount: calculateSum(filteredQty || [], "transit_qty"),
    },
    {
      status: "ถูกจอง",
      amount: calculateSum(filteredQty || [], "sale_committed_qty"),
    },
    {
      status: "คงคลัง",
      amount: calculateSum(filteredQty || [], "stock_qty"),
    },
    {
      status: "คงเหลือสุทธิ",
      amount: calculateSum(filteredQty || [], "available_qty"),
    },
  ];

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const maxChars = 100;

  const focusHandler = () => {
    setIsFocused(true);
  };

  const blurHandler = () => {
    setIsFocused(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flex: 1,
          textAlign: "left",
        }}
      >
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            mt={1}
            color="primary"
            onClick={() =>
              window.open(
                `/inventory/items/${
                  field.item_sku_name || field.item_unique_id
                }?tab=item&subtab=general`,
                "_blank"
              )
            }
            sx={{ cursor: "pointer" }}
          >
            {field.item_name}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={documentType === "purchase_return" || disabled}
            placeholder="ชื่อสินค้า"
            control={control}
            error={Boolean(
              errors?.item_list &&
                errors?.item_list[index] &&
                errors?.item_list[index]?.item_name
            )}
            name={`item_list.${index}.item_name`}
            sx={{ mt: 1 }}
          />
        )}
        <Typography
          variant="overline"
          sx={{
            my: 0,
            ml: !disabled ? 2 : 0,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {field.item_sku_name}
        </Typography>
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            gutterBottom
            sx={{
              whiteSpace: "normal",
            }}
          >
            {field.item_sku_desc}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={documentType === "purchase_return" || disabled}
            control={control}
            name={`item_list.${index}.item_sku_desc`}
            placeholder="คำอธิบาย"
            multiline
            minRows={1}
            maxRows={5}
            sx={{ mb: 1 }}
            error={Boolean(
              errors?.item_list &&
                errors?.item_list[index] &&
                errors?.item_list[index]?.item_sku_desc
            )}
            viewModeNoHelperText
            helperText={
              isFocused ? `${watchItemSkuDetail.length}/${maxChars}` : undefined
            }
            onFocus={focusHandler}
            onBlur={blurHandler}
            FormHelperTextProps={{
              style: {
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 0,
              },
            }}
            inputProps={{
              maxLength: maxChars,
            }}
          />
        )}
      </Box>
      {!disabled && (
        <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
          <IconButton
            size="small"
            color="primary"
            onClick={() =>
              window.open(
                `/inventory/items/${field.item_sku_name}?tab=item&subtab=general`,
                "_blank"
              )
            }
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        </CustomizedTooltip>
      )}
      <CustomizedTooltip
        title={
          <ItemQty
            isSufficient={isSufficient}
            formatData={formatData}
            uom={watchUom}
          />
        }
      >
        <IconButton
          size="small"
          sx={{ color: isSufficient ? "#8FCBB1" : "#F44336", fontSize: "13px" }}
        >
          <CircleIcon fontSize="inherit" />
        </IconButton>
      </CustomizedTooltip>
    </Box>
  );
};

export default ItemNameCell;
