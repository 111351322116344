import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";

import { Box, Grid, Typography, IconButton } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledCheckbox from "../../../Controller/ControlledCheckbox";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { useUnitOptions } from "../../../../hooks/use-inventory-setting-option";
import LabelInput from "../../../UI/LabelInput";
import CheckedVendorModal from "../../../UI/Modal/CheckedVendorModal";
import ItemImg from "./ItemImg";
import ControlledSwitch from "../../../Controller/ControlledSwitch";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import {
  ItemType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

interface ICheckBox {
  name: string;
  label: string;
  testId: string;
  disabled?: boolean;
}

const Information = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled] = useDisable();
  const [checkBoxOptions, setCheckBoxOptions] = useState<ICheckBox[]>();
  const [vendorModal, setVendorModal] = useState<boolean>(false);
  const [vendorIds, setVendorIds] = useState<string[]>([]);
  const [vendorIdsSnapshot, setVendorIdsSnapshot] = useState<string[]>([]);
  const { state } = useLocation();

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    option: uoms,
    isLoading: isLoadingUom,
    renderOption,
  } = useUnitOptions();

  const itemType = useWatch({ control, name: "type" });
  const vendorInfo = useWatch({ control, name: "vendor_contact" });
  const isActive = useWatch({ control, name: "is_active" });

  const mapTypeToModelType = () => {
    if (itemType) {
      if (itemType === "service") return itemType;
      else return `item_${itemType}`;
    } else if (state) {
      if (state.type === "service") return state.type;
      else return `item_${state.type}`;
    } else return "";
  };

  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphqlClientWms,
      {
        modelType: mapTypeToModelType(),
      },
      {
        enabled: false,
        cacheTime: 0,
      }
    );

  const generateSellerUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("seller_unique_id", data?.UniqueIdGenerate ?? "");
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  const checkBoxField = useMemo(
    () => [
      {
        testId: "item-is-purchase-check-box",
        name: "is_purchasable",
        label: t("inventory.items.purchaseItem"),
      },
      {
        testId: "item-is-sales-check-box",
        name: "is_saleable",
        label: t("inventory.items.salesItem"),
      },
      {
        testId: "item-is-stock-check-box",
        name: "is_stockable",
        label: t("inventory.items.stockItem"),
        disabled: id ? true : false,
        // item.is_stockable,
      },
    ],
    [id, t]
  );

  const selectedCheckBox = useCallback(
    (itemType: string) => {
      switch (itemType) {
        case "service":
        case "bundle":
          return setCheckBoxOptions(checkBoxField.slice(0, 2));
        default:
          return setCheckBoxOptions(checkBoxField);
      }
    },
    [checkBoxField]
  );

  useEffect(() => {
    if (itemType) {
      selectedCheckBox(itemType);
    }
  }, [itemType, selectedCheckBox]);

  useEffect(() => {
    if (vendorInfo && vendorInfo.id) {
      if (vendorInfo.id) {
        setVendorIds([vendorInfo.id]);
        setVendorIdsSnapshot([vendorInfo.id]);
      }
    }
  }, [vendorInfo]);

  const openVendorModalhandler = () => {
    setVendorModal(true);
  };

  const closeVendorModalhandler = () => {
    setVendorModal(false);
  };

  useEffect(() => {
    if (!id) generateSellerUniqueId();
  }, [id, generateSellerUniqueId]);

  const finishVendorsSelect = (data: any) => {
    const formatVendor = {
      id: data.id,
      first_name: data.name,
      img_url: data.img_url,
      last_name: data.last_name,
    };
    setValue("vendor_contact", formatVendor);
    closeVendorModalhandler();
  };

  const changeSkuStatus = (status: boolean) => {
    if (itemType === ItemType.Variant) {
      const currentValue = getValues("variation_list"); // Replace with the actual field name

      const updateStatusRecursively = (items: any) => {
        return items.map((item: any) => {
          if (item.value && Array.isArray(item.value)) {
            item.value = updateStatusRecursively(item.value);
          }
          return { ...item, status: status };
        });
      };

      if (currentValue && Array.isArray(currentValue)) {
        const updatedValue = updateStatusRecursively(currentValue);
        setValue("variation_list", updatedValue); // Replace with the actual field name
      }
    }
  };

  return (
    <CustomizedBox margin="0 0 1.5rem 0">
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">{t("global.picture")}</Typography>
            <CustomizedTooltip title={""}>
              <ErrorOutlineIcon
                sx={{ color: (theme) => theme.palette.secondary.main }}
              />
            </CustomizedTooltip>
          </Box>
          <Box marginTop={2}>
            <ItemImg
              control={control}
              name="img_url"
              setValue={setValue}
              maxSize={10}
              disabled={disabled}
              acceptedFileType="image"
              isNotRevoke
            />
          </Box>
          <Box mt={2}>
            <ControlledSwitch
              control={control}
              name="is_active"
              label={t("status.active")}
              onChange={(e, field) => {
                field.onChange(!field.value);
                changeSkuStatus(!field.value);
              }}
              disabled={disabled}
            />
          </Box>
          {!isActive && (
            <>
              <Box mt={1.5}>
                <ControlledDatePicker
                  control={control}
                  name="inactive_date"
                  label={t("date.inactive_date")}
                  viewMode={disabled}
                />
              </Box>
              <ControlledTextField
                sx={{ mt: 1.5 }}
                name="remark_status"
                control={control}
                label={t("sentence.remark_status")}
                viewMode={disabled}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6">
            {t("inventory.items.description")}
          </Typography>
          <Grid container marginTop={2} spacing={1}>
            <Grid item xs={6}>
              <ControlledTextField
                control={control}
                name="seller_unique_id"
                label={t("inventory.items.unique_id")}
                error={Boolean(errors.seller_unique_id)}
                helperText={errors?.seller_unique_id?.message?.toString()}
                viewMode={disabled}
                disabled={Boolean(id)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              {!disabled && !id && (
                <CustomizedTooltip title="เรียกรหัสสินค้าใหม่">
                  <IconButton
                    id="item-btn-reload-unique-id"
                    data-test-id="item-btn-reload-unique-id"
                    onClick={generateSellerUniqueId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                control={control}
                name="sku"
                label={t("inventory.items.sku")}
                viewMode={disabled}
                disabled={Boolean(id)}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            {/* <Grid item xs={6}>
              <ControlledTextField
                control={control}
                name="unique_id"
                label={t("inventory.items.seller_unique_id")}
                error={Boolean(errors.unique_id)}
                helperText={errors?.unique_id?.message?.toString()}
                viewMode={disabled}
                disabled={true}
                required
              />
            </Grid> */}
            <Grid item xs={6}>
              <ControlledTextField
                control={control}
                name="name"
                label={t("inventory.items.name")}
                error={Boolean(errors.name)}
                helperText={errors?.name?.message?.toString()}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <ControlledTextField
                control={control}
                name="model"
                label={t("inventory.items.model")}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              {disabled ? (
                <LabelInput
                  label={t("inventory.items.stock_uom")}
                  value={renderOption(getValues("stock_uom_id"))}
                />
              ) : (
                <Controller
                  control={control}
                  name={"stock_uom_id"}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      testId="item-base-uom-field"
                      label={t("inventory.items.stock_uom")}
                      options={uoms}
                      onChange={(_, newValue) => {
                        field.onChange(newValue ? newValue.value : undefined);
                      }}
                      value={renderOption(field.value)}
                      error={Boolean(errors.stock_uom_id)}
                      helperText={errors?.stock_uom_id?.message?.toString()}
                      loading={isLoadingUom}
                      required
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6} alignSelf="center">
              {!disabled && (
                <CustomizedTooltip
                  title={
                    <Box>
                      <Typography fontWeight="bold" fontSize={14}>
                        {t("inventory.items.stock_uom")}
                      </Typography>
                      <Typography fontSize={14}>
                        {t("inventory.sentence.stock_uom_information")}
                      </Typography>
                    </Box>
                  }
                  isNoMaxWidth
                  placement="right"
                >
                  <ErrorOutlineIcon
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                  />
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                control={control}
                name="vendor_contact.first_name"
                label={t("contact.contact_type.vendor")}
                viewMode={disabled}
                disabled={true}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      disabled={disabled}
                      size="small"
                      onClick={openVendorModalhandler}
                    >
                      <EditIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
              {checkBoxOptions?.map((option, index) => (
                <ControlledCheckbox
                  key={index}
                  name={option.name}
                  control={control}
                  label={option.label}
                  disabled={option.disabled || disabled}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name="description"
                label={t("inventory.items.description")}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <CheckedVendorModal
        showSelectVendor={vendorModal}
        closeVendorTable={closeVendorModalhandler}
        finishVendorsSelect={finishVendorsSelect}
        vendorIds={vendorIds}
        setVendorIds={setVendorIds}
        vendorIdsSnapshot={vendorIdsSnapshot}
        setVendorIdsSnapshot={setVendorIdsSnapshot}
        rowSelection="single"
      />
    </CustomizedBox>
  );
};

export default Information;
