import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import CustomizedAddressBox from "../Custom/CustomizedAddressBox";
import { Dispatch, SetStateAction } from "react";
import LabelInput from "../UI/LabelInput";
import { useTranslation } from "react-i18next";

interface Props {
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name?: string;
  type: string;
  disabled?: boolean;
  addressValue: any;
  setAddressValue: Dispatch<SetStateAction<any>>;
  viewMode: boolean;
  error?: boolean;
  helperText?: string;
  clearErrorsHandler?: () => void;
  required?: boolean;
}

const ControlledAddressBox = ({
  name,
  type,
  disabled,
  getValues,
  setValue,
  addressValue,
  setAddressValue,
  viewMode,
  error,
  helperText,
  clearErrorsHandler,
  required,
}: Props) => {
  const { t } = useTranslation();
  return viewMode ? (
    <LabelInput
      label={t(`address.${type}`)}
      value={getValues(`${name}.${type}`)}
    />
  ) : (
    <CustomizedAddressBox
      value={addressValue}
      type={type}
      disabled={disabled}
      onChange={(_: any, data: any) => {
        setAddressValue(data);
        if (name) {
          setValue(`${name}.sub_district`, data.sub_district);
          setValue(`${name}.district`, data.district);
          setValue(`${name}.province`, data.province);
          setValue(`${name}.postal_code`, data.postal_code);
          setValue(`${name}.country`, "thailand");
        } else {
          setValue(`sub_district`, data.sub_district);
          setValue(`district`, data.district);
          setValue(`province`, data.province);
          setValue(`postal_code`, data.postal_code);
          setValue(`country`, "thailand");
        }

        if (clearErrorsHandler) {
          clearErrorsHandler();
        }
      }}
      onInputChange={(value: string) => {
        if (name) {
          if (value.length === 0) {
            setValue(`${name}.${type}`, value, { shouldValidate: true });
          } else {
            setValue(`${name}.${type}`, value);
          }
        } else {
          if (value.length === 0) {
            setValue(`${type}`, value, { shouldValidate: true });
          } else {
            setValue(`${type}`, value);
          }
        }
      }}
      error={error}
      helperText={helperText}
      required={required}
    />
  );
};

export default ControlledAddressBox;
