import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormContext } from "react-hook-form";

import { Divider, Grid, Stack } from "@mui/material";

import ModalUI from "./ModalUI";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";
import ControlledRadioGroup from "../../Controller/ControlledRadioGroup";

import { countryOptions } from "../../../utils/Global";
import { IDeliveryAddress } from "../../../types/global";
import { useAddressOptions } from "../../../hooks/use-address-options";

type Props = {
  open: boolean;
  handleClose: () => void;
  viewMode: boolean;
  name: string;
};

const PurchaseDeliveryAddressModal = ({
  open,
  handleClose,
  viewMode,
  name,
}: Props) => {
  const { setValue: setContextValue, getValues: getContextValues } =
    useFormContext();

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<IDeliveryAddress>({
    defaultValues: {
      type: "company",
      company_address_name: "",
      branch_id: undefined,
      branch_name: "",
      warehouse_id: undefined,
      warehouse_name: "",
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  });

  const watchType = watch("type");
  const watchCompanyAddressName = watch("company_address_name");
  const watchBranch = watch("branch_id");
  const [addressValue, setAddressValue] = useState<any>("");

  const {
    branch,
    branchByUser,
    companyAddressOption,
    companyAddresList,
    warehouseOptions,
    warehouses,
  } = useAddressOptions(watchBranch);

  const getAddressFromBranch = useCallback(async () => {
    if (branch.branchAddress || branch.branchId || branch.branchName) {
      const {
        address_name,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = branch.branchAddress;
      setValue("branch_id", branch.branchId);
      setValue("branch_name", branch.branchName);
      setValue("address_name", address_name);
      setValue("sub_district", sub_district);
      setValue("district", district);
      setValue("province", province);
      setValue("postal_code", postal_code);
      setValue("country", country);
      setAddressValue(branch.branchAddress);
    }
  }, [branch.branchAddress, branch.branchId, branch.branchName, setValue]);

  useEffect(() => {
    if (watchType) {
      setValue("company_address_name", "");
      setValue("branch_id", null);
      setValue("branch_name", "");
      setValue("warehouse_id", undefined);
      setValue("warehouse_name", "");
      setValue("address_name", "");
      setValue("sub_district", "");
      setValue("district", "");
      setValue("province", "");
      setValue("postal_code", "");
      setValue("country", "");
      setAddressValue("");
    }
  }, [setValue, watchType]);

  useEffect(() => {
    if (watchType === "company" && !watchCompanyAddressName) {
      setValue("company_address_name", "ที่อยู่จดทะเบียน");
      const findAddress: any = companyAddresList?.find(
        (address) => address.type === "ที่อยู่จดทะเบียน"
      );

      if (findAddress) {
        const { contact_name, contact_phone, fax, is_default, ...otherData } =
          findAddress;

        setValue("address_name", otherData.address_name);
        setValue("sub_district", otherData.sub_district);
        setValue("district", otherData.district);
        setValue("province", otherData.province);
        setValue("postal_code", otherData.postal_code);
        setValue("country", otherData.country);
        setAddressValue(otherData);
      }
    }
  }, [setValue, watchType, watchCompanyAddressName, companyAddresList]);

  useEffect(() => {
    if (watchType) {
      if (watchType === "branch") {
        getAddressFromBranch();
      }
    }
  }, [getAddressFromBranch, watchType]);

  useEffect(() => {
    if (open) {
      const currentAddress = getContextValues(`delivery_address`);
      setAddressValue(currentAddress);
      reset(currentAddress);
    }
  }, [getContextValues, name, open, reset]);

  const onSubmitHandler = (data: IDeliveryAddress) => {
    setContextValue(`delivery_address`, data);
    handleClose();
  };

  const { t } = useTranslation();

  const radioLists = [
    { value: "company", label: "ที่อยู่บริษัท" },
    { value: "branch", label: "ที่อยู่สาขา" },
    { value: "warehouse", label: "ที่อยู่คลัง" },
  ];

  const renderOptions = (type?: string) => {
    switch (type) {
      case "warehouse":
        return (
          <>
            <Grid item sm={12} md={4}>
              <ControlledSelect
                control={control}
                name="branch_id"
                label={t("branch.index")}
                options={
                  branchByUser?.map((branch) => ({
                    label: branch.branch?.name || "",
                    value: branch.branch_id || 0,
                  })) || []
                }
                onChange={(e: any) => {
                  setValue("warehouse_id", undefined);
                  setValue("warehouse_name", "");
                  setValue("address_name", "");
                  setValue("sub_district", "");
                  setValue("district", "");
                  setValue("province", "");
                  setValue("postal_code", "");
                  setValue("country", "");
                  setAddressValue("");
                }}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <ControlledSelect
                control={control}
                name="warehouse_id"
                label={t("inventory.warehouse")}
                options={warehouseOptions}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value) {
                    const findAddress: any = warehouses.find(
                      (warehouse) => warehouse.id === value
                    );

                    const { address } = findAddress;

                    if (address) {
                      setValue("address_name", address.address_name);
                      setValue("sub_district", address.sub_district);
                      setValue("district", address.district);
                      setValue("province", address.province);
                      setValue("postal_code", address.postal_code);
                      setValue("country", address.country);
                      setAddressValue(address);
                    }
                  }
                }}
                disabled={!Boolean(watchBranch)}
              />
            </Grid>
          </>
        );
      case "company":
        return (
          <Grid item sm={12} md={4}>
            <ControlledSelect
              control={control}
              name="company_address_name"
              label={t("button.option")}
              options={companyAddressOption}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value) {
                  const findAddress: any = companyAddresList.find(
                    (address) => address.type === value
                  );

                  const {
                    contact_name,
                    contact_phone,
                    fax,
                    is_default,
                    ...otherData
                  } = findAddress;

                  setValue("address_name", otherData.address_name);
                  setValue("sub_district", otherData.sub_district);
                  setValue("district", otherData.district);
                  setValue("province", otherData.province);
                  setValue("postal_code", otherData.postal_code);
                  setValue("country", otherData.country);
                  setAddressValue(otherData);
                }
              }}
            />
          </Grid>
        );

      default:
        break;
    }
  };

  return (
    <ModalUI
      open={open}
      handleClose={handleClose}
      title={t("address.delivery_address")}
      maxWidth="md"
      action={
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            title={t("button.save")}
            onClick={handleSubmit(onSubmitHandler)}
            variant="contained"
          />
        </Stack>
      }
    >
      <Divider />
      <ControlledRadioGroup
        control={control}
        name="type"
        sx={{ marginY: 1 }}
        radioLists={radioLists}
        disabled={false}
        row
      />
      <Grid container spacing={1.5} mb={1.5}>
        {renderOptions(watchType)}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            name={`address_name`}
            control={control}
            label={t("contact.address.index")}
            error={Boolean(errors.address_name)}
            helperText={errors.address_name && errors.address_name?.message}
            rows={4}
            multiline
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="sub_district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="province"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="postal_code"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSelect
            name={`country`}
            control={control}
            label={t("contact.address.country")}
            error={Boolean(errors.country)}
            helperText={errors.country && errors.country?.message}
            options={countryOptions}
            disabled={true}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default PurchaseDeliveryAddressModal;
