import { useTranslation } from "react-i18next";
import {
  useBinLocationCreateMutation,
  useBinLocationDeleteMutation,
  useBinLocationUpdateMutation,
  useWarehouseLevel1CreateMutation,
  useWarehouseLevel1DeleteMutation,
  useWarehouseLevel1UpdateMutation,
  useWarehouseLevel2CreateMutation,
  useWarehouseLevel2DeleteMutation,
  useWarehouseLevel2UpdateMutation,
  useWarehouseLevel3CreateMutation,
  useWarehouseLevel3DeleteMutation,
  useWarehouseLevel3UpdateMutation,
  useWarehousesFindAllQuery,
} from "../generated/general";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { useSnackbar } from "notistack";

interface IDefault {
  value: string;
}

export const useInventoryLocation = (
  branch_id?: number,
  selectedLocation?: any[],
  handleClose?: () => void
) => {
  const { t } = useTranslation();
  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    refetch,
  } = useWarehousesFindAllQuery(
    graphQLClient,
    {
      whereInput: {
        branch_id,
      },
    },
    {
      enabled: !!branch_id,
    }
  );

  const { mutateAsync: warehouseLv1Create } =
    useWarehouseLevel1CreateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv2Create } =
    useWarehouseLevel2CreateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv3Create } =
    useWarehouseLevel3CreateMutation<Error>(graphQLClient);

  const { mutateAsync: binlocationCreate } =
    useBinLocationCreateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv1Update } =
    useWarehouseLevel1UpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv2Update } =
    useWarehouseLevel2UpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv3Update } =
    useWarehouseLevel3UpdateMutation<Error>(graphQLClient);

  const { mutateAsync: binlocationUpdate } =
    useBinLocationUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv1Delete } =
    useWarehouseLevel1DeleteMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv2Delete } =
    useWarehouseLevel2DeleteMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv3Delete } =
    useWarehouseLevel3DeleteMutation<Error>(graphQLClient);

  const { mutateAsync: binlocationDelete } =
    useBinLocationDeleteMutation<Error>(graphQLClient);

  const handleSubmitCreate = async (data: IDefault) => {
    try {
      if (selectedLocation?.length === 1) {
        const input = {
          name: data.value,
          warehouse_id: selectedLocation[0].id,
        };
        await warehouseLv1Create({ data: input });
      } else if (selectedLocation?.length === 2) {
        const input = {
          name: data.value,
          warehouse_level_1_id: selectedLocation[1].id,
        };
        await warehouseLv2Create({ data: input });
      } else if (selectedLocation?.length === 3) {
        const input = {
          name: data.value,
          warehouse_level_2_id: selectedLocation[2].id,
        };
        await warehouseLv3Create({ data: input });
      } else if (selectedLocation?.length === 4) {
        const input = {
          name: data.value,
          warehouse_level_3_id: selectedLocation[3].id,
        };
        await binlocationCreate({ data: input });
      }
      if (handleClose) {
        handleClose();
      }
      enqueueSnackbar(`เพิ่ม${t("setting.inventory.location.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(
        `เพิ่ม${t("setting.inventory.location.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitUpdate = async (data: IDefault) => {
    try {
      if (selectedLocation?.length === 2) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[1].id,
          },
        };
        await warehouseLv1Update(input);
      } else if (selectedLocation?.length === 3) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[2].id,
          },
        };
        await warehouseLv2Update(input);
      } else if (selectedLocation?.length === 4) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[3].id,
          },
        };
        await warehouseLv3Update(input);
      } else if (selectedLocation?.length === 5) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[4].id,
          },
        };
        await binlocationUpdate(input);
      }
      if (handleClose) {
        handleClose();
      }
      enqueueSnackbar(`แก้ไข${t("setting.inventory.location.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(
        `แก้ไข${t("setting.inventory.location.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitDelete = async () => {
    try {
      if (selectedLocation?.length === 2) {
        const input = {
          uniqueInput: {
            id: selectedLocation[1].id,
          },
        };
        await warehouseLv1Delete(input);
      } else if (selectedLocation?.length === 3) {
        const input = {
          uniqueInput: {
            id: selectedLocation[2].id,
          },
        };
        await warehouseLv2Delete(input);
      } else if (selectedLocation?.length === 4) {
        const input = {
          uniqueInput: {
            id: selectedLocation[3].id,
          },
        };
        await warehouseLv3Delete(input);
      } else if (selectedLocation?.length === 5) {
        const input = {
          uniqueInput: {
            id: selectedLocation[4].id,
          },
        };
        await binlocationDelete(input);
      }
      if (handleClose) {
        handleClose();
      }
      enqueueSnackbar(`ลบ${t("setting.inventory.location.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`ลบ${t("setting.inventory.location.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  return {
    isLoading: isWarehousesLoading,
    warehouses: warehouses?.WarehousesFindAll,
    refetch,
    handleSubmitCreate,
    handleSubmitUpdate,
    handleSubmitDelete,
  };
};
