import * as Yup from "yup";
import dayjs from "dayjs";
import { IQuotation } from "../../../../types/Sales/quotation";
import { AdditionalDiscountType } from "../../../../generated/sales";
import { PriceVatType } from "../../../../generated/sales";
import { itemListSchema } from "../itemListSchema";

export const quotationSchema: IQuotation = {
  unique_id: "",
  created_by: null,
  created_date: dayjs(),
  credit_day: undefined,
  issue_date: dayjs(),
  due_date: dayjs(),
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  price_vat_type: PriceVatType.IncludeVat,
  currency: "THB",
  customer_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      type: "",
      contact_name: "",
      contact_phone: "",
      fax: "",
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  item_list: [],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  post_discount_amount: 0,
  total_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  customer_unique_id: "",
  withholding_tax_amount: 0,
  reference_no: "",
  tag_list: [],
  item_price_list_id: 0,
  sales_channel: undefined,
  accepted_date: dayjs(),
  accepted_remark: "",
  attachment_list: [],
  branch_id: undefined,
  contact_person_list: [],
};

export const quotationValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  credit_day: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  customer_unique_id: Yup.string().required("กรุณาเลือกลูกค้า"),
  item_list: Yup.array()
    .of(itemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  branch_id: Yup.number().required("กรุณาเลือกสาขา"),
  price_vat_type: Yup.string().required("กรุณาเลือกประเภทราคา"),
  currency: Yup.string().required("กรุณาเลือกสกุลเงิน"),
  item_price_list_id: Yup.number().required("กรุณาเลือกรายการราคา"),
  additional_discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
});
