import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { ITraceEntry } from "../../../types/Inventory";
import { IGoodsReturn } from "../../../types/Inventory/GoodsReturn";
import {
  InventoryDocumentType,
  Tracability,
} from "../../../generated/inventory";
import { IUser } from "../../../types/Auth/user";

export const goodsReturnCreatePayloadFormatter = (
  data: IGoodsReturn,
  status: string
) => {
  const {
    created_date,
    main_status,
    aggrid_status,
    trace_entry_list,
    warehouse_id,
    branch_id,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            source_bin_location,
            destination_bin_location,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_id: uom?.id,
            reference_unique_id: data.unique_id,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_id: uom?.id,
          reference_unique_id: data.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      const {
        serial_list,
        uom,
        source_bin_location,
        destination_bin_location,
        ...otherTrace
      } = trace;
      const formatTrace = {
        ...otherTrace,
        qty:
          typeof otherTrace.qty === "string"
            ? parseInt(otherTrace.qty)
            : otherTrace.qty,
        uom_id: uom?.id,
        reference_unique_id: data.unique_id,
      };
      allTraceEntries.push(formatTrace);
    }
  });

  const payload = {
    ...otherPayload,
    main_status: status,
    trace_entry_list: allTraceEntries,
    warehouse_id:
      typeof warehouse_id === "string" ? parseInt(warehouse_id) : warehouse_id,
    branch_id: branch_id as number,
  };

  return payload;
};

const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const goodsReturnQueryFormatter = (data: IGoodsReturn) => {
  const { trace_entry_list, created_date, posted_date, ...otherData } = data;
  const groupedTrace = groupBy(trace_entry_list, "item_unique_id");

  const allTraceEntries: ITraceEntry[] = [];
  for (const entry of Object.entries(groupedTrace)) {
    const key = entry[0] as string;
    const value = entry[1] as ITraceEntry[];

    const formatSerial = value.map((serial) => ({
      ...serial,
      posted_date: serial.posted_date ?? undefined,
      scanned_by: serial.scanned_by ?? undefined,
    }));

    const formatTrace: ITraceEntry = {
      item_unique_id: key,
      item_name: value[0].item_name,
      item_img_url: value[0].item_img_url,
      tracability: value[0].tracability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      is_stockable: value[0].is_stockable,
      sku_name: value[0].sku_name,
      posted_date:
        value[0].tracability === Tracability.Normal
          ? value[0].posted_date
          : undefined,
      created_date: value[0].created_date,
      uom: value[0].uom,
      source_bin_location:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].tracability === Tracability.Normal
          ? value[0].barcode
          : undefined,
      serial_list:
        value.some((serial) => serial.tracability === Tracability.Normal) ||
        value[0].qty === 0
          ? undefined
          : formatSerial,
      status:
        value[0].tracability === Tracability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].tracability === Tracability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      remark: value[0].remark,
    };
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
  };
};

export const goodsReturnTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocation: any,
  user: IUser | null
) => {
  const {
    type,
    posted_date,
    created_date,
    scanned_by,
    unique_id,
    source_bin_location,
    source_bin_location_id,
    ...otherPayload
  } = trace;
  if (user) {
    const formatTrace: ITraceEntry = {
      ...otherPayload,
      unique_id: uuidv4(),
      type: InventoryDocumentType.GoodsReturn,
      scanned_by: {
        user_unique_id: user.unique_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        img_url: user.img_url,
      },
      source_bin_location: binLocation,
      source_bin_location_id: binLocation.id,
      posted_date: dayjs().toDate(),
    };
    return formatTrace;
  }
  return;
};

export const goodsReturnUpdatePayloadFormatter = (
  data: IGoodsReturn,
  status: string
) => {
  const {
    created_date,
    unique_id,
    main_status,
    aggrid_status,
    warehouse,
    warehouse_id,
    trace_entry_list,
    created_by,
    branch,
    id,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_id: uom?.id,
            reference_unique_id: data.unique_id,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          created_date,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_id: uom?.id,
          reference_unique_id: data.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      const {
        serial_list,
        uom,
        source_bin_location,
        destination_bin_location,
        created_date,
        ...otherTrace
      } = trace;
      const formatTrace = {
        ...otherTrace,
        qty:
          typeof otherTrace.qty === "string"
            ? parseInt(otherTrace.qty)
            : otherTrace.qty,
        uom_id: uom?.id,
        reference_unique_id: data.unique_id,
      };
      allTraceEntries.push(formatTrace);
    }
  });
  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
    main_status: status,
    warehouse_id:
      typeof warehouse_id === "string" ? parseInt(warehouse_id) : warehouse_id,
  };
  return payload;
};

export const goodsReturnTypeValueFormatter = (type: string) => {
  switch (type) {
    case "sales_return":
      return "รับคืนจากลูกค้า";
    default:
      return "";
  }
};
