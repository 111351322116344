import { Box, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { formatDate } from "../../../utils/Date";
import { IReviewedBy } from "../../../types/global";

interface Props {
  documentType?: string;
  isApprove?: boolean;
  horizontal?: boolean;
  data: any;
}

const PDFSigner = ({ data, documentType, isApprove, horizontal }: Props) => {
  const getReviewerName = (index: number) => {
    const reviewerId: IReviewedBy =
      data.reviewer_list && data.reviewer_list[index];
    if (reviewerId) {
      const approver = findApproverById(reviewerId.user_id, index);
      return approver ? `${approver.first_name} ${approver.last_name}` : null;
    }
    return "_________________";
  };

  const findApproverById = (reviewerId: number | undefined, index: number) => {
    if (data.approver_list && data.approver_list.length > 0)
      return data.approver_list[index].approver_list.find(
        (approver: any) => approver.id === reviewerId
      );
  };

  return (
    <>
      {documentType === "delivery_trip" ? (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <QRCodeSVG value={window.location.href} size={72} />
            <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสารออนไลน์</Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: horizontal ? "285.3mm" : "197.3mm",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <QRCodeSVG value={window.location.href} size={72} />
            <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสารออนไลน์</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              ผู้ออกเอกสาร
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              {data?.created_by?.first_name} {data?.created_by?.last_name}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              {formatDate(data?.created_date)}
            </Typography>
          </Box>
          {isApprove &&
            data?.approver_list &&
            data?.approver_list?.map((list: any, index: number) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                  ผู้อนุมัติ
                  {data.approver_list.length > 0 && "ที่ " + index + 1}
                </Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                  {getReviewerName(index)}
                </Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                  _____/_____/_____
                </Typography>
              </Box>
            ))}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType === "delivery_order"
                ? "สโตร์"
                : documentType === "GT"
                ? "ผู้ตรวจสอบ"
                : documentType === "GI"
                ? "ผู้รับสินค้า"
                : "ผู้ส่งสินค้า"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PDFSigner;
