// import React, { useEffect, useMemo } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import Conversion from "./Conversion";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

import { useDisable } from "../../../../hooks/use-disable";
import RadioBox from "../../../UI/RadioBox";
import { ItemType, Tracability } from "../../../../generated/inventory";
import { useUnitOptions } from "../../../../hooks/use-inventory-setting-option";
import LabelInput from "../../../UI/LabelInput";
import { useParams } from "react-router-dom";

const Warehouse = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [disabled] = useDisable();

  const { option, renderOption } = useUnitOptions();

  const isStock = useWatch({ control, name: "is_stockable" });
  const tracability = useWatch({ control, name: "tracability" });
  const itemType = useWatch({ control, name: "type" });

  const trackSerialList = [
    {
      label: "ติดตาม Batch/Serial Number",
      value: "serial",
    },
    {
      label: "ติดตามแบบทั่วไป",
      value: "normal",
    },
  ];

  const warehouseUomList = [
    {
      testId: "item-width-text-field",
      label: t("inventory.items.sales_uom"),
      name: "sales_uom_id",
      errors: errors.sales_uom_id,
    },
    {
      testId: "item-length-text-field",
      label: t("inventory.items.purchase_uom"),
      name: "purchase_uom_id",
      errors: errors.purchase_uom_id,
    },
    {
      testId: "item-height-text-field",
      label: t("inventory.items.deliver_uom"),
      name: "deliver_uom_id",
      errors: errors.deliver_uom_id,
    },
  ];

  return (
    <>
      <Box>
        {isStock && ![ItemType.Bundle, ItemType.Service].includes(itemType) && (
          <Fragment>
            <Box my={2} ml={1} display={"flex"} alignItems="center">
              <Typography variant="h6">การติดตามสินค้า</Typography>
              <CustomizedTooltip
                title={
                  <Box>
                    <Box display="flex">
                      <Typography fontWeight="bold" fontSize={14}>
                        {t("inventory.sentence.tracability.normal")}
                      </Typography>
                      <Typography fontSize={14}>
                        : {t("inventory.sentence.tracability.detail_normal")}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography fontWeight="bold" fontSize={14}>
                        {t("inventory.sentence.tracability.serial")}
                      </Typography>
                      <Typography fontSize={14}>
                        : {t("inventory.sentence.tracability.detail_serial")}
                      </Typography>
                    </Box>
                  </Box>
                }
                isNoMaxWidth
                placement="right"
              >
                <ErrorOutlineIcon sx={{ fontSize: "14px", ml: 1 }} />
              </CustomizedTooltip>
            </Box>
            <Grid container spacing={2}>
              {disabled ? (
                <Grid item xs={12} md={4}>
                  <LabelInput
                    label={"การติดตาม"}
                    value={
                      trackSerialList.find((list) => list.value === tracability)
                        ?.label
                    }
                  />
                </Grid>
              ) : (
                <Fragment>
                  <Grid item xs={12} md={4}>
                    <RadioBox
                      onClick={() => {
                        setValue("tracability", trackSerialList[0].value);
                        setValue("barcode", "");
                      }}
                      small
                      label={trackSerialList[0].label}
                      checked={tracability === "serial"}
                      disabled={Boolean(id)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RadioBox
                      onClick={() => {
                        setValue("tracability", trackSerialList[1].value);
                        setValue("barcode", getValues("unique_id"));
                      }}
                      small
                      label={trackSerialList[1].label}
                      checked={tracability === "normal"}
                      disabled={Boolean(id)}
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
            {tracability === Tracability.Normal &&
              ![ItemType.Variant].includes(itemType) && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} mt={2}>
                    <ControlledTextField
                      control={control}
                      name="barcode"
                      label="Barcode"
                      viewMode={disabled}
                      required
                    />
                  </Grid>
                </Grid>
              )}
          </Fragment>
        )}
        <Grid container spacing={2} mt={1} mb={2}>
          {warehouseUomList.map((list) => (
            <Grid item xs={12} sm={4} key={list.name}>
              {disabled ? (
                <LabelInput
                  label={list.label}
                  value={renderOption(getValues(list.name))}
                />
              ) : (
                <Controller
                  control={control}
                  name={list.name}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={list.label}
                      options={option}
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.value : "")
                      }
                      value={renderOption(field.value)}
                      error={Boolean(list.errors)}
                      helperText={list.errors?.message?.toString()}
                      disabled={disabled}
                    />
                  )}
                />
              )}
            </Grid>
          ))}
        </Grid>
        {itemType !== "bundle" && <Conversion />}
      </Box>
    </>
  );
};

export default Warehouse;
