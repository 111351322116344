import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { BRANCHES_AGGRIDS } from "../../services/AgGrid/BranchAgGrid";
import { IBranch } from "../../types/Setting/Branch";
import { IGlobalSetting, ITag } from "../../types/global";
import {
  ALL_SALES_CHANNEL,
  ALL_SALES_TYPE,
  SALES_TAG_LIST,
} from "../../services/AgGrid/SalesAgGrid";
import { USERS_AGGRID } from "../../services/AgGrid/UserAgGrid";
import { IUser } from "../../types/Auth/user";
import { TagDocumentType } from "../../generated/general";

export const useSalesAgGrid = (type: string) => {
  const branch_id = parseInt(sessionStorage.getItem("branch-id") || "");

  const branchFilterPararms = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { BranchesFindManyAggrid } = await graphQLClient.request(
          BRANCHES_AGGRIDS,
          {
            aggridInput: {
              startRow: 0,
              endRow: 9999,
              filterModel: {
                id: {
                  filterType: "set",
                  values: branch_id === -1 ? undefined : [branch_id],
                },
              },
              sortModel: [],
            },
          }
        );
        const branchName = BranchesFindManyAggrid.results.map(
          (branch: IBranch) => branch.name
        );
        params.success(branchName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const salesTypeFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { SalesTypesFindAll } = await graphQLClient.request(
          ALL_SALES_TYPE
        );
        const salesTypeName = SalesTypesFindAll.map(
          (type: IGlobalSetting) => type.name_th
        );
        params.success(salesTypeName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const salesChannelFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { SalesChannelsFindAll } = await graphQLClient.request(
          ALL_SALES_CHANNEL
        );
        const salesChannelName = SalesChannelsFindAll.map(
          (type: IGlobalSetting) => type.name_th
        );
        params.success(salesChannelName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const salesUserFilterParams = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { UsersView } = await graphQLClient.request(USERS_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: 9999,
            filterModel: {
              // branch_id: {
              //   filterType: "set",
              //   values: branch_id === -1 ? [branch_id] : [-1, branch_id],
              // },
            },
            sortModel: [],
          },
        });
        const userName = UsersView.results.map(
          (user: IUser) => user.first_name + " " + user.last_name
        );
        params.success(userName);
      } catch (err) {
        params.fail();
      }
    },
  };

  const salesTagList = {
    values: async (params: any) => {
      try {
        const graphQLClient: GraphQLClient =
          createGraphQLClientWithMiddleware("general");
        const { Tags } = await graphQLClient.request(SALES_TAG_LIST, {
          findManyInput: {
            document_type: type as TagDocumentType,
          },
        });
        const tagName = Tags.map((tag: ITag) => tag.name);
        params.success(tagName);
      } catch (err) {
        params.fail();
      }
    },
  };

  return {
    branchFilterPararms,
    salesTypeFilterParams,
    salesChannelFilterParams,
    salesUserFilterParams,
    salesTagList,
  };
};
