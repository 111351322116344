import { Box, CircularProgress } from "@mui/material";

const LoadingUI = () => {
  return (
    <Box
      sx={{
        height: "calc(100dvh - 300px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingUI;
