import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const usePurchaseItemListHeader = (
  documentType: string,
  disabled: boolean,
  status?: string
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  const addHeader = (
    headers: IHeaderTable[],
    index: number,
    newHeader: IHeaderTable
  ) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 0, newHeader);
    return newHeaders;
  };

  const emptyColumnHeader = useMemo(() => {
    return {
      label: "",
      thaiLabel: "",
      width: 20,
    };
  }, []);

  const POQtyHeader = (thaiLabel: string, label: string) => ({
    thaiLabel,
    label,
    width: 100,
  });

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 320,
        required: true,
      },
      {
        thaiLabel: "จำนวน",
        label: "Qty",
        width: 100,
        required: true,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
        required: true,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
        required: true,
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
      },

      {
        thaiLabel: "ภาษี (%)",
        label: "Vat",
        width: 100,
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 100,
      },
      {
        thaiLabel: "หมายเหตุรายการ",
        label: "Product Remark",
        width: 150,
      },
    ];

    const prHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 320,
        required: true,
      },
      {
        thaiLabel: "จำนวน",
        label: "Qty",
        width: 100,
        required: true,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
        required: true,
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 150,
      },
      {
        thaiLabel: "ใบสั่งขาย",
        label: "SO Ref.",
        width: 150,
      },
      {
        thaiLabel: "หมายเหตุรายการ",
        label: "List Remark",
        width: 150,
      },
    ];

    if (documentType === "purchase_request") {
      const POQty = POQtyHeader("สั่งซื้อแล้ว", "PO Qty");
      if (
        [
          null,
          undefined,
          "",
          "draft",
          "wait_approve",
          "not_approved",
          "cancelled",
        ].includes(status)
      ) {
        setHeaders(disabled ? prHeaders : [...prHeaders, emptyColumnHeader]);
      } else {
        setHeaders(
          addHeader(
            disabled ? prHeaders : [...prHeaders, emptyColumnHeader],
            6,
            POQty
          )
        );
      }
    } else if (documentType === "purchase_order") {
      const poHeaders = addHeader([...defaultHeaders], 8, {
        thaiLabel: "ใบขอซื้อ",
        label: "PR Ref.",
        width: 150,
      });

      const POQty = POQtyHeader("นำเข้าแล้ว", "");

      if (
        [null, undefined, "", "draft", "wait_approve", "cancelled"].includes(
          status
        )
      ) {
        setHeaders(disabled ? poHeaders : [...poHeaders, emptyColumnHeader]);
      } else {
        setHeaders(
          addHeader(
            disabled ? poHeaders : [...poHeaders, emptyColumnHeader],
            10,
            POQty
          )
        );
      }
    } else {
      const rsHeaders = addHeader([...defaultHeaders], 8, {
        thaiLabel: "ใบสั่งซื้อ",
        label: "PO Ref.",
        width: 150,
      });

      setHeaders(disabled ? rsHeaders : [...rsHeaders, emptyColumnHeader]);
    }
  }, [disabled, documentType, emptyColumnHeader, status]);

  return headers;
};
