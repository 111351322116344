import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  UnitsFindAllQuery,
  CategoryGroupsFindAllQuery,
  useCategoryGroupsFindAllQuery,
  useUnitsFindAllQuery,
  useMainCategorysFindAllQuery,
  MainCategorysFindAllQuery,
  useSubCategorysFindAllQuery,
  SubCategorysFindAllQuery,
  useBrandsFindAllQuery,
  BrandsFindAllQuery,
  useSegmentsFindAllQuery,
  SegmentsFindAllQuery,
  useSeriessFindAllQuery,
  SeriessFindAllQuery,
  useSetItemAttributesQuery,
  SetItemAttributesQuery,
} from "../generated/general";
import { IOptionsCreatable, ISelectOption } from "../types/global";

export const useUnitOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: uomsData, isLoading: isLoadingUoms } =
    useUnitsFindAllQuery<UnitsFindAllQuery>(graphQLClient);

  const uomOptions: ISelectOption[] =
    uomsData?.UnitsFindAll?.map((uom) => ({
      id: uom?.id || 0,
      label: uom?.name_th || "",
      value: uom?.id || 0,
    })) || [];

  const renderFieldValue = (value?: number) =>
    value && uomsData?.UnitsFindAll?.find((uom) => uom?.id === value)?.name_th;

  const allUnit = uomsData?.UnitsFindAll?.map((uom) => ({
    id: uom?.id,
    name: uom?.name_th,
    unique_id: uom?.unique_id,
  }));

  return {
    option: uomOptions,
    isLoading: isLoadingUoms,
    renderOption: renderFieldValue,
    allUnit: allUnit || [],
  };
};

export const useCategoryGroupOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useCategoryGroupsFindAllQuery<CategoryGroupsFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.CategoryGroupsFindAll?.map((category) => ({
      id: category?.id || 0,
      label: category?.name_th || "",
      value: category?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allUnit: data || [],
  };
};

export const useMainCategoryOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useMainCategorysFindAllQuery<MainCategorysFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.MainCategorysFindAll?.map((category) => ({
      id: category?.id || 0,
      label: category?.name_th || "",
      value: category?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allUnit: data || [],
  };
};

export const useSubCategoryOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useSubCategorysFindAllQuery<SubCategorysFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.SubCategorysFindAll?.map((category) => ({
      id: category?.id || 0,
      label: category?.name_th || "",
      value: category?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allUnit: data || [],
  };
};

export const useBrandOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useBrandsFindAllQuery<BrandsFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.BrandsFindAll?.map((brand) => ({
      id: brand?.id || 0,
      label: brand?.name_th || "",
      value: brand?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allUnit: data || [],
  };
};

export const useSegmentOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useSegmentsFindAllQuery<SegmentsFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.SegmentsFindAll?.map((segment) => ({
      id: segment?.id || 0,
      label: segment?.name_th || "",
      value: segment?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allUnit: data || [],
  };
};

export const useSeriesOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useSeriessFindAllQuery<SeriessFindAllQuery>(graphQLClient);

  const options: IOptionsCreatable[] =
    data?.SeriessFindAll?.map((series) => ({
      id: series?.id || 0,
      label: series?.name_th || "",
      value: series?.name_th || "",
    })) || [];

  return {
    options,
    isLoading,
    allUnit: data || [],
  };
};

export const useAttributeOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useSetItemAttributesQuery<SetItemAttributesQuery>(graphQLClient);

  const options: ISelectOption[] =
    data?.SetItemAttributes?.map((set) => ({
      id: set?.id || 0,
      label: set?.name_th || "",
      value: set?.id || "",
    })) || [];

  const findMapValue = (id: number) => {
    const find = data && data?.SetItemAttributes?.find((set) => set?.id === id);
    return find;
  };

  const renderFieldValue = (value?: number) =>
    data && data?.SetItemAttributes?.find((set) => set?.id === value)?.name_th;

  const replaceAttribute = (id: number, replace: any) => {
    if (id) {
      const findId = data?.SetItemAttributes?.find((set) => set?.id === id);
      if (findId) {
        replace(findId.item_attribute_list);
      }
    }
  };

  return {
    options,
    isLoading,
    renderFieldValue,
    replaceAttribute,
    findMapValue,
    allSet: data || [],
  };
};
