import { SxProps, Typography } from "@mui/material";

type Props = {
  title: string;
  sx?: SxProps;
};

const SubHeader = ({ title, sx }: Props) => {
  return (
    <Typography fontWeight={600} color={"primary.main"} sx={sx}>
      {title}
    </Typography>
  );
};

export default SubHeader;
