import React, { useRef, ReactNode } from "react";
import { SnackbarProvider } from "notistack";
import { IconButton, styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MaterialDesignContent } from "notistack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const StyledSnackbar = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

interface Props {
  children?: ReactNode;
}

export default function CustomizedSnackbar({ children }: Props) {
  const notistackRef = useRef<any>(null);

  const dismiss = (key: any) => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      Components={{
        success: StyledSnackbar,
        error: StyledSnackbar,
        warning: StyledSnackbar,
        info: StyledSnackbar,
      }}
      iconVariant={{
        success: <CheckCircleOutlinedIcon color="success" sx={{ mr: 0.5 }} />,
        error: <ErrorOutlineIcon color="error" sx={{ mr: 0.5 }} />,
        warning: <ReportProblemOutlinedIcon color="warning" sx={{ mr: 0.5 }} />,
        info: <InfoOutlinedIcon color="info" sx={{ mr: 0.5 }} />,
      }}
      maxSnack={7}
      preventDuplicate={true}
      action={(key) => (
        <IconButton size="small" onClick={() => dismiss(key)}>
          <CloseOutlinedIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
