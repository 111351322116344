import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { ICompany } from "../../../types/Setting/Company";
import CompanyContactChannel from "../../../components/Form/Company/ContactChannel";
import CompanyAddress from "../../../components/Form/Company/Address";
import {
  companySchema,
  companyValidation,
} from "../../../components/Form/Company/schema";
import CompanyDetail from "../../../components/Form/Company/Detail";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { Box, CircularProgress, Stack } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useDisable } from "../../../hooks/use-disable";
import { useEffect } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  CompanyType,
  useCompanyFindUniqueQuery,
  useCompanyUpdateMutation,
} from "../../../generated/general";
import { useSnackbar } from "notistack";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import dayjs from "dayjs";
import { uploadFileToS3 } from "../../../utils/s3";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Global";

const selectModifyOptions = [
  {
    value: "แก้ไข",
    disabled: false,
  },
];

const CompanySetting = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.company.index"),
      to: "/setting/company",
    },
  ];

  const methods = useForm<ICompany>({
    defaultValues: companySchema,
    resolver: yupResolver<any>(companyValidation),
  });

  const { handleSubmit, reset } = methods;

  const [disabled, setDisabled] = useDisable();

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess, refetch } =
    useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(graphqlClientGeneral, {
      uniqueInput: {
        id: 1,
      },
    });

  useEffect(() => {
    if (isSuccess) {
      const { CompanyFindUnique } = data;
      const companyData = CompanyFindUnique as ICompany;

      const formatData: ICompany = {
        ...companyData,
        register_date: dayjs(companyData.register_date),
        vat_registration_date: dayjs(companyData.vat_registration_date),
      };
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    setDisabled(true);
    return () => setDisabled(false);
  }, [setDisabled]);

  const { mutateAsync: update, isLoading: isUpdating } =
    useCompanyUpdateMutation<Error>(graphqlClientGeneral);

  const updateCompanyHandler = async (data: ICompany) => {
    try {
      const { type, id, ...otherData } = data;
      let img_url: string[] = [];
      if (data.img_url && data.img_url.length > 0) {
        const filteredFile = data.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "company",
            id?.toString() || ""
          );
          img_url.push(Location);
        } else {
          img_url = data.img_url;
        }
      }
      const { CompanyUpdate } = await update({
        uniqueInput: {
          id: 1,
        },
        data: {
          ...otherData,
          img_url,
          type: type as CompanyType,
        },
      });
      enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      const updatedData = CompanyUpdate as ICompany;
      const formatData: ICompany = {
        ...updatedData,
        register_date: dayjs(updatedData.register_date),
        vat_registration_date: dayjs(updatedData.vat_registration_date),
      };
      reset(formatData);
      refetch();
      setDisabled(true);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "แก้ไขผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const cancelEditHandler = () => {
    reset();
    setDisabled(true);
  };

  if (isLoading || isUpdating) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        maxWidth={1040}
        mt={3}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              default:
                break;
            }
          }}
        />
      </Box>
      <FormProvider {...methods}>
        <CompanyDetail />
        <CompanyContactChannel />
        <CustomizedBox padding={"0 1.5rem"}>
          <CompanyAddress addressList={data?.CompanyFindUnique?.address_list} />
        </CustomizedBox>
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.cancel")}
                variant="outlined"
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(updateCompanyHandler)}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
    </>
  );
};

export default CompanySetting;
