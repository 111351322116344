import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { loginSchema, loginValidation } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ILogin } from "../../../types/Auth/login";
import CustomizedButton from "../../Custom/CustomizedButton";
import {
  Exact,
  InputMaybe,
  LoginInput,
  LoginMutation,
} from "../../../generated/general";
import { UseMutateFunction } from "@tanstack/react-query";

interface Props {
  isMobile: boolean;
  isLoading: boolean;
  login: UseMutateFunction<
    LoginMutation,
    Error,
    Exact<{
      loginInput?: InputMaybe<LoginInput> | undefined;
    }>,
    unknown
  >;
}

const LoginForm = ({ isMobile, isLoading, login }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    defaultValues: loginSchema,
    resolver: yupResolver(loginValidation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleMouseDownPassword = (event: MouseEvent) => {
  //   event.preventDefault();
  // };

  const onLoginSubmit = ({ email, password }: ILogin) => {
    login({
      loginInput: {
        email,
        password: password || "",
      },
    });
  };

  const onSubmit = handleSubmit(onLoginSubmit);

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value || null}
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            autoComplete="off"
            label="อีเมลผู้ใช้งาน"
            margin="dense"
            size={isMobile ? "medium" : "small"}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <FormControl
            variant="outlined"
            fullWidth
            size={isMobile ? "medium" : "small"}
            sx={{ mt: 1 }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              รหัสผ่าน
            </InputLabel>
            <OutlinedInput
              {...field}
              value={field.value || null}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              data-test-id="user-password-text-field"
            />
          </FormControl>
        )}
      />
      <CustomizedButton
        disabled={isLoading}
        type="submit"
        title="เข้าสู่ระบบ"
        variant="contained"
        fullWidth
        size="medium"
        sx={{ mt: 1 }}
      />
    </form>
  );
};

export default LoginForm;
