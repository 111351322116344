import { useRef } from "react";
import useSetAttributeColumnDefs from "./useSetAttributeColumnDefs";
import AgGrid from "../../../UI/AgGrid";
import { RowDoubleClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router-dom";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  data: any[];
};

const SetAttributeTable = ({ onEditHandler, onDeleteHandler, data }: Props) => {
  const gridRef = useRef(null);
  const navigate = useNavigate();

  const setAttributeColumnDefs = useSetAttributeColumnDefs(
    onEditHandler,
    onDeleteHandler
  );

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/setting/inventory/attribute/${id}`);
  };

  return (
    <AgGrid
      columnDefs={setAttributeColumnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
      onRowDoubleClicked={rowSelectHandler}
    />
  );
};

export default SetAttributeTable;
