import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import BranchList from "../components/UI/BranchList";
import CustomizedButton from "../components/Custom/CustomizedButton";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/auth-context";
import { useCookies } from "react-cookie";
import { useBranchFilter } from "../hooks/use-branch-filter";
import CustomizedTextField from "../components/Custom/CustomizedTextField";

const SelectBranch = () => {
  const [, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const { dispatch } = useStateContext();

  const logoutHandler = () => {
    removeCookie("access_token", {
      path: "/",
    });
    removeCookie("refresh_token", {
      path: "/",
    });
    setCookie("logged_in", "false", {
      path: "/",
    });
    sessionStorage.removeItem("branch-id");
    sessionStorage.removeItem("is_hq");
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    navigate("/login", { replace: true });
  };

  const { filterText, filteredDataMemoized, handleInputChange } =
    useBranchFilter();

  return !isMobile ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(116.58deg, #36458C 10.99%, #C4CAE4 50.84%, #FFFFFF 60%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="login" src="/static/loginBg.svg" />
        </Box>
        <Box
          sx={{
            borderRadius: "40px 0 0 40px",
            background: "white",
            display: "flex",
            flex: 1,
            height: "inherit",
            alignItems: "center",
            flexDirection: "column",
            p: "2rem",
            justifyContent: "space-between",
            overflowY: "scroll",
            overflowX: "hidden",
            minWidth: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              flex: 15,
            }}
          >
            <img alt="logo" src="/static/logo.jpg" style={{ height: 150 }} />
            <Typography variant="h4">เลือกสาขา</Typography>
            <Box width={300} mt={2}>
              <CustomizedTextField
                label={"ค้นหา"}
                value={filterText}
                onChange={handleInputChange}
              />
            </Box>
            <BranchList branchList={filteredDataMemoized} />
            <CustomizedButton
              title="ออกจากระบบ"
              variant="outlined"
              onClick={logoutHandler}
              sx={{ width: 300 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        background: "white",
        display: "flex",
        height: "100dvh",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        p: 4,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flex: 15,
        }}
      >
        <img alt="logo" src="/static/logo.jpg" style={{ height: 150 }} />
        <Typography variant="h4">เลือกสาขา</Typography>
        <BranchList branchList={filteredDataMemoized} />
        <CustomizedButton
          title="ออกจากระบบ"
          variant="outlined"
          onClick={logoutHandler}
          sx={{ width: 300 }}
        />
      </Box>
    </Box>
  );
};

export default SelectBranch;
