import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";

export const useCashSalesPrint = (
  status?: string | null,
  isNeedTax?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: `พิมพ์ใบเสร็จรับเงิน`,
      },
      {
        value: `พิมพ์ใบกำกับภาษี`,
      },
      {
        value: `พิมพ์ใบเสร็จรับเงิน/ใบกำกับภาษี`,
      },
    ],
    []
  );

  const [printOptions, setPrintOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "finished":
        if (isNeedTax) {
          setPrintOptions(defaultOptions);
        } else {
          setPrintOptions([defaultOptions[0]]);
        }
        break;
      default:
        setPrintOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, isNeedTax, status]);

  return printOptions;
};
