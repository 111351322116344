import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Avatar,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Dispatch, SetStateAction, useState, useEffect, Fragment } from "react";
import { useDisable } from "../../../../hooks/use-disable";

import { IItem } from "../../../../types/Inventory/item";
import { IHeaderTable } from "../../../../types/global";

import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CheckedItemListModal from "../../../UI/Modal/CheckedItemListModal";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { numberFormatter } from "../../../../utils/Formatter/Global";

type Props = {
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  fields: any;
  replace: UseFieldArrayReplace<IItem, "bundle_item_detail_list">;
  remove: UseFieldArrayRemove;
};

const BundleTable = ({
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  fields,
  replace,
  remove,
}: Props) => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  const [disabled] = useDisable();
  const [itemModal, setItemModal] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
  } = useFormContext<IItem>();

  const openItemModalHandler = () => {
    setItemModal(true);
  };

  const closeItemModalHandler = () => {
    setItemModal(false);
  };

  const addItemHandler = (data: any[]) => {
    if (data) {
      const newItem = data.map((item) => ({
        item: item.item,
        item_unique_id: item.item_unique_id,
        sku_name: item.sku_name,
        img_url: item.img_url,
        ratio_percentage: 0,
        item_sku_detail: item,
        price: 0,
        special_price: 0,
        qty: 1,
      }));
      replace(newItem);
    }
    closeItemModalHandler();
  };

  useEffect(() => {
    if (!disabled)
      setHeaders([
        { label: t("inventory.items.picture"), width: 50 },
        { label: t("inventory.items.index"), width: 200 },
        { label: "%", width: 100 },
        { label: t("global.price"), width: 100 },
        { label: t("global.special_price"), width: 100 },
        { label: t("inventory.quantity"), width: 100 },
        { label: t("inventory.unit"), width: 100 },
        { label: t("inventory.bundle.header.price_per_unit"), width: 170 },
        { label: t("inventory.bundle.header.price_all_unit"), width: 170 },
        { label: "", width: 50 },
      ]);
    else
      setHeaders([
        { label: t("inventory.items.picture"), width: 50 },
        { label: t("inventory.items.index"), width: 200 },
        { label: "%", width: 100 },
        { label: t("global.price"), width: 100 },
        { label: t("global.special_price"), width: 100 },
        { label: t("inventory.quantity"), width: 100 },
        { label: t("inventory.unit"), width: 100 },
        { label: t("inventory.bundle.header.price_per_unit"), width: 170 },
        { label: t("inventory.bundle.header.price_all_unit"), width: 170 },
      ]);
  }, [disabled, t]);

  const SumAllPurchasePrice = () => {
    const bundleList =
      useWatch({ control, name: "bundle_item_detail_list" }) || [];

    return (
      <Typography>
        {bundleList
          .reduce(
            (prev: any, curr: any) =>
              prev + curr.qty * (curr.item?.purchase_standard_price || 0),
            0
          )
          .toLocaleString() ?? "-"}
      </Typography>
    );
  };

  const SumPurchasePrice = ({
    purchasePrice,
    qty,
  }: {
    purchasePrice?: number;
    qty?: number;
  }) => {
    return (
      <Typography fontSize={14}>
        {((purchasePrice ?? 0) * (qty ?? 1)).toLocaleString()}
      </Typography>
    );
  };

  // const calculatePrice = useCallback(
  //   (type: string, index: number = 0) => {
  //     const ratio_percentage =
  //       watch(`bundle_item_detail_list.${index}.ratio_percentage`) || 0;
  //     const qty = watch(`bundle_item_detail_list.${index}.qty`) || 0;

  //     var finalPrice = 0;
  //     if (!disabled) {
  //       // If the percentage is negative, it's an increase
  //       if (type === "sale_price")
  //         finalPrice = sale_price * (Math.abs(ratio_percentage) / 100);
  //       if (type === "special_price")
  //         finalPrice = special_price * (Math.abs(ratio_percentage) / 100);
  //       // Round the final price to two decimal places
  //       finalPrice = Math.round(finalPrice * 100) / 100;
  //       return finalPrice;
  //     } else {
  //       // If the percentage is negative, it's an increase
  //       if (type === "sale_price")
  //         finalPrice = sale_price * (Math.abs(ratio_percentage) / 100);
  //       if (type === "special_price")
  //         finalPrice = special_price * (Math.abs(ratio_percentage) / 100);
  //       // Round the final price to two decimal places
  //       finalPrice = Math.round((finalPrice / qty) * 100) / 100;
  //       return finalPrice;
  //     }
  //   },
  //   [disabled, sale_price, special_price, watch]
  // );

  return (
    <Fragment>
      <CustomizedBox margin="0" bgcolor="primary.light">
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell align="center" width={header.width} key={index}>
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={700}
                      width={header.width}
                    >
                      {header.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((list: any, index: number) => (
                <TableRow key={list.id}>
                  <TableCell align="center">
                    <Box
                      sx={{
                        fieldsdisplay: "flex",
                        width: "100%",
                        minWidth: "90px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        alt={list.item?.name ?? ""}
                        src={list.item_sku_detail?.img_url?.[0] ?? ""}
                        sx={{
                          width: 60,
                          height: 60,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {!disabled ? (
                      <Fragment>
                        <ControlledTextField
                          control={control}
                          name={`bundle_item_detail_list[${index}].sku_name`}
                          disabled
                        />
                        <Typography fontSize={10} color="#666666" my={0.5}>
                          {list.item_unique_id}
                        </Typography>
                        <ControlledTextField
                          control={control}
                          name={`bundle_item_detail_list[${index}].item_sku_detail.item.description`}
                          disabled
                        />
                      </Fragment>
                    ) : (
                      <>
                        <Box display="flex" my={0.5} alignItems="center">
                          <Typography
                            color={(theme) =>
                              !list.item?.is_active
                                ? theme.palette.error.main
                                : undefined
                            }
                          >
                            {list.sku_name}
                          </Typography>
                          {!list.item?.is_active && (
                            <CustomizedTooltip
                              title={t("inventory.sentence.item_disabled")}
                            >
                              <ErrorOutlineIcon
                                sx={{ fontSize: "14px", ml: 1 }}
                              />
                            </CustomizedTooltip>
                          )}
                        </Box>
                        <Typography fontSize={10} color="#666666" my={0.5}>
                          {list.item_unique_id}
                        </Typography>{" "}
                        <Typography my={0.5}>
                          {list.item?.description}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <ControlledNumberTextField
                      control={control}
                      name={`bundle_item_detail_list[${index}].ratio_percentage`}
                      error={Boolean(
                        errors?.bundle_item_detail_list?.[index]
                          ?.ratio_percentage
                      )}
                      helperText={
                        errors?.bundle_item_detail_list?.[index]
                          ?.ratio_percentage?.message
                      }
                      viewMode={disabled}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {disabled ? (
                      <>{numberFormatter(list.price / list.qty)}</>
                    ) : (
                      <ControlledNumberTextField
                        control={control}
                        name={`bundle_item_detail_list[${index}].price`}
                        disabled={true}
                        fullWidth
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {disabled ? (
                      <>{numberFormatter(list.special_price / list.qty)}</>
                    ) : (
                      <ControlledNumberTextField
                        control={control}
                        name={`bundle_item_detail_list[${index}].special_price`}
                        disabled={true}
                        viewMode={disabled}
                        fullWidth
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <ControlledNumberTextField
                      control={control}
                      name={`bundle_item_detail_list[${index}].qty`}
                      error={Boolean(
                        errors?.bundle_item_detail_list?.[index]?.qty
                      )}
                      helperText={
                        errors?.bundle_item_detail_list?.[index]?.qty?.message
                      }
                      viewMode={disabled}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {disabled ? (
                      list.item?.stock_uom?.name_th
                    ) : (
                      <TextField
                        value={list.item?.stock_uom?.name_th}
                        autoComplete="off"
                        size="small"
                        disabled
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontSize={14}>
                      {list.item?.purchase_standard_price?.toLocaleString() ??
                        "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <SumPurchasePrice
                      purchasePrice={list.item?.purchase_standard_price}
                      qty={list.qty}
                    />
                  </TableCell>
                  {!disabled && (
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          setItemIdsSnapshot((prev) =>
                            prev.filter((_, fIndex) => fIndex !== index)
                          );
                          setItemIds((prev) =>
                            prev.filter((_, fIndex) => fIndex !== index)
                          );
                          remove(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="flex-end" mt={1} gap={10}>
          <Typography fontWeight="bold">รวมทั้งหมด</Typography>
          <SumAllPurchasePrice />
          <Typography>บาท</Typography>
        </Box>
      </CustomizedBox>
      {!disabled && (
        <Box my={2}>
          <CustomizedButton
            variant="outlined"
            title="เพิ่มสินค้า"
            addIcon
            onClick={openItemModalHandler}
          />
        </Box>
      )}
      <CheckedItemListModal
        showSelectItem={itemModal}
        closeItemTable={closeItemModalHandler}
        finishItemsSelect={addItemHandler}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="multiple"
      />
    </Fragment>
  );
};

export default BundleTable;
