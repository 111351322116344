import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Grid } from "@mui/material";

import { useDisable } from "../../../../hooks/use-disable";

import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledCheckbox from "../../../Controller/ControlledCheckbox";

const Stock = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [disabled] = useDisable();

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} ml={1}>
        <ControlledCheckbox
          control={control}
          name="replenishment_stock"
          label={t("inventory.sentence.selected_replenishment_stock")}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"min_stock_qty"}
          label={"Minimum Stock"}
          onChange={(e, field) => field.onChange(parseInt(e.target.value))}
          viewMode={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"max_stock_qty"}
          label="Maximum Stock"
          onChange={(e, field) => field.onChange(parseInt(e.target.value))}
          viewMode={disabled}
        />
      </Grid>
      {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <ControlledTextField
          control={control}
          name={"reorder_point"}
          label="Reorder Point"
          onChange={(e, field) => field.onChange(parseInt(e.target.value))}
          viewMode={disabled}
        />
      </Grid> */}
    </Grid>
  );
};

export default Stock;
