import { Typography } from "@mui/material";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { forwardRef, ReactNode, useCallback, useMemo } from "react";
// import AG_GRID_LOCALE_TH from "../../locale.th";
// import CustomDate from "./CustomDate";

import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../../table.css";
import CustomDate from "./CustomDate";
import AG_GRID_LOCALE_TH from "../../locale.th";

interface Props {
  isClientSide?: boolean;
  autoHeight?: boolean;
  suppressMenu?: boolean;
  disabledSidebar?: boolean;
  disableFloatingFilter?: boolean;
  headerClass?: [string];
  disableResized?: boolean;
  paginationSize?: number;
  height?: number | string;
  width?: number | string;
  children?: ReactNode;
  path?: string;
}

type ExtendProps = AgGridReactProps & Props;

const AgGrid = forwardRef<any, ExtendProps>(
  (
    {
      isClientSide,
      rowData,
      columnDefs,
      onRowDoubleClicked,
      height,
      width,
      groupDisplayType,
      groupDefaultExpanded,
      groupIncludeFooter,
      groupIncludeTotalFooter,
      // searchBox,
      onGridReady,
      onFirstDataRendered,
      masterDetail,
      detailCellRenderer,
      detailRowHeight,
      autoHeight,
      rowSelection,
      onRowClicked,
      onRowSelected,
      enableRangeSelection,
      components,
      onRowDataUpdated,
      suppressMenu,
      rowDragManaged,
      onRowDragMove,
      disabledSidebar,
      disableFloatingFilter,
      rowDragEntireRow,
      getRowId,
      headerClass,
      rowDragMultiRow,
      rowMultiSelectWithClick,
      disableResized,
      onFilterChanged,
      detailCellRendererParams,
      autoGroupColumnDef,
      paginationSize,
      path,
      suppressContextMenu,
    },
    ref
  ) => {
    const sideBar = {
      toolPanels: ["columns", "filters"],
    };

    const defaultColDef = useMemo(() => {
      return {
        editable: false,
        floatingFilter:
          typeof disableFloatingFilter !== "undefined"
            ? !disableFloatingFilter
            : isClientSide
            ? false
            : true,
        filter: isClientSide ? false : true,
        resizable: disableResized ? false : true,
        sortable: isClientSide ? false : true,
        suppressMenu: suppressMenu ? true : false,
        headerClass: headerClass || [""],
      };
    }, [
      disableFloatingFilter,
      disableResized,
      headerClass,
      isClientSide,
      suppressMenu,
    ]);

    const localeText = useMemo(() => {
      return AG_GRID_LOCALE_TH;
    }, []);

    const CustomizedNoRowsOverlay = ({ noRowsMessageFunc }: any) => (
      <Typography>{noRowsMessageFunc()}</Typography>
    );

    const noRowsOverlayComponent = useMemo(() => {
      return CustomizedNoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
      return {
        noRowsMessageFunc: () => "ไม่มีข้อมูล",
      };
    }, []);

    const getContextMenuItems = useCallback(
      (params: any) => {
        return [
          // ...params.defaultItems,
          // {
          //   name: "Export Selected Rows",
          //   icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
          //   subMenu: [
          //     {
          //       name: "CSV Export",
          //       icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
          //       action: () =>
          //         params.api.exportDataAsCsv({
          //           onlySelected: true,
          //         }),
          //     },
          //     {
          //       name: "Excel Export",
          //       icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          //       action: () =>
          //         params.api.exportDataAsExcel({
          //           onlySelected: true,
          //         }),
          //     },
          //   ],
          // },
          {
            name: "Open Document in New Tab",
            icon: '<span class="material-icons"  style={{fontSize: "18px"}}>open_in_new</span>',
            action: () => {
              if (params.node != null) {
                let documentPath;
                const id = params.node.data.id ?? "";

                if (path === "/inventory/items") {
                  const { sku_name } = params.node.data;
                  documentPath = `/inventory/items/${encodeURIComponent(
                    sku_name
                  )}?tab=item&subtab=general`;
                } else {
                  documentPath = `${path}/${encodeURIComponent(id)}`;
                }

                window.open(documentPath, "_blank");
              }
            },
          },
        ];
      },
      [path]
    );

    const defaultComponents = useMemo(() => {
      return {
        agDateInput: CustomDate,
      };
    }, []);

    return (
      <div
        id="myGrid"
        className="ag-theme-material"
        style={{
          height: height || "100%",
          // width: "calc(100vw - 300px)",
          width: width || "100%",
          minHeight: "450px",
        }}
      >
        <AgGridReact
          domLayout={autoHeight ? "autoHeight" : "normal"}
          ref={ref}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rowData}
          localeText={localeText}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          sideBar={disabledSidebar ? false : sideBar}
          detailRowHeight={detailRowHeight}
          enableRangeSelection={enableRangeSelection}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          onRowDoubleClicked={onRowDoubleClicked}
          onRowClicked={onRowClicked}
          onRowSelected={onRowSelected}
          groupDisplayType={groupDisplayType}
          groupDefaultExpanded={groupDefaultExpanded}
          groupIncludeFooter={groupIncludeFooter}
          groupIncludeTotalFooter={groupIncludeTotalFooter}
          detailCellRenderer={detailCellRenderer}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
          rowModelType={rowData ? "clientSide" : "serverSide"}
          // serverSideStoreType={isClientSide || rowData ? "full" : "partial"}
          pagination={true}
          paginationPageSize={paginationSize ? paginationSize : 10}
          cacheBlockSize={rowData ? undefined : 10}
          rowSelection={rowSelection}
          getContextMenuItems={getContextMenuItems}
          components={components || defaultComponents}
          onRowDataUpdated={onRowDataUpdated}
          rowDragMultiRow={rowDragMultiRow}
          rowDragManaged={rowDragManaged}
          onRowDragMove={onRowDragMove}
          rowDragEntireRow={rowDragEntireRow}
          getRowId={getRowId}
          enableCellTextSelection={true}
          suppressContextMenu={suppressContextMenu || !path}
          suppressMovableColumns={rowData ? true : false}
          suppressAggFuncInHeader={true}
          rowMultiSelectWithClick={rowMultiSelectWithClick}
          onFilterChanged={onFilterChanged}
          autoGroupColumnDef={autoGroupColumnDef}
          // serverSideInfiniteScroll={rowData ? false : true}
          serverSideSortOnServer={rowData ? false : true}
          serverSideSortAllLevels={rowData ? false : true}
          serverSideFilterOnServer={rowData ? false : true}
          getRowStyle={(params: any) => {
            if (params.node.footer) {
              return { fontWeight: "bold" };
            }
          }}
        />
      </div>
    );
  }
);

export default AgGrid;
