import {
  GridReadyEvent,
  IServerSideDatasource,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { GOODS_TRANSFER_VIEW } from "../../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { IGoodsTransfer } from "../../../../types/Inventory/GoodsTransfer";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import AgGrid from "../../../UI/AgGrid";
import { useGoodsTransferColumnDefs } from "./columnDef";
import { branchInfoFromSessionStorage } from "../../../../utils/Global";

interface Props {
  gridRef: RefObject<AgGridReact<IGoodsTransfer>>;
  isFilter?: string | null;
  setRowCount?: Dispatch<SetStateAction<number>>;
}

const GoodsTransferTable = ({ gridRef, isFilter, setRowCount }: Props) => {
  const navigate = useNavigate();

  const columnDef = useGoodsTransferColumnDefs();
  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, posted_date } = filterModel;
      const formatFilter = {
        ...filterModel,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
      };

      try {
        const { GoodsTransferView } = await graphQLClientWithHeaderItem.request(
          GOODS_TRANSFER_VIEW,
          {
            branchId:
              is_hq === "false" && branch_id ? parseInt(branch_id) : undefined,
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        if (setRowCount) {
          setRowCount(GoodsTransferView.count as number);
        }

        params.success({
          rowData: GoodsTransferView.results as any[],
          rowCount: GoodsTransferView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");

      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        case "wait_transfer":
          instance?.setModel({ values: ["wait_transfer"] });
          break;
        case "in_transit":
          instance?.setModel({ values: ["in_transit"] });
          break;
        case "wait_approve":
          instance?.setModel({ values: ["wait_approve"] });
          break;
        case "not_approved":
          instance?.setModel({ values: ["not_approved"] });
          break;
        default:
          instance?.setModel({
            values: [
              "draft",
              "wait_approve",
              "not_approved",
              "finished",
              "wait_transfer",
              "in_transit",
            ],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    // const { branch_id, is_hq } = branchInfoFromSessionStorage();

    // const instanceฺฺBranchId = params.api.getFilterInstance("source_branch_id");
    // instanceฺฺBranchId?.setModel({
    //   filterType: "number",
    //   type: "equals",
    //   filter: is_hq === "false" && branch_id ? parseInt(branch_id) : undefined,
    // });

    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/inventory/goods-transfer/${encodeURIComponent(params.data.id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/inventory/goods_transfer"}
    />
  );
};

export default GoodsTransferTable;
