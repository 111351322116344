import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import SalesInvoiceTable from "../../../components/Table/Sales/Invoice";
import {
  CustomerDepositFindUniqueQuery,
  useCustomerDepositFindUniqueQuery,
} from "../../../generated/sales";
import { useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { numberFormatter } from "../../../utils/Formatter/Global";

type Props = {
  cdUniqueId?: string;
};

const SalesInvoiceTab = ({ cdUniqueId }: Props) => {
  const { id } = useParams();
  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } =
    useCustomerDepositFindUniqueQuery<CustomerDepositFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const remainingDepositAmount =
    data?.CustomerDepositFindUnique.remaining_deposit_amount;

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <SalesInvoiceTable cdUniqueId={cdUniqueId} />{" "}
      <Box my={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={7} />
          <Grid item xs={12} sm={12} md={5} justifyContent={"flex-end"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                py: 1,
              }}
              bgcolor={"primary.light"}
            >
              <Typography
                color={"primary.main"}
                textAlign={"right"}
                flex={2}
                fontWeight={400}
              >
                จำนวนเงินมัดจำคงเหลือ
              </Typography>
              <Typography
                color={"primary.main"}
                fontWeight={600}
                flex={1}
                mr={2}
                minWidth={150}
                textAlign={"right"}
              >
                {numberFormatter(remainingDepositAmount || 0)}
              </Typography>
              <Typography color={"primary.main"} mr={2}>
                บาท
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SalesInvoiceTab;
