import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";

import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";

export const useItemImporterColumnDefs = (type?: string, itemType?: string) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    if (type === "img") {
      setColumnDefs([
        {
          field: "item_unique_id",
          headerName: t("inventory.items.unique_id"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "img_url",
          headerName: t("inventory.items.picture"),
          filter: "agTextColumnFilter",
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value && params.value.length) {
              return <CustomizedAvatar avatars={params.value} noTooltip />;
            } else return <></>;
          },
          flex: 1,
          minWidth: 200,
        },
      ]);
    } else if (type === "update_stock") {
      setColumnDefs([
        {
          field: "item_unique_id",
          headerName: "รหัสสินค้า",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "sku_name",
          headerName: "SKU",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "branch_unique_id",
          headerName: "รหัสสาขา",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "warehouse_unique_id",
          headerName: "รหัสคลัง",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "min_stock_qty",
          headerName: "Minimum Stock",
          filter: "agNumberColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "mix_stock_qty",
          headerName: "Maximum Stock",
          filter: "agNumberColumnFilter",
          flex: 1,
          minWidth: 200,
        },
      ]);
    } else if (type === "bundle_items") {
      setColumnDefs([
        {
          field: "item_unique_id",
          headerName: "รหัสสินค้า",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
      ]);
    } else if (["sku", "each_sku"].includes(type || "")) {
      setColumnDefs([
        {
          field: "item_unique_id",
          headerName: "รหัสสินค้า",
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
      ]);
    } else
      setColumnDefs([
        {
          field: "seller_unique_id",
          headerName: t("inventory.items.unique_id"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "name",
          headerName: t("inventory.items.name"),
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "is_active",
          headerName: t("inventory.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [1, 0],
            valueFormatter: ({ value }: { value: number }) => {
              if (value) return "ใช้งาน";
              return "หยุดใช้งาน";
            },
          },
          cellRenderer: ({ value }: { value: boolean }) => {
            if (value) return <CustomizedStatus status="active" />;
            return <CustomizedStatus status="inactive" />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      ]);
  }, [t, type]);

  return columnDefs;
};

export const usePriceListImporterColumnDefs = (type?: string) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: "รหัสรายการราคา",
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: "ชื่อรายการราคา",
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
    ]);
  }, [t, type]);

  return columnDefs;
};
