import { useStateContext } from "../contexts/auth-context";
import {
  CompanyFindUniqueQuery,
  useBranchFindUniqueQuery,
  useBranchUserGetByUserIdQuery,
  useCompanyFindUniqueQuery,
  useWarehousesFindAllQuery,
} from "../generated/general";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { IAddress } from "../types/global";
import { branchInfoFromSessionStorage } from "../utils/Global";

export const useAddressOptions = (selectedBranch?: number | null) => {
  const {
    state: { authUser },
  } = useStateContext();

  const { branch_id } = branchInfoFromSessionStorage();
  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { data: branchData } = useBranchFindUniqueQuery(graphQLClient, {
    uniqueInput: {
      id: parseInt(branch_id || "0"),
    },
  });

  const { data: branchByUserData } = useBranchUserGetByUserIdQuery(
    graphQLClient,
    {
      userId: authUser?.id,
    }
  );

  const { data: companyData } =
    useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(graphQLClient, {
      uniqueInput: {
        id: 1,
      },
    });

  const { data: warehousesByBranch } = useWarehousesFindAllQuery(
    graphQLClient,
    { whereInput: { branch_id: selectedBranch || 0 } }
  );

  const companyAddresList = companyData?.CompanyFindUnique?.address_list || [];

  const companyAddressOption =
    companyData?.CompanyFindUnique?.address_list?.map((list: IAddress) => ({
      ...list,
      label: list.type || "",
      value: list.type || "",
    })) || [];

  const branchByUser = branchByUserData?.BranchUserGetByUserId;

  const branch = {
    branchId: branchData?.BranchFindUnique?.id,
    branchName: branchData?.BranchFindUnique?.name,
    branchAddress: branchData?.BranchFindUnique?.address,
  };

  const warehouseOptions =
    warehousesByBranch?.WarehousesFindAll.map((warehouse) => ({
      ...warehouse,
      label: warehouse.name,
      value: warehouse.id,
    })) || [];

  const warehouses = warehousesByBranch?.WarehousesFindAll || [];

  return {
    branch,
    branchByUser,
    companyAddresList,
    companyAddressOption,
    warehouses,
    warehouseOptions,
  };
};
