import { Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useDisable } from "../../../hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { IBranch } from "../../../types/Setting/Branch";
import ControlledSelect from "../../Controller/ControlledSelect";
import LabelInput from "../../UI/LabelInput";
import RadioBox from "../../UI/RadioBox";
import ControlledSwitch from "../../Controller/ControlledSwitch";
import {
  GeneralModelType,
  GeneralUniqueIdGenerateQuery,
  ZonesFindAllQuery,
  useGeneralUniqueIdGenerateQuery,
  useZonesFindAllQuery,
} from "../../../generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ISelectOption } from "../../../types/global";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

const BranchDetail = () => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IBranch>();

  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const watchIsHq = useWatch({
    control,
    name: "is_hq",
  });

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphQLClientGeneral,
      {
        modelType: GeneralModelType.Branch,
      },
      {
        enabled: false,
      }
    );

  const { data: zones } =
    useZonesFindAllQuery<ZonesFindAllQuery>(graphQLClientGeneral);

  const zonesOption: ISelectOption[] =
    zones?.ZonesFindAll.map((zone) => ({
      label: zone.name_th,
      value: zone.name_th,
    })) || [];

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  return (
    <CustomizedBox>
      <Typography fontWeight={600} color="primary.main" mb={2}>
        {t("branch.info")}
      </Typography>
      <Grid container spacing={1.5}>
        {disabled ? (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <LabelInput
              label={t("branch.type")}
              value={watchIsHq ? t("branch.hq_branch") : t("branch.sub_branch")}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <RadioBox
                onClick={() => {
                  setValue("is_hq", true);
                }}
                label={t("branch.hq_branch")}
                checked={watchIsHq}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <RadioBox
                onClick={() => {
                  setValue("is_hq", false);
                }}
                label={t("branch.sub_branch")}
                checked={!watchIsHq}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={disabled ? 8 : 4}
          lg={disabled ? 8 : 4}
          xl={disabled ? 8 : 4}
        />
        <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="unique_id"
            control={control}
            label={t("branch.unique_id")}
            error={Boolean(errors.unique_id)}
            helperText={errors.unique_id?.message?.toString()}
            viewMode={disabled}
            disabled={Boolean(id)}
            required
          />
        </Grid>
        <Grid item xs={1} sm={1} md={8} lg={8} xl={8}>
          {!disabled && !id && (
            <CustomizedTooltip
              title={`เรียกรหัส${t(`branch.index`)}`}
              enterNextDelay={200}
            >
              <IconButton onClick={generateUniqueId} color="primary">
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="name"
            control={control}
            label={t("branch.name")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message?.toString()}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="short_name"
            control={control}
            label={t("branch.short_name")}
            error={Boolean(errors.short_name)}
            helperText={errors.short_name?.message?.toString()}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput label={t("branch.zone")} value={getValues("zone")} />
          ) : (
            <ControlledSelect
              name="zone"
              control={control}
              label={t("branch.zone")}
              error={Boolean(errors.zone)}
              helperText={errors.zone?.message?.toString()}
              options={zonesOption}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="phone"
            control={control}
            label={t("branch.phone")}
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message?.toString()}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="fax"
            control={control}
            label={t("branch.fax")}
            error={Boolean(errors.fax)}
            helperText={errors.fax?.message?.toString()}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} />
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSwitch
            control={control}
            name="status"
            disabled={disabled}
            label={t("branch.status")}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default BranchDetail;
