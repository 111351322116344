import { GraphQLClient } from "graphql-request";
import { IQuotation } from "../../../types/Sales/quotation";
import {
  IApprovalTemplate,
  IAttachment,
  IReviewedBy,
} from "../../../types/global";
import { uploadFileToS3 } from "../../s3";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ISalesItemList } from "../../../types/Sales";
import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import {
  QuotationFindUniqueQuery,
  SalesReferenceDocumentType,
} from "../../../generated/sales";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { CUSTOMERS_AGGRID } from "../../../services/AgGrid/ContactAgGrid";
import { IUser } from "../../../types/Auth/user";

export const quotationCreatePayloadFormatter = (
  data: IQuotation,
  status: string,
  approval_step?: number,
  reviewed_by?: IReviewedBy
) => {
  const {
    item_list,
    customer_details,
    tag_list,
    created_date,
    item_price_list,
    sales_channel,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: SalesReferenceDocumentType.Quotation,
      reference_unique_id: data.unique_id,
    })
  );

  const { unique_id_name, ...customer } = customer_details;

  const formatPayload = {
    ...otherData,
    customer_details: customer,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    approval_step: approval_step ? 1 : 0,
    reviewer_list: reviewed_by ? [reviewed_by] : [],
  };
  return formatPayload;
};

export const quotationUpdatePayloadFormatter = async (
  data: IQuotation,
  status: string,
  approval_step?: number,
  approver_list?: IApprovalTemplate[],
  isNotApprove?: boolean,
  reviewed_by?: IReviewedBy
) => {
  const {
    id,
    main_status,
    flag_status,
    aggrid_status,
    customer_details,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    branch,
    sales_channel,
    item_price_list,
    reviewer_list,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_details;

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: SalesReferenceDocumentType.Quotation,
      reference_unique_id: data.unique_id,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  let attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "quotation",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        attachment_list.push(formatAttachment);
      } else {
        attachment_list.push(file);
      }
    }
  }

  const formatFlag =
    !isNotApprove && status === "wait_approve"
      ? flag_status?.filter((flag) => flag !== "not_approved")
      : flag_status;

  const formatPayload = {
    ...otherData,
    approval_step: approval_step ? approval_step : 0,
    approver_list:
      approver_list && approver_list.length > 0
        ? approver_list
        : data.approver_list,
    reviewer_list: reviewer_list
      ? (data.aggrid_status === "wait_approve" ||
          (data.aggrid_status === "draft" &&
            approver_list &&
            approver_list.length === 0)) &&
        reviewed_by
        ? !isNotApprove
          ? [...reviewer_list, reviewed_by]
          : [...reviewer_list]
        : data.aggrid_status === "not_approved" && status === "wait_approve"
        ? []
        : [...reviewer_list]
      : [],
    customer_details: customer,
    item_list: formatItemList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    flag_status: formatFlag,
    attachment_list,
  };
  return formatPayload;
};

export const quotationQueryFormatter = async (
  data: QuotationFindUniqueQuery["QuotationFindUnique"]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");
  const allItemListUniqueId =
    data.item_list && data.item_list.length > 0
      ? data.item_list.map((item) => item?.item_sku_name)
      : [];

  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });

  const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item?.item_sku_name
      );

      const itemType = item as ISalesItemList;

      formatItemList.push({
        ...itemType,
        uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        item_sku_qty: itemSkuDetails[foundItemIndex]?.total_sku_qty,
      });
    });
  }

  const formatCustomer = {
    ...data.customer_details,
    unique_id_name: `${data.customer_unique_id} - ${data.customer_details.name}`,
  };

  const formatPayload = {
    ...data,
    customer_details: formatCustomer,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    due_date: data.due_date ? dayjs(data.due_date) : undefined,
    accepted_date: data.accepted_date ? dayjs(data.accepted_date) : undefined,
    item_price_list_id: data.item_price_list_id || 0,
  } as IQuotation;

  return formatPayload;
};

export const copyQuotationFormatter = async (
  data: IQuotation,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      created_by,
      accepted_date,
      accepted_remark,
      attachment_list,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      branch,
      sales_channel,
      approval_step,
      approver_list,
      reviewer_list,
      credit_day,
      // related_user_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({ id, unique_id, reference_unique_id, ...otherItemList }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
            })
          )
        : [];

    const graphQLClientContact: GraphQLClient =
      createGraphQLClientWithMiddleware("crm");

    const { CustomersView } = await graphQLClientContact.request(
      CUSTOMERS_AGGRID,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            unique_id: {
              filterType: "text",
              type: "equals",
              filter: otherData.customer_unique_id,
            },
          },
          sortModel: [],
        },
      }
    );

    const contactCreditDay = CustomersView.results[0].credit_day;

    // const formatRelatedUserList =
    //   // related_user_list.filter((user) => user.user_unique_id !== currentUser?.unique_id)
    //   created_by?.user_unique_id !== currentUser?.unique_id
    //     ? [created_by, ...related_user_list]
    //     : related_user_list;

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
      accepted_date: dayjs().toDate(),
      accepted_remark: "",
      attachment_list: [],
      // related_user_list: formatRelatedUserList,
      credit_day: contactCreditDay,
    };
  }
};

export const createSalesOrderFromQuotation = (data: IQuotation) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      reference_no,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      accepted_date,
      accepted_remark,
      attachment_list,
      branch,
      sales_channel,
      approval_step,
      approver_list,
      reviewer_list,
      tag_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              reference_document_type,
              reference_unique_id,
              unique_id,
              ...otherItemList
            }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
              reference_line_item: {
                line_item_unique_id: unique_id,
                line_item_document_type: SalesReferenceDocumentType.Quotation,
              },
            })
          )
        : [];

    return {
      ...otherData,
      reference_document_list: [
        {
          document_id: data.id,
          document_unique_id: data.unique_id,
          document_type: SalesReferenceDocumentType.Quotation,
        },
      ],
      item_list: formatItemList,
    };
  }
};
