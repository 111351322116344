import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  ContactImportMode,
  ContactImportType,
  ValidateContactImportQuery,
  useImportContactPersonMutation,
  useValidateContactImportQuery,
} from "../../../generated/contact";
import { Dispatch, SetStateAction, useState } from "react";
import { useSnackbar } from "notistack";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  addImporterErrorHandler,
  findNoneContactChannel,
  // findDuplicatesWithList,
  findNoneTitleName,
  mapNestedData,
} from "../../../utils/Importer";
import { IContactChannel, IImporterError } from "../../../types/global";
import { contactChannelFormatter } from "../../../utils/Formatter/Global";
import { useTranslation } from "react-i18next";

const keys = ["contact_channel_list"];

const columns = [
  "unique_id",
  "img_url",
  "position",
  "title_name",
  "first_name",
  "last_name",
  "nick_name",
  "contact_channel_list",
  "remark",
];

const count_limit = 50000;

export const useContactPersonImporter = (
  type: ContactImportType,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();
  const { t } = useTranslation();

  const requiredKeys = [
    {
      label: `รหัส${t(`contact.contact_type.${type}`)}`,
      value: "unique_id",
    },
    { label: "ตำแหน่ง", value: "position" },
    { label: "คำนำหน้า", value: "title_name" },
    { label: "ชื่อจริง", value: "first_name" },
  ];

  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const { refetch: validateCustomer, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_type: type,
          import_mode: ContactImportMode.Update,
          contact_unique_id_list: contactUniqueIdList,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useImportContactPersonMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const formattedDataSnapshot = [...data];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const uniqueIdList: string[] = [];
      const titleNameList: string[] = [];
      const contactChannelTypeList: string[] = [];

      const formattedData: any[] = Array.from(
        data
          .reduce((map: any, item: any) => {
            const {
              ing_url,
              unique_id,
              position,
              title_name,
              first_name,
              last_name,
              nick_name,
              contact_channel_list,
              remark,
            } = item;

            const formatContactChannelList: IContactChannel[] = [];

            contact_channel_list.forEach((channel: IContactChannel) => {
              if (channel) {
                contactChannelTypeList.push(channel.contact_channel_type);
                formatContactChannelList.push({
                  contact_channel_type:
                    contactChannelFormatter(
                      channel.contact_channel_type,
                      false
                    ) || "",
                  contact_channel_info: channel.contact_channel_info,
                });
              }
            });

            if (!map.has(unique_id)) {
              // Create a new entry if the unique_id doesn't exist
              map.set(unique_id, {
                unique_id,
                contact_person_list: [],
              });
            }

            if (unique_id) {
              uniqueIdList.push(unique_id);
            }
            if (title_name) {
              titleNameList.push(title_name);
            }

            // Add the contact channel to the existing entry
            map.get(unique_id).contact_person_list.push({
              ing_url,
              position,
              title_name,
              first_name,
              last_name,
              nick_name,
              contact_channel_list: formatContactChannelList,
              remark,
            });

            return map;
          }, new Map())
          .values()
      );

      const noneTitleName = findNoneTitleName(titleNameList);
      const noneContactChannel = findNoneContactChannel(contactChannelTypeList);

      const allError = [noneTitleName, noneContactChannel, requiredFieldErrors];

      addImporterErrorHandler(
        errorData,
        "คำนำหน้าไม่ถูกต้อง",
        "คำนำหน้า",
        noneTitleName
      );

      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ช่องทางการติดต่อ",
        noneContactChannel
      );

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setContactUniqueIdList([...new Set(uniqueIdList)]);

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData(keys, getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
  const importHandler = async () => {
    try {
      const { data } = await validateCustomer();
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];

      const arrays = [missingUniqueId];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];
      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        `รหัส${t(`contact.contact_type.${type}`)}`,
        missingUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await create({
            importInput: {
              import_type: type,
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isCreating,
    validateHandler,
    importHandler,
  };
};
