import { useSnackbar } from "notistack";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { IDefaultForm } from "../../types/global";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  TagCreateInput,
  TagsQuery,
  useTagCreateMutation,
  useTagDeleteMutation,
  useTagsQuery,
} from "../../generated/general";
import CustomizedCreatable from "../Custom/CustomizedCreatable";
import CustomizedChips from "../Custom/CustomizedChips";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  disabled: IDefaultForm["disabled"];
  documentType: any;
  name: string;
  label?: string;
  onClickHandler?: (data: string) => void;
  fullWidth?: boolean;
};

interface Tag {
  id?: number;
  name: string;
  document_type: any;
}

// interface ModalPayload {
//   value: boolean;
//   document_id_list: string[];
//   optionToDelete: string;
//   uniqueInput: { id: number };
// }

type FieldArrayType = {
  tag_list: Tag[];
};

const TagList = ({
  control,
  getValues,
  setValue,
  disabled,
  documentType,
  name,
  label,
  onClickHandler,
  fullWidth,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tagList, setTagList] = useState<Tag[]>([]);
  //   const [modalDelete, setModalDelete] = useState<ModalPayload>({
  //     value: false,
  //     document_id_list: [],
  //     optionToDelete: "",
  //     uniqueInput: { id: 0 },
  //   });

  const { fields, append, remove } = useFieldArray<
    FieldArrayType,
    "tag_list",
    "id"
  >({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "tag_list", // unique name for your Field Array
  });

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: tagListResult, isSuccess } = useTagsQuery<TagsQuery>(
    graphQLClient,
    {
      findManyInput: {
        document_type: documentType,
      },
    }
  );

  const { mutateAsync: createTag } = useTagCreateMutation<Error>(
    graphQLClient,
    {
      onSuccess(data) {
        const { TagCreate } = data;
        setTagList((prev) => [...prev, TagCreate]);
      },
    }
  );

  //   const { mutate: verifyTag } = useContactTagDeleteVerifyMutation<Error>(
  //     graphQLClient,
  //     {
  //       onSuccess(data, variables) {
  //         if (
  //           data.contactTagDeleteVerify &&
  //           data.contactTagDeleteVerify.length === 0
  //         ) {
  //           deleteOptionHandler(variables.uniqueInput ?? { id: 0 });
  //         } else
  //           setModalDelete((prev) => ({
  //             ...prev,
  //             value: true,
  //             document_id_list: data.contactTagDeleteVerify || [],
  //             uniqueInput: variables.uniqueInput ?? { id: 0 },
  //           }));
  //       },
  //       onError() {
  //         enqueueSnackbar("ไม่มีกลุ่มนี้อยู่ในระบบ", {
  //           variant: "error",
  //         });
  //       },
  //     }
  //   );

  const { mutate: daleteTag } = useTagDeleteMutation<Error>(graphQLClient, {
    onSuccess(data) {
      setTagList((prevOptions) =>
        prevOptions.filter((option) => option.id !== data.TagDelete?.id)
      );
    },
    onError() {
      enqueueSnackbar("ลบการจัดกลุ่มไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { Tags } = tagListResult;
      setTagList(Tags);
    }
  }, [isSuccess, tagListResult]);

  //   const getAllTag = useCallback(() => {
  //     if (isSuccess) {
  //       const { contactTagsFindByEntity } = tagListResult;
  //       const tagListType = contactTagsFindByEntity as TagList[];
  //       setTagList(tagListType);
  //     }
  //   }, [isSuccess, tagListResult]);

  const createOptionHandler = async (createInput: TagCreateInput) => {
    const { TagCreate } = await createTag({ data: createInput });
    return TagCreate;
  };

  //   const deleteVerify = async (id: number, name: string) => {
  //     const findId = tagList.find((tag) => tag.id === id);
  //     if (findId) {
  //       setModalDelete((prev) => ({
  //         ...prev,
  //         optionToDelete: name,
  //       }));
  //       //   verifyTag({ uniqueInput });
  //     }
  //   };

  const deleteOptionHandler = async (id: number) => {
    daleteTag({
      uniqueInput: {
        id,
      },
    });
    setTagList((prevOptions) =>
      prevOptions.filter((option) => option.id !== id)
    );
    const value: Tag[] = getValues(name);
    const filterValue = value.filter((option) => option.id !== id);
    setValue(name, filterValue);
  };

  //   const closeModalHandler = () => {
  //     setModalDelete((prev) => ({
  //       ...prev,
  //       value: false,
  //     }));
  //     setTimeout(() => {
  //       setModalDelete((prev) => ({
  //         ...prev,
  //         document_id_list: [],
  //         optionToDelete: "",
  //         uniqueInput: { id: 0 },
  //       }));
  //     }, 700);
  //   };

  //   const confirmationDeleteAction = () => {
  //     const { uniqueInput } = modalDelete;
  //     deleteOptionHandler(uniqueInput);
  //     closeModalHandler();
  //   };

  return (
    <>
      {!disabled && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={fullWidth ? 12 : 6}
            lg={fullWidth ? 12 : 6}
            xl={fullWidth ? 12 : 6}
          >
            <CustomizedCreatable
              testId="item-tag-list-combobox-field"
              options={
                tagList?.map((tag) => ({
                  id: tag.id,
                  value: tag.name,
                  label: tag.name,
                })) || []
              }
              placeholder={label}
              onChange={async (
                event: ChangeEvent<{ value: unknown }>,
                newValue: any
              ) => {
                if (typeof newValue === "string") {
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  const createInput: TagCreateInput = {
                    name: newValue.inputValue,
                    document_type: documentType,
                  };
                  const data = await createOptionHandler(createInput);
                  append({
                    id: data.id,
                    name: data.name,
                    document_type: documentType,
                  });
                } else {
                  if (newValue) {
                    const existingValue: Tag[] = getValues(name);
                    const isExist = existingValue.find(
                      (value) => value.name === newValue.value
                    );
                    if (!isExist) {
                      append({
                        id: newValue.id,
                        name: newValue.value,
                        document_type: documentType,
                      });
                    }
                  }
                }
              }}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                }
              }}
              renderOption={(props: any, option: any) => {
                return (
                  <li
                    {...props}
                    value={option.name}
                    key={option.id}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {option.label ?? option}
                    {!option?.label?.includes("เพิ่ม ") && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          deleteOptionHandler(option.id);
                          e.stopPropagation();
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </li>
                );
              }}
              disabled={disabled}
            />
          </Grid>
          {!fullWidth && <Grid item xs={6}></Grid>}
        </>
      )}
      <Grid item xs={12}>
        <Box display="flex" gap={0.5}>
          {fields && fields.length > 0 ? (
            fields.map((data, index) => (
              <Controller
                key={data.id}
                name={`${name}[${index}].name`}
                control={control}
                render={({ field }) => (
                  <CustomizedChips
                    {...field}
                    handleDelete={!disabled ? () => remove(index) : null}
                    onClick={
                      onClickHandler
                        ? () => onClickHandler(data.name)
                        : undefined
                    }
                  />
                )}
              />
            ))
          ) : disabled ? (
            <Typography mx={2}>-</Typography>
          ) : null}
        </Box>
      </Grid>
      {/* <DeleteTagListConfirmation
        payload={modalDelete}
        openDeleteConfirmation={modalDelete.value}
        comfirmationDeleteAction={comfirmationDeleteAction}
        closeModalHandler={closeModalHandler}
        entityType={entity}
      /> */}
    </>
  );
};

export default TagList;
