import { ISelectOption } from "../types/global";
import { GraphQLClient } from "graphql-request";
import {
  CustomerAttributesFindAllQuery,
  CustomerGroupsFindAllQuery,
  CustomerTypesFindAllQuery,
  SalesChannelsFindAllQuery,
  VendorAttributesFindAllQuery,
  VendorGroupsFindAllQuery,
  VendorTypesFindAllQuery,
  ZonesFindAllQuery,
  useCustomerAttributesFindAllQuery,
  useCustomerGroupsFindAllQuery,
  useCustomerTypesFindAllQuery,
  useSalesChannelsFindAllQuery,
  useVendorAttributesFindAllQuery,
  useVendorGroupsFindAllQuery,
  useVendorTypesFindAllQuery,
  useZonesFindAllQuery,
} from "../generated/general";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

export const useContactOption = (type: "customer" | "vendor") => {
  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: customerTypes, isLoading: isCustomerTypesLoading } =
    useCustomerTypesFindAllQuery<CustomerTypesFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "customer",
      }
    );

  const { data: customerGroups, isLoading: isCustomerGroupsLoading } =
    useCustomerGroupsFindAllQuery<CustomerGroupsFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "customer",
      }
    );

  const { data: customerAttributes, isLoading: isCustomerAttributesLoading } =
    useCustomerAttributesFindAllQuery<CustomerAttributesFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "customer",
      }
    );

  const { data: saleChannels, isLoading: isSaleChannelsLoading } =
    useSalesChannelsFindAllQuery<SalesChannelsFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "customer",
      }
    );

  const { data: vendorTypes, isLoading: isVendorTypesLoading } =
    useVendorTypesFindAllQuery<VendorTypesFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "vendor",
      }
    );

  const { data: vendorGroups, isLoading: isVendorGroupsLoading } =
    useVendorGroupsFindAllQuery<VendorGroupsFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "vendor",
      }
    );

  const { data: vendorAttributes, isLoading: isVendorAttributesLoading } =
    useVendorAttributesFindAllQuery<VendorAttributesFindAllQuery>(
      graphQLClientGeneral,
      {},
      {
        enabled: type === "vendor",
      }
    );

  const { data: zones, isLoading: isZonesLoading } =
    useZonesFindAllQuery<ZonesFindAllQuery>(graphQLClientGeneral);

  const customerTypesOption: ISelectOption[] =
    customerTypes?.CustomerTypesFindAll.map((type) => ({
      label: type.name_th,
      value: type.name_th,
    })) || [];

  const customerGroupsOption: ISelectOption[] =
    customerGroups?.CustomerGroupsFindAll.map((group) => ({
      label: group.name_th,
      value: group.name_th,
    })) || [];

  const vendorTypesOption: ISelectOption[] =
    vendorTypes?.VendorTypesFindAll.map((type) => ({
      label: type.name_th,
      value: type.name_th,
    })) || [];

  const vendorGroupsOption: ISelectOption[] =
    vendorGroups?.VendorGroupsFindAll.map((group) => ({
      label: group.name_th,
      value: group.name_th,
    })) || [];

  const saleChannelsOption: ISelectOption[] =
    saleChannels?.SalesChannelsFindAll.map((sale) => ({
      label: sale.name_th,
      value: sale.name_th,
    })) || [];

  const zonesOption: ISelectOption[] =
    zones?.ZonesFindAll.map((zone) => ({
      label: zone.name_th,
      value: zone.name_th,
    })) || [];

  if (type === "customer") {
    return {
      typesOption: customerTypesOption,
      groupsOption: customerGroupsOption,
      saleChannelsOption,
      isLoading:
        isCustomerTypesLoading ||
        isCustomerGroupsLoading ||
        isSaleChannelsLoading ||
        isCustomerAttributesLoading ||
        isZonesLoading,
      attributes: customerAttributes?.CustomerAttributesFindAll,
      zonesOption,
    };
  } else {
    return {
      typesOption: vendorTypesOption,
      groupsOption: vendorGroupsOption,
      isLoading:
        isVendorTypesLoading ||
        isVendorGroupsLoading ||
        isVendorAttributesLoading ||
        isZonesLoading,
      attributes: vendorAttributes?.VendorAttributesFindAll,
      zonesOption,
    };
  }
};
