import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, DialogContentText, Stack, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  action: () => void;
};

const PrintModal = ({ open, closeModalHandler, action }: Props) => {
  const { t } = useTranslation();

  const onClose = () => {
    closeModalHandler();
  };

  return (
    <ModalUI
      title={`${t("print.index")}${t("print.original")}`}
      open={open}
      handleClose={onClose}
      maxWidth={"xs"}
      action={
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={action}
          />
        </Stack>
      }
    >
      <>
        <DialogContentText>
          <Box textAlign="center">
            <WarningIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "warning.main",
              }}
            />
            <Box>
              <Typography>การพิมพ์เอกสารต้นฉบับ</Typography>
              <Typography fontWeight={600}>
                สามารถพิมพ์ได้เพียงครั้งเดียว
              </Typography>
            </Box>
          </Box>
        </DialogContentText>
      </>
    </ModalUI>
  );
};

export default PrintModal;
