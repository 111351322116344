import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { FormProvider, useForm } from "react-hook-form";
import { IBranch } from "../../../types/Setting/Branch";
import {
  branchSchema,
  branchValidation,
  // branchValidation,
} from "../../../components/Form/Branch/schema";
import BranchDetail from "../../../components/Form/Branch/Detail";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { Box, CircularProgress, Stack } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useDisable } from "../../../hooks/use-disable";
import { useEffect } from "react";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  BranchFindUniqueQuery,
  ZonesFindAllQuery,
  useBranchCreateMutation,
  useBranchDeleteMutation,
  useBranchFindUniqueQuery,
  useBranchUpdateMutation,
  useZonesFindAllQuery,
} from "../../../generated/general";
import { useSnackbar } from "notistack";
import {
  branchCreateFormatter,
  branchUpdateFormatter,
} from "../../../utils/Formatter/Branch";
import BranchAddress from "../../../components/Form/Branch/Address";
import { useModal } from "../../../hooks/use-modal";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Global";

const selectModifyOptions = [
  {
    value: "แก้ไข",
    disabled: false,
  },
  {
    value: "ลบ",
    disabled: false,
  },
];

const BranchContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useDisable();

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<IBranch>({
    defaultValues: branchSchema,
    resolver: yupResolver<any>(branchValidation),
  });

  const { handleSubmit, reset } = methods;

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isSuccess, refetch } =
    useBranchFindUniqueQuery<BranchFindUniqueQuery>(
      graphqlClientGeneral,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
      }
    );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("branch.info"),
      to: "/setting/branch",
    },
    {
      name: id
        ? data && data.BranchFindUnique?.unique_id
          ? data.BranchFindUnique.unique_id
          : ""
        : t("button.add") + t("branch.index"),
      to: "/setting/branch",
    },
  ];

  const { isLoading: isZonesLoading } =
    useZonesFindAllQuery<ZonesFindAllQuery>(graphqlClientGeneral);

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    if (isSuccess) {
      const { BranchFindUnique } = data;
      const branchData = BranchFindUnique as IBranch;
      reset(branchData);
    }
  }, [data, isSuccess, reset]);

  const { mutateAsync: create, isLoading: isCreating } =
    useBranchCreateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: update, isLoading: isUpdating } =
    useBranchUpdateMutation<Error>(graphqlClientGeneral);

  const { mutateAsync: deleteBranch, isLoading: isDeleting } =
    useBranchDeleteMutation<Error>(graphqlClientGeneral);

  const branchCreateHandler = async (data: IBranch) => {
    try {
      const formatData = branchCreateFormatter(data);
      const { BranchCreate } = await create({
        data: formatData,
      });
      refetch();
      enqueueSnackbar("สร้างสาขาสำเร็จ", {
        variant: "success",
      });
      navigate(`/setting/branch/${BranchCreate?.id}`);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "สร้างสาขาไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const branchUpdateHandler = async (data: IBranch) => {
    try {
      const { id } = data;
      const formatData = branchUpdateFormatter(data);
      const { BranchUpdate } = await update({
        uniqueInput: {
          id: id,
        },
        data: formatData,
      });
      refetch();
      enqueueSnackbar("แก้ไขสาขาสำเร็จ", {
        variant: "success",
      });
      const updatedData = BranchUpdate as IBranch;
      reset(updatedData);
      setDisabled(true);
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || "แก้ไขสาขาไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const branchDeleteHandler = async () => {
    try {
      await deleteBranch({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`ลบ${t("branch.index")}สำเร็จ`, {
        variant: "success",
      });
      navigate("/setting/branch");
    } catch (err) {
      const formatError = errorMessageFormatter(err);
      enqueueSnackbar(formatError || `ลบ${t("branch.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(branchDeleteHandler);

  if (
    isZonesLoading ||
    (id && (isLoading || isCreating || isUpdating || isDeleting))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        maxWidth={1040}
        mt={3}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                setDisabled(false);
                break;
              case "ลบ":
                openConfirmationHandler();
                break;
              default:
                break;
            }
          }}
          disabled={!id}
        />
      </Box>
      <FormProvider {...methods}>
        <BranchDetail />
        <BranchAddress address={data?.BranchFindUnique?.address} />
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              {!!id && (
                <CustomizedButton
                  variant="outlined"
                  title={t("button.cancel")}
                  onClick={editCancelHandler}
                  disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(
                  id ? branchUpdateHandler : branchCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <ConfirmationModal
        title={`ยันยืนการลบ${t("branch.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default BranchContainer;
