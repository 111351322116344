import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useDisable } from "../../../../hooks/use-disable";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "../../../../generated/sales";
import { useCallback, useEffect } from "react";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { IApprovalTemplate, IMenuOption } from "../../../../types/global";
import {
  useQuotationCreate,
  useQuotationOption,
} from "../../../../hooks/Sales/Quotation/use-quotation-option";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import {
  copyQuotationFormatter,
  createSalesOrderFromQuotation,
} from "../../../../utils/Formatter/Sales/Quotation";
import Confirmation from "../../../UI/Confirmation";
import ApproverBox from "../../../UI/ApproverBox";
import { useStateContext } from "../../../../contexts/auth-context";
import SalesPersonHeader from "../SalesPerson";
import { Dayjs } from "dayjs";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import LabelInput from "../../../UI/LabelInput";

type Props = {
  editClickHandler: () => void;
  cancelHandler: () => Promise<void>;
  approverList: IApprovalTemplate[];
  approvalStep?: number | null;
};

const QuotationHeader = ({
  editClickHandler,
  cancelHandler,
  approverList,
  approvalStep,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<any>();
  const [disabled] = useDisable();
  const navigate = useNavigate();
  const {
    state: { authUser },
  } = useStateContext();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const issueDate: Dayjs = useWatch({
    control,
    name: "issue_date",
  });

  const dueDate: Dayjs = useWatch({
    control,
    name: "due_date",
  });

  const priceDay =
    issueDate &&
    dueDate &&
    dueDate.endOf("day").diff(issueDate.startOf("day"), "days");

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("sales.quotation.index")}`,
      disabled: !id,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.Quotation,
      },
      {
        enabled: false,
      }
    );

  const selectModifyOptions = useQuotationOption(status);
  const { selectCreateOptions, disabledCreateOptions } =
    useQuotationCreate(status);

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const copyDocumentHandler = async () => {
    const quotation = getValues();
    navigate("/sales/quotation/add", {
      state: await copyQuotationFormatter(quotation, authUser),
    });
  };

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("sales.quotation.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (
                    value.innerText === `พิมพ์${t("sales.quotation.index")}`
                  ) {
                    navigate(`/sales/quotation/${id}/pdf`);
                  }
                }}
                disabled={
                  !id || status === "cancelled" || status === "not_approved"
                }
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openCancelConfirmation();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("sales.order.index")}`:
                      const quotation = getValues();
                      navigate("/sales/order/add", {
                        state: createSalesOrderFromQuotation(quotation),
                      });
                      break;
                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("sales.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id) && status && status !== "draft"}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {(!Boolean(id) || (status && status === "draft")) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                placeholder={t("sales.reference_no")}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            {id && status !== "draft" && approverList.length > 0 && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography fontWeight={600}>
                    {t("sales.approval")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={7}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      mt: 1,
                      whiteSpace: "nowrap",
                      flexWrap: "wrap",
                      mx: disabled ? 1 : 0,
                    }}
                  >
                    {approverList.map((approval, index) => {
                      const formatApproval = approval as IApprovalTemplate;
                      return (
                        <ApproverBox
                          key={index}
                          approval={formatApproval}
                          index={index}
                          approvalStep={approvalStep}
                        />
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </>
            )}

            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box mx={disabled ? 1.5 : 0}>
                <SalesPersonHeader />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              {!disabled ? (
                <CustomizedTextField
                  label={t("date.price_day")}
                  value={priceDay}
                  disabled
                  type="number"
                />
              ) : (
                <LabelInput
                  label={t("date.price_day")}
                  value={priceDay || ""}
                  noDecimal
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                label={t("date.to_date")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
                viewMode={disabled}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                noDecimal
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยกเลิกใบเสนอราคา"
        message="หากทำการยกเลิกเอกสารจะไม่สามารถย้อนกลับได้"
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
    </Box>
  );
};

export default QuotationHeader;
