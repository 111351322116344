import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHeaderTable } from "../../../../types/global";

export const useSalesDeductionColumnDefs = (documentType: string) => {
  const { t } = useTranslation();
  const [columnDef, setColumnDef] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultCol: IHeaderTable[] = [
      {
        thaiLabel: "วันที่",
        width: 88,
        align: "left",
      },
      {
        thaiLabel: t("sales.deduction.document_type"),
        width: 168,
        align: "left",
      },
      {
        thaiLabel: t("sales.deduction.document_unique_id"),
        width: 168,
        align: "left",
      },
      {
        thaiLabel: t("sales.deduction.deduction_amount"),
        width: 168,
        align: "right",
      },
      {
        thaiLabel: t("sales.deduction.credit_balance"),
        width: 168,
        align: "right",
      },
      {
        thaiLabel: t("sentence.remark"),
        width: 350,
        align: "left",
      },
    ];
    if (documentType === "sales_invoice") {
      setColumnDef([
        ...defaultCol,
        {
          thaiLabel: "",
          width: 36,
        },
      ]);
    } else {
      setColumnDef(defaultCol);
    }
  }, [documentType, t]);
  return columnDef;
};
