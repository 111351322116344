import { Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import { IContact } from "../../../types/Contact/contact";
import { useFormContext } from "react-hook-form";
import { useDisable } from "../../../hooks/use-disable";
import TagList from "../../UI/TagList";

interface Props {
  type: "customer" | "vendor";
}

const ContactTag = ({ type }: Props) => {
  const { control, getValues, setValue } = useFormContext<IContact>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  return (
    <CustomizedBox>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("contact.tag_list")}
      </Typography>
      <Grid container spacing={1.5}>
        <TagList
          control={control}
          name="tag_list"
          label={t("contact.tag_list")}
          documentType={type}
          disabled={disabled}
          getValues={getValues}
          setValue={setValue}
        />
      </Grid>
    </CustomizedBox>
  );
};

export default ContactTag;
