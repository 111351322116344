import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useStateContext } from "../../../../contexts/auth-context";

import { Avatar, Box, Grid, Typography } from "@mui/material";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";

import { IGoodsReceive } from "../../../../types/Inventory/GoodsReceive";
import { printStatusWithoutApproval } from "../../../../utils/Constant";

const GoodsReceiveHeader = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const { control, setValue, getValues } = useFormContext<IGoodsReceive>();

  const status = useWatch({ control, name: "aggrid_status" });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  useEffect(() => {
    if (!id && authUser) {
      setValue("created_by", {
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, id, setValue]);

  const isFinished = ["finished", "cancelled"].includes(status);

  return (
    <Box maxWidth={1040} my={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography variant="h5">
              {id
                ? getValues("unique_id")
                : `${t("button.create")}${t("inventory.goods_receive.index")}`}
            </Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={6} sm={6} md={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={t("button.print_document")}
                options={[{ value: t("button.print") }]}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("button.print")}`:
                      navigate("pdf");
                      break;
                    default:
                  }
                }}
                disabled={!id || !printStatusWithoutApproval.includes(status)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                // error={Boolean(errors.created_date)}
                label={t("date.created_date")}
                disabled={true}
                // viewMode={disabled && status !== "draft"}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <ControlledDatePicker
                name="posted_date"
                control={control}
                // error={Boolean(errors.posted_date)}
                label={t("date.received_date")}
                disabled={isFinished}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mt: 1 }}>
        <Typography fontWeight="bold">{t("sentence.created_by")}</Typography>
        <CustomizedTooltip
          title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
          enterNextDelay={200}
        >
          {watchCreatedBy?.img_url && watchCreatedBy?.img_url.length > 0 ? (
            <Avatar
              alt={`avatar`}
              src={watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""}
            />
          ) : (
            <CustomizedLetterAvatar
              name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
            />
          )}
        </CustomizedTooltip>
      </Box>
    </Box>
  );
};

export default GoodsReceiveHeader;
