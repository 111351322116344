import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import { IPdfInfo } from "../../../types/global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CompanyFindUniqueQuery,
  useCompanyFindUniqueQuery,
} from "../../../generated/general";
import { ICompany } from "../../../types/Setting/Company";
import PdfCompanyInfo from "../SalesPdf/PdfCompanyInfo";
import SalesPdfLeftInfo from "../SalesPdf/SalesPdfLeftInfo";
import SalesPdfHighlight from "../SalesPdf/SalesPdfHighlight";
import SalesPdfRightInfo from "../SalesPdf/SalesPdfRightInfo";
import SalesPdfExternalSigner from "../SalesPdf/SalesPdfExternalSigner";

type Props = {
  name: string;
  highlightSection: IPdfInfo;
  leftSection: IPdfInfo;
  rightSection: IPdfInfo;
  data: any;
  multiplePage?: boolean;
  page?: number;
  allPages?: number;
  children: ReactNode;
  documentType: string;
};

const InventoryPdfLayout = ({
  name,
  highlightSection,
  leftSection,
  rightSection,
  data,
  multiplePage,
  page,
  allPages,
  children,
  documentType,
}: Props) => {
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${height}px`
      );
    }
  }, []);

  const graphqlClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: companyData, isLoading } =
    useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(graphqlClientGeneral, {
      uniqueInput: {
        id: 1,
      },
    });

  const isLastPage = multiplePage && page === allPages;

  return (
    <>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
          "@media print": {
            width: "auto",
            height: "297mm",
            //padding: '0',
            boxSizing: "border-box",
          },
        }}
      >
        <Box>
          <Box id="print-header" ref={headerRef}>
            <Grid container spacing={1.5}>
              <Grid item xs={7}>
                <PdfCompanyInfo
                  branch_unique_id={data.destination_branch.unique_id}
                  company={companyData?.CompanyFindUnique as ICompany}
                  isLoading={isLoading}
                />
                <Divider sx={{ my: 1 }} />
                <SalesPdfLeftInfo
                  headers={leftSection.headers}
                  info={leftSection.info}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  fontSize={26.66}
                  fontWeight={600}
                  align="right"
                  color={"primary.main"}
                >
                  {name}
                </Typography>
                <SalesPdfHighlight
                  headers={highlightSection.headers}
                  info={highlightSection.info}
                />
                <SalesPdfRightInfo
                  headers={rightSection.headers}
                  info={rightSection.info}
                />
              </Grid>
            </Grid>

            {/* <SalesPdfFooter data={data} /> */}
          </Box>
          <Box id="print-content">{children}</Box>
        </Box>
        <Box>
          {(multiplePage && isLastPage) || allPages === 1 ? (
            <>
              <Box height={"auto"} mb={1}>
                <Typography fontWeight={600} fontSize={10.66}>
                  หมายเหตุ
                </Typography>
                <Typography fontSize={10.66}>{data.remark || "-"}</Typography>
              </Box>
            </>
          ) : null}
          {(page || multiplePage) && (
            <>
              <Divider />
              <SalesPdfExternalSigner
                documentType={documentType}
                company={companyData?.CompanyFindUnique as ICompany}
              />
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  fontSize: "10px",
                  color: "#737373",
                  position: "absolute",
                  bottom: 10,
                  right: 24,
                  "@media print": {
                    height: "auto",
                  },
                }}
              >
                <Typography fontSize={11} align="right" color="#333333">
                  หน้า {page}/{allPages || 1}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default InventoryPdfLayout;
