import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";
import { IItem } from "../../../../types/Inventory/item";
import dayjs from "dayjs";

export const itemSchema: IItem = {
  unique_id: "",
  name: "",
  description: "",
  type: null,
  barcode: "",
  model: "",
  seller_unique_id: "",
  vendor_contact: {
    id: undefined,
    first_name: "",
    last_name: "",
    img_url: [],
  },
  img_url: [],
  tag_list: [],
  category_group: "",
  main_category: "",
  sub_category: "",
  brand: "",
  segment: "",
  series: "",
  // is_variation: "false",
  is_purchasable: true,
  is_saleable: true,
  is_stockable: true,
  is_active: 1,
  remark_status: "",
  delivery_attribute: {
    weight: 0,
    length: 0,
    height: 0,
    width: 0,
    weight_uom_id: 3,
    height_uom_id: 2,
    length_uom_id: 2,
    width_uom_id: 2,
  },
  // volume_uom: "",
  stock_uom_id: undefined,
  purchase_uom_id: undefined,
  sales_uom_id: undefined,
  deliver_uom_id: undefined,
  tracability: Tracability.Serial,
  item_variant_key_list: [],
  sku_list: [],
  bundle_item_detail_list: [],
  purchase_standard_price: 0,
  min_purchase_request_qty: 0,
  sale_price: 0,
  special_price: 0,
  // main_bom: null,
  // main_bom_name: "",
  // mrp_default_method: "no_setting",
  // uom_group: {
  //   id: "",
  //   base_uom: "",
  //   convertable_uom_list: [],
  //   name: "",
  //   uom_conversion_list: [],
  // },
  // uom_conversion_list: [],
  // item_group_sub_level_1: { id: "", name: "", sub_level_2_list: [] },
  insurance_name: "",
  insurance_detail: "",
  customer_insurance_duration: 0,
  seller_insurance_duration: 0,
  selling_point_list: [],
  set_attribute: {
    name: "",
  },
  attribute_list: [],
  variation_list: [],
  variation_img_list: [],
  option_1: { key: "", list: [] },
  option_2: { key: "", list: [] },
  option_3: { key: "", list: [] },
  min_stock_qty: 0,
  max_stock_qty: 0,
  reorder_point: 0,
  created_date: dayjs(),
};

const positiveNumberValidate = Yup.number()
  .min(0, "กรุณาระบุมากกว่าหรือเท่ากับ 0")
  .required("กรุณาระบุ")
  .typeError("กรุณาระบุค่าให้ถูกต้อง");

export const itemValidate = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  seller_unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  type: Yup.string().required("กรุณาระบุ"),
  tracability: Yup.string().required("กรุณาเลือกการติดตาม"),
  barcode: Yup.string().when("tracability", {
    is: (v: any) => v !== "serial",
    then: (schema) =>
      schema.when("type", {
        is: (v: any) => v !== "variant",
        then: (schema) => schema.required("กรุณาระบุ"),
        otherwise: (schema) => schema.notRequired(),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  stock_uom_id: Yup.number().required("กรุณาเลือก"),
  attribute_list: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required("กรุณาระบุ"),
    })
  ),
  bundle_item_detail_list: Yup.array().when("type", {
    is: (v: any) => v === "bundle",
    then: (schema) =>
      schema
        .min(1, "กรุณาเลือกรายการสินค้าอย่างน้อย 1 ตัว")
        .of(
          Yup.object().shape({
            qty: Yup.number()
              .required("กรุณาระบุจำนวน")
              .min(1, "กรุณาระบุจำนวนอย่างน้อย 1"),
            price: Yup.number().required("กรุณาระบุราคา"),
            special_price: Yup.number().required("กรุณาระบุราคาพิเศษ"),
            ratio_percentage: Yup.number().min(0).max(100).required(),
          })
        )
        .test(
          "sum-of-percentage",
          "กรุณาระบุเปอร์เซ็นต์ผลรวมรายการให้ครบ 100",
          (item) => {
            const sum =
              item?.reduce((acc, item) => {
                return acc + item.ratio_percentage;
              }, 0) || 0;
            return sum === 100;
          }
        )
        .test(
          "sum-of-sale-price",
          "กรุณาระบุราคารวมในรายการให้เท่ากับราคาของสินค้าจัดชุด",
          function (item) {
            const sum =
              item?.reduce((acc, item) => {
                return acc + item.price;
              }, 0) || 0;
            return sum === this.parent.sale_price;
          }
        )
        .test(
          "sum-of-special-price",
          "กรุณาระบุราคาพิเศษรวมในรายการให้เท่ากับราคาพิเศษของสินค้าจัดชุด",
          function (item) {
            const sum =
              item?.reduce((acc, item) => {
                return acc + item.special_price;
              }, 0) || 0;
            return sum === this.parent.special_price;
          }
        )
        .required(),
    otherwise: (schema) => schema.notRequired(),
  }),

  // option_1: Yup.object().when("type", {
  //   is: (v: ItemType) => v === ItemType.Variant,
  //   then: (schema) =>
  //     schema.shape({
  //       key: Yup.string().required("กรุณากรอก"),
  //       list: Yup.array().min(1),
  //     }),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // variation_list: Yup.array().when("tracability", {
  //   is: (v: any) => {
  //     return v !== Tracability.Serial;
  //   },
  //   then: (schema) =>
  //     schema.when("variation_list", {
  //       is: (v: any) => {
  //         return v && v.length > 0 && v[0].value && v[0].value.length > 0;
  //       },
  //       then: (schema) =>
  //         schema.of(
  //           Yup.object().shape(
  //             {
  //               value: Yup.array().when("value", {
  //                 is: (v: any) => {
  //                   return (
  //                     v && v.length > 0 && v[0].value && v[0].value.length > 0
  //                   );
  //                 },
  //                 then: (schema) =>
  //                   schema.of(
  //                     Yup.object().shape({
  //                       value: Yup.array().of(
  //                         Yup.object().shape({
  //                           barcode: Yup.string().required("กรุณาระบุ"),
  //                         })
  //                       ),
  //                     })
  //                   ),
  //                 otherwise: (schema) => {
  //                   console.log("otherwise");
  //                   return schema.of(
  //                     Yup.object().shape({
  //                       barcode: Yup.string().required("กรุณาระบุ"),
  //                     })
  //                   );
  //                 },
  //               }),
  //             },
  //             [["value", "value"]]
  //           )
  //         ),
  //       otherwise: (schema) =>
  //         schema.of(
  //           Yup.object().shape({ barcode: Yup.string().required("กรุณาระบุ") })
  //         ),
  //     }),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  sale_price: positiveNumberValidate,
  special_price: positiveNumberValidate,
  purchase_standard_price: positiveNumberValidate,
  min_purchase_request_qty: positiveNumberValidate,
  // sale_vat_type: Yup.string().required("กรุณาระบุ"),
  // purchase_vat_type: Yup.string().required("กรุณาระบุ"),
});

// schema.of(
//   Yup.object().shape({
//     value: Yup.array().when("value", {
//       is: (v: any) => {
//         console.log("v2", v);
//         return v.value && v.value.length > 0;
//       },
//       then: () =>
//         Yup.array().of(
//           Yup.object().shape({
//             value: Yup.array().when("value", {
//               is: (v: any) => v.value && v.value.length > 0,
//               then: () =>
//                 Yup.array().of(
//                   Yup.object().shape({
//                     value: Yup.array().when("", {
//                       is: (v: any) => v.value && v.value.length > 0,
//                     }),
//                   })
//                 ),
//               otherwise: () =>
//                 Yup.array().of(
//                   Yup.object().shape({
//                     barcode: Yup.string().required("กรุณาระบุ"),
//                   })
//                 ),
//             }),
//           })
//         ),
//       otherwise: () =>
//         Yup.array().of(
//           Yup.object().shape({
//             barcode: Yup.string().required("กรุณาระบุ"),
//           })
//         ),
//     }),
//   })
// ),
