import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "../types/global";
import { useTranslation } from "react-i18next";

const useAttributeColumn = (disabled: boolean) => {
  const { t } = useTranslation();
  const defaultHeaders: IHeaderTable[] = useMemo<IHeaderTable[]>(
    () => [
      {
        thaiLabel: t("contact.attribute.index"),
        width: 200,
      },
      {
        thaiLabel: t("contact.attribute.short_name"),
        width: 200,
      },
      {
        thaiLabel: t("contact.attribute.value"),
        width: 200,
      },
    ],
    [t]
  );
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [defaultHeaders, disabled]);

  return headers;
};

export default useAttributeColumn;
