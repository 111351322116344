import { useDisable } from "../../../hooks/use-disable";
import DocumentDropzoneUI from "../../UI/DocumentDropzoneUI";
import { useFormContext } from "react-hook-form";

const ContactAttachment = () => {
  const { control, setValue } = useFormContext();
  const [disabled] = useDisable();
  return (
    <DocumentDropzoneUI
      control={control}
      name="attachment_list"
      setValue={setValue}
      disabled={disabled}
    />
  );
};

export default ContactAttachment;
