import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSalesItemListPdfHeader } from "./columnDef";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { numberFormatter } from "../../../../utils/Formatter/Global";

type Props = {
  itemList: IPurchaseItemList[];
  startNumber: number;
};

const PurchaseRequestListPdfTable = ({ itemList, startNumber }: Props) => {
  const headers = useSalesItemListPdfHeader();

  const tablePadding = {
    px: 1,
    py: 1,
  };

  const fontSize = 10.66;

  return (
    <>
      <Box>
        <TableContainer
          sx={{
            my: 1,
          }}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item, index) => (
                <TableRow key={item.unique_id}>
                  <TableCell align="center">
                    <Typography fontSize={fontSize}>
                      {startNumber + index}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.item_unique_id}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.item_name}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        color: "#737373",
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.item_sku_desc}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.qty)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontSize={fontSize}>{item.uom_name}</Typography>
                  </TableCell>
                  {/* <TableCell align="right">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {numberFormatter(item.po_qty)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      fontSize={fontSize}
                      sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
                    >
                      {item.remark}
                    </Typography>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default PurchaseRequestListPdfTable;
