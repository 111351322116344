import { Grid } from "@mui/material";
import ControlledSelect from "../../Controller/ControlledSelect";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
// import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ImporterDropzoneUI from "../../UI/ImporterDropzoneUI";
import RadioBox from "../../UI/RadioBox";
import { useFormContext } from "react-hook-form";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import {
  customerTypeOptions,
  vendorTypeOptions,
} from "../../../utils/Formatter/Contact";

type Props = {
  contactType: "customer" | "vendor";
};

const ContactImportUploader = ({ contactType }: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();

  const type = watch("type");
  const importType = watch("import_type");

  const downloadTemplateHandler = () => {
    let importTypeName: string = "";
    if (contactType === "customer") {
      importTypeName =
        customerTypeOptions.find((type) => type.value === importType)?.label ||
        "";
    } else {
      importTypeName =
        vendorTypeOptions.find((type) => type.value === importType)?.label ||
        "";
    }
    const filePath = `/static/importer/${contactType}/Template_${importTypeName}.xlsx`;
    window.open(filePath, "_blank");
  };

  const createButtonDisabled = [
    "customer_attribute",
    "vendor_attribute",
  ].includes(importType);
  const editButtonDisabled = ![
    "customer",
    "vendor",
    "customer_attribute",
    "vendor_attribute",
  ].includes(importType);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ControlledSelect
            control={control}
            name="import_type"
            options={
              contactType === "customer"
                ? customerTypeOptions
                : vendorTypeOptions
            }
            onChange={(e: any) => {
              const value = e.target.value;
              if (
                value === "customer_attribute" ||
                value === "vendor_attribute"
              ) {
                setValue("type", "update");
              } else {
                setValue("type", "create");
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedButton
            title={t("inventory.items.import.download_template")}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={downloadTemplateHandler}
          />
        </Grid>
        <Grid item xs={12} md={4} />
        {/* <Grid item xs={12} md={4}>
          <CustomizedTooltip title="">
            <InfoOutlinedIcon />
          </CustomizedTooltip>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "create");
            }}
            label={t("button.create")}
            checked={type === "create"}
            disabled={createButtonDisabled}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "update");
            }}
            label={t("button.edit")}
            checked={type === "update"}
            disabled={editButtonDisabled}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12}>
          <ImporterDropzoneUI hasLabelHeader />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ContactImportUploader;
