import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import ControlledSelect from "../../Controller/ControlledSelect";
import { IExporterSales, ISelectOption } from "../../../types/global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import RadioBox from "../RadioBox";
import CustomizedButton from "../../Custom/CustomizedButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SalesExportType } from "../../../generated/sales";
import { SALES_EXPORT } from "../../../services/Sales/SalesExport";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  getSalesExportTypeOptions,
  getSalesExporterSchema,
} from "../../../utils/Formatter/Sales/SalesExport";
import dayjs from "dayjs";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  documentType: SalesExportType;
};

const exporterValidation = Yup.object().shape({
  type: Yup.string().required("กรุณาเลือกประเภทข้อมูล"),
  start_date: Yup.date().when("export_type", {
    is: "date_range",
    then: (schema) => schema.required("กรุณาเลือกวันที่"),
    otherwise: (schema) => schema.nullable(),
  }),
  end_date: Yup.date().when("export_type", {
    is: "date_range",
    then: (schema) => schema.required("กรุณาเลือกวันที่"),
    otherwise: (schema) => schema.nullable(),
  }),
});

const ExporterSalesModal = ({
  open,
  closeModalHandler,
  documentType,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IExporterSales>({
    defaultValues: getSalesExporterSchema(documentType),
    resolver: yupResolver<any>(exporterValidation),
  });

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const typeOptions: ISelectOption[] = getSalesExportTypeOptions(documentType);

  const onClose = () => {
    reset(getSalesExporterSchema(documentType));
    closeModalHandler();
  };

  const exportHandler = async (data: IExporterSales) => {
    if (data) {
      const { type, start_date, end_date } = data;
      const formattedStartDate = start_date
        ? dayjs(start_date).startOf("day")
        : null;
      const formattedEndDate = end_date ? dayjs(end_date).endOf("day") : null;

      try {
        const { GenerateExport } = await graphQLClientWithHeaderSales.request(
          SALES_EXPORT,
          {
            input: {
              export_type: type,
              start_date: formattedStartDate,
              end_date: formattedEndDate,
            },
          }
        );
        window.open(GenerateExport, "_blank", "noopener,noreferrer");
        enqueueSnackbar("นำออกสำเร็จ", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("นำออกไม่สำเร็จ", {
          variant: "error",
        });
        console.error(error);
      }
      closeModalHandler();
    }
  };

  const watchSalesType = watch("type");
  const watchExportType = watch("export_type");

  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"md"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.export")}
            variant="contained"
            onClick={() => {
              handleSubmit(exportHandler)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12}>
          <ControlledSelect
            name="type"
            control={control}
            label={""}
            error={Boolean(errors.type)}
            helperText={errors.type?.message?.toString()}
            options={typeOptions}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.all")}
            onClick={() => {
              setValue("export_type", "all");
              setValue("start_date", null);
              setValue("end_date", null);
            }}
            checked={watchExportType === "all"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <RadioBox
            label={t("global.date_range")}
            onClick={() => {
              setValue("export_type", "date_range");
            }}
            checked={watchExportType === "date_range"}
          />
        </Grid>
        {watchExportType === "date_range" && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="start_date"
                label={t("date.from")}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                control={control}
                name="end_date"
                label={t("date.to")}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalUI>
  );
};

export default ExporterSalesModal;
