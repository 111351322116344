import { Grid, Box } from "@mui/material";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { useFormContext } from "react-hook-form";
import { IContact } from "../../../types/Contact/contact";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useTranslation } from "react-i18next";
import { useDisable } from "../../../hooks/use-disable";

type Props = {
  type?: string;
};

const ContactMember = ({ type }: Props) => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();

  const [disabled] = useDisable();
  return (
    <Box mt={2}>
      <Grid container spacing={1.5}>
        {type !== "vendor" && (
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="member_detail.member_unique_id"
              label={"รหัส" + t("contact.member.index")}
              viewMode={disabled}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledDatePicker
            control={control}
            name="member_detail.opened_date"
            label={t("date.opened_date")}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledDatePicker
            control={control}
            name="member_detail.expired_date"
            label={t("date.expired_date")}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactMember;
