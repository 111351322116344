import { Box, CircularProgress, Typography } from "@mui/material";
import {
  CustomerDepositFindUniqueQuery,
  CustomerDepositItem,
  SalesReferenceDocumentType,
  useCustomerDepositFindUniqueQuery,
  useSalesOriginalPrintMutation,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useParams } from "react-router-dom";
import { IPdfInfo, ISelectOption } from "../../../types/global";
import { formatDate } from "../../../utils/Date";
import { renderAddressString } from "../../../utils/Formatter/Global";
import DepositListPdfTable from "../../../components/Table/Pdf/DepositListTable";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { useReactToPrint } from "react-to-print";
import { ReactInstance, useRef, useState } from "react";
import SalesPdfLayout from "../../../components/UI/SalesPdf/SalesPdfLayout";
import { IContactPerson } from "../../../types/Contact/contact";
import { formatNumber } from "../../../utils/Global";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import PrintModal from "../../../components/UI/Modal/PrintModal";
import { useConfirmation } from "../../../hooks/use-confirmation";

type Props = {
  name: string;
  variableName: string;
};

const CustomerDepositPdf = ({ name, variableName }: Props) => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);
  const { t } = useTranslation();

  const [printType, setPrintType] = useState<"original" | "copy">("copy");

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const { data, isLoading, refetch } =
    useCustomerDepositFindUniqueQuery<CustomerDepositFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      }
    );

  const { mutateAsync } = useSalesOriginalPrintMutation<Error>(graphQLClient);

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.CustomerDepositFindUnique, //data
    "customer_deposit", // document type
    141, // name and description row width
    700, // max height without footer
    520, // max height with footer
    226.484, // left header minimum height
    226.484, // right header minimum height
    undefined, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    undefined // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const printTypeOption: ISelectOption[] = [
    {
      label: t(`print.original`),
      value: "original",
      disabled:
        data?.CustomerDepositFindUnique.is_original_print &&
        data?.CustomerDepositFindUnique.is_original_print[variableName],
    },
    {
      label: t(`print.copy`),
      value: "copy",
    },
  ];

  // const formatItemList = useSalesItemListFormatter(
  //   (data?.QuotationFindUnique.item_list as ISalesItemList[]) || []
  // );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.CustomerDepositFindUnique.unique_id,
    onAfterPrint: async () => {
      if (printType === "original") {
        await mutateAsync({
          input: {
            document_id: id ? parseInt(id) : 0,
            document_type: SalesReferenceDocumentType.CashSales,
            key: variableName,
          },
        });
        await refetch();
        setPrintType("copy");
      }
    },
  });

  const taxInvoice = data?.CustomerDepositFindUnique.tax_invoice_no;

  const highlightSection: IPdfInfo = {
    headers: [
      "เลขที่เอกสาร",
      ...(taxInvoice ? ["เลขที่ใบกำกับภาษี"] : []),
      "วันที่ออกเอกสาร",
      // "ใช้ได้ถึง",
    ],
    info: [
      data?.CustomerDepositFindUnique.unique_id || "-",
      ...(taxInvoice ? [taxInvoice] : []),
      data?.CustomerDepositFindUnique.issue_date
        ? formatDate(data?.CustomerDepositFindUnique.issue_date)
        : "-",
      // data?.CustomerDepositFindUnique.due_date
      //   ? formatDate(data?.CustomerDepositFindUnique.due_date)
      //   : "-",
    ],
  };

  const identityNoBranch = data?.CustomerDepositFindUnique.customer_details
    .identity_no
    ? // &&
      // data?.CustomerDepositFindUnique.branch
      // ? `${data?.CustomerDepositFindUnique.customer_details.identity_no} (${data?.CustomerDepositFindUnique.branch.unique_id})`
      `${data?.CustomerDepositFindUnique.customer_details.identity_no}`
    : "-";

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@โทรสาร",
      "อีเมล",
    ],
    info: [
      `${data?.CustomerDepositFindUnique.customer_unique_id} ${data?.CustomerDepositFindUnique.customer_details.name}`,
      renderAddressString(
        data?.CustomerDepositFindUnique.customer_details.billing_address
      ),
      identityNoBranch,
      `${data?.CustomerDepositFindUnique.customer_details.phone || "-"}@${
        data?.CustomerDepositFindUnique.customer_details.fax || "-"
      }`,
      data?.CustomerDepositFindUnique.customer_details.email || "-",
    ],
  };

  const salesPersonFirstName =
    (data?.CustomerDepositFindUnique.related_user_list &&
      data?.CustomerDepositFindUnique.related_user_list.length > 0 &&
      data.CustomerDepositFindUnique.related_user_list[0].first_name) ||
    "-";

  const salesPersonLastname =
    (data?.CustomerDepositFindUnique.related_user_list &&
      data?.CustomerDepositFindUnique.related_user_list.length > 0 &&
      data.CustomerDepositFindUnique.related_user_list[0].last_name) ||
    "";
  const salePerson = salesPersonFirstName + " " + salesPersonLastname;

  const contactPerson: IContactPerson =
    data?.CustomerDepositFindUnique.contact_person_list &&
    data?.CustomerDepositFindUnique.contact_person_list.length > 0 &&
    data?.CustomerDepositFindUnique.contact_person_list[0];

  const contactPersonName = contactPerson
    ? `${contactPerson.title_name}${contactPerson.first_name} ${contactPerson.last_name}`
    : "-";

  const contactPersonPhone = contactPerson
    ? contactPerson.contact_channel_list.find(
        (channel) => channel.contact_channel_type === "phone"
      )?.contact_channel_info || "-"
    : "-";
  // data.CustomerDepositFindUnique.need_tax_invoice

  const rightSection: IPdfInfo = {
    headers: ["พนักงานขาย", "หมายเลขอ้างอิง", "ผู้ติดต่อ", "เบอร์โทรศัพท์"],
    info: [
      salePerson || "-",
      data?.CustomerDepositFindUnique.reference_no || "-",
      contactPersonName,
      contactPersonPhone,
    ],
  };

  const payment =
    data?.CustomerDepositFindUnique.payment_list &&
    data?.CustomerDepositFindUnique.payment_list.length > 0
      ? data.CustomerDepositFindUnique.payment_list[0]
      : undefined;

  const paymentChannelName = payment?.payment_channel_unique_id
    ? payment?.payment_channel_unique_id + " - " + payment?.payment_channel_name
    : "-";

  const leftPaymentSection: IPdfInfo = {
    headers: [
      "การรับชำระ",
      "ชำระโดย",
      "ชื่อบัญชี",
      "จำนวนเงิน",
      "มูลค่าถูกหัก ณ ที่จ่าย",
    ],
    info: [
      payment?.payment_date ? formatDate(payment.payment_date) : "-",
      payment?.payment_channel_payment_name || "-",
      paymentChannelName,
      payment?.payment_amount
        ? formatNumber(payment.payment_amount) + " บาท"
        : "-",
      payment?.withholding_tax_amount
        ? formatNumber(payment.withholding_tax_amount) + " บาท"
        : "-",
    ],
  };

  const rightPaymentSection: IPdfInfo = {
    headers: ["วันที่เช็ค", "ธนาคารผู้ออกเช็ค", "เลขที่เช็ค", "หมายเหตุ"],
    info: [
      payment?.cheque_date ? formatDate(payment.cheque_date) : "-",
      payment?.cheque_provider || "-",
      payment?.cheque_no || "-",
      payment?.remark || "-",
    ],
  };

  const needTaxInvoice = data?.CustomerDepositFindUnique.need_tax_invoice;

  const needOriginal =
    needTaxInvoice &&
    !["draft"].includes(data?.CustomerDepositFindUnique.aggrid_status || "");

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(() => handlePrint());

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const allPages = paginatedItems.length;

  return (
    <>
      <Box maxWidth={1650}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box>
            <Typography variant="h5">{name}</Typography>
            <Typography>{`เลขที่เอกสาร ${data?.CustomerDepositFindUnique.unique_id}`}</Typography>
          </Box>

          <Box display={"flex"} gap={1}>
            {needOriginal && (
              <Box>
                <CustomizedSelect
                  sx={{ width: 120 }}
                  label={t("print.type")}
                  options={printTypeOption}
                  onChange={(e) => {
                    const value = e.target.value as "copy" | "original";
                    setPrintType(value);
                  }}
                  value={printType}
                />
              </Box>
            )}
            <CustomizedButton
              fullWidth
              title="พิมพ์เอกสาร"
              variant="outlined"
              onClick={
                printType === "original" ? openConfirmationHandler : handlePrint
              }
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box ref={componentRef} sx={{ m: "auto" }}>
            {paginatedItems.map((item, index) => (
              <SalesPdfLayout
                printType={needOriginal ? printType : undefined}
                highlightSection={highlightSection}
                leftSection={leftSection}
                rightSection={rightSection}
                leftPaymentSection={leftPaymentSection}
                rightPaymentSection={rightPaymentSection}
                name={`${name}`}
                multiplePage={paginatedItems.length > 1}
                page={index + 1}
                allPages={allPages}
                data={data?.CustomerDepositFindUnique}
                summary={index === paginatedItems.length - 1}
                salePerson={salePerson}
                totalAmount={
                  data?.CustomerDepositFindUnique.total_amount
                    ? data?.CustomerDepositFindUnique.total_amount
                    : 0
                }
                documentType="customer_deposit"
                hideAdditionalDiscount={true}
                showPaymentSection={true}
                //sPrinting={isPrinting}
              >
                {/* สร้าง table ใหม่ */}
                <DepositListPdfTable
                  itemList={(item as CustomerDepositItem[]) || []}
                  startNumber={startNumbers[index]}
                  isLastPage={index === paginatedItems.length - 1}
                  lastTable={
                    paginatedItems[paginatedItems.length - 1].length === 0
                      ? index === paginatedItems.length - 2
                      : index === paginatedItems.length - 1
                  }
                  totalAmount={
                    data?.CustomerDepositFindUnique.total_amount
                      ? data?.CustomerDepositFindUnique.total_amount
                      : 0
                  }
                  hasItems={item.length > 0}
                />
              </SalesPdfLayout>
            ))}
          </Box>
        </Box>
      </Box>
      <PrintModal
        open={confirmation}
        closeModalHandler={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default CustomerDepositPdf;
