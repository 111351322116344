import { Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { IGoodsAdjustment } from "../../../../types/Inventory/GoodsAdjustment";

type Props = {
  nestedIndex: number;
  index: number;
};

const GoodsAdjustmentSerialSumStock = ({ nestedIndex, index }: Props) => {
  const { control } = useFormContext<IGoodsAdjustment>();
  const watchTrace = useWatch({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const trace = watchTrace.at(index);

  return (
    <Typography fontSize={14}>
      {Number(trace?.qty) + (trace?.stock_qty || 0)}
    </Typography>
  );
};

export default GoodsAdjustmentSerialSumStock;
