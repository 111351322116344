import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "../../../../UI/AgGrid";
import { useCurrentStockColumnDefs } from "./useCurrentStockColumnDefs";
import { GridReadyEvent, IServerSideDatasource } from "ag-grid-community";
import { useFormContext } from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { IItem } from "../../../../../types/Inventory/item";
import { CURRENT_STOCK_BY_SERIAL_NUMBER_VIEW } from "../../../../../services/AgGrid/InventoryAggrid";
import { dateFilterModel } from "../../../../../utils/Formatter/AgGridFilter";
import { branchInfoFromSessionStorage } from "../../../../../utils/Global";

const CurrentStockBySerialNumber = () => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = useCurrentStockColumnDefs("serial_number");

  const { getValues } = useFormContext<IItem>();
  const itemSkuName = getValues("sku");
  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");
  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { lot_date, manufactured_date, expired_date } = filterModel;

      const formatFilter = {
        ...filterModel,
        lot_date: dateFilterModel(lot_date),
        manufactured_date: dateFilterModel(manufactured_date),
        expired_date: dateFilterModel(expired_date),
        sku_name: {
          filterType: "text",
          type: "equals",
          filter: itemSkuName,
        },
        branch_id:
          is_hq === "false"
            ? {
                type: "equals",
                filterType: "number",
                filter: parseInt(branch_id ?? "0"),
              }
            : undefined,
      };

      try {
        const { ItemSkuQtysByBarcodeView } = await graphQLClient.request(
          CURRENT_STOCK_BY_SERIAL_NUMBER_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: ItemSkuQtysByBarcodeView.results as any[],
          rowCount: ItemSkuQtysByBarcodeView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    params.api.onFilterChanged();
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onGridReady={onGridReady}
      disabledSidebar
    />
  );
};

export default CurrentStockBySerialNumber;
