import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const usePurchaseRequestOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [disabled]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "not_approved":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]); // ยกเลิก, ตัดลอก
        break;
      case "partially_ordered":
      case "fully_ordered":
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]]); //คัดลอก
        break;
      default:
        // setSelectedModifyOptions(defaultOptions);
        setSelectedModifyOptions([
          defaultOptions[0],
          defaultOptions[1],
          defaultOptions[2],
        ]);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};

export const usePurchaseRequestCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: t("purchase.order.index"),
        disabled: false,
      },
    ],
    [t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "accepted":
      case "approved":
      case "finished":
      case "partially_ordered":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
