import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, IGlobalSetting } from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GlobalSettingTable from "../../../components/Table/Setting/Global";
import { useModal } from "../../../hooks/use-modal";
import GlobalSettingModal from "../../../components/UI/Modal/GlobalSettingModal";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import {
  CustomerTypeFindUniqueQuery,
  CustomerTypesFindAllQuery,
  GeneralModelType,
  useCustomerTypeCreateMutation,
  useCustomerTypeDeleteMutation,
  useCustomerTypeFindUniqueQuery,
  useCustomerTypeUpdateMutation,
  useCustomerTypesFindAllQuery,
} from "../../../generated/general";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const CustomerTypeSetting = () => {
  const { t } = useTranslation();
  const [deletingId, setDeletingId] = useState<number>();
  let [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const setSearchParamsHandler = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const removeSearchParamsHandler = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal(
    undefined,
    () => removeSearchParamsHandler("id")
  );

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.contact.index"),
      to: "/setting/contact",
    },
    {
      name: t("setting.contact.customer_type"),
    },
  ];

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData, refetch } =
    useCustomerTypesFindAllQuery<CustomerTypesFindAllQuery>(
      graphQLClientGeneral
    );

  const { data, isLoading, isSuccess } =
    useCustomerTypeFindUniqueQuery<CustomerTypeFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: createCustomerType } =
    useCustomerTypeCreateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: updateCustomerType } =
    useCustomerTypeUpdateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: deleteCustomerType } =
    useCustomerTypeDeleteMutation<Error>(graphQLClientGeneral);

  const onAddHandler = async (data: IGlobalSetting) => {
    try {
      await createCustomerType({
        data,
      });
      enqueueSnackbar(`เพิ่ม${t("setting.contact.customer_type")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`เพิ่ม${t("setting.contact.customer_type")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onEditHandler = (id?: number) => {
    if (id) {
      setSearchParamsHandler("id", id.toString());
      openModalHandler();
    }
  };

  const onUpdateHandler = async (data: IGlobalSetting) => {
    try {
      const { id, ...otherData } = data;
      await updateCustomerType({
        uniqueInput: {
          id: id,
        },
        data: otherData,
      });
      enqueueSnackbar(`แก้ไข${t("setting.contact.customer_type")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`แก้ไข${t("setting.contact.customer_type")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onDeleteHandler = (id?: number) => {
    if (id) {
      setDeletingId(id);
      openConfirmationHandler();
    }
  };

  const deleteActionHandler = async () => {
    try {
      await deleteCustomerType({
        uniqueInput: {
          id: deletingId,
        },
      });
      setDeletingId(undefined);
      enqueueSnackbar(`ลบ${t("setting.contact.customer_type")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`ลบ${t("setting.contact.customer_type")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deleteActionHandler);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("setting.contact.customer_type")}
        </Typography>
        <CustomizedButton
          title={t("button.add") + t("setting.contact.customer_type")}
          variant="contained"
          onClick={openModalHandler}
        />
      </Box>
      <GlobalSettingTable
        data={rowData?.CustomerTypesFindAll || []}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
      />
      <GlobalSettingModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
        data={data?.CustomerTypeFindUnique}
        isLoading={isLoading}
        isSuccess={isSuccess}
        type={GeneralModelType.CustomerType}
      />
      <ConfirmationModal
        title={`ยันยืนการลบ${t("setting.contact.customer_type")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default CustomerTypeSetting;
