import { CheckboxProps, SxProps } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import CustomizedCheckbox from "../Custom/CustomizedCheckbox";

type Props = {
  sx?: SxProps;
  label?: string;
  error?: boolean;
  testId?: string;
  control: Control<any>;
  name: string;
  disabled?: boolean;
  onChange?: CheckboxProps["onChange"];
};

const ControlledCheckbox = ({
  control,
  name,
  label,
  disabled,
  onChange,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckbox
          {...field}
          label={label}
          isDisabled={disabled}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) {
              onChange(e, e.target.checked);
            }
          }}
        />
      )}
    />
  );
};

export default ControlledCheckbox;
