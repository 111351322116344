import { Dispatch, SetStateAction, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import { useStateContext } from "../../../contexts/auth-context";
import {
  ContactImportType,
  ValidateContactImportQuery,
  useImportCustomerMutation,
  useImportVendorMutation,
  useValidateContactImportQuery,
} from "../../../generated/contact";
import {
  IAddress,
  IContactChannel,
  IImporterError,
} from "../../../types/global";
import {
  formatFloat,
  formatString,
  formatStringToArray,
} from "../../../utils/Global";
import {
  businessTypeFormatter,
  companyTypeFormatter,
  countryFormatter,
  genderFormatter,
} from "../../../utils/Formatter/Global";
import dayjs from "dayjs";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
  findNoneBusinessType,
  findNoneBusinessTypeDetail,
  findNoneGender,
  findNoneSourceCountry,
  findNoneStatus,
  findNoneTitleName,
  mapNestedData,
} from "../../../utils/Importer";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

const customerColumns = [
  "unique_id",
  "img_url",
  "branch_unique_id",
  "business_type",
  "business_type_detail",
  "name",
  "source_country",
  "taxpayer_id",
  "source",
  "type",
  "group",
  "zone",
  "sales_channel",
  "title_name",
  "last_name",
  "nick_name",
  "gender",
  "phone",
  "email",
  "status",
  "remark_status",
  "inactive_date",
  "tag_list",
  "billing_address",
  "billing_address_sub_district",
  "billing_address_district",
  "billing_address_province",
  "billing_address_postal_code",
  "billing_address_country",
  "delivery_address",
  "delivery_address_contact_name",
  "delivery_address_sub_district",
  "delivery_address_district",
  "delivery_address_province",
  "delivery_address_postal_code",
  "delivery_address_country",
  "delivery_address_contact_phone",
  "delivery_address_fax",
  "credit_limit",
  "credit_day",
  "sales_person_unique_id",
  "member_unique_id",
  "member_opened_date",
  "member_expired_date",
  "remark",
];

const vendorColumns = [
  "unique_id",
  "img_url",
  "branch_unique_id",
  "business_type",
  "business_type_detail",
  "name",
  "source_country",
  "taxpayer_id",
  "source",
  "type",
  "group",
  "zone",
  "title_name",
  "last_name",
  "nick_name",
  "gender",
  "phone",
  "email",
  "status",
  "remark_status",
  "inactive_date",
  "tag_list",
  "billing_address",
  "billing_address_sub_district",
  "billing_address_district",
  "billing_address_province",
  "billing_address_postal_code",
  "billing_address_country",
  "delivery_address",
  "delivery_address_contact_name",
  "delivery_address_sub_district",
  "delivery_address_district",
  "delivery_address_province",
  "delivery_address_postal_code",
  "delivery_address_country",
  "delivery_address_contact_phone",
  "delivery_address_fax",
  "credit_limit",
  "credit_day",
  "remark",
];

const count_limit = 50000;

const useNormalContactImporter = (
  type: ContactImportType,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();
  const { t } = useTranslation();

  const requiredKeys = [
    {
      label: `รหัส${t(`contact.contact_type.${type}`)}`,
      value: "unique_id",
    },
    {
      label: "ประเภทกิจการ",
      value: "business_type",
    },
    {
      label: "ชื่อประเภทกิจการ",
      value: "business_type_detail",
    },
    {
      label: "ชื่อกิจการ/ชื่อจริง",
      value: "name",
    },
    {
      label: "สถานที่จดทะเบียน",
      value: "source_country",
    },
    {
      label: "เลขประจำตัวผู้เสียภาษี",
      value: "taxpayer_id",
    },
    {
      label: "โซน",
      value: "zone",
    },
    {
      label: "สถานะ",
      value: "status",
    },
  ];

  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);
  const [contactGroupList, setContactGroupList] = useState<string[]>([]);
  const [contactTypeList, setContactTypeList] = useState<string[]>([]);
  const [contactSourceList, setContactSourceList] = useState<string[]>([]);
  const [salesChannelList, setSalesChannelList] = useState<string[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [zoneList, setZoneList] = useState<string[]>([]);
  const [salesPersonUniqueIdList, setSalesPersonUniqueIdList] = useState<
    string[]
  >([]);

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const { refetch: validateContact, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_type: type,
          import_mode: getValues("type"),
          contact_group_list: contactGroupList,
          contact_unique_id_list: contactUniqueIdList,
          contact_type_list: contactTypeList,
          sales_channel_list: salesChannelList,
          source_list: contactSourceList,
          tag_list: tagList,
          zone_list: zoneList,
          branch_unique_id_list: [],
          sales_person_unique_id_list: salesPersonUniqueIdList,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportCustomerMutation<Error>(graphqlClient);

  const {
    mutateAsync: createOrUpdateVendor,
    isLoading: isCreatingOrUpdatingVendor,
  } = useImportVendorMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols =
        type === ContactImportType.Customer
          ? customerColumns.filter((col) => !dataCols.includes(col))
          : vendorColumns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const formattedDataSnapshot = [...data];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      let formattedData: any[] = [];

      const uniqueIdList: string[] = [];
      const groupList: string[] = [];
      const sourceList: string[] = [];
      const typeList: string[] = [];
      const salesChannelList: string[] = [];
      const tagList: string[] = [];
      const zoneList: string[] = [];
      const salesPersonUniqueIdList: string[] = [];

      const businessTypeList: string[] = [];
      const businessTypeDetailList: string[] = [];
      const titleNameList: string[] = [];
      const genderList: string[] = [];
      const statusList: string[] = [];
      const sourceCountryList: string[] = [];

      data.forEach((contact: any) => {
        const {
          unique_id,
          group,
          source,
          type,
          sales_channel,
          tag_list,
          zone,
          sales_person_unique_id,
          business_type,
          business_type_detail,
          gender,
          title_name,
          status,
          source_country,
        } = contact;

        //Validate on API
        if (unique_id) {
          uniqueIdList.push(unique_id);
        }
        if (group) {
          groupList.push(group);
        }
        if (source) {
          sourceList.push(source);
        }
        if (type) {
          typeList.push(type);
        }
        if (sales_channel) {
          salesChannelList.push(sales_channel);
        }
        if (tag_list) {
          tagList.push(tag_list.split(",").map((tag: string) => tag.trim()));
        }
        if (zone) {
          zoneList.push(zone);
        }
        if (sales_person_unique_id) {
          salesPersonUniqueIdList.push(sales_person_unique_id);
        }

        //Validate own file
        if (business_type) {
          businessTypeList.push(business_type);
        }
        if (business_type_detail) {
          businessTypeDetailList.push(business_type_detail);
        }
        if (title_name) {
          titleNameList.push(title_name);
        }
        if (gender) {
          genderList.push(gender);
        }
        if (status) {
          statusList.push(status);
        }
        if (source_country) {
          sourceCountryList.push(source_country);
        }

        const contact_channel_list: IContactChannel[] = [
          {
            contact_channel_type: "phone",
            contact_channel_info: formatString(contact.phone),
          },
          {
            contact_channel_type: "email",
            contact_channel_info: formatString(contact.email),
          },
        ];

        const address_list: IAddress[] = [
          {
            type: "ที่อยู่จดทะเบียน",
            is_default: true,
            contact_name: "",
            contact_phone: "",
            address_name: formatString(contact.billing_address),
            sub_district: formatString(contact.billing_address_sub_district),
            district: formatString(contact.billing_address_district),
            province: formatString(contact.billing_address_province),
            postal_code: formatString(contact.billing_address_postal_code),
            country: countryFormatter(
              formatString(contact.billing_address_country),
              false
            ),
            fax: "",
          },
          {
            type: "ที่อยู่จัดส่ง",
            is_default: false,
            is_same_as_default_address: false,
            contact_name: formatString(contact.delivery_address_contact_name),
            contact_phone: formatString(contact.delivery_address_contact_phone),
            address_name: formatString(contact.delivery_address),
            sub_district: formatString(contact.delivery_address_sub_district),
            district: formatString(contact.delivery_address_district),
            province: formatString(contact.delivery_address_province),
            postal_code: formatString(contact.delivery_address_postal_code),
            country: countryFormatter(
              formatString(contact.delivery_address_country),
              false
            ),
            fax: formatString(contact.delivery_address_fax),
          },
        ];

        const member_detail = {
          member_unique_id: formatString(contact.member_unique_id),
          opened_date: contact.member_opened_date
            ? dayjs(contact.member_opened_date)
            : undefined,
          expired_date: contact.member_expired_date
            ? dayjs(contact.member_expired_date)
            : undefined,
        };
        const formatBusinessType = businessTypeFormatter(
          formatString(contact.business_type),
          false
        );

        const formatBusinessTypeDetail = companyTypeFormatter(
          formatString(contact.business_type_detail),
          false
        );

        const formatGender = genderFormatter(
          formatString(contact.gender),
          false
        );

        const formatSourceCountry = countryFormatter(
          formatString(contact.source_country),
          false
        );

        const mappedData = {
          unique_id: formatString(contact.unique_id),
          img_url: formatStringToArray(contact.img_url),
          branch_unique_id: formatString(contact.branch_unique_id),
          business_type: formatBusinessType,
          business_type_detail: formatBusinessTypeDetail,
          name: formatString(contact.name),
          taxpayer_id: formatString(contact.taxpayer_id),
          source: formatString(contact.source),
          type: formatString(contact.type),
          group: formatString(contact.group),
          zone: formatString(contact.zone),
          sales_channel: formatString(contact.sales_channel),
          title_name: formatString(contact.title_name),
          last_name: formatString(contact.last_name),
          nick_name: formatString(contact.nick_name),
          gender: formatGender,
          contact_channel_list,
          status: contact.status === "ใช้งาน" ? 1 : 0,
          remark_status: formatString(contact.remark_status),
          inactive_date: contact.inactive_date
            ? dayjs(contact.inactive_date)
            : undefined,
          tag_list: formatStringToArray(contact.tag_list),
          address_list,
          credit_limit: contact.credit_limit
            ? formatFloat(contact.credit_limit)
            : undefined,
          credit_day: contact.credit_day
            ? parseInt(contact.credit_day)
            : undefined,
          member_detail,
          remark: formatString(contact.remark),
          source_country: formatSourceCountry,
        };

        formattedData.push(mappedData);
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const noneBusinessType = findNoneBusinessType(businessTypeList);
      const noneBusinessTypeDetail = findNoneBusinessTypeDetail(
        businessTypeDetailList
      );
      const noneTitleName = findNoneTitleName(titleNameList);
      const noneGender = findNoneGender(genderList);
      const noneStatus = findNoneStatus(statusList);
      const noneSourceCountry = findNoneSourceCountry(sourceCountryList);

      const allError = [
        duplicateUniqueId,
        noneBusinessType,
        noneBusinessTypeDetail,
        noneTitleName,
        noneGender,
        noneStatus,
        noneSourceCountry,
        requiredFieldErrors,
      ];

      addImporterErrorHandler(
        errorData,
        `รหัส${t(`contact.contact_type.${type}`)}ในไฟล์ซ้ำ`,
        `รหัส${t(`contact.contact_type.${type}`)}`,
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "ประเภทกิจการไม่ถูกต้อง",
        "ประเภทกิจการ",
        noneBusinessType
      );
      addImporterErrorHandler(
        errorData,
        "ชื่อประเภทกิจการไม่ถูกต้อง",
        "ชื่อประเภทกิจการ",
        noneBusinessTypeDetail
      );
      addImporterErrorHandler(
        errorData,
        "คำนำหน้าไม่ถูกต้อง",
        "คำนำหน้า",
        noneTitleName
      );
      addImporterErrorHandler(errorData, "เพศไม่ถูกต้อง", "เพศ", noneGender);
      addImporterErrorHandler(
        errorData,
        "สถานะไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "สถานที่จดทะเบียนไม่ถูกต้อง",
        "สถานที่จดทะเบียน",
        noneSourceCountry
      );

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setContactUniqueIdList([...new Set(uniqueIdList)]);
      setContactGroupList([...new Set(groupList)]);
      setContactSourceList([...new Set(sourceList)]);
      setContactTypeList([...new Set(typeList)]);
      setSalesChannelList([...new Set(salesChannelList)]);
      setTagList([...new Set(...tagList)]);
      setZoneList([...new Set(zoneList)]);
      setSalesPersonUniqueIdList([...new Set(salesPersonUniqueIdList)]);

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (data.length === 0) {
        message = "กรุณาระบุข้อมูล";
      }
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateContact();
      const existingUniqueId =
        data?.ValidateContactImport.existing_contact_unique_id_list || [];
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];
      const missingSource =
        data?.ValidateContactImport.missing_source_list || [];
      const missingType =
        data?.ValidateContactImport.missing_contact_type_list || [];
      const missingGroup =
        data?.ValidateContactImport.missing_contact_group_list || [];
      const missingZone = data?.ValidateContactImport.missing_zone_list || [];
      const missingSalesChannel =
        data?.ValidateContactImport.missing_sales_channel_list || [];
      const missingTagList = data?.ValidateContactImport.missing_tag_list || [];

      const arrays = [
        existingUniqueId,
        missingUniqueId,
        missingSource,
        missingType,
        missingGroup,
        missingZone,
        missingSalesChannel,
        missingTagList,
      ];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        `รหัส${t(`contact.contact_type.${type}`)}ในระบบซ้ำ`,
        `รหัส${t(`contact.contact_type.${type}`)}`,
        existingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `รหัส${t(`contact.contact_type.${type}`)}`,
        missingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        `ไม่มีการตั้งค่าในระบบ`,
        `แหล่งที่มา${t(`contact.contact_type.${type}`)}`,
        missingSource
      );
      addImporterErrorHandler(
        errorData,
        `ไม่มีการตั้งค่าในระบบ`,
        `ประเภทของ${t(`contact.contact_type.${type}`)}`,
        missingType
      );
      addImporterErrorHandler(
        errorData,
        `ไม่มีการตั้งค่าในระบบ`,
        `กลุ่ม${t(`contact.contact_type.${type}`)}`,
        missingGroup
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "โซน",
        missingZone
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "ช่องทางการขาย",
        missingSalesChannel
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "การจัดกลุ่ม",
        missingTagList
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          if (type === ContactImportType.Customer) {
            await createOrUpdate({
              importInput: {
                import_mode: getValues("type"),
                data: rowData,
                priority: 1,
                user_unique_id: authUser?.unique_id || "",
              },
            });
          } else {
            await createOrUpdateVendor({
              importInput: {
                import_mode: getValues("type"),
                data: rowData,
                priority: 1,
                user_unique_id: authUser?.unique_id || "",
              },
            });
          }
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading:
      isValidating || isCreatingOrUpdating || isCreatingOrUpdatingVendor,
    validateHandler,
    importHandler,
  };
};

export default useNormalContactImporter;
