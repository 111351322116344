import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import ControlledSSCreatable from "../../../Controller/ControlledSSCreatable";
import { useFormContext, useWatch } from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";
import RadioBox from "../../../UI/RadioBox";
import { IOptionsCreatable } from "../../../../types/global";

const isEffectStockOptions: IOptionsCreatable[] = [
  {
    label: "สินค้าเสียหาย",
    value: "สินค้าเสียหาย",
  },
];

const ReturnDetail = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [disabled] = useDisable();
  const watchIsEffectStock = useWatch({ control, name: "is_effect_stock" });
  return (
    <CustomizedBox>
      <Typography fontWeight={600} color={"primary.main"} mb={2}>
        {t("sales.return.return_detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledSSCreatable
            label={t(`sales.return.reason_to_return`)}
            control={control}
            documentType={"sales_return"}
            name="reason_to_return"
            setValue={setValue}
            defaultOptions={isEffectStockOptions}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} color={"primary.main"} mt={2} mb={1}>
        {t("sales.return.is_effect_stock")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("is_effect_stock", true);
            }}
            label={t("sales.return.effect")}
            checked={watchIsEffectStock}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("is_effect_stock", false);
            }}
            label={t("sales.return.not_effect")}
            checked={!watchIsEffectStock}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ReturnDetail;
