import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { ItemType } from "../../../../generated/inventory";
import {
  TagDocumentType,
  TagsQuery,
  useTagsQuery,
} from "../../../../generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

export const useItemColumnDefs = (isCheckbox?: boolean) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useTagsQuery<TagsQuery>(
    graphQLClient,
    {
      findManyInput: {
        document_type: TagDocumentType.Item,
      },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    setColumnDefs([
      {
        field: "img_url",
        headerName: t("inventory.items.picture"),
        filter: "agTextColumnFilter",
        checkboxSelection: isCheckbox,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value && params.value.length) {
            return (
              <CustomizedAvatar
                avatars={[
                  {
                    img_url: params.value[0],
                    unique_id: params.data.sku_name,
                    first_name: "",
                    last_name: "",
                  },
                ]}
                noTooltip
              />
            );
          } else return <></>;
        },
      },
      {
        field: "item_unique_id",
        headerName: t("inventory.items.seller_unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "sku_name",
        headerName: t("inventory.items.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "name",
        headerName: t("inventory.items.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "model",
        headerName: t("inventory.items.model"),
        filter: "agTextColumnFilter",
      },
      {
        field: "description",
        headerName: t("inventory.items.description"),
        filter: "agTextColumnFilter",
      },
      {
        field: "tag_list",
        headerName: t("inventory.items.tag_list"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            // fetch values from server
            const { data: tagListResult } = await refetch();
            const values = tagListResult?.Tags;
            const formatValues = values?.map((tag) => tag.name);
            params.success(formatValues);
          },
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value)
            return params.value?.map((tag: string) => (
              <Box key={tag} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
                <CustomizedChips value={`${tag}`} />
              </Box>
            ));
        },
      },
      {
        field: "category_group",
        headerName: t("inventory.items.category_group"),
        filter: "agTextColumnFilter",
      },
      {
        field: "main_category",
        headerName: t("inventory.items.main_category"),
        filter: "agTextColumnFilter",
      },
      {
        field: "sub_category",
        headerName: t("inventory.items.sub_category"),
        filter: "agTextColumnFilter",
      },
      {
        field: "brand",
        headerName: t("inventory.items.brand"),
        filter: "agTextColumnFilter",
      },
      {
        field: "segment",
        headerName: t("inventory.items.segment"),
        filter: "agTextColumnFilter",
      },
      {
        field: "series",
        headerName: t("inventory.items.series"),
        filter: "agTextColumnFilter",
      },
      {
        field: "stock_qty",
        headerName: t("inventory.quantities.stock_qty"),
        filter: "agTextColumnFilter",
        sortable: false,
        floatingFilter: false,
      },
      {
        field: "purchase_ordered_qty",
        headerName: t("inventory.quantities.purchase_ordered_qty"),
        filter: "agTextColumnFilter",
        sortable: false,
        floatingFilter: false,
      },
      {
        field: "sale_committed_qty",
        headerName: t("inventory.quantities.sale_committed_qty"),
        filter: "agTextColumnFilter",
        sortable: false,
        floatingFilter: false,
      },
      {
        field: "transit_qty",
        headerName: t("inventory.quantities.in_transit_qty"),
        filter: "agTextColumnFilter",
        sortable: false,
        floatingFilter: false,
      },
      {
        field: "available_qty",
        headerName: t("inventory.quantities.available_qty"),
        filter: "agTextColumnFilter",
        sortable: false,
        floatingFilter: false,
      },
      {
        field: "status",
        headerName: t("inventory.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [1, 0],
          valueFormatter: ({ value }: { value: number }) =>
            value ? "ใช้งาน" : "หยุดใช้งาน",
        },
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive"} />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      {
        field: "tracability",
        headerName: t("inventory.sentence.tracability.index"),
        filter: "agTextColumnFilter",
        hide: true,
      },
      {
        field: "type",
        headerName: t("inventory.items.type"),
        filter: "agSetColumnFilter",
        hide: true,
        filterParams: {
          values: [
            ItemType.Normal,
            ItemType.Variant,
            ItemType.Bundle,
            ItemType.Service,
          ],
          valueFormatter: (params: ValueFormatterParams) => {
            switch (params.value) {
              case ItemType.Normal:
                return "สินค้าธรรมดา";
              case ItemType.Variant:
                return "สินค้ามีตัวเลือก";
              case ItemType.Bundle:
                return "สินค้าจัดเซ็ต";
              case ItemType.Service:
                return "บริการ";
              default:
                break;
            }
          },
        },
      },
    ]);
  }, [isCheckbox, refetch, t]);

  return columnDefs;
};
