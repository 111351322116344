import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import CheckboxAggridModal from "./CheckboxAggridModal";
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useSalesOrderColumnDefs } from "../../Table/Sales/Order/columnDefs";
import { PURCHASE_REQUEST_AGGRID } from "../../../services/AgGrid/PurchaseAgGrid";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { usePurchaseRequestColumnDefs } from "../../Table/Purchase/Request/columnDefs";

type CheckedItemListModalProps = {
  showSelectItem: boolean;
  closeItemTable: () => void;
  finishItemsSelect: (data: any) => void;
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  idsSnapshot: string[];
  setIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection: "single" | "multiple";
  statusList?: string[];
};

const CheckedPurchaseRequestModal = ({
  showSelectItem,
  closeItemTable,
  finishItemsSelect,
  selectedIds,
  setSelectedIds,
  idsSnapshot,
  setIdsSnapshot,
  rowSelection,
  statusList = ["approved", "finished", "partially_ordered"],
}: CheckedItemListModalProps) => {
  const { t } = useTranslation();
  const selectGridRef = useRef();

  const columnDefs: ColDef[] = usePurchaseRequestColumnDefs() ?? [];

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        issue_date,
        due_date,
        within_date,
        created_by_object,
        related_user_object_list,
        tag_list,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        issue_date: dateFilterModel(issue_date),
        due_date: dateFilterModel(due_date),
        within_date: dateFilterModel(within_date),
        created_by: created_by_object,
        tag_list: tag_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: tag_list.values,
            }
          : undefined,
        related_user_list: related_user_object_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: related_user_object_list.values,
            }
          : undefined,
        status: {
          values: statusList,
          filterType: "set",
        },
      };
      try {
        const { PurchaseRequestViewsAggrid } =
          await graphQLClientWithHeaderSales.request(PURCHASE_REQUEST_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: PurchaseRequestViewsAggrid.results as any[],
          rowCount: PurchaseRequestViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={t("import") + t("purchase.request.index")}
      btnTitle={t("button.add")}
      gridRef={selectGridRef}
      height={665}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      rowSelection={rowSelection}
      onFinishEditing={finishItemsSelect}
      modalIsOpen={showSelectItem}
      getRowId={getRowId}
      closeModal={closeItemTable}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      idsSnapshot={idsSnapshot}
      setIdsSnapshot={setIdsSnapshot}
      rowMultiSelectWithClick={true}
      keyName="unique_id"
    />
  );
};

export default CheckedPurchaseRequestModal;
