import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { ITEM_VIEW } from "../../../../services/AgGrid/InventoryAggrid";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

import AgGrid from "../../../UI/AgGrid";
import { ItemType } from "../../../../generated/inventory";
import { useItemColumnDefs } from "./useItemColumnDefs";

type Props = {
  gridRef: any;
};

const ItemTable = ({ gridRef }: Props) => {
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const columnDefs = useItemColumnDefs();

  const navigate = useNavigate();

  const GraphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, ...otherFilter } = filterModel;

      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
      };

      const formatSorting = [
        ...sortModel,
        { sort: "desc", colId: "created_date" },
      ];

      try {
        const { ItemsView } = await GraphQLClient.request(ITEM_VIEW, {
          aggridInput: {
            startRow,
            endRow,
            filterModel: formatFilter,
            sortModel: formatSorting,
          },
        });
        params.success({
          rowData: ItemsView.results as any[],
          rowCount: ItemsView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceType = params.api.getFilterInstance("type");
      const instanceStatus = params.api.getFilterInstance("status");
      instanceType?.setModel({});
      instanceStatus?.setModel({ values: ["1"] });
      switch (isFilter) {
        case "normal":
          instanceType?.setModel({
            values: [ItemType.Normal],
          });
          break;
        case "variant":
          instanceType?.setModel({
            values: [ItemType.Variant],
          });
          break;
        case "bundle":
          instanceType?.setModel({ values: [ItemType.Bundle] });
          break;
        case "service":
          instanceType?.setModel({ values: [ItemType.Service] });
          break;
        case "in_active":
          instanceStatus?.setModel({ values: ["0"] });
          break;
        default:
          instanceType?.setModel({});
          instanceStatus?.setModel({ values: ["1"] });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { sku_name } = params.data;
    navigate(
      `/inventory/items/${encodeURIComponent(sku_name)}?tab=item&subtab=general`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      disabledSidebar
      onGridReady={onGridReady}
    />
  );
};

export default ItemTable;
