import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider } from "@mui/material";

import { formatDate } from "../../../../utils/Date";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GoodsReceiveQuery,
  useGoodsReceiveQuery,
} from "../../../../generated/inventory";

import PDFLayout from "../../../../components/UI/Pdf/PDFLayout";
import PDFInfo from "../../../../components/UI/Pdf/PDFInfo";
import GoodsReceivePdfTable from "../../../../components/Table/Pdf/GoodsReceive/GoodsReceivePdfTable";
import { IGoodsReceive } from "../../../../types/Inventory/GoodsReceive";
import {
  goodsReceiveQueryFormatter,
  goodsReceiveTypeValueFormatter,
} from "../../../../utils/Formatter/Inventory/GoodsReceive";

const GoodsReceiptPdf = () => {
  const { id } = useParams();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading } = useGoodsReceiveQuery<GoodsReceiveQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const leftHeader = [
    "เลขที่เอกสาร",
    "ประเภท",
    "เอกสารอ้างอิง",
    "สาขา",
    "คลัง",
  ];

  const leftInfo = [
    data?.GoodsReceive?.unique_id || "-",
    goodsReceiveTypeValueFormatter(data?.GoodsReceive?.type ?? "") || "-",
    data?.GoodsReceive?.reference_unique_id_list.join(",") || "-",
    data?.GoodsReceive?.branch.name || "-",
    data?.GoodsReceive?.warehouse?.name || "-",
  ];

  const rightHeader = ["วันที่สร้าง", "วันที่นำเข้า", "หมายเหตุ"];

  const rightInfo = [
    data?.GoodsReceive?.created_date
      ? formatDate(data?.GoodsReceive?.created_date)
      : "-",
    data?.GoodsReceive?.posted_date
      ? formatDate(data?.GoodsReceive?.posted_date)
      : "-",
    data?.GoodsReceive?.remark ?? "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบนำเข้า / Goods Receipt"
      data={data?.GoodsReceive}
      horizontal
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <GoodsReceivePdfTable
        itemList={
          data?.GoodsReceive
            ? goodsReceiveQueryFormatter(data.GoodsReceive as IGoodsReceive)
                .trace_entry_list
            : []
        }
      />
    </PDFLayout>
  );
};

export default GoodsReceiptPdf;
