import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const useQuotationOption = (status?: string | null) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "not_approved":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]); // ยกเลิก, ตัดลอก
        // setSelectedModifyOptions([defaultOptions[2]]); // ยกเลิก, ตัดลอก
        break;
      case "accepted":
      case "finished":
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]]); //คัดลอก
        // setSelectedModifyOptions([]); //คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        // setSelectedModifyOptions([defaultOptions[0], defaultOptions[2]]);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};

export const useQuotationCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: t("sales.order.index"),
        disabled: false,
      },
    ],
    [t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "accepted":
      case "finished":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
