import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Date";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

export const usePriceListColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("inventory.price_list.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "name",
        headerName: t("inventory.price_list.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "sales_channel_name_th",
        headerName: t("inventory.price_list.sales_channel"),
        filter: "agTextColumnFilter",
      },

      {
        field: "type",
        headerName: t("inventory.price_list.type"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["by_item", "all"],
          valueFormatter: ({ value }: { value: string }) => {
            if (value === "by_item") {
              return "กำหนดจากสินค้ารายตัว";
            } else {
              return "กำหนดจากสินค้าทั้งหมด";
            }
          },
        },
        valueFormatter: ({ value }: { value: string }) => {
          if (value === "by_item") {
            return "กำหนดจากสินค้ารายตัว";
          } else {
            return "กำหนดจากสินค้าทั้งหมด";
          }
        },
      },
      {
        field: "is_increase",
        headerName: t("inventory.price_list.is_increase"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [1, 0],
          valueFormatter: ({ value }: { value: number }) =>
            value ? "เพิ่ม" : "ลด",
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.data.type === "by_item") {
            return "-";
          } else {
            if (params.value) {
              return "เพิ่ม";
            } else {
              return "ลด";
            }
          }
        },
      },

      {
        field: "start_date",
        headerName: t("inventory.price_list.start_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "end_date",
        headerName: t("inventory.price_list.end_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "is_active",
        headerName: t("inventory.price_list.is_active"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [1, 0],
          valueFormatter: ({ value }: { value: number }) =>
            value ? "ใช้งาน" : "หยุดใช้งาน",
        },
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive"} />;
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    ]);
  }, [t]);

  return columnDefs;
};
