import { useSnackbar } from "notistack";

export const useItemErrors = (
  errors: any,
  isValid: boolean,
  isSubmitted: boolean
) => {
  const { enqueueSnackbar } = useSnackbar();
  if (!isValid && isSubmitted)
    if (errors) {
      if (
        errors.bundle_item_detail_list &&
        errors.bundle_item_detail_list.message
      ) {
        enqueueSnackbar(errors.bundle_item_detail_list.message, {
          variant: "error",
        });
      }
    }
};
