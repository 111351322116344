import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDisable } from "../../../hooks/use-disable";
import { Box, CircularProgress, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { IGoodsIssue } from "../../../types/Inventory/GoodsIssue";
import {
  goodsIssueSchema,
  goodsIssueValidation,
} from "../../../components/Form/Inventory/GoodsIssue/schema";
import GoodsIssueInfo from "../../../components/Form/Inventory/GoodsIssue/Info";
import GoodsIssueItemList from "../../../components/Table/Inventory/GoodsIssue/ItemList";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  goodsIssueCreatePayloadFormatter,
  goodsIssueQueryFormatter,
  goodsIssueUpdatePayloadFormatter,
} from "../../../utils/Formatter/Inventory/GoodsIssue";
import {
  GoodsIssueCreateInput,
  GoodsIssueUpdateInput,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  useGoodsIssueCreateMutation,
  useGoodsIssueQuery,
  useGoodsIssueUpdateMutation,
  useInventoryDocumentCancelMutation,
  useItemSkuQtysQuery,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useEffect, useState } from "react";
import { useInventoryError } from "../../../hooks/use-inventory-error";
import GoodsIssueHeaderBreadcrumbs from "../../../components/Form/Inventory/GoodsIssue/HeaderBreadcrumbs";
import useInventoryControl from "../../../hooks/Inventory/use-inventory-control";
import { IApprovalTemplate } from "../../../types/global";
import { useStateContext } from "../../../contexts/auth-context";
import GoodsIssueHeader from "../../../components/Form/Inventory/GoodsIssue/Header";
import { useActivityLog } from "../../../hooks/use-activity-log";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import dayjs from "dayjs";

const GoodsIssueContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { authUser },
  } = useStateContext();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDisabled] = useDisable();
  const navigate = useNavigate();
  const [barcodeList, setBarcodeList] = useState<any[]>([]);
  const [approverList, setApproverList] = useState<IApprovalTemplate[]>([]);
  const [approvalStep, setApprovalStep] = useState<number | null | undefined>(
    0
  );
  const [isHaveApproval, setIsHaveApproval] = useState<boolean>(false);

  const { createActivityLog } = useActivityLog();
  // const [isEdit, setIsEdit] = useState<boolean>(false);

  const methods = useForm<IGoodsIssue>({
    defaultValues: goodsIssueSchema,
    resolver: yupResolver<any>(goodsIssueValidation),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { mutateAsync: createGoodsIssue, isLoading: isCreating } =
    useGoodsIssueCreateMutation<Error>(graphQLClient);

  const { mutateAsync: updateGoodsIssue, isLoading: isUpdating } =
    useGoodsIssueUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancelGoodsIssue, isLoading: isCanceling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const { data, refetch, isSuccess } = useGoodsIssueQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const {
    data: allSkuQtys,
    isLoading: isAllSkuLoading,
    isSuccess: isSkuSuccess,
  } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClient,
    {
      findManyInput: {
        where: {
          barcode: {
            in: barcodeList,
          },
        },
      },
    },
    {
      enabled: barcodeList.length > 0,
    }
  );

  const { approvalTemplates } = useInventoryControl(
    undefined,
    undefined,
    "goods_issue"
  );

  useEffect(() => {
    if (id && isSuccess) {
      const { GoodsIssue } = data;
      const traceEntryList = GoodsIssue?.trace_entry_list;
      const allBarcode = traceEntryList?.map((trace) => trace?.barcode) || [];
      setBarcodeList(allBarcode);

      const currentStep = GoodsIssue?.approval_step;
      setApprovalStep(currentStep);

      if (GoodsIssue?.aggrid_status === "wait_approve") {
        setApproverList(approvalTemplates as IApprovalTemplate[]);
      } else {
        const allApproval = GoodsIssue?.approver_list || [];
        setApproverList(allApproval);
      }

      const currentReviewerList =
        approvalTemplates.find(
          (template) => template?.step_number === currentStep
        )?.approver_list || [];

      const isHaveApproval = currentReviewerList.some(
        (reviewer) => reviewer.id === authUser?.id
      );

      setIsHaveApproval(isHaveApproval);

      const goodsIssueType = GoodsIssue as IGoodsIssue;

      const formatGoodsIssue = goodsIssueQueryFormatter(
        goodsIssueType,
        allSkuQtys
      );

      reset(formatGoodsIssue);
    }
  }, [
    data,
    id,
    isSuccess,
    reset,
    isSkuSuccess,
    allSkuQtys,
    approvalTemplates,
    authUser?.id,
  ]);

  const currentStatus = data?.GoodsIssue?.aggrid_status;

  useEffect(() => {
    if (
      id &&
      (data?.GoodsIssue?.aggrid_status === "finished" ||
        data?.GoodsIssue?.aggrid_status === "cancelled")
    ) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [data?.GoodsIssue?.aggrid_status, id, setDisabled]);

  const draftHandler = async (data: IGoodsIssue) => {
    try {
      if (!id) {
        const payload = goodsIssueCreatePayloadFormatter(
          data,
          "draft",
          approvalTemplates.length > 0 ? 1 : 0
        ) as GoodsIssueCreateInput;
        const { GoodsIssueCreate } = await createGoodsIssue({
          createInput: payload,
        });

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
        navigate(`/inventory/goods-issue/${GoodsIssueCreate.id}`);
      } else {
        const payload = goodsIssueUpdatePayloadFormatter(
          data,
          "draft",
          approvalTemplates.length > 0 ? 1 : 0
        ) as GoodsIssueUpdateInput;
        const { GoodsIssueUpdate } = await updateGoodsIssue({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: payload,
        });

        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueUpdate?.id,
          activity_detail: {},
        });
        await refetch();
      }

      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const waitApproveHandler = async (data: IGoodsIssue) => {
    try {
      if (!id) {
        const payload = goodsIssueCreatePayloadFormatter(
          data,
          "wait_approve",
          approvalTemplates.length > 0 ? 1 : 0
        ) as GoodsIssueCreateInput;
        const { GoodsIssueCreate } = await createGoodsIssue({
          createInput: payload,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_approve",
          },
        });
        navigate(`/inventory/goods-issue/${GoodsIssueCreate.id}`);
      } else {
        const payload = goodsIssueUpdatePayloadFormatter(
          data,
          "wait_approve",
          approvalTemplates.length > 0 ? 1 : 0
        ) as GoodsIssueUpdateInput;
        const { GoodsIssueUpdate } = await updateGoodsIssue({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: payload,
        });
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "wait_approve",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
        await refetch();
      }
      enqueueSnackbar(`ส่งอนุมัติสำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`ส่งอนุมัติไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const saveHandler = async (data: IGoodsIssue) => {
    try {
      const payload = goodsIssueUpdatePayloadFormatter(
        data,
        data.main_status || "",
        approvalStep || 0,
        data.approver_list,
        data.aggrid_status === "not_approved"
      ) as GoodsIssueUpdateInput;
      const { GoodsIssueUpdate } = await updateGoodsIssue({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.GoodsIssue,
        reference_id: GoodsIssueUpdate?.id,
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const approveHandler = async (data: IGoodsIssue) => {
    try {
      let changingStatus = "wait_approve";
      let currentApprovalList: IApprovalTemplate[] = [];
      if (approvalStep && approvalStep > approvalTemplates.length - 1) {
        changingStatus = "wait_issue";
        currentApprovalList = approvalTemplates as IApprovalTemplate[];
      }

      let currentApprovalStep = (approvalStep || 0) + 1;

      if (data.aggrid_status === "not_approved") {
        currentApprovalStep = approvalStep || 0;
      }

      const reviewed_by = {
        user_id: authUser?.id,
        first_name: authUser?.first_name || "",
        last_name: authUser?.last_name || "",
        reviewed_date: dayjs(),
      };
      const payload = goodsIssueUpdatePayloadFormatter(
        data,
        changingStatus,
        currentApprovalStep,
        currentApprovalList,
        false,
        reviewed_by
      ) as GoodsIssueUpdateInput;
      const { GoodsIssueUpdate } = await updateGoodsIssue({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      if (payload.main_status === "wait_issue") {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Approve,
            message: [`ลำดับที่ ${approvalStep}`],
            prev_status: "wait_approve",
            curr_status: "wait_issue",
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.Approve,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueUpdate?.id,
          activity_detail: {
            message: [`ลำดับที่ ${approvalStep}`],
          },
        });
      }
      await refetch();
      enqueueSnackbar(`อนุมัติสำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`อนุมัติไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const notApproveHandler = async (data: IGoodsIssue) => {
    try {
      const reviewed_by = {
        user_id: authUser?.id,
        first_name: authUser?.first_name || "",
        last_name: authUser?.last_name || "",
        reviewed_date: dayjs(),
      };
      const payload = goodsIssueUpdatePayloadFormatter(
        data,
        data.main_status,
        data.approval_step,
        approvalTemplates as IApprovalTemplate[],
        true,
        reviewed_by
      ) as GoodsIssueUpdateInput;
      const { GoodsIssueUpdate } = await updateGoodsIssue({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsIssue,
        reference_id: GoodsIssueUpdate?.id,
        activity_detail: {
          message: [`ลำดับที่ ${data.approval_step}`],
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "not_approved" /*  */,
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
      await refetch();
      enqueueSnackbar(`ไม่${t("button.approve")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`ไม่${t("button.not_approve")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const waitIssueHandler = async (data: IGoodsIssue) => {
    try {
      if (!id) {
        const payload = goodsIssueCreatePayloadFormatter(
          data,
          "wait_issue",
          data.approval_step
        ) as GoodsIssueCreateInput;
        const { GoodsIssueCreate } = await createGoodsIssue({
          createInput: payload,
        });

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_issue",
          },
        });
        navigate(`/inventory/goods-issue/${GoodsIssueCreate.id}`);
      } else {
        const payload = goodsIssueUpdatePayloadFormatter(
          data,
          "wait_issue"
        ) as GoodsIssueUpdateInput;
        const { GoodsIssueUpdate } = await updateGoodsIssue({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: payload,
        });

        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsIssue,
          reference_id: GoodsIssueUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "wait_issue",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
        await refetch();
      }
      enqueueSnackbar(`${t("sentence.continue")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("sentence.continue")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const issueHandler = async (data: IGoodsIssue) => {
    try {
      const payload = goodsIssueUpdatePayloadFormatter(
        data,
        "finished",
        data.approval_step
      ) as GoodsIssueUpdateInput;
      const { GoodsIssueUpdate } = await updateGoodsIssue({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsIssue,
        reference_id: GoodsIssueUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "finished",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
      await refetch();
      enqueueSnackbar(`${t("inventory.goods_issue.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("inventory.goods_issue.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancelGoodsIssue({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryDocumentType.GoodsIssue,
      });

      await createActivityLog({
        activity_type: ActivityType.Cancel,
        document_type: ActivityLogDocumentType.GoodsIssue,
        reference_id: parseInt(id!),
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("status.cancelled")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("status.cancelled")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  // const saveEditHandler = async (data: IGoodsIssue) => {
  //   try {
  //     const payload = goodsIssueUpdatePayloadFormatter(
  //       data,
  //       currentStatus || ""
  //     ) as GoodsIssueUpdateInput;
  //     await updateGoodsIssue({
  //       uniqueInput: {
  //         id: id ? parseInt(id) : undefined,
  //       },
  //       updateInput: payload,
  //     });
  //     await refetch();
  //     enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
  //       variant: "success",
  //     });
  //     setIsEdit(false);
  //     setDisabled(true);
  //   } catch (err) {
  //     enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
  //       variant: "error",
  //     });
  //   }
  // };

  // const editHandler = () => {
  //   setIsEdit(true);
  //   setDisabled(false);
  // };

  // const cancelEditHandler = () => {
  //   setIsEdit(false);
  //   setDisabled(true);
  //   reset();
  // };

  useInventoryError(errors);

  const renderButton = (status: string) => {
    // if (isEdit) {
    //   return (
    //     <>
    //       <CustomizedButton
    //         variant="outlined"
    //         title={t("button.cancel")}
    //         onClick={cancelEditHandler}
    //       />
    //       <CustomizedButton
    //         variant="contained"
    //         title={t("button.save")}
    //         onClick={handleSubmit(saveEditHandler)}
    //         disabled={isUpdating}
    //       />
    //     </>
    //   );
    // } else {
    switch (status) {
      case "draft":
        if (approvalTemplates && approvalTemplates.length > 0) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(draftHandler)}
                disabled={isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.send")}
                onClick={handleSubmit(waitApproveHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(draftHandler)}
                disabled={isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.next")}
                onClick={handleSubmit(waitIssueHandler)}
                disabled={isUpdating}
              />
            </>
          );
        }

      case "wait_approve":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              onClick={handleSubmit(saveHandler)}
              disabled={isUpdating}
            />
            {isHaveApproval && (
              <>
                <CustomizedButton
                  variant="outlined"
                  title={t("button.not_approve")}
                  onClick={handleSubmit(notApproveHandler)}
                  disabled={isUpdating}
                />
                <CustomizedButton
                  variant="contained"
                  title={t("button.approve")}
                  onClick={handleSubmit(approveHandler)}
                  disabled={isUpdating}
                />
              </>
            )}
          </>
        );

      case "wait_issue":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              onClick={handleSubmit(saveHandler)}
              disabled={isUpdating}
            />
            <CustomizedButton
              variant="contained"
              title={t("inventory.goods_issue.index")}
              onClick={handleSubmit(issueHandler)}
              disabled={isUpdating}
            />
          </>
        );
      case "finished":
      case "cancelled":
        return null;
      case "not_approved":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save")}
              onClick={handleSubmit(saveHandler)}
              disabled={isUpdating}
            />
            <CustomizedButton
              variant="contained"
              title={t("button.send")}
              onClick={handleSubmit(waitApproveHandler)}
              disabled={isUpdating}
            />
          </>
        );
      default:
        if (approvalTemplates && approvalTemplates.length > 0) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(draftHandler)}
                disabled={isCreating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.send")}
                onClick={handleSubmit(waitApproveHandler)}
                disabled={isCreating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(draftHandler)}
                disabled={isCreating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.next")}
                onClick={handleSubmit(waitIssueHandler)}
                disabled={isCreating}
              />
            </>
          );
        }
    }
    // }
  };

  if (id && (isCreating || isUpdating || isCanceling || isAllSkuLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <GoodsIssueHeaderBreadcrumbs cancelHandler={cancelHandler} />
      <GoodsIssueHeader
        approverList={approverList as IApprovalTemplate[]}
        approvalStep={approvalStep}
      />
      <GoodsIssueInfo replace={replace} />
      <GoodsIssueItemList
        fields={fields}
        update={update}
        remove={remove}
        append={append}
      />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(currentStatus || "")}
        </Stack>
      </BottomNavbar>
    </FormProvider>
  );
};

export default GoodsIssueContainer;
