import { ICompany } from "../../../types/Setting/Company";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/Global";

export const companySchema: ICompany = {
  unique_id: "",
  name: "",
  type: "บริษัท",
  identity_no: "",
  register_date: null,
  is_registered_vat: false,
  vat_registration_date: null,
  is_active: 1,
  img_url: [],
  contact_channel_list: [
    {
      contact_channel_type: "phone",
      contact_channel_info: "",
    },
    {
      contact_channel_type: "email",
      contact_channel_info: "",
    },
  ],
  address_list: [
    {
      type: "ที่อยู่จดทะเบียน",
      address_name: "",
      district: "",
      is_default: true,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "thailand",
      fax: "",
    },
    {
      type: "ที่อยู่จัดส่งสินค้า",
      address_name: "",
      district: "",
      is_default: false,
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "thailand",
      fax: "",
    },
  ],
};

const contactChannelListValidation = Yup.object().shape({
  contact_channel_type: Yup.string().required("กรุณาระบุ"),
  contact_channel_info: Yup.string().when(
    "contact_channel_type",
    (contact_channel_type, schema) => {
      if (
        Array.isArray(contact_channel_type) &&
        contact_channel_type[0] === "phone"
      )
        return schema.matches(phoneRegExp, "กรุณาระบุให้ถูกต้อง");
      else return schema;
    }
  ),
});

export const companyValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  type: Yup.string().required("กรุณาระบุ"),
  identity_no: Yup.string()
    .required("กรุณาระบุ")
    .max(13, "ห้ามยาวเกิน 13 หลัก"),
  contact_channel_list: Yup.array().of(contactChannelListValidation),
});
