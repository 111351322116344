import { Grid, Typography, IconButton } from "@mui/material";
import ControlledTextField from "../../../../components/Controller/ControlledTextField";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../../components/Custom/CustomizedBox";
import { useFormContext } from "react-hook-form";
import WarehouseAddress from "./WarehouseAddress";
import { useDisable } from "../../../../hooks/use-disable";
import EditIcon from "@mui/icons-material/Edit";
import { useModal } from "../../../../hooks/use-modal";
import CheckedBranchModal from "../../../../components/UI/Modal/CheckedBranchModal";
import { useCallback, useEffect, useState } from "react";
import { IBranch } from "../../../../types/Setting/Branch";
import { IWarehouse } from "../../../../types/Setting/Inventory";
import CheckedUserModal from "../../../../components/UI/Modal/CheckedUserModal";
import { IUser } from "../../../../types/Auth/user";
import {
  GeneralModelType,
  GeneralUniqueIdGenerateQuery,
  useGeneralUniqueIdGenerateQuery,
} from "../../../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useParams } from "react-router-dom";
import ControlledCheckbox from "../../../../components/Controller/ControlledCheckbox";

type Props = {
  address: any;
};

const InfoTab = ({ address }: Props) => {
  const {
    control,
    formState: { errors },
    reset,
    setValue,
  } = useFormContext<IWarehouse>();
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { modal, openModalHandler, closeModalHandler } = useModal();
  const {
    modal: userModal,
    openModalHandler: openUserModal,
    closeModalHandler: closeUserModal,
  } = useModal();

  const [branchIds, setBranchsIds] = useState<string[]>([]);
  const [branchIdsSnapshot, setBranchsIdsSnapshot] = useState<string[]>([]);

  const [userIds, setUsersIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUsersIdsSnapshot] = useState<string[]>([]);

  const finishBranchSelect = (data: IBranch) => {
    const { id, unique_id } = data;

    reset((prev: IWarehouse) => ({
      ...prev,
      branch_unique_id: unique_id,
      branch_id: id,
    }));

    closeModalHandler();
  };

  const finishUserSelect = (data: IUser) => {
    const { id, first_name, last_name } = data;
    reset((prev: IWarehouse) => ({
      ...prev,
      user_id: id,
      supervisor: first_name + " " + last_name,
    }));
  };

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: GeneralModelType.Warehouse,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
          ข้อมูล
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={
                t("setting.unique_id") + t("setting.inventory.warehouse.index")
              }
              control={control}
              name="unique_id"
              error={Boolean(errors?.unique_id)}
              helperText={
                errors?.unique_id && errors.unique_id.message?.toString()
              }
              required
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("setting.inventory.warehouse.name")}
              control={control}
              name="name"
              error={Boolean(errors?.name)}
              helperText={errors?.name && errors.name.message?.toString()}
              required
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("setting.inventory.warehouse.branch_id")}
              control={control}
              name="branch_unique_id"
              error={Boolean(errors?.branch_unique_id)}
              helperText={
                errors?.branch_unique_id &&
                errors.branch_unique_id.message?.toString()
              }
              required
              viewMode={disabled}
              disabled
              InputProps={{
                endAdornment: (
                  <IconButton
                    disabled={disabled}
                    size="small"
                    onClick={openModalHandler}
                  >
                    <EditIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              label={t("setting.inventory.warehouse.supervisor")}
              control={control}
              name="supervisor"
              error={Boolean(errors?.supervisor)}
              helperText={
                errors?.supervisor && errors.supervisor.message?.toString()
              }
              viewMode={disabled}
              disabled
              InputProps={{
                endAdornment: (
                  <IconButton
                    disabled={disabled}
                    size="small"
                    onClick={openUserModal}
                  >
                    <EditIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledCheckbox
              label={t("setting.inventory.warehouse.is_consignment")}
              control={control}
              name="is_consignment"
              error={Boolean(errors?.is_consignment)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <WarehouseAddress address={address} />
      <CustomizedBox>
        <Typography color="primary.main" fontWeight={600} fontSize={14} mb={2}>
          รายละเอียด
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="description"
              error={Boolean(errors?.description)}
              helperText={
                errors?.description && errors.description.message?.toString()
              }
              multiline
              rows={3}
              viewMode={disabled}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedBranchModal
        branchIds={branchIds}
        branchIdsSnapshot={branchIdsSnapshot}
        closeBranchTable={closeModalHandler}
        setBranchIds={setBranchsIds}
        setBranchIdsSnapshot={setBranchsIdsSnapshot}
        showSelectBranch={modal}
        rowSelection="single"
        finishBranchSelect={finishBranchSelect}
      />
      <CheckedUserModal
        userIds={userIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIds={setUsersIds}
        setUserIdsSnapshot={setUsersIdsSnapshot}
        showSelectUser={userModal}
        closeUserTable={closeUserModal}
        finishUsersSelect={finishUserSelect}
        rowSelection="single"
      />
    </>
  );
};

export default InfoTab;
