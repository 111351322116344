import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Typography, Divider } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useForm } from "react-hook-form";
import { IExternalProps } from "../../../types/Sales";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect } from "react";

interface Props {
  open: boolean;
  prevValue?: string;
  handleClose: () => void;
  action: (data: IExternalProps) => void;
}

const ExternalReferenceModal = ({
  open,
  handleClose,
  action,
  prevValue,
}: Props) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<IExternalProps>({
    defaultValues: {
      external_reference_no: "",
    },
  });

  useEffect(() => {
    if (prevValue) {
      reset({ external_reference_no: prevValue });
    }
  }, [prevValue, reset]);

  return (
    <ModalUI
      title={t("sales.external_reference_no")}
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      action={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={() => {
              handleClose();
              reset();
            }}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={handleSubmit(action)}
          />
        </Box>
      }
    >
      <Divider sx={{ marginBottom: 2 }} />
      <Box display="flex" gap={1}>
        <Typography mb={2}>เลขที่เอกสาร</Typography>
        <CustomizedTooltip
          title={
            <Box>
              <Typography fontSize={14}>
                การอ้างอิงเอกสารไม่มีผลต่อสถานะของเอกสาร
              </Typography>
              <Typography fontSize={14}>
                เพียงแต่จะมีเลขอ้างอิงเพิ่มขึ้นในหน้าเอกสาร
              </Typography>
            </Box>
          }
          isNoMaxWidth
          placement="right"
        >
          <ErrorOutlineIcon
            sx={{ color: (theme) => theme.palette.secondary.main }}
          />
        </CustomizedTooltip>
      </Box>
      <ControlledTextField
        control={control}
        name="external_reference_no"
        placeholder={t("sentence.unique_id")}
      />
      <Divider sx={{ marginTop: 2 }} />
    </ModalUI>
  );
};

export default ExternalReferenceModal;
