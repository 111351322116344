import { useRef } from "react";
import AgGrid from "../../../UI/AgGrid";
import useWarehouseColumnDefs from "./useWarehouseColumnDefs";
import { RowDoubleClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any[];
};

const WarehouseTable = ({ data }: Props) => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const columnDefs = useWarehouseColumnDefs();

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/setting/inventory/warehouse/${id}`);
  };

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      onRowDoubleClicked={rowSelectHandler}
      path={`/setting/inventory/warehouse`}
      disabledSidebar
    />
  );
};

export default WarehouseTable;
