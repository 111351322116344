import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { genderFormatter } from "../../../utils/Formatter/Global";
import { formatString, formatStringToArray } from "../../../utils/Global";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
  findNoneBoolean,
  findNoneGender,
  findNoneStatus,
  findNoneTitleName,
  isNotValidDate,
  isNotValidPhone,
  mapNestedData,
} from "../../../utils/Importer";
import {
  ValidateUserImportQuery,
  useImportUserMutation,
  useValidateUserImportQuery,
} from "../../../generated/general";
import { IImporterError } from "../../../types/global";
import { useTranslation } from "react-i18next";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

dayjs.extend(customParseFormat);

const columns = [
  "unique_id",
  "title_name",
  "first_name",
  "last_name",
  "nick_name",
  "gender",
  "img_url",
  "phone",
  "email",
  "department",
  "position",
  "branch_unique_id",
  "employee_type",
  "is_sales_employee",
  "status",
  "start_date",
  "inactive_date",
];

const requiredKeys = [
  "unique_id",
  "first_name",
  "last_name",
  "phone",
  "email",
  "branch_unique_id",
  "status",
];

const count_limit = 50000;

export const useUserImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [userUniqueIdList, setUserUniqueIdList] = useState<string[]>([]);
  const [branchUniqueIdList, setBranchUniqueIdList] = useState<string[]>([]);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [phoneList, setPhoneList] = useState<string[]>([]);
  const [departmentList, setDepartmentList] = useState<string[]>([]);
  const [positionList, setPositionList] = useState<string[]>([]);
  const [employeeTypeList, setEmployeeTypeList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("general");

  const { refetch: validateUser, isFetching: isValidating } =
    useValidateUserImportQuery<ValidateUserImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_mode: getValues("type"),
          user_unique_id_list: userUniqueIdList,
          branch_unique_id_list: branchUniqueIdList,
          email_list: getValues("type") === "create" ? emailList : [],
          phone_list: getValues("type") === "create" ? phoneList : [],
          department_list: departmentList,
          position_list: positionList,
          employee_type_list: employeeTypeList,
        },
      },
      {
        enabled: false,
        retry: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportUserMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const formattedDataSnapshot = [...data];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j]]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j],
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            t(`user.account.${error.field}`),
            [""]
          );
        });
      }

      let formattedData: any[] = [];

      const uniqueIdList: string[] = [];
      const branchUniqueIdList: string[] = [];
      const emailList: string[] = [];
      const phoneList: string[] = [];
      const departmentList: string[] = [];
      const positionList: string[] = [];
      const employeeTypeList: string[] = [];

      const titleNameList: string[] = [];
      const genderList: string[] = [];
      const statusList: string[] = [];
      const isSalesEmployeeList: string[] = [];
      const startDateList: string[] = [];
      const inactiveDateList: string[] = [];

      data.forEach((user: any) => {
        const {
          unique_id,
          branch_unique_id,
          email,
          phone,
          department,
          position,
          employee_type,
          title_name,
          gender,
          status,
          start_date,
          inactive_date,
          is_sales_employee,
        } = user;

        if (unique_id) {
          uniqueIdList.push(unique_id);
        }
        if (branch_unique_id) {
          branchUniqueIdList.push(branch_unique_id);
        }
        if (email) {
          emailList.push(email);
        }
        if (phone) {
          phoneList.push(phone.trim());
        }
        if (department) {
          departmentList.push(department);
        }
        if (position) {
          positionList.push(position);
        }
        if (employee_type) {
          employeeTypeList.push(employee_type);
        }

        //Validate own file
        if (title_name) {
          titleNameList.push(title_name);
        }
        if (gender) {
          genderList.push(gender);
        }
        if (status) {
          statusList.push(status);
        }
        if (is_sales_employee) {
          isSalesEmployeeList.push(is_sales_employee);
        }
        if (start_date) {
          startDateList.push(start_date);
        }
        if (inactive_date) {
          inactiveDateList.push(inactive_date);
        }

        const formatGender = genderFormatter(formatString(user.gender), false);

        const mappedData = {
          unique_id: formatString(user.unique_id),
          title_name: formatString(user.title_name),
          first_name: formatString(user.first_name),
          last_name: formatString(user.last_name),
          nick_name: formatString(user.nick_name),
          gender: formatGender,
          img_url: formatStringToArray(user.img_url),
          phone: formatString(user.phone),
          email: formatString(user.email),
          user_password: "tQBEV66Gy06i",
          department: formatString(user.department),
          position: formatString(user.position),
          branch_unique_id: formatString(user.branch_unique_id),
          employee_type: formatString(user.employee_type),
          is_sales_employee: user.is_sales_employee === "ใช่" ? true : false,
          status: user.status === "ใช้งาน" ? 1 : 0,
          start_date: user.start_date
            ? dayjs(user.start_date, "YYYY-MM-DD")
            : undefined,
          inactive_date: user.inactive_date
            ? dayjs(user.inactive_date, "YYYY-MM-DD")
            : undefined,
          remark_status: formatString(user.remark_status),
        };

        formattedData.push(mappedData);
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const duplicatePhone = findDuplicatesWithList(phoneList);
      const duplicateEmail = findDuplicatesWithList(emailList);
      const noneTitleName = findNoneTitleName(titleNameList);
      const noneGender = findNoneGender(genderList);
      const noneStatus = findNoneStatus(statusList);
      const noneIsSalesEmployee = findNoneBoolean(isSalesEmployeeList);
      const notValidStartDate = isNotValidDate(startDateList);
      const notValidInActiveDate = isNotValidDate(inactiveDateList);
      const notValidPhone = isNotValidPhone(phoneList);

      const allError = [
        duplicateUniqueId,
        duplicatePhone,
        duplicateEmail,
        noneTitleName,
        noneGender,
        noneStatus,
        noneIsSalesEmployee,
        notValidStartDate,
        notValidInActiveDate,
        notValidPhone,
        requiredFieldErrors,
      ];

      addImporterErrorHandler(
        errorData,
        "รหัสพนักงานในไฟล์ซ้ำ",
        "รหัสพนักงาน",
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "เบอร์โทรศัพท์ในไฟล์ซ้ำ",
        "เบอร์โทรศัพท์",
        duplicatePhone
      );
      addImporterErrorHandler(
        errorData,
        "อีเมลในไฟล์ซ้ำ",
        "อีเมล",
        duplicateEmail
      );
      addImporterErrorHandler(
        errorData,
        "คำนำหน้าไม่ถูกต้อง",
        "คำนำหน้า",
        noneTitleName
      );
      addImporterErrorHandler(errorData, "เพศไม่ถูกต้อง", "เพศ", noneGender);
      addImporterErrorHandler(
        errorData,
        "สถานะไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "พนักงานขายไม่ถูกต้อง",
        "พนักงานขาย",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "วันที่เริ่มงาน",
        notValidStartDate
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "วันที่หยุดใช้งาน",
        notValidInActiveDate
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เบอร์โทรศัพท์",
        notValidPhone
      );

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setUserUniqueIdList([...new Set(uniqueIdList)]);
      setBranchUniqueIdList([...new Set(branchUniqueIdList)]);
      setEmailList([...new Set(emailList)]);
      setPhoneList([...new Set(phoneList)]);
      setDepartmentList([...new Set(departmentList)]);
      setPositionList([...new Set(positionList)]);
      setEmployeeTypeList([...new Set(employeeTypeList)]);

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateUser();
      const existingUniqueId =
        data?.ValidateUserImport.existing_user_unique_id_list || [];
      const existingPhone = data?.ValidateUserImport.existing_phone_list || [];
      const existingEmail = data?.ValidateUserImport.existing_email_list || [];
      const missingUniqueId =
        data?.ValidateUserImport.missing_user_unique_id_list || [];
      const missingBranchUniqueId =
        data?.ValidateUserImport.missing_branch_unique_id_list || [];
      const missingDepartment =
        data?.ValidateUserImport.missing_department_list || [];
      const missingEmployeeType =
        data?.ValidateUserImport.missing_employee_type_list || [];
      const missingPosition =
        data?.ValidateUserImport.missing_position_list || [];

      const arrays = [
        existingUniqueId,
        missingUniqueId,
        existingPhone,
        existingEmail,
        missingBranchUniqueId,
        missingDepartment,
        missingEmployeeType,
        missingPosition,
      ];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        "รหัสพนักงานซ้ำในระบบ",
        "รหัสพนักงาน",
        existingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสพนักงาน",
        missingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "เบอร์โทรศัพท์ซ้ำในระบบ",
        "เบอร์โทรศัพท์",
        existingPhone
      );
      addImporterErrorHandler(
        errorData,
        "อีเมลซ้ำในระบบ",
        "อีเมล",
        existingEmail
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "รหัสสาขา",
        missingBranchUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "แผนก",
        missingDepartment
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "ตำแหน่ง",
        missingPosition
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "ประเภทพนักงาน",
        missingEmployeeType
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await createOrUpdate({
            importInput: {
              import_mode: getValues("type"),
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
  return {
    rowData,
    isLoading: isValidating || isCreatingOrUpdating,
    validateHandler,
    importHandler,
  };
};
