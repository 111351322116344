import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDisable } from "../../../hooks/use-disable";

import { Grid, IconButton } from "@mui/material";

import RestartAltIcon from "@mui/icons-material/RestartAlt";

import BundleTable from "../../../components/Table/Inventory/Item/Bundle";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import ControlledNumberTextField from "../../../components/Controller/ControlledNumberTextField";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { IItem } from "../../../types/Inventory/item";

type Props = {
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
};

const BundleTab = ({
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
}: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { control, watch, getValues } = useFormContext<IItem>();

  const sale_price = watch("sale_price");
  const special_price = watch("special_price");

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "bundle_item_detail_list",
  });

  const calcultePrice = () => {
    const bundleList = getValues("bundle_item_detail_list");
    const newBundle = bundleList?.map((list: any) => ({
      ...list,
      price: sale_price * (Math.abs(list.ratio_percentage || 0) / 100),
      special_price:
        special_price * (Math.abs(list.ratio_percentage || 0) / 100),
    }));
    replace(newBundle || []);
  };

  return (
    <CustomizedBox>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={4}>
          <ControlledNumberTextField
            control={control}
            name={"sale_price"}
            label={t("inventory.items.sale_price")}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledNumberTextField
            control={control}
            name={"special_price"}
            label={t("inventory.items.special_price")}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} textAlign="end">
          {!disabled && (
            <CustomizedTooltip title="คำนวณราคาใหม่">
              <IconButton onClick={calcultePrice}>
                <RestartAltIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
      </Grid>
      <BundleTable
        fields={fields}
        replace={replace}
        remove={remove}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
      />
    </CustomizedBox>
  );
};

export default BundleTab;
