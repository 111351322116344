import { Dispatch, SetStateAction, useState } from "react";
import { useSnackbar } from "notistack";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

import {
  InventoryImportMode,
  useImportItemPriceListAllMutation,
  useImportItemPriceListByItemMutation,
  useValidateItemImportQuery,
} from "../../../generated/inventory";
import {
  addImporterErrorHandler,
  extractLists,
  findDuplicatesWithList,
  findNoneStatus,
  isNotValidDate,
  mapNestedData,
} from "../../../utils/Importer";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useStateContext } from "../../../contexts/auth-context";
import { formatPriceTwoDecimal, formatString } from "../../../utils/Global";
import dayjs from "dayjs";
import { IImporterError } from "../../../types/global";

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const keysByItem = [
  "adjusted_item_list.sku_name",
  "adjusted_item_list.adjusted_price",
];

const requiredKeys = [
  { label: "รหัสรายการราคา", value: "unique_id" },
  { label: "ชื่อรายการราคา", value: "name" },
  { label: "ช่องทางการขาย", value: "sales_channel" },
  { label: "ลด/เพิ่ม (%)", value: "adjusted_price" },
  { label: "สถานะ", value: "is_active" },
  { label: "วันที่เริ่ม", value: "start_date" },
  { label: "วันที่สิ้นสุด", value: "end_date" },
];

const requiredKeysByItem = [
  { label: "รหัสรายการราคา", value: "unique_id" },
  { label: "ชื่อรายการราคา", value: "name" },
  { label: "ช่องทางการขาย", value: "sales_channel" },
  { label: "", value: "adjusted_item_list" },
  { label: "สกุลเงิน", value: "currency" },
  { label: "สถานะ", value: "is_active" },
  { label: "วันที่เริ่ม", value: "start_date" },
  { label: "วันที่สิ้นสุด", value: "end_date" },
];

const columnsAllItem = [
  "unique_id",
  "name",
  "sales_channel",
  "adjusted_price",
  "description",
  "is_active",
  "start_date",
  "end_date",
];

const columnsByItem = [
  "unique_id",
  "name",
  "sales_channel",
  "adjusted_item_list",
  "description",
  "is_active",
  "start_date",
  "end_date",
];

const count_limit = 50000;

export const usePriceListAllItemImporter = (
  type: InventoryImportMode,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    sku_name_list: string[];
    item_price_list_unique_id_list: string[];
    sales_channel_list: string[];
  }>({
    import_mode: InventoryImportMode.Update,
    sku_name_list: [],
    item_price_list_unique_id_list: [],
    sales_channel_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportItemPriceListAllMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columnsAllItem.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!data[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: data[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const keys = [
        "unique_id",
        "is_active",
        "adjusted_price",
        "start_date",
        "end_date",
      ];

      const { unique_id, is_active, start_date, end_date } = extractLists(
        data,
        keys
      );

      const formattedData = data.map((item: any, index: number) => {
        return {
          unique_id: formatString(item.unique_id),
          name: formatString(item.name),
          sales_channel: formatString(item.sales_channel),
          adjusted_price: formatPriceTwoDecimal(item.adjusted_price),
          is_active: item.is_active === "ใช้งาน" ? 1 : 0,
          start_date: dayjs(item.start_date),
          end_date: dayjs(item.end_date),
        };
      });

      const duplicateUniqueId = findDuplicatesWithList(unique_id);
      const noneStatus = findNoneStatus(
        is_active.filter((status) => status !== null)
      );
      const startDateNotValidDate = isNotValidDate(
        start_date.filter((status) => status !== null)
      );
      const endDateNotValidDate = isNotValidDate(
        end_date.filter((status) => status !== null)
      );
      const { dateNotValid } = formattedData.reduce(
        (lists: any, curr: any) => {
          const checkDate = dayjs(curr.start_date).isAfter(curr.end_date);
          if (checkDate) lists.dateNotValid.push(curr.unique_id);
          return lists;
        },
        {
          dateNotValid: [] as string[],
        }
      );

      const allError = [
        duplicateUniqueId,
        noneStatus,
        startDateNotValidDate,
        endDateNotValidDate,
        dateNotValid,
      ];

      addImporterErrorHandler(
        errorData,
        "รหัสรายการราคาในไฟล์ซ้ำ",
        "รหัสรายการราคา",
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "วันที่เริ่ม",
        startDateNotValidDate
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "วันที่สิ้นสุด",
        endDateNotValidDate
      );
      addImporterErrorHandler(
        errorData,
        "กรุณาระบุวันที่สิ้นสุดเท่ากับหรือสูงกว่าวันที่เริ่มต้น",
        "รหัสรายการราคา",
        dateNotValid
      );

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength || requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const existingPricelistUniqueId =
        data?.ValidateItemImport.existing_item_price_list_unique_id_list || [];
      const missingPricelistUniqueId =
        data?.ValidateItemImport.missing_item_price_list_unique_id_list || [];
      const missingSalesPerson =
        data?.ValidateItemImport.missing_sales_channel_list || [];

      let arrays: any[] = [];
      let sumErrorLength: boolean = false;
      let errorData: IImporterError[] = [];
      const defaultArrays = [missingSalesPerson];

      if (type === "update") {
        arrays = [missingPricelistUniqueId, ...defaultArrays];
        addImporterErrorHandler(
          errorData,
          "ไม่พบข้อมูลในระบบ",
          "รหัสรายการราคา",
          missingPricelistUniqueId
        );
      } else {
        arrays = [existingPricelistUniqueId, ...defaultArrays];
        addImporterErrorHandler(
          errorData,
          "รหัสรายการราคาซ้ำกับในระบบ",
          "รหัสรายการราคา",
          existingPricelistUniqueId
        );
      }

      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "ช่องทางการขาย",
        missingSalesPerson
      );

      sumErrorLength =
        arrays.reduce((total, currentArray) => total + currentArray.length, 0) >
        0;

      if (sumErrorLength) {
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await importItems({
            importInput: {
              data: rowData,
              import_mode: type,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (error) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData: any[] = await formatItemdata(tempDoc);

      const validateInput = {
        import_mode: type,
        sku_name_list: formattedData
          .map((data) => data.sku_name)
          .filter((skuName) => skuName !== null && skuName !== undefined),
        item_price_list_unique_id_list: formattedData
          .map((data) => data.unique_id)
          .filter((skuName) => skuName !== null && skuName !== undefined),
        sales_channel_list: formattedData
          .map((data) => data.sales_channel_list)
          .filter((skuName) => skuName !== null && skuName !== undefined),
      };

      setValidateInput(validateInput);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }

    //use validateInput to call api ValidateItemImport or ... for other modules
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler,
  };
};

export const usePriceListByItemImporter = (
  type: InventoryImportMode,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [rowData, setRowData] = useState<any[]>([]);
  const [validateInput, setValidateInput] = useState<{
    import_mode: InventoryImportMode;
    sku_name_list: string[];
    item_price_list_unique_id_list: string[];
    sales_channel_list: string[];
  }>({
    import_mode: InventoryImportMode.Create,
    sku_name_list: [],
    item_price_list_unique_id_list: [],
    sales_channel_list: [],
  });

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportQuery(
      graphqlClient,
      {
        validateInput: validateInput,
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: importItems, isLoading: isImporting } =
    useImportItemPriceListByItemMutation(graphqlClient);

  const formatItemdata = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columnsByItem.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors = [];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < requiredKeysByItem.length; j++) {
          if (!data[i][requiredKeysByItem[j].value]) {
            requiredFieldErrors.push({
              unique_id: data[i].unique_id,
              type: "required",
              field: requiredKeysByItem[j].label,
            });
          }
          if (
            data[i][requiredKeysByItem[j].value] &&
            typeof data[i][requiredKeysByItem[j].value] === "object"
          ) {
            console.log(
              "data[i][requiredKeysByItem[j].value]",
              data[i][requiredKeysByItem[j].value]
            );
            data[i][requiredKeysByItem[j].value].sku_name.forEach(
              (sku: any) => {
                console.log("sku", sku);
                if (!sku || Object.keys(sku).length === 0)
                  requiredFieldErrors.push({
                    unique_id: data[i].unique_id,
                    type: "required",
                    field: "รหัสสินค้า",
                  });
              }
            );
            data[i][requiredKeysByItem[j].value].adjusted_price.forEach(
              (price: any) => {
                if (!price || Object.keys(price).length === 0)
                  requiredFieldErrors.push({
                    unique_id: data[i].unique_id,
                    type: "required",
                    field: "กำหนดราคา",
                  });
              }
            );
          }
        }
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
            error.field,
            [""]
          );
        });
      }

      const keys = ["unique_id", "is_active", "start_date", "end_date"];

      const { unique_id, is_active, start_date, end_date } = extractLists(
        data,
        keys
      );

      const convertAdjustedItemList = (inputObject: any) => {
        const { sku_name, adjusted_price } = inputObject;

        if (
          !sku_name ||
          !adjusted_price ||
          sku_name.length !== adjusted_price.length
        ) {
          // Handle invalid input
          return "Invalid input";
        }

        const resultArray = sku_name.map((sku: any, index: any) => ({
          sku_name: sku,
          adjusted_price: formatPriceTwoDecimal(adjusted_price[index]),
        }));

        return resultArray;
      };

      const formattedData = data.map((item: any, index: number) => {
        return {
          unique_id: formatString(item.unique_id),
          name: formatString(item.unique_id),
          sales_channel: formatString(item.sales_channel),
          currency: formatString(item.currency),
          adjusted_item_list: convertAdjustedItemList(item.adjusted_item_list),
          description: formatString(item.description),
          is_active: item.is_active === "ใช้งาน" ? 1 : 0,
          start_date: dayjs(item.start_date),
          end_date: dayjs(item.end_date),
        };
      });

      const duplicateUniqueId = findDuplicatesWithList(unique_id);
      const noneStatus = findNoneStatus(
        is_active.filter((status) => status !== null)
      );
      const startDateNotValidDate = isNotValidDate(
        start_date.filter((status) => status !== null)
      );
      const endDateNotValidDate = isNotValidDate(
        end_date.filter((status) => status !== null)
      );
      const { dateNotValid } = formattedData.reduce(
        (lists: any, curr: any) => {
          const checkDate = dayjs(curr.start_date).isAfter(curr.end_date);
          if (checkDate) lists.dateNotValid.push(curr.unique_id);
          return lists;
        },
        {
          dateNotValid: [] as string[],
        }
      );

      const allError = [
        duplicateUniqueId,
        noneStatus,
        startDateNotValidDate,
        endDateNotValidDate,
        dateNotValid,
      ];

      addImporterErrorHandler(
        errorData,
        "รหัสรายการราคาในไฟล์ซ้ำ",
        "รหัสรายการราคา",
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "วันที่เริ่ม",
        startDateNotValidDate
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "วันที่สิ้นสุด",
        endDateNotValidDate
      );
      addImporterErrorHandler(
        errorData,
        "กรุณาระบุวันที่สิ้นสุดเท่ากับหรือสูงกว่าวันที่เริ่มต้น",
        "รหัสรายการราคา",
        dateNotValid
      );

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      setErrorData(errorData);

      if (sumErrorLength || requiredFieldErrors.length > 0) {
        openModalHandler();
        return [];
      }

      setRowData(formattedData);
      return formattedData;
    } catch (e) {
      console.log(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();

      const missingSku = data?.ValidateItemImport.missing_sku_name_list || [];
      const existingPricelistUniqueId =
        data?.ValidateItemImport.existing_item_price_list_unique_id_list || [];
      const missingPricelistUniqueId =
        data?.ValidateItemImport.missing_item_price_list_unique_id_list || [];
      const missingSalesPerson =
        data?.ValidateItemImport.missing_sales_channel_list || [];

      let arrays: any[] = [];
      let sumErrorLength: boolean = false;
      let errorData: IImporterError[] = [];
      if (type === "update") {
        arrays = [missingPricelistUniqueId, missingSalesPerson, missingSku];
        addImporterErrorHandler(
          errorData,
          "ไม่พบข้อมูลในระบบ",
          "รหัสรายการราคา",
          missingPricelistUniqueId
        );
      } else {
        arrays = [existingPricelistUniqueId, missingSalesPerson, missingSku];

        addImporterErrorHandler(
          errorData,
          "รหัสรายการราคาซ้ำกับในระบบ",
          "รหัสรายการราคา",
          missingPricelistUniqueId
        );
      }

      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสรายการราคา",
        missingSalesPerson
      );
      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        "รหัสสินค้า",
        missingSalesPerson
      );

      sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await importItems({
            importInput: {
              data: rowData,
              import_mode: type,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (error) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData(keysByItem, getValues, enqueueSnackbar);
      const formattedData: any[] = await formatItemdata(tempDoc);

      const uniqueSkuNames: string[] = Array.from(
        new Set(
          ...formattedData.flatMap((product) =>
            product.adjusted_item_list.map((item: any) => item.sku_name)
          )
        )
      );

      const validateInput = {
        import_mode: type,
        sku_name_list: uniqueSkuNames,
        item_price_list_unique_id_list: formattedData
          .map((data) => data.unique_id)
          .filter((uniqueId) => uniqueId !== null && uniqueId !== undefined),
        sales_channel_list: formattedData
          .map((data) => data.sales_channel_list)
          .filter((sales) => sales !== null && sales !== undefined),
      };

      setValidateInput(validateInput);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }

    //use validateInput to call api ValidateItemImport or ... for other modules
  };

  return {
    rowData,
    isLoading: isValidating || isImporting,
    validateHandler,
    importHandler,
  };
};
