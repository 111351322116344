import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";
import { useUserOption } from "../../../../hooks/use-user-option";

import { Box, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import LabelInput from "../../../UI/LabelInput";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CheckedUserModal from "../../../UI/Modal/CheckedUserModal";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { IUser } from "../../../../types/Auth/user";
import { IPurchaseRequest } from "../../../../types/Purchase/purchaseRequest";
import SubHeader from "../SubHeader";

const RequesterInfo = () => {
  const [disabled] = useDisable();
  const { t } = useTranslation();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUserIdsSnapshot] = useState<string[]>([]);
  const [openUserTable, setOpenUserTable] = useState<boolean>(false);

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IPurchaseRequest>();

  const { departmentsOption, positionsOption } = useUserOption();

  const requester = useWatch({
    control,
    name: "requester",
  });

  useEffect(() => {
    if (requester && requester.id) {
      setUserIds([requester.id.toString()]);
      setUserIdsSnapshot([requester.id.toString()]);
    }
  }, [requester]);

  const closeUserTable = () => {
    setOpenUserTable(false);
  };

  const openContactDetail = () => {
    window.open(`/user/account/${requester?.id}`, "_blank");
  };

  const finishUsersSelect = (data: IUser) => {
    if (data) {
      setValue("requester", {
        ...data,
        name: data.first_name + " " + data.last_name,
      });
    }
  };

  return (
    <CustomizedBox>
      <Box display="flex" gap={1} alignItems="center">
        <SubHeader title={t("purchase.request.requester_description")} />
        {!disabled ? (
          <CustomizedTooltip title={t("sentence.choose_customer")}>
            <IconButton onClick={() => setOpenUserTable(true)} color="primary">
              <EditIcon />
            </IconButton>
          </CustomizedTooltip>
        ) : (
          <CustomizedTooltip title="กดเพื่อดูรายละเอียดผู้ขอ">
            <IconButton onClick={openContactDetail} color="primary">
              <LaunchOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={0.5}>
        <Grid item sm={12} md={4}>
          <ControlledTextField
            control={control}
            name="requester.name"
            label={t("purchase.request.requester")}
            error={Boolean(errors.requester?.name)}
            helperText={errors.requester?.name?.message?.toString()}
            viewMode={disabled}
            disabled
            required
          />
        </Grid>
        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("user.account.department")}
              value={getValues("requester")?.department || ""}
            />
          ) : (
            <ControlledSelect
              name="requester.department"
              control={control}
              label={t("user.account.department")}
              error={Boolean(errors.requester?.department)}
              helperText={errors.requester?.department?.message?.toString()}
              options={departmentsOption}
              disabled={disabled}
              required
            />
          )}
        </Grid>
        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("user.account.position")}
              value={getValues("requester")?.position || ""}
            />
          ) : (
            <ControlledSelect
              name="requester.position"
              control={control}
              label={t("user.account.position")}
              error={Boolean(errors?.requester?.position)}
              helperText={errors?.requester?.position?.message?.toString()}
              options={positionsOption}
              disabled={disabled}
              required
            />
          )}
        </Grid>
      </Grid>
      <CheckedUserModal
        showSelectUser={openUserTable}
        closeUserTable={closeUserTable}
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
        finishUsersSelect={finishUsersSelect}
        rowSelection="single"
      />
    </CustomizedBox>
  );
};

export default RequesterInfo;
