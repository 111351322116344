import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ItemSkuDetailQuery,
  ItemType,
  WmsUniqueIdGenerateQuery,
  useItemCreateWithUomMutation,
  useItemDeleteMutation,
  useItemSkuDetailQuery,
  useItemUpdateWithSkuMutation,
  useWmsUniqueIdGenerateQuery,
} from "../../../generated/inventory";

import { IItem } from "../../../types/Inventory/item";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import DetailTab from "./DetailTab";
import BundleTab from "./BundleTab";
import VariationTab from "./VariationTab";

import { Box, CircularProgress, Stack, IconButton } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  itemSchema,
  itemValidate,
} from "../../../components/Form/Inventory/Item/schema";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

import { useDisable } from "../../../hooks/use-disable";

import {
  formatItem,
  formattedItemPayload,
} from "../../../utils/Formatter/Item";
import { useUnitOptions } from "../../../hooks/use-inventory-setting-option";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { yupResolver } from "@hookform/resolvers/yup";
import Confirmation from "../../../components/UI/Confirmation";
import { useConfirmation } from "../../../hooks/use-confirmation";
import CurrentStockTab from "./CurrentStockTab";
import TransactionTab from "./TransactionTab";
import { errorMessageFormatter } from "../../../utils/Global";
import { useItemErrors } from "../../../hooks/Inventory/use-item-error";
import ControlledDatePicker from "../../../components/Controller/ControlledDatePicker";
import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

const ItemContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [tabs, setTabs] = useState<ITab[]>([]);
  const [currentTab, setCurrentTab] = useState(`${pathname}?tab=address`);
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);
  const [showActivityLog, setShowActivityLog] = useState<boolean>(false);

  const { createActivityLog } = useActivityLog();

  const [isEditVariant, setIsEditVariant] = useState<boolean>(false);

  const selectModifyOptions = [
    {
      value: t("sentence.edit"),
      disabled: false,
    },
    {
      value: t("button.delete"),
      disabled: false,
    },
  ];

  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");

  const { allUnit } = useUnitOptions();
  const [disabled, setDisabled] = useDisable();

  const { data, refetch, isLoading } = useItemSkuDetailQuery(
    graphqlClientWms,
    {
      uniqueInput: {
        sku_name: id ?? "",
      },
    },
    {
      enabled: !!id,
    }
  );

  const itemId = data?.itemSkuDetail?.item?.id;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: Boolean(id)
        ? data?.itemSkuDetail?.sku_name ?? t("inventory.items.add")
        : t("inventory.items.add"),
    },
  ];

  const methods = useForm<IItem>({
    defaultValues: itemSchema,
    resolver: yupResolver<any>(itemValidate),
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, isSubmitted, errors },
  } = methods;

  useItemErrors(errors, isValid, isSubmitted);

  const itemType = useWatch({ control: methods.control, name: "type" });
  const isStock = useWatch({ control: methods.control, name: "is_stockable" });

  const mapTypeToModelType = () => {
    if (itemType) {
      if (itemType === "service") return itemType;
      else return `item_${itemType}`;
    } else if (state) {
      if (state.type === "service") return state.type;
      else return `item_${state.type}`;
    } else return "";
  };

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphqlClientWms,
      {
        modelType: mapTypeToModelType(),
      },
      {
        enabled: false,
        cacheTime: 0,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
    setValue("seller_unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  const defaultTabs: ITab[] = useMemo(
    () => [
      {
        label: t("inventory.items.itemInfomation"),
        path: `${pathname}?tab=item&subtab=general`,
      },
      {
        label: t("inventory.items.variation"),
        path: `${pathname}?tab=variation`,
      },
      {
        label: t("inventory.items.item_bundle"),
        path: `${pathname}?tab=bundle`,
      },
      {
        label: t("inventory.items.onHandItemTab"),
        path: `${pathname}?tab=currentstock`,
        disabled: !disabled,
      },
      {
        label: t("inventory.items.transaction"),
        path: `${pathname}?tab=transaction`,
        disabled: !disabled,
      },
    ],
    [t, pathname, disabled]
  );

  const getItem = useCallback(async () => {
    const { data } = await refetch();
    const skuDatailType =
      data?.itemSkuDetail as ItemSkuDetailQuery["itemSkuDetail"];
    if (skuDatailType) {
      const newFormatItem = formatItem(skuDatailType);
      if (newFormatItem?.type === ItemType.Bundle) {
        const bundle_list =
          newFormatItem?.bundle_item_detail_list?.map(
            (list) => list.sku_name
          ) || [];
        setItemIds(bundle_list);
        setItemIdsSnapshot(bundle_list);
      }
      reset(newFormatItem as IItem);
    }
  }, [refetch, reset]);

  useEffect(() => {
    if (id) {
      setDisabled(true);
      setIsEditVariant(true);
    }
    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    if (id) {
      getItem();
    } else {
      if (state) {
        const newSchema = {
          ...itemSchema,
          type: state.type ?? ItemType.Normal,
          // stock_uom_unique_id: state.type === ItemType.Service ? "01" : "",
        } as IItem;
        methods.reset(newSchema);
        // getDefaultPackageAttribute();
        generateUniqueId();
      }
    }
  }, [generateUniqueId, getItem, id, methods, state]);

  useEffect(() => {
    switch (tab) {
      case "item":
        setCurrentTab(pathname + `?tab=item&subtab=general`);
        break;
      default:
        setCurrentTab(pathname + `?tab=${tab}`);
        break;
    }
  }, [pathname, tab]);

  useEffect(() => {
    if (itemType)
      if (["service"].includes(itemType)) {
        setTabs([defaultTabs[0]]);
      } else if (["normal"].includes(itemType)) {
        if (isStock)
          if (id) setTabs([defaultTabs[0], defaultTabs[3], defaultTabs[4]]);
          else setTabs([defaultTabs[0]]);
        else setTabs([defaultTabs[0]]);
      } else if (["variant"].includes(itemType)) {
        if (isStock)
          setTabs([
            defaultTabs[0],
            defaultTabs[1],
            defaultTabs[3],
            defaultTabs[4],
          ]);
        else setTabs([defaultTabs[0], defaultTabs[1]]);
      } else if (["bundle"].includes(itemType)) {
        if (isStock)
          setTabs([
            defaultTabs[0],
            defaultTabs[2],
            defaultTabs[3],
            // defaultTabs[4],
          ]);
        else setTabs([defaultTabs[0], defaultTabs[2]]);
      } else {
        setTabs(defaultTabs);
      }
  }, [defaultTabs, id, isStock, itemType]);

  const renderTab = () => {
    switch (tab) {
      case "currentstock":
        return <CurrentStockTab />;
      case "transaction":
        return <TransactionTab />;
      case "bundle":
        return (
          <BundleTab
            itemIds={itemIds}
            setItemIds={setItemIds}
            itemIdsSnapshot={itemIdsSnapshot}
            setItemIdsSnapshot={setItemIdsSnapshot}
          />
        );
      case "variation":
        return (
          <VariationTab
            isEditVariant={isEditVariant}
            setIsEditVariant={setIsEditVariant}
          />
        );
      default:
        return <DetailTab />;
    }
  };

  const { mutateAsync: createItem, isLoading: isCreating } =
    useItemCreateWithUomMutation<Error>(graphqlClientWms);

  const { mutateAsync: updateItem, isLoading: isUpdating } =
    useItemUpdateWithSkuMutation<Error>(graphqlClientWms);

  const { mutateAsync: deleteItem } =
    useItemDeleteMutation<Error>(graphqlClientWms);

  const editCancelHandler = () => {
    setDisabled(true);
    reset();
  };

  const itemCreateHandler = async (data: IItem) => {
    const formatData = await formattedItemPayload(data, allUnit);
    try {
      const { itemCreateWithUOM } = await createItem({
        createInput: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.Item,
        reference_id: itemCreateWithUOM!.id,
        activity_detail: {},
      });
      enqueueSnackbar("สร้างสินค้าสำเร็จ", {
        variant: "success",
      });
      navigate(
        `/inventory/items/${itemCreateWithUOM?.sku_list[0].sku_name}?tab=item&subtab=general`
      );
    } catch (err) {
      const formatError = errorMessageFormatter(err, "item");
      if (formatError === "รหัสนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่") {
        generateUniqueId();
      }
      enqueueSnackbar(formatError || "สร้างสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const itemUpdateHandler = async (data: IItem) => {
    const { id: dataId, ...otherData } = data;
    const formatData = await formattedItemPayload(otherData, allUnit, true);
    const { unique_id, created_by, ...otherFormatData } = formatData;
    try {
      const { itemUpdateWithSku } = await updateItem({
        uniqueInput: {
          id: dataId,
        },
        updateInput: otherFormatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.Item,
        reference_id: itemUpdateWithSku!.id,
        activity_detail: {},
      });
      if (id === itemUpdateWithSku?.unique_id) {
        navigate(
          `/inventory/items/${itemUpdateWithSku?.sku_list[0].sku_name}?tab=item&subtab=general`
        );
      }
      enqueueSnackbar("แก้ไขสินค้าสำเร็จ", {
        variant: "success",
      });
      getItem();
      setDisabled(true);
    } catch (error) {
      enqueueSnackbar("แก้ไขสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const itemDeleteHandler = async (data: IItem) => {
    const id = getValues("id");
    try {
      const { itemDelete } = await deleteItem({
        uniqueInput: {
          id: id,
        },
      });
      await createActivityLog({
        activity_type: ActivityType.Delete,
        document_type: ActivityLogDocumentType.Item,
        reference_id: itemDelete!.id,
        activity_detail: {},
      });
      enqueueSnackbar("ลบสินค้าสำเร็จ", {
        variant: "success",
      });
      navigate(`/inventory/items`);
    } catch (err) {
      const formatError = errorMessageFormatter(err, "item");
      enqueueSnackbar(formatError || "ลบสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const {
    confirmation: confirmationDelete,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(itemDeleteHandler);

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={itemId!}
          documentType={ActivityLogDocumentType.Item}
        />
      )}
      <HeaderLayout marginBottom={0} marginTop={0} maxWidth>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box display="flex" alignItems="center" gap={1}>
          <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
            <IconButton
              onClick={() => {
                setShowActivityLog(true);
              }}
              sx={{
                color: (theme) => theme.palette.grey[500],
                padding: 0,
                marginLeft: "4px",
              }}
            >
              <RestoreOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
          <CustomizedMenuOptions
            size="small"
            label={t("button.option")}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case t("sentence.edit"):
                  setDisabled(false);
                  break;
                case t("button.delete"):
                  openDeleteConfirmation();
                  break;
                default:
                  break;
              }
            }}
            disabled={!id}
          />
        </Box>
      </HeaderLayout>
      <FormProvider {...methods}>
        <Box
          display="flex"
          maxWidth={1040}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
          <Box width={150}>
            <ControlledDatePicker
              name="created_date"
              control={control}
              label={t("date.created_date")}
              disabled={true}
            />
          </Box>
        </Box>
        {renderTab()}
        <BottomNavbar>
          {!disabled && (
            <Stack direction="row" spacing={1} alignItems="center">
              {!!id && (
                <CustomizedButton
                  variant="outlined"
                  title={t("button.cancel")}
                  onClick={editCancelHandler}
                  disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit(
                  id ? itemUpdateHandler : itemCreateHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <Confirmation
        open={confirmationDelete}
        title={t("inventory.sentence.delete_item") + " " + getValues("name")}
        handleClose={closeDeleteConfirmation}
        action={submitDeleteConfirmation}
      />
    </Fragment>
  );
};

export default ItemContainer;
