import {
  IItem,
  ISkuList,
  IUom,
  IVariantKeyList,
} from "../../types/Inventory/item";
import { uploadFileToS3 } from "../s3";
// import {
//   formatStringArray,
//   formatStringToArray,
//   formatString,
//   formatFloat,
// } from "../dataTransformer";
import {
  ItemSkuDetailQuery,
  ItemType,
  Tracability,
} from "../../generated/inventory";
import dayjs from "dayjs";

interface Variation {
  key: string;
  value: string[];
}

interface SKU {
  sku_name: string;
  variation_list: Variation[];
}

export const formattedItemPayload = async (
  data: IItem,
  allUoms: IUom[],
  isUpdate?: boolean
) => {
  const {
    sku,
    delivery_attribute,
    uom_conversion_list,
    variation_list,
    variation_img_list,
    bundle_item_detail_list: oldBundle,
    tag_list,
    sku_list,
    option_1,
    option_2,
    option_3,
    barcode,
    created_date,
    uom_group_id,
    attribute_list,
    img_url,
    ...otherData
  } = data;

  const new_attribute_list =
    attribute_list?.map(({ id, name, value, short_name, type }) => ({
      id,
      name,
      value,
      short_name,
      type,
    })) || [];

  const new_tag_list = tag_list?.map((tag) => tag.id) || undefined;

  const new_variantion_key_list = transformKeyList(variation_list);
  const variantAmount = new_variantion_key_list.length;

  const new_sku_list: ISkuList[] = [];

  const addSkuToList = (skuData: ISkuList, data: IItem): void => {
    const { key, name, barcode, status, ...otherData } = skuData;

    const sku: ISkuList = {
      ...otherData,
      barcode:
        data.tracability === Tracability.Serial
          ? undefined
          : barcode?.length === 0
          ? undefined
          : barcode,
      status: status ? 1 : 0,
    };

    new_sku_list.push(sku);
  };

  if (variantAmount === 1) {
    const new_variation_list = variation_list as ISkuList[];

    new_variation_list?.forEach((skuData: ISkuList) => {
      addSkuToList(skuData, data);
    });
  } else {
    const processVariations = (list: any[], depth: number) => {
      if (depth >= variantAmount) {
        list.forEach((skuData: ISkuList) => {
          addSkuToList(skuData, data);
        });
      } else {
        list.forEach(({ value }) => {
          processVariations(value, depth + 1);
        });
      }
    };

    const new_variation_list = variation_list as any;
    processVariations(new_variation_list, 1);
  }

  let uploded_img: string[] = [];

  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      for (const file of filteredFile) {
        const { Location } = await uploadFileToS3(
          file,
          "item",
          data?.unique_id || ""
        );
        uploded_img.push(Location);
      }
    } else {
      uploded_img = img_url;
    }
  }

  let variant_current_img: string[] = [];
  for (let imgUrl of variation_img_list ?? []) {
    if (imgUrl && imgUrl.length > 0) {
      if (typeof imgUrl[0] === "string") variant_current_img.push(imgUrl[0]);
      else {
        const filteredFile = imgUrl.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "item",
            data?.unique_id || ""
          );
          variant_current_img.push(Location);
        }
      }
    } else variant_current_img.push("");
  }

  if (data.type === "variant")
    new_sku_list.forEach((sku) => {
      const matchingVariant = sku.item_variant_value[0];
      const matchingVariantValue = matchingVariant.variant_value_name;
      const imgUrlIndex =
        new_variantion_key_list?.[0].item_variant_value_list?.findIndex(
          (value) => value.variant_value_name === matchingVariantValue
        ) || 0;

      if (imgUrlIndex !== -1) {
        if (variant_current_img[imgUrlIndex])
          sku.img_url = [variant_current_img[imgUrlIndex]];
      }
    });

  const newDeliveryAttibute = {};

  const notIncludesValues = [
    "width_uom",
    "height_uom",
    "weight_uom",
    "length_uom",
  ];

  const bundle_item_detail_list = oldBundle?.map((bundle) => ({
    item_unique_id: bundle.item_unique_id,
    sku_name: bundle.sku_name,
    qty: bundle.qty,
    price: bundle.price,
    special_price: bundle.special_price,
    ratio_percentage: bundle.ratio_percentage ?? undefined,
  }));

  // const bundle_item_detail_list: any[] = [];

  for (const [key, value] of Object.entries(delivery_attribute)) {
    if (!notIncludesValues.includes(key))
      Object.assign(newDeliveryAttibute, {
        [key]: value || value === 0 ? value : null,
      });
  }

  const new_uom_conversion_list = uom_conversion_list?.map((list) => ({
    base_uom_rate: list.base_uom_rate,
    target_uom_rate: list.target_uom_rate,
    target_uom_id: list.target_uom_id,
  }));

  const {
    id,
    uom_group,
    stock_uom,
    purchase_uom,
    sales_uom,
    deliver_uom,
    item_variant_key_list,
    item_variant_value_list,
    ...updateData
  } = otherData;

  const defaultData = isUpdate ? updateData : otherData;

  return {
    ...defaultData,
    type: defaultData.type ?? ItemType.Normal,
    bundle_item_detail_list,
    attribute_list: new_attribute_list,
    tag_id_list: new_tag_list,
    uom_group: {
      unique_id: data.unique_id,
      name: data.unique_id,
      uom_conversion_list: new_uom_conversion_list,
    },
    sku_list: [ItemType.Variant].includes(data.type ?? ItemType.Normal)
      ? new_sku_list
      : sku && sku !== ""
      ? [
          {
            sku_name: sku,
            item_variant_value: [],
            status: defaultData.is_active,
          },
        ]
      : undefined,
    barcode: data.tracability === Tracability.Serial ? data.unique_id : barcode,
    img_url: uploded_img,
    item_variant_key_list: new_variantion_key_list,
    delivery_attribute: newDeliveryAttibute,
    is_active: [1, "1", true].includes(data.is_active) ? 1 : 0,
  };
};

// const pushVariantArray = (
//   list: IVariantKeyList[],
//   option?: { key: string; list: { value: string }[] }
// ) => {
//   if (option?.list && option?.list.length > 0) {
//     list.push({
//       item_variant_key_name: option.key,
//       item_variant_value_list:
//         option.list.map((list) => ({
//           item_variant_value_name: list.value,
//         })) || [],
//     });
//   }
// };

export const variationConvert = (option?: {
  key: string;
  list: { value: string }[];
}) => {
  return {
    variant_key_name: option?.key || "",
    item_variant_value_list: option?.list.map((list) => ({
      variant_value_name: list.value || "",
    })),
  };
};

export const generateVariations = (data: {
  unique_id: string;
  variation_list: {
    key: string;
    value: string[];
  }[];
}): SKU[] => {
  const { unique_id, variation_list } = data;

  const output: SKU[] = [];

  const generateSku = (index: number, skuName: string, variations: any[]) => {
    if (index === variation_list.length) {
      output.push({
        sku_name: skuName,
        variation_list: variations,
      });
    } else {
      const { key, value } = variation_list[index];
      value.forEach((val) => {
        const newSkuName = skuName ? `${skuName}-${val}` : val;
        const newVariations = [...variations, { key, value: val }];
        generateSku(index + 1, newSkuName, newVariations);
      });
    }
  };

  generateSku(0, unique_id, []);

  return output;
};

export const transformVariation = (data: any, watchVariantList: any) => {
  const result: any[] = [];

  const variationKeys = data.reduce((keys: string[], item: any) => {
    item.variation_list.forEach((variation: any) => {
      if (!keys.includes(variation.key)) {
        keys.push(variation.key);
      }
    });
    return keys;
  }, []);

  for (const item of data) {
    const variations: any[] = [];
    for (const key of variationKeys) {
      const variation = item.variation_list.find((v: any) => v.key === key);
      variations.push(variation);
    }

    let obj = result;
    for (let i = 0; i < variations.length - 1; i++) {
      const variation = variations[i];
      let newObj = obj.find((o: any) => o.name === variation?.value);
      if (!newObj) {
        newObj = {
          key: variation?.key,
          name: variation?.value,
          value: [],
        };
        obj.push(newObj);
      }
      obj = newObj.value;
    }

    const findValueBySkuName = (
      watchVariantList: any[],
      skuName: string
    ): any => {
      let foundVariant: any = null;
      for (const item of watchVariantList) {
        if (item.sku_name === skuName) {
          foundVariant = item;
          break;
        }
        if (Array.isArray(item.value)) {
          foundVariant = findValueBySkuName(item.value, skuName);
          if (foundVariant) {
            break;
          }
        }
      }
      return foundVariant;
    };

    let foundVariant = findValueBySkuName(watchVariantList, item.sku_name);

    const newItem = {
      item_variant_value: variations.map((variation) => ({
        variant_key_name: variation.key,
        variant_value_name: variation.value,
      })),
      key: variations[variations.length - 1]?.key,
      name: variations[variations.length - 1]?.value,
      sale_price: foundVariant?.sale_price ?? 0,
      special_price: foundVariant?.special_price ?? 0,
      purchase_standard_price: foundVariant?.purchase_standard_price ?? 0,
      sku_name: item.sku_name,
      barcode: item.barcode ?? item.sku_name,
      img_url: item.img_url ?? [],
      status: foundVariant ? foundVariant.status : true,
    };

    obj.push(newItem);
  }

  return result;
};

export const formatItem = (data: ItemSkuDetailQuery["itemSkuDetail"]) => {
  const transformVariant = transformData(data?.item.sku_list);
  const transformKeyVariant = transformKeyValue(
    data?.item.item_variant_key_list
  );
  const transformImgVariant = getItemVariantImageUrl(
    data?.item?.sku_list,
    data?.item?.item_variant_key_list || []
  );
  const transformUomGroup = data?.item.uom_group?.uom_conversion_list?.map(
    ({ base_uom_rate, target_uom_rate, target_uom_id }) => ({
      base_uom_rate,
      target_uom_rate,
      target_uom_id,
    })
  );

  const remark_status = data?.item.bundle_item_detail_list.some(
    (detail_list) => !detail_list.item?.is_active
  )
    ? "มีรายการสินค้าถูกปิดใช้งาน"
    : data?.item.remark_status;

  return {
    ...data?.item,
    sku: data?.sku_name,
    bundle_item_detail_list: data?.item.bundle_item_detail_list,
    remark_status: remark_status,
    uom_conversion_list: transformUomGroup ?? [],
    variation_list: transformVariant,
    variation_img_list: transformImgVariant.map((v: string) =>
      v === "placeholder" || !v ? [] : [v]
    ),
    option_1: transformKeyVariant[0] || { key: "", list: [] },
    option_2: transformKeyVariant[1] || { key: "", list: [] },
    option_3: transformKeyVariant[2] || { key: "", list: [] },
    inactive_date: data?.item?.inactive_date
      ? dayjs(data.item.inactive_date)
      : null,
    created_date: dayjs(data?.item.created_date),
  };
};

export const transformData = (data: any) => {
  const result: any[] = [];

  const variationKeys = data.reduce((keys: string[], item: any) => {
    item.item_variant_value.forEach((variation: any) => {
      if (!keys.includes(variation.variant_key_name)) {
        keys.push(variation.variant_key_name);
      }
    });
    return keys;
  }, []);

  for (const item of data) {
    const variations: any[] = [];
    for (const key of variationKeys) {
      const variation = item.item_variant_value.find(
        (v: any) => v.variant_key_name === key
      );
      variations.push(variation);
    }

    let obj = result;
    for (let i = 0; i < variations.length - 1; i++) {
      const variation = variations[i];
      let newObj = obj.find(
        (o: any) => o.name === variation?.variant_value_name
      );
      if (!newObj) {
        newObj = {
          key: variation?.variant_key_name,
          name: variation?.variant_value_name,
          value: [],
        };
        obj.push(newObj);
      }
      obj = newObj.value;
    }

    const newItem = {
      item_variant_value: variations.map((variation) => ({
        variant_key_name: variation.variant_key_name,
        variant_value_name: variation.variant_value_name,
      })),
      key: variations[variations.length - 1]?.variant_key_name,
      name: variations[variations.length - 1]?.variant_value_name,
      sale_price: item.sku_detail.sale_price,
      special_price: item.sku_detail.special_price,
      purchase_standard_price: item.sku_detail.purchase_standard_price,
      sku_name: item.sku_name,
      barcode: item.sku_detail.barcode,
      status: item.sku_detail.status ? true : false,
    };

    obj.push(newItem);
  }

  return result;
};

export const transformKeyList = (data: any) => {
  const transformedData: IVariantKeyList[] = [];
  const processItem = (item: ISkuList) => {
    if (item.item_variant_value) {
      item.item_variant_value.forEach((value) => {
        const existingVariant = transformedData.find(
          (v) => v.variant_key_name === value.variant_key_name
        );

        if (!existingVariant) {
          transformedData.push({
            variant_key_name: value.variant_key_name,
            item_variant_value_list: [
              { variant_value_name: value.variant_value_name },
            ],
          });
        } else {
          const existingValue = existingVariant.item_variant_value_list?.find(
            (v) => v.variant_value_name === value.variant_value_name
          );

          if (!existingValue) {
            existingVariant.item_variant_value_list?.push({
              variant_value_name: value.variant_value_name,
            });
          }
        }
      });

      if (item.value && Array.isArray(item.value)) {
        item.value.forEach(processItem);
      }
    } else {
      const existingVariant = transformedData.find(
        (v) => v.variant_key_name === item.key
      );

      if (!existingVariant) {
        transformedData.push({
          variant_key_name: item.key as string,
          item_variant_value_list: [{ variant_value_name: item.name ?? "" }],
        });
      } else {
        const existingValue = existingVariant.item_variant_value_list?.find(
          (v) => v.variant_value_name === item.name
        );

        if (!existingValue) {
          existingVariant.item_variant_value_list?.push({
            variant_value_name: item.name ?? "",
          });
        }
      }

      if (item.value && Array.isArray(item.value)) {
        item.value.forEach(processItem);
      }
    }
  };

  data.forEach(processItem);

  return transformedData;
};

export const transformKeyValue = (data: any) => {
  return data.map(({ variant_key_name, item_variant_value_list }: any) => {
    return {
      key: variant_key_name,
      list: item_variant_value_list?.map(({ variant_value_name }: any) => ({
        value: variant_value_name,
      })),
    };
  });
};

export const filterSKU = (skuList: any[]): ISkuList[] => {
  const filteredList: any[] = [];

  skuList.forEach((sku) => {
    if (sku.item_variant_value && sku.item_variant_value.length > 0) {
      const variantValue = sku.item_variant_value[0].variant_value_name;
      const variantKey = sku.item_variant_value[0].variant_key_name;

      const isDuplicate = filteredList.some((filteredSKU) => {
        return (
          filteredSKU.item_variant_value[0].variant_value_name ===
            variantValue &&
          filteredSKU.item_variant_value[0].variant_key_name === variantKey
        );
      });
      if (!isDuplicate) {
        filteredList.push(sku);
      }
    }
  });

  return filteredList || [];
};

export const getItemVariantImageUrl = (
  skuList: any,
  itemVariantKeyList: IItem["item_variant_key_list"]
) => {
  const filteredSku = filterSKU(skuList);
  const variantKeyName = itemVariantKeyList?.[0].variant_key_name;
  const variantValues = itemVariantKeyList?.[0].item_variant_value_list?.map(
    (value) => value.variant_value_name
  );

  const sortedSkuList = filteredSku.sort((a, b) => {
    const aVariantValue = a.item_variant_value.find(
      (v) => v.variant_key_name === variantKeyName
    )?.variant_value_name;
    const bVariantValue = b.item_variant_value.find(
      (v) => v.variant_key_name === variantKeyName
    )?.variant_value_name;
    if (variantValues)
      return (
        variantValues.indexOf(aVariantValue ?? "") -
        variantValues.indexOf(bVariantValue ?? "")
      );
    else return 0;
  });

  const variation_img_url: any = [];
  sortedSkuList.forEach((sku) => {
    const imgUrl = sku.sku_detail?.img_url[0];
    variation_img_url.push(imgUrl);
  });

  return variation_img_url;
};

export const countUniqueItemVariantKeyNames = (data: any) => {
  const uniqueKeyNames = new Set();

  function processItemVariantValues(itemVariantValues: any) {
    itemVariantValues.forEach((itemVariantValue: any) => {
      const itemVariantKeyName = itemVariantValue.variant_key_name;
      if (itemVariantKeyName) {
        uniqueKeyNames.add(itemVariantKeyName);
      }
    });
  }

  function processVariantData(variantData: any) {
    if (variantData.item_variant_value) {
      processItemVariantValues(variantData.item_variant_value);
    }

    if (variantData.value) {
      variantData.value.forEach((value: any) => {
        processVariantData(value);
      });
    }
  }

  data.forEach((item: any) => {
    if (item.item_variant_value) {
      processItemVariantValues(item.item_variant_value);
    }
    processVariantData(item);
  });

  return uniqueKeyNames.size;
};

export const calColorCount = (first: any) => {
  let count = 1; // Start with 1 to account for the current color itself

  const countVariations = (variations: any) => {
    variations.forEach((variant: any) => {
      count += 1; // Increment count for each variation
      if (variant.value) {
        countVariations(variant.value); // Recursively count variations within each variant
      }
    });
  };

  if (first.value) {
    countVariations(first.value); // Start counting variations within the color object
  }

  return count;
};

// export const formatSkuListForImporter = (data: any) => {
//   const variation_attribute_1_name = [
//     ...Array.from(
//       new Set(formatStringArray(data.attribute_1_name).filter((name) => name))
//     ),
//   ];
//   const variation_attribute_1_value = [
//     ...Array.from(
//       new Set(formatStringArray(data.attribute_1_value).filter((name) => name))
//     ),
//   ];

//   const variation_attribute_2_name = [
//     ...Array.from(
//       new Set(formatStringArray(data.attribute_2_name).filter((name) => name))
//     ),
//   ];
//   const variation_attribute_2_value = [
//     ...Array.from(
//       new Set(formatStringArray(data.attribute_2_value).filter((name) => name))
//     ),
//   ];

//   const variation_attribute_3_name = [
//     ...Array.from(
//       new Set(formatStringArray(data.attribute_3_name).filter((name) => name))
//     ),
//   ];
//   const variation_attribute_3_value = [
//     ...Array.from(
//       new Set(formatStringArray(data.attribute_3_value).filter((name) => name))
//     ),
//   ];

//   const sku_list: any[] = [];

//   let counter = 0;

//   const item_unique_id = formatString(data.unique_id);

//   variation_attribute_1_value.forEach((attribute_1_value) => {
//     if (variation_attribute_2_value.length === 0) {
//       sku_list.push({
//         sku_name: `${item_unique_id}-${attribute_1_value}`,
//         barcode: undefined,
//         sale_price: formatFloat(data.sales_standard_price?.[counter + 1]),
//         special_price: formatFloat(data.sales_special_price?.[counter + 1]),
//         purchase_standard_price: formatFloat(data.purchase_standard_price?.[counter + 1]),
//         status:
//           typeof data.status?.[counter + 1] === "boolean"
//             ? data.status?.[counter + 1]
//               ? 1
//               : 0
//             : 1,
//         item_variant_value: [
//           {
//             item_variant_key_name: variation_attribute_1_name[0],
//             item_variant_value_name: attribute_1_value,
//           },
//         ],
//         img_url: formatStringToArray(data.image_list?.[counter + 1]),
//       });
//       counter++;
//     } else {
//       variation_attribute_2_value.forEach((attribute_2_value) => {
//         if (variation_attribute_3_value.length === 0) {
//           sku_list.push({
//             sku_name: `${item_unique_id}-${attribute_1_value}-${attribute_2_value}`,
//             barcode: undefined,
//             sale_price: formatFloat(data.sales_standard_price?.[counter + 1]),
//             special_price: formatFloat(data.sales_special_price?.[counter + 1]),
//             purchase_standard_price: formatFloat(data.sales_purchase_standard_price?.[counter + 1]),
//             status:
//               typeof data.status?.[counter + 1] === "boolean"
//                 ? data.status?.[counter + 1]
//                   ? 1
//                   : 0
//                 : 1,
//             item_variant_value: [
//               {
//                 item_variant_key_name: variation_attribute_1_name[0],
//                 item_variant_value_name: attribute_1_value,
//               },
//               {
//                 item_variant_key_name: variation_attribute_2_name[0],
//                 item_variant_value_name: attribute_2_value,
//               },
//             ],
//             img_url: formatStringToArray(data.image_list?.[counter + 1]),
//           });
//           counter++;
//         } else {
//           variation_attribute_3_value.forEach((attribute_3_value) => {
//             sku_list.push({
//               sku_name: `${item_unique_id}-${attribute_1_value}-${attribute_2_value}-${attribute_3_value}`,
//               barcode: undefined,
//               sale_price: formatFloat(data.sales_standard_price?.[counter + 1]),
//               special_price: formatFloat(
//                 data.sales_special_price?.[counter + 1]
//               ),
//               purchase_standard_price: formatFloat(
//                 data.sales_purchase_standard_price?.[counter + 1]
//               ),
//               status:
//                 typeof data.status?.[counter + 1] === "boolean"
//                   ? data.status?.[counter + 1]
//                     ? 1
//                     : 0
//                   : 1,
//               // img_url: formatStringToArray(data.image_list?.[counter + 1]),
//               item_variant_value: [
//                 {
//                   item_variant_key_name: variation_attribute_1_name[0],
//                   item_variant_value_name: attribute_1_value,
//                 },
//                 {
//                   item_variant_key_name: variation_attribute_2_name[0],
//                   item_variant_value_name: attribute_2_value,
//                 },
//                 {
//                   item_variant_key_name: variation_attribute_3_name[0],
//                   item_variant_value_name: attribute_3_value,
//                 },
//               ],
//               img_url: formatStringToArray(data.image_list?.[counter + 1]),
//             });
//             counter++;
//           });
//         }
//       });
//     }
//   });

//   const item_variant_key_list = [];

//   if (variation_attribute_1_name[0]) {
//     item_variant_key_list.push({
//       item_variant_key_name: variation_attribute_1_name[0],
//       item_variant_value_list: variation_attribute_1_value.map((name) => ({
//         item_variant_value_name: name,
//       })),
//     });
//   }
//   if (variation_attribute_2_name[0]) {
//     item_variant_key_list.push({
//       item_variant_key_name: variation_attribute_2_name[0],
//       item_variant_value_list: variation_attribute_2_value.map((name) => ({
//         item_variant_value_name: name,
//       })),
//     });
//   }
//   if (variation_attribute_3_name[0]) {
//     item_variant_key_list.push({
//       item_variant_key_name: variation_attribute_3_name[0],
//       item_variant_value_list: variation_attribute_3_value.map((name) => ({
//         item_variant_value_name: name,
//       })),
//     });
//   }

//   return { sku_list, item_variant_key_list };
// };
export const formatVatType = (type: string | number) => {
  switch (type) {
    case 7:
    case 0.07:
    case "7":
    case "7%":
    case "7 %":
      return "7";
    case 0:
    case "0":
    case "0%":
    case "0 %":
      return "0";
    default:
      return "ไม่มี";
  }
};

export const formatTracability = (type: string | number) => {
  switch (type) {
    case "LOT":
    case "SN":
    case "ติดตาม Lot/Serial Number":
    case "ติดตามLot/Serial Number":
    case "1":
      return "serial";
    default:
      return "normal";
  }
};
