import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { CURRENT_STOCK_BY_BRANCH_REPORT_VIEW } from "../../../../services/AgGrid/Report/InventoryReportAggrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const CurrentStockByBranchReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();
  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "sku_name",
      headerName: t("inventory.items.sku"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "branch_id",
      headerName: t("branch.unique_id"),
      filter: "agNumberColumnFilter",
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "branch_unique_id",
      headerName: t("branch.unique_id"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "branch_name",
      headerName: t("branch.index"),
      filter: "agTextColumnFilter",
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "purchase_ordered_qty",
      headerName: t("inventory.quantities.purchase_ordered_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "transit_qty",
      headerName: t("inventory.quantities.in_transit_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "sale_committed_qty",
      headerName: t("inventory.quantities.sale_committed_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
  ];

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      try {
        const { CurrentStockByBranchReportView } = await graphQLClient.request(
          CURRENT_STOCK_BY_BRANCH_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel,
              sortModel,
            },
          }
        );
        params.success({
          rowData: CurrentStockByBranchReportView.results,
          rowCount: CurrentStockByBranchReportView.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default CurrentStockByBranchReportTable;
