import { IBranch } from "../../../types/Setting/Branch";
import * as Yup from "yup";
import { faxReqExp, phoneRegExp } from "../../../utils/Global";

export const branchSchema: IBranch = {
  unique_id: "",
  name: "",
  short_name: "",
  zone: "",
  phone: "",
  fax: "",
  status: 1,
  is_hq: false,
  created_date: null,
  last_updated_date: null,
  address: {
    address_name: "",
    sub_district: "",
    district: "",
    province: "",
    postal_code: "",
    fax: "",
    is_default: true,
    country: "",
    description: "",
  },
  user_id_list: [],
};

const addressValidation = Yup.object().shape({
  address_name: Yup.string().required("กรุณาระบุ"),
  sub_district: Yup.string().required("กรุณาระบุ"),
  district: Yup.string().required("กรุณาระบุ"),
  province: Yup.string().required("กรุณาระบุ"),
  postal_code: Yup.string().required("กรุณาระบุ"),
  country: Yup.string().required("กรุณาระบุ"),
});

export const branchValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  short_name: Yup.string().required("กรุณาระบุ"),
  phone: Yup.string()
    .required("กรุณาระบุ")
    .matches(phoneRegExp, "กรุณาระบุให้ถูกต้อง"),
  fax: Yup.string()
    .required("กรุณาระบุ")
    .matches(faxReqExp, "กรุณาระบุให้ถูกต้อง"),
  address: addressValidation,
});
