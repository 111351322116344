import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
  },
  {
    thaiLabel: "จำนวนรับคืนแล้ว",
    width: 100,
  },
  {
    thaiLabel: "จำนวนรอรับคืน",
    width: 100,
  },

  {
    thaiLabel: "จำนวนที่รับคืน",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
];

const defaultScanHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
  },
  {
    thaiLabel: "จำนวนรับคืนแล้ว",
    width: 100,
  },
  {
    thaiLabel: "จำนวนรอรับคืน",
    width: 100,
  },

  {
    thaiLabel: "จำนวนที่รับคืน",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "สถานที่",
    width: 200,
  },
  {
    thaiLabel: "ผู้สแกน",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 150,
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
  {
    thaiLabel: "วันที่ผลิต",
    width: 100,
  },
  {
    thaiLabel: "วันที่หมดอายุ",
    width: 100,
  },
];

const headerPdf: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 20,
  },
  // {
  //   thaiLabel: "รูป",
  //   width: 50,
  // },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 110,
  },
  {
    thaiLabel: "เลขที่เอกสาร",
    width: 75,
  },
  {
    thaiLabel: "จำนวนรับคืน - หน่วย",
    width: 105,
  },
  {
    thaiLabel: "Batch/SN",
    width: 120,
  },
  {
    thaiLabel: "สถานที่",
    width: 130,
  },
  {
    thaiLabel: "Barcode",
    width: 200,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 80,
  },
  {
    thaiLabel: "LOT",
    width: 70,
  },
];

export const useGoodsReturnItemList = (status: string, disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!disabled) {
      if (!status || status === "draft") {
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        setHeaders([
          ...defaultScanHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      }
    } else {
      if (!status || status === "draft") {
        setHeaders(defaultHeaders);
      } else {
        setHeaders(defaultScanHeaders);
      }
    }
  }, [disabled, status]);

  return headers;
};

export const useGoodsReturnItemListPdf = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders(headerPdf);
  }, []);

  return headers;
};
