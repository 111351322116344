import * as Yup from "yup";
import dayjs from "dayjs";
import { IPurchaseRequest } from "../../../../types/Purchase/purchaseRequest";
import { itemListPRSchema } from "../../Sales/itemListSchema";

export const purchaseRequestSchema: IPurchaseRequest = {
  unique_id: "",
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  issue_date: dayjs(),
  expected_date: dayjs(),
  within_date: dayjs(),
  last_updated_by: null,
  created_date: dayjs(),
  created_by: null,
  reference_no: "",
  related_user_list: [],
  requester: undefined,
  reference_document_list: [],
  remark: "",
  accepted_remark: "",
  tag_list: [],
  item_list: [],
  attachment_list: [],
  approval_step: 0,
  approver_list: [],
  reviewer_list: [],
};

export const purchaseRequestValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  // expected_date: Yup.date()
  // .required("กรุณาระบุต้องการภายในวันที่")
  // .typeError("กรุณาระบุเป็นวันที่")
  // .nullable(),
  requester: Yup.object().shape({
    name: Yup.string().required("กรุณาเลือกผู้ขอ"),
    department: Yup.string().required("กรุณาเลือกแผนก"),
    position: Yup.string().required("กรุณาเลือกตำแหน่ง"),
  }),
  item_list: Yup.array().of(itemListPRSchema).min(1, "กรุณาเพิ่มสินค้า"),
  branch_id: Yup.number().required("กรูณาเลือกสาขา"),
});
