import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDisable } from "../../../hooks/use-disable";
import { useCallback, useEffect } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";

import GoodsReceiveInfo from "../../../components/Form/Inventory/GoodsReceive/Info";
import { IGoodsReceive } from "../../../types/Inventory/GoodsReceive";
import {
  goodsReceiveSchema,
  goodsReceiveValidation,
} from "../../../components/Form/Inventory/GoodsReceive/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import GoodsReceiveItemList from "../../../components/Table/Inventory/GoodsReceive/ItemList";
import {
  GoodsReceiveCreateInput,
  GoodsReceiveQuery,
  GoodsReceiveUpdateInput,
  InventoryDocumentType,
  WmsModelType,
  WmsUniqueIdGenerateQuery,
  useGoodsReceiveCreateMutation,
  useGoodsReceiveQuery,
  useGoodsReceiveUpdateMutation,
  useInventoryDocumentCancelMutation,
  useWmsUniqueIdGenerateQuery,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  goodsReceiveCreateFormatter,
  goodsReceiveQueryFormatter,
  goodsReceiveUpdateFormatter,
} from "../../../utils/Formatter/Inventory/GoodsReceive";
import GoodsReceiveHeader from "../../../components/Form/Inventory/GoodsReceive/Header";
import GoodsReceiveHeaderBreadcrumbs from "../../../components/Form/Inventory/GoodsReceive/HeaderBreadcrumbs";
import { useActivityLog } from "../../../hooks/use-activity-log";
import {
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/general";
import { errorMessageFormatter } from "../../../utils/Global";

const GoodsReceiveContainer = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDisabled] = useDisable();
  const navigate = useNavigate();

  const { createActivityLog } = useActivityLog();

  const methods = useForm<IGoodsReceive>({
    defaultValues: goodsReceiveSchema,
    resolver: yupResolver<any>(goodsReceiveValidation),
  });

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const {
    fields,
    remove,
    update: updateFieldArray,
    replace,
  } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  useEffect(() => {
    if (!id && state) {
      reset({ ...state, created_date: dayjs(), posted_date: dayjs() });
    }
  }, [id, reset, state]);

  const status = useWatch({ control, name: "aggrid_status" });

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading, isSuccess, refetch } =
    useGoodsReceiveQuery<GoodsReceiveQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      { enabled: !!id }
    );

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: WmsModelType.GoodsReceive,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  const { mutateAsync: create, isLoading: isCreating } =
    useGoodsReceiveCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useGoodsReceiveUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancel, isLoading: isCanceling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const formatGR = useCallback(
    (goodsReceiveType: any) => {
      const formattedGoodsReceive =
        goodsReceiveQueryFormatter(goodsReceiveType);
      reset(formattedGoodsReceive);
      setDisabled(true);
    },
    [reset, setDisabled]
  );

  const getGoodsReceive = useCallback(async () => {
    if (isSuccess) {
      const { GoodsReceive } = data;
      const goodsReceiveType = GoodsReceive as IGoodsReceive;
      formatGR(goodsReceiveType);
      // const currentStep = formattedGoodsReceive.draft_step_no;
      // setStep(currentStep);
      // const allItemIds = formattedGoodsReceive.trace_entry_list.map(
      //   (item) => item.item_unique_id
      // );
      // setItemIds(allItemIds);
      // setItemIdsSnapshot(allItemIds);
    }
  }, [data, formatGR, isSuccess]);

  useEffect(() => {
    getGoodsReceive();
  }, [getGoodsReceive]);

  useEffect(() => {
    if (id) {
      setDisabled(true);
    } else generateUniqueId();
    return () => setDisabled(false);
  }, [id, setDisabled, generateUniqueId]);

  const draftHandler = async (data: IGoodsReceive) => {
    if (!validateQty()) return;

    try {
      const formatData = id
        ? goodsReceiveUpdateFormatter(data, "draft")
        : goodsReceiveCreateFormatter(data, "draft");

      const result: any = id
        ? await update({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsReceiveUpdateInput,
          })
        : await create({ createInput: formatData as GoodsReceiveCreateInput });

      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveUpdate?.id,
          activity_detail: {},
        });
      }

      if (!id)
        navigate(`/inventory/goods-receive/${result.GoodsReceiveCreate.id}`);

      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const saveHandler = async (data: IGoodsReceive) => {
    try {
      const payload = goodsReceiveUpdateFormatter(
        data,
        data.aggrid_status || ""
      ) as GoodsReceiveUpdateInput;
      const { GoodsReceiveUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.GoodsReceive,
        reference_id: GoodsReceiveUpdate?.id,
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const waitReceiveHandler = async (data: IGoodsReceive) => {
    if (!validateQty()) return;

    try {
      const formatData = id
        ? goodsReceiveUpdateFormatter(data, "wait_receive")
        : goodsReceiveCreateFormatter(data, "wait_receive");

      const result: any = id
        ? await update({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsReceiveUpdateInput,
          })
        : await create({ createInput: formatData as GoodsReceiveCreateInput });

      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_receive",
          },
        });
      } else {
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.main_status,
            curr_status: "wait_receive",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      }

      if (!id)
        navigate(
          `/inventory/goods-receive/${
            result.GoodsReceiveCreate.id ?? result.GoodsReceiveUpdate.id
          }`
        );
      await refetch();

      enqueueSnackbar(`${t("sentence.continue")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("sentence.continue")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const inTransitHandler = async (data: IGoodsReceive) => {
    try {
      const formatData = goodsReceiveUpdateFormatter(
        data,
        "in_transit"
      ) as GoodsReceiveUpdateInput;
      const { GoodsReceiveUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatData,
      });

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsReceive,
        reference_id: GoodsReceiveUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.main_status,
          curr_status: "in_transit",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });

      if (!id) navigate(`/inventory/goods-receive/${GoodsReceiveUpdate.id}`);
      await refetch();

      enqueueSnackbar(`${t("sentence.change_status")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("sentence.change_status")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const receiveHandler = async (data: IGoodsReceive) => {
    try {
      const formatData = goodsReceiveUpdateFormatter(
        data,
        "finished"
      ) as GoodsReceiveUpdateInput;
      const { GoodsReceiveUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatData,
      });

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsReceive,
        reference_id: GoodsReceiveUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.main_status,
          curr_status: "finished",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });

      if (!id) navigate(`/inventory/goods-receive/${GoodsReceiveUpdate.id}`);
      else {
        const goodsReceiveType = GoodsReceiveUpdate as IGoodsReceive;
        formatGR(goodsReceiveType);
      }

      enqueueSnackbar(`${t("inventory.goods_receive.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const message = errorMessageFormatter(err);
      if (message?.split(",").length > 0) {
        message.split(",").forEach((unique_id: string) =>
          enqueueSnackbar(`${unique_id} เกินจำนวนสั่งซื้อ`, {
            variant: "error",
          })
        );
      } else
        enqueueSnackbar(`${t("inventory.goods_receive.index")}ไม่สำเร็จ`, {
          variant: "error",
        });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancel({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryDocumentType.GoodsReceive,
      });
      await createActivityLog({
        activity_type: ActivityType.Cancel,
        document_type: ActivityLogDocumentType.GoodsReceive,
        reference_id: parseInt(id!),
        activity_detail: {},
      });
      await refetch();
      enqueueSnackbar(`${t("status.cancelled")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("status.cancelled")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const validateQty = () => {
    const checkBoolean: { item_unique_id: string; status: boolean }[] = [];
    const goodsReceive = getValues();
    const type = getValues("type");
    const formatData = goodsReceiveCreateFormatter(goodsReceive);

    if (type === "other") return true;

    formatData.trace_entry_list.forEach((list) => {
      if (list.qty > (list.document_item_qty || 0) - (list.posted_qty || 0))
        return checkBoolean.push({
          item_unique_id: list.item_unique_id,
          status: false,
        });
    });

    if (!checkBoolean.every((boolean) => boolean.status)) {
      checkBoolean.forEach((list) =>
        enqueueSnackbar(`${list.item_unique_id} เกินจำนวนใบสั่งซื้อ`, {
          variant: "error",
        })
      );
    }

    return checkBoolean.every((boolean) => boolean.status);
  };

  if (id && (isLoading || isCreating || isUpdating || isCanceling)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <GoodsReceiveHeaderBreadcrumbs cancelHandler={cancelHandler} />
        <GoodsReceiveHeader />
        <GoodsReceiveInfo
          generateUniqueId={generateUniqueId}
          replace={replace}
        />
        <GoodsReceiveItemList
          fields={fields}
          remove={remove}
          update={updateFieldArray}
          replace={replace}
        />
        <BottomNavbar>
          {(!status || status === "draft") && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(draftHandler)}
                // disabled={isCreating || isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.next")}
                onClick={handleSubmit(waitReceiveHandler)}
                // disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
          {["wait_receive", "in_transit"].includes(status) && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save")}
                onClick={handleSubmit(saveHandler)}
                // disabled={isCreating || isUpdating}
              />
              {status === "wait_receive" && (
                <CustomizedButton
                  variant="outlined"
                  title={t("status.in_transit")}
                  onClick={handleSubmit(inTransitHandler)}
                  // disabled={isCreating || isUpdating}
                />
              )}
              <CustomizedButton
                variant="contained"
                title={t("inventory.goods_receive.index")}
                onClick={handleSubmit(receiveHandler)}
                // disabled={isCreating || isUpdating}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
    </>
  );
};

export default GoodsReceiveContainer;
