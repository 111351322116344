import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { Grid, Typography } from "@mui/material";

import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledSelect from "../../Controller/ControlledSelect";
import { ISelectOption } from "../../../types/global";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import { PriceVatType } from "../../../generated/sales";
import { IPriceList } from "../../../types/Inventory/PriceList";
import { ISalesItemList } from "../../../types/Sales";
import { priceListEnumerator } from "../../../utils/Formatter/Sales";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import { priceVatTypeFormatter } from "../../../utils/Formatter/Global";
import { useParams } from "react-router-dom";

const priceVatTypeOptions: ISelectOption[] = [
  {
    label: "ราคารวมภาษี",
    value: PriceVatType.IncludeVat,
  },
  {
    label: "ราคาแยกภาษี",
    value: PriceVatType.ExcludeVat,
  },
];

const currencyOptions: ISelectOption[] = [
  {
    label: "THB",
    value: "THB",
  },
];

type Props = {
  forceDisabled?: boolean;
  documentType?: string;
};

const SalesInfo = ({ forceDisabled, documentType }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { id } = useParams();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const {
    saleTypesOption,
    saleChannelsOption,
    branchesOptions,
    itemPriceListOption,
  } = useSalesOption();

  const is_hq = sessionStorage.getItem("is_hq");
  const isReferenceDocumentList = getValues("reference_document_list");

  const salesChannelChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value) {
      const salesChannel = saleChannelsOption.find(
        (channel) => channel.value === value
      );
      if (salesChannel) {
        setValue("sales_channel", salesChannel);
        const foundActivePriceList = salesChannel?.item_price_list?.find(
          (price: IPriceList) => price.is_active
        );
        if (foundActivePriceList) {
          setValue("item_price_list", foundActivePriceList);
          setValue("item_price_list_id", foundActivePriceList.id);
          const currentItemList: ISalesItemList[] = getValues("item_list");
          priceListEnumerator(foundActivePriceList, currentItemList, setValue);
        } else {
          setValue("item_price_list", undefined);
          setValue("item_price_list_id", 0);
        }
      } else {
        setValue("sales_channel", undefined);
      }
    }
  };
  const referenceDocumentDisabled =
    documentType === "cash_sales" &&
    isReferenceDocumentList &&
    isReferenceDocumentList.length > 0;
  const itemPriceListChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value) {
      const itemPriceList: IPriceList = itemPriceListOption.find(
        (price) => price.value === value
      )?.actual_value;

      if (itemPriceList) {
        setValue("item_price_list", itemPriceList);
        const currentItemList: ISalesItemList[] = getValues("item_list");
        priceListEnumerator(itemPriceList, currentItemList, setValue);
      } else {
        setValue("item_price_list", undefined);
      }
    }
  };

  const priceDisabled =
    Boolean(id) &&
    (disabled || (status !== "draft" && status !== "not_approved"));

  return (
    <Fragment>
      <CustomizedBox margin={"1.5rem 0rem"}>
        <Typography fontWeight={600} mb={2} color={"primary.main"}>
          {t("sales.sales_description")}
        </Typography>
        <Grid container spacing={1} mt={1}>
          {documentType !== "customer_deposit" && (
            <>
              <Grid item sm={12} md={4}>
                {disabled ? (
                  <LabelInput
                    label={t("sales.sales_type")}
                    value={getValues("sales_type")}
                  />
                ) : (
                  <ControlledSelect
                    control={control}
                    name="sales_type"
                    label={t("sales.sales_type")}
                    options={saleTypesOption}
                    error={Boolean(errors.sales_type)}
                    helperText={errors?.sales_type?.message?.toString()}
                    disabled={forceDisabled}
                  />
                )}
              </Grid>
              <Grid item sm={12} md={4}>
                {disabled ? (
                  <LabelInput
                    label={t("sales.sales_channel")}
                    value={getValues("sales_channel")?.name_th || ""}
                  />
                ) : (
                  <ControlledSelect
                    control={control}
                    name="sales_channel_id"
                    label={t("sales.sales_channel")}
                    options={saleChannelsOption}
                    onChange={salesChannelChangeHandler}
                    error={Boolean(errors.sales_channel_id)}
                    helperText={errors?.sales_channel_id?.message?.toString()}
                    disabled={
                      forceDisabled ||
                      priceDisabled ||
                      referenceDocumentDisabled
                    }
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item sm={12} md={4}>
            {disabled ? (
              <LabelInput
                label={t("branch.index")}
                value={getValues("branch")?.name || ""}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="branch_id"
                label={t("branch.index")}
                options={branchesOptions}
                disabled={
                  is_hq !== "true" ||
                  (status &&
                    status !== "draft" &&
                    status !== "wait_approve" &&
                    status !== "not_approved") ||
                  forceDisabled ||
                  referenceDocumentDisabled
                }
                error={Boolean(errors.branch_id)}
                helperText={errors?.branch_id?.message?.toString()}
                required
              />
            )}
          </Grid>
          <Grid item sm={12} md={4}>
            {disabled ? (
              <LabelInput
                label={t("sales.price_vat_type")}
                value={priceVatTypeFormatter(getValues("price_vat_type"))}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="price_vat_type"
                label={t("sales.price_vat_type")}
                options={priceVatTypeOptions}
                required
                error={Boolean(errors.price_vat_type)}
                helperText={errors?.price_vat_type?.message?.toString()}
                disabled={
                  forceDisabled || priceDisabled || referenceDocumentDisabled
                }
              />
            )}
          </Grid>
          <Grid item sm={12} md={4}>
            {disabled ? (
              <LabelInput
                label={t("global.currency")}
                value={getValues("currency")}
              />
            ) : (
              <ControlledSelect
                control={control}
                name="currency"
                label={t("global.currency")}
                options={currencyOptions}
                disabled
                required
                error={Boolean(errors.currency)}
                helperText={errors?.currency?.message?.toString()}
              />
            )}
          </Grid>
          {documentType !== "customer_deposit" && (
            <Grid item sm={12} md={4}>
              {disabled ? (
                <LabelInput
                  label={t("sales.price_list.index")}
                  value={getValues("item_price_list")?.name || ""}
                />
              ) : (
                <ControlledSelect
                  control={control}
                  name="item_price_list_id"
                  label={t("sales.price_list.index")}
                  options={itemPriceListOption}
                  onChange={itemPriceListChangeHandler}
                  required
                  error={Boolean(errors.item_price_list_id)}
                  helperText={errors?.item_price_list_id?.message?.toString()}
                  disabled={
                    forceDisabled || priceDisabled || referenceDocumentDisabled
                  }
                />
              )}
            </Grid>
          )}
        </Grid>
      </CustomizedBox>
    </Fragment>
  );
};

export default SalesInfo;
