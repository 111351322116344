import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { numberFormatter } from "../../../../utils/Formatter/Global";

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

type Props = {
  paymentAmount: number;
  deductionAmount: number;
  withholdingTaxAmount: number;
  netAmount: number;
};

const PaymentTabSummary = ({
  paymentAmount,
  deductionAmount,
  withholdingTaxAmount,
  netAmount,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const paidNetAmount: number = paymentAmount + deductionAmount;

  const paidTotalAmount = paidNetAmount + withholdingTaxAmount;

  const remainingPayment =
    parseFloat(netAmount.toFixed(2)) - parseFloat(paidTotalAmount.toFixed(2));

  const summaryList: ISummary[] = [
    {
      label: "มูลค่าชำระ",
      amount: paidNetAmount,
    },
    {
      label: "มูลค่าถูกหัก ณ ที่จ่าย",
      amount: withholdingTaxAmount,
    },
    {
      label: "มูลค่าสุทธิ",
      amount: netAmount,
    },
    {
      name: "paid_total_amount",
      label: "ชำระรวมทั้งสิ้น",
      amount: paidTotalAmount,
    },
    {
      label: "ต้องชำระอีก",
      amount: remainingPayment,
    },
  ];

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={12} md={6.5} lg={6.5} xl={6.5} />
      <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
        {summaryList.map((item) => (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1.5,
                gap: 3,
                py: item.name === "paid_total_amount" ? 1 : 0,
              }}
              bgcolor={
                item.name === "paid_total_amount" ? "primary.light" : undefined
              }
            >
              <Typography
                color={"primary.main"}
                textAlign={isMobile ? "left" : "right"}
                flex={2}
                fontWeight={item.name === "paid_total_amount" ? 600 : 400}
              >
                {item.label}
              </Typography>
              <Typography
                color={"primary.main"}
                fontWeight={item.name === "paid_total_amount" ? 600 : 400}
                sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
              >
                {numberFormatter(item.amount)}
              </Typography>
              <Typography
                color={"primary.main"}
                sx={{ ml: 2.5, mr: 4.7 }}
                fontWeight={item.name === "paid_total_amount" ? 600 : 400}
              >
                บาท
              </Typography>
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default PaymentTabSummary;
