import SalesReturnTable from "../../../components/Table/Sales/Return";

type Props = {
  referenceUniqueId?: string;
};

const SalesReturnTab = ({ referenceUniqueId }: Props) => {
  return <SalesReturnTable referenceUniqueId={referenceUniqueId} />;
};

export default SalesReturnTab;
