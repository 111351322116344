import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Box, Typography } from "@mui/material";
import { numberFormatter } from "../../../../utils/Formatter/Global";

interface Props {
  control: IDefaultForm["control"];
}

const SumPreVatAmount = ({ control }: Props) => {
  const sumPreVatAmount =
    useWatch({
      control,
      name: "pre_vat_amount",
    }) || 0;
  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        alignItems: "center",
        gap: 3,
        height: "2rem",
      }}
      bgcolor={"#F0F1FA"}
      width="fit-content"
      marginLeft="auto"
      justifyItems="flex-end"
      borderRadius={1}
    >
      <>
        <Typography
          color={"primary.main"}
          textAlign={"right"}
          flex={2}
          minWidth={50}
        >
          รวม
        </Typography>
        <Typography
          color={"primary.main"}
          variant="subtitle1"
          sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
        >
          {numberFormatter(sumPreVatAmount)}
        </Typography>
      </>
      <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 4 }}>
        บาท
      </Typography>
    </Box>
  );
};

export default SumPreVatAmount;
