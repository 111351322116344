import React from "react";
import CustomizedChips from "../../../Custom/CustomizedChips";

interface VariantChipProps {
  value: string;
  index: number;
  removeVariant: (index: number) => void;
  type: string;
  key: string;
  handleOpen: (chip: string, type: string, index: number, key: string) => void;
  isEditVariant: boolean;
  firstOptions: any;
  secondOptions: any;
  thirdOptions: any;
  watchVariantList: any;
}

const VariantChip: React.FC<VariantChipProps> = ({
  value,
  index,
  removeVariant,
  type,
  key,
  handleOpen,
  isEditVariant,
  firstOptions,
  secondOptions,
  thirdOptions,
  watchVariantList,
}) => {
  const handleDelete = () => {
    removeVariant(index);
  };

  const RenderConditionChip = () => {
    let deleteable: boolean = false;
    switch (type) {
      case "first":
        deleteable =
          (secondOptions.length > 0 && firstOptions.length > 1) ||
          secondOptions.length === 0;
        break;
      case "second":
        deleteable =
          (thirdOptions.length > 0 && secondOptions.length > 1) ||
          thirdOptions.length === 0;
        break;
      case "third":
        deleteable = true;
        break;
      default:
        deleteable = true;
    }
    if (watchVariantList.length === 0) {
      if (deleteable)
        return (
          <CustomizedChips
            value={value}
            sx={{ marginRight: "0.5rem" }}
            handleDelete={handleDelete}
          />
        );
      else
        return <CustomizedChips value={value} sx={{ marginRight: "0.5rem" }} />;
    } else {
      if (deleteable && !isEditVariant)
        return (
          <CustomizedChips
            value={value}
            sx={{ marginRight: "0.5rem" }}
            handleDelete={() => handleOpen(value, type, index, key)}
          />
        );
      else
        return <CustomizedChips value={value} sx={{ marginRight: "0.5rem" }} />;
    }
  };

  return <RenderConditionChip />;
};

export default VariantChip;
