import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { IGlobalSetting } from "../../../types/global";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
// import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
// import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GeneralModelType,
  GeneralUniqueIdGenerateQuery,
  useGeneralUniqueIdGenerateQuery,
} from "../../../generated/general";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: IGlobalSetting) => void;
  onUpdateHandler: (data: IGlobalSetting) => void;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
  type: GeneralModelType;
};

const globalSettingSchema: IGlobalSetting = {
  unique_id: "",
  name_th: "",
  name_en: "",
  description: "",
};

const globalSettingValidate = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name_th: Yup.string().required("กรุณาระบุ"),
  name_en: Yup.string().required("กรุณาระบุ"),
  description: Yup.string(),
});

const GlobalSettingModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  data,
  isLoading,
  isSuccess,
  type,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const id = searchParams.get("id");
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm({
    defaultValues: globalSettingSchema,
    resolver: yupResolver(globalSettingValidate),
  });

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: type,
      },
      {
        enabled: false,
      }
    );

  const onClose = () => {
    reset(globalSettingSchema);
    closeModalHandler();
  };

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (open && !id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id, open]);

  useEffect(() => {
    if (isSuccess) {
      reset(data);
    }
  }, [data, isSuccess, reset]);

  return (
    <ModalUI
      isLoading={Boolean(id && isLoading)}
      title={id ? t("sentence.edit") : t("button.add")}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {id ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={() => {
                handleSubmit(onUpdateHandler)();
                if (isValid) {
                  onClose();
                }
              }}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={() => {
                handleSubmit(onAddHandler)();
                if (isValid) {
                  onClose();
                }
              }}
            />
          )}
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.unique_id")}
            control={control}
            name="unique_id"
            error={Boolean(errors?.unique_id)}
            helperText={
              errors?.unique_id && errors.unique_id.message?.toString()
            }
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.name_th")}
            control={control}
            name="name_th"
            error={Boolean(errors?.name_th)}
            helperText={errors?.name_th && errors.name_th.message?.toString()}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.name_en")}
            control={control}
            name="name_en"
            error={Boolean(errors?.name_en)}
            helperText={errors?.name_en && errors.name_en.message?.toString()}
            required
          />
        </Grid>
      </Grid>
      <Typography
        color="primary.main"
        fontWeight={600}
        fontSize={14}
        mt={3}
        mb={2}
      >
        คำอธิบาย
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.description")}
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={
              errors?.description && errors.description.message?.toString()
            }
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default GlobalSettingModal;
