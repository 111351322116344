import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import CustomizedAvatar from "../Custom/CustomizedAvatar";
import {
  IContact,
  IContactPerson,
  ISalesPerson,
} from "../../types/Contact/contact";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useDisable } from "../../hooks/use-disable";
import { useModal } from "../../hooks/use-modal";
import PersonModal from "./Modal/PersonModal";
import { UseFieldArrayRemove, UseFieldArrayUpdate } from "react-hook-form";

type Props = {
  person: IContactPerson | ISalesPerson;
  remove: UseFieldArrayRemove;
  index: number;
  update: UseFieldArrayUpdate<IContact, any>;
  isSalesPerson?: boolean;
};

const PersonCard = ({
  person,
  remove,
  index,
  update,
  isSalesPerson,
}: Props) => {
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const [anchorPos, setAnchorPos] = useState(null);
  const [disabled] = useDisable();

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const updateContactPersonHandler = (data: IContactPerson) => {
    update(index, data);
  };

  const showActions = Boolean(anchorPos);

  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        {!isSalesPerson && (
          <MenuItem
            onClick={() => {
              closeActionsHandler();
              openModalHandler();
            }}
          >
            {t("contact.utility.edit")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            closeActionsHandler();
            remove(index);
          }}
        >
          {t("contact.utility.delete")}
        </MenuItem>
      </Menu>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "14px",
          cursor: disabled ? "pointer" : "default",
          backgroundColor: "primary.light",
        }}
        onClick={disabled ? openModalHandler : undefined}
      >
        <CardContent
          sx={{
            "&:last-child": {
              p: 2,
            },
            p: 2,
          }}
        >
          <Box display="flex" gap={2} justifyContent="space-between">
            <Box display="flex" gap={2} alignItems="center">
              <CustomizedAvatar
                avatars={[
                  {
                    unique_id: person.first_name + person.last_name,
                    first_name: person.first_name,
                    last_name: person.last_name,
                  },
                ]}
              />
              <Box>
                <Typography fontWeight={600} color="primary.main">
                  {`${person.title_name}${person.first_name} ${person.last_name}`}
                </Typography>
                <Typography>{person.position}</Typography>
              </Box>
            </Box>
            {!disabled && (
              <IconButton
                onClick={openActionsHandler}
                sx={{
                  height: "fit-content",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <MoreVertOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
      <PersonModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onUpdateHandler={updateContactPersonHandler}
        data={person as IContactPerson}
        isEdit
      />
    </Grid>
  );
};

export default PersonCard;
