import { CustomizedBox } from "../../../../components/Custom/CustomizedBox";
import ControlledCheckbox from "../../../../components/Controller/ControlledCheckbox";
import { Box, Grid } from "@mui/material";
import ControlledTextField from "../../../../components/Controller/ControlledTextField";
import ControlledAddressBox from "../../../../components/Controller/ControlledAddressBox";
import LabelInput from "../../../../components/UI/LabelInput";
import ControlledSelect from "../../../../components/Controller/ControlledSelect";
import { useDisable } from "../../../../hooks/use-disable";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IWarehouse } from "../../../../types/Setting/Inventory";
import { countryOptions } from "../../../../utils/Global";
import { countryFormatter } from "../../../../utils/Formatter/Global";
import { useTranslation } from "react-i18next";
import { useBranchFindUniqueQuery } from "../../../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

type Props = {
  address: any;
};
const WarehouseAddress = ({ address }: Props) => {
  const {
    control,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IWarehouse>();
  const [disabled] = useDisable();
  const [addressValue, setAddressValue] = useState<any>("");

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const branch_id = useWatch({
    control,
    name: "branch_id",
  });

  const { data: branch } = useBranchFindUniqueQuery(
    graphQLClient,
    {
      uniqueInput: {
        id: branch_id,
      },
    },
    {
      enabled: !!branch_id,
    }
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (disabled) {
      setAddressValue(address);
    }
  }, [address, disabled]);

  const clearErrorsHandler = () => {
    clearErrors([
      "address.sub_district",
      "address.district",
      "address.province",
      "address.postal_code",
    ]);
  };

  const branchAddress = branch?.BranchFindUnique?.address;

  const isSameAsBranch = useWatch({
    control,
    name: "address.is_same_as_branch",
  });

  useEffect(() => {
    if (isSameAsBranch && branchAddress) {
      const newAddressValue = {
        ...branchAddress,
        is_same_as_branch: true,
      };
      setValue("address", newAddressValue);
      setAddressValue(newAddressValue);
    }
  }, [branchAddress, isSameAsBranch, setValue]);

  return (
    <CustomizedBox
      bgcolor="primary.light"
      margin={"1.5rem 0 0 0"}
      padding={"1rem"}
    >
      <Box mb={1}>
        <ControlledCheckbox
          control={control}
          name="address.is_same_as_branch"
          label="ใช้ที่อยู่ตามสาขา"
          disabled={disabled}
        />
      </Box>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            name={`address.address_name`}
            control={control}
            label={t("contact.address.index")}
            error={Boolean(errors.address?.address_name)}
            helperText={errors.address && errors.address.address_name?.message}
            rows={4}
            multiline
            disabled={disabled}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            name={`address`}
            type="sub_district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={disabled}
            error={Boolean(errors.address?.sub_district)}
            helperText={errors.address && errors.address.sub_district?.message}
            clearErrorsHandler={clearErrorsHandler}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            name={`address`}
            type="district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={disabled}
            error={Boolean(errors.address?.district)}
            helperText={errors.address && errors.address.district?.message}
            clearErrorsHandler={clearErrorsHandler}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            name={`address`}
            type="province"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={disabled}
            error={Boolean(errors.address?.province)}
            helperText={errors.address && errors.address.province?.message}
            clearErrorsHandler={clearErrorsHandler}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            name={`address`}
            type="postal_code"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={disabled}
            error={Boolean(errors.address?.postal_code)}
            helperText={errors.address && errors.address.postal_code?.message}
            clearErrorsHandler={clearErrorsHandler}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("contact.address.country")}
              value={countryFormatter(getValues("address.country"))}
            />
          ) : (
            <ControlledSelect
              name={`address.country`}
              control={control}
              label={t("contact.address.country")}
              error={Boolean(errors.address?.country)}
              helperText={errors.address && errors.address.country?.message}
              options={countryOptions}
              disabled={true}
            />
          )}
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default WarehouseAddress;
