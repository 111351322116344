import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
// import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RadioBox from "../../UI/RadioBox";
import ImporterDropzoneUI from "../../UI/ImporterDropzoneUI";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const UserImportUploader = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const type = watch("type");

  const downloadTemplateHandler = () => {
    const filePath = `/static/importer/Template_ผู้ใช้งาน.xlsx`;
    window.open(filePath, "_blank");
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomizedButton
            title={t("inventory.items.import.download_template")}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={downloadTemplateHandler}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <CustomizedTooltip title="">
            <InfoOutlinedIcon />
          </CustomizedTooltip>
        </Grid> */}
        <Grid item xs={12} md={8} />
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "create");
            }}
            label={t("button.create")}
            checked={type === "create"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "update");
            }}
            label={t("button.edit")}
            checked={type === "update"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12}>
          <ImporterDropzoneUI hasLabelHeader />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserImportUploader;
