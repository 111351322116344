import { Grid, IconButton, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  UseFieldArrayReplace,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";
import { useCallback, useEffect, useState } from "react";
import { ISelectOption } from "../../../../types/global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import useInventoryControl from "../../../../hooks/Inventory/use-inventory-control";
import { IGoodsIssue } from "../../../../types/Inventory/GoodsIssue";
import LabelInput from "../../../UI/LabelInput";
import {
  WmsModelType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useParams } from "react-router-dom";
import { goodsIssueTypeValueFormatter } from "../../../../utils/Formatter/Inventory/GoodsIssue";
import { branchInfoFromSessionStorage } from "../../../../utils/Global";

const typeOptions: ISelectOption[] = [
  {
    label: "ขาย",
    value: "sales_order",
  },
  {
    label: "ขายออนไลน์",
    value: "online",
  },
  {
    label: "ส่งคืน",
    value: "purchase_return",
  },
  {
    label: "สินค้าเสีย",
    value: "goods_damaged",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

// interface Props {
//   viewMode: boolean;
// }

type Props = {
  replace: UseFieldArrayReplace<IGoodsIssue, "trace_entry_list">;
};

const GoodsIssueInfo = ({ replace }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const [disabled] = useDisable();
  const [currentType, setCurrentType] = useState<string>("");

  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: WmsModelType.GoodsIssue,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  // const [currentReference, setCurrentReference] = useState<string>("");
  // const [referenceUniqueIdOptions, setReferenceUniqueIdOptions] = useState<
  //   ISelectOption[]
  // >([]);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchBranchId = useWatch({
    control,
    name: "branch_id",
  });

  const { branchesOptions, warehousesOptions } =
    useInventoryControl(watchBranchId);

  const onTypeChangeHandler = () => {
    replace([]);
    setValue("type", currentType);
    setValue("reference_unique_id", "");
  };

  // const { fields, append, remove } = useFieldArray({
  //   name: "reference_unique_id_list",
  //   control,
  // });

  // const addReferenceUniqueIdHandler = () => {
  //   append("");
  // };

  // const removeReferenceUniqueIdHandler = (index: number) => {
  //   remove(index);
  // };

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={11} sm={11} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="unique_id"
            control={control}
            label={t(`sentence.unique_id`)}
            error={Boolean(errors.unique_id)}
            helperText={errors.unique_id?.message?.toString()}
            disabled={
              status === "draft" ||
              status === "wait_approve" ||
              status === "not_approved" ||
              status === "wait_issue"
            }
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={1} sm={1} md={8} lg={8} xl={8}>
          {!id && (
            <CustomizedTooltip
              title={`เรียก${t(`sentence.unique_id`)}`}
              enterNextDelay={200}
            >
              <IconButton onClick={generateUniqueId} color="primary">
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("sentence.type")}
              value={goodsIssueTypeValueFormatter(getValues("type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="type"
              label={t("sentence.type")}
              options={typeOptions}
              disabled={status === "wait_approve" || status === "wait_issue"}
              onChange={(e: any) => {
                setCurrentType(e.target.value);
                const currentTrace = getValues("trace_entry_list");
                if (currentTrace.length > 0) {
                  onTypeChangeHandler();
                }
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("branch.index")}
              value={getValues("branch.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="branch_id"
              label={t("branch.index")}
              options={
                is_hq === "true"
                  ? branchesOptions
                  : branchesOptions.filter(
                      (branch) => branch.value === parseInt(branch_id ?? "0")
                    )
              }
              disabled={status === "wait_approve" || status === "wait_issue"}
              onChange={() => {
                setValue("warehouse_id", "");
              }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.warehouse")}
              value={getValues("warehouse.name")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="warehouse_id"
              label={t("inventory.warehouse")}
              options={warehousesOptions}
              disabled={
                status === "wait_approve" ||
                status === "wait_issue" ||
                warehousesOptions.length === 0
              }
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("sentence.reference_unique_id")}
              value={getValues("reference_unique_id")}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="reference_unique_id"
              label={t("sentence.reference_unique_id")}
              options={[]}
              disabled={disabled || !watchType || watchType === "other"}
              required={watchType && watchType !== "other"}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            label={t("inventory.remark")}
            disabled={disabled}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default GoodsIssueInfo;
