import {
  ColDef,
  GridReadyEvent,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Date";
import AgGrid from "../../../UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { TagsQuery, useTagsQuery } from "../../../../generated/general";
import { TagDocumentType } from "../../../../generated/inventory";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { NO_TRACE_ENTRY_REPORT_VIEW } from "../../../../services/AgGrid/Report/InventoryReportAggrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const NoTraceEntryReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useTagsQuery<TagsQuery>(graphQLClientGeneral, {
    findManyInput: {
      document_type: TagDocumentType.Item,
    },
  });

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "description",
      headerName: t("inventory.items.description"),
      filter: "agTextColumnFilter",
    },
    {
      field: "purchase_ordered_qty",
      headerName: t("inventory.quantities.purchase_ordered_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "sale_committed_qty",
      headerName: t("inventory.quantities.sale_committed_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    // {
    //   field: "category_group",
    //   headerName: t("inventory.category_group"),
    //   filter: "agTextColumnFilter",
    // },
    // {
    //   field: "main_category",
    //   headerName: t("inventory.main_category"),
    //   filter: "agTextColumnFilter",
    // },
    // {
    //   field: "sub_category",
    //   headerName: t("inventory.sub_category"),
    //   filter: "agTextColumnFilter",
    // },
    {
      field: "tag_list",
      headerName: t("inventory.items.tag_list"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          // fetch values from server
          const { data: tagListResult } = await refetch();
          const values = tagListResult?.Tags;
          const formatValues = values?.map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value)
          return params.value?.map((tag: string) => (
            <Box key={tag} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips value={`${tag}`} />
            </Box>
          ));
      },
    },
    {
      field: "is_active",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) =>
          value ? "ใช้งาน" : "หยุดใช้งาน",
      },
      cellRenderer: ({ value }: { value: number }) => {
        return <CustomizedStatus status={value ? "active" : "inactive"} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { created_date, tag_list, is_active } = filterModel;

      const formatFilter = {
        ...filterModel,
        created_date: dateFilterModel(created_date),
        is_active: is_active && {
          filterType: "setBoolean",
          values: is_active.values.map((v: string) => v === "1"),
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
      };
      try {
        const { NoTraceEntryReportView } = await graphQLClient.request(
          NO_TRACE_ENTRY_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: NoTraceEntryReportView.results,
          rowCount: NoTraceEntryReportView.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default NoTraceEntryReportTable;
