import * as Yup from "yup";
import dayjs from "dayjs";
import { IGoodsReturn } from "../../../../types/Inventory/GoodsReturn";
import { Tracability } from "../../../../generated/inventory";

export const goodsReturnSchema: IGoodsReturn = {
  unique_id: "",
  type: "",
  reference_unique_id: "",
  warehouse_id: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: null,
  branch_id: undefined,
  flag_status: [],
  main_status: "",
  aggrid_status: "",
};

const goodsReturnTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่รับคืน")
    .required("กรุณาระบุจำนวนที่รับคืน"),
});

const goodsReturnTraceValidationInitial = Yup.object().shape({
  qty: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
});

const goodsReturnSerialValidation = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .min(1, "กรุณาระบุจำนวนที่รับคืน")
          .required("กรุณาระบุจำนวนที่รับคืน")
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsReturnTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

const goodsReturnSerialValidationInitial = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number().transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsReturnTraceValidationInitial)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsReturnValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  warehouse_id: Yup.string().required("กรุณาระบุคลัง"),
  reference_unique_id: Yup.string().when("type", (type: any) => {
    if (type[0] && type[0] !== "other") {
      return Yup.string().required("กรุณาระบุเอกสารอ้างอิง");
    } else {
      return Yup.string();
    }
  }),
  remark: Yup.string().required("กรุณาระบุหมายเหตุ"),
  trace_entry_list: Yup.array().when("aggrid_status", {
    is: (v: any) => {
      return v !== "" && v !== "draft";
    },
    then: (schema) =>
      schema.of(goodsReturnSerialValidation).min(1, "กรุณาเพิ่มสินค้า"),
    otherwise: (schema) => schema.of(goodsReturnSerialValidationInitial),
  }),
});
