import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const getDefaultHeader = (): IHeaderTable[] => {
  return [
    {
      thaiLabel: "ลำดับ",
      width: 75,
    },
    {
      thaiLabel: "วันที่และเวลา",
      width: 120,
    },
    {
      thaiLabel: "รูป",
      width: 100,
    },
    {
      thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
      width: 150,
      align: "left",
    },
    {
      thaiLabel: "จำนวนคงคลัง",
      width: 100,
    },
    {
      thaiLabel: "จำนวนที่ปรับปรุง",
      width: 100,
    },
    {
      thaiLabel: "จำนวนคงคลังใหม่",
      width: 150,
    },
    {
      thaiLabel: "หน่วย",
      width: 100,
    },
    {
      thaiLabel: "Barcode/SN",
      width: 150,
    },
    {
      thaiLabel: "สถานที่",
      width: 200,
    },
    {
      thaiLabel: "ผู้สแกน",
      width: 100,
    },
    {
      thaiLabel: "Barcode",
      width: 300,
      align: "left",
    },
    {
      thaiLabel: "หมายเหตุ",
      width: 150,
    },
    {
      thaiLabel: "LOT",
      width: 100,
    },
    {
      thaiLabel: "วันที่ผลิต",
      width: 100,
    },
    {
      thaiLabel: "วันที่หมดอายุ",
      width: 100,
    },
  ];
};

export const useGoodsAdjustmentItemList = (isNotDraft: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (isNotDraft) {
      setHeaders([...getDefaultHeader()]);
    } else {
      setHeaders([
        ...getDefaultHeader(),
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    }
  }, [isNotDraft]);

  return headers;
};
