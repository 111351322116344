import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import { IUser } from "../../../types/Auth/user";
import { useUserAgGrid } from "../../../hooks/use-user-aggrid";

const useUserColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { departmentFilterParams, positionFilterParams, branchesFilterParams } =
    useUserAgGrid();
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("user.account.id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "img_url",
        headerName: t("user.account.img_url"),
        filter: false,
        floatingFilter: false,
        cellRenderer: (params: ICellRendererParams<IUser, string[]>) => {
          if (params.value && params.value.length) {
            return (
              <CustomizedAvatar
                avatars={params.value.map((v) => ({
                  img_url: v,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.first_name ?? "",
                  last_name: params?.data?.last_name ?? "",
                }))}
              />
            );
          } else
            return (
              <CustomizedAvatar
                avatars={[
                  {
                    img_url: undefined,
                    unique_id: params?.data?.unique_id ?? "",
                    first_name: params?.data?.first_name ?? "",
                    last_name: params?.data?.last_name ?? "",
                  },
                ]}
              />
            );
        },
        minWidth: 200,
        // flex: 1,
      },
      {
        field: "title_name",
        headerName: t("user.account.title_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "first_name",
        headerName: t("user.account.first_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "last_name",
        headerName: t("user.account.last_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "department",
        headerName: t("user.account.department"),
        filter: "agSetColumnFilter",
        filterParams: departmentFilterParams,
      },
      {
        field: "position",
        headerName: t("user.account.position"),
        filter: "agSetColumnFilter",
        filterParams: positionFilterParams,
      },
      {
        field: "branch_id",
        filter: "agNumberColumnFilter",
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "branch_name",
        headerName: t("branch.index"),
        filter: "agSetColumnFilter",
        filterParams: branchesFilterParams,
      },
      {
        field: "status",
        headerName: t("user.account.status"),
        filterParams: {
          values: [1, 0],
          valueFormatter: ({ value }: { value: number }) =>
            value ? "ใช้งาน" : "หยุดใช้งาน",
        },
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive"} />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return columnDefs;
};

export default useUserColumnDefs;
