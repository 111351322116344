import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";

import { ITab } from "../../../types/global";

import ItemTag from "../../../components/Form/Inventory/Item/Tag";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedScrollbar from "../../../components/Custom/CustomizedScrollbar";
import ItemInformation from "../../../components/Form/Inventory/Item/Information";
import General from "../../../components/Form/Inventory/Item/General";
import Logistic from "../../../components/Form/Inventory/Item/Logistic";
import Sales from "../../../components/Form/Inventory/Item/Sales";
import Purchase from "../../../components/Form/Inventory/Item/Purchase";
import Stock from "../../../components/Form/Inventory/Item/Stock";
import Insurance from "../../../components/Form/Inventory/Item/Insurance";
import Margetting from "../../../components/Form/Inventory/Item/Margetting";
import Attribute from "../../../components/Form/Inventory/Item/Attribute";
import Warehouse from "../../../components/Form/Inventory/Item/Warehouse";

const DetailTab = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("subtab");

  const { control, getValues } = useFormContext();

  const itemType = useWatch({ control, name: "type" });

  const tabs: ITab[] = [
    {
      testid: "item-tab-general",
      label: t("inventory.items.general"),
      path: `${pathname}?tab=item&subtab=general`,
    },
    {
      testid: "item-tab-logistic",
      label: t("inventory.items.logistic"),
      path: `${pathname}?tab=item&subtab=logistic`,
    },
    {
      testid: "item-tab-purchase",
      label: t("inventory.items.purchase"),
      path: `${pathname}?tab=item&subtab=purchase`,
    },
    {
      testid: "item-tab-sales",
      label: t("inventory.items.sales"),
      path: `${pathname}?tab=item&subtab=sales`,
    },
    {
      testid: "item-tab-warehouse",
      label: t("inventory.warehouse"),
      path: `${pathname}?tab=item&subtab=warehouse`,
    },
    {
      testid: "item-tab-stock",
      label: t("inventory.items.stock"),
      path: `${pathname}?tab=item&subtab=stock`,
    },
    {
      testid: "item-tab-insurance",
      label: t("inventory.items.insurance"),
      path: `${pathname}?tab=item&subtab=insurance`,
    },
    {
      testid: "item-tab-attribute",
      label: t("inventory.items.attribute"),
      path: `${pathname}?tab=item&subtab=attribute`,
    },
    {
      testid: "item-tab-margetting",
      label: t("inventory.items.margetting"),
      path: `${pathname}?tab=item&subtab=margetting`,
    },
  ];

  const currentTab =
    pathname + (tab ? `?tab=item&subtab=${tab}` : "?tab=item&subtab=general");

  const renderTab = () => {
    switch (tab) {
      case "general":
        return <General />;
      case "logistic":
        return <Logistic />;
      case "sales":
        return <Sales />;
      case "purchase":
        return <Purchase />;
      case "warehouse":
        return <Warehouse />;
      case "stock":
        return <Stock />;
      case "insurance":
        return <Insurance />;
      case "attribute":
        return <Attribute />;
      case "margetting":
        return <Margetting />;
      default:
        return <General />;
    }
  };

  const subTabsOptions = () => {
    if (!getValues("is_stockable")) tabs.splice(5, 1);
    if (["normal", "variation"].includes(itemType)) return tabs;
    else if (itemType === "bundle") {
      tabs.splice(2, 1);
      tabs.splice(4, 2);
      return tabs;
    } else if (itemType === "service")
      return [tabs[0], tabs[2], tabs[3], tabs[4]];
    else return tabs;
  };

  return (
    <Fragment>
      <ItemInformation />
      <ItemTag />
      <CustomizedBox>
        <CustomizedScrollbar>
          <CustomizedTab
            subtab
            tabs={subTabsOptions()}
            currentTab={currentTab}
          />
        </CustomizedScrollbar>
        <CustomizedScrollbar>{renderTab()}</CustomizedScrollbar>
      </CustomizedBox>
    </Fragment>
  );
};

export default DetailTab;
