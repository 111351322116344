import { Dispatch, SetStateAction, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import { useStateContext } from "../../../contexts/auth-context";
import {
  ContactImportMode,
  ContactImportType,
  ValidateContactImportQuery,
  useImportContactChannelMutation,
  useValidateContactImportQuery,
} from "../../../generated/contact";
import {
  addImporterErrorHandler,
  // findDuplicatesWithList,
  findNoneContactChannel,
  // isNotValidPhone,
  mapNestedData,
} from "../../../utils/Importer";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { contactChannelFormatter } from "../../../utils/Formatter/Global";
import { IImporterError } from "../../../types/global";
import { useTranslation } from "react-i18next";

const columns = ["unique_id", "contact_channel_type", "contact_channel_info"];

const count_limit = 50000;

const useContactChannelImporter = (
  type: ContactImportType,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();
  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const { refetch: validateCustomer, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_type: type,
          import_mode: ContactImportMode.Update,
          contact_unique_id_list: contactUniqueIdList,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useImportContactChannelMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const uniqueIdList: string[] = [];
      const contactChannelList: string[] = [];
      const phoneList: string[] = [];

      const formattedData: any[] = Array.from(
        data
          .reduce((map: any, item: any) => {
            const { unique_id, contact_channel_type, contact_channel_info } =
              item;

            const formatContactChannelType: string | undefined =
              contactChannelFormatter(contact_channel_type, false);

            if (!map.has(unique_id)) {
              // Create a new entry if the unique_id doesn't exist
              map.set(unique_id, {
                unique_id,
                contact_channel_list: [],
              });
            }

            if (unique_id) uniqueIdList.push(unique_id);
            if (contact_channel_type) {
              contactChannelList.push(contact_channel_type);
              if (contact_channel_type === "เบอร์โทรศัพท์") {
                if (contact_channel_info) {
                  phoneList.push(contact_channel_info);
                }
              }
            }

            // Add the contact channel to the existing entry
            map.get(unique_id).contact_channel_list.push({
              contact_channel_type: formatContactChannelType,
              contact_channel_info,
            });

            return map;
          }, new Map())
          .values()
      );

      // const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const noneContactChannel = findNoneContactChannel(contactChannelList);
      // const notValidPhone = isNotValidPhone(phoneList);

      const allError = [noneContactChannel];

      const errorData: IImporterError[] = [];

      // addImporterErrorHandler(
      //   errorData,
      //   "รหัสพนักงานในไฟล์ซ้ำ",
      //   "รหัสพนักงาน",
      //   duplicateUniqueId
      // );

      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ช่องทางการติดต่อ",
        noneContactChannel
      );

      // addImporterErrorHandler(
      //   errorData,
      //   "รูปแบบข้อมูลไม่ถูกต้อง",
      //   "ข้อมูลติดต่อ",
      //   notValidPhone
      // );

      setErrorData(errorData);

      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return;
      }

      setContactUniqueIdList([...new Set(uniqueIdList)]);

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else {
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateCustomer();
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];

      const arrays = [missingUniqueId];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        "ไม่พบข้อมูลในระบบ",
        `รหัส${t(`contact.contact_type.${type}`)}`,
        missingUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await create({
            importInput: {
              import_type: type,
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isCreating,
    validateHandler,
    importHandler,
  };
};

export default useContactChannelImporter;
