import {
  Stack,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useStateContext } from "../../contexts/auth-context";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import { useNavigate } from "react-router-dom";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";

type Props = {
  branchList: any[];
};

const BranchList = ({ branchList }: Props) => {
  const navigate = useNavigate();
  const {
    state: { authUser },
  } = useStateContext();

  const onBranchSelectHandler = (branchId?: number | null, isHq?: boolean) => {
    if (branchId) {
      sessionStorage.setItem("branch-id", branchId.toString());
      sessionStorage.setItem("is_hq", isHq ? "true" : "false");
      navigate("/landing");
    }
  };

  if (!authUser) {
    return <CircularProgress sx={{ mt: 1, mb: 4 }} />;
  }

  return (
    <CustomizedScrollbar sx={{ my: 3, height: 375, px: 1.5, width: 324 }}>
      <Stack spacing={2}>
        {branchList.map((branch) => (
          <Card
            key={branch.id}
            variant="outlined"
            sx={{
              borderColor: "#8B94CA",
              width: 300,
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => onBranchSelectHandler(branch.id, branch?.is_hq)}
          >
            <CardContent
              sx={{
                "&:last-child": {
                  p: 1,
                },
                p: 1,
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  component="span"
                  sx={{
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  bgcolor={(theme) => theme.palette.primary.light}
                >
                  <DomainOutlinedIcon color="primary" />
                </Box>
                <Box>
                  <Typography fontWeight={600}>{branch?.name}</Typography>
                  <Typography>รหัส: {branch?.unique_id}</Typography>
                  <Typography>
                    สถานะ: {branch?.status ? "ใช้งาน" : "หยุดใช้งาน"}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </CustomizedScrollbar>
  );
};

export default BranchList;
