import { Grid, IconButton, Typography } from "@mui/material";
import { ChangeEventHandler, FC, Fragment, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FileRejection } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FileError } from "react-dropzone";
import { Box } from "@mui/system";

import CancelIcon from "@mui/icons-material/Cancel";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { IMenuOption } from "../../../../types/global";
import CustomizedButton from "../../../Custom/CustomizedButton";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  borderWidth: 2,
  borderRadius: 14,
  borderColor: "#324290",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const ItemImg: FC<{
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  options?: IMenuOption[];
  acceptedFileType?: string;
  maxSize?: number;
  maxFileSize?: number;
  disabled?: boolean;
  isNotRevoke?: boolean;
}> = ({
  control,
  name,
  setValue,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isNotRevoke,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          onChange={(e) => onChange(e.target.files?.[0] ?? null)}
          options={options}
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isNotRevoke={isNotRevoke}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isNotRevoke?: boolean;
}> = ({
  control,
  name,
  setValue,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize = 10,
  maxFileSize,
  isNotRevoke,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/*"?: any[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?: any[];
    "application/pdf"?: any[];
  } => {
    switch (fileType) {
      case "image":
        return {
          "image/*": [],
        };
      case "xlsx":
        return {
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
      default:
        return {
          "image/*": [],
          "application/pdf": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const [selectedImg, setSelectedImg] = useState<number>(0);

  const files = useWatch({
    control,
    name,
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 10,
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize ? maxFileSize : 10485760,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
          }
        });
      });

      const newFiles: File[] =
        (!!files?.length && [...files].concat(acceptedFiles)) || acceptedFiles;

      // let url: any[] = [];
      // if (files.length + newFiles.length < maxSize) {
      //   console.log("newFiles", newFiles);
      //   const count = Math.abs(files.length + newFiles.length - maxSize);
      //   console.log("count", count);
      //   if (count > 0)
      //     for (let index = 0; index < newFiles.length; index++) {
      //       const file = newFiles[index];
      //       if (typeof file === "object")
      //         url.push(
      //           Object.assign(file, {
      //             preview: URL.createObjectURL(file),
      //           })
      //         );
      //       else url.push(file);
      //     }
      // }
      const url = newFiles.map((file: File) => {
        if (typeof file === "object")
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        else return file;
      });
      setValue(name, url);
    },
    disabled: files.length === (maxSize ?? 10) || disabled,
    multiple: true,
  });

  const style = useMemo(
    () => ({
      ...{
        ...baseStyle,
        borderStyle: "solid",
        borderColor: disabled ? "#e5e5e5" : "#324290",
      },
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [disabled, isDragActive, isDragAccept, isDragReject]
  );

  // const styleSmallImg = useMemo(
  //   () => ({
  //     display: "block",
  //     border: "2px solid #e5e5e5",
  //     cursor: !disabled ? "pointer" : "",
  //     position: "relative",
  //     height: "50px",
  //     width: "50px",
  //   }),
  //   []
  // );

  const moveToFirst = (arr: string[] & File[], index: number) => {
    if (!index && index !== 0) return;
    // Check if index is within the bounds of the array
    if (index < 0 || index >= arr.length) {
      return arr;
    }
    // Remove element at index and store it in a variable
    const element = arr.splice(index, 1)[0];
    // Insert the element at the beginning of the array
    arr.unshift(element);

    setSelectedImg(0);
    setValue(name, arr);
  };

  const removeFile = (file: any) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setSelectedImg(0);
    setValue(name, newFiles);
  };

  const renderGallary = () => (
    <Box
      display={"flex"}
      flexDirection="column"
      flexWrap="wrap"
      gap={2}
      maxWidth={"100%"}
    >
      {!disabled ? (
        <Box
          display="flex"
          justifyContent="center"
          width={200}
          height={205}
          position="relative"
        >
          <div {...getRootProps({ style })}>
            <input {...getInputProps({ onChange })} />
            {files && files.length > 0 ? (
              <Fragment>
                <div className="image-container">
                  <img
                    alt="company"
                    src={files[selectedImg].preview || files[selectedImg]}
                    className={"image-no-hover"}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      if (!isNotRevoke) {
                        URL.revokeObjectURL(
                          files[selectedImg].preview || files[selectedImg]
                        );
                      }
                    }}
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <ImageOutlinedIcon color="primary" />
                <Typography color="primary">อัปโหลดรูปภาพ</Typography>
                <Typography color="primary">
                  {files.length}/{maxSize}
                </Typography>
              </Fragment>
            )}
          </div>
          {files.length > 0 && selectedImg === 0 && (
            <Box
              sx={{
                position: "absolute",
                left: "5%",
                top: "5%",
                zIndex: 5,
              }}
              bgcolor="primary.light"
              padding="0 1rem"
              borderRadius="0.5rem"
            >
              <Typography color="#4E80FA">รูปหลัก</Typography>
            </Box>
          )}
          {!disabled && files.length > 0 && (
            <div
              style={{
                position: "absolute",
                left: "90.3%",
                top: "-9%",
                zIndex: 5,
              }}
            >
              <IconButton onClick={removeFile(files[selectedImg])}>
                <CancelIcon
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                  }}
                />
              </IconButton>
            </div>
          )}
        </Box>
      ) : files && files.length > 0 ? (
        <Box
          position="relative"
          justifyContent="center"
          width={180}
          height={180}
        >
          <div
            className="image-container"
            style={{
              border: "2px solid #e5e5e5",
            }}
          >
            <img
              alt="company"
              src={files[0].preview || files[0]}
              className={"image-no-hover"}
              // Revoke data uri after image is loaded
              onLoad={() => {
                if (!isNotRevoke) {
                  URL.revokeObjectURL(files[0].preview || files[0]);
                }
              }}
            />
          </div>
          {files.length > 0 && selectedImg === 0 && (
            <Box
              sx={{
                position: "absolute",
                left: "5%",
                top: "5%",
                zIndex: 5,
              }}
              bgcolor="primary.light"
              padding="0 1rem"
              borderRadius="0.5rem"
            >
              <Typography color="#4E80FA">รูปหลัก</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" width={200} height={200}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps({ onChange })} />
            <ImageOutlinedIcon
              color={disabled ? "disabled" : "primary"}
              fontSize="large"
            />
          </div>
        </Box>
      )}
      {!disabled && (
        <CustomizedButton
          variant="contained"
          title="ใช้เป็นรูปหลัก"
          onClick={() => {
            moveToFirst(files, selectedImg);
          }}
          sx={{ width: 200 }}
        />
      )}
      <Grid container spacing={1} mt={disabled ? 1 : undefined}>
        {files.map((file: any, index: number) => {
          return (
            <Grid
              item
              xs={4}
              sm={1}
              md={2.2}
              // justifyContent="center"
              key={typeof file === "string" ? file : file.preview}
              // position="relative"
              // width={55}
              height={55}
            >
              <div
                style={{
                  display: "block",
                  border: "2px solid #e5e5e5",
                  cursor: !disabled ? "pointer" : "",
                  position: "relative",
                  height: "52px",
                  width: "52px",
                }}
                onClick={() => setSelectedImg(index)}
              >
                <img
                  alt="company"
                  src={file.preview || file}
                  className="image-no-hover"
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    if (!isNotRevoke) {
                      URL.revokeObjectURL(file.preview);
                    }
                  }}
                />
              </div>
              {/* {!disabled && files.length > 0 && (
                <IconButton
                  style={{
                    position: "absolute",
                    top: -16,
                    right: -18,
                    zIndex: 1000,
                  }}
                  size="small"
                  onClick={removeFile(files[index])}
                >
                  <CancelIcon
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  />
                </IconButton>
              )} */}
            </Grid>
          );
        })}
        {files.length < (maxSize ?? 10) && !disabled && (
          <Grid item xs={4} sm={1} md={2.2}>
            <div
              style={{
                height: "52px",
                width: "52px",
              }}
            >
              <div {...getRootProps({ style })}>
                <input {...getInputProps({ onChange })} />
                <ImageOutlinedIcon color="primary" />
                <Typography color="primary">
                  {files.length}/{maxSize}
                </Typography>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  return <>{renderGallary()}</>;
};

export default ItemImg;
