import { IContact, IContactPerson } from "../../../types/Contact/contact";
import * as Yup from "yup";
import { faxReqExp, phoneRegExp } from "../../../utils/Global";

export const contactSchema: IContact = {
  unique_id: "",
  business_type: "corporate",
  business_type_detail: "",
  title_name: "",
  name: "",
  last_name: "",
  nick_name: "",
  gender: "",
  taxpayer_id: "",
  source_country: "thailand",
  type: "",
  source: "",
  group: "",
  zone: "",
  sales_channel: "",
  contact_channel_list: [
    {
      contact_channel_type: "phone",
      contact_channel_info: "",
    },
    {
      contact_channel_type: "email",
      contact_channel_info: "",
    },
  ],
  tag_list: [],
  img_url: [],
  remark: "",
  address_list: [
    {
      type: "ที่อยู่จดทะเบียน",
      address_name: "",
      district: "",
      is_default: true,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "thailand",
      fax: "",
    },
    {
      type: "ที่อยู่จัดส่งสินค้า",
      address_name: "",
      district: "",
      is_default: false,
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "thailand",
      fax: "",
    },
  ],
  contact_person_list: [],
  attribute_list: [],
  attachment_list: [],
  sales_person_list: [],
  member_detail: {
    member_unique_id: "",
    opened_date: null,
    expired_date: null,
  },
  status: 1,
  remark_status: "",
};

export const contactPersonSchema: IContactPerson = {
  position: "",
  title_name: "",
  first_name: "",
  last_name: "",
  nick_name: "",
  img_url: [],
  contact_channel_list: [
    {
      contact_channel_type: "",
      contact_channel_info: "",
    },
  ],
  remark: "",
};

const contactChannelListValidation = Yup.object().shape({
  contact_channel_type: Yup.string().required("กรุณาระบุ"),
  contact_channel_info: Yup.string().when(
    "contact_channel_type",
    (contact_channel_type, schema) => {
      console.log("contact_channel_type", contact_channel_type);
      if (
        Array.isArray(contact_channel_type) &&
        contact_channel_type[0] === "phone"
      )
        return schema.matches(phoneRegExp, "กรุณาระบุให้ถูกต้อง");
      else if (contact_channel_type[0] === "mobile_phone")
        return schema.max(50, "กรุณาระบุไม่เกิน 50 ตัวอักษร");
      else return schema;
    }
  ),
});

const addressValidation = Yup.object().shape({
  contact_phone: Yup.string().test(
    "is-valid-phone",
    "กรุณาระบุให้ถูกต้อง",
    function (value) {
      if (value) {
        return phoneRegExp.test(value);
      }
      return true;
    }
  ),
  fax: Yup.string().test(
    "is-valid-fax",
    "กรุณาระบุให้ถูกต้อง",
    function (value) {
      if (value) {
        return faxReqExp.test(value);
      }
      return true;
    }
  ),
});

export const contactValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  source_country: Yup.string(),
  taxpayer_id: Yup.string()
    .required("กรุณาระบุ")
    .when("source_country", {
      is: "thailand",
      then: (schema) =>
        schema.min(13, "กรุณาระบุให้ถูกต้อง").max(13, "กรุณาระบุให้ถูกต้อง"),
      otherwise: (schema) => schema.max(50, "ห้ามยาวเกิน 50 หลัก"),
    }),

  contact_channel_list: Yup.array().of(contactChannelListValidation),
  address_list: Yup.array().of(addressValidation),
});
